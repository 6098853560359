import { asyncLoadCSS, asyncLoadScript } from 'helpers/loadScript';
import logger from 'helpers/logger';
import { toast } from 'react-toastify';
import { getProdamusDomain } from 'helpers/parseURL';

import i18n from 'services/i18n';

import { ProdamusEventType } from 'modules/Announcements/types';
import { ProdamusWidgetParams, ProdamusEvent } from './types';

/*  Prodamus wont define any window variables, just variables inside script */
declare const payformInit: ((url: string, config: Record<string, unknown>) => void);

class Prodamus {
  private eventHandler: (event: ProdamusEvent) => void = () => {};

  private async init() {
    if (this.isInit) {
      return;
    }

    try {
      await Promise.all([
        asyncLoadScript('https://widget.prodamus.ru/src/init.js'),
        asyncLoadCSS('https://widget.prodamus.ru/src/init.css'),
      ]);
    } catch (error) {
      logger.error('Prodamus: failed to load scripts', { error });
      toast.error(i18n.t('announcements.loadPaymentFail'));
    }
  }

  get isInit() {
    return typeof payformInit === 'function';
  }

  async openProdamusWidget({
    url, currency, orderSum, orderNum, customHandler,
  }: ProdamusWidgetParams) {
    if (!this.isInit) {
      await this.init();
    }

    this.eventHandler = (event: ProdamusEvent) => {
      const { status } = event.data || {};
      customHandler?.(event);

      if (status === ProdamusEventType.Error) {
        toast('announcements.paymentError');
      }

      if (status === ProdamusEventType.Close) {
        window.removeEventListener('message', this.eventHandler);
      }
    };

    window.removeEventListener('message', this.eventHandler);
    window.addEventListener('message', this.eventHandler);

    try {
      const paymentUrl = getProdamusDomain(url);
      if (!paymentUrl) {
        toast.error(i18n.t('announcements.paymentWrongUrl'));
        return;
      }

      payformInit(paymentUrl, {
        _param_order_num: orderNum,
        order_sum: orderSum,
        currency: currency?.toLocaleLowerCase(),
      });
    } catch (error) {
      logger.error('Prodamus: failed to open widget', {
        error, url, currency, orderSum, orderNum,
      });
      toast.error(i18n.t('announcements.paymentInitFail'));
    }
  }
}

export default new Prodamus();
