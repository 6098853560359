import React, { VoidFunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';

import useStores from 'stores/root';
import { LivedigitalTeleboss } from 'components/Optional';
import KnowledgeBaseButton from 'components/BasicHeader/Desktop/KnowledgeBaseButton';
import Navigation from 'components/BasicHeader/Navigation';
import Logo from 'components/Logo';

import {
  BasicHeaderMobileRoot,
  InfoBlock,
} from './index.styled';
import UserButton from '../UserButton';
import SettingsButton from '../SettingsButton';

const BasicHeaderMobile: VoidFunctionComponent = observer(() => {
  const { userStore } = useStores();

  return (
    <BasicHeaderMobileRoot>
      <InfoBlock>
        <Logo data-stats="ui_rooms_ld_logo" />
        <UserButton name={userStore.username} icon={userStore.image} />
        <LivedigitalTeleboss>
          <KnowledgeBaseButton />
        </LivedigitalTeleboss>
        <SettingsButton />
      </InfoBlock>
      <Navigation />
    </BasicHeaderMobileRoot>
  );
});

export default BasicHeaderMobile;
