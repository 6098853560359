import React from 'react';

export enum DnDType {
  PEER_SLOT = 'peerSlot',
  PEER_VIEW = 'peerView',
  PARTICIPANT_CARD = 'participantCard',
  PARTICIPANTS_LIST = 'participantsList',
  PEER_SCREEN_SHARE = 'screenSharePeer',
  PEER_OFFLINE = 'peerOffline',
  PARTICIPANT = 'participant',
  MODERATOR = 'moderator',
  FLOAT_SLOT_TARGET = 'floatSlotTarget',
}

export enum WaitingPageType {
  conferenceWaiting = 'conferenceWaiting',
  conferenceDenied = 'conferenceDenied',
}

export interface WaitingPageConfig {
  title?: string,
  subtitle?: string,
  showLoader?: boolean,
  extra?: React.ReactNode,
}

export enum MessagesToChatPopup {
  chatSetEventJoinInfo = 'chatSetEventJoinInfo',
  chatSetEventSetTime = 'chatSetSetTime',
}

export enum MessagesFromChatPopup {
  chatPopupWindowIsReady = 'chatPopupWindowIsReady',
}

export interface PostMessage {
  topic: string;
  payload: unknown
}
