import { AxiosInstance } from 'axios';

import { getAxiosErrorMessage } from 'helpers/errors';

import {
  WebHookCreatePayload,
  WebHookResponse,
  WebHookUpdatePayload,
  WebHookView,
} from '../types';

class WebHookApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async get(spaceId: string): Promise<WebHookView> {
    try {
      const { data } = await this.api.get<WebHookView>(`spaces/${spaceId}/webhook`);
      return data;
    } catch (error) {
      throw new Error(getAxiosErrorMessage(error));
    }
  }

  async create(payload: WebHookCreatePayload): Promise<WebHookResponse> {
    try {
      const { spaceId, url } = payload;
      const { data } = await this.api.post<WebHookResponse>(`spaces/${spaceId}/webhook`, { url });
      return data;
    } catch (error) {
      throw new Error(getAxiosErrorMessage(error));
    }
  }

  async update(payload: WebHookUpdatePayload): Promise<WebHookView> {
    try {
      const { spaceId, url, isActive } = payload;
      const { data } = await this.api.put<WebHookView>(`spaces/${spaceId}/webhook`, { url, isActive });
      return data;
    } catch (error) {
      throw new Error(getAxiosErrorMessage(error));
    }
  }

  async delete(spaceId: string): Promise<void> {
    try {
      await this.api.post(`spaces/${spaceId}/webhook`);
    } catch (error) {
      throw new Error(getAxiosErrorMessage(error));
    }
  }

  async refreshSecret(spaceId: string): Promise<WebHookResponse> {
    try {
      const { data } = await this.api.put<WebHookResponse>(`spaces/${spaceId}/webhook/refresh-secret`);
      return data;
    } catch (error) {
      throw new Error(getAxiosErrorMessage(error));
    }
  }
}

export default WebHookApi;
