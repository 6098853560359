import { observable, makeAutoObservable, reaction } from 'mobx';

import logger from 'helpers/logger';
import { getUrlParam } from 'helpers/url';
import { SpaceRole } from 'services/MoodHoodApiClient/types';
import type UserStore from 'stores/user';
import type PaymentStore from 'stores/payment';
import AccountsApi from 'services/MoodHoodApiClient/AccountsApi';
import Api from 'services/MoodHoodApiClient/Api';

import {
  TrackUserSignUpParams, EventName,
} from './types';

class CarrotQuest {
  @observable isReady = false;

  private isLoggedIn = false;

  private healthCheckTimerId: NodeJS.Timer | null = null;

  private HEALTH_CHECK_TIMEOUT_MS = 5_000;

  private accountsApi = new AccountsApi(Api);

  user: UserStore;

  payments: PaymentStore;

  private getUserInfo() {
    const { userInfo } = this.user;
    if (!userInfo) {
      return null;
    }

    const {
      username, email, phone, userId,
    } = userInfo;

    return {
      $name: username, /* '$' is a prefix for builtin properties in carrotquest */
      $email: email,
      ...(phone && { $phone: phone }),
      userId,
    };
  }

  private auth(): void {
    if (!this.isReady || this.isLoggedIn) {
      return;
    }

    const { id: userId, carrotQuestToken } = this.user;

    if (!carrotQuestToken || !userId) {
      return;
    }

    try {
      window.carrotquest.auth(userId, carrotQuestToken);
      this.isLoggedIn = true;
      this.updateUserInfo();
    } catch (error) {
      logger.error('CarrotQuest error: Cant update user info', { error, case: 'auth' });
    }
  }

  private updateUser(fields: Record<string, unknown>) {
    if (!this.isReady) {
      return;
    }

    try {
      window.carrotquest.identify(fields);
    } catch (error) {
      logger.error('CarrotQuest error: Cant update user info', { error, ...fields });
    }
  }

  constructor(userStore: UserStore, paymentStore: PaymentStore) {
    this.user = userStore;
    this.payments = paymentStore;

    if (this.isDisabled()) {
      return;
    }

    makeAutoObservable(this);

    reaction(
      () => [this.user.carrotQuestToken, this.isReady],
      ([token, isReady]) => {
        if (token && isReady) {
          this.auth();
        }
      },
    );

    reaction(
      () => this.user.auth.isTokenFetched,
      (isTokenFetched) => {
        if (!isTokenFetched) {
          this.trackUserSignOut();
        }
      },
    );

    this.healthCheckTimerId = setTimeout(() => {
      if (!this.isReady && window.lsdProductionBuild) {
        logger.error('CarrotQuest error: failed to load');
      }
    }, this.HEALTH_CHECK_TIMEOUT_MS);

    if (!window.carrotquest) {
      document.addEventListener('carrotquest_ready', () => {
        this.isReady = true;
      }, { once: true });
      return;
    }

    window.carrotquest.onReady(() => {
      this.isReady = true;
    });
  }

  isDisabled() {
    const isDisabledByParam = Boolean(getUrlParam('disableSupport'));
    return isDisabledByParam;
  }

  dispose(): void {
    if (this.healthCheckTimerId) {
      clearTimeout(this.healthCheckTimerId);
    }
  }

  updateUserInfo(customFields: Record<string, unknown> = {}):void {
    if (!this.isReady) {
      return;
    }

    const userInfo = this.getUserInfo();

    if (!userInfo) {
      return;
    }

    try {
      const { currentPlan } = this.payments;
      const plan = currentPlan ? { plan: currentPlan.alias } : {};
      this.updateUser({ ...userInfo, ...plan, ...customFields });
    } catch (error) {
      logger.error('CarrotQuest error: Cant update user info', { error, ...userInfo });
    }
  }

  updateUserSpaceRole(spaceRole: SpaceRole) {
    this.updateUser({ spaceRole });
  }

  trackUserSignUp(params: TrackUserSignUpParams):void {
    if (this.isReady) {
      window.carrotquest.track(EventName.SignUp);
      this.updateUserInfo({ ...params });
    }
  }

  trackUserSignIn(): void {
    if (!this.isReady) {
      return;
    }

    try {
      this.auth();
      window.carrotquest.track(EventName.SignIn);
    } catch (error) {
      logger.error('CarrotQuest error', { error, case: 'trackUserSignIn' });
    }
  }

  trackUserSignOut(): void {
    if (!this.isReady) {
      return;
    }

    try {
      window.carrotquest.track(EventName.SignOut);
    } catch (error) {
      logger.error('CarrotQuest error', { error, case: 'trackUserSignOut' });
    }
  }

  trackPageView(url: string):void {
    if (!this.isReady) {
      return;
    }

    try {
      window.carrotquest.track(EventName.PageView, { url });
    } catch (error) {
      logger.error('CarrotQuest error', { error, case: 'trackPageView' });
    }
  }

  async openChat(spaceId?: string): Promise<void> {
    if (!this.isReady) {
      return;
    }

    const { data, error: accountsError } = await this.accountsApi.getPublicMeta(spaceId);
    if (accountsError) {
      logger.error('CarrotQuest error', { error: accountsError, case: 'openChat' });
      /* continue to open chat anyway */
    }

    if (data) {
      window.carrotquest.identify(data);
    }

    try {
      window.carrotquest.open();
    } catch (error) {
      logger.error('CarrotQuest error', { error, case: 'openChat' });
    }
  }
}

export default CarrotQuest;
