export const VLProjectSpacesToIgnore = [
  '60d984cacb9468521ed406d1',
  '61f3a7fbf2f6f780cd5ece7a',
  '60d984e6cb9468521ed40739',
  '6124dcab684e73c16c00321d',
  '61386a18b91d13839b80bbd3',
  '61077bee4b8db55d66cdb410',
  '61e66a02e279062bab348287',
  '6139c5ec09639865094ad203',
  '61c187c413fb9d3476ad94e0',
  '620a3f14ec67e45d389ffdf3',
  '612dbb98b2f9d4a99f18f553',
  '61617e0460c154b049d32e20',
  '61e16d98001d7a3b31e40718',
  '621efa238f24b482e72d6e8b',
  '621f7a4c1c2f7632ca624852',
  '622191091d428c04e970d4ba',
];
