import { v4 as uuid } from 'uuid';
import {
  addSeconds, isBefore, max as maxDate, subSeconds,
} from 'date-fns';

import { EventSessionFrequency, PlaybackEvent } from 'services/MoodHoodApiClient/Playbacks.types';

import { SortOrder } from 'types/common';
import { ScheduleParams, PlaybackEventSessionExtended, DateRange } from './types';
import RecurrentEvent from './RecurrentEvent';

const getEventSessionFrequency = (evt: PlaybackEvent) => {
  if (evt.recurrence) {
    return EventSessionFrequency.Recurrent;
  }

  return EventSessionFrequency.OneTime;
};

class Schedule {
  private params: ScheduleParams;

  private lastSessionDate: Date = new Date();

  private recurrentEvents: PlaybackEvent[];

  private oneTimeEvents: PlaybackEvent[];

  private continuousEvents: PlaybackEvent[];

  private dateRange: DateRange | null = null;

  private makeSession(event: PlaybackEvent) {
    const {
      id: playbackEventId,
      type,
      startDate,
      endDate,
      recurrence,
      timezone,
    } = event;

    if (!startDate) {
      throw new Error('Schedule error: playback event is permanent');
    }

    const session: PlaybackEventSessionExtended = {
      id: uuid(),
      playbackEventId,
      type,
      startDate,
      endDate,
      recurrence,
      uniqueParticipantsCount: 0,
      frequency: getEventSessionFrequency(event),
      timezone,
    };

    return session;
  }

  private getContinuousSessions() {
    const { limit } = this.params;
    return this.continuousEvents.slice(0, limit).map((evt) => this.makeSession(evt));
  }

  private getOneTimeSessions() {
    const { limit } = this.params;
    const actualEvents = this.oneTimeEvents.filter(
      ({ startDate }) => startDate && startDate > this.lastSessionDate,
    ).slice(0, limit);

    return actualEvents.map((evt) => this.makeSession(evt));
  }

  private getRecurrentSessions() {
    const { limit } = this.params;
    const { lastSessionDate } = this;
    const actualEvents = this.recurrentEvents.filter(
      ({ endDate }) => !endDate || (endDate && endDate > lastSessionDate),
    ).slice(0, limit);

    const recurrentEvents: RecurrentEvent[] = actualEvents.filter((evt) => evt.startDate)
      .map((evt) => new RecurrentEvent({
        /* cron-parser wont include currentDate even if it satisfies cron pattern */
        /* as a workaround we substract a second from lastSession */
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...evt, startDate: subSeconds(evt.startDate! < lastSessionDate ? lastSessionDate : evt.startDate!, 1),
      }, this.makeSession(evt)));

    if (recurrentEvents.length === 0) {
      return [];
    }

    const sessions = recurrentEvents
      .flatMap((evt) => evt.nextSessions(limit));

    return sessions;
  }

  constructor(params: ScheduleParams) {
    this.params = params;
    this.params.events = params.events.sort((a, b) => Number(a.startDate) - Number(b.startDate));
    this.recurrentEvents = this.params.events.filter((evt) => evt.recurrence);
    this.oneTimeEvents = this.params.events.filter((evt) => !evt.recurrence);
    this.continuousEvents = this.params.events.filter((evt) => !evt.startDate);
  }

  nextSessions(sortOrder: SortOrder) {
    const { limit } = this.params;
    const hasInfiniteRange = this.recurrentEvents.find((evt) => !evt.endDate);
    const sortFn = (sortOrder === 'asc' || hasInfiniteRange)
      ? (a: number, b:number) => a - b : (a: number, b:number) => b - a;
    const recurrentSessions = this.getRecurrentSessions();
    const oneTimeSessions = this.getOneTimeSessions();
    const sortedSessions = [...recurrentSessions, ...oneTimeSessions]
      .filter((session) => !this.dateRange?.endDate || isBefore(new Date(session.startDate), this.dateRange?.endDate))
      .sort((a, b) => sortFn(Number(a.startDate), Number(b.startDate)))
      .slice(0, limit);

    if (sortedSessions.length) {
      /* cron-parser compensation */
      this.lastSessionDate = addSeconds(
        sortedSessions[sortOrder === 'asc' ? sortedSessions.length - 1 : 0].startDate, 1,
      );
    }

    return sortedSessions;
  }

  continuousSessions() {
    return this.getContinuousSessions();
  }

  setDateRange(dateRange: DateRange | null) {
    this.dateRange = dateRange;
    this.reset();
  }

  reset() {
    if (this.dateRange) {
      const startDate = this.dateRange.startDate ? maxDate([new Date(), this.dateRange.startDate]) : new Date();
      this.dateRange.startDate = startDate;
    }

    this.lastSessionDate = this.dateRange?.startDate || new Date();
  }
}

export default Schedule;
