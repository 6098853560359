import { AxiosInstance } from 'axios';

import { SpaceRole } from './types';

class InviteApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async createInvite(spaceId: string, role: SpaceRole): Promise<string> {
    const { data } = await this.api.post<{ id: string }>(`/spaces/${spaceId}/invites`, {
      role,
    });
    return data.id;
  }

  async activateInvite(spaceId: string, inviteId: string): Promise<boolean> {
    const { status } = await this.api.post(`/spaces/${spaceId}/invites/${inviteId}/activate`);
    return status === 200;
  }
}

export default InviteApi;
