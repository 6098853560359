import React, {
  VoidFunctionComponent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { areCookiesBlocked } from 'helpers/browser';

import { Button, Layout } from './Layout';

const AllowPage: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      {t('safariRequestStorageAccess.thanks')}
      <br />
      <br />
      <Button
        id="close"
        variant="primary"
        onClick={() => {
          window.close();
          areCookiesBlocked();
        }}
      >
        {t('common.close')}
      </Button>
    </Layout>
  );
};

export default AllowPage;
