import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';

import { Dialog } from 'ui-kit';
import { useTranslation } from 'react-i18next';
import useStores from 'stores/root';

import Oauth2ClientSettings from './Oauth2ClientSettings';
import WebHookSettings from './WebHookSettings';
import PersonalTokenSettings from './PersonalTokenSettings';

const IntegrationDialog: FunctionComponent = observer(() => {
  const { uiStore, paymentStore, spaceStore } = useStores();
  const { t } = useTranslation();

  const canUseIntegration = paymentStore.currentPlan?.features.apiAccess
    && spaceStore.space?.isSubscriptionActive
    && spaceStore.isSpaceOwner;

  const closeDialog = () => uiStore.setIsIntegrationDialogOpen(false);

  return (
    <Dialog
      fixedPosition
      onClose={closeDialog}
      title={t('common.integration')}
    >
      <PersonalTokenSettings />
      {canUseIntegration && (
        <>
          <Oauth2ClientSettings />
          <WebHookSettings />
        </>
      )}
    </Dialog>
  );
});

export default IntegrationDialog;
