import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';

import { isRoomRecord } from 'helpers/url';

const HiddenFromRecorder: FunctionComponent = observer(({ children }) => {
  if (isRoomRecord()) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
});

export default HiddenFromRecorder;
