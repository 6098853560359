import React, {
  FunctionComponent, useEffect, useRef, useState, PropsWithChildren, useLayoutEffect,
} from 'react';
import { useTheme } from '@emotion/react';

import UserImageIcon from 'ui-kit/assets/icons/user-image.svg';
import { getUserInitials, getColorFromString } from 'helpers/common';

import Layout from './Layout';

interface AvatarProps {
  userName?: string | null;
  imageUrl?: string | null;
  className?: string;
  showDefaultImage?: boolean;
  defaultImage?: string;
  fixedSize?: boolean;
}

const Avatar: FunctionComponent<PropsWithChildren<AvatarProps>> = ({
  userName,
  imageUrl,
  className,
  showDefaultImage = true,
  defaultImage = UserImageIcon,
  fixedSize,
  children,
}) => {
  const [initials, setInitials] = useState('');
  const [bkgColor, setBkgColor] = useState('');
  const [boxSize, setBoxSize] = useState(0);
  const ref = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  useLayoutEffect(() => {
    if (fixedSize && ref.current) {
      return () => {};
    }

    const ro = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const { width = 0, height = 0 } = entry.target.getBoundingClientRect() || {};
        const size = Math.round(Math.min(width, height) / 2);
        setBoxSize(size);
      });
    });
    if (ref.current?.parentElement) {
      ro.observe(ref.current.parentElement);
    }

    return () => ro.disconnect();
  }, []);

  useEffect(() => {
    if ((userName || userName === '') && showDefaultImage) {
      const userInitials = getUserInitials(userName);
      const userBkgColor = getColorFromString(theme.avatarColors, userName);
      setInitials(userInitials);
      setBkgColor(userBkgColor);
    }
  }, [userName]);

  const fontSize = Math.min(Math.floor(boxSize * 0.5), 24);
  const style = fixedSize
    ? { }
    : { width: `${boxSize}px`, height: `${boxSize}px`, fontSize };

  return (
    <Layout
      className={className}
      bkgColor={bkgColor}
      imageUrl={imageUrl || (!initials && showDefaultImage ? defaultImage : undefined)}
      ref={ref}
      /* updating style directly, to avoid generating unnecessary styles */
      style={style}
    >
      {!imageUrl && initials }
      {children}
    </Layout>
  );
};

export default Avatar;
