import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FigmaTypography } from 'ui-kit';

export const AfterRegisterDialogContentRoot = styled.div<{ isCompact: boolean }>`
  --animation-delay: 850ms;
  --animation-duration: 150ms;

  display: flex;
  gap: 24px;
  flex-direction: column;
  width: 536px;
  flex-grow: 1;

  ${({ isCompact }) => isCompact && `
    width: 100%;
    justify-content: space-between;
    max-width: 575px;
    margin: 0 auto;
  `};
`;

export const Title = styled.div<{ isCompact: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 48px;

  ${({ isCompact }) => isCompact && `
    height: 36px;
  `};
`;

export const TopBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const TitleText = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const CheckList = styled.div<{ isCompact: boolean }>`
  gap: 8px;
  height: 120px;
  display: flex;
  flex-direction: column;

  ${({ isCompact }) => isCompact && `
    height: auto;
  `};
`;

const FadeInAnimation = css`
  opacity: 0;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
  animation-duration: var(--animation-duration);
  animation-delay: calc(var(--animation-order) * var(--animation-delay));
`;

export const CheckListItem = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    align-self: flex-start;
    flex-shrink: 0;
    margin: 0 8px;
    color: ${({ theme }) => theme.colors.successBase};
  }

  & > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  ${FadeInAnimation}
`;

export const BottomBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > a {
    height: 48px;
  }
`;

export const Alert = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary01};
  border-radius: 10px;
  display: flex;
  gap: 8px;
  padding: 8px 16px 8px 8px;
  & > svg {
    color: ${({ theme }) => theme.colors.secondary03};
  }
  ${FadeInAnimation}
`;

export const AlertMessage = styled(FigmaTypography)`
  color: ${({ theme }) => theme.colors.primary};
  flex: 1;
  position: relative;
  text-shadow: 0px 4px 4px #00000040;
`;
