import styled from '@emotion/styled';

import backImage from './background.svg';

export const Layout = styled.div<{ withBackground: boolean }>(({ withBackground }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  position: 'absolute',
  inset: 0,
  background: withBackground ? `url(${backImage})` : undefined,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  textAlign: 'center',
}));
