import styled from '@emotion/styled';

import { FlexCenter } from 'ui-kit';
import { ChatProps } from './types';

export const Root = styled(FlexCenter)<ChatProps>`
  position: absolute;
  inset: 0;
  background-color: ${({ theme }) => theme.colors.bg};
  justify-content: center;
  flex-grow: 1;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.bg};
  margin: 16px;
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.secondary02};
`;
