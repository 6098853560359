import React, { FunctionComponent, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import useStores from 'stores/root';
import { MAX_USERNAME_LENGTH } from 'constants/common';

import ChangeEmail from '../ChangeEmail';
import ChangePassword from '../ChangePassword';
import SuccessRequest from '../SuccessRequest';
import UserProfileMain from '../Main';
import { ProfileStatesNames } from '../type';

const ProfileStates: FunctionComponent = observer(() => {
  const {
    uiStore: { userProfileDialogState },
    participantStore,
    userStore,
    authStore,
  } = useStores();

  const { participantInfo } = participantStore;

  const [username, setUsername] = useState(participantInfo.username);

  const [touched, setTouched] = useState(false);
  const { t } = useTranslation();

  // for old users with long name
  useEffect(() => {
    if (username.length > MAX_USERNAME_LENGTH) {
      const newUsername = username.slice(0, MAX_USERNAME_LENGTH);
      setUsername(newUsername);
    }
  }, [username]);

  useEffect(() => {
    setUsername(participantStore.name);
  }, [participantStore.name]);

  useEffect(() => {
    if (userStore.chatUserUpdatingError) {
      toast.error(t('errors.chatUserUpdateInfo'));
    }
  }, [userStore.chatUserUpdatingError]);

  const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.length > MAX_USERNAME_LENGTH) {
      return;
    }

    if (username !== value) {
      setUsername(value);
      setTouched(true);
    }
  };

  const handleUserNameOnSave = () => {
    const userNameChanged = touched && username && username.length >= 2;

    if (userNameChanged) {
      const trimValue = username.trim();
      if (authStore.isUser) {
        userStore.updateUser({ username: trimValue });
      }

      participantStore.updateUsername(trimValue);
    }
  };

  switch (userProfileDialogState) {
    case ProfileStatesNames.PROFILE_DATA:
      return (
        <UserProfileMain
          userName={username}
          handleUserNameChange={handleUserNameChange}
          handleUserNameOnSave={handleUserNameOnSave}
        />
      );
    case ProfileStatesNames.CHANGE_EMAIL:
      return <ChangeEmail />;
    case ProfileStatesNames.CHANGE_PASSWORD:
      return <ChangePassword />;
    case ProfileStatesNames.CHANGE_EMAIL_REQUEST_SUCCESS:
      return <SuccessRequest />;
    case ProfileStatesNames.CHANGE_PASSWORD_REQUEST_SUCCESS:
      return <SuccessRequest />;
    default:
      return null;
  }
});

export default ProfileStates;
