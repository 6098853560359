import React from 'react';
import { toast } from 'react-toastify';

import PutHandDownAutomatically from './PutHandDownAutomatically';
import { PUT_HAND_AUTOMATICALLY_ID } from './const';
import { toastBody, toastClassName } from './Layout';

export const showPutHandDownAutomaticallyToast = () => {
  toast.success(<PutHandDownAutomatically />, {
    toastId: PUT_HAND_AUTOMATICALLY_ID,
    draggable: false,
    closeOnClick: false,
    autoClose: false,
    closeButton: false,
    bodyStyle: toastBody,
    className: toastClassName,
  });
};

export const hidePutHandDownAutomaticallyToast = () => {
  toast.dismiss(PUT_HAND_AUTOMATICALLY_ID);
};
