import styled from '@emotion/styled';

import { Avatar } from 'ui-kit';

export const UserAvatar = styled(Avatar)({
  width: 32,
  height: 32,
  fontSize: '16px',
});

export const User = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserButtonLayout = styled.div`
  margin-left: auto;
`;
