import React from 'react';
import ParticipantStore from 'stores/participant';
import RoomStore from 'stores/room';
import ModeratorControlsStore from 'stores/moderatorControls';
import AuthStore from 'stores/auth';
import UserStore from 'stores/user';
import SpaceStore from 'stores/space';
import UIStore from 'stores/ui';
import MoodHoodApiClient from 'services/MoodHoodApiClient';
import RecordStore from 'stores/record';
import Metrics from 'services/Metrics';
import CarrotQuest from 'services/CarrotQuest';
import NewRelic from 'services/NewRelic';
import PaymentStore from './payment';
import RoomPaginationStore from './room.pagination';
import CallStore from './call';
import FeatureStore from './feature';
import Oauth2Client from './oauth2Client';
import WebHook from './webHook';
import AudioLevelWatcherStore from './audioLevelWatcher';
import analyticsApiClient from '../services/MoodHoodAnalyticsApiClient';
import { AnalyticsApiClient } from '../services/MoodHoodAnalyticsApiClient/types';
import DebugStore from './debug';
import EventStore from './event';

interface IStores {
  rootStore: RootStore,
  participantStore: ParticipantStore,
  roomStore: RoomStore,
  roomPaginationStore: RoomPaginationStore,
  moderatorControlsStore: ModeratorControlsStore,
  recordStore: RecordStore,
  authStore: AuthStore,
  userStore: UserStore,
  spaceStore: SpaceStore,
  uiStore: UIStore,
  paymentStore: PaymentStore,
  callStore: CallStore,
  featureStore: FeatureStore,
  oauth2ClientStore: Oauth2Client;
  webHookStore: WebHook;
  audioLevelWatcherStore: AudioLevelWatcherStore,
  debugStore: DebugStore,
  eventStore: EventStore,
}

export class RootStore {
  participantStore: ParticipantStore;

  roomStore: RoomStore;

  roomPaginationStore: RoomPaginationStore;

  moderatorControlsStore: ModeratorControlsStore;

  recordStore: RecordStore;

  authStore: AuthStore;

  userStore: UserStore;

  spaceStore: SpaceStore;

  uiStore: UIStore;

  paymentStore: PaymentStore;

  callStore: CallStore;

  featureStore: FeatureStore;

  moodHoodApiClient: MoodHoodApiClient;

  moodHoodAnalyticsApiClient: AnalyticsApiClient;

  metrics: Metrics;

  carrotQuest: CarrotQuest;

  newRelic: NewRelic;

  oauth2Client: Oauth2Client;

  webHook: WebHook;

  audioLevelWatcherStore: AudioLevelWatcherStore;

  debugStore: DebugStore;

  eventStore: EventStore;

  constructor() {
    this.moodHoodApiClient = new MoodHoodApiClient();
    this.authStore = new AuthStore();
    this.paymentStore = new PaymentStore();
    this.callStore = new CallStore();
    this.featureStore = new FeatureStore();
    this.audioLevelWatcherStore = new AudioLevelWatcherStore();

    this.userStore = new UserStore(this.authStore);
    this.carrotQuest = new CarrotQuest(this.userStore, this.paymentStore);
    this.metrics = new Metrics(this);
    this.spaceStore = new SpaceStore(this.metrics);
    this.recordStore = new RecordStore(this.moodHoodApiClient.record);
    this.oauth2Client = new Oauth2Client(this.moodHoodApiClient.space.oauth2client);
    this.webHook = new WebHook(this.moodHoodApiClient.space.webhook);

    this.participantStore = new ParticipantStore(this);
    this.roomPaginationStore = new RoomPaginationStore(this);
    this.roomStore = new RoomStore(this);
    this.moderatorControlsStore = new ModeratorControlsStore(this);
    this.uiStore = new UIStore(this);

    this.moodHoodAnalyticsApiClient = analyticsApiClient;
    this.debugStore = new DebugStore(this);
    this.newRelic = new NewRelic(this);
    this.eventStore = new EventStore(this);
  }

  getStores(): IStores {
    return {
      rootStore: this,
      participantStore: this.participantStore,
      roomStore: this.roomStore,
      roomPaginationStore: this.roomPaginationStore,
      moderatorControlsStore: this.moderatorControlsStore,
      authStore: this.authStore,
      userStore: this.userStore,
      spaceStore: this.spaceStore,
      uiStore: this.uiStore,
      recordStore: this.recordStore,
      paymentStore: this.paymentStore,
      callStore: this.callStore,
      featureStore: this.featureStore,
      oauth2ClientStore: this.oauth2Client,
      webHookStore: this.webHook,
      audioLevelWatcherStore: this.audioLevelWatcherStore,
      debugStore: this.debugStore,
      eventStore: this.eventStore,
    };
  }
}

export const rootStore = new RootStore();

const storesContext = React.createContext(rootStore.getStores());

const useStores = (): IStores => React.useContext(storesContext);

export default useStores;
