import logger from 'helpers/logger';
import { getLocalStorageItem } from 'helpers/localStorage';

import YandexMetricaClient from './YandexMetricaClient';

class MarketingMetrics {
  private readonly ymTrackerId = Number(process.env.REACT_APP_YANDEX_METRICA_TRACKER_ID);

  private readonly yaTracker = new YandexMetricaClient();

  private readonly mouseClickHandler: (evt: MouseEvent) => void;

  constructor() {
    this.mouseClickHandler = (evt: MouseEvent) => { this.handleMouseClick(evt); };
    window.addEventListener('click', this.mouseClickHandler, { capture: true });
  }

  trackUserSignUp(userName: string): void {
    this.trackMetric('user:register:success', userName);
  }

  trackInviteActivation(inviteId: string): void {
    this.trackMetric('user:invite:success', inviteId);
  }

  trackSpaceCreation(spaceId: string): void {
    this.trackMetric('user:createSpace:success', spaceId);
  }

  trackRoomCreation(roomAlias: string): void {
    this.trackMetric('user:createRoom:success', roomAlias);
  }

  trackEnterSpaceAttempt(spaceId: string): void {
    this.trackMetric('user:enterSpace:attempt', spaceId);
  }

  trackEnterSpace(spaceId: string): void {
    this.trackMetric('user:enterSpace:success', spaceId);
  }

  trackEnterRoomAttempt(roomAlias: string): void {
    this.trackMetric('user:enterRoom:attempt', roomAlias);
  }

  trackEnterRoom(roomAlias: string): void {
    this.trackMetric('user:enterRoom:success', roomAlias);
  }

  trackLeaveRoom(roomAlias: string): void {
    this.trackMetric('room:leave', roomAlias);
  }

  trackLeaveEvent(sessionId: string): void {
    this.trackMetric('event:leave', sessionId);
  }

  trackSentChatMessage(roomAlias: string): void {
    this.trackMetric('room:chatMessage', roomAlias);
  }

  trackStartRoomRecord(roomAlias: string): void {
    this.trackMetric('room:startRecord', roomAlias);
  }

  trackRoomRecordDownload(fileName: string): void {
    this.trackMetric('room:downloadRecord', fileName);
  }

  trackRoomReportDownload(roomAlias: string): void {
    this.trackMetric('room:downloadReport', roomAlias);
  }

  trackPlanPayment(planId: string): void {
    this.trackMetric('space:paymentSuccess', planId);
  }

  trackRoomPresence(roomAlias: string, sessionTag = ''): void {
    const tag = sessionTag ? `${sessionTag}_` : sessionTag;
    this.trackMetric(`room:${tag}sessionPresence`, roomAlias);
  }

  trackStartSession(sessionId: string, sessionTag = ''): void {
    const tag = sessionTag ? `${sessionTag}_` : sessionTag;
    this.trackMetric(`room:${tag}sessionStart`, sessionId);
  }

  trackReaction(roomAlias: string) {
    this.trackMetric('room:reaction', roomAlias);
  }

  trackUserRegistration(email: string) {
    this.trackMetric('user:register:success', email);
  }

  trackUserRegistrationAttempt(email: string) {
    this.trackMetric('user:register:attempt', email);
  }

  trackPip(state: boolean) {
    this.trackMetric(state ? 'room:pip-open' : 'room:pip-close', state);
  }

  trackModeratorLeavesRoom(roomAlias: string): void {
    this.trackMetric('room:moderatorLeave', roomAlias);
  }

  trackRoomUnstableConnection(roomAlias: string): void {
    this.trackMetric('room:unstableConnection', roomAlias);
  }

  trackPageView(url: string): void {
    try {
      this.yaTracker.trackPageView(url);
      if (window.gtag) {
        window.gtag('event', 'page_view', { page_location: url });
      }
    } catch (error) {
      logger.error('Failed to track pageview', { error, url });
    }
  }

  dispose() {
    window.removeEventListener('click', this.mouseClickHandler, { capture: true });
  }

  private trackMetric(metricName: string, value: unknown) {
    const isDebugEnabled = getLocalStorageItem('ld_gtm_debug');
    if (!window.gtag && isDebugEnabled) {
      // eslint-disable-next-line no-console
      console.warn('Google Analytics tag not loaded');
      return;
    }

    if (!this.yaTracker.isLoaded() && isDebugEnabled) {
      // eslint-disable-next-line no-console
      console.warn('Yandex Metrika tag not loaded');
      return;
    }

    if (this.yaTracker.isLoaded()) {
      this.yaTracker.sendEvent(metricName, value);
    }

    if (window.gtag) {
      const ga4EventName = metricName.replaceAll(':', '_');
      const payload = { ...window.lsd, value };
      if (isDebugEnabled) {
        // eslint-disable-next-line no-console
        console.log(ga4EventName, payload);
      }

      window.gtag('event', ga4EventName, payload);
    }
  }

  private handleMouseClick(evt: MouseEvent) {
    const target = evt.target as HTMLElement;
    const statsEl = target.closest('*[data-stats]') as HTMLElement;
    if (!statsEl) {
      return;
    }

    const { stats, statsValue, qaEnable } = statsEl.dataset || {};
    if (!stats) {
      return;
    }

    const mapElNameToValuName = {
      ui_room_reaction: 'reaction',
      ui_profile_change_language: 'language',
    };

    type ElementName = keyof typeof mapElNameToValuName;

    const isChecked = (target as HTMLInputElement).checked;

    const payload = {
      ...(isChecked !== undefined && { state: isChecked ? 'on' : 'off' }),
      // the state is inverse because we are capturing event before click handled by toggle buttons
      ...(qaEnable !== undefined && { state: qaEnable === 'false' ? 'on' : 'off' }),
      ...(statsValue !== undefined && { [mapElNameToValuName[stats as ElementName] ?? 'value']: statsValue }),
    };

    const isDebugEnabled = getLocalStorageItem('ld_gtm_debug');
    if (isDebugEnabled) {
      // eslint-disable-next-line no-console
      console.log('Stats', stats, payload, evt);
    }

    if (window.gtag) {
      window.gtag('event', stats, payload);
    }
  }
}

export default MarketingMetrics;
