import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import isMobile from 'is-mobile';
import { observer } from 'mobx-react-lite';

import useStores from 'stores/root';

import {
  DesktopLayout,
  HintContainer,
  HintTitle,
  HintText,
} from '../DesktopLayout';
import { MobileLayout, MobileTitle } from '../MobileLayout';
import { ProfileStatesNames } from '../type';

type AccountMessages = {
  [key in ProfileStatesNames]: { title: string, text: string }
};

const SuccessRequest: FunctionComponent = observer(() => {
  const { uiStore: { userProfileDialogState }, userStore: { email } } = useStores();
  const { t } = useTranslation();
  const messages = {
    CHANGE_EMAIL_REQUEST_SUCCESS: {
      title: t('userProfile.changeEmail'),
      text: t('userProfile.changeEmailHint', { email }),
    },
    CHANGE_PASSWORD_REQUEST_SUCCESS: {
      title: t('userProfile.changePassword'),
      text: t('userProfile.changePasswordHint', { email }),
    },
  } as AccountMessages;

  if (isMobile()) {
    return (
      <MobileLayout>
        <HintContainer>
          <MobileTitle>
            {messages[userProfileDialogState].title}
          </MobileTitle>
          <HintText>
            {messages[userProfileDialogState].text}
          </HintText>
        </HintContainer>
      </MobileLayout>
    );
  }

  return (
    <DesktopLayout>
      <HintContainer>
        <HintTitle>
          {messages[userProfileDialogState].title}
        </HintTitle>
        <HintText>
          {messages[userProfileDialogState].text}
        </HintText>
      </HintContainer>
    </DesktopLayout>
  );
});

export default SuccessRequest;
