import styled from '@emotion/styled';

import { Color } from 'ui-kit/theme';

export const LinearProgressBody = styled.div`
  width: 100%;
  height: 8px;
  background: ${Color.GREY1};
  border-radius: 5px;
  overflow: hidden;
  position: relative;
`;

export const LinearProgressValue = styled.div`
  height: 8px;
  background: lime;
  transition-duration: 0.3s;
  transition-property: width;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
`;
