import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import getIsMobile from 'is-mobile';
import { useTranslation } from 'react-i18next';

import useStores from 'stores/root';
import { FigmaTypography, FlexCenter, FullPageLoader } from 'ui-kit';
import { IUrlParams } from 'components/Space/types';

const MobileAppProfilePage: FunctionComponent = observer(({ children }) => {
  const isMobile = useMemo(() => getIsMobile(), []);

  const {
    spaceStore,
    paymentStore,
    rootStore: { moodHoodApiClient },
  } = useStores();

  const { t } = useTranslation();

  const [spaceError, setSpaceError] = useState<string>();

  const { spaceId: spaceIdFromParams } = useParams<IUrlParams>();

  const spaceIdFromSearch = new URLSearchParams(window.location.search).get('spaceId');

  const spaceId = spaceIdFromParams || spaceIdFromSearch;

  useEffect(() => {
    if (!spaceId) {
      return;
    }

    moodHoodApiClient.space.getSpace(spaceId).then(({ space, error }) => {
      if (space) {
        spaceStore.setSpaceInfo({ space });
      }

      if (error) {
        setSpaceError(error);
      }
    });
  }, [spaceId]);

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    if (spaceStore.space?.id) {
      paymentStore.setCurrentPlan(spaceStore.space.id);
    }
  }, [spaceStore.space]);

  if (!isMobile) {
    return (
      <Redirect to="/" />
    );
  }

  if (spaceError) {
    let text = t('errors.somethingWentWrong');

    if (['not_found', 'Invalid value'].includes(spaceError)) {
      text = t('errors.spaceWithIdNotFound', { spaceId });
    }

    return (
      <FlexCenter style={{ width: '100%', justifyContent: 'center' }}>
        <FigmaTypography center>
          {text}
        </FigmaTypography>
      </FlexCenter>
    );
  }

  if (!spaceStore.space?.id || !paymentStore.currentPlan) {
    return (
      <FullPageLoader />
    );
  }

  return (
    <>
      {children}
    </>
  );
});

export default MobileAppProfilePage;
