import styled from '@emotion/styled';

import { Font, Color } from 'ui-kit/theme';

export const InputBox = styled.textarea(({ theme }) => ({
  display: 'block',
  marginTop: 4,
  minHeight: 111,
  width: '100%',
  padding: '16px 8px',
  fontFamily: Font.BRAND,
  fontSize: 14,
  color: theme.colors.primary,
  background: 'none',
  border: `1px solid ${theme.colors.secondary03}`,
  borderRadius: 10,
  '&:focus': {
    outline: 'none',
    boxShadow: `${theme.colors.accent04} 0px 0px 0px 1px`,
  },
  '&:disabled': {
    color: Color.DARK0,
    backgroundColor: Color.GREY2,
  },
  '&:not(:focus):hover': {
    boxShadow: `${theme.colors.secondary03} 0px 0px 0px 1px`,
  },
}));

export const Label = styled.label(({ theme }) => ({
  color: theme.colors.secondaryBase,
  fontFamily: Font.BRAND_BOLD,
  fontWeight: 700,
  display: 'block',
}));

export const RequiredMark = styled.span({
  color: Color.RED0,
});

const Layout = styled.label({
  fontSize: 14,
});

export default Layout;
