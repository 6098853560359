import styled from '@emotion/styled';

import Badge from '../Badge/Layout';

export const TabsHeaderRoot = styled.div({
  width: '100%',
  overflowX: 'auto',
});

export const Header = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
});

export const Indicator = styled.div<{ left?: number, width?: number }>(({ theme, left = 0, width = 0 }) => ({
  position: 'relative',
  top: -3,
  left,
  width,
  minHeight: 3,
  backgroundColor: theme.colors.accent05,
  transition: 'left 0.2s ease-in-out, width 0.3s 0.10s',
}));

export const HeaderWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  width: '100%',
  borderBottom: `solid ${theme.colors.accent01} 1px`,
}));

export const TabTitle = styled.div<{ current: boolean }>(({ theme, current }) => ({
  display: 'flex',
  flexDirection: 'row',
  lineHeight: '18px',
  padding: '0 12px 8px',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  ...(current && { color: theme.colors.accent05, fontWeight: 700 }),
  [Badge as never]: {
    transition: 'all 0.3s',
    marginLeft: 4,
    '&[data-variant=primary]':
    {
      ...(current && {
        backgroundColor: theme.colors.accentBase,
        color: theme.colors.contrast,
      }),
    },
  },
  '&:first-of-type': {
    paddingLeft: 0,
  },
}));
