import { action, makeAutoObservable, observable } from 'mobx';

import logger from '../helpers/logger';
import AudioMeter from '../services/AudioMeter';

type OnLevelTrackedCallback = (audioLevel: number, audioTrack: MediaStreamTrack) => void;

interface AudioTrackLevelData {
  audioMeter: AudioMeter;
  track: MediaStreamTrack;
  lastAudioLevel: number; // range 0-10
}

class AudioLevelWatcherStore {
  @observable private audioTracksLevelsData: Map<string, AudioTrackLevelData> = new Map();

  constructor() {
    makeAutoObservable(this);
  }

  @action watchAudioLevel(audioTrack: MediaStreamTrack, onLvlTracked?: OnLevelTrackedCallback): void {
    try {
      this.deleteAudioMeter(audioTrack);
      const stream = new MediaStream([audioTrack]);
      const audioMeter = this.createAudioMeter(stream, audioTrack, onLvlTracked);
      this.setAudioTrackLevelData({
        track: audioTrack,
        lastAudioLevel: 0,
        audioMeter,
      });
    } catch (error: unknown) {
      logger.error('Failed to watch audio level', { error, audioTrack });
    }
  }

  @action stopWatchAudioLevel(audioTrack: MediaStreamTrack): void {
    try {
      this.deleteAudioMeter(audioTrack);
    } catch (error: unknown) {
      logger.error('Failed to unwatch audio level', { error, audioTrack });
    }
  }

  @action getAudioLevel(audioTrack: MediaStreamTrack): number {
    const levelData = this.audioTracksLevelsData.get(audioTrack.id);
    return levelData?.lastAudioLevel ?? -1;
  }

  @action private deleteAudioMeter(audioTrack: MediaStreamTrack): boolean {
    const existingData = this.audioTracksLevelsData.get(audioTrack.id);

    if (!existingData) {
      return false;
    }

    existingData.audioMeter.dispose();
    this.deleteAudioTrackLevelData(existingData.track.id);
    return true;
  }

  @action private updateAudioTrackCurrentLevel(trackId: string, currentLevel: number) {
    const levelData = this.audioTracksLevelsData.get(trackId);

    if (!levelData) {
      logger.warn('Could not find audio track level data', { trackId, currentLevel });
      return;
    }

    levelData.lastAudioLevel = currentLevel;
  }

  @action private deleteAudioTrackLevelData(trackId: string) {
    this.audioTracksLevelsData.delete(trackId);
  }

  @action private setAudioTrackLevelData(levelData: AudioTrackLevelData) {
    this.audioTracksLevelsData.set(levelData.track.id, levelData);
  }

  private createAudioMeter(
    stream: MediaStream,
    audioTrack: MediaStreamTrack,
    onLvlTracked?: OnLevelTrackedCallback,
  ): AudioMeter {
    const onLevelTracked = (audioLevel: number) => {
      if (onLvlTracked) {
        onLvlTracked(audioLevel, audioTrack);
      }

      const levelData = this.audioTracksLevelsData.get(audioTrack.id);

      if (!levelData) {
        logger.error('Could not find audio level meter for track', {
          trackId: audioTrack.id,
          level: audioLevel,
          trackLabel: audioTrack.label,
        });
        return;
      }

      if (levelData.lastAudioLevel === audioLevel) {
        return;
      }

      this.updateAudioTrackCurrentLevel(audioTrack.id, audioLevel);
    };

    return new AudioMeter(stream, onLevelTracked);
  }
}

export default AudioLevelWatcherStore;
