import {
  computed, makeAutoObservable, observable, reaction,
} from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';
import { v4 as uuid } from 'uuid';

import { lock } from 'decorators';
import { getLocalStorageItem, setLocalStorageItem } from 'helpers/localStorage';
import { getClientUniqueId } from 'helpers/common';
import RoomApi from 'services/MoodHoodApiClient/RoomApi';
import api from 'services/MoodHoodApiClient/Api';
import {
  CallData, SlotDataType, TrackLabel,
} from 'services/MoodHoodApiClient/types';
import { ChannelEvent } from 'types/socket';
import logger from 'helpers/logger';

import {
  BroadcastDemoData,
  BroadcastDemoLocalStorage,
  BroadcastDemoParams,
  BroadcastSlot,
  Demonstration,
  DemonstrationType,
  DemonstrationUpdatedEvent,
} from '../types';

const BROADCAST_LS_KEY = 'ld-broadcast';
class BroadcastDemo {
  @observable streamKey: string | null = null;

  @observable rtmpLink: string | null = null;

  @observable isBusy = false;

  @observable error: string | null = null;

  @observable slot: BroadcastSlot | null = null;

  @observable isBroadcastInfoDlgVisible = false;

  @observable isBroadcastCreated = false;

  private roomApi = new RoomApi(api);

  private params: BroadcastDemoParams;

  private reactionDisposers: (() => void)[] = [];

  constructor(params: BroadcastDemoParams) {
    this.params = params;
    this.initReactions();
    eventBus.register(this);
    makeAutoObservable(this);
  }

  dispose() {
    this.reactionDisposers.forEach((disposer) => disposer());
    this.reset();
  }

  resetError() {
    this.error = null;
  }

  reset() {
    this.slot = null;
    this.resetError();
    this.streamKey = null;
    this.rtmpLink = null;
    this.isBroadcastCreated = false;
  }

  @lock()
  async startBroadcast() {
    const { room } = this.params;
    const { spaceId, id: roomId } = room;

    if (!spaceId || !roomId) {
      logger.error('Broadcast start: no room info', { case: 'broadcast.start' });
      throw new Error('Broadcast start: no room info');
    }

    const clientUniqueId = getClientUniqueId();

    const { data, error } = await this.roomApi.startBroadcast({ spaceId, roomId, clientUniqueId });

    if (error) {
      this.handleError('room.demo.broadcastStartError');
    }

    if (data) {
      const { rtmpLink, streamKey } = data.privateMeta;
      this.streamKey = data.privateMeta.streamKey;
      this.rtmpLink = data.privateMeta.rtmpLink;
      this.isBroadcastInfoDlgVisible = true;
      const userId = room.myPeer?.userId ?? null;
      setLocalStorageItem(BROADCAST_LS_KEY, {
        started: {
          roomId,
          userId,
          rtmpLink,
          streamKey,
          clientUniqueId,
          demonstrationId: data.startedDemonstrationId,
        },
      });
    }
  }

  @lock()
  async stopBroadcast() {
    const { room } = this.params;
    const { spaceId, id: roomId } = room;

    if (!spaceId || !roomId) {
      logger.error('Broadcast stop: no room info', { case: 'broadcast.stop' });
      throw new Error('Broadcast stop: no room info');
    }

    if (!this.demonstrationId) {
      throw new Error('Broadcast stop: no broadcast slot');
    }

    const { error } = await this.roomApi.stopBroadcast({
      spaceId,
      roomId,
      demonstrationId: this.demonstrationId,
      clientUniqueId: getClientUniqueId(),
    });

    if (error) {
      this.handleError('room.demo.broadcastStopError');
    }

    if (this.canStopBroadcast) {
      setLocalStorageItem(BROADCAST_LS_KEY, undefined);
    }

    this.reset();
  }

  @computed get hasBroadcastSlot() {
    return Boolean(this.slot);
  }

  @computed get hasAvailableDemoSlot() {
    return !this.hasBroadcastSlot;
  }

  @computed get demonstrationId() {
    return this.slot?.slotAssignment.data.id;
  }

  @computed get hasInfo() {
    return this.hasBroadcastSlot && Boolean(this.streamKey && this.rtmpLink);
  }

  @computed get hasOwnerInRoom() {
    if (!this.slot) {
      return true;
    }

    const { room } = this.params;
    return Boolean(room.allPeers.find((peer) => peer.userId === this.slot?.slotAssignment.data.startedUserId));
  }

  @computed get hasNoOwnerInRoomNotice() {
    return !this.hasOwnerInRoom && this.params.room.myPeer?.isModerator;
  }

  @computed get isStartedByMe() {
    const { room: { myPeer } } = this.params;
    if (!myPeer || !myPeer?.userId) {
      return false;
    }

    return this.slot?.slotAssignment.data.startedUserId === myPeer?.userId;
  }

  @computed get canStopBroadcast() {
    const { spaceId, id: roomId, myPeer } = this.params.room;

    if (!spaceId || !roomId) {
      throw new Error('Room info not available');
    }

    if (!this.demonstrationId) {
      return false;
    }

    if (myPeer?.isModerator) {
      return true;
    }

    const data = getLocalStorageItem<BroadcastDemoLocalStorage>(BROADCAST_LS_KEY);

    if (!data) {
      return false;
    }

    const { started } = data;

    return (
      started.userId === myPeer?.userId
      && started.roomId === roomId
      && started.demonstrationId === this.demonstrationId
    );
  }

  private initReactions() {
    const { room } = this.params;
    this.reactionDisposers = [
      reaction(
        () => [room.isRoomJoined, room.isRoomReJoined, room.calls.callData, room.myPeer],
        ([isRoomJoined, isRoomReJoined, callData]) => {
          if ((isRoomJoined || isRoomReJoined) && callData) {
            this.reset();
            this.updateParticipantSlots((callData as CallData).demonstrations);
            this.loadBroadcastInfo();
          }
        },
      ),
    ];
  }

  private loadBroadcastInfo() {
    const data = getLocalStorageItem<BroadcastDemoLocalStorage>(BROADCAST_LS_KEY);

    if (!data || !this.slot || !this.isStartedByMe) {
      return;
    }

    this.streamKey = data.started.streamKey;
    this.rtmpLink = data.started.rtmpLink;
    this.isBroadcastCreated = true;
  }

  private updateParticipantSlots(demonstrations: Demonstration[]) {
    const broadcast = demonstrations.find((demo) => demo.type === DemonstrationType.Broadcast);

    if (!broadcast) {
      return;
    }

    this.addBroadcastSlot(broadcast as BroadcastDemoData);
  }

  private addBroadcastSlot(demo: BroadcastDemoData) {
    if (this.slot) {
      logger.error('Demonstrations already exists', { demo, case: 'broadcast.addBroadcastSlot' });
      throw new Error('Broadcast: already have broadcast');
    }

    const { embedAlias, playLink, streamId } = demo.meta;

    this.slot = {
      peer: null,
      trackLabel: TrackLabel.Broadcast,
      hasVideo: false,
      slotAssignment: {
        trackLabel: TrackLabel.Broadcast,
        slotUuid: uuid(),
        data: {
          type: SlotDataType.Broadcast,
          id: demo.id,
          embedAlias,
          playLink,
          streamId,
          startedUserId: demo.startedUserId,
        },
      },
      isOutOfScreen: false,
    };
  }

  private removeBroadcastSlot(demonstrationId: string) {
    if (this.demonstrationId !== demonstrationId) {
      return;
    }

    this.reset();
  }

  @subscribe(ChannelEvent.DemonstrationsUpdated)
  private handleDemonstrationsUpdate(evt: DemonstrationUpdatedEvent) {
    logger.info('Demonstrations updated', { evt, case: 'broadcast.updated' });
    const { startedDemonstrationId, stoppedDemonstrationId, allDemonstrations } = evt.payload;
    const demoId = startedDemonstrationId ?? stoppedDemonstrationId;
    const demonstration = allDemonstrations.find((demo) => demo.id === demoId);

    if (startedDemonstrationId) {
      if (!demonstration) {
        logger.error('Demonstrations not found', { evt, case: 'broadcast.updated' });
        throw new Error('Broadcast: Demonstration not found');
      }

      if (demonstration.type !== DemonstrationType.Broadcast) {
        return;
      }

      this.addBroadcastSlot(demonstration);
    } else if (stoppedDemonstrationId) {
      this.removeBroadcastSlot(stoppedDemonstrationId);
    }
  }

  private handleError(userError: string) {
    this.error = userError;
  }
}

export default BroadcastDemo;
