import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import isMobile from 'is-mobile';

import useStores from 'stores/root';
import Portal from 'ui-kit/components/Portal';
import { isMobileAppProfile, isPlansPage, isPromoPlansPage } from 'helpers/url';

import Layout, { SupportIcon } from './Layout';

const FloatSupportButton: FunctionComponent = observer(() => {
  const {
    rootStore: { carrotQuest },
    roomStore: { isRoomJoined },
    eventStore: { isEventJoined },
    spaceStore: { space },
  } = useStores();

  const { isReady } = carrotQuest;

  const handleClick = () => {
    carrotQuest.openChat(space?.id);
  };

  const skipConditions = !isReady
    || isRoomJoined
    || isPlansPage()
    || isEventJoined
    || isPromoPlansPage()
    || isMobileAppProfile()
    || carrotQuest.isDisabled();

  if (skipConditions) {
    return null;
  }

  return (
    <Portal>
      <Layout
        id="support-button"
        onClick={handleClick}
        disabled={!isReady}
        isMobile={isMobile()}
      >
        <SupportIcon />
      </Layout>
    </Portal>
  );
});

export default FloatSupportButton;
