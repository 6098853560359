import React, { VoidFunctionComponent, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GetRoomResponse } from 'services/MoodHoodApiClient/RoomApi.types';
import useStores from 'stores/root';

import {
  Dialog,
  FigmaTypography,
  IconButton,
  Loader,
} from 'ui-kit';
import Logo from 'components/Logo';
import { CheckedIcon, InformationIcon } from 'ui-kit/assets/icons';
import {
  AfterRegisterDialogContentRoot,
  Alert,
  AlertMessage,
  BottomBlock,
  CheckList,
  CheckListItem,
  Title,
  TitleText,
  TopBlock,
} from './AfterRegisterDialog.styled';

const AfterRegisterDialog: VoidFunctionComponent<{ room: GetRoomResponse }> = observer(({
  room,
}) => {
  const {
    uiStore,
    spaceStore,
  } = useStores();
  const { t } = useTranslation();

  const [disabled, setDisabled] = useState(true);

  const checkList: string[] = t(
    'afterRegisterPopup.checkList',
    {
      defaultValue: [],
      returnObjects: true,
      roomName: room.name || '',
      spaceName: spaceStore.space?.name || '',
    },
  );

  return (
    <Dialog
      canClose={false}
      title={(
        <Title isCompact={uiStore.isCompact}>
          <Logo brandOnly />
        </Title>
      )}
      isCompact={uiStore.isCompact}
    >
      <AfterRegisterDialogContentRoot isCompact={uiStore.isCompact}>
        <TopBlock>
          <TitleText>
            <FigmaTypography size={uiStore.isCompact ? 'main-text-bold' : 'h2'}>
              {t('afterRegisterPopup.title1')}
              <br />
              {t('afterRegisterPopup.title2')}
            </FigmaTypography>
          </TitleText>
          <CheckList isCompact={uiStore.isCompact}>
            {checkList.map((x, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <CheckListItem key={`checklist-${x}`} style={{ '--animation-order': i } as React.CSSProperties}>
                <CheckedIcon />
                <FigmaTypography size="main-text-medium">
                  {x}
                </FigmaTypography>
              </CheckListItem>
            ))}
          </CheckList>
        </TopBlock>
        <BottomBlock>
          <Alert
            onAnimationEnd={() => setDisabled(false)}
            style={{ '--animation-order': checkList.length } as React.CSSProperties}
          >
            <InformationIcon />
            <AlertMessage size="main-text-medium">
              {t('afterRegisterPopup.alert1')}
              <br />
              {t('afterRegisterPopup.alert2')}
            </AlertMessage>
          </Alert>
          <IconButton
            disabled={!room || disabled}
            fullWidth
            id="after-reg-join-button"
            as={Link}
            to={room.alias ? `/room/${room.alias}` : '#'}
            variant="primary"
          >
            {room.name ? t('afterRegisterPopup.buttonText', { roomName: room.name }) : <Loader height={24} />}
          </IconButton>
        </BottomBlock>
      </AfterRegisterDialogContentRoot>
    </Dialog>
  );
});

export default AfterRegisterDialog;
