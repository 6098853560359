import { AxiosInstance } from 'axios';

import { getAxiosErrorMessage } from 'helpers/errors';

import { apiCall } from 'decorators';
import {
  ApiResponse,
  PaginableRequest,
  PaginableResponse,
} from './types';
import {
  Playback,
  PlaybackTimeline,
} from './Playbacks.types';
import {
  GetPlaybackPayload,
  GetPlaybacksPayload,
  CreatePlaybackPayload,
  UpdatePlaybackPayload,
  RemovePlaybackPayload,
  PlaybackQueryParams,
  PlaybackTimeLineParams,
  PlaybackPreviewResponse,
} from './PlaybacksApi.types';
import { JoinPlaybackEventResponse } from './PlaybackEventsApi.types';

class PlaybacksApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async createPlayback({
    spaceId, name, recordId, type, joinSettingsFields,
  }: CreatePlaybackPayload): ApiResponse<Playback> {
    try {
      const options = {
        name, recordId, type, joinSettingsFields,
      };
      const { data } = await this.api.post<Playback>(`spaces/${spaceId}/playbacks`, options);

      return { data };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async fetchPlaybacks({
    limit,
    offset,
    spaceId,
    recordId,
    name,
  }: PaginableRequest<GetPlaybacksPayload>): ApiResponse<PaginableResponse<Playback>> {
    try {
      const { data } = await this.api.get<PaginableResponse<Playback>>(`spaces/${spaceId}/playbacks`, {
        params: {
          recordId, limit, offset, name,
        },
      });

      return { data };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async fetchPlayback({
    id,
    spaceId,
  }: GetPlaybackPayload): ApiResponse<Playback> {
    try {
      const { data } = await this.api.get<Playback>(`spaces/${spaceId}/playbacks/${id}`);

      return { data };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async fetchPlaybackTimeline({
    id,
    spaceId,
  }: GetPlaybackPayload): ApiResponse<PlaybackTimeline> {
    try {
      const { data } = await this.api.get<PlaybackTimeline>(`spaces/${spaceId}/playbacks/${id}/timeline`);

      return { data };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  @apiCall()
  async deletePlaybackTimeline({
    id,
    spaceId,
  }: PlaybackQueryParams): ApiResponse<void> {
    return this.api.delete(`spaces/${spaceId}/playbacks/${id}/timeline`);
  }

  @apiCall()
  async uploadPlaybackTimeline({
    id,
    spaceId,
    timeline,
  }: PlaybackTimeLineParams): ApiResponse<PlaybackTimeline> {
    return this.api.put(`spaces/${spaceId}/playbacks/${id}/timeline`, {
      ...timeline,
    });
  }

  async removePlayback({
    id,
    spaceId,
  }: RemovePlaybackPayload): ApiResponse<boolean> {
    try {
      await this.api.delete<Playback>(`spaces/${spaceId}/playbacks/${id}`);

      return { data: true };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async updatePlayback({
    id,
    spaceId,
    name,
    joinSettingsFields,
    recordId,
    assetId,
    readinessStatus,
    duration,
  }: UpdatePlaybackPayload): ApiResponse<Playback> {
    try {
      const { data } = await this.api.put<Playback>(`spaces/${spaceId}/playbacks/${id}`, {
        name,
        readinessStatus,
        joinSettingsFields,
        recordId,
        assetId,
        duration,
      });

      return { data };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  @apiCall()
  async previewPlayback({ id, spaceId }: PlaybackQueryParams): ApiResponse<JoinPlaybackEventResponse> {
    const { data } = await this.api.post<PlaybackPreviewResponse>(`/spaces/${spaceId}/playbacks/${id}/preview`);

    return {
      data: {
        ...data,
        id: data.playbackId,
      },
    };
  }
}

export default PlaybacksApi;
