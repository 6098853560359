import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { AppTheme, getStringTextStyle } from 'ui-kit/theme';
import FigmaTypography from 'ui-kit/components/FigmaTypography';

export const customInputStyle = (theme: AppTheme) => css`
  &, &::placeholder {
    ${getStringTextStyle('main-text-medium')}
  }

  &::placeholder {
    color: ${theme.colors.secondary03};
  }

  &:disabled {
      color: ${theme.colors.secondary03};
      background-color: ${theme.colors.secondary01};
  }

  color: ${theme.colors.primary};
  height: 44px;
  padding: 12px 16px;
  width: 100%;
`;

export const customContainerStyle = (theme: AppTheme) => css`
  padding: 1px;
  border: 1px solid var(--border-color, ${theme.colors.secondary03});
  border-radius: 10px;
  overflow: hidden;

  &:focus-within {
    padding: 0px;
    border-width: 2px;
    border-color: var(--border-color, ${theme.colors.accent04});
  }

  &:hover {
    padding: 0px;
    border-width: 2px;
  }
`;

export const Label = styled(FigmaTypography)`
  color: ${({ theme }) => theme.colors.secondaryBase};
  margin-bottom: 4px;
  display: block;
`;
