import styled from '@emotion/styled';
import { getObjectTextStyle } from 'ui-kit/theme';

export const SliderRoot = styled.label(({
  theme: { colors },
}) => ({
  ...getObjectTextStyle('label-text-bold'),
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  userSelect: 'none',
  color: colors.secondaryBase,
  'input[disabled]': {
    opacity: 0.5,
  },
}));

export const MinMaxHint = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
});

export const LabelText = styled.div({
  marginLeft: 2,
});

export const SliderInput = styled.input<{ progress: number }>`
  & {
    -webkit-appearance: none;
    margin: 6px 0;
    width: 100%;
    border-radius: 6px;
  }
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 12px;
    cursor: pointer;
    background: ${({
    theme, progress,
  }) => `linear-gradient(to right, ${theme.colors.accent03} ${progress}%, ${theme.colors.secondary02} ${progress}%)`};
    border-radius: 6px;
    // instead border
    box-shadow: inset 0px 0px 0px 2px ${({ theme }) => theme.colors.contrast};
  }
  &::-webkit-slider-thumb {
    border: 2px solid ${({ theme }) => theme.colors.accent03};
    height: 16px;
    width: 16px;
    border-radius: 8px;
    background: ${({ theme }) => theme.colors.contrast};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -2px;
  }
  &:focus::-webkit-slider-runnable-track {
    background: ${({
    theme, progress,
  }) => `linear-gradient(to right, ${theme.colors.accentBase} ${progress}%, ${theme.colors.secondary02} ${progress}%)`};
  }
  &:focus::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    margin-top: -4px;
    border: 2px solid ${({ theme }) => theme.colors.accentBase};
  }
  &:hover::-webkit-slider-thumb {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    margin-top: -4px;
    border: 2px solid ${({ theme }) => theme.colors.accentBase};
  }
`;
