import {
  action, autorun, computed, makeAutoObservable, observable,
} from 'mobx';

import { LogLevelStr } from '@livedigital/client/dist/types/common';
import { getUserAgentInfo } from 'helpers/browser';
import videoElementsPool from 'services/MediaElementsPool/video';
import audioElementsPool from 'services/MediaElementsPool/audio';

import { getLocalStorageItem, setLocalStorageItem } from 'helpers/localStorage';
import { DEBUG_MODE_LS_KEY } from 'constants/common';
import logger from 'helpers/logger';
import { PeerParticipantInfo, RoomEventData, DebugAudioTrackInfo } from 'types/common';
import { getUrlParam } from 'helpers/url';

import PeerStore from './peer';
import { RootStore } from './root';

const sendDumpsTimeout = 3000;

class DebugStore {
  @observable isDebugger = false;

  @observable debugInfo: Record<string, unknown> = {};

  @observable debugPeerStore?: PeerStore;

  @observable isUserHasDebuggerFlag = false;

  @observable isAudioRemountedForDebug = false;

  private reconnectsCount = 0;

  private webrtcIssueTypes = new Map<string, number>();

  private logLevelsFromSDK = new Map<LogLevelStr, number>();

  private readonly rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);

    autorun(() => {
      const lsDebuggerEnabled = getLocalStorageItem<boolean>(DEBUG_MODE_LS_KEY);
      const isUserDebugger = Boolean(this.rootStore.userStore.isDebugger);
      const qsDebuggerEnabled = getUrlParam('debug') === '1';
      const isDebugger = lsDebuggerEnabled || qsDebuggerEnabled || isUserDebugger;
      this.setIsUserHasDebuggerFlag(isUserDebugger);
      this.setIsDebugger(isDebugger);
    });
  }

  @computed get canReportIssues(): boolean {
    return this.isUserHasDebuggerFlag;
  }

  @computed get reconnectionsCount(): number {
    return this.reconnectsCount;
  }

  @computed get sdkLogLevels(): Record<string, number> {
    return Object.fromEntries(this.logLevelsFromSDK);
  }

  @computed get webrtcIssuesCount(): Record<string, number> {
    return Object.fromEntries(this.webrtcIssueTypes);
  }

  @action setDebugInfo(value: Record<string, unknown>) {
    this.debugInfo = value;
  }

  @action incReconnectsCount() {
    this.reconnectsCount += 1;
  }

  @action pushWebrtcIssueType(issueType: string) {
    const count = this.webrtcIssueTypes.get(issueType);
    this.webrtcIssueTypes.set(issueType, count ? count + 1 : 1);
  }

  @action pushSDKLogLevel(logLevel: LogLevelStr) {
    const count = this.logLevelsFromSDK.get(logLevel);
    this.logLevelsFromSDK.set(logLevel, count ? count + 1 : 1);
  }

  @action private setIsDebugger(value: boolean) {
    this.isDebugger = value;
  }

  @action setIsAudioRemountedForDebug(value: boolean) {
    this.isAudioRemountedForDebug = value;
  }

  @action async toggleDebugMode() {
    const newIsDebuggerValue = !this.isDebugger;
    this.isDebugger = newIsDebuggerValue;
    setLocalStorageItem(DEBUG_MODE_LS_KEY, newIsDebuggerValue);

    if (!this.rootStore.userStore.id) {
      return;
    }

    await this.setUserDebugMode(newIsDebuggerValue);
  }

  @action setDebugPeerStore(peer?: PeerStore) {
    this.debugPeerStore = peer;
  }

  @action setIsUserHasDebuggerFlag(value: boolean) {
    this.isUserHasDebuggerFlag = value;
  }

  async createIssue(description: string): Promise<void> {
    const {
      roomStore: { id: roomId, calls: { activeCall } },
      participantStore: { name: issuerName, id: issuerParticipantId },
    } = this.rootStore;

    if (!roomId || !activeCall || !issuerParticipantId || !this.debugPeerStore) {
      logger.error('createIssue()', { message: 'invalid parameters' });
      return;
    }

    const issueId = await this.rootStore.moodHoodApiClient.issue.createIssue({
      roomId,
      issuerName: issuerName ?? 'unknown',
      description,
      issuerParticipantId,
      callId: activeCall.id,
      defendantPeerId: this.debugPeerStore.id,
      dump: await this.getDump(),
    });

    setTimeout(() => {
      this.sendIssuerDump(issueId);
    }, sendDumpsTimeout);
  }

  async getDump(): Promise<Record<string, unknown>> {
    const {
      roomStore,
      participantStore,
      uiStore: { isParticipantsVideoDisabled, isUserOwnVideoAutoDisabled },
    } = this.rootStore;
    const { reconnectionsCount, sdkLogLevels, webrtcIssuesCount } = this;
    const {
      deviceStore: {
        isDevicesLoaded,
        deviceIsBusy,
        cameraError,
        microphoneError,
        detectDevicesError,
      },
      clientUniqueId,
      id: participantId,
      peer,
    } = participantStore;

    try {
      const { userAgent } = getUserAgentInfo();
      return {
        date: new Date(),
        roomId: roomStore.id,
        spaceId: roomStore.spaceId,
        roomName: roomStore.name,
        participantId,
        peerId: peer?.id,
        clientUniqueId,
        inboundMOS: participantStore.inboundMOS.value,
        outboundMOS: participantStore.outboundMOS.value,
        connectionQuality: roomStore.myPeer?.connectionQuality,
        isParticipantsVideoDisabled,
        isUserOwnVideoAutoDisabled,
        participantName: participantStore.name,
        callId: roomStore.calls.activeCall?.id,
        reconnectionsCount,
        isTabActive: !document.hidden,
        userAgent,
        transportsInfo: await participantStore.client.getTransportsInfo(),
        sdkLogLevels,
        webrtcIssuesCount,
        devicesState: {
          isDevicesLoaded,
          deviceIsBusy,
          cameraError,
          microphoneError,
          detectDevicesError,
        },
        peers: await this.getRoomPeersDump(),
        videoElements: videoElementsPool.dump(),
        audioElements: audioElementsPool.dump(),
      };
    } catch (error) {
      logger.error('Failed to get user dump', { error });
      return {};
    }
  }

  async handleNeedSendDumps(payload: RoomEventData): Promise<void> {
    const { issueId } = payload as { issueId: string };
    await this.sendDefendantDump(issueId);
    setTimeout(() => {
      this.sendDefendantDump(issueId);
    }, sendDumpsTimeout);
  }

  getAudioDOMElementsInfo(): DebugAudioTrackInfo[] {
    const audioNodes = document.querySelectorAll('audio');
    const audioElments = Array.from(audioNodes);
    const { roomAudioTracks } = this.rootStore.roomStore;
    return audioElments.reduce((acc, el) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { sinkId } = (el as any); // browser-specific
      const isAudioElementMuted = el.muted;
      const isAudioElementPaused = el.paused;
      const elVolume = el.volume;
      const stream = el.srcObject as MediaStream;
      const audioTracks = stream?.getAudioTracks() || [];
      audioTracks.forEach((track) => {
        const { muted, enabled, readyState } = track;
        const trackStore = roomAudioTracks.find((ts) => ts.track.id === track.id);
        const { isPaused: tsIsPaused, isMuted: tsIsMuted } = trackStore || {};
        const trackVolume = this.rootStore.audioLevelWatcherStore.getAudioLevel(track);
        const badState = audioTracks.length > 1 || isAudioElementMuted || isAudioElementPaused
         || elVolume === 0 || readyState !== 'live' || sinkId || !enabled || (muted && !tsIsPaused);

        acc.push({
          id: track.id,
          peerName: trackStore?.peerStore.name || 'none',
          isAudioElementMuted,
          isAudioElementPaused,
          elVolume,
          enabled,
          muted,
          readyState,
          tsIsMuted,
          tsIsPaused,
          sinkId: sinkId || 'default',
          label: trackStore?.label || 'none',
          trackVolume,
          badState,
        });
      });

      return acc;
    }, [] as DebugAudioTrackInfo[]);
  }

  private async getRoomPeersDump(): Promise<PeerParticipantInfo[]> {
    return Promise
      .all((this.rootStore.roomStore.allPeers.map((peer) => peer.getInfo())));
  }

  private async sendIssuerDump(issueId: string): Promise<void> {
    try {
      await this.rootStore.moodHoodApiClient.issue.pushIssuerDump({
        id: issueId,
        dump: await this.getDump(),
      });
    } catch (error) {
      logger.error('Failed to send issuer dump', { error, issueId });
    }
  }

  private async sendDefendantDump(issueId: string): Promise<void> {
    try {
      await this.rootStore.moodHoodApiClient.issue.pushDefendantDump({
        id: issueId,
        dump: await this.getDump(),
      });
    } catch (error) {
      logger.error('Failed to send defendant dump', { error, issueId });
    }
  }

  private async setUserDebugMode(value: boolean) {
    try {
      await this.rootStore.moodHoodApiClient.user.updateUserProfile({ isDebugger: value });
      this.setIsUserHasDebuggerFlag(value);
    } catch (error) {
      logger.error('Failed to set user debug mode', { error });
    }
  }
}

export default DebugStore;
