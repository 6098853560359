import React, {
  FunctionComponent, useEffect, useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import DatePicker from 'react-multi-date-picker';
import reactMultiDatePickerRuLocale from 'react-multi-date-picker-ru-locale';
import { useTranslation } from 'react-i18next';
import DateObject from 'react-date-object';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { endOfDay, startOfDay } from 'date-fns';

import {
  Badge, FigmaTypography, IconButton, Loader, Tab, Tabs,
} from 'ui-kit';
import {
  ArrowLeftIcon, EditNameIcon, PlayIcon,
} from 'ui-kit/assets/icons';
import { SessionsProviderSource } from 'modules/PlaybackEventSessions/types';
import PlaybackSessions from 'modules/PlaybackEventSessions';
import useStores from 'stores/root';
import timeZones from 'services/i18n/timeZones';

import { TimeZoneLocale } from 'services/MoodHoodApiClient/StaticApi.types';
import Layout, {
  Playback, PlaybackInfo, PlaybackSchedule, PlaybackTitle,
} from './PlaybackCard.styled';
import DatePickerButton from './DatePickerButton';
import PlaybackEventSessionsList from '../Lists/PlaybackEventSessionsList';
import CalendarButton from './CalendarButton';
import SortOrderButton from './SortOrderButton';

interface PlaybackSessionsCardProps {
  playbackId: string,
}

const PlaybackSessionsCard: FunctionComponent<PlaybackSessionsCardProps> = observer(({ playbackId }) => {
  const playbackSessions = PlaybackSessions.getInstance();
  const { playback, sessionsProvider } = playbackSessions;
  const { t } = useTranslation();
  const { userStore } = useStores();
  const [dateRange, setDateRange] = useState<DateObject[]>([]);

  const handleDateChange = (selectedDates: DateObject[]) => {
    if (Array.isArray(selectedDates)) {
      const startDate = selectedDates[0] ? selectedDates[0].toDate() : new Date();
      const endDate = selectedDates[1] ? selectedDates[1].toDate() : null;
      const date = {
        startDate: startOfDay(startDate),
        endDate: endOfDay(endDate || startDate),
      };
      setDateRange(selectedDates);
      sessionsProvider?.setDateRange(date);
      sessionsProvider?.load();
    }
  };

  const handleDatePickerClose = () => {
    sessionsProvider?.setDateRange(null);
    sessionsProvider?.load();
  };

  const handleTabChange = (index: number, value?: string) => {
    if (!value) {
      return;
    }

    sessionsProvider?.setSource(value as SessionsProviderSource);
    sessionsProvider?.load();
  };

  const handleDownloadAnalyticsReport = (playbackEventId: string, playbackEventSessionId: string) => {
    playbackSessions.downloadAnalyticsReport(playbackEventId, playbackEventSessionId);
  };

  const handleOrderChange = (order: 'asc' | 'desc') => {
    sessionsProvider?.setSortOrder(order);
    sessionsProvider?.load();
  };

  useEffect(() => {
    playbackSessions.load(playbackId);

    return () => { playbackSessions.dispose(); };
  }, []);

  useEffect(() => {
    timeZones.fetchTimeZones(userStore.currentLanguage as TimeZoneLocale);
  }, [userStore.currentLanguage]);

  const lang = userStore.currentLanguage;

  if (!playback || !sessionsProvider) {
    return <Loader centered size={48} />;
  }

  const { counters } = playback;

  return (
    <Layout>
      <Helmet title={playback.name} />
      <Playback>
        <IconButton
          as={Link}
          size="large"
          id="events-back"
          icon={<ArrowLeftIcon />}
          to={`/space/${playback.spaceId}/playbacks`}
        />
        <PlaybackInfo>
          <PlaybackTitle size="h3">{playback.name}</PlaybackTitle>
          <PlaybackSchedule>
            <FigmaTypography>{t('common.schedule')}</FigmaTypography>
            <Badge>
              {t('admin.playbacks.withRepeating', { value: counters.recurring })}
            </Badge>
            <Badge>
              {t('admin.playbacks.withoutRepeating', { value: counters.nonRecurring })}
            </Badge>
            <Badge>
              {t('admin.playbacks.constant', { value: counters.persistent })}
            </Badge>
          </PlaybackSchedule>
        </PlaybackInfo>
        <DatePicker
          range
          weekStartDayIndex={1}
          value={dateRange}
          locale={lang === 'ru' ? reactMultiDatePickerRuLocale : undefined}
          render={<CalendarButton />}
          onChange={handleDateChange}
        />
        <IconButton
          as={Link}
          size="large"
          id="edit-playback"
          icon={<EditNameIcon />}
          text={t('events.edit')}
          to={`/space/${playback.spaceId}/playback/${playbackId}/edit`}
        />
        <IconButton
          id="preview-playback"
          size="large"
          icon={<PlayIcon />}
          text={t('common.preview')}
          as={Link}
          target="_blank"
          to={`/space/${playback.spaceId}/playback/${playback.id}/preview`}
        />
      </Playback>
      {sessionsProvider.dateRange && (
        <DatePicker
          range
          weekStartDayIndex={1}
          value={dateRange}
          locale={lang === 'ru' ? reactMultiDatePickerRuLocale : undefined}
          render={<DatePickerButton onClose={handleDatePickerClose} />}
          onChange={handleDateChange}
        />
      )}
      <Tabs
        onTabChange={handleTabChange}
        controls={<SortOrderButton onClick={handleOrderChange} disabled={sessionsProvider.isBusy} />}
        disabled={sessionsProvider.isBusy}
      >
        <Tab
          id="tab-running-now"
          data-value="sessions-running"
          header={(
            <FigmaTypography size="main-text-medium">{t('events.runningNow')}</FigmaTypography>
          )}
        />
        <Tab
          id="tab-not-started"
          data-value="schedule"
          header={(
            <FigmaTypography size="main-text-medium">{t('events.notStarted')}</FigmaTypography>
          )}
        />
        <Tab
          id="tab-completed"
          data-value="sessins-completed"
          header={(
            <FigmaTypography size="main-text-medium">{t('events.completed')}</FigmaTypography>
          )}
        />
      </Tabs>
      <PlaybackEventSessionsList
        onDownloadAnalyticsReport={handleDownloadAnalyticsReport}
        dataProvider={sessionsProvider}
      />
    </Layout>
  );
});

export default PlaybackSessionsCard;
