import styled from '@emotion/styled';
import { Virtuoso } from 'react-virtuoso';
import { IconButton } from 'ui-kit';

export const VirtualList = styled(Virtuoso)({
  flex: '1 1 auto',
  overflowX: 'hidden',
});

export const ListContainerRoot = styled.div(({ theme }) => ({
  width: '100%',
  height: '100%',
  color: theme.colors.primary,
}));

export const JoinButton = styled(IconButton)({
  minWidth: 194,
});
