import React, { FunctionComponent, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import useStores from 'stores/root';

import {
  UserImage,
} from '../DesktopLayout';

interface UserAvatarWithUploaderProps {
  userName: string;
}

const UserAvatarWithUploader: FunctionComponent<UserAvatarWithUploaderProps> = observer(({
  userName,
}) => {
  const {
    uiStore,
    userStore,
    authStore,
    roomStore,
    participantStore,
  } = useStores();
  const { t } = useTranslation();

  useEffect(() => {
    if (userStore.userUpdatingError) {
      toast.error(t('admin.assets.uploadFail'));
      userStore.removeUserAvatar();
    }
  }, [userStore.userUpdatingError]);

  const onImageCropperOpen = () => {
    uiStore.setIsUserProfileVisible(false);
  };

  const onImageCropperCloseWithSaving = () => {
    uiStore.setIsUserProfileVisible(true);
  };

  const onFinish = (publicUrl: string) => {
    userStore.updateUser({ image: publicUrl });
    if (roomStore.spaceId) {
      participantStore.updateImage(publicUrl);
    }

    toast.success(t('userProfile.profilePhotoChanged'));
  };

  const onRemoveImage = () => {
    userStore.removeUserAvatar();
    if (roomStore.spaceId) {
      participantStore.updateImage(null);
    }

    toast.success(t('userProfile.profilePhotoRemoved'));
  };

  const validUserName = userName.trim().length === 0 ? userStore.username : userName;

  if (!validUserName) {
    return null;
  }

  return (
    <UserImage
      id="change-photo-btn"
      isUploadingAllowed={authStore.isUser}
      name={validUserName}
      image={userStore.image}
      isUploading={userStore.isUserUpdating}
      onCropperOpen={onImageCropperOpen}
      onSaveCroppedImage={onImageCropperCloseWithSaving}
      onGetPublicUrl={onFinish}
      onRemoveImage={onRemoveImage}
      kindOfImages="avatars"
      openImageCropper={(img: string | ArrayBuffer | null) => uiStore.openUserAvatarCropper(img)}
    />
  );
});

export default UserAvatarWithUploader;
