import React, {
  FunctionComponent, useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Input from 'ui-kit/new-components/Input';
import { VisualState } from 'ui-kit/new-components/Input/types';
import { CheckedIcon } from 'ui-kit/assets/icons';
import useStores from 'stores/root';

import { ReferralCodeRoot, CodeApplied, ApplyButton } from './index.styled';

const ReferralCode: FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const { userStore } = useStores();
  const [referralCode, setReferralCode] = useState('');
  const [codeApplied, setCodeApplied] = useState(false);
  const [codeState, setCodeState] = useState<VisualState>('default');
  const [isBusy, setIsBusy] = useState(false);

  const handleAppyCode = async () => {
    setIsBusy(true);
    const { error } = await userStore.updateUser({
      referralCode,
    });

    if (error) {
      toast.error(t('errors.somethingWentWrong'));
      return;
    }

    setCodeApplied(true);
    setIsBusy(false);
  };

  const handleChangeCode = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const code = evt.target.value;
    setReferralCode(code);
    if (!code) {
      setCodeState('default');
      return;
    }

    if (code.length > 100) {
      setCodeState('error');
      return;
    }

    setCodeState(code.match(/^[a-z0-9]+$/i) ? 'success' : 'error');
  };

  const handleClearCode = () => {
    setReferralCode('');
    setCodeState('default');
  };

  const button = codeApplied ? (
    <CodeApplied>
      <CheckedIcon />
    </CodeApplied>
  ) : (
    <ApplyButton
      id="apply-code"
      text={t('referralCode.apply')}
      variant="primary"
      size="large"
      disabled={!referralCode || codeState === 'error' || isBusy}
      onClick={handleAppyCode}
    />
  );

  return (
    <ReferralCodeRoot>
      <Input
        id="referal-code"
        label={t('referralCode.enterCode')}
        placeholder={t('referralCode.codePlaceholder')}
        onChange={handleChangeCode}
        onClear={handleClearCode}
        state={codeState}
        hint={codeState === 'error' ? t('referralCode.validationError') : undefined}
        disabled={codeApplied || isBusy}
        variant="large"
        asideControls={button}
        fullWidth
        showClearButton
      />
    </ReferralCodeRoot>
  );
});

export default ReferralCode;
