import { ReactComponent as IconLight } from './icon-tb-light.svg';
import { ReactComponent as IconDark } from './icon-tb-dark.svg';
import { ReactComponent as LogoLight } from './logo-tb-light.svg';
import { ReactComponent as LogoDark } from './logo-tb-dark.svg';
import { ReactComponent as LogoMaxLight } from './logo-tb-max-light.svg';
import { ReactComponent as LogoMaxDark } from './logo-tb-max-dark.svg';

export const TelebossImages = {
  IconLight,
  IconDark,
  LogoLight,
  LogoDark,
  LogoMaxLight,
  LogoMaxDark,
};
