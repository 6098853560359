import styled from '@emotion/styled';

export const ArrowDown = styled.div({
  display: 'flex',
  alignItems: 'center',
  '&&& > svg': {
    width: 8,
    height: 4,
  },
});

export const IndicatorWrapper = styled.div({
  position: 'absolute',
  top: 4,
  right: 4,
  display: 'flex',
  height: 'calc(100% - 8px)',
});

export const ButtonWrapper = styled.div({
  position: 'relative',
  '&>div:not([id="indicator-wrapper"]):first-of-type>button, &>button:first-of-type': {
    paddingRight: 36,
  },
});
