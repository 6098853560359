import React, { FunctionComponent } from 'react';
import { toast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainers } from 'constants/common';
import { Theme } from '@emotion/react';

import {
  StyledChatSnackbarContainer,
} from './Layout';
import { getSnackbarByType } from './helpers';

const ChatSnackbarContainer: FunctionComponent<{ theme: Theme }> = ({ theme }) => (
  <StyledChatSnackbarContainer
    position="bottom-left"
    containerId={ToastContainers.Chat}
    icon={false}
    hideProgressBar
    enableMultiContainer
    appTheme={theme}
    limit={1}
  />
);

export const commonSnackbarParams: ToastOptions = {
  containerId: ToastContainers.Chat,
  closeButton: false,
  autoClose: 5000,
};

const snackbarInfo = getSnackbarByType('info', commonSnackbarParams);

const snackbarWarn = getSnackbarByType('warning', commonSnackbarParams);

const snackbarError = getSnackbarByType('error', commonSnackbarParams);

const snackbarSuccess = getSnackbarByType('success', commonSnackbarParams);

export default {
  snackbarInfo,
  snackbarWarn,
  snackbarError,
  snackbarSuccess,
  ChatSnackbarContainer,
  dismissSnackbar: toast.dismiss,
};
