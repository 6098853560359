import { getBrandDomain } from 'helpers/url';
import { PaymentPlan } from 'constants/payment';

import { LivedigitalImages } from 'modules/Branding/livedigital';
import { MoodhoodImages } from 'modules/Branding/moodhood';
import { TelebossImages } from 'modules/Branding/teleboss';

import { BrandOptions } from './types';

const brands: Record<string, BrandOptions> = {
  'livedigital.space': {
    name: 'common.brand.livedigital',
    company: 'common.company.livedigital',
    pageTitle: 'common.pageTitle.livedigital',
    PageBack: LivedigitalImages.PageBack,
    IconLight: LivedigitalImages.IconLight,
    IconDark: LivedigitalImages.IconDark,
    LogoLight: LivedigitalImages.LogoLight,
    LogoDark: LivedigitalImages.LogoDark,
    LogoMaxLight: LivedigitalImages.LogoMaxLight,
    LogoMaxDark: LivedigitalImages.LogoMaxDark,
    planDescriptions: {
      [PaymentPlan.free]: {
        name: 'Basic',
        alias: PaymentPlan.free,
        title: 'Basic',
        priceHintMonthly: 'payment.freePlan.priceHintMonthly',
        priceHintAnnual: 'payment.freePlan.priceHintAnnual',
        mainFeatures: 'payment.freePlan.mainFeatures',
        extraFeaturesTitle: 'payment.freePlan.extraFeaturesTitle',
        extraFeatures: 'payment.freePlan.extraFeatures',
        buyButtonText: 'payment.freePlan.buyButton',
      },
      [PaymentPlan.enterprise_feb17]: {
        name: 'Enterprise',
        alias: PaymentPlan.enterprise_feb17,
        title: 'payment.enterprisePlan.title',
        priceHintMonthly: 'payment.enterprisePlan.priceHintMonthly',
        priceHintAnnual: 'payment.enterprisePlan.priceHintAnnual',
        mainFeatures: 'payment.enterprisePlan.mainFeatures',
        extraFeaturesTitle: 'payment.enterprisePlan.extraFeaturesTitle',
        extraFeatures: 'payment.enterprisePlan.extraFeatures',
        buyButtonText: 'payment.enterprisePlan.buyButton',
      },
    },
    plansCanBeProlonged: [
    ],
    onlyThroughSupport: [
      PaymentPlan.enterprise_feb17,
    ],
    freePlanAlias: PaymentPlan.free,
  },
  'moodhood.online': {
    name: 'common.brand.moodhood',
    company: 'common.company.moodhood',
    pageTitle: 'common.pageTitle.moodhood',
    IconLight: MoodhoodImages.IconLight,
    IconDark: MoodhoodImages.IconDark,
    LogoLight: MoodhoodImages.LogoLight,
    LogoDark: MoodhoodImages.LogoDark,
    LogoMaxLight: MoodhoodImages.LogoMaxLight,
    LogoMaxDark: MoodhoodImages.LogoMaxDark,
    planDescriptions: {
      [PaymentPlan.free]: {
        name: 'Free',
        alias: PaymentPlan.free,
        title: 'Free',
        priceHintMonthly: 'payment.freePlan.priceHintMonthly',
        priceHintAnnual: 'payment.freePlan.priceHintAnnual',
        mainFeatures: 'payment.freePlan.mainFeatures',
        extraFeaturesTitle: 'payment.freePlan.extraFeaturesTitle',
        extraFeatures: 'payment.freePlan.extraFeatures',
        buyButtonText: 'payment.freePlan.buyButton',
      },
      [PaymentPlan.personal_nov1323_1m]: {
        name: 'Personal',
        alias: PaymentPlan.personal_nov1323_1m,
        annualAlias: PaymentPlan.personal_nov1323_12m,
        title: 'payment.personalPlan.title',
        priceHintMonthly: 'payment.personalPlan.priceHintMonthly',
        priceHintAnnual: 'payment.personalPlan.priceHintAnnual',
        mainFeatures: 'payment.personalPlan.mainFeatures',
        extraFeaturesTitle: 'payment.personalPlan.extraFeaturesTitle',
        extraFeatures: 'payment.personalPlan.extraFeatures',
        buyButtonText: 'payment.personalPlan.buyButton',
      },
      [PaymentPlan.team_nov1323_1m]: {
        name: 'Team',
        alias: PaymentPlan.team_nov1323_1m,
        annualAlias: PaymentPlan.team_nov1323_12m,
        promoAlias: PaymentPlan.team_newyear23_1m,
        promoAnnualAlias: PaymentPlan.team_newyear23_12m,
        title: 'payment.teamPlan.title',
        priceHintMonthly: 'payment.teamPlan.priceHintMonthly',
        priceHintAnnual: 'payment.teamPlan.priceHintAnnual',
        mainFeatures: 'payment.teamPlan.mainFeatures',
        extraFeaturesTitle: 'payment.teamPlan.extraFeaturesTitle',
        extraFeatures: 'payment.teamPlan.extraFeatures',
        buyButtonText: 'payment.teamPlan.buyButton',
        planHint: 'payment.teamPlan.planHint',
      },
      [PaymentPlan.business_nov1323_1m]: {
        name: 'Business',
        alias: PaymentPlan.business_nov1323_1m,
        annualAlias: PaymentPlan.business_nov1323_12m,
        title: 'payment.businessPlan.title',
        priceHintMonthly: 'payment.businessPlan.priceHintMonthly',
        priceHintAnnual: 'payment.businessPlan.priceHintAnnual',
        mainFeatures: 'payment.businessPlan.mainFeatures',
        extraFeaturesTitle: 'payment.businessPlan.extraFeaturesTitle',
        extraFeatures: 'payment.businessPlan.extraFeatures',
        buyButtonText: 'payment.businessPlan.buyButton',
      },
      [PaymentPlan.enterprise_feb17]: {
        name: 'Enterprise',
        alias: PaymentPlan.enterprise_feb17,
        title: 'payment.enterprisePlan.title',
        priceHintMonthly: 'payment.enterprisePlan.priceHintMonthly',
        priceHintAnnual: 'payment.enterprisePlan.priceHintAnnual',
        mainFeatures: 'payment.enterprisePlan.mainFeatures',
        extraFeaturesTitle: 'payment.enterprisePlan.extraFeaturesTitle',
        extraFeatures: 'payment.enterprisePlan.extraFeatures',
        buyButtonText: 'payment.enterprisePlan.buyButton',
      },
    },
    plansCanBeProlonged: [
      PaymentPlan.personal_nov1323_1m,
      PaymentPlan.personal_nov1323_12m,

      PaymentPlan.business_nov1323_1m,
      PaymentPlan.business_nov1323_12m,

      PaymentPlan.team_nov1323_1m,
      PaymentPlan.team_nov1323_12m,
    ],
    freePlanAlias: PaymentPlan.free,
    onlyThroughSupport: [
      PaymentPlan.enterprise_feb17,
    ],
  },
  'teleboss.ru': {
    name: 'common.brand.teleboss',
    company: 'common.company.teleboss',
    pageTitle: 'common.pageTitle.teleboss',
    IconLight: TelebossImages.IconLight,
    IconDark: TelebossImages.IconDark,
    LogoLight: TelebossImages.LogoLight,
    LogoDark: TelebossImages.LogoDark,
    LogoMaxLight: TelebossImages.LogoMaxLight,
    LogoMaxDark: TelebossImages.LogoMaxDark,
    planDescriptions: {
      [PaymentPlan.teleboss_free]: {
        name: 'Free',
        alias: PaymentPlan.teleboss_free,
        title: 'Free',
        priceHintMonthly: 'payment.freePlan.priceHintMonthly',
        priceHintAnnual: 'payment.freePlan.priceHintAnnual',
        mainFeatures: 'payment.freePlan.mainFeatures',
        extraFeaturesTitle: 'payment.freePlan.extraFeaturesTitle',
        extraFeatures: 'payment.freePlan.extraFeatures',
        buyButtonText: 'payment.freePlan.buyButton',
      },
      [PaymentPlan.teleboss_personal_jan01_1m]: {
        name: 'Personal',
        alias: PaymentPlan.teleboss_personal_jan01_1m,
        annualAlias: PaymentPlan.teleboss_personal_jan01_12m,
        title: 'payment.personalTelebossPlan.title',
        priceHintMonthly: 'payment.personalTelebossPlan.priceHintMonthly',
        priceHintAnnual: 'payment.personalTelebossPlan.priceHintAnnual',
        mainFeatures: 'payment.personalTelebossPlan.mainFeatures',
        extraFeaturesTitle: 'payment.personalTelebossPlan.extraFeaturesTitle',
        extraFeatures: 'payment.personalTelebossPlan.extraFeatures',
        buyButtonText: 'payment.personalTelebossPlan.buyButton',
      },
      [PaymentPlan.teleboss_enterprise]: {
        name: 'Enterprise',
        alias: PaymentPlan.teleboss_enterprise,
        title: 'payment.enterpriseTelebossPlan.title',
        priceHintMonthly: 'payment.enterpriseTelebossPlan.priceHintMonthly',
        priceHintAnnual: 'payment.enterpriseTelebossPlan.priceHintAnnual',
        mainFeatures: 'payment.enterpriseTelebossPlan.mainFeatures',
        extraFeaturesTitle: 'payment.enterpriseTelebossPlan.extraFeaturesTitle',
        extraFeatures: 'payment.enterpriseTelebossPlan.extraFeatures',
        buyButtonText: 'payment.enterpriseTelebossPlan.buyButton',
      },
    },
    plansCanBeProlonged: [
      PaymentPlan.teleboss_personal_jan01_1m,
      PaymentPlan.teleboss_personal_jan01_12m,
    ],
    freePlanAlias: PaymentPlan.teleboss_free,
    onlyThroughSupport: [
      PaymentPlan.teleboss_enterprise,
    ],
  },
};

export const getBrandOptions = (): BrandOptions => {
  const appDomain = getBrandDomain();

  if (!appDomain || !brands[appDomain]) {
    throw new Error('Unknown Domain');
  }

  return brands[appDomain];
};
