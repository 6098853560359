import React, { FunctionComponent, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/css';

import useStores from 'stores/root';
import { IconButton } from 'ui-kit';
import { IconButtonProps } from 'ui-kit/components/IconButton/types';
import { MediaBreakPoint } from 'ui-kit/theme';
import { Link } from 'react-router-dom';

type PaymentPlansButtonProps = Omit<IconButtonProps, 'id'> & {
  className?: string,
  hideAtBreakpoint?: MediaBreakPoint,
};

const PaymentPlansButton: FunctionComponent<PaymentPlansButtonProps> = observer(({
  text,
  children,
  fullWidth,
  hideAtBreakpoint,
  ...restProps
}) => {
  const {
    spaceStore: { space },
    paymentStore,
    authStore,
    uiStore: { isCompact },
  } = useStores();
  const { t } = useTranslation();

  useEffect(() => {
    if (space) {
      paymentStore.setCurrentPlan(space.id);
    }
  }, [space?.isSubscriptionActive, space?.planId]);

  if (!authStore.isUser || !space) {
    return null;
  }

  const plansUrl = `/space/${space.id}/plans`;

  return (
    <IconButton
      as={Link}
      to={plansUrl}
      fullWidth={fullWidth !== undefined ? fullWidth : true}
      id="tariffs"
      size={isCompact ? 'small' : 'large'}
      text={text || `${t('payment.plan')}: ${paymentStore.currentPlan?.name || ''}`}
      className={hideAtBreakpoint ? css`
        ${hideAtBreakpoint} {
          &&& {
            display: none;
          }
        }
      ` : undefined}
      {...restProps}
    >
      {children}
    </IconButton>
  );
});

export default PaymentPlansButton;
