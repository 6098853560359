import React, { FunctionComponent } from 'react';
import { createCtx } from 'helpers/common';

interface TooltipSettings {
  tooltipsEnabled: boolean,
}

interface TooltipContext {
  tooltipsSettings: TooltipSettings,
  setTooltipsSettings: (settings: TooltipSettings) => void,
}

const [useTooltipsContext, Provider] = createCtx<TooltipContext>();

export const TooltipsProvider: FunctionComponent = ({ children }) => {
  const [tooltipsSettings, setTooltipsSettings] = React.useState<TooltipSettings>({ tooltipsEnabled: true });

  return (
    <Provider value={{ tooltipsSettings, setTooltipsSettings }}>
      {children}
    </Provider>
  );
};

export default useTooltipsContext;
