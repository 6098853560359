import React, {
  FunctionComponent, useRef, useEffect, useCallback, useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';
import { cx } from '@emotion/css';

import Portal from 'ui-kit/components/Portal';
import useClickOutside from 'hooks/useClickOutside';
import { toCssPx } from 'helpers/common';

import {
  Rect,
  Alignment,
  PopupProps,
  AlignmentOptions,
} from './types';
import Layout, { Cover } from './Layout';
import { getDimensionsByAnchor } from './helpers';

const calcPosition = ({
  popup,
  anchor,
  alignment,
  alignmentOptions,
  snackbarOffset = 15,
}: {
  popup:HTMLElement | null,
  anchor:HTMLElement | null,
  alignment: Alignment,
  alignmentOptions: AlignmentOptions,
  snackbarOffset: number | undefined,
}): Rect | null => {
  if (!popup) {
    return null;
  }
  /* TODO: alignment option */
  const popupRect = popup.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();

  if (anchor) {
    const res = getDimensionsByAnchor(anchor, alignment, popupRect, bodyRect, alignmentOptions);
    return res;
  }

  if (popup.parentElement) {
    const parent = popup.parentElement;
    const viewRect = parent?.getBoundingClientRect();
    if (alignment === 'top' || alignment === 'bottom') { // no anchor element and has aligment - this is snackbar
      const top = alignment === 'top' ? snackbarOffset : viewRect.height - popupRect.height - snackbarOffset;
      const left = 0;
      const right = 0;
      const { width } = popupRect;
      return {
        left, top, right, width, // makes it centered even with window resize
      };
    }

    if (alignment === 'left-bottom') {
      const top = viewRect.height - popupRect.height - snackbarOffset;
      const left = snackbarOffset;
      const { width } = popupRect;
      return {
        left, top, width, // makes it centered even with window resize
      };
    }

    if (alignment === 'center') {
      const top = viewRect.height / 2 - popupRect.height / 2;
      const left = viewRect.width / 2 - popupRect.width / 2;
      const { width } = popupRect;
      return {
        left, top, width, // makes it centered even with window resize
      };
    }
  }

  return null;
};

const Popup: FunctionComponent<PopupProps> = ({
  children,
  isVisible,
  onClose,
  anchor,
  hostElementId,
  snackbarOffset,
  position = null,
  alignment = 'right',
  closeOnClick = true,
  withBackdrop = true,
  alignmentOptions = {},
  className,
  animationDurationMs = 100,
  inAnimationName = 'animate__fadeIn',
  outAnimationName = 'animate__fadeOut',
  ...restProps
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [popupDimensions, setPopupDimensions] = useState<Rect | null>(null);

  const updatePopupDimensions = () => {
    if (position) {
      setPopupDimensions(position);
      return;
    }

    const newPosition = calcPosition({
      popup: ref.current,
      anchor: anchor?.current ?? null,
      alignment,
      alignmentOptions,
      snackbarOffset,
    });

    setPopupDimensions(newPosition);
  };

  useClickOutside(ref, () => {
    if (anchor?.current) {
      anchor.current.removeAttribute('popup-open');
    }

    onClose?.();
  }, !isVisible);

  const iid = useRef<ReturnType<typeof setInterval> | undefined | null>();

  useEffect(() => {
    if (isVisible) {
      iid.current = setInterval(() => {
        if (isVisible) {
          updatePopupDimensions();
        }
      }, 100);
    }

    return () => {
      if (iid.current) {
        clearInterval(iid.current);
      }
    };
  }, [isVisible]);

  useEffect(() => {
    if (isVisible) {
      if (anchor?.current) {
        anchor.current.setAttribute('popup-open', '1');
      }

      updatePopupDimensions();
    }
  }, [isVisible]);

  const handleMount = useCallback((el) => {
    ref.current = el;
    updatePopupDimensions();
  }, [alignment]);

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    if (closeOnClick) {
      onClose?.();
    }
  }, [closeOnClick, onClose]);

  const handleCoverClick = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <Portal hostElementId={hostElementId}>
      {withBackdrop && isVisible && (
        <Cover onClick={handleCoverClick} />
      )}
      <CSSTransition
        in={isVisible}
        mountOnEnter
        unmountOnExit
        timeout={animationDurationMs}
        classNames={{
          enter: 'animate__animated',
          enterActive: `animate__animated ${inAnimationName}`,
          enterDone: 'animate__animated',
          exit: 'animate__animated',
          exitActive: `animate__animated ${outAnimationName}`,
          exitDone: 'animate__animated',
        }}
      >
        <Layout
          ref={handleMount}
          className={cx('popup', className)} // don't remove popup classname it's needed for useClickOutside
          onClick={handleClick}
          duration={animationDurationMs}
          style={{
            left: toCssPx(popupDimensions?.left),
            top: toCssPx(popupDimensions?.top),
            right: toCssPx(popupDimensions?.right),
            bottom: toCssPx(popupDimensions?.bottom),
            width: toCssPx(popupDimensions?.width),
            height: toCssPx(popupDimensions?.height),
          }}
          {...restProps}
        >
          {children}
        </Layout>
      </CSSTransition>
    </Portal>
  );
};

export default Popup;
