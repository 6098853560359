import styled, { CSSObject } from '@emotion/styled';

import { MediaBreakPoint } from 'ui-kit/theme';
import { FigmaTypography, FigmaTypographyBR } from 'ui-kit';
import { isMobileSafari } from 'helpers/browser';

const textOverflowEllipsis: CSSObject = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const Layout = styled.div<{ inPopup?: boolean }>(({ theme, inPopup }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  maxWidth: 376,
  minWidth: isMobileSafari() ? 'min-content' : 216,
  ...(!inPopup && {
    width: 270,
    padding: 16,
    border: `1px solid ${theme.colors.secondary02}`,
  }),
  borderRadius: 10,
  color: theme.colors.primary,
  transition: 'color 0.3s, background 0.3s !important',
  overflow: 'hidden',
}));

export const Header = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const Title = styled(FigmaTypography)<{ inPopup?: boolean }>(({ theme, inPopup }) => ({
  color: theme.colors.accentBase,
  ...(inPopup && {
    color: 'inherit',
    margin: '8px 0',
  }),
  [MediaBreakPoint.WIDTH_640]: {
    fontSize: '16px',
    lineHeight: '20px',
  },
}));

export const PriceContainer = styled.div({
  display: 'flex',
  alignItems: 'baseline',
  marginBottom: 4,
});

export const Price = styled(FigmaTypography)<{ inPopup?: boolean }>(({ inPopup }) => ({
  ...(!inPopup && {
    [MediaBreakPoint.WIDTH_640]: {
      fontSize: '16px',
      lineHeight: '20px',
    },
  }),
}));

export const Currency = styled(FigmaTypography)(({ theme }) => ({
  padding: '4px 8px',
  paddingTop: 4,
  paddingBottom: 4,
  color: theme.colors.secondaryBase,
  whiteSpace: 'nowrap',
  [MediaBreakPoint.WIDTH_640]: {
    padding: '0px 8px',
  },
}));

export const Priceless = styled(FigmaTypographyBR)(({ theme }) => ({
  color: theme.colors.primary,
  marginTop: 8,
  whiteSpace: 'nowrap',
  marginBottom: 4,
  [MediaBreakPoint.WIDTH_640]: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

export const MainFeaturesList = styled.div({
  marginBottom: 16,
});

export const MainFeature = styled.div({
  marginBottom: 16,
  position: 'relative',
  '&:last-of-type': {
    marginBottom: 0,
  },
  ...textOverflowEllipsis,
  [MediaBreakPoint.WIDTH_640]: {
    marginBottom: 8,
  },
});

export const BulletContainer = styled(FigmaTypography)<{ isAccented?: boolean }>(({ theme, isAccented = false }) => ({
  color: isAccented ? theme.colors.warningBase : 'inherit',
  position: 'absolute',
}));

export const FeatureMainText = styled(FigmaTypography)(({ theme }) => ({
  marginLeft: 22,
  marginBottom: 4,
  color: theme.colors.secondaryBase,
}));

export const FeatureSecondaryText = styled(FigmaTypographyBR)({
  marginLeft: 22,
  marginBottom: 4,
  '&:last-of-type': {
    marginBottom: 0,
  },
});

export const ExtraFeaturesList = styled.div(({ theme }) => ({
  color: theme.colors.secondaryBase,
}));

export const ExtraFeaturesTitle = styled(FigmaTypography)({
  marginBottom: 6,
  color: 'inherit',
});

export const ExtraFeature = styled.div({
  position: 'relative',
  marginBottom: 4,
  '&:last-of-type': {
    marginBottom: 0,
  },
  color: 'inherit',
});

export const ExtraFeatureTextsHolder = styled.div`
`;

export const ExtraFeatureText = styled(FigmaTypographyBR)(() => ({
  marginLeft: 22,
  color: 'inherit',
}));

export const Footer = styled.div({
  marginTop: 12,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const Badges = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: 4,
});

export const FeaturesContainer = styled.div({
  overflowY: 'auto',
  flexGrow: 1,
  marginTop: 16,
  paddingBottom: 2,
});
