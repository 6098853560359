import { PeerlessSlot } from 'modules/Grid/types';
import { BroadcastSlotData, MiroBoardSlotData, SlotAssignment } from 'services/MoodHoodApiClient/types';
import type RoomStore from 'stores/room';

export enum DemonstrationType {
  Miro = 'miro',
  Broadcast = 'broadcast',
}

export interface BroadcastDemoData {
  id: string,
  type: DemonstrationType.Broadcast,
  startedUserId: string,
  meta: {
    id: string,
    embedAlias: string,
    streamId: string,
    playLink: string,
  }
}

export interface MiroDemoData {
  id: string,
  type: DemonstrationType.Miro,
  meta: MiroBoardSlotData,
}

export type Demonstration = BroadcastDemoData | MiroDemoData;

export type BroadcastSlot = Omit<PeerlessSlot, 'slotAssignment'> & {
  slotAssignment: Omit<SlotAssignment, 'data'> & { data: BroadcastSlotData }
};

export type MiroSlot = Omit<PeerlessSlot, 'slotAssignment'> & {
  slotAssignment: Omit<SlotAssignment, 'data'> & { data: MiroBoardSlotData }
};

export interface BroadcastDemoParams {
  room: RoomStore,
}

export interface BroadcastDemoLocalStorage {
  started: {
    roomId: string,
    demonstrationId: string,
    clientUniqueId: string,
    userId: string,
    rtmpLink: string,
    streamKey: string,
  }
}

export interface DemonstrationUpdatedEvent {
  payload: {
    startedDemonstrationId?: string,
    stoppedDemonstrationId?: string,
    allDemonstrations: Demonstration[];
  }
}

export interface MiroBoardParams {
  room: RoomStore,
}

export interface MiroBoardLocalStorage {
  started: {
    demonstrationId: string,
    meta: MiroBoardSlotData,
  },
  isBreakoutRoom?: boolean,
}
