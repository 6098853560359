import i18n from 'services/i18n';
import axios, { AxiosError } from 'axios';

import { Errors } from 'constants/errors';
import { ApiResponsePayload } from 'types/common';

const getErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case 'participant_not_found': return i18n.t('errors.participantNotRegistered');
    case 'already_online': return i18n.t('errors.participantAlreadyOnline');
    case 'room_not_specified': return i18n.t('errors.noEntryRoomSpecified');
    case 'room_not_found': return i18n.t('errors.roomNotFound');
    case 'no_free_slots': return i18n.t('errors.noFreePlacesInRoom');
    case 'email_already_registered': return i18n.t('errors.emailAlreadyRegistered');
    case 'something_went_wrong': return i18n.t('errors.somethingWentWrong');
    case 'scenario_not_found': return i18n.t('errors.scenarioNotFound');
    case 'shortcut_not_found': return i18n.t('errors.shortcutNotFound');
    case 'action_not_found': return i18n.t('errors.actionNotFound');
    case 'user_not_found': return i18n.t('errors.participantNotFound');
    case 'already_translating_slot_to_room': return i18n.t('errors.alreadyTranslatingSlotToRoom');
    case 'already_translating_to_slot': return i18n.t('errors.alreadyTranslatingToSlot');
    default: return errorCode;
  }
};

export const getAxiosErrorMessage = (err: unknown): string => {
  if (!axios.isAxiosError(err)) {
    return `Not axios error: ${String(err)}`;
  }

  const axiosError = err as AxiosError<{
    code: string,
    message: string,
    errors: {
      [index: string]: string | { message: string }[]
    }
  }>;

  if (axiosError.response) {
    const { response: { data } } = axiosError;

    if (data.code) {
      const messageFromCode = getErrorMessage(data.code);
      if (messageFromCode !== data.code) {
        return messageFromCode;
      }
    }

    if (axiosError.response.data.code) {
      return axiosError.response.data.code;
    }

    if (axiosError.response.data.errors) {
      const firstErrorField = Object.keys(axiosError.response.data.errors)[0];
      const errors = axiosError.response.data.errors[firstErrorField];
      return Array.isArray(errors) ? `${errors[0].message}` : errors;
    }

    if (axiosError.response.status === 400) {
      return 'bad_request';
    }

    if (axiosError.response.status === 401) {
      return 'unauthorized';
    }

    if (axiosError.response.status === 404) {
      return Errors.NOT_FOUND;
    }

    if (axiosError.response.status === 403) {
      return 'forbidden';
    }
  } else if (axiosError.request) {
    return 'connection_refused';
  }
  return 'something_went_wrong';
};

export const getExceptionMessage = (err: unknown) => {
  if (err instanceof Error) return err.message;
  return String(err);
};

export const getApiErrorResponse = (err: unknown) => {
  if (axios.isAxiosError(err)) {
    const { code, error } = err.response?.data as ApiResponsePayload || {};
    return code || error || Errors.SOMETHING_WENT_WRONG;
  }

  return Errors.SOMETHING_WENT_WRONG;
};
