import styled from '@emotion/styled';

import { Font } from 'ui-kit/theme';
import { IconButton, DialogFromBottom as DialogFromBottomDefault } from 'ui-kit';

export const Message = styled.div({
  fontWeight: 'bold',
  fontSize: '22px',
  lineHeight: '28px',
  letterSpacing: '-0.01em',
});

export const Hint = styled.div({
  margin: '12px 0 20px 0',
  fontSsize: '15px',
  lineHeight: '19px',
  color: '#A1B0BC',
});

export const ContinueButton = styled(IconButton)({
  width: '100%',
});

export const DialogFromBottom = styled(DialogFromBottomDefault)({
  fontFamily: Font.BRAND_MEDIUM,
});
