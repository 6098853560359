import { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';

interface ParamTypes {
  action: 'join' | 'leave';
}

const Canary: FunctionComponent = () => {
  const { action } = useParams<ParamTypes>();
  const moodhoodApiUrl = process.env.REACT_APP_MOODHOOD_API_BASEURL as string;
  useEffect(() => {
    window.location.href = `${moodhoodApiUrl}/users/me/canary/${action}`;
  }, []);

  return null;
};

export default Canary;
