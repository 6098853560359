import styled from '@emotion/styled';

import { getStringTextStyle, TextSize } from 'ui-kit/theme';

// eslint-disable-next-line import/prefer-default-export
export const Text = styled.span<{ size: TextSize, noAA?: boolean, center?: boolean, color?: string }>`
  text-align: ${({ center }) => (center ? 'center' : undefined)};
  color: ${({ color }) => color};

  ${({ noAA }) => !noAA && `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;`}
  ${({ size }) => getStringTextStyle(size)}
`;
