import styled from '@emotion/styled';

import { FlexCenterCenter } from 'ui-kit';
import SupportButtonBase from 'components/SupportButton';

export const DesktopAppUpdaterRoot = styled(FlexCenterCenter)(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.colors.secondary01,
}));

export const ProgressContainer = styled(FlexCenterCenter)(({ theme }) => ({
  width: '100%',
  paddingBottom: 4,
  color: theme.colors.secondaryBase,
}));

export const ProgressTip = styled.div(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.colors.secondary01,
  padding: 8,
  borderRadius: 10,
}));

export const ErrorText = styled(FlexCenterCenter)<{ hasError: boolean }>(({ theme }) => ({
  width: '100%',
  fontSize: '14px',
  justifyContent: 'initial',
  color: theme.colors.errorBase,
}));

export const SupportButton = styled(SupportButtonBase)({
  position: 'absolute',
  right: 24,
  bottom: 24,
});

export const Content = styled(FlexCenterCenter)(({ theme }) => ({
  width: 500,
  padding: 24,
  margin: 'auto',
  outline: `solid 2px ${theme.colors.secondary02}`,
  backgroundColor: theme.colors.bg,
  borderRadius: 10,
}));
