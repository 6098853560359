import type { SelectType } from './types';

export class SingleSelect {
  constructor(private parentEl: HTMLElement) { }

  select(target: HTMLElement) {
    const listItem = target.closest('li:not(:disabled)');
    if (!listItem || !this.parentEl.contains(listItem)) {
      return;
    }

    const prevElement = this.parentEl.querySelector('li[data-selected="1"]');
    prevElement?.removeAttribute('data-selected');
    listItem.setAttribute('data-selected', '1');
  }

  getSelected() {
    return [...this.parentEl.querySelectorAll<HTMLElement>('li[data-selected="1"]')].map(
      (el) => ({
        value: el.dataset.value || el.id || '',
        text: el.textContent || '',
      }),
    );
  }
}

export function makeItemsSelector(selectType: SelectType, parentEl: HTMLElement) {
  switch (selectType) {
    case 'single':
      return new SingleSelect(parentEl);
    default:
      return null;
  }
}
