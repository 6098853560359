import styled from '@emotion/styled';

import { IconButton as ButtonBase } from 'ui-kit';
import { TDialogType } from './types';

export const Title = styled.div(({ theme }) => ({
  fontSize: 20,
  lineHeight: '24px',
  color: theme.colors.primary,
}));

export const Message = styled.div(({ theme }) => ({
  marginTop: 8,
  marginBottom: 24,
  fontSize: 16,
  lineHeight: '20px',
  color: theme.colors.primary,
}));

export const PrimaryButton = styled(ButtonBase)<{ dialogVariant:TDialogType }>(({ dialogVariant, theme }) => ({
  ...(dialogVariant === 'warn' ? {
    color: theme.colors.errorBase,
    borderColor: theme.colors.errorBase,
    background: theme.colors.error01,
    '&:hover': {
      background: `${theme.colors.secondary02} !important`,
    },
  } : {}),
}));

export const Button = styled(ButtonBase)({
  marginLeft: 16,

});

export const Buttons = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
});

export const Layout = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minWidth: 400,
});
