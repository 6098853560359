import React, {
  FunctionComponent,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from 'ui-kit';
import useStores from 'stores/root';

import { CongratsContent, CongratsImage } from './CongratsDialog.styled';

const CongratsDialog: FunctionComponent = () => {
  const { t } = useTranslation();
  const { paymentStore } = useStores();

  return (
    <Dialog onClose={() => paymentStore.closePaymentCongratsDialog()}>
      <CongratsContent>
        <CongratsImage />
        {t('payment.paymentDone')}
      </CongratsContent>
    </Dialog>
  );
};

export default CongratsDialog;
