export enum AnnouncementType {
  PromoButton = 'promo_button',
  Prodamus = 'prodamus_pay_form',
}

export enum AnnouncementAudience {
  All = 'all',
}

export enum CurrencyCode {
  RUB = 'RUB',
  EUR = 'EUR',
  USD = 'USD',
  KZT = 'KZT',
}

export interface AnnouncementTypeSpecificFields {
  amount?: number,
  currency?: CurrencyCode,
}

export interface AnnouncementEditableFields extends AnnouncementTypeSpecificFields {
  type: AnnouncementType,
  title: string,
  description: string,
  buttonText: string,
  url?: string,
}

export interface Announcement extends AnnouncementEditableFields {
  id: string,
  auditory: AnnouncementAudience,
  imgUrl?: string,
  order?: number,
  userId?: string,
  createdAt?: string,
}
