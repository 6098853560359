import React, { VoidFunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { Link, useRouteMatch, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStores from 'stores/root';
import { IconButton } from 'ui-kit';
import { RoutePath } from 'types/common';
import Breadcrumbs from 'components/Breadcrumbs';

import {
  Layout,
  Links,
} from './index.styled';

interface RouteParams {
  spaceId: string,
}

const Navigation: VoidFunctionComponent = observer((props) => {
  const {
    spaceStore,
  } = useStores();
  const { t } = useTranslation();
  const match = useRouteMatch<RouteParams>();
  const { name: spaceName, id: spaceId } = spaceStore.space || {};

  const getBreadCrumbsLinks = () => {
    const commonProps = {
      as: Link,
      variant: 'link',
    };

    const rootLink = (
      <IconButton {...commonProps} id="back-to-spaces-link" subVariant="link" to="/" key="root">
        {t('admin.spaces.title')}
      </IconButton>
    );

    if (match.path === RoutePath.Root || !spaceId || !spaceName) {
      return [rootLink];
    }

    switch (match.path) {
      case RoutePath.Space:
      case RoutePath.Playbacks:
        return [rootLink, <span key="space-name">{spaceName}</span>];
      case RoutePath.Plans:
        return [
          <IconButton
            {...commonProps}
            id="back-to-rooms"
            to={generatePath(RoutePath.Space, { spaceId })}
            key="rooms"
            subVariant="link"
          >
            {t('common.rooms')}
          </IconButton>,
          <span key="plans">{t('payment.planTitle')}</span>,
        ];
      case RoutePath.PlaybackEdit:
      case RoutePath.PlaybackSessions: {
        const url = generatePath(RoutePath.Playbacks, { spaceId });
        return [
          rootLink,
          <IconButton {...commonProps} id="playbacks-link" subVariant="link" to={url} key="playbacks">
            {t('admin.playbacks.playbacks')}
          </IconButton>,
          <span key="space-name">{spaceName}</span>,
        ];
      }
      default:
        return [];
    }
  };

  if (!spaceName) {
    return null;
  }

  return (
    <Layout
      id="back-to-spaces"
      data-stats="ui_rooms_back_to_spaces"
      {...props}
    >
      <Links>
        <Breadcrumbs>
          { getBreadCrumbsLinks() }
        </Breadcrumbs>
      </Links>
    </Layout>
  );
});

export default Navigation;
