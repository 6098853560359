export enum DialogCloseReason {
  CloseButton = 'close button',
  ClickOutside = 'click outside',
  ClickInside = 'click inside',
}

export interface DialogProps {
  fixedPosition?: boolean;
  canClose?: boolean;
  onClose?(reason: DialogCloseReason): void;
  className?: string;
  isVisible?: boolean;
  closeOnClick?: boolean;
  title?: React.ReactNode;
  showSwipeIndicator?: boolean;
}
