import { Alignment, AlignmentOptions } from 'ui-kit/components/Popup/types';

const POPUP_Y_OFFSET = 8;

export const getDimensionsByAnchor = (
  anchor: HTMLElement,
  alignment: Alignment,
  popupRect: DOMRect,
  bodyRect: DOMRect,
  {
    yOffset = 0,
    xOffset = 0,
  }: AlignmentOptions = {},
): { left: number, top: number } => {
  const anchorRect = anchor.getBoundingClientRect();
  let { left } = anchorRect;
  let top = anchorRect.bottom + popupRect.height > bodyRect.bottom
    ? bodyRect.bottom - popupRect.height - POPUP_Y_OFFSET : anchorRect.bottom + POPUP_Y_OFFSET;

  if (alignment === 'center-bottom') {
    left = anchorRect.left + ((anchorRect.width - popupRect.width) / 2);
    top = anchorRect.top + (anchorRect.height / 2);
  }

  if (alignment === 'left-top') {
    left = anchorRect.right - (popupRect.width);
    top = anchorRect.top - (popupRect.height) - POPUP_Y_OFFSET;
  }

  if (alignment === 'bottom') {
    const prevLeft = anchorRect.left + ((anchorRect.width - popupRect.width) / 2);
    left = Math.max(prevLeft + popupRect.width > bodyRect.right ? anchorRect.right - popupRect.width : prevLeft, 0);
    top = anchorRect.bottom + POPUP_Y_OFFSET;
  }

  if (alignment === 'left') {
    left = anchorRect.left - popupRect.width;
    top = anchorRect.top;
  }

  if (alignment === 'top-right') {
    left = anchorRect.left;
    const height = popupRect.height + POPUP_Y_OFFSET;
    top = anchorRect.top - height > 0 ? anchorRect.top - height : anchorRect.bottom + POPUP_Y_OFFSET;
  }

  if (alignment === 'top-center') {
    left = anchorRect.left + ((anchorRect.width - popupRect.width) / 2);
    top = anchorRect.top - (popupRect.height) - POPUP_Y_OFFSET;
  }

  if (alignment === 'right-bottom') {
    left = anchorRect.left + anchorRect.width;
    top = anchorRect.bottom - popupRect.height;
  }

  if (alignment === 'left-bottom') {
    left = anchorRect.left;
    top = anchorRect.bottom + POPUP_Y_OFFSET;
  }

  if (alignment === 'screen-center') {
    left = window.innerWidth / 2 - popupRect.width / 2;
    top = anchorRect.top - popupRect.height - POPUP_Y_OFFSET;
  }

  if (alignment === 'right') {
    left = Math.max(0, anchorRect.right - popupRect.width);
  }

  left += xOffset;
  top += yOffset;

  return { left, top };
};
