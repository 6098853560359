const Features = {
  HidePeersWoVideo: {
    title: 'Hide peer without video',
    key: 'hide-peer-wo-video',
  },
  LocalVideoConfig: {
    title: 'Turn on local video config',
    key: 'local-video-config',
  },
  ShowNRVideoEffects: {
    title: 'Show not ready video effects',
    key: 'show-not-ready-video-effects',
  },
};

export default Features;
