import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isMobile from 'is-mobile';

import { Loader } from 'ui-kit';
import { redirectTo } from 'helpers/url';

import Layout, {
  MobileTitle,
  MobileHint,
  DesktopTitle,
  DesktopHint,
} from './Layout';

interface IActionSuccessPage {
  waiting?: boolean;
  redirectTime?: number;
  title: string;
}

const RedirectPage: FunctionComponent<IActionSuccessPage> = ({ waiting, title, redirectTime = 5 }) => {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(redirectTime);

  useEffect(() => {
    if (!waiting && counter > 0) {
      setTimeout(() => setCounter((prevCounter) => prevCounter - 1), 1000);
    }
  }, [waiting, counter]);

  useEffect(() => {
    if (counter < 1) {
      redirectTo('/');
    }
  }, [counter]);

  if (waiting) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  if (isMobile()) {
    return (
      <Layout>
        <MobileTitle>{title}</MobileTitle>
        <MobileHint>{t('common.redirectHint', { counter })}</MobileHint>
      </Layout>
    );
  }

  return (
    <Layout>
      <DesktopTitle>{title}</DesktopTitle>
      <DesktopHint>{t('common.redirectHint', { counter })}</DesktopHint>
    </Layout>
  );
};

export default RedirectPage;
