import { css } from '@emotion/react';

import { getBrandOptions } from 'modules/Branding';

import ScrollBack from 'ui-kit/assets/images/scroll.png';
import ScrollThumb from 'ui-kit/assets/images/scroll-thumb.png';
import ScrollBackVert from 'ui-kit/assets/images/scroll-vert.png';
import ScrollThumbVert from 'ui-kit/assets/images/scroll-thumb-vert.png';

import {
  ThemeColors, ThemeType, DefaultThemes, BrandLogos,
} from './types';

const getThemeColors = (colors: ThemeColors) => {
  const cssVars = Object.entries(colors).map(([key]) => [key, `var(--ld-${key})`]);
  return Object.fromEntries(cssVars) as ThemeColors;
};

const getCssVariablesFromColors = (colors: ThemeColors) => Object.entries(colors)
  .map(([key, value]) => `--ld-${key}: ${value};`).join('\n');

const getThemeStyles = (colors: ThemeColors) => css`
  :root {
    ${getCssVariablesFromColors(colors)}
  }

  #root {
    background: var(--ld-bg);
    color: var(--ld-primary);
  }

  a {
    color: var(--ld-accent04)
  }

  ::-webkit-scrollbar {
    width: 11px;
    height: 11px;
  }

  ::-webkit-scrollbar-track {
    background: url(${ScrollBack});
  }

  ::-webkit-scrollbar-track:vertical {
    background: url(${ScrollBack});
  }

  ::-webkit-scrollbar-track:horizontal {
    background: url(${ScrollBackVert});
  }

  ::-webkit-scrollbar-thumb {
  }

  ::-webkit-scrollbar-thumb:vertical {
    background: url(${ScrollThumb});
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background: url(${ScrollThumbVert});
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

const getAvatarColors = ({
  avatarBurgundy,
  avatarRed,
  avatarDarkPink,
  avatarPink,
  avatarGold,
  avatarNavyGreen,
  avatarNavyBlue,
  avatarPurple,
}: ThemeColors) => [
  avatarBurgundy,
  avatarRed,
  avatarDarkPink,
  avatarPink,
  avatarGold,
  avatarNavyGreen,
  avatarNavyBlue,
  avatarPurple,
];

export const makeTheme = (themeType: DefaultThemes, colors: ThemeColors) => {
  const branding = getBrandOptions();
  const logos: Record<DefaultThemes, BrandLogos> = {
    [ThemeType.light]: {
      Icon: branding.IconLight,
      Logo: branding.LogoLight,
      LogoMax: branding.LogoMaxLight,
      PageBack: branding.PageBack,
    },
    [ThemeType.dark]: {
      Icon: branding.IconDark,
      Logo: branding.LogoDark,
      LogoMax: branding.LogoMaxDark,
      PageBack: branding.PageBack,
    },
  };

  return {
    name: themeType,
    styles: getThemeStyles(colors),
    colors: getThemeColors(colors),
    avatarColors: getAvatarColors(colors),
    ...logos[themeType],
  };
};
