import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Crop } from 'react-image-crop';

import { drawImage } from 'ui-kit/components/ImageUploader/helpers';

import {
  BigPreviewContainer,
  SmallPreviewContainer,
  Preview,
} from './Layout';

interface UserAvatarPreviewProps {
  crop?: Crop,
  image?: HTMLImageElement;
}

const previewWidths = {
  big: 286,
  small: 143,
};

const SpaceLogoPreview: FunctionComponent<UserAvatarPreviewProps> = ({
  crop,
  image: initialImage,
}) => {
  const bigPreviewCanvasRef = useRef<HTMLCanvasElement>(null);
  const smallPreviewCanvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    drawImage(bigPreviewCanvasRef.current, initialImage, crop);
    drawImage(smallPreviewCanvasRef.current, initialImage, crop);
  }, [crop, initialImage]);

  return (
    <>
      <BigPreviewContainer width={previewWidths.big}>
        <Preview width={previewWidths.big} ref={bigPreviewCanvasRef} />
      </BigPreviewContainer>
      <SmallPreviewContainer width={previewWidths.small}>
        <Preview width={previewWidths.small} ref={smallPreviewCanvasRef} />
      </SmallPreviewContainer>
    </>
  );
};

export default SpaceLogoPreview;
