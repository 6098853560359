import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Theme } from '@emotion/react';
import { CSSObject } from '@emotion/serialize';
import isPropValid from '@emotion/is-prop-valid';

import {
  MediaBreakPoint,
} from 'ui-kit/theme';

import {
  IconButtonProps,
  IconButtonSelectedVariant,
  IconButtonSize,
  IconButtonSubVariant,
  IconButtonVariant,
  IconButtonStyle,
  ButtonColors,
  OutlineColors,
} from './types';
import FigmaTypography from '../FigmaTypography';

const smallSizeStyle = (text: ReactNode, isLinkWithIcon: boolean) => {
  const padding = (text || typeof text === 'number') ? '6px 12px' : '6px';

  return {
    padding: isLinkWithIcon ? '0' : padding,
    minWidth: 36,
    height: 36,
  };
};

const getButtonColors = ({ colors }: Theme, variant: IconButtonStyle, iconColor: string): ButtonColors => {
  const buttonColors: Record<IconButtonStyle, ButtonColors> = {
    primary: {
      iconColor: colors.contrast,
      textColor: colors.contrast,
      buttonColor: colors.accentBase,
      hoverColor: colors.accent05,
      hoverTextColor: colors.accent01,
      hoverIconColor: colors.accent01,
      pressedColor: colors.accentBase,
      pressedTextColor: colors.contrast,
      pressedIconColor: colors.contrast,
    },
    red: {
      iconColor: colors.errorBase,
      textColor: colors.error04,
      buttonColor: colors.error01,
      hoverColor: colors.error02,
      hoverTextColor: colors.error03,
      hoverIconColor: colors.error03,
      pressedColor: colors.errorBase,
      pressedTextColor: colors.contrast,
      pressedIconColor: colors.contrast,
    },
    secondary: {
      iconColor: colors.accent04,
      textColor: colors.accent05,
      buttonColor: colors.accent01,
      hoverColor: colors.accent02,
      hoverTextColor: colors.accent05,
      hoverIconColor: colors.accent04,
      pressedColor: colors.accentBase,
      pressedTextColor: colors.contrast,
      pressedIconColor: colors.contrast,
    },
    'primary-red': {
      iconColor: colors.contrast,
      textColor: colors.contrast,
      buttonColor: colors.errorBase,
      hoverColor: colors.error04,
      hoverTextColor: colors.error01,
      hoverIconColor: colors.error01,
      pressedColor: colors.errorBase,
      pressedTextColor: colors.contrast,
      pressedIconColor: colors.contrast,
    },
    'primary-red-selected': {
      iconColor: colors.error01,
      textColor: colors.error01,
      buttonColor: colors.error04,
      hoverColor: colors.errorBase,
      hoverTextColor: colors.contrast,
      hoverIconColor: colors.contrast,
      pressedColor: colors.errorBase,
      pressedTextColor: colors.contrast,
      pressedIconColor: colors.contrast,
    },
    'secondary-selected': {
      iconColor: colors.accent01,
      textColor: colors.accent01,
      buttonColor: colors.accent05,
      hoverColor: colors.accent04,
      hoverTextColor: colors.accent01,
      hoverIconColor: colors.accent01,
      pressedColor: colors.accentBase,
      pressedTextColor: colors.contrast,
      pressedIconColor: colors.contrast,
    },
    'primary-selected': {
      iconColor: colors.accent01,
      textColor: colors.accent01,
      buttonColor: colors.accent05,
      hoverColor: colors.accentBase,
      hoverTextColor: colors.contrast,
      hoverIconColor: colors.contrast,
      pressedColor: colors.accentBase,
      pressedTextColor: colors.contrast,
      pressedIconColor: colors.contrast,
    },
    'red-selected': {
      iconColor: colors.error01,
      textColor: colors.error01,
      buttonColor: colors.error04,
      hoverColor: colors.error02,
      hoverTextColor: colors.error03,
      hoverIconColor: colors.error03,
      pressedColor: colors.errorBase,
      pressedTextColor: colors.contrast,
      pressedIconColor: colors.contrast,
    },
  };

  const baseColors = buttonColors[variant] ?? buttonColors.secondary;
  return {
    ...baseColors,
    ...(iconColor && {
      iconColor,
      hoverIconColor: iconColor,
      pressedIconColor: iconColor,
    }),
  };
};

const getOutlineColors = (theme: Theme, variant: IconButtonSelectedVariant): OutlineColors => {
  switch (variant) {
    case 'primary-selected':
      return {
        outlineColor: theme.colors.accent03,
        hoverInsetColor: theme.colors.accentBase,
      };
    case 'red-selected':
      return {
        outlineColor: theme.colors.error02,
        hoverInsetColor: theme.colors.bg,
      };
    case 'primary-red-selected':
      return {
        outlineColor: theme.colors.error03,
        hoverInsetColor: theme.colors.bg,
      };
    case 'secondary-selected':
    default:
      return {
        outlineColor: theme.colors.accent03,
        hoverInsetColor: theme.colors.bg,
      };
  }
};

const buttonColorsCSS = (
  theme:Theme, {
    iconColor, textColor, buttonColor, hoverColor, pressedColor,
    pressedTextColor, pressedIconColor, hoverIconColor, hoverTextColor,
  }: ButtonColors,
): CSSObject => ({
  color: textColor,
  background: buttonColor,
  '@media (hover: hover)': {
    '&:hover:not([disabled]):not(:active)': {
      color: hoverTextColor,
      background: hoverColor,
      svg: {
        color: hoverIconColor,
      },
    },
    '&:active:not([disabled])': {
      color: pressedTextColor,
      background: pressedColor,
      svg: {
        color: pressedIconColor,
      },
    },
  },
  '& svg': {
    flexShrink: 0,
    width: 24,
    height: 24,
    color: iconColor,
    transitionDuration: '0.3s',
    transitionProperty: 'color',
  },
  '&[disabled] svg': {
    color: theme.colors.secondary03,
  },
});

const baseCSS = (theme: Theme, variant: IconButtonVariant, iconColor: string): CSSObject => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: 48,
  flexShrink: 0,
  width: 'max-content',
  height: 48,
  outline: 'none',
  transitionDuration: '0.3s',
  transitionProperty: 'color, background, opacity, box-shadow',
  border: 'none',
  borderRadius: 10,
  backgroundImage: 'none',
  cursor: 'pointer',
  gap: 4,
  textDecoration: 'none',
  '&[disabled]': {
    cursor: 'default',
    color: theme.colors.secondary03,
    background: theme.colors.secondary01,
    boxShadow: 'none',
  },
  ...buttonColorsCSS(theme, getButtonColors(theme, variant, iconColor)),
});

const selectedStateCSS = (
  theme: Theme,
  variant: IconButtonVariant,
  subVariant: IconButtonSubVariant | undefined,
  iconColor: string,
): CSSObject => {
  const colors = getOutlineColors(theme, `${variant}-selected` as IconButtonSelectedVariant);
  return {
    '&[disabled]::before, &:active::before': {
      display: 'none',
    },
    '&::before': {
      position: 'absolute',
      pointerEvents: 'none',
      left: 0,
      top: 0,
      zIndex: 1,
      display: 'block',
      content: '""',
      width: '100%',
      height: '100%',
      borderRadius: subVariant === 'indicator' ? 7 : 10,
      boxShadow: `0px 0px 0px 2px ${colors.outlineColor}`,
      outline: `solid ${theme.colors.bg} 2px !important`,
      outlineOffset: -2,
    },
    '&:hover::before': {
      outline: `solid ${colors.hoverInsetColor} 2px !important`,
    },

    ...buttonColorsCSS(theme, getButtonColors(theme, `${variant}-selected` as IconButtonVariant, iconColor)),
  };
};

const propsCSS = (
  fullWidth?: boolean, text?: ReactNode, size?: IconButtonSize,
  hideTextBreakPoint = '', autoSmallBreakPoint = MediaBreakPoint.WIDTH_640,
  subVariant?: IconButtonSubVariant,
  isLinkWithoutIcon = false,
): CSSObject => ({
  padding: `12px  ${text ? 18 : 12}px`,
  ...(fullWidth && {
    '&&&': {
      width: '100%',
    },
  }),
  ...(size === 'large' && {
    minWidth: 48,
    height: 48,
    ...(text && { padding: '12px 18px;' }),
    '& > svg': {
      width: 24,
      height: 24,
    },
  }),
  ...(size === 'small' && {
    ...smallSizeStyle(text, isLinkWithoutIcon),
    [hideTextBreakPoint]: { padding: 6 },
  }),
  ...(size === 'tiny' && {
    minWidth: 24,
    height: 24,
    padding: 0,
    borderRadius: 5,
  }),
  ...(!size && !subVariant && {
    [autoSmallBreakPoint]: { ...smallSizeStyle(text, isLinkWithoutIcon) },
    [hideTextBreakPoint]: { padding: 6 },
  }),
});

const subVariantCSS = (
  theme: Theme,
  isSelected: boolean,
  subVariant?: IconButtonSubVariant,
  textColor?: string,
): CSSObject => ({
  ...(subVariant === 'menuItem' && {
    flexGrow: 1,
    color: 'inherit',
    background: 'none',
    padding: 0,
    fontFamily: 'inherit',
    fontSize: 'inherit',
    width: 'auto',
    minWidth: 64,
    justifyContent: 'flex-start',
    '&&:hover:not([disabled])': {
      background: 'none',
    },
    '@media (hover: hover)': {}, // drop default styling
  }),
  ...((subVariant === 'clear' || subVariant === 'link') && {
    '&[disabled]': {
      background: 'none',
    },
    background: 'none',
    fontFamily: 'inherit',
    fontSize: 'inherit',
  }),
  ...(subVariant === 'link' && {
    '&&:hover:not([disabled])': {
      background: 'none',
      color: textColor || theme.colors.accent04,
    },
    display: 'inline-flex',
    fontFamily: 'PT Root UI Bold',
    textDecoration: 'underline',
    textUnderlineOffset: 2,
    padding: 0,
    minWidth: 'initial',
    height: 'initial',
    color: textColor || theme.colors.accent04,
  }),
  ...(subVariant === 'indicator' && {
    padding: 0,
    borderRadius: 7,
    outlineWidth: 7,
    ...(!isSelected && { background: theme.colors.bg }),
    '&&&&:not([disabled])': {
      svg: {
        color: isSelected ? theme.colors.bg : theme.colors.accent04,
      },
    },
    '@media (hover: hover)': {
      '&&&:hover:not([disabled]):not(:active)': {
        background: theme.colors.accentBase,
        svg: {
          color: theme.colors.contrast,
        },
      },
    },
    '&&': {
      width: 24,
      minWidth: 24,
      height: '100%',
    },
  }),
});

export const IconButtonRoot = styled('button', {
  shouldForwardProp: isPropValid,
})<IconButtonProps>(({
  theme, variant = 'secondary', subVariant, isSelected = false, fullWidth,
  text, size, hideTextBreakPoint, autoSmallBreakPoint, iconColor = '',
  icon, iconAtEnd, textColor,
}) => ({
  ...baseCSS(theme, variant, iconColor),
  ...(isSelected && (!subVariant || subVariant === 'indicator') && {
    ...selectedStateCSS(theme, variant, subVariant, iconColor),
  }),
  ...propsCSS(
    fullWidth,
    text,
    size,
    hideTextBreakPoint,
    autoSmallBreakPoint,
    subVariant,
    subVariant === 'link' && (!icon || !iconAtEnd),
  ),
  ...subVariantCSS(theme, isSelected, subVariant, textColor),
}));

export const Text = styled(FigmaTypography)<{
  hideBreakPoint?: MediaBreakPoint,
  color?: string,
  isLinkWithoutIcon: boolean,
}>`
  display: flex;
  align-items: center;
  color: ${({ color }) => (color || 'inherit')};
  min-width: 1ch;
  justify-content: center;
  margin: ${({ isLinkWithoutIcon }) => (isLinkWithoutIcon ? '0' : '2px 4px')};

  ${({ hideBreakPoint }) => {
    if (hideBreakPoint) {
      return `
        ${hideBreakPoint} {
          display: none;
        }
      `;
    }

    return '';
  }}
`;
