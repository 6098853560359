import { checkOSRequirements } from 'helpers/browser';
import { getClientUniqueId } from 'helpers/common';
import { RootStore } from 'stores/root';
import { getEventTag } from 'services/QualityOfService/helpers';

import { NewRelicEvent } from './types';

class NewRelic {
  private clientId: string;

  private readonly isUnSupportedOS;

  constructor(private rootStore: RootStore) {
    this.clientId = getClientUniqueId();
    this.isUnSupportedOS = checkOSRequirements().isUnSupportedOS;
    window.addEventListener('load', () => {
      if (window.newrelic) {
        window.newrelic.setErrorHandler((err) => {
          const { message } = err as { message: string };
          return message.includes('ResizeObserver');
        });
      }
    });
  }

  public reportEvents(events: NewRelicEvent[]): void {
    events.forEach((evt) => this.reportEvent(evt));
  }

  public reportEvent(event: NewRelicEvent): void {
    const { newrelic } = window;
    if (newrelic) {
      const { clientId } = this;
      const { activeCall } = this.rootStore.roomStore.calls;
      const {
        name: roomName, type: roomType, roomAlias, spaceId,
      } = this.rootStore.roomStore;
      const { username, email } = this.rootStore.userStore.userInfo || {};
      const tag = getEventTag(spaceId);
      const { timestamp, ua, ...filteredEvent } = event;
      newrelic.addPageAction(event.type, {
        roomName,
        roomType,
        roomAlias,
        ...(activeCall && { callId: activeCall.id, callStartTime: activeCall.startedAt }),
        ...filteredEvent,
        clientId,
        username,
        email,
        tag,
      });
    }
  }
}

export default NewRelic;
