import React, { VoidFunctionComponent } from 'react';
import {
  ToastContainerProps, ToastOptions,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainers } from 'constants/common';
import { useTheme } from '@emotion/react';

import {
  StyledCommonSnackbarContainer,
} from './Layout';
import { getSnackbarByType } from './helpers';

export interface ContainerProps extends Omit<ToastContainerProps, 'theme'> {
  isPanelsShown: boolean,
}

export const CommonSnackbarContainer: VoidFunctionComponent<Omit<ContainerProps, 'theme'>> = (props) => {
  const theme = useTheme();

  return (
    <StyledCommonSnackbarContainer
      position="bottom-left"
      containerId={ToastContainers.Common}
      icon={false}
      hideProgressBar
      enableMultiContainer
      appTheme={theme}
      limit={1}
      {...props}
    />
  );
};

export const commonSnackbarParams: ToastOptions = {
  containerId: ToastContainers.Common,
  closeButton: false,
  autoClose: 5000,
};

export const snackbarInfo = getSnackbarByType('info', commonSnackbarParams);

export const snackbarWarn = getSnackbarByType('warning', commonSnackbarParams);

export const snackbarError = getSnackbarByType('error', commonSnackbarParams);

export const snackbarSuccess = getSnackbarByType('success', commonSnackbarParams);
