import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';

import { BreabcrumbsRoot, Separator } from './index.styled';

interface BreadcrumbsProps {
  separator?: string,
}

const Breadcrumbs: FunctionComponent<BreadcrumbsProps> = observer(({ separator = '/', children, ...restProps }) => {
  const links = React.Children.toArray(children);

  return (
    <BreabcrumbsRoot {...restProps}>
      {links.map((link, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {link}
          {index < links.length - 1 && <Separator>{ separator }</Separator>}
        </React.Fragment>
      ))}
    </BreabcrumbsRoot>
  );
});

export default Breadcrumbs;
