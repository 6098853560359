import { computed, makeAutoObservable, observable } from 'mobx';
import MediaTrackStore from 'stores/mediaTrack';

class ActiveSpeakers {
  @observable private audioTracks: MediaTrackStore[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  @computed get peerIds() {
    return this.audioTracks.filter((track) => track.volume && track.peerStore.isAudioStreamingAllowed)
      .map((track) => track.peerStore.id);
  }

  setAudioTracks(audioTracks: MediaTrackStore[]) {
    this.audioTracks = audioTracks;
  }

  has(peerId: string) {
    return this.peerIds.includes(peerId);
  }

  dispose() {
    this.audioTracks = [];
  }
}

export default ActiveSpeakers;
