import { rootStore } from 'stores/root';
import CloudRecords from './CloudRecords';

let cloudRecords: CloudRecords | null = null;

const dispose = () => {
  if (cloudRecords) {
    cloudRecords = null;
  }
};

const getInstance = () => {
  if (cloudRecords) {
    return cloudRecords;
  }

  const { metrics, moodHoodApiClient: { room, record } } = rootStore;

  const params = {
    roomApi: room,
    recordApi: record,
    metrics,
  };

  cloudRecords = new CloudRecords(params);
  return cloudRecords;
};

export default { getInstance, dispose };
