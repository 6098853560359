import styled from '@emotion/styled';

import { ZIndex } from 'ui-kit/theme';

// main dialog elements
export const Layout = styled.div(({ theme }) => ({
  position: 'absolute',
  zIndex: ZIndex.DIALOG,
  backgroundColor: theme.colors.bg,
  top: 0,
  left: 0,
  width: '100dvw',
  height: '100dvh',
  padding: '24px 16px',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

export const BackButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
  z-index: 1;
  padding-top: 24px;
  padding-left: 16px;
  cursor: pointer;
`;

export const MobileDialogContent = styled.div`
  padding-top: 24px;
`;
