import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Label, SelectRoot, ErrorText } from './select.styled';
import SelectInput from '../SelectInput/SelectInput';
import { SelectWrapperProps } from './types';
import { SelectInputProps } from '../SelectInput/types';

const Select = forwardRef<HTMLDivElement, SelectInputProps & SelectWrapperProps>(({
  id,
  children,
  title = undefined,
  label = '',
  className = '',
  placeholder = undefined,
  inject = undefined,
  style,
  hasError = false,
  variant,
  errorText,
  ...restProps
}, forwardedRef) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dataSet = { 'data-stats': (restProps as any)['data-stats'] };

  return (
    <SelectRoot
      as="div"
      size="main-text-medium"
      ref={forwardedRef}
      {...dataSet}
      title={title}
      className={className}
      style={style}
    >
      {label && (
        <Label
          as="label"
          size="label-text-bold"
          id={`${id}-label`}
          htmlFor={`${id}-select`}
        >
          {label}
        </Label>
      )}
      <SelectInput
        variant={variant}
        inject={inject}
        id={`${id}-select`}
        hasError={hasError || Boolean(errorText)}
        isSearchable={false}
        placeholder={placeholder || t('common.selectInputPlaceHolder')}
        {...restProps}
      />
      <ErrorText size="label-text-medium">
        {errorText}
        {/* keep space for error message */}
        &nbsp;
      </ErrorText>
      {children}
    </SelectRoot>
  );
});

export default Select;
