import { computed, makeAutoObservable, observable } from 'mobx';

import Schedule from 'modules/PlaybackEventSessions/Schedule';
import { SortOrder } from 'types/common';

import { lock } from 'decorators';
import {
  SessionsProviderSource,
  PlaybackEventSessionExtended,
  DateRange,
  SessionsListItemsProviderParams,
} from './types';

interface ListItemsProvider<T> {
  items: T[],
  loadMore(): void,
}

const MAX_SCHEDULE_ITEMS = 32;
const MAX_SESSION_ITEMS = 100;

class PlaybackEventSessionsListItemsProvider implements ListItemsProvider<PlaybackEventSessionExtended> {
  @observable items: PlaybackEventSessionExtended[] = [];

  @observable error: string | null = null;

  @observable isBusy = false;

  private schedule: Schedule | null = null;

  private source: SessionsProviderSource = 'schedule';

  private _sortOrder: SortOrder = 'asc';

  @observable private _dateRange: DateRange | null = null;

  private params: SessionsListItemsProviderParams;

  private _noMoreItems = false;

  constructor(params: SessionsListItemsProviderParams) {
    this.params = params;
    makeAutoObservable(this);
  }

  async load() {
    this.reset();
    return this.loadMore();
  }

  @lock('isBusy', false)
  async loadMore() {
    if (this._noMoreItems) {
      return;
    }

    if (!this.schedule) {
      const events = await this.fetchEvents();
      this.schedule = new Schedule({ events, limit: MAX_SCHEDULE_ITEMS });
    }

    const nextSessions = await (this.source === 'schedule' ? this.fetchScheduleSessions() : this.fetchSessions());

    if (!nextSessions.length) {
      this._noMoreItems = true;
      return;
    }

    this.items = [...this.items, ...nextSessions];
  }

  setSource(source: SessionsProviderSource) {
    this.source = source;
    this.reset();
  }

  setDateRange(dateRange: DateRange | null) {
    this._dateRange = dateRange;
    this.schedule?.setDateRange(dateRange);
  }

  setSortOrder(order: SortOrder) {
    this._sortOrder = order;
  }

  reset() {
    this.items = [];
    this._noMoreItems = false;
    this.schedule?.reset();
  }

  dispose() {
    this.items = [];
  }

  @computed get dateRange() {
    return this._dateRange;
  }

  @computed get hasMoreItems() {
    return !this._noMoreItems;
  }

  private fetchScheduleSessions() {
    if (!this.schedule) {
      return Promise.resolve([]);
    }

    const nextSessions = this.schedule.nextSessions(this._sortOrder);

    if (this.items.length === 0) {
      return Promise.resolve([...nextSessions]); // continous sessions should prepend here
    }

    return Promise.resolve(nextSessions);
  }

  private async fetchSessions() {
    const { spaceId, playbackId, playbackEventsApi } = this.params;
    const dateFrom = this._dateRange?.startDate;
    const dateTo = this._dateRange?.endDate || undefined;
    const group = this.source === 'sessions-running' ? 'active' : 'finished';

    const { data, error } = await playbackEventsApi.fetchPlaybackEventSessions({
      spaceId,
      playbackId,
      offset: this.items.length,
      limit: MAX_SESSION_ITEMS,
      dateFrom,
      dateTo,
      group,
      order: this._sortOrder,
    });

    if (error) {
      this.handleApiError('events.failedToFetchSessions');
    }

    if (!data) {
      return [];
    }

    if (data.items.length === 0 || data.items.length === data.total) {
      this._noMoreItems = true;
    }

    return data.items;
  }

  private async fetchEvents() {
    const { spaceId, playbackId, playbackEventsApi } = this.params;
    const { data } = await playbackEventsApi.fetchPlaybackEvents({ spaceId, playbackId, limit: MAX_SESSION_ITEMS });
    if (data) {
      return data.items;
    }

    return [];
  }

  private handleApiError(error: string) {
    this.error = error;
  }
}

export default PlaybackEventSessionsListItemsProvider;
