/* eslint-disable @typescript-eslint/naming-convention */
import { computed, observable } from 'mobx';

import UrlQueryParams from 'modules/UrlQueryParams';
import { getLocalStorageItem, setLocalStorageItem } from 'helpers/localStorage';

import { ParticipantInfoParams } from './types';

type ParticipantField = 'username' | 'email' | 'phone';

/* TBD: Should be also used in participantStore */
class ParticipantInfo {
  private params: ParticipantInfoParams;

  @observable
  private _username = '';

  @observable
  private _email = '';

  @observable
  private _phone = '';

  constructor(params: ParticipantInfoParams) {
    this.params = params;
  }

  @computed.struct get fromUrl() {
    const { participantName, email, phone } = UrlQueryParams.userParams;
    return {
      username: participantName,
      email,
      phone,
    };
  }

  @computed.struct get fromLocalStorage() {
    const email = getLocalStorageItem<string>('email');
    const username = getLocalStorageItem<string>('username');
    const phone = getLocalStorageItem<string>('phone');

    return {
      email, username, phone,
    };
  }

  @computed.struct get fromUser() {
    const { userStore: { email, phone, username } } = this.params;

    return {
      email, username, phone,
    };
  }

  @computed get username() {
    return this._username || this.getParticipantField('username');
  }

  @computed get email() {
    return this._email || this.getParticipantField('email');
  }

  @computed get phone() {
    return this._phone || this.getParticipantField('phone');
  }

  setUsername(username: string) {
    this._username = username;
    setLocalStorageItem('username', username);
    window.lsd.participantName = username;
  }

  setEmail(email: string) {
    this._email = email;
    setLocalStorageItem('email', email);
    window.lsd.email = email;
  }

  setPhone(phone: string) {
    this._phone = phone;
    setLocalStorageItem('phone', phone);
    window.lsd.phone = phone;
  }

  setInfo(info: Partial<ParticipantInfo>) {
    this._username = info.username ? info.username : this._username;
    this._email = info.email ? info.email : this._email;
    this._phone = info.phone ? info.phone : this._phone;
    this.saveToLocalStorage();
    window.lsd = {
      ...window.lsd,
      ...{
        participantName: this.username,
        email: this.email,
        phone: this.phone,
      },
    };
  }

  saveToLocalStorage() {
    setLocalStorageItem('username', this.username);
    setLocalStorageItem('email', this.email);
    setLocalStorageItem('phone', this.phone);
  }

  private getParticipantField(fieldName: ParticipantField) {
    const { fromUrl, fromUser, fromLocalStorage } = this;
    return fromUrl[fieldName] || fromUser[fieldName] || fromLocalStorage[fieldName] || '';
  }
}

export default ParticipantInfo;
