import React, { FunctionComponent, useCallback } from 'react';
import isMobile from 'is-mobile';

import { PopupProps } from 'ui-kit/components/Popup/types';
import useStores from 'stores/root';
import { redirectTo } from 'helpers/url';

import UserMenuMobile from './Mobile';
import UserMenuDesktop from './Desktop';

const UserMenu: FunctionComponent<PopupProps> = (props) => {
  const { authStore, uiStore } = useStores();

  const handleLogoutClick = useCallback(async () => {
    if (authStore.isUser) {
      await authStore.logout();
      redirectTo('/');
    }
  }, []);

  const handleProfileClick = useCallback(() => {
    uiStore.openUserProfile();
  }, []);

  if (isMobile()) {
    return (
      <UserMenuMobile
        handleProfileClick={handleProfileClick}
        handleLogoutClick={handleLogoutClick}
        {...props}
      />
    );
  }

  return (
    <UserMenuDesktop
      handleProfileClick={handleProfileClick}
      handleLogoutClick={handleLogoutClick}
      {...props}
    />
  );
};

export default UserMenu;
