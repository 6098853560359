import { useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getLocalStorageItem, setLocalStorageItem } from 'helpers/localStorage';
import { VIDEO_CONFIG_LS_KEY } from 'constants/common';
import { VideoConfig } from 'helpers/types';
import { FeatureFlags } from 'types/featureFlags';
import useFeature from 'hooks/useFeature';
import { getVideoConfig } from 'helpers/media';
import { setSessionStorageItem } from 'helpers/sessionStorage';
import useStores from 'stores/root';
import Features from 'constants/features';

const useVideoConfig = (): void => {
  const {
    participantStore,
    roomStore,
  } = useStores();
  const isVideoDebugTurnedOn = useFeature(Features.LocalVideoConfig);
  const debugVideoConfig = getLocalStorageItem<VideoConfig>(VIDEO_CONFIG_LS_KEY);
  const flags: FeatureFlags = useFlags();

  useEffect(() => {
    setSessionStorageItem('mediaConfig', flags.mediaConfig);
  }, [flags.mediaConfig]);

  useEffect(() => {
    if (!isVideoDebugTurnedOn || debugVideoConfig) {
      return;
    }

    setLocalStorageItem(VIDEO_CONFIG_LS_KEY, getVideoConfig(roomStore.type));
  }, [isVideoDebugTurnedOn]);

  useEffect(() => {
    const isDebugVideoConfigUsed = isVideoDebugTurnedOn && !!debugVideoConfig;
    const config = isDebugVideoConfigUsed ? debugVideoConfig : getVideoConfig(roomStore.type);
    participantStore.setVideoConfig(config);
    if (isDebugVideoConfigUsed) {
      // eslint-disable-next-line no-console
      console.log('You\'re working with local storage video config', config);
    }
  }, [isVideoDebugTurnedOn, flags.mediaConfig]);
};

export default useVideoConfig;
