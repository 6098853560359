import { RoomType } from 'types/common';
import { VideoCodec } from '@livedigital/client/dist/types/common';

enum UTMKey {
  UTMSource = 'utm_source',
  UTMMedium = 'utm_medium',
  UTMCampaign = 'utm_campaign',
  UTMContent = 'utm_content',
  UTMTerm = 'utm_term',
  UTMPartnerId = 'utm_partner_id',
}

enum LandingMetaKey {
  Referrer = 'referrer',
}

interface CameraConfig {
  encoderConfig: EncoderConfig,
  options: {
    width?: number,
    height?: number,
  },
}
interface ScreenSharingConfig {
  encoderConfig: EncoderConfig,
  options: {
    width: number,
    height: number,
  },
}

interface Encodings {
  dtx?: boolean,
  scaleResolutionDownBy?: number,
  maxBitrate?: number,
  maxFramerate?: number,
}

export interface GetUTMSPayload {
  roomId?: string;
  roomType?: RoomType;
  content: string;
  addSuffix?: boolean;
}

export type UTMParams = {
  [key in UTMKey]: string;
};

export interface EncoderConfig {
  encodings?: Encodings[],
  preferredCodec?: VideoCodec,
}

export interface VideoConfig {
  screenSharingConfig: ScreenSharingConfig,
  cameraConfig: CameraConfig,
}

export const UTM_KEYS = Object.values(UTMKey);

export const META_KEYS = Object.values(LandingMetaKey);
