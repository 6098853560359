import logger from 'helpers/logger';
import { MediaElementsPoolClass, PoolElement } from '.';

const createFakeTrack = () => {
  const canvas = document.createElement('canvas');
  canvas.style.cssText = `
    display: none;
    width: 100px;
    height: 100px;
    background: white;
  `;
  canvas.className = 'fake-track';
  const canvasContext = canvas.getContext('2d');
  if (!canvasContext) {
    return null;
  }

  canvasContext.fillStyle = 'white';
  canvasContext.fillRect(0, 0, 300, 300);

  const stream = canvas.captureStream(0);
  const track = stream.getVideoTracks()[0];

  return track;
};

class VideoElementsPool extends MediaElementsPoolClass<HTMLVideoElement> {
  private fakeTrack = createFakeTrack();

  constructor(maxSize = Infinity, initialSize = 0) {
    super(maxSize, initialSize);
  }

  protected resetElement(poolElement: PoolElement<HTMLVideoElement>) {
    const el = poolElement;
    if (!el.mediaStream) {
      return;
    }

    el.refCount = 0;
    if (el.domElement) {
      el.domElement.pause();
      el.domElement.muted = true;
      el.domElement.defaultMuted = true;
      el.domElement.playsInline = true;
    }

    el.trackId = null;
    const tracks = el.mediaStream.getTracks();

    tracks.forEach((track) => {
      el.mediaStream?.removeTrack(track);
    });

    if (this.fakeTrack) {
      /* workaround, need to clear backbuffer to avoid displaying frame from previous track */
      el.mediaStream?.addTrack(this.fakeTrack);
      el.mediaStream?.removeTrack(this.fakeTrack);
    }
    /* Delibiratly leaving dom element and MediaStream untouched to keep MediaPlayer alive to reuse it later */
  }

  protected addElement(): PoolElement<HTMLVideoElement> {
    if (this.pool.length >= this.maxSize) {
      logger.error('Video pool error: max elements reached');
      throw new Error('Video pool error: max elements reached');
    }

    const el = document.createElement('video');
    el.className = 'peer-view-video';
    el.style.cssText = `
      width: 100%;
      height: 100%;
      object-fit: cover;
    `;
    el.setAttribute('autoplay', '1');
    el.setAttribute('muted', '1');
    el.setAttribute('playsInline', '1');
    el.defaultMuted = true;
    el.muted = true;

    const mediaStream = new MediaStream();
    el.srcObject = mediaStream;

    const poolElement = {
      id: this.pool.length + 1,
      domElement: el,
      refCount: 0,
      trackId: null,
      mediaStream,
    };

    this.pool.push(poolElement);

    return poolElement;
  }
}

export default new VideoElementsPool();
