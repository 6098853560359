import type { ReactComponent } from '*.svg';

import { FigmaColors } from './light';

export enum ThemeType {
  light = 'ld-light-theme',
  dark = 'ld-dark-theme',
  auto = 'ld-auto-theme',
}

export type AvatarColors = string[];

export type ThemeColors = typeof FigmaColors;

export type DefaultThemes = ThemeType.light | ThemeType.dark;

export interface BrandLogos {
  Icon: typeof ReactComponent,
  Logo: typeof ReactComponent,
  LogoMax: typeof ReactComponent,
  PageBack?: string,
}
