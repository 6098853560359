import React, { VoidFunctionComponent } from 'react';

import { FlexGrow, SignUpFormStep2 } from 'components/Auth/Page/Mobile/index.styled';
import PageMobile from 'components/Auth/Page/Mobile';

const SignUpStep2PageMobile: VoidFunctionComponent = () => (
  <PageMobile>
    <SignUpFormStep2 separator={<FlexGrow />} />
  </PageMobile>
);

export default SignUpStep2PageMobile;
