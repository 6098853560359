import React, { VoidFunctionComponent } from 'react';

import { FixedTextRoot } from './index.styled';

interface TextFixedLengthProps {
  value: string | number;
  as?: React.ElementType;
}

const FixedText: VoidFunctionComponent<TextFixedLengthProps> = ({ value, as = 'span' }) => (
  <FixedTextRoot as={as} len={value.toString().length}>
    {value}
  </FixedTextRoot>
);

export default FixedText;
