import styled from '@emotion/styled';

export const ColorPickerInput = styled.input(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: 0,
  '&::-webkit-color-swatch': {
    borderRadius: 5,
    border: `solid 2px ${theme.colors.secondary02}`,
  },
  '&::-webkit-color-swatch-wrapper': {
    padding: 0,
  },
}));

export const IconWrapper = styled.div(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  color: theme.colors.accentBase,
}));

export const ColorPickerRoot = styled.div({
  position: 'relative',
  display: 'flex',
  width: 32,
  height: 32,
});
