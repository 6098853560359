import {
  action, computed, makeAutoObservable, observable, reaction,
} from 'mobx';
import { v4 as uuid } from 'uuid';
import axios, { AxiosError } from 'axios';
import PQueue from 'p-queue';

import {
  Auditory,
  CommonToastType,
  DevicesTypes,
  ReJoinReason,
  RoomEventData,
  RoomEventNewReactionPayload,
  RoomType,
  TJoinParams,
  WaitingRoomAudience,
  WindowEvent,
} from 'types/common';
import { RootStore } from 'stores/root';
import PeerStore from 'stores/peer';
import {
  chunk,
  getClientUniqueId,
} from 'helpers/common';
import logger from 'helpers/logger';
import { Errors } from 'constants/errors';
import { AUDIO_PUBLISHER_LABELS, SlotsOnPage } from 'constants/common';
import { CreateParticipantPayload } from 'types/stores/participant';
import { PeerAppData } from 'types/stores/peer';
import { JoinApproval } from 'types/stores/peerAppData';
import { getUserAgentInfo } from 'helpers/browser';
import { JoinSettings } from 'services/MoodHoodApiClient/types/joinSettings';
import { Room } from 'services/MoodHoodApiClient/types/room';
import { lock } from 'decorators';

import ActiveSpeakers from 'modules/ActiveSpeakers';
import PutHandDownAutomatically from 'modules/PutHandDownAutomatically';
import ScreenWakeLock from 'modules/ScreenWakeLock';
import CallStatsCollector from 'modules/CallStatsCollector';
import BroadcastDemo from 'modules/Demo/Broadcast/BroadcastDemo';
import MiroBoard from 'modules/Demo/Miro/MiroBoard';
import Calls from 'modules/Calls';

import {
  BreakoutRooms,
  FloatSlotsInfo,
  ParticipantResponse,
  PeerRole,
  RoomRole,
  SetRoomParams,
  TrackLabel,
} from '../services/MoodHoodApiClient/types';
import MediaTrackStore from './mediaTrack';
import PublisherStore from './publisher';
import { CloudRecordStatus } from '../modules/RoomRecorder/CloudRoomRecorder';

const DEFAULT_ROOM_NAME = 'Room';

class RoomStore {
  rootStore: RootStore;

  isWhiteLabel = false;

  isRoomInfoLoaded = false;

  spaceId?: string = undefined;

  isRoomJoined = false;

  isRoomJoining = false;

  isRoomReJoining = false;

  isRoomReJoined = false;

  isPeersAndParticipantsLoaded = false;

  slotFullScreenUuid?: string = undefined;

  floatSlots: FloatSlotsInfo[] = [];

  sessionId: string | null = null;

  allParticipantsCount = 0;

  isIframe = false;

  breakoutRooms: BreakoutRooms | null = null;

  joinSettings: JoinSettings | null = null;

  isMovingParticipant = false;

  reJoinReason: ReJoinReason | null = null;

  private videoPlaybackActualizationHash = uuid();

  private publisherSubscriptionQueue: PQueue;

  audioContext: AudioContext | null = null;

  reloadTracked = false;

  activeSpeakers = new ActiveSpeakers();

  putHandDownAutomatically = new PutHandDownAutomatically();

  screenLock = new ScreenWakeLock();

  broadcast: BroadcastDemo;

  miroBoard: MiroBoard;

  calls: Calls;

  callStatsCollector: CallStatsCollector;

  hasBoardOrScreenSharingOnJoin = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.type = undefined;
    this.name = DEFAULT_ROOM_NAME;
    this.isScreensharingAllowed = false;
    this.isRecordAllowed = false;
    this.isChatAllowed = false;
    this.callStatsCollector = new CallStatsCollector({
      roomStore: this,
      participantStore: rootStore.participantStore,
    });
    this.resetStore();
    this.publisherSubscriptionQueue = new PQueue({
      concurrency: 10,
      autoStart: false,
    });
    this.isAutoRecordingAllowed = false;
    this.isPublic = false;

    makeAutoObservable(this);
    this.setupReactions();

    this.calls = new Calls({ room: this });
    this.broadcast = new BroadcastDemo({ room: this });
    this.miroBoard = new MiroBoard({ room: this });
  }

  @observable public id?: string;

  @observable public appId?: string;

  @observable public channelId?: string;

  @observable public connectionId?: string;

  @observable public isConnectionLost = false;

  @observable public roomAlias: string | null = null;

  @observable public type: RoomType | undefined;

  @observable public waitingRoomAudience: WaitingRoomAudience | undefined;

  @observable name: string;

  @observable isScreensharingAllowed: boolean;

  @observable isRecordAllowed: boolean;

  @observable isChatAllowed: boolean;

  @observable parentRoomId: string | null = null;

  isForceDisconnected = false;

  withNamesForViewer = false;

  @observable isAllParticipantsMuted = false;

  @observable roomAuthenticationFailReason?: string;

  @observable assets: string[] = [];

  @observable activeMediaStreamTrackIds = new Set<string>();

  @observable isAutoRecordingAllowed: boolean;

  @observable isPublic: boolean;

  @observable roleInRoom = RoomRole.Guest;

  @observable onlineUsersCount: number | null = null;

  @observable isOnlineUsersCountFetching = false;

  private reactionDisposers: (() => void)[] = [];

  @action resetStore(): void {
    this.isRoomInfoLoaded = false;
    this.isRoomInfoFetched = false;
    this.spaceId = undefined;
    this.isRoomJoined = false;
    this.isRoomJoining = false;
    this.isRoomReJoined = false;
    this.slotFullScreenUuid = undefined;
    this.id = undefined;
    this.type = undefined;
    this.name = DEFAULT_ROOM_NAME;
    this.isForceDisconnected = false;
    this.withNamesForViewer = false;
    this.isAllParticipantsMuted = false;
    this.isScreensharingAllowed = false;
    this.isRecordAllowed = false;
    this.isChatAllowed = false;
    this.roomAuthenticationFailReason = '';
    this.sessionId = null;
    this.waitingRoomAudience = undefined;
    this.parentRoomId = null;
    this.activeSpeakers.dispose();
    this.callStatsCollector.dispose();
    this.isAutoRecordingAllowed = false;
    this.isPublic = false;
    this.roleInRoom = RoomRole.Guest;
  }

  setupReactions() {
    this.reactionDisposers = [
      reaction(
        () => this.roomScreenVideoTracks.length,
        (screenTracks, prevScreenTracks) => {
          if (screenTracks > 0 && prevScreenTracks === 0) {
            this.rootStore.roomPaginationStore.gotoFirstPage();
          }

          if (screenTracks === 0 && prevScreenTracks > 0) {
            this.rootStore.roomPaginationStore.gotoFirstPage();
          }
        },
      ),

      reaction(() => this.myPeer,
        (peer) => {
          if (peer) {
            this.rootStore.participantStore.participantAppData.setAppDataAndPeer(peer);
          }
        }),

      reaction(() => this.myPeer?.appData.joinApproval,
        (peerJoinApproval) => {
          if (peerJoinApproval === JoinApproval.Approved) {
            this.rootStore.participantStore.participantAppData.setJoinApproval(JoinApproval.Approved);
          }
        }),

      reaction(() => this.myPeer?.appData.isOutOfScreen,
        (isOutOfScreen) => {
          if (!this.isWebinar) {
            return;
          }

          if (isOutOfScreen && !this.rootStore.participantStore.videoDisabled) {
            this.rootStore.participantStore.disableVideo();
          }
        }),

      reaction(() => this.roomAudioTracksWithMe,
        (tracks) => {
          this.activeSpeakers.setAudioTracks(tracks);
        }),

      reaction(
        () => this.isRoomJoined,
        (isRoomJoined) => {
          if (isRoomJoined) {
            this.screenLock.acquire();
          } else {
            this.screenLock.release();
          }
        },
      ),

      reaction(
        () => this.isRoomJoined,
        (isRoomJoined) => {
          if (isRoomJoined && !this.callStatsCollector.isRunning()) {
            this.callStatsCollector.start();
          }
        },
      ),
    ];
  }

  get allParticipantsInRoom(): number {
    if (this.type === 'lesson') {
      return this.participantPeersInRoom
        .filter((x) => !x.appData.isBroadcaster)
        .length;
    }

    return this.allParticipantsCount;
  }

  @observable isRoomInfoFetched = false;

  @action async fetchSpaceInfo(): Promise<void> {
    this.isRoomInfoFetched = false;

    const { spaceId, id: roomId } = this;
    if (!spaceId) {
      throw new Error('Missing space ID');
    }

    if (!roomId) {
      throw new Error('Missing room ID');
    }

    const { space, error: spaceFetchingError } = await this.rootStore.moodHoodApiClient.space.getSpace(spaceId);
    if (!space) {
      throw new Error(spaceFetchingError);
    }

    const { room } = await this.rootStore.moodHoodApiClient.room.getRoomById(spaceId, roomId);

    if (!room) {
      throw new Error('Room not exists');
    }

    this.setRoom({ ...room });
    this.rootStore.spaceStore.setSpaceInfo({ space });
    this.isRoomInfoFetched = true;
  }

  @action async joinParticipant({
    roomId,
    participantId,
    role,
    image,
    name,
  }: TJoinParams): Promise<void> {
    const { spaceId } = this;
    if (!spaceId) {
      throw new Error('Missing space ID');
    }

    this.setIsRoomJoining(true);
    this.clearPeers();
    this.setIsPeersLoaded(false);

    try {
      const { userAgent } = getUserAgentInfo();
      const token = await this.rootStore.moodHoodApiClient.space.participants.createSignalingToken(
        spaceId,
        participantId,
      );

      await this.rootStore.participantStore.client.join({
        channelId: this.channelId as string,
        token,
        appData: {
          ...this.rootStore.participantStore.participantAppData.getCurrentAppData(),
          image: image || undefined,
          name,
          userId: this.rootStore.userStore.id,
          roomId: this.id,
          spaceId: this.spaceId,
          isModerator: this.rootStore.participantStore.isModerator,
          userAgent,
          isOutOfScreen: this.isWebinar,
          isAudioStreamingAllowed: true,
          isVideoStreamingAllowed: true,
          roleInRoom: this.rootStore.participantStore.roomPermissions?.role,
        } as PeerAppData,
        role,
      });
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        logger.error('Failed to join room', {
          error,
          participantId,
        });
      } else {
        logger.warn('SDK client failed to fulfill join room operation', {
          error,
          role,
          participantId,
          roomId: this.id,
          spaceId: this.spaceId,
          userId: this.rootStore.userStore.id,
        });
      }

      this.setIsRoomJoining(false);
      throw error;
    }

    this.setConnectionId(this.rootStore.participantStore.client.id);

    const { error } = await this.rootStore.moodHoodApiClient.room.join({
      spaceId,
      roomId,
      participantId,
    });

    if (error) {
      this.setIsRoomJoining(false);
      throw new Error(error);
    } else {
      this.setIsRoomJoining(false);
      this.setIsRoomJoined(true);
    }
  }

  @action async joinRecorder(): Promise<void> {
    this.setIsRoomJoining(true);
    this.clearPeers();
    this.setIsPeersLoaded(false);

    const { signalingToken } = this.rootStore.recordStore.cloudRoomRecorder;
    if (!signalingToken) {
      logger.warn('Missing cloud recorder signaling token', {
        roomId: this.id,
        spaceId: this.spaceId,
        isBroadcaster: true,
      });

      this.setIsRoomJoining(false);
      return;
    }

    try {
      await this.rootStore.participantStore.client.join({
        channelId: this.channelId as string,
        token: signalingToken,
        appData: {
          isBroadcaster: true,
        },
        role: PeerRole.Audience,
      });
    } catch (error: unknown) {
      logger.warn('SDK client failed to fulfill join room operation', {
        error,
        roomId: this.id,
        spaceId: this.spaceId,
        isBroadcaster: true,
      });

      this.setIsRoomJoining(false);
      throw error;
    }

    this.setIsRoomJoined(true);
  }

  @action async reJoinRecorder(): Promise<void> {
    const { participantStore } = this.rootStore;
    this.isRoomReJoined = false;
    this.isRoomReJoining = true;

    this.rootStore.spaceStore.reset();
    participantStore.reset();

    await this.leave();
    await this.joinRecorder();

    this.isRoomReJoined = true;
    this.isRoomReJoining = false;
    this.rootStore.debugStore.incReconnectsCount();
    logger.info('Room is rejoined by broadcaster');
  }

  @action async loadPeers(): Promise<void> {
    const { client } = this.rootStore.participantStore;
    await client.loadPeers(PeerRole.Host);
    const { peers } = client;

    peers
      .filter((peer) => peer.role === PeerRole.Host)
      .forEach((peer) => {
        if (this.hasPeer(peer.id)) {
          return;
        }

        const peerStore = this.peers.get(peer.id) || new PeerStore({
          peer,
          roomStore: this,
        });

        this.setPeer(peerStore);
      });

    this.setIsPeersLoaded(true);
  }

  @action async loadMyPeerOnly(): Promise<void> {
    const { client } = this.rootStore.participantStore;
    await client.loadPeers(PeerRole.Host);
    const { peers } = client;

    const peer = peers.find((item) => item.isMe);
    if (!peer) {
      logger.error('Self peer not found', {
        case: 'loadMyPeerOnly',
      });

      return;
    }

    const peerStore = new PeerStore({
      peer,
      roomStore: this,
    });

    this.setPeer(peerStore);
    window.lsd.peerId = this.myPeer?.id;
  }

  @action setFloatSlots(value: FloatSlotsInfo[]) {
    this.floatSlots = value;
  }

  @action setHasBoardOrScreenSharingOnJoin(value: boolean) {
    this.hasBoardOrScreenSharingOnJoin = value;
  }

  @action async reJoinParticipant(reJoinReason: ReJoinReason): Promise<boolean> {
    if (reJoinReason === ReJoinReason.Move) {
      const { participantStore } = this.rootStore;
      this.isRoomInfoFetched = false;
      participantStore.deviceStore.setShouldEnableCameraAfterJoin(!participantStore.videoDisabled);
      participantStore.deviceStore.setShouldEnableMicrophoneAfterJoin(!participantStore.audioDisabled);
    }

    const { participantStore, userStore } = this.rootStore;
    this.isRoomReJoined = false;
    this.isRoomReJoining = true;
    this.reJoinReason = reJoinReason;
    // keep isRoomJoined flag due to prevent JoinForm render

    if (!this.isRoomJoined || !this.id) {
      logger.info('reJoin: can not rejoin due to participant is not joined the room');
      return false;
    }

    if (!participantStore.id) {
      logger.info('reJoin: can not rejoin due to participantId is unknown');
      return false;
    }

    const roomId = this.id;

    if (!this.spaceId) {
      logger.info('reJoin: can not rejoin due to spaceId is unknown');
      return false;
    }

    if (!roomId) {
      logger.info('reJoin: can not rejoin due to roomId is unknown');
      return false;
    }

    logger.info('Start rejoin the room');

    const participant = await this.createParticipant({
      spaceId: this.spaceId,
      roomId,
      image: userStore.image,
      name: participantStore.name || 'Participant',
      clientUniqueId: getClientUniqueId(),
      role: participantStore.role,
    });

    if (!participant) {
      logger.info('reJoin: Participant creation error');
      return false;
    }

    // use clearPeers instead reset to avoid resetting space info in spaceStore
    this.rootStore.spaceStore.clearPeers();

    this.peers.clear();
    participantStore.reset();

    await this.leave();
    await this.joinParticipant({
      roomId: this.id,
      role: participantStore.role,
      participantId: participant.id,
      name: participantStore.participantInfo.username || 'Participant',
      image: userStore.image,
    });

    this.isRoomReJoined = true;
    this.isRoomReJoining = false;
    logger.info('Room is rejoined by participant');

    if (this.roomAlias && !this.isMovingParticipant) {
      this.rootStore.debugStore.incReconnectsCount();
    }

    return true;
  }

  @action handleNewReaction(payload: RoomEventNewReactionPayload): void {
    if (payload.peerId === this.rootStore.participantStore.client.id) {
      // own reactions are visualized immediately after click
      return;
    }

    this.rootStore.uiStore.setLatestReaction(payload);
  }

  @action handleRoomRecord(payload: RoomEventData): void {
    const { recordState, id, startedAt } = payload as {
      recordState: CloudRecordStatus,
      id: string,
      createdAt: string,
      startedAt?: string,
    };

    if (!recordState) {
      logger.error('Room update record error: no record event type', payload);
    }

    if (this.rootStore.recordStore.cloudRoomRecorder.currentRecordId) {
      const activeState: CloudRecordStatus[] = [
        CloudRecordStatus.New,
        CloudRecordStatus.Pending,
        CloudRecordStatus.Recording,
      ];

      const inactiveState: CloudRecordStatus[] = [
        CloudRecordStatus.Stopping,
        CloudRecordStatus.Stopped,
        CloudRecordStatus.Uploading,
        CloudRecordStatus.Finished,
        CloudRecordStatus.Failed,
      ];

      const isCurrentRecordActive = activeState.includes(this.rootStore.recordStore.cloudRoomRecorder.status);

      const isAnotherRecordId = id !== this.rootStore.recordStore.cloudRoomRecorder.currentRecordId;

      if (isCurrentRecordActive && isAnotherRecordId && inactiveState.includes(recordState)) {
        return;
      }
    }

    if (startedAt) {
      this.rootStore.recordStore.cloudRoomRecorder.setStartedAt(new Date(startedAt).getTime());
    }

    this.rootStore.recordStore.cloudRoomRecorder.setCurrentRecordId(id);
    this.rootStore.recordStore.cloudRoomRecorder.setStatus(recordState);
  }

  @action deletePeer(peerId: string): void {
    this.rootStore.spaceStore.deletePeer(peerId);
  }

  @action clearPeers(): void {
    this.rootStore.spaceStore.clearPeers();
  }

  @action setPeer(peerStore: PeerStore): void {
    this.rootStore.spaceStore.setPeer(peerStore);
  }

  hasPeer(peerId: string): boolean {
    return this.rootStore.spaceStore.hasPeer(peerId);
  }

  @computed get myPeer(): PeerStore | undefined {
    return this.peers.get(this.connectionId as string) || undefined;
  }

  @computed get allPeers(): PeerStore[] {
    return Array.from(this.peers.values());
  }

  @computed get participantPeers(): PeerStore[] {
    return this.allPeers.filter((p) => !p.appData.isBroadcaster); /* TBD: rename to isRecorder */
  }

  @computed get participantPeersInRoom(): PeerStore[] {
    return this.allPeers.filter((p) => p.isJoinApproved);
  }

  @computed get moderatorsPeers(): PeerStore[] {
    return this.participantPeersInRoom.filter((peer) => peer.isModerator);
  }

  @computed get notModeratorsPeers(): PeerStore[] {
    return this.participantPeersInRoom.filter((peer) => !peer.isModerator);
  }

  @computed get participantsWithActiveAudio(): PeerStore[] {
    return this.allPeers.filter((peer) => peer.hasActiveAudio && !peer.isMe);
  }

  @computed get participantsWithActiveAudioWithMe(): PeerStore[] {
    return this.allPeers.filter((peer) => peer.hasActiveAudio);
  }

  @computed get participantsWithActiveVideo(): PeerStore[] {
    return this.allPeers.filter((peer) => peer.hasActiveVideo && !peer.isMe);
  }

  @computed get participantsWithActiveCameras(): PeerStore[] {
    return this.allPeers.filter((peer) => peer.hasActiveCameraTrack && !peer.isMe);
  }

  @computed get participantsWithActiveMics(): PeerStore[] {
    return this.allPeers.filter((peer) => peer.hasActiveMicTrack && !peer.isMe);
  }

  @computed get participantsWithAudioTracks(): PeerStore[] {
    return this.allPeers.filter((peer) => peer.audioTracks.length && !peer.isMe);
  }

  @computed get participantsWithVideoTracks(): PeerStore[] {
    return this.allPeers.filter((peer) => peer.videoTracks.length && !peer.isMe);
  }

  @computed get roomAudioTracks(): MediaTrackStore[] {
    return this.participantsWithAudioTracks.flatMap((peer) => peer.audioTracks);
  }

  @computed get roomAudioTracksWithMe(): MediaTrackStore[] {
    return this.participantsWithActiveAudioWithMe.flatMap((peer) => peer.audioTracks);
  }

  @computed get roomVideoTracks(): MediaTrackStore[] {
    return this.participantsWithVideoTracks.flatMap((peer) => peer.videoTracks);
  }

  @computed get roomScreenVideoTracks(): MediaTrackStore[] {
    return this.allPeers.flatMap((x) => x.videoTracks.filter((y) => y.label === TrackLabel.ScreenVideo));
  }

  @computed get isScreenSharingOpenByMe(): boolean {
    return this.roomScreenVideoTracks.some((x) => x.peerStore.isMe);
  }

  @computed get roomScreenVideoAndBoards(): PeerStore[] {
    return this.rootStore.roomPaginationStore.peersInfo().filter(
      (x) => [TrackLabel.Board, TrackLabel.Broadcast, TrackLabel.ScreenVideo].includes(x.slotAssignment.trackLabel),
    ).map((x) => x.peer);
  }

  @action setRoom({
    id,
    alias,
    name,
    isScreensharingAllowed,
    isChatAllowed,
    isRecordAllowed,
    breakoutRooms,
    joinSettings,
    parentRoomId,
    isAutoRecordingAllowed,
    roleInRoom,
    type,
  }: SetRoomParams): void {
    this.setId(id);
    this.name = name;
    this.isScreensharingAllowed = isScreensharingAllowed;
    this.isChatAllowed = isChatAllowed;
    this.isRecordAllowed = isRecordAllowed;
    this.breakoutRooms = breakoutRooms;
    this.roomAlias = alias;
    this.joinSettings = joinSettings;
    this.parentRoomId = parentRoomId;
    this.isAutoRecordingAllowed = isAutoRecordingAllowed;
    this.roleInRoom = roleInRoom;
    this.type = type;
    this.rootStore.participantStore.setRoleInRoom(roleInRoom, type);
  }

  @computed get room(): Room | null {
    const {
      id,
      roomAlias,
      appId,
      channelId,
      spaceId,
      parentRoomId,
      breakoutRooms,
      name,
      type,
      isScreensharingAllowed,
      isRecordAllowed,
      isChatAllowed,
      isPublic,
      waitingRoomAudience,
      joinSettings,
      isAutoRecordingAllowed,
      roleInRoom,
    } = this;

    if (!id
    || !appId
    || !channelId
    || !spaceId
    || !breakoutRooms
    || !type
    || !waitingRoomAudience
    || !joinSettings
    || !roomAlias) {
      return null;
    }

    return {
      id,
      alias: roomAlias,
      appId,
      channelId,
      spaceId,
      parentRoomId,
      breakoutRooms,
      name,
      type,
      isScreensharingAllowed,
      isRecordAllowed,
      isChatAllowed,
      isPublic,
      waitingRoomAudience,
      joinSettings,
      isAutoRecordingAllowed,
      roleInRoom,
    };
  }

  @action async getRoomByAlias(alias: string): Promise<void> {
    try {
      const room = await this.rootStore.moodHoodApiClient.room.getRoomByAlias(alias);
      if (!room) {
        return;
      }

      this.setId(room.id);
      this.channelId = room.channelId;
      this.appId = room.appId;
      this.spaceId = room.spaceId;
      this.name = room.name || DEFAULT_ROOM_NAME;
      this.waitingRoomAudience = room.waitingRoomAudience;
      this.isAutoRecordingAllowed = room.isAutoRecordingAllowed;
      this.setRoomType(room.type);
      this.roleInRoom = room.roleInRoom ?? RoomRole.Guest;
      window.lsd.spaceId = room.spaceId;
      window.lsd.roomName = this.name;
      window.lsd.roomAlias = room.alias;
      this.rootStore.participantStore.setRoleInRoom(room.roleInRoom, room.type);
      this.setIsRoomInfoLoaded(true);
    } catch (err) {
      this.setIsRoomInfoLoaded(false);
      this.handleRoomFetchError(err as AxiosError);
    }
  }

  @action async createParticipant(payload: CreateParticipantPayload): Promise<ParticipantResponse | null> {
    const {
      spaceId, name, roomId, image, clientUniqueId, role,
    } = payload;

    return this.rootStore.participantStore.create({
      spaceId,
      name,
      roomId,
      image,
      clientUniqueId,
      role,
    });
  }

  @action.bound setIsForceDisconnected(isForceDisconnected: boolean): void {
    this.isForceDisconnected = isForceDisconnected;
    this.rootStore.uiStore.showErrorPage(Errors.DEVICE_IS_DISCONNECTED);
  }

  @action setWithNamesForViewer(withNames: boolean):void {
    this.withNamesForViewer = withNames;
  }

  @action openSlotFullScreen(value: string): void {
    this.slotFullScreenUuid = value;
  }

  @action closeSlotFullScreen(): void {
    this.slotFullScreenUuid = undefined;
  }

  @computed get peers(): Map<string, PeerStore> {
    return this.rootStore.spaceStore.peers;
  }

  @computed get isSlotFullScreenOpened(): boolean {
    return !!this.slotFullScreenUuid;
  }

  @computed get isMiroSlotFullScreen(): boolean {
    return this.slotFullScreenUuid === this.miroBoard.slot?.slotAssignment.slotUuid;
  }

  @action toggleAllParticipantsMuted(): void {
    this.isAllParticipantsMuted = !this.isAllParticipantsMuted;
  }

  @action setApiFailReason(reason: Errors): void {
    this.roomAuthenticationFailReason = reason;
    this.rootStore.uiStore.showErrorPage(reason);
  }

  @action setId(id: string): void {
    this.id = id;
    window.lsd.roomId = id;
  }

  @action async leave(): Promise<void> {
    if (this.roomAlias && this.myPeer?.appData.joinApproval !== JoinApproval.Denied) {
      this.rootStore.metrics.marketing.trackLeaveRoom(this.roomAlias);
    }

    this.publisherSubscriptionQueue.clear();
    if (!this.publisherSubscriptionQueue.isPaused) {
      this.publisherSubscriptionQueue.pause();
    }

    await this.rootStore.participantStore.client.leave();
    window.parent.postMessage(WindowEvent.FinishCall, '*');
    this.setIsRoomJoined(false);
    this.calls.callLimit.stopTimer();
  }

  @action setIsRoomInfoLoaded(value: boolean): void {
    this.isRoomInfoLoaded = value;
  }

  @action setIsRoomJoined(value: boolean): void {
    this.isRoomJoined = value;
  }

  @action setIsRoomJoining(value: boolean): void {
    this.isRoomJoining = value;
  }

  @action setIsPeersLoaded(value: boolean): void {
    this.isPeersAndParticipantsLoaded = value;
  }

  @action setIsConnectionLost(value: boolean): void {
    this.isConnectionLost = value;
  }

  @action setSessionId(value: string): void {
    this.sessionId = value;
  }

  @action setRoomType(roomType: RoomType) {
    this.type = roomType;
  }

  handleAxiosError(err: AxiosError): void {
    if (!err.response?.status) {
      throw err;
    }

    const mapError: { [index: number]: Errors } = {
      401: Errors.AUTHENTICATION_REQUIRED,
      403: Errors.ACCESS_DENIED,
      404: Errors.SOMETHING_WENT_WRONG,
    };

    const errCode = mapError[err.response?.status];
    if (errCode) {
      this.setApiFailReason(errCode);
      return;
    }

    throw err;
  }

  handleRoomFetchError(err: AxiosError): void {
    if (!err.response?.status) {
      this.setApiFailReason(Errors.SOMETHING_WENT_WRONG);
      return;
    }

    const mapError: { [index: number]: Errors } = {
      401: Errors.AUTH_ERROR,
      403: Errors.NO_PERMISSION_FOR_ROOM,
      404: Errors.ROOM_NOT_FOUND_BY_ROOM_ID,
    };

    const errCode = mapError[err.response?.status] || Errors.SOMETHING_WENT_WRONG;
    this.setApiFailReason(errCode);
  }

  async createRoom(
    name: string, spaceId: string, type: RoomType, waitingRoomAudience: WaitingRoomAudience,
  ): Promise<string | null> {
    const { data: room } = await this.rootStore.moodHoodApiClient.room.createRoom({
      spaceId,
      name,
      isPublic: true,
      isScreensharingAllowed: true,
      isChatAllowed: true,
      type,
      waitingRoomAudience,
    });

    return room?.alias || null;
  }

  async createLesson(lessonName: string, spaceId: string): Promise<string | null> {
    return this.createRoom(lessonName, spaceId, RoomType.Lesson, WaitingRoomAudience.Nobody);
  }

  async createWebinar(webinarName: string, spaceId: string): Promise<string | null> {
    return this.createRoom(webinarName, spaceId, RoomType.Webinar, WaitingRoomAudience.Nobody);
  }

  @action setParticipantsInRoom(value: number): void {
    this.allParticipantsCount = value;
  }

  @action handleUpdateRoomParticipantsCount(payload: RoomEventData): void {
    const { participantsCountInRoom } = payload as { participantsCountInRoom: number };
    if (participantsCountInRoom && Number.isInteger(participantsCountInRoom)) {
      this.setParticipantsInRoom(participantsCountInRoom);
      return;
    }

    logger.error('Room update participants count error: no count', payload);
  }

  @computed get visibleMediaStreamsIds(): string[] {
    return Array.from(this.activeMediaStreamTrackIds.values());
  }

  @action addActiveMediaStreamTrackId(id: string): void {
    this.activeMediaStreamTrackIds.add(id);
  }

  @action deleteActiveMediaStreamTrackId(id: string): void {
    this.activeMediaStreamTrackIds.delete(id);
  }

  @action setVideoPlaybackActualizationHash(hash: string): void {
    this.videoPlaybackActualizationHash = hash;
  }

  // Use only with debounce, to avoid race condition, debounce is necessary for swiping peers
  async actualizeVideoTracksPlayback(): Promise<void> {
    // This operation hash is required to have ability to break the operation somewhere in the middle,
    // so that we started to mute/unmute from the beginning and didn't double the actions.
    const currentOperationHash = uuid();
    this.setVideoPlaybackActualizationHash(currentOperationHash);

    const { visibleMediaStreamsIds } = this;
    const muteActions: (() => Promise<unknown>)[] = [];
    const unmuteActions: (() => Promise<unknown>)[] = [];

    this.roomVideoTracks.forEach((item) => {
      if (visibleMediaStreamsIds.includes(item.track.id) && item.isMuted) {
        unmuteActions.push(async () => {
          try {
            await item.unmute();
          } catch (error: unknown) {
            logger.error('Failed to perform unmute video track action', {
              error,
              trackLabel: item.track.label,
              trackId: item.track.id,
              page: this.rootStore.roomPaginationStore.pageNumber,
            });
          }
        });
      } else if (!visibleMediaStreamsIds.includes(item.track.id) && !item.isMuted) {
        muteActions.push(async () => {
          try {
            await item.mute();
          } catch (error: unknown) {
            logger.error('Failed to perform mute video track action', {
              error,
              trackLabel: item.track.label,
              trackId: item.track.id,
              page: this.rootStore.roomPaginationStore.pageNumber,
            });
          }
        });
      }
    });

    await Promise.all(muteActions.map(
      (doAction) => (currentOperationHash === this.videoPlaybackActualizationHash ? doAction() : Promise.resolve()),
    ));

    if (currentOperationHash !== this.videoPlaybackActualizationHash) {
      return;
    }

    for (const actions of chunk(unmuteActions, 5)) { // eslint-disable-line no-restricted-syntax
      if (currentOperationHash !== this.videoPlaybackActualizationHash) {
        break;
      }
      await Promise.all(actions.map((doAction) => doAction())); // eslint-disable-line no-await-in-loop
    }
  }

  @action setIsIframe(value: boolean): void {
    this.isIframe = value;
  }

  @computed get totalDemoSlots(): number {
    return this.roomScreenVideoTracks.length
      + Number(!!this.broadcast.slot)
      + Number(!!this.miroBoard.slot);
  }

  @computed get demoCountLimit(): number {
    if (!this.rootStore.paymentStore.currentPlan?.restrictions.maxNumberOfDemoSlots.enabled) {
      return 1;
    }

    return this.rootStore.paymentStore.currentPlan?.restrictions.maxNumberOfDemoSlots.value;
  }

  @computed get isDemoCountLimitExceed(): boolean {
    return this.totalDemoSlots >= this.demoCountLimit;
  }

  async finishCallForEveryone(): Promise<void> {
    const { spaceId, id: roomId } = this;

    if (!spaceId || !roomId) {
      throw new Error('Insufficient parameters for finishing the call');
    }

    try {
      await this.miroBoard.stopMiro();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('cant stop miro');
    }

    const { error } = await this.rootStore.moodHoodApiClient.room.finishCallForEveryone({ spaceId, roomId });

    if (error) {
      logger.error('Failed to finish call for everyone by some reason', { error, spaceId, roomId });
    }
  }

  @computed get allPublishers(): PublisherStore[] {
    return this.allPeers.reduce((publishers: PublisherStore[], peer) => {
      peer.allPublishers.forEach((publisher) => {
        publishers.push(publisher);
      });

      return publishers;
    }, []);
  }

  subscribePublishers(): void {
    this.allPublishers.forEach((publisher) => {
      const priority = AUDIO_PUBLISHER_LABELS.includes(publisher.label) ? 1 : 0;
      this.publisherSubscriptionQueue.add(() => publisher.subscribe(), { priority });
    });

    this.publisherSubscriptionQueue.start();
  }

  @action setConnectionId(connectionId?: string) {
    this.connectionId = connectionId;
  }

  @computed get isWebinar(): boolean {
    return this.type === RoomType.Webinar;
  }

  @computed get isBreakoutRoom(): boolean {
    return !!this.room?.parentRoomId;
  }

  async updatePeerAppData(peer: PeerStore, newValues: Partial<PeerAppData>): Promise<void> {
    const {
      spaceId, participantId, id: peerId, appData,
    } = peer;
    const currentAppData = appData.getCurrentAppData();
    await this.rootStore.moodHoodApiClient.space.participants.updatePeerAppData(
      spaceId,
      participantId,
      {
        peerId,
        appData: {
          ...currentAppData,
          ...newValues,
        },
      },
    );
  }

  @computed get usersPeerOnScreenInWebinarReachedLimit(): boolean {
    return this.rootStore.roomPaginationStore.visibleUserPeers.length >= SlotsOnPage.videoWebinar;
  }

  async setIsPeerOutOfScreen(peer: PeerStore, value: boolean): Promise<void> {
    if (!value && this.isWebinar && this.usersPeerOnScreenInWebinarReachedLimit) {
      return;
    }

    await this.updatePeerAppData(peer, { isOutOfScreen: value });
  }

  async setPeerCanEnterTheRoom(peer: PeerStore, value: JoinApproval): Promise<void> {
    await this.updatePeerAppData(peer, { joinApproval: value });
  }

  async setPeerCanEnterTheRoomByPeerId(peerId: string, value: JoinApproval): Promise<void> {
    const peer = this.allPeers.find((p) => p.id === peerId);
    if (peer && peer.appData.joinApproval === JoinApproval.Waiting) {
      await this.updatePeerAppData(peer, { joinApproval: value });
    }
  }

  async allowPeersEnterRoom(): Promise<void> {
    await Promise.all(
      this.waitingPeers.map(async (peer) => {
        await this.setPeerCanEnterTheRoom(peer, JoinApproval.Approved);
      }),
    );
  }

  async denyPeersEnterRoom(): Promise<void> {
    await Promise.all(
      this.waitingPeers.map(async (peer) => {
        await this.setPeerCanEnterTheRoom(peer, JoinApproval.Denied);
      }),
    );
  }

  @computed get waitingPeers(): PeerStore[] {
    return this.allPeers.filter((p) => p.appData.joinApproval === JoinApproval.Waiting);
  }

  @computed get waitingPeersIds(): string[] {
    return this.waitingPeers.map((peer) => peer.id);
  }

  @computed get isParticipantInRoom(): boolean {
    if (this.isWebinar) {
      return this.isRoomJoined;
    }

    return this.isRoomJoined && Boolean(this.myPeer?.isJoinApproved);
  }

  @action setIsWhiteLabel(value: boolean) {
    this.isWhiteLabel = value;
  }

  @action handleSwitchOffParticipantsMedia(payload: RoomEventData): void {
    const { isModerator } = this.rootStore.participantStore;
    const { auditory, devicesTypes, initiatorUserId } = payload as {
      auditory: Auditory[],
      devicesTypes: DevicesTypes[],
      initiatorUserId: string,
    };

    if (this.rootStore.userStore.id === initiatorUserId) {
      return;
    }

    const isIAmInAuditory = auditory.reduce((acc, aud) => {
      if (acc) {
        return acc;
      }

      switch (aud) {
        case Auditory.All:
          return true;
        case Auditory.Moderators:
          return isModerator;
        case Auditory.Users:
        case Auditory.Guests:
          return !isModerator;
        default:
          return false;
      }
    }, false);

    if (!isIAmInAuditory) {
      return;
    }

    this.switchOffDevices(devicesTypes);
  }

  switchOffDevices(devicesTypes: DevicesTypes[]): void {
    const { videoDisabled, audioDisabled, isScreenSharingDisabled } = this.rootStore.participantStore;
    devicesTypes.forEach((device) => {
      switch (device) {
        case DevicesTypes.Camera:
          if (!videoDisabled) {
            this.rootStore.participantStore.disableVideo();
            this.rootStore.uiStore.setCommonToast({
              message: 'warnings.adminSwitchedOffVideo',
              type: CommonToastType.Warn,
            });
          }
          break;
        case DevicesTypes.Microphone:
          if (!audioDisabled) {
            this.rootStore.participantStore.disableAudio();
            this.rootStore.uiStore.setCommonToast({
              message: 'warnings.adminSwitchedOffAudio',
              type: CommonToastType.Warn,
            });
          }
          break;
        case DevicesTypes.SharedScreenMedia:
          if (!isScreenSharingDisabled) {
            this.rootStore.participantStore.disableScreensharing();
            this.rootStore.uiStore.setCommonToast({
              message: 'warnings.adminSwitchedOffScreenSharing',
              type: CommonToastType.Warn,
            });
          }
          break;
        default:
      }
    });
  }

  @computed get usersWithRaisedHand(): PeerStore[] {
    return this.rootStore.roomPaginationStore.usersPeers
      .filter((p) => p.isHandRaised)
      .sort((a, b) => {
        if (!a.appData.handRaisedAt) {
          return 1;
        }

        if (a.appData.handRaisedAt && b.appData.handRaisedAt) {
          return Number(a.appData.handRaisedAt) - Number(b.appData.handRaisedAt);
        }

        return -1;
      });
  }

  @computed get usersWithRaisedHandAfterMyJoin(): PeerStore[] {
    const myLoginTime = this.rootStore.participantStore.peer?.loginDate || new Date(0);

    return this.usersWithRaisedHand
      .filter((x) => !x.isMe && new Date(x?.appData.handRaisedAt || 0) >= myLoginTime);
  }

  @computed get showWebinarWaitingRoom(): boolean {
    return this.isRoomJoined
      && this.isWebinar
      && this.rootStore.participantStore.isWebinarGuest
      && this.rootStore.roomPaginationStore.visiblePeers.length < 1
      && !this.miroBoard.slot
      && !this.broadcast.slot;
  }

  @action setAudioContext(value: AudioContext): void {
    this.audioContext = value;
    if ('setSinkId' in AudioContext.prototype) {
      // eslint-disable-next-line
      // @ts-ignore
      this.audioContext?.setSinkId(this.rootStore.participantStore.deviceStore.currentAudioOutputDeviceId || '');
    }
  }

  @action disposeAudioContext(): void {
    this.audioContext?.close();
    this.audioContext = null;
  }

  @action setReloadTracked(value: boolean): void {
    this.reloadTracked = value;
  }

  async getChatUserToken() {
    const { name, clientUniqueId } = this.rootStore.participantStore;
    const username = this.rootStore.userStore.username || name || 'Guest';

    const roomId = this.id;
    const spaceId = this.rootStore.spaceStore.space?.id;
    const userId = this.rootStore.userStore.id || clientUniqueId;
    const chatApplicationId = this.rootStore.spaceStore.space?.chatToken;

    if (!chatApplicationId || !userId || !spaceId || !clientUniqueId || !roomId) {
      const fields = JSON.stringify({
        userId,
        roomId,
        spaceId,
        clientUniqueId,
        chatApplicationId,
      }, (_, value: unknown) => (value === undefined ? null : value));

      throw new Error(`Failed to get chat token in room: one of ${fields} is missing`);
    }

    const { userToken, error } = await this.rootStore.moodHoodApiClient.user.getOrCreateRoomChatToken({
      roomId,
      userId,
      spaceId,
      clientUniqueId,
      chatApplicationId,
      username,
    });

    if (error) {
      throw new Error(`Failed to get chat token in room: ${error}`);
    }

    if (!userToken) {
      throw new Error('Failed to get chat token in room: no token');
    }

    return userToken;
  }

  @lock('isOnlineUsersCountFetching')
  @action async getOnlineUsersCount(spaceId: string, roomid: string): Promise<void> {
    const { data } = await this.rootStore.moodHoodApiClient.space.getStats(spaceId);
    if (data) {
      this.onlineUsersCount = data.totalByRooms[roomid];
    }
  }
}

export default RoomStore;
