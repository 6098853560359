import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Crop } from 'react-image-crop';

import { drawImage } from 'ui-kit/components/ImageUploader/helpers';

import { BigPreview, SmallPreview } from './Layout';

interface UserAvatarPreviewProps {
  crop?: Crop,
  image?: HTMLImageElement;
}

const UserAvatarPreview: FunctionComponent<UserAvatarPreviewProps> = ({
  crop,
  image: initialImage,
}) => {
  const bigPreviewCanvasRef = useRef<HTMLCanvasElement>(null);
  const smallPreviewCanvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    drawImage(bigPreviewCanvasRef.current, initialImage, crop);
    drawImage(smallPreviewCanvasRef.current, initialImage, crop);
  }, [crop, initialImage]);

  return (
    <>
      <BigPreview width={140} ref={bigPreviewCanvasRef} />
      <SmallPreview width={32} ref={smallPreviewCanvasRef} />
    </>
  );
};

export default UserAvatarPreview;
