import { useEffect, useState } from 'react';
import { intervalToDuration } from 'date-fns';

import useTimer from 'hooks/useTimer';

const INITIAL_TIME = { minutes: '00', seconds: '00' };

const useRestTime = (endTime: Date) => {
  const [restTime, setRestTime] = useState<typeof INITIAL_TIME>(INITIAL_TIME);

  const [startTimer, stopTimer] = useTimer(() => {
    const now = new Date();
    const { minutes = 0, seconds = 0 } = intervalToDuration({
      start: now,
      end: endTime,
    });

    const fullMinutes = minutes.toString().padStart(2, '0');
    const fullSeconds = seconds.toString().padStart(2, '0');

    if (now >= endTime) {
      stopTimer();
    }

    setRestTime({ minutes: fullMinutes, seconds: fullSeconds });
  }, 100);

  useEffect(() => {
    setRestTime(INITIAL_TIME);
    startTimer();

    return stopTimer;
  }, []);

  return restTime;
};

export default useRestTime;
