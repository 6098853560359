import { AxiosInstance } from 'axios';
import { IssueCreatePayload, IssuePushDumpPayload } from './types';

class IssueApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async createIssue(payload: IssueCreatePayload): Promise<string> {
    const { data } = await this.api.post<{ id: string }>('/issues', payload);
    return data.id;
  }

  async pushIssuerDump(payload: IssuePushDumpPayload): Promise<void> {
    await this.api.put(`/issues/${payload.id}/push-issuer-dump`, payload);
  }

  async pushDefendantDump(payload: IssuePushDumpPayload): Promise<void> {
    await this.api.put(`/issues/${payload.id}/push-defendant-dump`, payload);
  }
}

export default IssueApi;
