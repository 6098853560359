import React, {
  useState,
  useEffect,
  useCallback,
  FunctionComponent,
} from 'react';
import { observer } from 'mobx-react-lite';
import { Global, css } from '@emotion/react';
import { useParams } from 'react-router-dom';

import Chat from 'components/Chat/ChatWithReload';
import useStores from 'stores/root';
import { isCorrectChatNewWindowOpener } from 'helpers/common';
import useRoomChatPopupStateTracking from 'hooks/room/useRoomChatPopupStateTracking';
import { LANDING_PAGE_URL } from 'constants/common';

interface QueryParams {
  roomAlias: string,
}

const ChatPopup: FunctionComponent = observer(() => {
  const { roomAlias } = useParams<QueryParams>();

  const {
    spaceStore: { space },
  } = useStores();

  const [isReady, setIsReady] = useState(false);

  useRoomChatPopupStateTracking();

  const onUnload = useCallback(() => {
    const iid = setInterval(() => {
      if (!isCorrectChatNewWindowOpener(roomAlias)) {
        clearInterval(iid);
        window.opener?.removeEventListener('unload', onUnload);
        window.close();
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (!isCorrectChatNewWindowOpener(roomAlias)) {
      window.location.href = LANDING_PAGE_URL;
      return () => {};
    }

    setIsReady(true);

    window.opener?.addEventListener('unload', onUnload);

    return () => {
      window.opener?.removeEventListener('unload', onUnload);
    };
  }, []);

  if (!isReady) {
    return null;
  }

  return (
    <>
      <Global
        styles={css`
          [data-panel-name='chat'] {
            & > div {
              border-radius: 0;
            }
          }
        `}
      />
      <Chat
        /* we need to wait till space info is fetched too */
        isVisible={Boolean(space?.id)}
        style={{
          height: '100dvh',
          width: '100dvw',
        }}
      />
    </>
  );
});

export default ChatPopup;
