import styled from '@emotion/styled';

import { IconButton as DefaultButton, Input as DefaultInput } from 'ui-kit';
import { Font } from 'ui-kit/theme';

export const Layout = styled.div({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& > button': {
    marginTop: 30,
  },
});

export const Form = styled.form({
  width: 300,
  marginTop: 24,
});

export const ShortButton = styled(DefaultButton)({
  '&:last-child': {
    marginLeft: 16,
  },
});

export const Input = styled(DefaultInput)({
  marginBottom: 0,
});

export const ServerError = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 16,
});

export const Buttons = styled.div({
  marginTop: 8,
  width: 'inherit',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const DesktopTitle = styled.div({
  fontFamily: Font.BRAND_BOLD,
  fontSize: 48,
  lineHeight: '60px',
  textAlign: 'center',
});

export const MobileTitle = styled.div({
  fontFamily: Font.BRAND_BOLD,
  fontSize: 24,
  lineHeight: '30px',
  textAlign: 'center',
});
