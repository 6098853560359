/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { FunctionComponent, useEffect, useLayoutEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { areCookiesBlocked, isInsideIFrame } from 'helpers/browser';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import {
  HiddenFromRecorder,
  LivedigitalTeleboss,
  NotWhiteLabelIFrame,
} from 'components/Optional';
import { MatchMedia, landscapeMediaQuery } from 'ui-kit/theme';
import { ToastNoticeContainer } from 'ui-kit/components/ToastNotice';
import { ToastHelpContainer } from 'components/ToastHelp';
import Auth from 'components/Auth/Auth';
import Routes from 'components/Routes';
import FeaturesPanel from 'components/FeaturesPanel';
import FloatSupportButton from 'components/Space/Buttons/FloatSupportButton';
import useLanguage from 'hooks/useLanguage';
import useStores from 'stores/root';
import { ToastContainers } from 'constants/common';
import { ToastActionContainer } from 'components/ToastAction';
import useFeature from 'hooks/useFeature';
import useVideoConfig from 'hooks/useVideoConfig';
import Features from 'constants/features';
import WithErrorPage from 'components/ErrorPage';
import useLaunchDarkly from 'hooks/useLaunchDarkly';
import logger from 'helpers/logger';
import UnsupportedOS from 'components/Dialogs/UnsupportedOS';
import { CommonSnackbarContainer } from 'ui-kit/components/Snackbar';
import DesktopAppUpdater from 'components/DesktopAppUpdater';

import 'reset-css';
import 'animate.css';
import './App.scss';
import useMediaQuery from 'hooks/useMediaQuery';
import AppVersion from 'components/AppVersion';
import { useTheme } from '@emotion/react';
import { getBrandOptions } from 'modules/Branding';
import { TopCenterSnackbarContainer } from 'components/ToastTopCenter/TopCenterSnackbar';

import { setCookiesExternalReferrer } from '../../helpers/pageMeta';

const App: FunctionComponent = observer(() => {
  const {
    participantStore,
    uiStore,
    roomStore,
  } = useStores();
  useLaunchDarkly();
  useLanguage();
  useVideoConfig();
  useFeature(Features.HidePeersWoVideo);
  useMediaQuery(landscapeMediaQuery, (hasMatch: boolean) => { uiStore.setIsLandscape(hasMatch); });
  useMediaQuery(MatchMedia.Width640, (hasMatch: boolean) => { uiStore.setIsCompactWidth(hasMatch); });
  useMediaQuery(MatchMedia.Height480, (hasMatch: boolean) => { uiStore.setIsCompactHeight(hasMatch); });
  const isPanelsShown = (roomStore.id && !roomStore.isSlotFullScreenOpened)
    || (roomStore.isSlotFullScreenOpened && uiStore.isRoomFullScreenControlsVisible);

  const theme = useTheme();

  const onTabSwitch = () => {
    uiStore.setIsTabActive(!document.hidden);
  };

  useLayoutEffect(() => {
    onTabSwitch();
    document.addEventListener('visibilitychange', onTabSwitch);

    return () => {
      document.removeEventListener('visibilitychange', onTabSwitch);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search.toLowerCase());

    const isDrone = urlParams.get('drone');
    if (isDrone) {
      participantStore.setIsDrone(true);
    }

    const isNoChat = urlParams.get('nochat');
    if (isNoChat) {
      participantStore.setIsNoChat(true);
    }

    roomStore.setIsIframe(isInsideIFrame());
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_FOR_ELECTRON) {
      return;
    }

    if (areCookiesBlocked()) {
      logger.warn('Client cookies are blocked');
      return;
    }

    setCookiesExternalReferrer();
  }, []);

  const { pageTitle } = getBrandOptions();

  const { t } = useTranslation();

  return (
    <DesktopAppUpdater>
      <NotWhiteLabelIFrame>
        <Helmet
          titleTemplate={`${t(pageTitle)} - %s`}
          defaultTitle={`${t(pageTitle)} - ${t('common.pageTitle.description')}`}
        />
        <LivedigitalTeleboss>
          <FloatSupportButton />
        </LivedigitalTeleboss>
      </NotWhiteLabelIFrame>
      <WithErrorPage>
        <div className="App">
          <AppVersion />
          <Auth>
            <Routes />
          </Auth>
          <HiddenFromRecorder>
            {/* FIXME: default toast container should have containerId,
            see https://github.com/fkhadra/react-toastify/issues/855 */}
            <ToastNoticeContainer isPanelsShown={isPanelsShown} emotionTheme={theme} />
            {/* a separate toast container, since default container has no containerId and cannot be updated */}
            <CommonSnackbarContainer isPanelsShown={isPanelsShown} />
            <TopCenterSnackbarContainer isPanelsShown={isPanelsShown} />
            <ToastNoticeContainer
              isPanelsShown={isPanelsShown}
              containerId={ToastContainers.Feedback}
              emotionTheme={theme}
            />
            <ToastHelpContainer containerId={ToastContainers.Help} />
            <ToastActionContainer containerId={ToastContainers.Action} />
            <FeaturesPanel />
            {/* 'poll-container' is container name in chat. Used if exists */}
            <div id="poll-container" />
            <UnsupportedOS />
          </HiddenFromRecorder>
        </div>
      </WithErrorPage>
    </DesktopAppUpdater>
  );
});

export default App;
