import styled from '@emotion/styled';

import { Color } from 'ui-kit/theme';

const getHeight = (width: number) => Math.round(width * 0.27);

export const BigPreviewContainer = styled.div<{ width: number }>(({ width }) => ({
  width,
  height: getHeight(width),
  display: 'flex',
  borderRadius: 5,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: Color.GREY2,
}));

export const Preview = styled.canvas<{ width: number }>(({ width }) => ({
  maxWidth: width,
  maxHeight: getHeight(width) - 2,
  borderRadius: 5,
  margin: 'auto auto auto auto',
}));

export const SmallPreviewContainer = styled(BigPreviewContainer)({
  marginTop: 24,
});
