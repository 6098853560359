import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import resources, { SupportLanguages } from './locales';

const fallbackLng: SupportLanguages = 'en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng,
    debug: false,
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator'],
      lookupLocalStorage: 'lang',
      lookupCookie: 'lang',
      lookupQuerystring: 'lang',
    },
    interpolation: {
      escapeValue: false,
    },
  });

export const getCurrentLanguage = (): SupportLanguages => {
  const { language } = i18n;
  const detectedLng = language && language.split('-')[0];
  return Object.keys(resources).includes(detectedLng)
    ? detectedLng as SupportLanguages
    : fallbackLng;
};

export const getPluralWord = (key: string, num?: number): string => {
  const words = i18n.t(
    `wordsEnding.${key}`,
    {
      returnObjects: true,
      defaultValue: ['', '', ''],
    },
  );

  if (!num) {
    return words[2] || words[1];
  }

  const currentLanguage = getCurrentLanguage();

  if (currentLanguage === 'ru') {
    if (num % 10 === 1 && num % 100 !== 11) {
      return words[0];
    }

    if (num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)) {
      return words[1];
    }

    return words[2] || words[1];
  }

  if (num === 1) {
    return words[0];
  }

  return words[1];
};

export const bytesToSize = (bytes?: number, decimals = 2): string => {
  const units: string[] = i18n.t(
    'sizeInBytes',
    {
      returnObjects: true,
      defaultValue: ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB'],
    },
  );

  if (bytes === 0) {
    return `0 ${units[0] || ''}`;
  }

  if (!bytes) {
    return i18n.t('unknownSize');
  }

  const k = 1024;

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  const value = bytes / (k ** i);

  const formattedSize = parseFloat(value.toFixed(decimals));

  const plural = getPluralWord('byte', value);
  units[0] = plural;

  return `${formattedSize} ${units[i]}`;
};

export default i18n;
