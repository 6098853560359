import styled from '@emotion/styled';

import { FigmaTypography } from 'ui-kit';
import { MediaBreakPoint } from 'ui-kit/theme';

export const Plans = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexShrink: 1,
  overflow: 'hidden',
  width: 'inherit',
  minHeight: 0,
  gridGap: 16,
  marginBottom: 'auto',
  overflowX: 'auto',
});

export const Info = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'end',
  marginBottom: 24,
  [MediaBreakPoint.WIDTH_568]: {
    marginBottom: 16,
    flexDirection: 'column',
    '& > *': {
      width: '100%',
    },
  },
});

export const CurrentPlan = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  [MediaBreakPoint.WIDTH_568]: {
    flexDirection: 'row',
  },
  justifyContent: 'space-between',
});

export const PlanName = styled(FigmaTypography)(({ theme }) => ({
  whiteSpace: 'nowrap',
  color: theme.colors.secondaryBase,
  marginRight: 8,
}));

export const PlanNameContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'self-start',
});

export const PlansTitle = styled(FigmaTypography)({
  marginBottom: 8,
  whiteSpace: 'nowrap',
  [MediaBreakPoint.WIDTH_640]: {
    fontSize: '16px',
    lineHeight: '20px',
  },
  [MediaBreakPoint.WIDTH_568]: {
    width: '100%',
  },
});

const Layout = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  maxWidth: '100dvw',
  height: '100%',
  overflowY: 'auto',
  background: theme.colors.bg,
  borderRadius: 10,
  padding: '24px 32px',
  [MediaBreakPoint.WIDTH_640]: {
    padding: '24px 16px 16px 16px',
  },
  [MediaBreakPoint.WIDTH_568]: {
    padding: '16px 8px 8px 8px',
  },
}));

export default Layout;
