import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';

import { isBrandLivedigital, isBrandTeleBoss } from 'helpers/url';

const LivedigitalTeleBoss: FunctionComponent = observer(({ children }) => {
  if (!isBrandLivedigital() && !isBrandTeleBoss()) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
});

export default LivedigitalTeleBoss;
