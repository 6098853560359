import React, { FunctionComponent } from 'react';
import DatePicker, { DatePickerProps, CalendarProps } from 'react-multi-date-picker';
import { cx } from '@emotion/css';
import { useTheme } from '@emotion/react';

import { Flex } from 'ui-kit';

import {
  customInputStyle,
  customContainerStyle,
  Label,
} from './index.styled';
import locales from './locales';
import { SupportedLanguages } from './types';

type ReactDatePickerProps = DatePickerProps & CalendarProps & {
  label?: string,
  currentLocale?: SupportedLanguages;
};

const ReactDatePicker: FunctionComponent<ReactDatePickerProps> = ({
  id = '',
  inputClass,
  containerClassName,
  className,
  label,
  currentLocale = 'en',
  ...props
}) => {
  const theme = useTheme();

  return (
    <Flex className={className} flexDirection="column">
      {label && (
      <Label
        as="label"
        size="label-text-bold"
        id={`${id}-label`}
        htmlFor={`${id}-date-picker`}
      >
        {label}
      </Label>
      )}
      <DatePicker
        locale={locales[currentLocale]}
        inputClass={cx(customInputStyle(theme), inputClass)}
        containerClassName={cx(customContainerStyle(theme), containerClassName)}
        {...props}
      />
    </Flex>
  );
};

export default ReactDatePicker;
