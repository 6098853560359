import styled from '@emotion/styled';

import { Font } from 'ui-kit/theme';

export const Cards = styled.div({
  flexGrow: 1,
  display: 'grid',
  height: 0,
  overflowY: 'auto',
  overflowX: 'hidden',
  gap: 30,
  gridTemplateColumns: 'repeat(auto-fill, 100%)',
  gridAutoRows: 'min-content',
  padding: '0 24px',
});

export const Header = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 14,
  '&::placeholder': {
    color: '#6C747E',
  },
});

export const Title = styled.input(({ readOnly }) => ({
  flexGrow: 1,
  padding: 0,
  margin: 0,
  border: 'none',
  background: 'none',
  fontFamily: Font.BRAND,
  fontSize: '48px',
  fontWeight: 700,
  letterSpacing: '0.01em',
  color: 'inherit',
  cursor: readOnly ? 'default' : 'text',
  '&:focus': {
    outline: 'none',
    border: 'none',
  },
}));

const Layout = styled.div(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  width: '100%',
  color: theme.colors.primary,
  fontFamily: Font.BRAND,
}));

export default Layout;
