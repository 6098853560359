import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { v4 as uuid } from 'uuid';
import { serializeError } from 'serialize-error';
import { Logger } from '../types/logger';

export const TRACE_ID_HEADER_NAME = 'x-tr-id';
export const CLIENT_ID_HEADER_NAME = 'x-cl-id';
export const TAB_ID_HEADER_NAME = 'x-tab-id';

export const setTraceIdInterceptor = (config: AxiosRequestConfig) => {
  const { headers } = config;
  headers.common[TRACE_ID_HEADER_NAME] = uuid();
  return config;
};

export const errorToJson = (err: Error | AxiosError | unknown): Record<string, unknown> => {
  const error = err instanceof Error ? err : new Error(String(err));

  if (!axios.isAxiosError(error)) {
    return serializeError(error);
  }

  const {
    config,
    code,
    response,
  } = error;

  let data: Record<string, unknown> | undefined;

  try {
    data = config.data ? JSON.parse(config.data as string) : undefined;
  } catch (e) {
    data = undefined;
  }

  return {
    config: {
      url: config.url,
      method: config.method,
      timeout: config.timeout,
      baseUrl: config.baseURL,
      data: {
        grant_type: data?.grant_type,
        client_id: data?.client_id,
        username: data?.username,
      },
    },
    code,
    response: {
      status: response?.status,
      data: response?.data,
      headers: response?.headers,
      response: {
        responseURL: response?.request.responseURL,
        status: response?.request.status,
        responseText: response?.request.responseText,
      },
    },
    stack: error.stack,
    message: error.message,
    'axios-retry': error.config['axios-retry'],
    [CLIENT_ID_HEADER_NAME]: error.config.headers[CLIENT_ID_HEADER_NAME],
    [TRACE_ID_HEADER_NAME]: error.config.headers[TRACE_ID_HEADER_NAME],
    [TAB_ID_HEADER_NAME]: error.config.headers[TAB_ID_HEADER_NAME],
  };
};

export const isNetworkRelatedError = (
  error: Error & { code?: string },
): boolean => error.code === 'ECONNABORTED' || error.message?.toLowerCase() === 'network error'
|| error.message === 'connection_refused';

export const isOneOfHttpMethods = (
  method: string | undefined,
  ...methods: ('get' | 'post' | 'put' | 'delete')[]
): boolean => !!method && methods.some((m) => m.toLowerCase() === method.toLowerCase());

export const createLogOnRetryDataFunc = (params: { apiName: string; logger: Logger }) => (
  retryCount: number,
  error: AxiosError,
  requestConfig: AxiosRequestConfig,
) => {
  params.logger.warn('Retrying request to API', {
    apiName: params.apiName,
    error: errorToJson(error),
    attempt: retryCount,
    url: requestConfig.url,
    method: requestConfig.method,
  });
};
