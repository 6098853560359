import styled from '@emotion/styled';
import { Font, ZIndex } from 'ui-kit/theme';

export const Arrow = styled('div')`
  height: 1rem;
  position: absolute;
  width: 1rem;
  pointer-events: none;

  &::before {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    width: 0;
  }

  &::after {
    border-style: solid;
    content: '';
    display: block;
    height: 0;
    margin: auto;
    position: absolute;
    width: 0;
  }
`;

export const TooltipContainer = styled('div')<{ backgroundColor?: string; }>`
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.primary};
  border-radius: 8px;
  border: 1px solid ${({ theme, backgroundColor }) => backgroundColor || theme.colors.primary};
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16);
  color: ${({ theme }) => theme.colors.bg};
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  transition: opacity 0.3s;
  z-index: ${ZIndex.TOOLTIP};
  font-family: ${Font.BRAND_MEDIUM};

  &[data-popper-interactive='false'] {
    pointer-events: none;
  }

  &[data-popper-placement*='bottom'] ${Arrow} {
    left: 0;
    margin-top: -0.4rem;
    top: 0;
  }

  &[data-popper-placement*='bottom'] ${Arrow}::before {
    border-color: transparent transparent ${({
    theme, backgroundColor,
  }) => backgroundColor || theme.colors.primary} transparent;
    border-width: 0 0.5rem 0.4rem 0.5rem;
    position: absolute;
    top: -1px;
  }

  &[data-popper-placement*='bottom'] ${Arrow}::after {
    border-color: transparent transparent ${({
    theme, backgroundColor,
  }) => backgroundColor || theme.colors.primary} transparent;
    border-width: 0 0.5rem 0.4rem 0.5rem;
  }

  &[data-popper-placement*='top'] ${Arrow} {
    bottom: 0;
    left: 0;
    margin-bottom: -1rem;
  }

  &[data-popper-placement*='top'] ${Arrow}::before {
    border-color: ${({
    theme, backgroundColor,
  }) => backgroundColor || theme.colors.primary} transparent transparent transparent;
    border-width: 0.4rem 0.5rem 0 0.5rem;
    position: absolute;
    top: 0px;
  }

  &[data-popper-placement*='top'] ${Arrow}::after {
    border-color: ${({
    theme, backgroundColor,
  }) => backgroundColor || theme.colors.primary} transparent transparent transparent;
    border-width: 0.4rem 0.5rem 0 0.5rem;
  }

  &[data-popper-placement*='right'] ${Arrow} {
    left: 0;
    margin-left: -0.7rem;
  }

  &[data-popper-placement*='right'] ${Arrow}::before {
    border-color: transparent ${({
    theme, backgroundColor,
  }) => backgroundColor || theme.colors.primary} transparent transparent;
    border-width: 0.5rem 0.4rem 0.5rem 0;
  }

  &[data-popper-placement*='right'] ${Arrow}::after {
    border-color: transparent ${({
    theme, backgroundColor,
  }) => backgroundColor || theme.colors.primary} transparent transparent;
    border-width: 0.5rem 0.4rem 0.5rem 0;
    left: 6px;
    top: 0;
  }

  &[data-popper-placement*='left'] ${Arrow} {
    margin-right: -0.7rem;
    right: 0;
  }

  &[data-popper-placement*='left'] ${Arrow}::before {
    border-color: transparent transparent transparent ${({
    theme, backgroundColor,
  }) => backgroundColor || theme.colors.primary};
    border-width: 0.5rem 0 0.5rem 0.4em;
  }

  &[data-popper-placement*='left'] ${Arrow}::after {
    border-color: transparent transparent transparent ${({
    theme, backgroundColor,
  }) => backgroundColor || theme.colors.primary};
    border-width: 0.5rem 0 0.5rem 0.4em;
    left: 3px;
    top: 0;
  }
`;
