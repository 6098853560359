import styled from '@emotion/styled';

interface FlexProps {
  gap?: number;
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  fullWidth?: boolean;
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  gap: ${({ gap }) => (gap ? `${gap}px` : undefined)};
  flex-direction:  ${({ flexDirection }) => flexDirection};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : undefined)};
`;

export const FlexCenter = styled(Flex)`
  align-items: center;
`;

export const FlexCenterCenter = styled(FlexCenter)`
  justify-content: center;
`;

export const FlexSpaceBetween = styled(Flex)`
  justify-content: space-between;
`;

export const FlexCenterSpaceBetween = styled(Flex)`
  justify-content: space-between;
`.withComponent(FlexCenter);

const FlexColumnGap = styled(Flex)`
  & > * + * {
    margin-left: ${({ gap = 8 }) => `${gap}px`};
  }
`;

export const FlexCenterColumnGap = FlexCenter.withComponent(FlexColumnGap);
