import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { isMobile } from 'is-mobile';
import { observer } from 'mobx-react-lite';
import i18n from 'services/i18n';

import useStores from 'stores/root';
import { StatsId } from 'types/stats';

import {
  ServerError,
  ConfirmButton,
} from '../Dialog/index.styled';
import { ProfileStatesNames } from '../type';
import { MobileLayout } from '../MobileLayout';
import { DesktopLayout } from '../DesktopLayout';

const ChangePassword: FunctionComponent = observer(() => {
  const { userStore, uiStore } = useStores();
  const [serverError, setServerError] = useState<string | undefined>();
  const { t } = useTranslation();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const getReCaptchaToken = async ():Promise<string> => {
    if (!executeRecaptcha) {
      return '';
    }
    const token: string = await executeRecaptcha('requestChangePassword');
    return token;
  };

  const handleRequestPasswordChanging = async () => {
    await userStore.updateUserPassword({
      getCaptcha: getReCaptchaToken,
    });

    if (!userStore.passwordUpdatingError) {
      uiStore.setUserProfileDialogState(ProfileStatesNames.CHANGE_PASSWORD_REQUEST_SUCCESS);
    }
  };

  useEffect(() => {
    if (!userStore.passwordUpdatingError) {
      setServerError(undefined);
      return;
    }
    setServerError(i18n.t('errors.somethingWentWrong'));
  }, [userStore.passwordUpdatingError]);

  const Layout = isMobile() ? MobileLayout : DesktopLayout;

  return (
    <Layout>
      <ConfirmButton
        id="request-change-password"
        data-stats={StatsId.ProfileChangePasswordConfirm}
        onClick={handleRequestPasswordChanging}
        variant="primary"
      >
        {t('userProfile.resetPassword')}
      </ConfirmButton>
      {Boolean(serverError) && <ServerError>{serverError}</ServerError>}
    </Layout>
  );
});

export default ChangePassword;
