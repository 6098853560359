import { ReactComponent as IconLight } from './icon-ld-light.svg';
import { ReactComponent as IconDark } from './icon-ld-dark.svg';
import { ReactComponent as LogoLight } from './logo-ld-light.svg';
import { ReactComponent as LogoDark } from './logo-ld-dark.svg';
import { ReactComponent as LogoMaxLight } from './logo-ld-max-light.svg';
import { ReactComponent as LogoMaxDark } from './logo-ld-max-dark.svg';
import PageBack from './pageBack.png';

export const LivedigitalImages = {
  PageBack,
  IconLight,
  IconDark,
  LogoLight,
  LogoDark,
  LogoMaxLight,
  LogoMaxDark,
};
