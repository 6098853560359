import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import useStores from 'stores/root';
import Popup from 'ui-kit/components/Popup';
import { PopupProps } from 'ui-kit/components/Popup/types';
import { Menu, MenuItem } from 'ui-kit/components/DropDownButton';
import { Color } from 'ui-kit/theme';
import { MenuDivider } from 'ui-kit/components/Menu/Layout';
import { LivedigitalTeleboss } from 'components/Optional';
import { StatsId } from 'types/stats';

interface UserMenuMobileProps extends PopupProps {
  handleProfileClick: () => void;
  handleLogoutClick: () => void;
}

const UserMenuMobile: FunctionComponent<UserMenuMobileProps> = ({
  handleProfileClick,
  handleLogoutClick,
  ...restProps
}) => {
  const { t } = useTranslation();
  const {
    authStore,
    participantStore: { isModerator },
    rootStore: { carrotQuest },
    spaceStore: { space },
  } = useStores();
  const { isReady } = carrotQuest;

  const handleClick = () => {
    carrotQuest.openChat(space?.id);
  };

  return (
    <Popup {...restProps}>
      <Menu>
        <MenuItem
          id="profile-edit"
          data-stats={StatsId.RoomsProfileEdit}
          onClick={handleProfileClick}
        >
          {t('common.profile')}
        </MenuItem>
        <LivedigitalTeleboss>
          {isModerator && (
            <MenuItem
              id="support"
              data-stats={StatsId.RoomsSupport}
              onClick={handleClick}
              disabled={!isReady}
            >
              {t('common.support')}
            </MenuItem>
          )}
        </LivedigitalTeleboss>
        {authStore.isUser && (
          <>
            <MenuDivider />
            <MenuItem
              id="profile-logout"
              data-stats={StatsId.RoomsProfileLogout}
              color={Color.RED0}
              onClick={handleLogoutClick}
            >
              {t('common.logout')}
            </MenuItem>
          </>
        )}
      </Menu>
    </Popup>
  );
};

export default UserMenuMobile;
