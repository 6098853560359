import React, { FunctionComponent } from 'react';

import ShowContentFor, { ShowContentProps } from './ShowContentFor';

const DontShowContentFor: FunctionComponent<Omit<ShowContentProps, 'allExcept'>> = ({
  children,
  ...restProps
}) => (
  <ShowContentFor allExcept {...restProps}>
    {children}
  </ShowContentFor>
);

export default DontShowContentFor;
