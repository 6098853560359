import { AxiosError } from 'axios';
import {
  action, computed, makeAutoObservable, observable, reaction,
} from 'mobx';

import i18n, { getCurrentLanguage } from 'services/i18n';
import { ApiResponse } from 'services/MoodHoodApiClient/types';
import { UpdateUserPasswordPayload, UserInfo } from 'types/common';
import { getApiErrorResponse } from 'helpers/errors';
import TimeZones from 'services/i18n/timeZones';
import ParticipantInfo from 'modules/ParticipantInfo';
import UserApi from 'services/MoodHoodApiClient/UserApi';
import Api from 'services/MoodHoodApiClient/Api';
import { getPageUTMParams } from 'helpers/utm';
import { getPageMetaParams } from 'helpers/pageMeta';

import { Errors } from '../constants/errors';
import logger from '../helpers/logger';
import { errorToJson } from '../helpers/apiClient';
import type AuthStore from './auth';

class UserStore {
  private userApi: UserApi;

  auth: AuthStore;

  id?: string;

  username?: string = undefined;

  email?: string = undefined;

  phone?: string = undefined;

  image: string | null = null;

  carrotQuestToken?: string = undefined;

  createdAt: Date = new Date();

  isEmailUpdating = false;

  emailUpdatingError?: Errors;

  isPasswordUpdating = false;

  passwordUpdatingError?: string;

  isUserUpdating = false;

  isUserImageUpdated = false;

  userUpdatingError?: string;

  chatUserUpdatingError?: string;

  currentLanguage: string;

  @observable isDebugger?: boolean;

  @observable isUserInfoFetched = false;

  @observable isLoading = false;

  @observable error?: AxiosError;

  constructor(auth: AuthStore) {
    this.auth = auth;
    this.userApi = new UserApi(Api);
    this.currentLanguage = getCurrentLanguage();
    makeAutoObservable(this);
    reaction(
      () => this.auth.accessTokenDecoded.ownerId,
      (ownerId) => {
        if (ownerId && this.auth.isUser) {
          logger.info('Got user token info. Fetching user data', { ownerId });

          this.fetchUserInfo();
        } else {
          logger.info('Token ownerId has changed', {
            ownerId,
            aud: this.auth.accessTokenDecoded?.aud,
          });
        }
      },
      {
        fireImmediately: true,
      },
    );

    reaction(
      () => this.userInfo,
      (userInfo) => {
        if (userInfo) {
          const { userId, email, phone } = userInfo;
          window.lsd.email = email;
          window.lsd.userId = userId;
          window.lsd.phone = phone;
        }
      },
    );
  }

  @action async createUser({
    username,
    email,
    password,
    phone,
    getReCaptchaToken,
  }: {
    username: string,
    email: string,
    password: string,
    phone: string,
    getReCaptchaToken: () => Promise<string>,
  }): Promise<void> {
    this.error = undefined;
    this.isLoading = true;

    logger.info('Handling create user', { username, case: 'user.createUser' });

    try {
      const captchaToken = await getReCaptchaToken();
      const utmParams = getPageUTMParams();
      const metaParams = getPageMetaParams();

      await this.userApi.createUser({
        username,
        email,
        password,
        phone,
        captchaToken,
        utm: utmParams,
        meta: metaParams,
      });
    } catch (error: unknown) {
      logger.error('Failed to create user', {
        email,
        phone,
        username,
        error: errorToJson(error),
      });

      this.error = error as AxiosError;
    }

    this.isLoading = false;
  }

  @action async updateUser({
    username,
    image,
    targetUse,
    referralCode,
  }: {
    username?: string,
    image?: string,
    targetUse?: string,
    referralCode?: string
  }): ApiResponse<undefined> {
    this.isUserUpdating = true;
    this.userUpdatingError = undefined;

    try {
      await this.userApi.updateUserProfile({
        username, image, targetUse, referralCode,
      });
      if (username) {
        this.username = username;
      }

      if (image) {
        this.image = image;
      }

      this.isUserUpdating = false;

      return {};
    } catch (e) {
      const { code, error } = ((e as AxiosError)?.response?.data) || {};

      this.userUpdatingError = code || error || Errors.SOMETHING_WENT_WRONG;

      this.isUserUpdating = false;

      return {
        error: code || error || Errors.SOMETHING_WENT_WRONG,
      };
    }
  }

  @action async updateUserEmail(email: string, getCaptcha: () => Promise<string>): Promise<void> {
    this.setIsEmailUpdating(true);
    this.clearEmailUpdatingError();

    try {
      const captcha = await getCaptcha();
      await this.userApi.requestChangeEmail(email, captcha);
    } catch (e) {
      this.setEmailUpdatingError(getApiErrorResponse(e) as Errors);
    } finally {
      this.setIsEmailUpdating(false);
    }
  }

  @action clearEmailUpdatingError(): void {
    this.setEmailUpdatingError(undefined);
  }

  @action setEmailUpdatingError(err?: Errors): void {
    this.emailUpdatingError = err;
  }

  @action async finishUpdateUserEmail(confirmationToken: string): Promise<void> {
    this.setIsEmailUpdating(true);
    this.clearEmailUpdatingError();

    try {
      await this.userApi.changeEmail(confirmationToken);
    } catch (e) {
      this.setEmailUpdatingError(getApiErrorResponse(e) as Errors);
    } finally {
      this.setIsEmailUpdating(false);
    }
  }

  @action setIsEmailUpdating(isUpdating: boolean): void {
    this.isEmailUpdating = isUpdating;
  }

  @action async updateUserPassword(payload: UpdateUserPasswordPayload): Promise<void> {
    this.isPasswordUpdating = true;
    this.setPasswordUpdatingError(undefined);
    const userEmail = payload.email || this.email;

    if (userEmail) {
      try {
        const captcha = await payload.getCaptcha();
        await this.userApi.requestChangePassword({
          email: userEmail,
          captchaToken: captcha,
          forgotPassword: payload.hasForgottenPassword,
        });
      } catch (e) {
        this.setPasswordUpdatingError(getApiErrorResponse(e));
      }
    }

    this.isPasswordUpdating = false;
  }

  @action async finishUpdateUserPassword(password: string, confirmationToken: string): Promise<void> {
    logger.info('Finishing user password update');

    this.isPasswordUpdating = true;
    this.setPasswordUpdatingError(undefined);

    try {
      await this.userApi.changePassword(password, confirmationToken);
    } catch (e) {
      logger.error('Failed to update user password', {
        error: errorToJson(e),
      });

      this.setPasswordUpdatingError(getApiErrorResponse(e));
    } finally {
      this.isPasswordUpdating = false;
    }
  }

  @action setPasswordUpdatingError(e: string | undefined) {
    this.passwordUpdatingError = e;
  }

  @action setCurrentLanguage(): void {
    this.currentLanguage = getCurrentLanguage();
    this.setActualTimeZonesList();
    window.electronApi?.setUserLanguage(
      this.currentLanguage,
      i18n.getResourceBundle(this.currentLanguage, 'translation') as Record<string, unknown>,
    );
  }

  setActualTimeZonesList() {
    const locale = this.currentLanguage === 'ru' ? 'ru' : 'en';
    TimeZones.setActualTimeZonesList(locale);
  }

  @action async removeUserAvatar(): Promise<void> {
    this.isUserUpdating = true;
    this.userUpdatingError = undefined;

    const { error } = await this.userApi.deleteUserAvatar();
    if (error) {
      this.userUpdatingError = error;
    } else {
      this.image = null;
    }

    this.isUserUpdating = false;
  }

  @action async fetchUserInfo(): Promise<void> {
    const userInfo = await this.userApi.getUserInfo();
    if (userInfo) {
      const {
        id: userId,
        username,
        email,
        image,
        phone,
        carrotQuestToken,
        createdAt,
        isDebugger,
      } = userInfo;

      this.setUserInfo({
        userId,
        username,
        email,
        image,
        phone,
        carrotQuestToken,
        isDebugger,
        createdAt: new Date(createdAt),
      });
    }

    this.setIsUserInfoFetched(true);
  }

  @action private setIsUserInfoFetched(value: boolean): void {
    this.isUserInfoFetched = value;
  }

  @action setUserInfo({
    userId,
    username,
    email,
    image,
    phone,
    carrotQuestToken,
    createdAt,
    isDebugger,
  }: UserInfo): void {
    this.id = userId;
    this.username = username;
    this.email = email;
    this.image = image;
    this.phone = phone;
    this.carrotQuestToken = carrotQuestToken;
    this.createdAt = createdAt;
    this.isDebugger = isDebugger;

    const participantInfo = new ParticipantInfo({ userStore: this });

    participantInfo.setUsername(username);

    if (phone) {
      participantInfo.setPhone(phone);
    }

    if (email) {
      participantInfo.setPhone(email);
    }
  }

  @computed get userInfo(): UserInfo | null {
    const {
      id: userId,
      username,
      email,
      image,
      phone,
      carrotQuestToken,
      createdAt,
    } = this;

    if (!(userId && username && email && carrotQuestToken && createdAt)) {
      return null;
    }

    return {
      userId,
      username,
      email,
      image,
      phone,
      carrotQuestToken,
      createdAt,
    };
  }
}

export default UserStore;
