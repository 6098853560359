import { RootStore } from 'stores/root';
import { getUserAgentInfo } from 'helpers/browser';
import logger from 'helpers/logger';
import {
  BannerEvent,
  ProdamusEvent,
  ProdamusEventType,
  ProdamusEventTypeValues,
} from 'modules/Announcements/types';
import {
  AnalyticsEventType,
  AnalyticsEventCategory,
  SendMetricPayload,
  SendEventMetricPayload,
  MetricUserInfo,
  MetricReactions,
  AnalyticsEvent,
} from 'services/MoodHoodAnalyticsApiClient/types';
import { MeetingType, RoomType } from 'types/common';
import { ReactionType } from 'services/MoodHoodApiClient/RoomApi.types';

import {
  EventCategory,
  IMetricEvent,
  IToggleEvents,
  IToggleEventStats,
  TMetricName,
  TToggleMetrics,
} from './types';
import { ReactionsCounter } from '../../components/Reactions/types';
import { REACTIONS_INITIAL_VALUE } from '../../constants/common';
import MarketingMetrics from './MarketingMetrics';

const mapMetricNames: Record<string, TMetricName> = {
  'action:attendance': 'onlineDuration',
  'action:activeTab:on': 'onlineDurationWithActiveTab',
  'action:microphone:on': 'activeMicrophoneDuration',
  'action:camera:on': 'activeCameraDuration',
};

const mapProdamusEventType = {
  [ProdamusEventType.Close]: AnalyticsEventType.AnnouncementProdamusClose,
  [ProdamusEventType.Open]: AnalyticsEventType.AnnouncementProdamusOpen,
  [ProdamusEventType.Error]: AnalyticsEventType.AnnouncementProdamusError,
  [ProdamusEventType.Success]: AnalyticsEventType.AnnouncementProdamusSuccess,
  [ProdamusEventType.Waiting]: AnalyticsEventType.AnnouncementProdamusWaiting,
};

class Metrics {
  readonly marketing: MarketingMetrics;

  private readonly TIMER_PERIOD_IN_SEC = 20;

  private timerHandle: NodeJS.Timer | null = null;

  private trackingStartTime: number | null = null;

  private toggleEvents: { [key in EventCategory]: IToggleEventStats } | null = null;

  private onTabSwitch: (() => void) | null = null;

  private chatSentMessagesCount = 0;

  private reactionsCount = 0;

  private reactionsTypeCounter: ReactionsCounter = REACTIONS_INITIAL_VALUE;

  private resetToggleEvents() {
    const defaultValue = {
      onTimeInMs: 0,
      offTimeInMs: 0,
      lastEventTime: 0,
      isOn: false,
    };

    this.toggleEvents = {
      microphone: { name: EventCategory.Microphone, ...defaultValue },
      camera: { name: EventCategory.Camera, ...defaultValue },
      activeTab: { name: EventCategory.ActiveTab, ...defaultValue, isOn: true },
    };
  }

  private resetCollectedData() {
    this.chatSentMessagesCount = 0;
    this.reactionsCount = 0;
    this.reactionsTypeCounter = REACTIONS_INITIAL_VALUE;
  }

  private getUserInfo(): MetricUserInfo {
    const {
      userStore,
      eventStore,
      participantStore,
    } = this.rootStore;

    const {
      id: userId,
    } = userStore;

    const {
      name: participantName,
      externalUserId,
      clientUniqueId,
    } = participantStore;

    const userName = participantName || userStore.username || 'Guest participant';

    const participantId = eventStore.playbackEvent?.id ? undefined : participantStore.id;

    return {
      participantId,
      ...(externalUserId ? { externalUserId: btoa(externalUserId) } : {}),
      clientUniqueId: clientUniqueId || undefined,
      userId,
      userName,
      email: participantStore.participantInfo.email,
      phone: participantStore.participantInfo.phone,
    };
  }

  private addCommonMetricsData(metricData: Partial<SendMetricPayload>) {
    return {
      ...metricData,
      ...this.getUserInfo(),
    } as SendMetricPayload;
  }

  private addCommonEventMetricsData(metricData: Partial<SendEventMetricPayload>) {
    return {
      reactionsCount: this.reactionsCount,
      chatMessagesCount: this.chatSentMessagesCount,
      ...this.getUserInfo(),
      ...metricData,
      playbackId: this.rootStore.eventStore?.playbackEvent?.playbackId,
      playbackEventId: this.rootStore.eventStore?.playbackEvent?.id,
      playbackEventSessionId: this.rootStore.eventStore.joinResult?.id,
      playbackType: this.rootStore.eventStore.playbackEvent?.type,
    } as SendEventMetricPayload;
  }

  private convertEventsToMetrics(metricEvents: IMetricEvent[]) {
    const metricToggleValues: TToggleMetrics = {
      onlineDuration: 0,
      onlineDurationWithActiveTab: 0,
      ...this.getReactionTypesCount(),
    };

    if (!this.rootStore.eventStore.playbackEvent?.id) {
      metricToggleValues.activeMicrophoneDuration = 0;
      metricToggleValues.activeCameraDuration = 0;
    }

    metricEvents.forEach((evt) => {
      const metricName = mapMetricNames[evt.metric];
      if (metricName) {
        metricToggleValues[metricName] = evt.value;
      }
    });

    if (this.rootStore.eventStore.isEventInfoFetched) {
      return this.addCommonEventMetricsData(metricToggleValues);
    }

    return this.addCommonMetricsData(metricToggleValues);
  }

  private getReactionTypesCount(): MetricReactions {
    return {
      thumbUpCount: this.reactionsTypeCounter[ReactionType.ThumbUp],
      thumbDownCount: this.reactionsTypeCounter[ReactionType.ThumbDown],
      heartCount: this.reactionsTypeCounter[ReactionType.Heart],
      fireCount: this.reactionsTypeCounter[ReactionType.Fire],
    };
  }

  private static makeToggleEvent(name: string, isOn: boolean, value: number): IMetricEvent {
    return {
      event: 'metric',
      metric: `action:${name}:${isOn ? 'on' : 'off'}`,
      value,
    };
  }

  private makeToggleEvents(): IMetricEvent[] {
    if (!this.toggleEvents) {
      return [];
    }

    const result: IMetricEvent[] = Object.values(this.toggleEvents).reduce(
      (events: IMetricEvent[], evt: IToggleEventStats): IMetricEvent[] => {
        const {
          lastEventTime, isOn, name, onTimeInMs, offTimeInMs,
        } = evt;

        const prevTime = Number(lastEventTime || this.trackingStartTime);
        /*
            During TIMER_PERIOD there are might be multiple toggle events.
            We collect corresponding time spans in onTimeInMs and offTimeInMs accordingly
        */
        const accValue = isOn ? onTimeInMs : offTimeInMs;
        const curTime = Date.now();
        // total time is a sum of previous time spans and the last one
        const timeInSeconds = Math.round(((curTime - prevTime) + accValue) / 1000);
        // in theory, timeInSeconds could be > TIME_PERIOD(e.g. paused in debugger)
        const value = Math.min(timeInSeconds, this.TIMER_PERIOD_IN_SEC);
        // since state could be only on/off, it is enough to get time in one state, to calc opposite state time
        const oppValue = this.TIMER_PERIOD_IN_SEC - value;

        // Each toggle state produces 2 records with toggle event in TIMER_PERIOD,
        // and one without it(current state with duration of TIMER_PERIOD)
        events.push(Metrics.makeToggleEvent(name, isOn, value));

        if (oppValue) {
          events.push(Metrics.makeToggleEvent(name, !isOn, oppValue));
        }

        const { toggleEvents } = this;

        if (toggleEvents) {
          toggleEvents[name].onTimeInMs = 0;
          toggleEvents[name].offTimeInMs = 0;
          /* reset event time to accumulate time only within TIMER_PERIOD */
          toggleEvents[name].lastEventTime = curTime;
        }

        return events;
      }, [],
    );

    return result;
  }

  async trackProdamusPaymentEvent({
    type, amount, currency, announcementId,
  }: ProdamusEvent) {
    if (!ProdamusEventTypeValues.includes(type)) {
      logger.warn('Prodamus unknown event', { eventType: type });
      return;
    }

    const {
      eventStore,
      roomStore,
    } = this.rootStore;

    const { activeCall } = roomStore.calls;
    const { type: roomType } = roomStore;

    let meetingType = roomType === RoomType.Lesson ? MeetingType.Conference : MeetingType.Webinar;
    if (eventStore.playbackEvent?.id) {
      meetingType = MeetingType.AutoWebinar;
    }

    const userInfo = this.getUserInfo();

    const meetingId = eventStore.joinResult?.id || roomStore.id;

    const sessionId = activeCall?.id || eventStore.joinResult?.id;

    const spaceId = roomStore.spaceId || eventStore.playbackEvent?.spaceId;

    if (!sessionId || !userInfo.clientUniqueId || !meetingId) {
      return;
    }

    const event: AnalyticsEvent = {
      event: mapProdamusEventType[type],
      meetingId,
      sessionId,
      target: announcementId,
      meetingType,
      category: AnalyticsEventCategory.Announcement,
      valueNum: amount,
      valueStr: currency,
      ...userInfo,
      ua: getUserAgentInfo(),
      spaceId,
      playbackId: eventStore.playbackEvent?.playbackId,
    };

    await this.rootStore.moodHoodAnalyticsApiClient.analytics.sendEvent(event);
  }

  async trackAnnouncementEvent({ event, announcementId }: BannerEvent) {
    const {
      eventStore,
      roomStore,
    } = this.rootStore;

    const { activeCall } = roomStore.calls;
    const { type: roomType } = roomStore;

    let meetingType = roomType === RoomType.Lesson ? MeetingType.Conference : MeetingType.Webinar;
    if (eventStore.playbackEvent?.id) {
      meetingType = MeetingType.AutoWebinar;
    }

    const userInfo = this.getUserInfo();

    const meetingId = roomStore.id || eventStore.playbackEvent?.id;

    const sessionId = activeCall?.id || eventStore.joinResult?.id;

    const spaceId = roomStore.spaceId || eventStore.playbackEvent?.spaceId;

    if (!sessionId || !userInfo.clientUniqueId || !meetingId) {
      return;
    }

    const data: AnalyticsEvent = {
      event,
      meetingId,
      sessionId,
      target: announcementId,
      meetingType,
      category: AnalyticsEventCategory.Announcement,
      ...userInfo,
      ua: getUserAgentInfo(),
      spaceId,
      playbackId: eventStore.playbackEvent?.playbackId,
    };

    await this.rootStore.moodHoodAnalyticsApiClient.analytics.sendEvent(data);
  }

  private updateToggleEventStats(eventName: keyof IToggleEvents, isOn: boolean) {
    if (!this.toggleEvents) {
      return;
    }

    const stats = this.toggleEvents[eventName];
    if (stats.isOn === isOn) {
      return;
    }

    const prevTime = Number(stats.lastEventTime || this.trackingStartTime);
    const timePassedInMs = Date.now() - prevTime;
    stats.isOn = isOn;
    stats.lastEventTime = Date.now();

    if (isOn) {
      stats.offTimeInMs += timePassedInMs;
    } else {
      stats.onTimeInMs += timePassedInMs;
    }
  }

  private startTracking() {
    this.stopTracking();
    this.resetToggleEvents();

    this.timerHandle = setInterval(() => {
      if (this.trackingStartTime) {
        const events = [
          {
            event: 'metric' as const,
            metric: 'action:attendance',
            value: this.TIMER_PERIOD_IN_SEC,
          },
          ...this.makeToggleEvents(),
        ];

        const metrics = this.convertEventsToMetrics(events);

        if (metrics) {
          const data = {
            ...metrics,
            reactionsCount: this.reactionsCount,
            chatMessagesCount: this.chatSentMessagesCount,
          };

          if (this.rootStore.eventStore.isEventInfoFetched) {
            this.sendEventMetrics(data);
          } else {
            this.sendMetrics(data as SendMetricPayload);
          }
        }

        this.resetCollectedData();
      }
    }, this.TIMER_PERIOD_IN_SEC * 1000);

    this.onTabSwitch = () => {
      this.trackTabSwitch(!document.hidden);
    };

    document.addEventListener('visibilitychange', this.onTabSwitch);
    this.trackingStartTime = Date.now();
  }

  private stopTracking() {
    if (this.timerHandle) {
      clearInterval(this.timerHandle);
    }

    if (this.onTabSwitch) {
      document.removeEventListener('visibilitychange', this.onTabSwitch);
    }
  }

  private async sendMetrics(metrics: SendMetricPayload): Promise<void> {
    const { spaceId } = this.rootStore.roomStore;
    const { roomStore: { id: roomId } } = this.rootStore;
    const { id: userId } = this.rootStore.userStore;
    const { activeCall } = this.rootStore.roomStore.calls;
    const { clientUniqueId } = this.rootStore.participantStore;

    if (!spaceId || !activeCall?.id || !clientUniqueId || !roomId) {
      logger.error('Cant sendMetrics: no spaceId or callId or clientUniqueId or roomId', {
        userId,
        roomId,
        spaceId,
        clientUniqueId,
        callId: activeCall?.id,
      });
      return;
    }

    await this.rootStore.moodHoodAnalyticsApiClient.analytics.sendMetrics({
      ...metrics,
      callId: activeCall.id,
      roomId,
      userId,
      spaceId,
      clientUniqueId,
    });
  }

  private async sendEventMetrics(metrics: SendEventMetricPayload): Promise<void> {
    const { spaceId } = this.rootStore.eventStore.playbackEvent || {};
    const { id: userId } = this.rootStore.userStore;
    const { clientUniqueId } = this.rootStore.participantStore;

    if (!spaceId || !clientUniqueId) {
      logger.error('cant sendEventMetrics: no spaceId or clientUniqueId', {
        userId,
        spaceId,
        clientUniqueId,
      });
      return;
    }

    await this.rootStore.moodHoodAnalyticsApiClient.analytics.sendEventMetrics({
      ...metrics,
      userId,
      spaceId,
      clientUniqueId,
    });
  }

  constructor(private rootStore: RootStore) {
    this.marketing = new MarketingMetrics();
  }

  start(): void {
    this.startTracking();
  }

  stop(): void {
    this.stopTracking();
  }

  trackMicrophoneInUse(isEnabled: boolean): void {
    this.updateToggleEventStats('microphone', isEnabled);
  }

  trackCameraInUse(isEnabled: boolean): void {
    this.updateToggleEventStats('camera', isEnabled);
  }

  trackTabSwitch(isTabActive: boolean): void {
    this.updateToggleEventStats('activeTab', isTabActive);
  }

  trackReaction({ roomAlias, reactionType }: { roomAlias: string, reactionType: ReactionType }): void {
    this.marketing.trackReaction(roomAlias);
    const { reactionsTypeCounter } = this;
    this.reactionsTypeCounter = { ...reactionsTypeCounter, [reactionType]: reactionsTypeCounter[reactionType] + 1 };
    this.reactionsCount += 1;
  }

  trackSentChatMessage(roomAlias: string): void {
    this.marketing.trackSentChatMessage(roomAlias);
    this.chatSentMessagesCount += 1;
  }

  trackPipToggle(state: boolean) {
    this.marketing.trackPip(state);
  }

  trackJoinRoom() {
    this.sendMetrics({
      ...getUserAgentInfo(),
      ...this.addCommonMetricsData({
        roomType: this.rootStore.roomStore.type,
        isBreakoutRoom: this.rootStore.roomStore.isBreakoutRoom,
        planAlias: this.rootStore.paymentStore.currentPlan?.alias as string,
        referer: document.referrer,
      }),
    });
  }

  trackJoinEvent() {
    this.sendEventMetrics({
      ...this.addCommonEventMetricsData({
        ...getUserAgentInfo(),
        referer: document.referrer,
      }),
    });
  }
}

export default Metrics;
