import { RoomRole, SpaceRole } from '../services/MoodHoodApiClient/types';

export const isAdministrativeRoomRole = (role: RoomRole): boolean => [
  RoomRole.Moderator,
  RoomRole.Owner,
].includes(role);

export const isAdministrativeSpaceRole = (role: SpaceRole): boolean => [
  SpaceRole.Moderator,
  SpaceRole.Owner,
].includes(role);
