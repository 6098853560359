class RingBuffer<T> {
  private _values: T[] = [];

  private _capacity = 0;

  private curIndex = 0;

  constructor(size: number) {
    if (size <= 0) {
      throw new Error('Size must be a natural number');
    }

    this._capacity = size;
  }

  push(value: T) {
    if (this.curIndex === this._capacity) {
      this.curIndex = 0;
    }

    this._values[this.curIndex] = value;
    this.curIndex += 1;
  }

  capacity() {
    return this._capacity;
  }

  size() {
    return this._values.length;
  }

  reset() {
    this._values = [];
    this.curIndex = 0;
  }

  get values(): ReadonlyArray<T> {
    return this._values;
  }
}

export default RingBuffer;
