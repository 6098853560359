import React from 'react';
import { useTheme } from '@emotion/react';
import { AppTheme } from 'ui-kit/theme';

import { LinearProgressBody, LinearProgressValue } from './LinearProgressLayout';

type ColorVariant = 'error' | 'warn' | 'success';

interface LinearProgressProps {
  value: number;
  color?: ColorVariant;
}

const colorVariantToColor = (theme: AppTheme, variant?: ColorVariant) => {
  switch (variant) {
    case 'error':
      return theme.colors.errorBase;
    case 'warn':
      return theme.colors.warningBase;
    default:
      return theme.colors.successBase;
  }
};

const LinearProgress: React.FunctionComponent<LinearProgressProps> = ({ value, color }) => {
  const theme = useTheme();

  return (
    <LinearProgressBody>
      {value > 0 && (
        <LinearProgressValue
          style={{
            width: `min(${value}%, 100%)`,
            background: colorVariantToColor(theme, color),
          }}
        />
      )}
    </LinearProgressBody>
  );
};

export default LinearProgress;
