export enum StatsId {
  RoomChangeBroadcast = 'ui_room_change_broadcast',
  RoomChangeCameraMenu = 'ui_room_change_camera_menu',
  RoomChangeMicrophoneMenu = 'ui_room_change_microphone_menu',
  RoomToggleCameraMenuItem = 'ui_room_toggle_camera_menu_item',
  RoomToggleMicrophoneMenuItem = 'ui_room_toggle_microphone_menu_item',
  RoomCameraChange = 'ui_room_camera_change',
  RoomMicrophoneChange = 'ui_room_microphone_change',
  RoomOutputChange = 'ui_room_output_change',
  RoomChangeDemo = 'ui_room_change_demo',
  RoomStopDemo = 'ui_room_stop_demo',
  RoomDebugDump = 'ui_room_debug_dump',
  RoomWaitingAllowAll = 'ui_room_waiting_allow_all',
  RoomWaitingDenyAll = 'ui_room_waiting_deny_all',
  RoomsInviteAdministrator = 'ui_rooms_invite_administrator',
  RoomsInviteUser = 'ui_rooms_invite_user',
  RoomsUserList = 'ui_rooms_user_list',
  RoomsProfileEdit = 'ui_rooms_profile_edit',
  RoomsProfileLogout = 'ui_rooms_profile_logout',
  RoomsSupport = 'ui_rooms_support',
  RoomsConferenceDialog = 'ui_rooms_conference_dialog',
  RoomsWebinarDialog = 'ui_rooms_webinar_dialog',
  RoomsPlaybackWebinarDialog = 'ui_rooms_playback_webinar_dialog',
  RoomsRoomRemove = 'ui_rooms_room_remove',
  RoomsRoomEdit = 'ui_rooms_room_edit',
  RoomsRoomRecords = 'ui_rooms_room_records',
  RoomsRoomLink = 'ui_rooms_room_link',
  RoomMoveUserOfScreen = 'ui_room_move_user_off_screen',
  RoomMoveUser = 'ui_room_move_user',
  RoomBanUser = 'ui_room_ban_user',
  RoomDisableScreenDemonstration = 'ui_room_disable_screen_demonstration',
  RoomMiroBoard = 'ui_room_miro_board',
  RoomBroadcast = 'ui_room_broadcast',
  RoomBroadcastInfo = 'ui_room_broadcast_info',
  RoomScreenSharingState = 'ui_room_screen_sharing_state',
  RoomsRoomSave = 'ui_rooms_room_save',
  RoomsCreateConference = 'ui_rooms_create_conference',
  RoomsCreateWebinar = 'ui_rooms_create_webinar',
  RoomsCreatePlaybackWebinar = 'ui_rooms_create_playback_webinar',
  RoomsRoomEnter = 'ui_rooms_room_enter',
  RoomsWebinarEmailState = 'rooms_webinar_email_state',
  RoomsWebinarPhoneState = 'rooms_webinar_phone_state',
  RoomEndCall = 'ui_room_end_call',
  RoomExit = 'ui_room_exit',
  RoomBannersPanelState = 'ui_room_banners_panel_state',
  RoomPipState = 'ui_room_pip_state',
  RoomFullScreenState = 'ui_room_full_screen_state',
  RoomProfile = 'ui_room_profile',
  RoomVisualEffects = 'ui_room_visual_effects',
  RoomDevices = 'ui_room_devices',
  RoomBannersEdit = 'ui_room_banners_edit',
  RoomBannersDelete = 'ui_room_banners_delete',
  RoomSessionsClearRoom = 'ui_room_sessions_clear_room',
  RoomSessionsMoveUser = 'ui_room_sessions_move_user',
  RoomSessionsRemoveUser = 'ui_room_sessions_remove_user',
  RoomUsersPanelState = 'ui_room_users_panel_state',
  RoomHelp = 'ui_room_help',
  RoomRetryDevices = 'ui_room_retry_devices',
  RoomEnter = 'ui_room_enter',
  RoomMoveUserButton = 'ui_room_move_user_button',
  RoomBannersShow = 'ui_room_banners_show',
  RoomBannersHide = 'ui_room_banners_hide',
  RoomBannersSave = 'ui_room_banners_save',
  RoomBannersCancel = 'ui_room_banners_cancel',
  RoomBannersCreate = 'ui_room_banners_create',
  RoomSessionsStart = 'ui_room_sessions_start',
  RoomSessionsStop = 'ui_room_sessions_stop',
  RoomFeedbackSendExplained = 'ui_room_feedback_send_explained',
  RoomFeedbackSend = 'ui_room_feedback_send',
  RoomInRoomContinue = 'ui_room_in_room_continue',
  SpacesProfileEdit = 'ui_spaces_profile_edit',
  SpacesProfileLogout = 'ui_spaces_profile_logout',
  SpacesSpaceCreate = 'ui_spaces_space_create',
  SpacesSpaceSave = 'ui_spaces_space_save',
  PeerDebugInfo = 'ui_peer_debug_info',
  PeerDebugTrackInfo = 'ui_peer_debug_track_info',
  PeerDebugRequestKeyFrame = 'ui_peer_debug_request_key_frame',
  ProfileChangeLanguage = 'ui_profile_change_language',
  ProfileChangeEmailConfirm = 'ui_profile_change_email_confirm',
  ProfileChangePasswordConfirm = 'ui_profile_change_password_confirm',
  ProfileChangeName = 'ui_profile_change_name',
  ProfileChangePlan = 'ui_profile_change_plan',
  ProfileChangeEmail = 'ui_profile_change_email',
  ProfileChangePassword = 'ui_profile_change_password',
  SignupUser = 'ui_signup_user',
  StatsDownload = 'ui_stats_download',
}
