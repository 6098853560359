import {
  action, computed, makeAutoObservable, observable,
} from 'mobx';

import { getExceptionMessage } from 'helpers/errors';

import WebHookApi from '../services/MoodHoodApiClient/space/WebHookApi';
import { Errors } from '../constants/errors';

class WebHookStore {
  @observable private readonly webHookApi: WebHookApi;

  @observable private webHookUrl?: string;

  @observable private webHookSecret?: string;

  @observable private isWebHookActive?: boolean;

  @observable private isWebHookExists?: boolean;

  constructor(webHookApi: WebHookApi) {
    this.webHookApi = webHookApi;
    makeAutoObservable(this);
  }

  @computed get isExists(): boolean {
    return !!this.isWebHookExists;
  }

  @computed get url(): string | undefined {
    return this.webHookUrl;
  }

  @computed get secret(): string | undefined {
    return this.webHookSecret;
  }

  @computed get isActive(): boolean {
    return !!this.isWebHookActive;
  }

  @action setWebHookUrl(webHookUrl?: string): void {
    this.webHookUrl = webHookUrl;
  }

  @action setWebHookSecret(webHookSecret?: string): void {
    this.webHookSecret = webHookSecret;
  }

  @action setIsActive(isWebHookActive?: boolean): void {
    this.isWebHookActive = isWebHookActive;
  }

  @action setIsExists(isWebHookExists?: boolean): void {
    this.isWebHookExists = isWebHookExists;
  }

  @action async get(spaceId: string, errorCb: (message: string) => void): Promise<void> {
    try {
      const { url } = await this.webHookApi.get(spaceId);
      this.setWebHookUrl(url);
      this.setIsExists(true);
    } catch (error) {
      const message = getExceptionMessage(error);
      if (message === Errors.NOT_FOUND) {
        return;
      }

      errorCb(message);
    }
  }

  @action async create(spaceId: string, errorCb: (message: string) => void): Promise<void> {
    try {
      if (!this.url) {
        return;
      }

      const { url, secret, isActive } = await this.webHookApi.create({
        spaceId,
        url: this.url,
      });

      this.setWebHookUrl(url);
      this.setWebHookSecret(secret);
      this.setIsActive(isActive);
      this.setIsExists(true);
    } catch (error) {
      const message = getExceptionMessage(error);
      errorCb(message);
    }
  }

  @action async update(spaceId: string, errorCb: (message: string) => void): Promise<void> {
    try {
      if (!this.url) {
        return;
      }

      const { url, isActive } = await this.webHookApi.update({
        spaceId,
        url: this.url,
        isActive: this.isActive,
      });
      this.setWebHookUrl(url);
      this.setIsActive(isActive);
    } catch (error) {
      const message = getExceptionMessage(error);
      errorCb(message);
    }
  }

  @action async delete(spaceId: string, errorCb: (message: string) => void): Promise<void> {
    try {
      await this.webHookApi.delete(spaceId);
      this.setWebHookUrl(undefined);
      this.setWebHookSecret(undefined);
      this.setIsActive(undefined);
    } catch (error) {
      const message = getExceptionMessage(error);
      errorCb(message);
    }
  }

  @action async refreshSecret(spaceId: string, errorCb: (message: string) => void): Promise<void> {
    try {
      const { url, secret, isActive } = await this.webHookApi.refreshSecret(spaceId);
      this.setWebHookUrl(url);
      this.setWebHookSecret(secret);
      this.setIsActive(isActive);
    } catch (error) {
      const message = getExceptionMessage(error);
      errorCb(message);
    }
  }
}

export default WebHookStore;
