import {
  FunctionComponent, useEffect, useState,
} from 'react';
import { createPortal } from 'react-dom';

interface IPortal {
  hostElementId?: string,
}

const Portal: FunctionComponent<IPortal> = ({ children, hostElementId = 'root' }) => {
  const [rootElem, setRootElem] = useState<HTMLElement | null>(null); // not ref to trigger re-render at mount

  useEffect(() => {
    const mount = document.getElementById(hostElementId);
    if (mount) {
      setRootElem(mount);
    }
  }, []);

  return rootElem && createPortal(children, rootElem);
};

export default Portal;
