import React, { FunctionComponent, useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation,
  Redirect,
} from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { observer } from 'mobx-react-lite';

import useMetrics from 'hooks/useMetrics';
import useCarrotQuest from 'hooks/useCarrotQuest';
import InvitePage from 'components/Invite';
import ChangeEmailConfirm from 'components/UserProfile/ChangeEmailConfirm';
import ChangePasswordConfirm from 'components/UserProfile/ChangePasswordConfirm';
import UserOnly from 'components/Auth/UserOnly';
import GuestOnly from 'components/Auth/GuestOnly';
import AllowPage from 'components/AllowPage';
import ThanksPage from 'components/ThanksPage';
import PlansPage from 'components/PlansPage';
import PromoPlansPage from 'components/PromoPlansPage';
import SignUpStep2Page from 'components/Auth/Pages/SignUpStep2Page';
import { RoutePath } from 'types/common';

import {
  Spaces,
  Space,
  SignUpPage,
  SignInPage,
  PasswordRecovery,
  Room,
  RoomStatistics,
  RoomStatisticsPulse,
  RoomRecord,
  Event,
  PlaybackEditor,
  ScreenSharingTest,
  UserProfileMobileApp,
  PlansPageMobileApp,
} from 'components/LazyComponents';
import ChatPopup from 'components/Room/ChatPopup';
import ChatEventPopup from 'components/Event/ChatEventPopup';
import PlaybackSessions from 'components/Space/PlaybackSessions';
import MiroWrapper from 'components/MiroBoard/MiroWrapper';
import IconButtonStory from 'stories/IconButton';
import Canary from 'components/Canary';
import MobileAppProfilePage from 'components/MobileAppProfilePage';
import Logout from 'components/Auth/Logout';
import { isElectronAppUserAgent } from 'helpers/common';

const Routes: FunctionComponent = observer(() => {
  const location = useLocation();
  const metrics = useMetrics();

  useEffect(() => {
    const pathName = location.pathname;
    metrics.marketing.trackPageView(pathName);
  }, [location]);

  useCarrotQuest();

  if (!process.env.REACT_APP_FOR_ELECTRON && isElectronAppUserAgent()) {
    return (
      <Switch>
        <Route path={RoutePath.MiroWrapper} exact>
          <MiroWrapper />
        </Route>
        <Route path="*">
          <Redirect to={RoutePath.FromApp} />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path={RoutePath.Root} exact>
        <UserOnly>
          <Spaces />
        </UserOnly>
      </Route>
      <Route path={RoutePath.SignUp} exact>
        <GuestOnly>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}>
            <SignUpPage />
          </GoogleReCaptchaProvider>
        </GuestOnly>
      </Route>
      <Route path={RoutePath.SignUpStep2} exact>
        <UserOnly>
          <SignUpStep2Page />
        </UserOnly>
      </Route>
      <Route path={RoutePath.SignIn} exact>
        <GuestOnly>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}>
            <SignInPage />
          </GoogleReCaptchaProvider>
        </GuestOnly>
      </Route>
      <Route path={RoutePath.Logout} exact>
        <UserOnly>
          <Logout />
        </UserOnly>
      </Route>
      <Route path={RoutePath.RecoverPassword}>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}>
          <PasswordRecovery />
        </GoogleReCaptchaProvider>
      </Route>
      <Route path={RoutePath.Room} exact>
        <Room />
      </Route>
      <Route path={RoutePath.RoomStatistics} exact>
        <RoomStatistics />
      </Route>
      <Route path={RoutePath.RoomStatisticsPulse} exact>
        <RoomStatisticsPulse />
      </Route>
      <Route path={RoutePath.RoomRecord}>
        <RoomRecord />
      </Route>
      <Route path={RoutePath.Plans} exact>
        <UserOnly>
          <PlansPage />
        </UserOnly>
      </Route>
      <Route path={RoutePath.MobileProfile} exact>
        <UserOnly>
          <MobileAppProfilePage>
            <UserProfileMobileApp />
          </MobileAppProfilePage>
        </UserOnly>
      </Route>
      <Route path={RoutePath.MobileProfilePlans} exact>
        <UserOnly>
          <MobileAppProfilePage>
            <PlansPageMobileApp />
          </MobileAppProfilePage>
        </UserOnly>
      </Route>
      <Route path={RoutePath.MobileProfileAndroid} exact>
        <UserOnly>
          <MobileAppProfilePage>
            <UserProfileMobileApp />
          </MobileAppProfilePage>
        </UserOnly>
      </Route>
      <Route path={RoutePath.MobileProfilePlansAndroid} exact>
        <UserOnly>
          <MobileAppProfilePage>
            <PlansPageMobileApp />
          </MobileAppProfilePage>
        </UserOnly>
      </Route>
      <Route path={RoutePath.PromoPlans} exact>
        <UserOnly>
          <PromoPlansPage />
        </UserOnly>
      </Route>
      <Route path={RoutePath.Playbacks} exact>
        <UserOnly>
          <Space />
        </UserOnly>
      </Route>
      <Route path={RoutePath.Event} exact>
        <Event />
      </Route>
      <Route path={RoutePath.PlaybackSessions} exact>
        <UserOnly>
          <PlaybackSessions />
        </UserOnly>
      </Route>
      <Route path={RoutePath.PlaybackPreview} exact>
        <UserOnly>
          <Event isPreview />
        </UserOnly>
      </Route>
      <Route path={RoutePath.PlaybackEdit} exact>
        <UserOnly>
          <PlaybackEditor />
        </UserOnly>
      </Route>
      <Route path={RoutePath.Space}>
        <UserOnly>
          <Space />
        </UserOnly>
      </Route>
      <Route path={RoutePath.SpaceOnly} exact>
        <Redirect to={RoutePath.Root} />
      </Route>
      <Route path={RoutePath.RoomRecords} exact>
        <UserOnly>
          <Space />
        </UserOnly>
      </Route>
      <Route path={RoutePath.Invite}>
        <UserOnly>
          <InvitePage />
        </UserOnly>
      </Route>
      <Route path={RoutePath.ChangeEmailConfirm}>
        <ChangeEmailConfirm />
      </Route>
      <Route path={RoutePath.ChangePasswordConfirm}>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}>
          <ChangePasswordConfirm />
        </GoogleReCaptchaProvider>
      </Route>
      <Route path={RoutePath.SafariStorageAccess} exact>
        <AllowPage />
      </Route>
      <Route path={RoutePath.Thanks} exact>
        <ThanksPage />
      </Route>
      <Route path={RoutePath.ChatPopup} exact>
        <ChatPopup />
      </Route>
      <Route path={RoutePath.ChatEventPopup} exact>
        <ChatEventPopup />
      </Route>
      <Route path={RoutePath.ChatPlaybackPreviewPopup} exact>
        <ChatEventPopup />
      </Route>
      <Route path={RoutePath.ToolsScreenSharingTest} exact>
        <ScreenSharingTest />
      </Route>
      <Route path={RoutePath.RoomRecordPLay} exact>
        <Event />
      </Route>
      {process.env.NODE_ENV !== 'production' && (
        <Route path={RoutePath.IconButtonStory} exact>
          <IconButtonStory />
        </Route>
      )}
      <Route path={RoutePath.Canary} exact>
        <Canary />
      </Route>
      <Route path="*">
        404
      </Route>
    </Switch>
  );
});

export default Routes;
