import { AxiosInstance } from 'axios';

import { getAxiosErrorMessage } from 'helpers/errors';
import { apiCall } from 'decorators';

import { ApiResponse } from '../MoodHoodApiClient/types';
import { PulseResponse } from '../../types/analytics';
import {
  SendMetricPayload,
  SendEventMetricPayload,
  GetPulsePayload,
  AnalyticsEvent,
  SendNetworkMetricsPayload,
} from './types';
import { QoSEvent } from '../QualityOfService/types';
import { isRoomRecord } from '../../helpers/url';
import { sanitizeAnalyticsPhoneNumber } from '../../helpers/common';

export default class AnalyticsApi {
  private readonly api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async sendMetrics(metric: SendMetricPayload): ApiResponse<void> {
    if (isRoomRecord()) {
      return {};
    }

    try {
      await this.api.post('/analytics/metrics', {
        ...metric,
        phone: sanitizeAnalyticsPhoneNumber(metric.phone),
      });
      return { };
    } catch (err) {
      return { error: getAxiosErrorMessage(err) };
    }
  }

  async sendEventMetrics(metric: SendEventMetricPayload): ApiResponse<void> {
    if (isRoomRecord()) {
      return {};
    }

    try {
      await this.api.post('/analytics/event-metrics', {
        ...metric,
        phone: sanitizeAnalyticsPhoneNumber(metric.phone),
      });
      return { };
    } catch (err) {
      return { error: getAxiosErrorMessage(err) };
    }
  }

  async getPulse(params: GetPulsePayload): ApiResponse<PulseResponse[]> {
    try {
      const { data } = await this.api.get<PulseResponse[]>(
        '/analytics/pulse', {
          params,
        },
      );

      return { data };
    } catch (err) {
      return { error: getAxiosErrorMessage(err) };
    }
  }

  async sendQoSEvents(events: QoSEvent[]): ApiResponse<void> {
    if (isRoomRecord()) {
      return {};
    }

    try {
      await this.api.post('/analytics/qos', {
        items: events,
      });
      return { };
    } catch (err) {
      return { error: getAxiosErrorMessage(err) };
    }
  }

  @apiCall()
  async sendEvent(event: AnalyticsEvent) {
    await this.api.post('/analytics/event', {
      ...event,
      phone: sanitizeAnalyticsPhoneNumber(event.phone),
    });
  }

  async sendNetworkMetrics(metrics: SendNetworkMetricsPayload): ApiResponse<void> {
    if (isRoomRecord()) {
      return {};
    }

    try {
      await this.api.post('/analytics/network-metrics', metrics);
      return {};
    } catch (err) {
      return { error: getAxiosErrorMessage(err) };
    }
  }
}
