import React, { forwardRef } from 'react';
import ReactSelect, { GroupBase } from 'react-select';
import { useTheme } from '@emotion/react';
import Select from 'react-select/dist/declarations/src/Select';

import {
  Option,
  DropdownIndicator,
  IndicatorSeparator,
  customStyles,
} from './common';
import { OptionType, SelectInputProps } from './types';

const SelectInput = forwardRef<Select<OptionType, false, GroupBase<OptionType>>, SelectInputProps>(({
  variant,
  inject = undefined,
  hasError = false,
  ...restProps
}, forwardedRef) => {
  const theme = useTheme();

  return (
    <ReactSelect
      ref={forwardedRef}
      styles={customStyles({
        theme,
        variant,
        inject,
        hasError,
      })}
      menuPosition="fixed"
      components={{
        Option,
        DropdownIndicator: restProps.isDisabled ? () => null : DropdownIndicator,
        IndicatorSeparator,
      }}
      {...restProps}
    />
  );
});

export default SelectInput;
