import React from 'react';
import { css, cx } from '@emotion/css';
import isMobile from 'is-mobile';

type HeadingProps = React.HTMLProps<HTMLHeadingElement>;

const bold = css`
  font-family: PTRootUIWebBold;
  font-weight: bold;
`;

const H1: React.FunctionComponent<HeadingProps> = ({ children, className = '', ...rest }) => (
  <h1
    className={cx(bold, isMobile() ? css`
      font-size: 48px;
      line-height: 60px;
      margin-bottom: 17px;
      overflow: hidden;
      text-overflow: ellipsis;
    ` : css`
      font-size: 48px;
      line-height: 60px;
      margin-bottom: 17px;
    `, className)}
    {...rest}
  >
    {children}
  </h1>
);

const H2: React.FunctionComponent<HeadingProps> = ({ children, className = '', ...rest }) => (
  <h2
    className={cx(bold, isMobile() ? css`
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
    ` : css`
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 8px;
    `, className)}
    {...rest}
  >
    {children}
  </h2>
);

const H3: React.FunctionComponent<HeadingProps> = ({ children, className = '', ...rest }) => (
  <h3
    className={cx(bold, isMobile() ? css`
      font-size: 16px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    ` : css`
      font-size: 20px;
      line-height: 24px;
    `, className)}
    {...rest}
  >
    {children}
  </h3>
);

export default { H1, H2, H3 };
