import { serializeError } from 'serialize-error';

let skipGettingStorage = false;

export const getSessionStorage = (): Storage | null => {
  try {
    return skipGettingStorage ? null : window.sessionStorage;
  } catch (err: unknown) {
    // eslint-disable-next-line no-console
    console.error('Get session storage error', { error: serializeError(err) });

    if (err instanceof Error && err.name === 'SecurityError') {
      skipGettingStorage = true; // to reduce extra logs
    }

    return null;
  }
};

export function getSessionStorageItem<T>(itemName: string): T | null {
  const sessionStorage = getSessionStorage();

  if (!sessionStorage) {
    return null;
  }

  try {
    return JSON.parse(sessionStorage.getItem(itemName) as string) as T;
  } catch (err) {
    return null;
  }
}

export function setSessionStorageItem(itemName: string, itemValue: unknown): void {
  const sessionStorage = getSessionStorage();

  if (!sessionStorage) {
    return;
  }

  try {
    sessionStorage.setItem(itemName, JSON.stringify(itemValue));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export function removeSessionStorageItem(itemName: string): void {
  const sessionStorage = getSessionStorage();

  if (!sessionStorage) {
    return;
  }

  try {
    sessionStorage.removeItem(itemName);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}
