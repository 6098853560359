import {
  action, computed,
  makeAutoObservable,
} from 'mobx';

import { getLocalStorageItem, setLocalStorageItem } from 'helpers/localStorage';
import hotkeyManager from 'modules/HotkeyManager';
import { AppState, AppHotkey } from 'types/common';

interface Feature {
  title: string,
  lsKey: string,
  isOn: boolean,
}

type Features = Record<string, Feature>;

const IS_FEATURE_TOGGLE_HIDDEN_KEY = 'isFeatureToggleHidden';
const IS_FEATURES_TOGGLE_ACTIVATED_KEY = 'isFeaturesToggleActivated';

class FeatureStore {
  isFeatureToggleHidden: boolean;

  isFeaturesToggleActivated: boolean;

  features: Features;

  constructor() {
    const urlParams = new URLSearchParams(window.location.search.toLowerCase());
    this.isFeaturesToggleActivated = Boolean(getLocalStorageItem(IS_FEATURES_TOGGLE_ACTIVATED_KEY)
      || urlParams.get('ft'));
    this.isFeatureToggleHidden = getLocalStorageItem<boolean>(IS_FEATURE_TOGGLE_HIDDEN_KEY) || false;

    // you can hardcode your feature right here or use useFeature hook
    this.features = {};
    this.setFeaturesInitState();
    this.addEventListener();

    makeAutoObservable(this);
  }

  @action setFeaturesInitState(): void {
    this.featureList.forEach((key) => {
      this.features[key].isOn = Boolean(getLocalStorageItem(this.features[key].lsKey));
    });
  }

  @computed get featureList(): string[] {
    return Object.keys(this.features);
  }

  @computed get getFeatures(): Features {
    return this.features;
  }

  @action toggleFeatureStatus(featureName: string): void {
    if (this.features[featureName]) {
      const newStatus = !this.features[featureName].isOn;
      this.features[featureName].isOn = newStatus;
      setLocalStorageItem(this.features[featureName].lsKey, Number(newStatus));
    }
  }

  @action toggleFeaturesToggle(): void {
    this.setIsFeatureToggleHidden(!this.isFeatureToggleHidden);
  }

  @computed isFeatureOn(featureKey: string): boolean {
    return Boolean(this.features[featureKey]?.isOn);
  }

  addEventListener(): void {
    if (!this.isFeaturesToggleActivated) {
      return;
    }

    hotkeyManager.registerHotkey(
      () => { this.toggleFeaturesToggle(); }, { key: AppHotkey.Feature }, AppState.RoomJoined,
    );
  }

  @action addFeature(title: string, key: string): void {
    const newFeature: Feature = { title, lsKey: `show-${key}`, isOn: false };
    this.features = { ...this.features, [key]: newFeature };
    this.setFeaturesInitState();
  }

  @action setIsFeatureToggleHidden(value: boolean) {
    this.isFeatureToggleHidden = value;
    setLocalStorageItem(IS_FEATURE_TOGGLE_HIDDEN_KEY, value);
  }

  @action setIsFeaturesToggleActivated(value: boolean) {
    this.isFeaturesToggleActivated = value;
    setLocalStorageItem(IS_FEATURES_TOGGLE_ACTIVATED_KEY, value ? 1 : 0);
    this.setIsFeatureToggleHidden(!value);
  }
}

export default FeatureStore;
