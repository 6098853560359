import styled from '@emotion/styled';

import {
  Font,
  MediaBreakPoint,
  Size,
} from 'ui-kit/theme';

export const PageLayoutRoot = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100dvw',
  alignItems: 'center',
  height: '100dvh',
  fontFamily: Font.BRAND_MEDIUM,
  fontWeight: 500,
  fontSize: 14,
  letterSpacing: '0.01em',
  color: theme.colors.primary,
  background: theme.colors.secondary01,
  overflowY: 'auto',
  overflowX: 'hidden',
}));

export const Container = styled.div<{ noHeader?: boolean }>(({ noHeader }) => ({
  padding: `${noHeader ? 24 : Size.BASIC_HEADER_MOBILE_HEIGHT + 16}px 24px 24px`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  width: '80vw',
  height: 'inherit',
  maxWidth: 1272,
  [MediaBreakPoint.WIDTH_1365]: {
    width: '100%',
  },
  [MediaBreakPoint.WIDTH_1024]: {
    width: '95vw',
  },
  [MediaBreakPoint.WIDTH_960]: {
    width: '100dvw',
  },
  [MediaBreakPoint.WIDTH_640]: {
    padding: `${Size.BASIC_HEADER_MOBILE_HEIGHT}px 0 0 0`,
  },
  [MediaBreakPoint.HEIGHT_480]: {
    padding: `${Size.BASIC_HEADER_MOBILE_HEIGHT}px 0 0 0`,
  },
}));
