/* eslint-disable max-len */
import { MeetingType } from 'types/common';
import { PlaybackEventType } from 'services/MoodHoodApiClient/Playbacks.types';

import { LogToRemotePayload } from '../../helpers/logger';
import AnalyticsApi from './AnalyticsApi';
import LogApi from './LogApi';

export interface AnalyticsApiClient {
  analytics: AnalyticsApi;
  log: LogApi;
}

export interface BatchLogPayload {
  logs: LogToRemotePayload[],
  clientMeta: Record<string, unknown>
}

export interface UserAgentInfo {
  browser: string;
  browserVersion: string;

  deviceType?: string;
  deviceModel?: string;

  platform?: string;
  platformVersion?: string;

  userAgent?: string;
}

export interface MetricUserInfo {
  email?: string;
  phone?: string;
  userId?: string;
  userName?: string;
  clientUniqueId?: string;
  externalUserId?: string;
  participantId?: string;
}

export interface MetricDurations {
  onlineDuration: number;
  onlineDurationWithActiveTab: number;
  activeCameraDuration?: number;
  activeMicrophoneDuration?: number;
}

export interface MetricReactions {
  fireCount: number;
  heartCount: number;
  thumbUpCount: number;
  thumbDownCount: number;
}

type SendMetricPayloadBaseSuper = Partial<Omit<UserAgentInfo, 'userAgent'>> & MetricUserInfo & MetricDurations & MetricReactions;

interface SendMetricPayloadBase extends SendMetricPayloadBaseSuper {
  roomId: string;
  spaceId: string;
  roomType?: string;
  planAlias?: string;

  reactionsCount: number;
  chatMessagesCount: number;

  referer?: string;
}

export interface SendMetricPayload extends SendMetricPayloadBase {
  callId: string;
  participantId: string;
  isBreakoutRoom?: boolean;
}

export interface SendEventMetricPayload extends Omit<SendMetricPayloadBase, 'roomId' | 'roomType' | 'participantId' | 'activeCameraDuration' | 'activeMicrophoneDuration'> {
  playbackId?: string;
  playbackType?: PlaybackEventType;
  playbackEventId?: string;
  playbackEventSessionId?: string;
}

export interface NetworkMetric {
  jitter: number;
  rtt: number;
  packetLoss: number;
  mos: number;
  streams: number;
  node: string;
  direction: 'inbound' | 'outbound';
  timestamp: string;
}

export interface SendNetworkMetricsPayload {
  metrics: {
    jitter: number;
    rtt: number;
    packetLoss: number;
    mos: number;
    streams: number;
    node: string;
    direction: 'inbound' | 'outbound';
    timestamp: string;
  }[];
  clientMeta: {
    roomId: string;
    clientUniqueId: string;
  };
}

export interface GetPulsePayload {
  roomId: string;
  timeFrom: string;
  timeTo: string;
  limit?: number;
}

export enum AnalyticsEventCategory {
  Announcement = 'announcement',
}

export enum AnalyticsEventType {
  AnnouncementProdamusSuccess = 'announcement.prodamus.success',
  AnnouncementProdamusError = 'announcement.prodamus.error',
  AnnouncementProdamusWaiting = 'announcement.prodamus.wait',
  AnnouncementProdamusOpen = 'announcement.prodamus.open',
  AnnouncementProdamusClose = 'announcement.prodamus.close',

  AnnouncementBannerClose = 'announcement.banner.close',
  AnnouncementBannerOpenLink = 'announcement.banner.openLink',
}

export interface AnalyticsEvent {
  event: AnalyticsEventType;
  meetingId: string; // roomId or eventId
  meetingType: MeetingType;
  category?: AnalyticsEventCategory; // category of event, e.g. "chat", "join_form"
  clientUniqueId?: string;
  externalUserId?: string;
  userId?: string;
  userName?: string;
  email?: string;
  phone?: string;
  sessionId?: string; // possible ID of the call / session
  tabId?: string; // e.g. ID of browser tab / view
  target?: string; // possible event target name / id, e.g. "sign_up_btn" / messageId
  valueNum?: number; // could be used to store some discrete number value
  valueStr?: string; // could store arbitrary text like chat messages
  ua: UserAgentInfo & {
    tag?: string;
  };
  meta?: Record<string, unknown>;
  spaceId?: string; // possible ID of the space
  playbackId?: string; // possible ID of the playback
}
