import { Crop } from 'react-image-crop';

export const drawImage = (canvas: HTMLCanvasElement | null, image?: HTMLImageElement, crop?: Crop) => {
  if (!canvas || !image || !crop) {
    return;
  }

  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;
  const width = crop.width || 0;
  const height = crop.height || 0;

  // eslint-disable-next-line no-param-reassign
  canvas.width = width * pixelRatio;
  // eslint-disable-next-line no-param-reassign
  canvas.height = height * pixelRatio;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  ctx.drawImage(
    image,
    (crop.x || 0) * scaleX,
    (crop.y || 0) * scaleY,
    width * scaleX,
    height * scaleY,
    0,
    0,
    width,
    height,
  );
};
