import React, { FunctionComponent } from 'react';

import { Tooltip } from 'ui-kit';
import { TooltipProps } from 'ui-kit/components/Tooltip';

import { TooltipTitle, InfoIcon } from './index.styled';

interface InfoIconWithTooltipProps extends TooltipProps {
  message: string,
}

const InfoIconWithTooltip: FunctionComponent<InfoIconWithTooltipProps> = ({ message, ...restProps }) => (
  <Tooltip
    title={(
      <TooltipTitle>{message}</TooltipTitle>
    )}
    {...restProps}
  >
    <InfoIcon onClickCapture={(evt) => { evt.preventDefault(); }} />
  </Tooltip>
);

export default InfoIconWithTooltip;
