import React, { VoidFunctionComponent } from 'react';
import {
  ToastContainerProps,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { Theme } from '@emotion/react';

import { StyledToastContainer } from './Layout';

/* react-toastify replaces 'theme' property by its own, need to provide it in another prop */
interface ToastNoticeContainerProps extends Omit<ToastContainerProps, 'theme'> {
  emotionTheme: Theme,
  isPanelsShown: boolean,
}

const ToastNoticeContainer: VoidFunctionComponent<ToastNoticeContainerProps> = (props) => (
  <StyledToastContainer
    position="bottom-left"
    icon={false}
    hideProgressBar
    enableMultiContainer
    {...props}
  />
);

export {
  ToastNoticeContainer,
};
