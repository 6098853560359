import React, {
  CSSProperties,
  FunctionComponent,
  HTMLProps,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import isMobile from 'is-mobile';

import {
  Dialog,
  DialogFromBottom,
  IconButton,
  Loader,
  ButtonSelect,
} from 'ui-kit';
import { PlanInfo } from 'modules/Plans/types';
import { ButtonSelectOption } from 'ui-kit/components/ButtonSelect/types';
import { PromoPlanPricing } from 'components/PromoPlansPage/const';
import { getBrandOptions } from 'modules/Branding';
import { PaymentPlan } from 'constants/payment';
import SupportButton from 'components/SupportButton';

import Layout, {
  Plans, Info, PlanName, PlansTitle, CurrentPlan, PlanNameContainer,
} from './PlansList.styled';
import PlanCard from './PlanCard';
import { BillPeriod } from './types';

interface PlansListProps {
  plans: PlanInfo[],
  currentPlanName: string,
  periodDiscounts: {
    month?: string,
    year?: string,
  },
  promoPlanPricing?: PromoPlanPricing,
  supportButtonStyle?: CSSProperties,
  noTitle?: boolean
}

const PlansList: FunctionComponent<Omit<HTMLProps<HTMLDivElement>, 'ref'> & PlansListProps> = observer(({
  plans,
  currentPlanName,
  periodDiscounts,
  promoPlanPricing,
  supportButtonStyle,
  noTitle,
  style,
  className,
}) => {
  const [isFreePlanDialogVisible, setIsFreePlanDialogVisible] = useState(false);
  const [billPeriod, setBillPeriod] = useState<BillPeriod>('month');
  const { t } = useTranslation();

  const handlePeriodChange = (option: ButtonSelectOption) => {
    setBillPeriod(option.value as BillPeriod);
  };

  const openFreePlanDialog = () => {
    setIsFreePlanDialogVisible(true);
  };

  if (plans.length === 0) {
    return <Loader centered />;
  }

  const { planDescriptions, freePlanAlias } = getBrandOptions();

  const freePlan = plans.find((plan) => plan.alias === freePlanAlias);

  const freePlanName = planDescriptions[freePlanAlias]?.name;
  const title = [t('payment.plan'), freePlanName].join(' ');

  return (
    <Layout style={style} className={className}>
      <Info>
        <CurrentPlan>
          {noTitle ? <div /> : <PlansTitle size="h2">{t('payment.planTitle')}</PlansTitle>}
          <PlanNameContainer>
            <PlanName size="main-text-bold">{`${t('common.current')}:`}</PlanName>
            {
              currentPlanName === planDescriptions[freePlanAlias].name
                ? (
                  <IconButton
                    id="free-plan-link"
                    subVariant="link"
                    onClick={openFreePlanDialog}
                  >
                    {currentPlanName}
                  </IconButton>
                )
                : <PlanName size="main-text-bold">{currentPlanName}</PlanName>
            }
          </PlanNameContainer>
        </CurrentPlan>
        {plans.find((x) => [PaymentPlan.enterprise_feb17, freePlanAlias].includes(x.alias)) && plans.length !== 2 && (
          <ButtonSelect
            size="small"
            onChange={handlePeriodChange}
            options={[
              {
                id: 'month',
                value: 'month',
                text: t('common.monthly'),
                badgeText: periodDiscounts.month || '',
                selected: true,
              },
              {
                id: 'year',
                value: 'year',
                text: t('common.annual'),
                badgeText: periodDiscounts.year || '',
              },
            ]}
          />
        )}
      </Info>
      <Plans style={{ justifyContent: isMobile() ? 'flex-start' : 'center' }}>
        {plans.filter((plan) => plan.alias !== freePlanAlias).map((plan) => (
          <PlanCard
            key={plan.planId}
            {...plan}
            openFreePlanDialog={openFreePlanDialog}
            period={billPeriod}
            promoPlanPricing={promoPlanPricing}
          />
        ))}
      </Plans>
      {
        /* TBD: should be single ui-kit dialog */
        isMobile() ? (
          <DialogFromBottom
            title={title}
            isVisible={isFreePlanDialogVisible}
            onClose={() => { setIsFreePlanDialogVisible(false); }}
          >
            { freePlan && <PlanCard {...freePlan} inPopup hideTitle period={billPeriod} /> }
          </DialogFromBottom>
        ) : (
          <Dialog
            isHidden={!isFreePlanDialogVisible}
            title={title}
            onClose={() => { setIsFreePlanDialogVisible(false); }}
          >
            { freePlan && <PlanCard {...freePlan} inPopup hideTitle period={billPeriod} /> }
          </Dialog>
        )
      }
      <SupportButton style={supportButtonStyle} />
    </Layout>
  );
});

export default PlansList;
