import React, { VoidFunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { ToastContainerProps, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { ToastContainers } from 'constants/common';
import { useTheme } from '@emotion/react';

import useStores from 'stores/root';
import { getSnackbarByType } from 'ui-kit/components/Snackbar/helpers';

import { StyledTopCenterSnackbarContainer } from './TopCenterSnackbar.styled';

export interface ContainerProps extends Omit<ToastContainerProps, 'theme'> {
  isPanelsShown: boolean,
}

export const TopCenterSnackbarContainer: VoidFunctionComponent<Omit<ContainerProps, 'theme'>> = observer((props) => {
  const theme = useTheme();
  const { uiStore } = useStores();

  return (
    <StyledTopCenterSnackbarContainer
      position="top-center"
      containerId={ToastContainers.TopCenter}
      icon={false}
      hideProgressBar
      hidden={uiStore.isDesktopAppSharingActive}
      enableMultiContainer
      appTheme={theme}
      limit={1}
      {...props}
    />
  );
});

export const TopCenterSnackbarParams: ToastOptions = {
  containerId: ToastContainers.TopCenter,
  closeButton: false,
  autoClose: 5000,
};

export const topCenterSnackbarInfo = getSnackbarByType('info', TopCenterSnackbarParams);

export const topCenterSnackbarWarn = getSnackbarByType('warning', TopCenterSnackbarParams);

export const topCenterSnackbarError = getSnackbarByType('error', TopCenterSnackbarParams);

export const topCenterSnackbarSuccess = getSnackbarByType('success', TopCenterSnackbarParams);
