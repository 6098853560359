import type { AxiosInstance } from 'axios';

import UserApi from './UserApi';
import AuthApi from './AuthApi';
import SpaceApi from './space';
import RoomApi from './RoomApi';
import InviteApi from './InviteApi';
import AssetsApi from './AssetsApi';
import RecordApi from './RecordApi';
import AnalyticsApi from './AnalyticsApi';
import PaymentsApi from './PaymentsApi';
import CallsApi from './CallsApi';
import IssueApi from './IssueApi';
import IntegrationsApi from './IntegrationsApi';
import api from './Api';
import PersonalTokenApi from './user/PersonalTokenApi';
import PlaybacksApi from './PlaybacksApi';
import PlaybackEventsApi from './PlaybackEventsApi';
import AccountsApi from './AccountsApi';

class MoodHoodApiClient {
  private readonly api: AxiosInstance;

  public auth: AuthApi;

  public readonly personalTokenApi: PersonalTokenApi;

  public user: UserApi;

  public space: SpaceApi;

  public room: RoomApi;

  public invite: InviteApi;

  public assets: AssetsApi;

  public record: RecordApi;

  public analytics: AnalyticsApi;

  public payments: PaymentsApi;

  public calls: CallsApi;

  public issue: IssueApi;

  public integrations: IntegrationsApi;

  public playbacks: PlaybacksApi;

  public playbackEvents: PlaybackEventsApi;

  public accounts: AccountsApi;

  constructor() {
    this.api = api;

    this.auth = new AuthApi(this.api);

    this.personalTokenApi = new PersonalTokenApi(this.api);

    this.user = new UserApi(this.api);

    this.space = new SpaceApi(this.api);

    this.room = new RoomApi(this.api);

    this.invite = new InviteApi(this.api);

    this.assets = new AssetsApi(this.api);

    this.record = new RecordApi(this.api);

    this.analytics = new AnalyticsApi(this.api);

    this.payments = new PaymentsApi(this.api);

    this.calls = new CallsApi(this.api);

    this.issue = new IssueApi(this.api);

    this.integrations = new IntegrationsApi(this.api);

    this.playbacks = new PlaybacksApi(this.api);

    this.playbackEvents = new PlaybackEventsApi(this.api);

    this.accounts = new AccountsApi(this.api);
  }
}

export default MoodHoodApiClient;
