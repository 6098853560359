import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import useStores from 'stores/root';
import { IconButton } from 'ui-kit';
import { getBrandDomain } from 'helpers/url';
import { QuestionOutlineIcon } from 'ui-kit/assets/icons';
import { MediaBreakPoint } from 'ui-kit/theme';

const KnowledgeBaseButton: FunctionComponent = observer(() => {
  const { spaceStore } = useStores();

  const { t } = useTranslation();

  return (
    <IconButton
      id="knowledge-base"
      data-stats={spaceStore.space ? 'ui_rooms_kb' : 'ui_spaces_kb'}
      as="a"
      href={`https://help.${getBrandDomain()}/`}
      target="_blank"
      rel="noreferrer"
      text={t('common.helpCenter')}
      icon={<QuestionOutlineIcon />}
      hideTextBreakPoint={MediaBreakPoint.WIDTH_1024}
    />
  );
});

export default KnowledgeBaseButton;
