import React, {
  FunctionComponent, KeyboardEventHandler, useEffect, useState,
} from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import isMobile from 'is-mobile';
import { toast } from 'react-toastify';
import type { LocationDescriptorObject } from 'history';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';

import {
  Checkbox,
  IconButton,
  InputMessage,
} from 'ui-kit';
import { NotWhiteLabelIFrame } from 'components/Optional';
import useStores from 'stores/root';
import { Errors } from 'constants/errors';
import { getUrlWithUTM } from 'helpers/utm';
import {
  getBrandDomain,
  getUrlParam,
  isBrandLivedigital,
  isBrandTeleBoss,
  isValidRedirectUrl,
} from 'helpers/url';
import { getApiErrorResponse } from 'helpers/errors';
import { getCurrentLanguage } from 'services/i18n';
import { extractInviteParams } from 'helpers/routes';
import logger from 'helpers/logger';
import { StatsId } from 'types/stats';
import { getFormInputClearButton } from 'ui-kit/components/Input/getFormInputClearButton';
import { EyeCloseIcon, EyeIcon } from 'ui-kit/assets/icons';
import { setLocalStorageItem } from 'helpers/localStorage';
import { REGISTERED_LS_KEY } from 'components/AfterRegisterDialog/AfterRegisterDialogWrapper';
import { getBrandOptions } from 'modules/Branding';

import {
  Input,
  SpanWhite,
  Agreements,
  CloseAlertButton,
  NewsletterAgreements,
  InputPhone,
} from './Forms.styled';
import FormError from './FormError';

interface FormFields {
  email: string;
  password: string;
  phone: string;
}

const SignUpForm: FunctionComponent<{
  className?: string,
  separator?: React.ReactNode,
  footer?: React.ReactNode,
}> = observer(({
  separator,
  footer,
  className,
}) => {
  const {
    authStore,
    roomStore,
    spaceStore,
    userStore,
    rootStore: { moodHoodApiClient, metrics, carrotQuest },
  } = useStores();
  const [redirectTo, setRedirectTo] = useState<LocationDescriptorObject>();
  const [errorCode, setErrorCode] = useState<string >();
  const [isEmailSubscriptionEnabled, setIsEmailSubscriptionEnabled] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const getReCaptchaToken = async ():Promise<string> => {
    if (!executeRecaptcha) {
      return '';
    }

    const token: string = await executeRecaptcha('register');

    return token;
  };

  const currentLanguage = getCurrentLanguage();

  const phoneDefaultCountry = ((isBrandLivedigital() || isBrandTeleBoss()) ? 'RU' : 'US') as CountryCode;

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: {
      errors,
      isValid,
    },
    setError,
    control,
    setValue,
    getValues,
    setFocus,
    watch,
  } = useForm<FormFields>({
    mode: 'all',
    defaultValues: {
      email: '',
      password: '',
      phone: `+${getCountryCallingCode(phoneDefaultCountry)}`,
    },
  });

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    metrics.marketing.trackUserRegistrationAttempt(data.email);
    setIsSubmitDisabled(true);

    await userStore.createUser({
      ...data,
      username: data.email,
      getReCaptchaToken,
    });

    if (!userStore.error) {
      await authStore.signIn(data.email, data.password, getReCaptchaToken);
    } else {
      setIsSubmitDisabled(false);
      return;
    }

    setLocalStorageItem(REGISTERED_LS_KEY, data.email);

    const to = getUrlParam('to');

    const { inviteId, spaceId: inviteSpaceId, roomId } = extractInviteParams({ pathname: to || '' });
    const activateInviteResults = await spaceStore.activateSpaceInviteForJustSignedUpUser({
      inviteId: inviteId || '',
      inviteSpaceId: inviteSpaceId || '',
      email: data.email,
    });
    let { spaceId } = activateInviteResults;
    const { skipInitialSpaceCreation } = activateInviteResults;

    if (skipInitialSpaceCreation) {
      logger.debug('Skip initial space creation for moderator signed up from invite');
    } else {
      const { space, error } = await moodHoodApiClient.space.createSpace({ name: t('admin.spaces.defaultSpaceName') });
      if (error) {
        logger.error('Failed to create initial space on signup', { error, email: data.email });
      }

      spaceId = space?.id;
    }

    const spaceUrl = spaceId ? `${window.location.origin}/space/${spaceId}` : '';
    window.lsd.spaceId = spaceId;
    carrotQuest.trackUserSignUp({ isEmailSubscriptionEnabled, spaceUrl });
    metrics.marketing.trackUserRegistration(data.email);

    if (userStore.username) {
      metrics.marketing.trackUserSignUp(userStore.username);
    }

    // in case of successfully activated invite we don't need to redirect to invite page
    const ignoreRedirectTarget = inviteSpaceId && inviteId && spaceStore.isInviteActivated;

    if (spaceId) {
      if (!skipInitialSpaceCreation) {
        await Promise.all([
          roomStore.createLesson(t('admin.rooms.lessonDefaultName'), spaceId),
          roomStore.createWebinar(t('admin.rooms.webinarDefaultName'), spaceId),
        ]);
      }

      if (to && to !== '/' && !ignoreRedirectTarget) {
        const search = new URLSearchParams(window.location.search);
        search.delete('to');
        setRedirectTo({ pathname: to, search: search.toString() });
        return;
      }

      if (isMobile()) {
        toast.info(t('moderator.afterSignupMobileAlert'),
          {
            autoClose: false,
            closeButton: (
              <CloseAlertButton id="close-alert">
                {t('common.close')}
              </CloseAlertButton>
            ),
          });
      }

      const currentUrl = new URL(window.location.href);
      currentUrl.pathname = '/';
      if (currentUrl.searchParams.has('to') && currentUrl.searchParams.get('to') !== '/') {
        currentUrl.searchParams.delete('to');
      }

      setRedirectTo({
        pathname: roomId ? `/room/${roomId}` : `/space/${spaceId}`,
        search: currentUrl.searchParams.toString(),
        state: { fromSignUp: true },
      });
    } else if (!isValidRedirectUrl(to)) {
      const url = new URL(getUrlWithUTM('/'), window.location.href);
      setRedirectTo({ pathname: '/', search: url.search });
    }

    setIsSubmitDisabled(false);
  };

  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  const passwordOnKeyupHandler: KeyboardEventHandler<HTMLInputElement> = (e) => {
    setIsCapsLockOn(e.getModifierState('CapsLock'));
  };

  useEffect(() => {
    clearErrors('password');
  }, [isCapsLockOn]);

  useEffect(() => reaction(
    () => [authStore.authError, userStore.error],
    ([signInError, createUserError]) => {
      if (createUserError) {
        const errCode = getApiErrorResponse(createUserError);
        setErrorCode(errCode);
        return;
      }

      if (signInError) {
        const errCode = getApiErrorResponse(signInError);
        setErrorCode(errCode);
        return;
      }

      setErrorCode(undefined);
    },
  ), []);

  useEffect(() => {
    if (errorCode === Errors.ALREADY_REGISTERED) {
      setError('email', { message: t('auth.emailIsAlreadyTaken') });
    }
  }, [errorCode]);

  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  if (redirectTo) {
    return (
      <Redirect to={redirectTo || getUrlWithUTM('/')} />
    );
  }

  const isPhoneErrorAsHint = errors.phone?.message === t('auth.phoneMustBeAValid') && watch('phone')?.length < 5;

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <InputPhone
            required
            fullWidth
            id="phone"
            name="phone"
            control={control}
            autoComplete="phone"
            label={t('auth.phone')}
            currentLanguage={currentLanguage}
            defaultCountry={phoneDefaultCountry}
            placeholder={t('auth.enterYourPhone')}
            validationFailedMessage={t('auth.phoneMustBeAValid')}
            requiredFailedMessage={t('auth.phoneIsRequired')}
            hasError={Boolean(errors.phone) && !isPhoneErrorAsHint}
            endInputElement={getFormInputClearButton<FormFields>({
              name: 'phone', getValues, setValue, clearErrors, setFocus,
            })}
          >
            <InputMessage isHint={isPhoneErrorAsHint}>
              {errors.phone?.message}
            </InputMessage>
          </InputPhone>
          <Input
            id="email"
            fullWidth
            label={t('auth.email')}
            type="email"
            required
            autoComplete="email"
            placeholder={t('auth.enterYourEmail')}
            {...register('email', {
              required: { value: true, message: t('auth.emailIsRequired') },
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: t('auth.emailMustBeAValid'),
              },
            })}
            hasError={Boolean(errors.email)}
            endInputElement={getFormInputClearButton<FormFields>({
              name: 'email', getValues, setValue, clearErrors, setFocus,
            })}
          >
            <InputMessage>
              {errors.email?.message}
            </InputMessage>
          </Input>
          <Input
            id="password"
            fullWidth
            label={t('auth.password')}
            type={isPasswordHidden ? 'password' : 'text'}
            required
            autoComplete="new-password"
            placeholder={t('auth.createYourPassword')}
            onKeyUp={passwordOnKeyupHandler}
            {...register('password', {
              required: { value: true, message: t('auth.passwordIsRequired') },
              minLength: { value: 6, message: t('auth.passwordMinLength', { value: 6 }) },
            })}
            hasError={Boolean(errors.password)}
            endInputElement={getFormInputClearButton<FormFields>({
              name: 'password', getValues, setValue, clearErrors, setFocus,
            })}
            afterInputElement={(
              <IconButton
                id="toggle-password"
                size="large"
                onClick={() => {
                  setIsPasswordHidden((x) => !x);
                }}
              >
                {isPasswordHidden ? <EyeIcon /> : <EyeCloseIcon />}
              </IconButton>
            )}
          >
            <InputMessage>
              {isCapsLockOn && (<SpanWhite>{t('auth.capsLockIsOn')}</SpanWhite>)}
              {isCapsLockOn && errors.password?.message && <SpanWhite>{t('auth.and')}</SpanWhite>}
              <span>{errors.password?.message}</span>
            </InputMessage>
          </Input>
          <NotWhiteLabelIFrame>
            <NewsletterAgreements>
              <Checkbox
                id="agree"
                type="checkbox"
                style={{ width: '100%' }}
                checked={isEmailSubscriptionEnabled}
                onChange={() => setIsEmailSubscriptionEnabled(!isEmailSubscriptionEnabled)}
                label={t('auth.agree', {
                  company: t(getBrandOptions().company),
                  brand: t(getBrandOptions().name),
                })}
              />
            </NewsletterAgreements>
          </NotWhiteLabelIFrame>
        </div>
        {separator}
        <div>
          <NotWhiteLabelIFrame>
            <Agreements center={isMobile()}>
              {t('auth.agreements1', { registration: t('common.registration') })}
              <a href={getUrlWithUTM(`https://${getBrandDomain()}/agreement`)} target="_blank" rel="noreferrer">
                {t('auth.agreements2')}
              </a>
              {t('auth.agreements3')}
              <a href={getUrlWithUTM(`https://${getBrandDomain()}/privacy`)} target="_blank" rel="noreferrer">
                {t('auth.agreements4')}
              </a>
              .
            </Agreements>
          </NotWhiteLabelIFrame>
          {errorCode === Errors.SOMETHING_WENT_WRONG && (
            <FormError title={t('errors.somethingWentWrong')}>
              {t('errors.somethingWentWrongBody')}
            </FormError>
          )}
          <IconButton
            id="register"
            type="submit"
            fullWidth
            isLoading={authStore.isLoading}
            data-stats={StatsId.SignupUser}
            disabled={isSubmitDisabled || !isValid}
            variant="primary"
          >
            {t('common.registration')}
          </IconButton>
        </div>
        {footer}
      </form>
    </div>
  );
});

export default SignUpForm;
