/* eslint-disable max-len */
import React, {
  VoidFunctionComponent, useEffect,
} from 'react';
import { observer } from 'mobx-react-lite';
import { isAfter, isBefore, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import {
  VirtualList,
} from 'components/Space/Lists/Layout';
import { PlaybackEventSessionExtended } from 'modules/PlaybackEventSessions/types';
import PlaybackEventSessionsListItemsProvider from 'modules/PlaybackEventSessions/PlaybackEventSessionsListItemsProvider';
import timeZones from 'services/i18n/timeZones';
import useDelayedLoader from 'hooks/useDelayedLoader';

import { PlaybackEventSessionsListItemProps, EventState } from './PlaybackEventSessionsListItem/types';
import PlaybackEventSessionsListItem from './PlaybackEventSessionsListItem';
import ListContainer from '../ListContainer';

interface PlaybackEventSessionsListProps {
  dataProvider: PlaybackEventSessionsListItemsProvider;
  onDownloadAnalyticsReport(playbackEventId: string, playbackEventSessionId: string): void,
}

const getZonedDateTime = ({ timezone, startDate, frequency }: PlaybackEventSessionExtended) => {
  const tzData = timezone ? timeZones.findTimeZoneById(timezone) : null;
  const zonedDate = tzData ? utcToZonedTime(startDate, tzData.id) : startDate;
  const sessionDate = frequency !== 'permanent' ? zonedDate : null;
  const date = sessionDate ? format(sessionDate, 'dd/MM/yy') : '';
  const time = sessionDate ? sessionDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : '';

  return { date, time };
};

const formatTimeZone = (timeZone: string) => {
  const tzData = timeZones.findTimeZoneById(timeZone);

  if (!tzData) {
    return '';
  }

  return tzData.titleWithOffset;
};

const PlaybackEventSessionsList: VoidFunctionComponent<PlaybackEventSessionsListProps> = observer(({
  dataProvider, onDownloadAnalyticsReport,
}) => {
  const { isLoaderVisible, resetLoader, cancelLoader } = useDelayedLoader();

  const getEventState = ({ startDate, endDate }: PlaybackEventSessionExtended): EventState => {
    const now = Date.now();
    if (endDate && isBefore(new Date(endDate), now)) {
      return EventState.Past;
    }

    if (startDate && isAfter(new Date(startDate), now)) {
      return EventState.Future;
    }

    return EventState.Now;
  };

  const sessionToEventCardProps = (session: PlaybackEventSessionExtended): PlaybackEventSessionsListItemProps => {
    const state = getEventState(session);
    const timeZone = session.timezone ? formatTimeZone(session.timezone) : '';
    const { date, time } = getZonedDateTime(session);
    const props = {
      sessionId: session.id,
      date,
      time,
      state,
      participantsCount: session.uniqueParticipantsCount,
      recurrence: session.recurrence || undefined,
      playbackEventId: session.playbackEventId || session.id,
      frequency: session.frequency,
      timeZone,
      onDownloadAnalyticsReport,
    };

    return props;
  };

  const { items, isBusy, hasMoreItems } = dataProvider;

  useEffect(() => {
    if (isBusy) {
      resetLoader();
    } else {
      cancelLoader();
    }
  }, [isBusy]);

  return (
    <ListContainer isLoading={isLoaderVisible && isBusy}>
      <VirtualList
        data={items}
        endReached={() => hasMoreItems && dataProvider.loadMore()}
        itemContent={(idx: number, session) => (
          <PlaybackEventSessionsListItem {...sessionToEventCardProps(session as PlaybackEventSessionExtended)} />
        )}
      />
    </ListContainer>
  );
});

export default PlaybackEventSessionsList;
