import { apiCall } from 'decorators';
import {
  action, makeAutoObservable, observable, reaction,
} from 'mobx';

import { CallData } from 'services/MoodHoodApiClient/types';
import api from 'services/MoodHoodApiClient/Api';
import CallsApi from 'services/MoodHoodApiClient/CallsApi';
import CallLimit from 'modules/СallLimit';

import { CallsParams } from './types';
import { Call, RoomEventNewRestrictionsPayload } from '../../types/common';

class Calls {
  @observable isBusy = false;

  @observable error: string | null = null;

  @observable callData: CallData | null = null;

  @observable activeCall: Call | null = null;

  private callsApi = new CallsApi(api);

  private params: CallsParams;

  private reactionDisposers: (() => void)[] = [];

  callLimit: CallLimit;

  constructor(params: CallsParams) {
    this.params = params;
    this.initReactions();
    makeAutoObservable(this);
    this.callLimit = new CallLimit({ calls: this, room: this.params.room });
  }

  dispose() {
    this.callLimit.dispose();
    this.reactionDisposers.forEach((disposer) => disposer());
  }

  initReactions() {
    const { room } = this.params;
    this.reactionDisposers = [
      reaction(
        () => room.isRoomJoined,
        (isRoomJoined) => {
          if (!isRoomJoined) {
            return;
          }

          this.fetchActiveCall();
          this.fetchCallData();
        },
      ),
    ];
  }

  handleError(userError: string) {
    this.error = userError;
  }

  resetError() {
    this.error = null;
  }

  @apiCall()
  async fetchCallData() {
    const { spaceId, id: roomId } = this.params.room;

    if ((!spaceId || !roomId)) {
      throw new Error('Room info not available');
    }

    const { data, error } = await this.callsApi.fetchCallData(spaceId, roomId);

    if (error) {
      this.handleError('calls.callLoadError');
      return;
    }

    if (data) {
      this.callData = data;
    }
  }

  @apiCall()
  async fetchActiveCall(): Promise<void> {
    const { spaceId, id: roomId } = this.params.room;

    if ((!spaceId || !roomId)) {
      throw new Error('Room info not available');
    }

    const { data, error } = await this.callsApi.fetchActiveCall(spaceId, roomId);

    if (error) {
      this.handleError('calls.activeCallLoadError');
      return;
    }

    if (data) {
      this.activeCall = data;
      window.lsd.callId = data?.id;
    }
  }

  @action updateRestrictionsByEvent(payload: RoomEventNewRestrictionsPayload): void {
    if (!this.callData || this.callData.callId !== payload.callId) {
      return;
    }

    const { restrictions, ...restCallData } = this.callData;
    this.callData = { ...restCallData, restrictions: payload.restrictions };
  }
}

export default Calls;
