import en from './en';
import ru from './ru';

export type SupportLanguages = 'en' | 'ru';

const locales = (
  /* desktop app languages should be restricted from spa side */
  (window.process ? process.env.REACT_APP_LOCALES : 'ru,en') as SupportLanguages
).split(',') as SupportLanguages[];

export default {
  ...(locales.includes('en') && { en }),
  ...(locales.includes('ru') && { ru }),
};
