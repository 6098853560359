import React, {
  ComponentPropsWithRef, FunctionComponent, forwardRef,
} from 'react';

import { ToggleRoot, CheckBoxInput } from './index.styled';

export type LabelPosition = 'right' | 'left';

export interface ToggleProps extends ComponentPropsWithRef<'input'> {
  label?: string | JSX.Element,
  labelPosition?: LabelPosition,
  fullWidth?: boolean,
  children?: never,
}

const Toggle: FunctionComponent<ToggleProps> = forwardRef<HTMLInputElement, ToggleProps>(({
  label, className, labelPosition = 'right', fullWidth, ...props
}, ref) => (
  <ToggleRoot className={className} labelPosition={labelPosition} fullWidth={fullWidth}>
    <CheckBoxInput type="checkbox" {...props} ref={ref} />
    {label}
  </ToggleRoot>
));

export default Toggle;
