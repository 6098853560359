import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { SubmitHandler, UseFormReturn } from 'react-hook-form';

import { Input, InputMessage } from 'ui-kit';
import useStores from 'stores/root';
import { StatsId } from 'types/stats';

import {
  ServerError,
  ConfirmButton,
} from '../Dialog/index.styled';
import { FormInput } from '.';

interface ChangeMailFormProps {
  useFormReturn: UseFormReturn<FormInput>;
  onSubmit: SubmitHandler<FormInput>;
  serverError: string | undefined;
}

const ChangeEmailForm: FunctionComponent<ChangeMailFormProps> = observer(({
  useFormReturn, onSubmit, serverError,
}) => {
  const { userStore } = useStores();
  const { t } = useTranslation();

  const {
    register, handleSubmit, formState: { errors },
  } = useFormReturn;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        id="current-email"
        label={t('userProfile.currentEmailLabel')}
        fullWidth
        disabled
        {...register('currentEmail')}
      />
      <Input
        id="new-email"
        label={t('userProfile.newEmailLabel')}
        fullWidth
        type="email"
        hasError={Boolean(errors.newEmail)}
        placeholder={t('userProfile.newEmailPlaceholder')}
        style={{ marginBottom: 10 }}
        {...register('newEmail', {
          required: { value: true, message: t('userProfile.newEmailRequired') },
          pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: t('auth.emailMustBeAValid'),
          },
        })}
      >
        <InputMessage>
          {errors.newEmail?.message}
        </InputMessage>
      </Input>
      <ConfirmButton
        id="change-email"
        data-stats={StatsId.ProfileChangeEmailConfirm}
        type="submit"
        disabled={userStore.isEmailUpdating}
        variant="primary"
      >
        {t('common.submit')}
      </ConfirmButton>
      {Boolean(serverError) && (
        <ServerError>
          <InputMessage>
            {serverError}
          </InputMessage>
        </ServerError>
      )}
    </form>
  );
});

export default ChangeEmailForm;
