import { computed, makeAutoObservable, observable } from 'mobx';

import UserSettings from 'modules/UserSettings';
import { ThemeType } from 'ui-kit/themes/types';

class AppTheme {
  @observable private _type: ThemeType = ThemeType.auto;

  private userSettings: UserSettings;

  async init() {
    await this.userSettings.load();
    const userTheme = this.userSettings.theme;
    if (userTheme) {
      this._type = userTheme;
    }
  }

  constructor(userSettings: UserSettings) {
    this.userSettings = userSettings;
    makeAutoObservable(this);
  }

  getSystemTheme() {
    const isSystemDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;

    return isSystemDarkTheme ? ThemeType.dark : ThemeType.light;
  }

  setTheme(themeType: ThemeType) {
    this._type = themeType;
  }

  changeTheme(themeType: ThemeType) {
    this.setTheme(themeType);
    this.userSettings.setTheme(themeType);
  }

  @computed get type() {
    return this._type;
  }
}

export default AppTheme;
