import styled from '@emotion/styled';
import {
  SecondColumn as SecondColumnDefault,
  Section as SectionDefault, Buttons as ButtonsDefault,
} from 'ui-kit/components/Dialog/DialogLayoutDesktop';

export const SecondColumn = styled(SecondColumnDefault)({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 0,
});

export const Section = styled(SectionDefault)({
  margin: '0 0',
  marginBottom: 24,
});

export const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const Buttons = styled(ButtonsDefault)({
  display: 'flex',
  flexDirection: 'row',
  alignSelf: 'center',
  width: 300,
  marginTop: 24,
  gap: 16,
  button: {
    width: '50%',
  },
});
