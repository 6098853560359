import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useState,
} from 'react';
import isMobile from 'is-mobile';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import useStores from 'stores/root';
import {
  Flex,
  Input,
  FlexSpaceBetween,
  InputMessage,
  IconButton,
} from 'ui-kit';
import { LivedigitalTeleboss, NotWhiteLabelIFrame } from 'components/Optional';
import { StatsId } from 'types/stats';
import { MIN_USER_NAME } from 'constants/common';
import usePlansPage from 'hooks/usePlansPage';

import { MobileLayout } from '../MobileLayout';
import { ProfileStatesNames } from '../type';
import ChangeLanguageDropDownButton from './ChangeLanguageDropDownButton';
import UserAvatarWithUploader from './UserAvatarWithUploader';
import {
  Inputs,
  UserName,
  PlanFlex,
  PlanName,
  PlanInfo,
  BadgeInfo,
  PlanTitle,
  InputButton,
  DesktopLayout,
  PlanExpiredDate,
  CurrentPaymentPlan,
} from '../DesktopLayout';

interface UserProfileMainProps {
  userName: string;
  handleUserNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleUserNameOnSave: () => void;
}

const UserProfileMain: FunctionComponent<UserProfileMainProps> = observer(({
  userName,
  handleUserNameChange,
  handleUserNameOnSave,
}) => {
  const {
    uiStore,
    authStore,
    userStore,
    paymentStore,
    spaceStore,
    spaceStore: { space },
  } = useStores();
  const { t } = useTranslation();
  const { CHANGE_EMAIL, CHANGE_PASSWORD } = ProfileStatesNames;
  const [nameError, setNameError] = useState('');
  const { redirectToPlansPage } = usePlansPage();

  const handleOpenPlans = () => {
    if (!space) {
      return;
    }

    uiStore.closeUserProfile();
    redirectToPlansPage();
  };

  useEffect(() => {
    if (userName.trim().length < MIN_USER_NAME) {
      setNameError(t('errors.tooShortName'));
      return;
    }

    if (nameError) {
      setNameError('');
    }
  }, [userName]);

  useEffect(() => {
    userStore.fetchUserInfo();
  }, []);

  const ismobile = useMemo(() => isMobile(), []);

  const Layout = ismobile ? MobileLayout : DesktopLayout;

  return (
    <Layout data-qa="user-details-layout">
      <div>
        <FlexSpaceBetween gap={ismobile ? 12 : 24}>
          <Flex gap={24}>
            <UserAvatarWithUploader userName={userName} />
            <BadgeInfo>
              <UserName>{userName}</UserName>
              <NotWhiteLabelIFrame>
                {(authStore.isUser && paymentStore.currentPlan) && (
                  <CurrentPaymentPlan>
                    {paymentStore.currentPlan?.name}
                  </CurrentPaymentPlan>
                )}
              </NotWhiteLabelIFrame>
            </BadgeInfo>
          </Flex>
          <ChangeLanguageDropDownButton />
        </FlexSpaceBetween>
        <Inputs data-qa="user-details-inputs">
          <Input
            id="user-name"
            fullWidth
            label={t('common.displayName')}
            value={userName}
            hasError={Boolean(nameError)}
            onChange={handleUserNameChange}
            style={{ marginBottom: 10 }}
            endInputElement={(
              <InputButton
                tabIndex={-1}
                id="save-username"
                data-stats={StatsId.ProfileChangeName}
                buttonSize="small"
                variant="primary"
                onClick={handleUserNameOnSave}
                disabled={userName.trim().length < MIN_USER_NAME}
              >
                {t('common.save')}
              </InputButton>
            )}
          >
            <InputMessage>
              {nameError}
            </InputMessage>
          </Input>
          <NotWhiteLabelIFrame>
            {authStore.isUser && (
              <>
                <Input
                  id="user-email"
                  fullWidth
                  label={t('common.email')}
                  value={userStore.email}
                  disabled
                  tabIndex={-1}
                  endInputElement={(
                    <InputButton
                      tabIndex={-1}
                      id="change-email"
                      data-stats={StatsId.ProfileChangeEmail}
                      buttonSize="small"
                      variant="primary"
                      onClick={() => uiStore.setUserProfileDialogState(CHANGE_EMAIL)}
                    >
                      {t('common.change')}
                    </InputButton>
                  )}
                />
                <Input
                  id="user-password"
                  fullWidth
                  label={t('common.password')}
                  value="password"
                  type="password"
                  disabled
                  tabIndex={-1}
                  endInputElement={(
                    <InputButton
                      tabIndex={-1}
                      id="change-password"
                      data-stats={StatsId.ProfileChangePassword}
                      buttonSize="small"
                      variant="primary"
                      onClick={() => uiStore.setUserProfileDialogState(CHANGE_PASSWORD)}
                    >
                      {t('common.change')}
                    </InputButton>
                  )}
                />
              </>
            )}
          </NotWhiteLabelIFrame>
        </Inputs>
      </div>
      <NotWhiteLabelIFrame>
        {(authStore.isUser && paymentStore.currentPlan && spaceStore.isSpaceOwner) && (
          <LivedigitalTeleboss>
            <PlanFlex>
              <PlanInfo>
                <PlanTitle>
                  {t('payment.plan')}
                  {': '}
                  <PlanName>{paymentStore.currentPlan?.name}</PlanName>
                </PlanTitle>
                <PlanExpiredDate>
                  {(space?.isSubscriptionActive && space?.subscriptionEndsAt)
                    ? t('common.until', { endOfSubscription: new Date(space.subscriptionEndsAt).toLocaleDateString() })
                    : t('payment.lifetime')}
                </PlanExpiredDate>
              </PlanInfo>
              <IconButton
                id="change-plan"
                data-stats={StatsId.ProfileChangePlan}
                buttonSize="small"
                variant="primary"
                onClick={handleOpenPlans}
                style={{ width: 'auto', flexShrink: 0 }}
              >
                {t('payment.changePlan')}
              </IconButton>
            </PlanFlex>
          </LivedigitalTeleboss>
        )}
      </NotWhiteLabelIFrame>
    </Layout>
  );
});

export default UserProfileMain;
