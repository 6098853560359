import cronParser from 'cron-parser';

import { PlaybackEvent } from 'services/MoodHoodApiClient/Playbacks.types';
import { PlaybackEventSessionExtended, DateRange } from './types';

class RecurrentEvent {
  private interval: cronParser.CronExpression;

  private dateRange: DateRange | null = null;

  private iterateInterval(limit: number, nextDate: () => cronParser.CronDate) {
    const sessions = [];
    // eslint-disable-next-line no-plusplus, no-param-reassign
    while (limit-- > 0) {
      try {
        const startDate = nextDate();
        sessions.push({ ...this.session, startDate: startDate.toDate() });
      } catch (err) {
        break;
      }
    }

    return sessions;
  }

  constructor(private evt: PlaybackEvent, private session: PlaybackEventSessionExtended) {
    const { startDate, endDate } = evt;

    if (!evt.recurrence || !startDate) {
      throw new Error('PlaybackEvent: event must be recurrent');
    }

    const options = {
      currentDate: startDate,
      utc: true,
      ...(endDate && { endDate }),
    };

    this.interval = cronParser.parseExpression(evt.recurrence, options);
  }

  nextSession() {
    const startDate = this.interval.next();
    return { ...this.session, startDate: startDate.toDate() };
  }

  prevSession() {
    const startDate = this.interval.prev();
    return { ...this.session, startDate: startDate.toDate() };
  }

  nextSessions(limit: number) {
    return this.iterateInterval(limit, () => this.interval.next());
  }

  prevSessions(limit: number) {
    return this.iterateInterval(limit, () => this.interval.prev());
  }
}

export default RecurrentEvent;
