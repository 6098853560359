/* eslint-disable @typescript-eslint/no-explicit-any */

import { getAxiosErrorMessage } from 'helpers/errors';
import { ApiResponse, ResponsePayload } from 'services/MoodHoodApiClient/types';
import { runInAction } from 'mobx';

export const lock = (lockName = 'isBusy', allowReEntry = true) => (
  target: any, propertyKey: string, descriptor: PropertyDescriptor,
) => {
  const originalMethod = descriptor.value;
  let callInProgress: Promise<any> | null = null;
  // eslint-disable-next-line no-param-reassign, func-names
  descriptor.value = function (...args: any[]) {
    const curValue = (this as any)[lockName];
    if (typeof curValue !== 'boolean') {
      throw new Error(`Lock decorator error: lock member must be boolean: ${lockName}`);
    }

    if (curValue && !allowReEntry && callInProgress) {
      /* allowReEntry forbids parallel function execution, returns the promise of first call */
      return callInProgress;
    }

    runInAction(() => { (this as any)[lockName] = true; });
    const result = originalMethod.apply(this, args);

    if (result && result instanceof Promise) {
      callInProgress = result;
      return result.finally(() => {
        runInAction(() => {
          (this as any)[lockName] = false;
          callInProgress = null;
        });
      });
    }

    throw new Error(`Lock decorator error: function must return Promise(${propertyKey})`);
  };
};

export const apiCall = <T>() => (
  target: any, propertyKey: string, descriptor: PropertyDescriptor,
) => {
  const originalMethod = descriptor.value;

  // eslint-disable-next-line no-param-reassign, func-names
  descriptor.value = async function (...args: any[]): ApiResponse<T> {
    try {
      const res: ResponsePayload<T> = await originalMethod.apply(this, args);
      return res;
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  };
};
