import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { ToastContainer as ToastContainerBase } from 'react-toastify';

import { IconButton as ButtonDefault } from 'ui-kit';
import InfoIcon from 'ui-kit/assets/icons/snackbar/info-circle-blue.svg';
import ErrorIcon from 'ui-kit/assets/icons/snackbar/error.svg';
import WarningIcon from 'ui-kit/assets/icons/snackbar/warning.svg';
import isMobile from 'is-mobile';
import { MediaBreakPoint, Size } from 'ui-kit/theme';

export const Content = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const Button = styled(ButtonDefault)({
  height: 36,
  width: 'initial',
  borderRadius: 10,
});

export const Buttons = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 12,
  marginTop: 10,

  ...(isMobile() ? {
    marginLeft: -32,
  } : {
  }),
});

export const ToastContainer = styled(ToastContainerBase)<{ appTheme: Theme }>(({ appTheme: theme }) => ({
  width: 'auto',
  height: 'auto',
  left: 16,
  top: Size.NAVBAR_HEIGHT + 14,

  [MediaBreakPoint.WIDTH_960_OR_HEIGHT_760]: {
    left: 0,
    top: Size.NAVBAR_HEIGHT_960,
  },

  ...(isMobile() ? {
    top: 'initial',
    padding: 16,
    bottom: Size.MOBILE_CONTROLPANEL_HEIGHT + Size.MOBILE_CONTROLPANEL_PADDING * 2,
    [MediaBreakPoint.WIDTH_960_OR_HEIGHT_760]: {
      left: 0,
      top: 'initial',
    },
  } : {
  }),

  '.Toastify__toast--default, .Toastify__toast--info': {
    backgroundColor: `${theme.colors.accent01} !important`,
    borderColor: `${theme.colors.accent04} !important`,
    backgroundImage: `url(${InfoIcon}) !important`,
  },

  '.Toastify__toast--warning': {
    backgroundColor: `${theme.colors.warning01} !important`,
    borderColor: `${theme.colors.warningBase} !important`,
    backgroundImage: `url(${WarningIcon}) !important`,
  },

  '.Toastify__toast--error': {
    backgroundColor: `${theme.colors.error01} !important`,
    borderColor: `${theme.colors.errorBase} !important`,
    backgroundImage: `url(${ErrorIcon}) !important`,
  },

  '.Toastify__toast': {
    margin: 0,
    padding: 0,
    minHeight: 0,
    borderRadius: 8,
    boxShadow: 'none',
    border: 'solid 1px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '18px 18px',
    color: theme.colors.primary,
    cursor: 'default',
    userSelect: 'none',
  },

  '&& .Toastify__toast-body': {
    ...(isMobile() ? {
      padding: '16px 16px 16px 48px',
    } : {
      padding: '16px 10px 16px 48px',
    }),
  },

  '.Toastify__close-button': {
    ...(isMobile() ? {
      position: 'absolute',
      top: 20,
      right: 20,
    } : {
      margin: 5,
    }),
  },
}));
