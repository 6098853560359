import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { IconButton } from 'ui-kit';
import { DownMiniArrowIcon, UpMiniArrowIcon } from 'ui-kit/assets/icons';

import { SortOrderRoot } from './SortOrderButton.styled';

interface SortOrderButtonProps {
  onClick(order: 'asc' | 'desc'): void,
  disabled: boolean,
}

const SortOrderButton: FunctionComponent<SortOrderButtonProps> = observer(({ onClick, disabled }) => {
  const { t } = useTranslation();
  const [order, setOrder] = useState('asc');

  const handleClick = () => {
    const newValue = order === 'asc' ? 'desc' : 'asc';
    setOrder(newValue);
    onClick(newValue);
  };

  const buttonText = t('events.startTime');
  const icon = order === 'asc' ? <DownMiniArrowIcon /> : <UpMiniArrowIcon />;

  return (
    <SortOrderRoot order={order} disabled={disabled}>
      <IconButton
        subVariant="link"
        onClick={handleClick}
        text={buttonText}
        disabled={disabled}
      />
      {icon}
    </SortOrderRoot>
  );
});

export default SortOrderButton;
