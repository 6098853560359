import { RoomRole } from 'services/MoodHoodApiClient/types';
import { RoomType } from 'types/common';

import webinarPermissions from './webinarPermissions';
import conferencePermissions from './conferencePermissions';
import { RoomRolePermissions } from './types';

class RoomPermissions {
  private _role: RoomRole;

  private _permissions: RoomRolePermissions;

  constructor(role: RoomRole, roomType: RoomType) {
    this._role = role;
    this._permissions = roomType === RoomType.Webinar ? webinarPermissions[role] : conferencePermissions[role];
  }

  get permimissons() {
    return this._permissions;
  }

  get role() {
    return this._role;
  }

  get isSpeaker() {
    return this.role === RoomRole.Speaker;
  }

  get canUseScreenShare() {
    return this.permimissons.canUseScreenShare;
  }

  get canUseRoomRecording() {
    return this.permimissons.canUseRoomRecording;
  }

  get canFinishCallForAll() {
    return this.permimissons.canFinishCallForAll;
  }

  get canDeleteMessageInChat() {
    return this.permimissons.canDeleteMessageInChat;
  }

  get canBanUsersInChat() {
    return this.permimissons.canBanUsersInChat;
  }

  get canPutUsersOutOfScreen() {
    return this.permimissons.canPutUsersOutOfScreen;
  }

  get canUseAnnouncements() {
    return this.permimissons.canUseAnnouncements;
  }

  get canUsePolls() {
    return this.permimissons.canUsePolls;
  }

  get canDisableParticapansMic() {
    return this.permimissons.canDisableParticapansMic;
  }

  get canUseSpacesList() {
    return this.permimissons.canUseSpacesList;
  }

  get canUseSpaceRoomsList() {
    return this.permimissons.canUseSpaceRoomsList;
  }

  get canResetReactionsCounters() {
    return this.permimissons.canResetReactionsCounters;
  }

  get canRaiseHand() {
    return this.permimissons.canRaiseHand;
  }

  get canUseRoomActivity() {
    return this.permimissons.canUseRoomActivity;
  }

  get canBanUsersInRoom() {
    return this.permimissons.canBanUsersInRoom;
  }
}

export default RoomPermissions;
