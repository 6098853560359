import React, {
  FunctionComponent, useEffect, useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@emotion/react';
import {
  FigmaTypography, LinearProgress, Flex, IconButton,
} from 'ui-kit';
import useStores from 'stores/root';

import {
  DesktopAppUpdaterRoot,
  Content,
  ProgressContainer,
  ProgressTip,
  ErrorText,
  SupportButton,
} from './index.styled';

const DesktopAppUpdater: FunctionComponent = observer(({ children }) => {
  const [progress, setProgress] = useState<number | undefined>();
  const [hasError, setHasError] = useState(false);
  const [updateCheckComplete, setUpdateCheckComplete] = useState(false);
  const { t } = useTranslation();
  const { rootStore: { carrotQuest } } = useStores();
  const theme = useTheme();

  const checkForUpdates = () => {
    window.electronApi?.checkForUpdates({
      onDownloadProgress: (progressPercent: number) => {
        setProgress(Math.round(progressPercent));
      },

      onError: () => {
        setHasError(true);
      },

      onUpdateNotAvailable: () => {
        setUpdateCheckComplete(true);
      },
    });
  };

  const handleWriteToSupport = () => {
    carrotQuest.openChat();
  };

  const handleTryCheckUpdates = () => {
    setProgress(0);
    setHasError(false);
    checkForUpdates();
  };

  useEffect(() => {
    checkForUpdates();
  }, []);

  if (!process.env.REACT_APP_FOR_ELECTRON || updateCheckComplete) {
    return <>{children}</>;
  }

  return (
    <DesktopAppUpdaterRoot>
      <Content flexDirection="column" gap={16}>
        <theme.LogoMax />
        <FigmaTypography size="h2">
          {t(progress === undefined ? 'electron.checkingUpdates' : 'electron.downloadingUpdates')}
        </FigmaTypography>
        <ProgressContainer flexDirection="column" gap={8}>
          <FigmaTypography size="label-text-bold">
            {`${progress || 0}%`}
          </FigmaTypography>
          <LinearProgress value={progress || 0} color={hasError ? 'error' : 'success'} />
          {hasError && (
            <ErrorText hasError={hasError}>
              {t('electron.checkError')}
            </ErrorText>
          )}
        </ProgressContainer>
        <ProgressTip>
          <FigmaTypography size="main-text-medium">
            {
              hasError ? (
                <Flex flexDirection="column" gap={4}>
                  {t('electron.errorTip')}
                  <ul>
                    {/* eslint-disable-next-line react/no-array-index-key */}
                    {Array.from(Array(4)).map((_, index) => (<li key={index}>{t(`electron.errorTip${index}`)}</li>)) }
                  </ul>
                </Flex>
              ) : t('electron.checkTip')
            }
          </FigmaTypography>
        </ProgressTip>
        { hasError && (
          <Flex gap={8}>
            <IconButton
              id="update-app-support"
              text={t('electron.writeToSupport')}
              onClick={handleWriteToSupport}
            />
            <IconButton
              id="update-app-retry"
              text={t('electron.retry')}
              variant="primary"
              onClick={handleTryCheckUpdates}
            />
          </Flex>
        )}
      </Content>
      <SupportButton />
    </DesktopAppUpdaterRoot>
  );
});

export default DesktopAppUpdater;
