import styled from '@emotion/styled';

import FigmaTypography from '../FigmaTypography';

const Description = styled(FigmaTypography)<{ variant?: 'default' | 'success' | 'warning' | 'clear' }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 4px 8px;
  border-radius: 5px;
  color: ${({ theme, variant = 'default' }) => {
    switch (variant) {
      case 'success':
        return theme.colors.success03;
      case 'warning':
        return theme.colors.warning03;
      case 'clear':
      default:
        return theme.colors.secondaryBase;
    }
  }};
  background-color: ${({ theme, variant = 'default' }) => {
    switch (variant) {
      case 'clear':
        return undefined;
      case 'success':
        return theme.colors.success01;
      case 'warning':
        return theme.colors.warning01;
      default:
        return theme.colors.secondary01;
    }
  }};
`;

Description.defaultProps = {
  as: 'div',
  size: 'main-text-bold',
};

export default Description;
