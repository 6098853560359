import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';
import { Link, useLocation } from 'react-router-dom';

import {
  ButtonSelect,
  FlexCenterSpaceBetween,
  FullPageLoader,
  IconButton,
} from 'ui-kit';
import useStores from 'stores/root';
import { observer } from 'mobx-react-lite';

import {
  Content,
  FormHolder,
  Text,
  Logo,
  Wrapper,
} from './index.styled';

const PageDesktop: FunctionComponent = observer(({ children }) => {
  const {
    authStore,
    userStore,
  } = useStores();

  const location = useLocation();
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <Wrapper>
      {(!authStore.isAuthenticated || (authStore.isUser && !userStore.isUserInfoFetched)) && (
        <FullPageLoader />
      )}
      <Content>
        <FormHolder>
          <FlexCenterSpaceBetween style={{ width: '100%' }}>
            <Text>
              {t('auth.welcome')}
            </Text>
            <Logo isClickable={false} brandOnly />
          </FlexCenterSpaceBetween>
          {location.pathname !== '/auth/signup/step2' && (
            <ButtonSelect style={{ width: '100%', marginTop: 24 }} variant="space-equal">
              <IconButton
                style={{ textDecoration: 'none' }}
                id="button-select-reg"
                text={t('common.registration')}
                isSelected={location.pathname === '/auth/signup'}
                variant={location.pathname === '/auth/signup' ? undefined : 'clear'}
                as={Link}
                to={`/auth/signup${window.location.search}`}
                size="small"
              />
              <IconButton
                style={{ textDecoration: 'none' }}
                id="button-select-login"
                text={t('auth.signIn')}
                isSelected={location.pathname === '/auth/signin'}
                variant={location.pathname === '/auth/signin' ? undefined : 'clear'}
                as={Link}
                to={`/auth/signin${window.location.search}`}
                size="small"
              />
            </ButtonSelect>
          )}
          <div
            style={{
              borderTop: `1px solid ${theme.colors.secondary02}`,
              margin: '24px 0',
              width: '100%',
            }}
          />
          {children}
        </FormHolder>
      </Content>
    </Wrapper>
  );
});

export default PageDesktop;
