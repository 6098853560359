import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  FunctionComponent,
  useMemo,
} from 'react';
import { CSSTransition } from 'react-transition-group';

import { CloseMiniIcon, LeftArrowIcon } from 'ui-kit/assets/icons';

import {
  DialogFromBottomRoot,
  Header,
  SwipeIndicator,
  Cover,
  Content,
  Title,
} from './index.styled';
import { DialogProps, DialogCloseReason } from './types';
import Portal from '../Portal';
import IconButton from '../IconButton';
import useSwipeable from './useSwipeable';

const CSSTransitionPropsUpDown = {
  timeout: 500,
  classNames: {
    enter: 'animate__animated animate__faster',
    enterActive: 'animate__fadeInUp',
    exit: 'animate__animated animate__faster',
    exitActive: 'animate__fadeOutDown',
  },
};

interface ChildDialog {
  content: React.ReactNode,
  title: React.ReactNode,
  onClose?(reason: DialogCloseReason): void,
}

type ContentSetter = React.Dispatch<React.SetStateAction<ChildDialog[]>>;
interface DialogContextValue {
  setRootDialogContent?: ContentSetter,
}

const DialogContext = React.createContext<DialogContextValue>({});

const DialogFromBottom: FunctionComponent<DialogProps> = ({
  children,
  fixedPosition = false,
  canClose = true,
  isVisible = false,
  closeOnClick = false,
  showSwipeIndicator = false,
  onClose,
  className,
  title,
}) => {
  const [isOpenAnimation, setIsOpenAnimation] = useState(false);
  const [childDialogs, setChildDialogs] = useState<ChildDialog[]>([]);
  const ref = useRef<HTMLDivElement>(null);
  const { setRootDialogContent } = React.useContext(DialogContext);

  const handleClose = useCallback(() => {
    if (canClose) {
      onClose?.(DialogCloseReason.ClickOutside);
    }

    if (setRootDialogContent) {
      setRootDialogContent([]);
    } else {
      setChildDialogs([]);
    }
  }, [onClose, canClose]);

  const handleCloseAnimation = useCallback(() => {
    setIsOpenAnimation(false);
  }, [canClose]);

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    if (closeOnClick) {
      onClose?.(DialogCloseReason.ClickInside);
    }
  }, [closeOnClick, onClose]);

  const handleBack = (e: React.MouseEvent) => {
    e.stopPropagation();
    const lastDialog = childDialogs.pop();
    if (lastDialog) {
      lastDialog.onClose?.(DialogCloseReason.CloseButton);
      /* to avoid selected menu item color transition */
      setTimeout(() => {
        setChildDialogs([...childDialogs]);
      }, 0);
    }
  };

  const swipeableHandlers = useSwipeable({ disabled: !showSwipeIndicator, onClose: handleCloseAnimation });

  useEffect(() => {
    if (fixedPosition && ref.current) {
      const content = ref.current;
      const { top } = content.getBoundingClientRect();
      Object.assign(content.style, { position: 'absolute', top: `${top}px` });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsOpenAnimation(isVisible);
    }, 0);

    if (isVisible && setRootDialogContent) {
      setRootDialogContent((prev) => [...prev, {
        content: children,
        title,
        onClose,
      }]);
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && setRootDialogContent) {
      setRootDialogContent((prev) => {
        prev.pop();
        return [...prev, {
          content: children,
          title,
          onClose,
        }];
      });
    }
  }, [children, isVisible, setRootDialogContent]);

  const memSetContent = useMemo(() => ({ setRootDialogContent: setChildDialogs, title }), []);

  if (setRootDialogContent) {
    return null;
  }

  const childDialog = childDialogs[childDialogs.length - 1];
  const toRender = (
    <Portal>
      {isVisible && <Cover onClick={canClose ? handleCloseAnimation : undefined} />}
      <CSSTransition
        in={isOpenAnimation}
        mountOnEnter
        unmountOnExit
        {...CSSTransitionPropsUpDown}
        onExited={handleClose}
      >
        <DialogFromBottomRoot className={className} onClick={handleClick} {...swipeableHandlers} ref={ref}>
          {showSwipeIndicator && <SwipeIndicator /> }
          {title && (
            <Header showDivider={showSwipeIndicator}>
              {childDialog && (
                <IconButton
                  id="back-dialog"
                  icon={<LeftArrowIcon />}
                  subVariant="clear"
                  onClick={handleBack}
                />
              )}
              <Title size="main-text-bold">{childDialog?.title || title}</Title>
              <IconButton
                id="close-dialog"
                icon={<CloseMiniIcon />}
                subVariant="clear"
                onClick={handleCloseAnimation}
              />
            </Header>
          )}
          {/* should keep children and subdialogs children, to keep submenu owner alive */}
          <Content isVisible={Boolean(!childDialog)}>
            {children}
          </Content>
          {childDialogs.map((child, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Content isVisible={index === childDialogs.length - 1} key={index}>{child.content}</Content>
          ))}
        </DialogFromBottomRoot>
      </CSSTransition>
    </Portal>
  );

  if (!setRootDialogContent) {
    return (
      <DialogContext.Provider value={memSetContent}>
        {toRender}
      </DialogContext.Provider>
    );
  }

  return toRender;
};

export default DialogFromBottom;
