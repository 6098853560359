import styled from '@emotion/styled';

import { getObjectTextStyle } from 'ui-kit/theme';

import CheckedDisabled from './assets/checked-disabled.svg';
import Checked from './assets/checked.svg';
import { LabelPosition } from './index';

export const CheckBoxInput = styled.input<{
  disabled?: boolean,
  inverted?: boolean,
}>(({
  theme: { colors },
  disabled,
  inverted,
}) => ({
  appearance: 'none',
  display: 'block',
  width: 24,
  height: 24,
  border: `2px solid ${colors.secondary03}`,
  borderRadius: 5,
  '&:hover:not([disabled])': {
    backgroundColor: colors.secondary02,
  },

  '&:focus': {
    outline: 'none',
  },

  '&[disabled]': {
    backgroundColor: colors.secondary02,
    border: 'none',
  },

  '&:checked': {
    backgroundColor: colors.accentBase,
    border: inverted ? `2px solid ${colors.secondary03}` : 'none',
    '&:hover:not([disabled])': {
      backgroundColor: colors.accent03,
      '&:before': {
        background: `url(${CheckedDisabled}) no-repeat`,
      },
    },
    '&[disabled]': {
      backgroundColor: colors.secondaryBase,
      border: 'none',
    },
  },

  '&:checked:before': {
    marginTop: inverted ? -2 : 0,
    marginLeft: inverted ? -2 : 0,
    content: '""',
    display: 'block',
    width: 20,
    height: 20,
    background: `url(${disabled ? CheckedDisabled : Checked}) no-repeat`,
  },

  flexShrink: 0,
}));

export const CheckboxRoot = styled.label<{ labelPosition: LabelPosition }>(({
  labelPosition,
  theme: { colors },
}) => ({
  ...getObjectTextStyle('main-text-medium'),
  color: colors.secondaryBase,
  position: 'relative',
  display: 'flex',
  flexDirection: labelPosition === 'left' ? 'row-reverse' : 'row',
  alignItems: 'flex-start',
  width: 'max-content',
  userSelect: 'none',
  '& > input:first-of-type': {
    margin: labelPosition === 'left' ? '0 0px 0 8px' : '0 8px 0 0px',
  },
}));

export const LoaderLayout = styled.label({
  marginRight: 16,
});
