import { action, makeAutoObservable, observable } from 'mobx';
import PeerStore from 'stores/peer';
import { JoinApproval, OutboundConnectionQuality } from 'types/stores/peerAppData';
import { PeerAppData } from 'types/stores/peer';
import { getClientUniqueId } from 'helpers/common';

class ParticipantAppDataStore {
  @observable peer?: PeerStore;

  @observable userId?: string;

  @observable roomId?: string;

  @observable spaceId?: string;

  @observable isModerator?: boolean;

  @observable isNoCam?: boolean;

  @observable isNoMic?: boolean;

  @observable detectDevicesError?: string;

  @observable outboundConnectionQuality?: OutboundConnectionQuality;

  @observable isHandRaised?: boolean;

  @observable handRaisedAt?: number | null;

  @observable joinApproval?: JoinApproval;

  clientUniqueId: string = getClientUniqueId();

  constructor() {
    makeAutoObservable(this);
  }

  @action setAppDataAndPeer(peer: PeerStore): void {
    this.peer = peer;
    const { appData } = peer;
    this.userId = appData.userId;
    this.roomId = appData.roomId;
    this.spaceId = appData.spaceId;
    this.isModerator = appData.isModerator;
    this.isNoCam = appData.isNoCam;
    this.isNoMic = appData.isNoMic;
    this.detectDevicesError = appData.detectDevicesError;
    this.outboundConnectionQuality = appData.outboundConnectionQuality;
    this.isHandRaised = appData.isHandRaised;
    this.handRaisedAt = appData.handRaisedAt;
    this.joinApproval = appData.joinApproval;
  }

  getCurrentAppData(): PeerAppData {
    return {
      userId: this.userId,
      roomId: this.roomId,
      spaceId: this.spaceId,
      isModerator: this.isModerator,
      isNoCam: this.isNoCam,
      isNoMic: this.isNoMic,
      detectDevicesError: this.detectDevicesError,
      outboundConnectionQuality: this.outboundConnectionQuality,
      isHandRaised: this.isHandRaised,
      handRaisedAt: this.handRaisedAt,
      clientUniqueId: this.clientUniqueId,
      joinApproval: this.joinApproval,
    };
  }

  @action toggleRaiseHand(): void {
    this.handRaisedAt = this.handRaisedAt ? null : Date.now();
    this.peer?.appData.setIsHandRaised(this.handRaisedAt);
  }

  @action setJoinApproval(value: JoinApproval): void {
    this.joinApproval = value;
  }
}

export default ParticipantAppDataStore;
