import React, {
  FunctionComponent, useEffect, useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

import type { LocationDescriptorObject } from 'history';

import useStores from 'stores/root';
import ImageCropper from 'components/Dialogs/ImageCropper';
import {
  hasUrlParam,
  redirectTo,
  isAuthPathName,
  getUrlParam,
} from 'helpers/url';
import usePlansPage from 'hooks/usePlansPage';
import { SignUpStep2OnlyCreatedAfterDate } from 'constants/common';
import { isValidUserName } from 'helpers/common';
import AfterRegisterDialogWrapper from 'components/AfterRegisterDialog/AfterRegisterDialogWrapper';
import { Livedigital } from 'components/Optional';

const Auth: FunctionComponent = observer(({ children }) => {
  const {
    authStore,
    userStore,
    spaceStore,
  } = useStores();
  const { redirectToPlansPage } = usePlansPage();

  useEffect(() => {
    if (!spaceStore.space) {
      return;
    }

    if (hasUrlParam('showPrice') && spaceStore.isSpaceOwner) {
      redirectToPlansPage();
    }
  }, [spaceStore.space]);

  const [redirect, setRedirect] = useState<LocationDescriptorObject>();

  useEffect(() => {
    const exchangeAuthCodeIfExists = async () => {
      const code = getUrlParam('code');
      if (code) {
        const url = new URL(window.location.href);
        url.searchParams.delete('code');
        window.history.replaceState(null, '', url.toString());

        if (authStore.isUser) {
          await authStore.logout();
        }

        await authStore.exchangeAuthCode(code);
      }
    };

    exchangeAuthCodeIfExists();
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (hasUrlParam('code')) {
      return;
    }

    if (isAuthPathName()) {
      return;
    }

    if (!authStore.isUser) {
      return;
    }

    if (!userStore.userInfo) {
      return;
    }

    if (isValidUserName(userStore.userInfo.username, userStore.userInfo.email)) {
      return;
    }

    if (userStore.userInfo && userStore.userInfo.createdAt <= SignUpStep2OnlyCreatedAfterDate) {
      return;
    }

    const to = encodeURIComponent(location.pathname + location.search);
    setRedirect({ pathname: '/auth/signup/step2', search: `to=${to}` });
  }, [location.pathname, authStore.isUser, userStore.isUserInfoFetched]);

  useEffect(() => {
    if (redirect) {
      const to = `${redirect.pathname || ''}?${redirect.search || ''}`;
      redirectTo(to);
    }
  }, [redirect]);

  return (
    <>
      {children}
      <ImageCropper />
      <Livedigital>
        <AfterRegisterDialogWrapper />
      </Livedigital>
    </>
  );
});

export default Auth;
