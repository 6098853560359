import React, {
  FunctionComponent,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import 'react-image-crop/dist/ReactCrop.css';

import { Dialog, IconButton } from 'ui-kit';

import {
  Buttons,
  Content,
} from './Layout';

interface CropperDialogProps {
  onClose?(): void;
  onCancel(): void;
  onSave(): void;
  saveButtonDisabled: boolean;
  title?: string;
}

const CropperDialog: FunctionComponent<CropperDialogProps> = observer(({
  onClose, onCancel, onSave, saveButtonDisabled, children, title,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog onClose={onClose} fixedPosition title={title}>
      <Content>
        {children}
        <Buttons>
          <IconButton id="change-avatar" variant="primary" onClick={onSave} disabled={saveButtonDisabled}>
            {t('common.save')}
          </IconButton>
          <IconButton id="cancel-change-avatar" onClick={onCancel}>
            {t('common.cancel')}
          </IconButton>
        </Buttons>
      </Content>
    </Dialog>
  );
});

export default CropperDialog;
