import { makeTheme } from './helpers';
import { ThemeType } from './types';

export const FigmaColors = {
  /* Main */
  bg: '#1D1D2C',
  primary: '#FFFFFF',
  contrast: '#FFFFFF',
  bgContrast: '#060A2DCC',
  primaryTransparency: '#1d1d2cb3',
  /* Secondary */
  secondaryBase: '#F1F1F4',
  secondary01: '#343643',
  secondary02: '#525566',
  secondary03: '#7F8290',
  /* Accent */
  accentBase: '#1D51FE',
  accent01: '#1A2657',
  accent02: '#6185FE',
  accent03: '#A5B9FF',
  accent04: '#D2DCFF',
  accent05: '#E8EEFF',
  /* Tertiary */
  tertiaryBase: '#1399FA',
  tertiary01: '#1B3655',
  /* Error */
  errorBase: '#E11D08',
  error01: '#5F0A01',
  error02: '#921003',
  error03: '#F07D70',
  error04: '#FCE5E2',
  /* Success */
  successBase: '#06AA2A',
  success01: '#1F5D2F',
  success02: '#9BDDAA',
  success03: '#CDEED4',
  /* Warning */
  warningBase: '#FC9E22',
  warning01: '#765128',
  warning02: '#FFD495',
  warning03: '#FFF4E5',
  /* Purple */
  purpleBase: '#C0437F',
  purprle01: '#8C315D',
  purprle02: '#DFA1BF',
  purprle03: '#F2D9E5',
  /* Reactions */
  'reaction-lilac': '#3431D0',
  'reaction-lilac-light-01': '#ABA9EB',
  'reaction-lilac-light-02': '#EAEAFA',
  'reaction-orange': '#FF9900',
  'reaction-orange-light-01': '#FFD495',
  'reaction-orange-light-02': '#FFF4E5',
  'reaction-pink': '#FF005C',
  'reaction-pink-light-02': '#FFE5EE',
  'reaction-red': '#F32A2A',
  'reaction-red-light-01': '#FE9FA9',
  'reaction-red-light-02': '#FDE9E9',
  /* Avatar */
  avatarBurgundy: '#991131',
  avatarRed: '#D9042B',
  avatarDarkPink: '#C0437F',
  avatarPink: '#D792D8',
  avatarGold: '#E2B978',
  avatarNavyGreen: '#588C8C',
  avatarNavyBlue: '#8875BA',
  avatarPurple: '#871199',
};

export const DarkTheme = makeTheme(ThemeType.dark, FigmaColors);
