/* eslint-disable no-bitwise */
import { eventBus, subscribe } from 'mobx-event-bus2';

import { AppState, AppEvent } from 'types/common';

import { HotKey, HotkeyCallback, KeyModifier } from './types';

export class HotkeyManager {
  private appState: AppState | null = null;

  private callbacks: Map<string, { callback: HotkeyCallback, appState: AppState }[]> = new Map();

  private bindedKeyHandler: (e: KeyboardEvent) => void;

  private bindedSpaceKeyFilter: (e: KeyboardEvent) => void;

  private hotkeysDisabled = false;

  private latestKeyDownModifiers = 0;

  /* everything below is a workaround against accidental hotkey triggering in chat message box */
  private bindedChatFocusOutHandler: (e: FocusEvent) => void;

  private bindedChatFocusInHandler: (e: FocusEvent) => void;

  private chatElement: HTMLElement | null = null;

  private hasChatInputBoxFocus = false;

  constructor() {
    this.bindedKeyHandler = (e: KeyboardEvent) => { this.handleOnKeyPress(e); };
    this.bindedSpaceKeyFilter = (e: KeyboardEvent) => { this.handleSpaceKey(e); };
    this.bindedChatFocusOutHandler = (e: FocusEvent) => { this.handleChatFocusOut(e); };
    this.bindedChatFocusInHandler = (e: FocusEvent) => { this.handleChatFocusIn(e); };
    window.addEventListener('keyup', this.bindedKeyHandler);
    window.addEventListener('keydown', this.bindedKeyHandler);
    window.addEventListener('keydown', this.bindedSpaceKeyFilter, { capture: true });
    eventBus.register(this);
  }

  // TODO: надо его когда-то вызывать наверное, но когда? но он один глобально на все приложение
  dispose() {
    if (this.chatElement) {
      this.chatElement.removeEventListener('focusout', this.bindedChatFocusOutHandler);
      this.chatElement.removeEventListener('focusin', this.bindedChatFocusOutHandler);
    }

    window.removeEventListener('keyup', this.bindedKeyHandler);
    window.removeEventListener('keydown', this.bindedKeyHandler);
    window.removeEventListener('keydown', this.bindedSpaceKeyFilter, { capture: true });
    this.callbacks.clear();
  }

  registerHotkey(callback: HotkeyCallback, hotKey: HotKey, appState: AppState) {
    const hotKeyHash = this.getHotkeyHash(hotKey);
    const handlers = this.callbacks.get(hotKeyHash) || [];

    if (handlers.some((handler) => handler.callback === callback)) {
      return;
    }

    handlers.push({ callback, appState });
    this.callbacks.set(hotKeyHash, handlers);
  }

  unregisterHotkey(callback: HotkeyCallback) {
    [...this.callbacks.values()].forEach((handlers) => {
      const filteredHandlers = handlers.filter((handler) => handler.callback !== callback);
      if (filteredHandlers.length === handlers.length) {
        return;
      }

      handlers.splice(0, handlers.length, ...filteredHandlers);
    });
  }

  disableHotkeys() {
    this.hotkeysDisabled = true;
  }

  enableHotkeys() {
    this.hotkeysDisabled = false;
  }

  @subscribe(AppEvent.ChangeAppState)
  private handleAppStateChange({ payload: appState }: { payload: AppState }) {
    this.appState = appState;
  }

  @subscribe(AppState.ChatInitialized)
  private handleChatInitialize({ payload: chatElement }: { payload: HTMLElement }) {
    if (!chatElement) {
      return;
    }

    /* setting callbacks on whole chat element, since chat message box might not be present yet */
    if (this.chatElement) {
      this.chatElement.removeEventListener('focusout', this.bindedChatFocusOutHandler);
      this.chatElement.removeEventListener('focusin', this.bindedChatFocusInHandler);
    }

    this.chatElement = chatElement;
    this.chatElement.addEventListener('focusout', this.bindedChatFocusOutHandler);
    this.chatElement.addEventListener('focusin', this.bindedChatFocusInHandler);
  }

  private handleSpaceKey(e: KeyboardEvent) {
    const elType = (e.target as Element).tagName.toLocaleLowerCase();
    if (e.code === 'Space' && elType === 'button' && this.appState === AppState.RoomJoined) {
      e.preventDefault();
    }
  }

  private handleOnKeyPress(evt: KeyboardEvent) {
    if (!evt || this.hotkeysDisabled) {
      return;
    }

    const { target } = evt;
    const ignoreElements = ['input', 'textarea'];

    if (ignoreElements.includes((target as Element).tagName.toLocaleLowerCase())) {
      return;
    }

    if (!this.isModifierKey(evt) && evt.type === 'keydown' && !this.latestKeyDownModifiers) {
      /* we should keep key modifiers on keydown to look at them in keyup,
       in case modifier keys were released before actual key got keyup */
      this.latestKeyDownModifiers = this.getKeyModifiers(evt);
    }

    const hotKeyHash = this.getKeyboardEventHash(evt);
    const handlers = this.callbacks.get(hotKeyHash);

    handlers?.forEach((handler) => {
      if (handler.appState === this.appState) {
        handler.callback(evt);
      }
    });

    if (!this.isModifierKey(evt) && evt.type === 'keyup') {
      this.latestKeyDownModifiers = 0;
    }
  }

  private handleChatFocusOut(evt: FocusEvent) {
    const target = evt.target as HTMLTextAreaElement;
    if (!target) {
      return;
    }

    if (target.dataset.qa === 'input-message' && target.disabled && this.hasChatInputBoxFocus) {
      this.disableHotkeys();
    }
  }

  private handleChatFocusIn(evt: FocusEvent) {
    const target = evt.target as HTMLElement;
    if (!target) {
      return;
    }

    this.hasChatInputBoxFocus = target.dataset.qa === 'input-message';
    if (this.hasChatInputBoxFocus) {
      this.enableHotkeys();
    }
  }

  private getKeyModifiers(evt: KeyboardEvent) {
    let modifiers = 0;

    if (evt.ctrlKey) {
      modifiers |= KeyModifier.Ctrl;
    }

    if (evt.altKey) {
      modifiers |= KeyModifier.Alt;
    }

    if (evt.shiftKey) {
      modifiers |= KeyModifier.Shift;
    }

    if (evt.metaKey) {
      modifiers |= KeyModifier.Meta;
    }

    return modifiers;
  }

  private getHotkeyHash(hotKey: HotKey) {
    return hotKey.key + (hotKey.keyPress || 'keyup') + String(hotKey.modifiers || '');
  }

  private getKeyboardEventHash(evt: KeyboardEvent) {
    const key = evt.code;
    const keyPress = evt.type;
    const modifiers = evt.type === 'keyup' ? this.latestKeyDownModifiers : this.getKeyModifiers(evt);

    return key + keyPress + String(modifiers || '');
  }

  private isModifierKey(evt: KeyboardEvent) {
    return ['control', 'alt', 'shift', 'meta'].includes(evt.key.toLocaleLowerCase());
  }
}

export default new HotkeyManager();
