import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';

import { Font, Size } from 'ui-kit/theme';
import InfoCircle from 'ui-kit/assets/icons/snackbar/info-circle-blue.svg';

interface ToastProps {
  hideInfoIcon?: boolean,
  minWidth?: string,
  hidden?: boolean,
}

export const StyledToastContainerDesktop = styled(ToastContainer)<ToastProps>`
  width: auto;
  height: auto;
  opacity: ${({ hidden }) => (hidden ? 0 : 1)};
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  pointer-events: ${({ hidden }) => (hidden ? 'none' : 'auto')};

  .Toastify__toast {
    min-height: 80px;
    min-width: ${({ minWidth }) => (minWidth || '742px')};
    border-radius: 8px;
    color: var(--ld-primary);
    background: var(--ld-bg);
    padding: 0;
  }

  .Toastify__toast-body {
    color: var(--ld-accentBase);
    font-family: ${Font.BRAND};
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    padding: 16px 16px 16px 0;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .Toastify__toast--default,
  .Toastify__toast--info {
    padding-left: 16px;
  }

  .Toastify__toast--success,
  .Toastify__toast--warning,
  .Toastify__toast--error {
    padding-left: 52px;
    background-repeat: no-repeat;
    background-position: 18px center;
  }

  .Toastify__toast--success,
  .Toastify__toast--error,
  .Toastify__toast--warning {
    background-image: url(${InfoCircle});
  }
`;

export const StyledToastContainerMobile = styled(ToastContainer)<ToastProps>`
  width: 100%;
  height: auto;
  top: ${Size.MOBILE_NAVBAR_HEIGHT + Size.MOBILE_NAVBAR_PADDING * 2 + Size.GRID_PADDING};
  padding: 0 7px;

  .Toastify__toast {
    border-radius: 8px;
    background: var(--ld-accentBase);
    padding: 12px 13px;

    .Toastify__toast-body {
      font-family: ${Font.BRAND};
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      color: var(--ld-contrast);
      padding: 0;
      margin: 0;
    }

    .Toastify__close-button {
      color: var(--ld-contrast);
      opacity: 1;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
