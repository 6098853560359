import styled from '@emotion/styled';

export const Tab = styled.div<{ header: string | JSX.Element }>({
});

const Layout = styled.div<{ disabled?: boolean }>(({ disabled }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontSize: 14,
  ...(disabled && {
    pointerEvents: 'none',
  }),
}));

export default Layout;
