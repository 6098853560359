import { ComponentType, lazy } from 'react';
import { getSessionStorageItem, setSessionStorageItem, getSessionStorage } from 'helpers/sessionStorage';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LazyComponent = Promise<{ default: ComponentType<any>; }>;

const tryToReloadPage = () => {
  const storageValue = 'true';
  const storageKey = 'ld_chunk_page_reloaded';
  if (!getSessionStorage()) {
    return null;
  }

  const alreadyTriedToReload = getSessionStorageItem(storageKey);
  if (alreadyTriedToReload === storageValue) {
    return null;
  }

  setSessionStorageItem(storageKey, storageValue);
  window.location.reload();
  return true;
};

const retry = (lazyComponent: ()=> LazyComponent, retriesLeft = 3, interval = 1000)
: LazyComponent => new Promise((resolve, reject) => {
  lazyComponent()
    .then(resolve)
    .catch((error) => {
      setTimeout(() => {
        if (retriesLeft > 1) {
          retry(lazyComponent, retriesLeft - 1, interval).then(resolve, reject);
          return;
        }

        if (!tryToReloadPage()) {
          reject(error);
        }
      }, interval);
    });
});

const loadWithRetry = (lazyComponent: ()=> LazyComponent) => lazy(() => retry(lazyComponent));

export default loadWithRetry;
