import React, { FunctionComponent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import isMobile from 'is-mobile';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import useStores from 'stores/root';
import RedirectPage from 'components/RedirectPage';
import { getUrlParam } from 'helpers/url';
import { InputMessage } from 'ui-kit';

import {
  Buttons,
  DesktopTitle,
  Form,
  Input,
  Layout,
  MobileTitle,
  ServerError,
  ShortButton,
} from './index.styled';
import { Errors } from '../../../constants/errors';

interface FormFields {
  newPassword: string;
  confirmPassword: string;
}

const ChangePasswordConfirm: FunctionComponent = () => {
  const confirm = getUrlParam('confirm');

  const { userStore, authStore } = useStores();
  const { t } = useTranslation();
  const history = useHistory();
  const [serverError, setServerError] = useState<string | undefined>();
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [operationInProgress, setOperationInProgress] = useState(false);

  const {
    register, handleSubmit, formState: { errors }, setError,
  } = useForm<FormFields>({
    mode: 'onChange',
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  const getReCaptchaToken = async (): Promise<string> => {
    if (!executeRecaptcha) {
      return '';
    }
    return executeRecaptcha('password_change');
  };

  const onSubmit: SubmitHandler<FormFields> = async ({ newPassword, confirmPassword }) => {
    if (operationInProgress) {
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('confirmPassword', { message: t('userProfile.passwordMismatch') });
      return;
    }

    if (!confirm) {
      return;
    }

    setOperationInProgress(true);

    const { email } = userStore;
    await userStore.finishUpdateUserPassword(newPassword, confirm);

    if (userStore.passwordUpdatingError) {
      setOperationInProgress(false);
      return;
    }

    try {
      if (email) {
        await authStore.logout();
        await authStore.fetchClientAccessToken();
        await authStore.signIn(email, newPassword, getReCaptchaToken);
      }
    } finally {
      setIsPasswordChanged(true);
    }
  };

  const handleOnCancel = () => {
    history.replace('/');
  };

  useEffect(() => {
    if (!userStore.passwordUpdatingError) {
      setServerError(undefined);
      return;
    }

    if (userStore.passwordUpdatingError === Errors.CONFIRMATION_TOKEN_INVALID) {
      setServerError(t('errors.confirmationTokenInvalid.errorText'));
      return;
    }

    setServerError(t('errors.somethingWentWrong'));
  }, [userStore.passwordUpdatingError]);

  if (isPasswordChanged) {
    return (
      <RedirectPage title={t('userProfile.passwordChanged')} />
    );
  }

  const Title = isMobile() ? MobileTitle : DesktopTitle;

  return (
    <Layout>
      <Title>{t('userProfile.changePassword')}</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Input
          id="new-password"
          label={t('userProfile.newPasswordLabel')}
          fullWidth
          type="password"
          autoComplete="new-password"
          disabled={operationInProgress}
          placeholder={t('userProfile.newPasswordPlaceholder')}
          {...register('newPassword', {
            required: { value: true, message: t('userProfile.newPasswordRequired') },
            minLength: { value: 6, message: t('auth.passwordMinLength', { value: 6 }) },
          })}
        >
          <InputMessage>
            {errors.newPassword?.message}
          </InputMessage>
        </Input>
        <Input
          id="password-confirm-password-changing"
          label={t('userProfile.confirmPasswordLabel')}
          fullWidth
          type="password"
          disabled={operationInProgress}
          placeholder={t('userProfile.confirmPasswordPlaceholder')}
          {...register('confirmPassword', {
            required: { value: true, message: t('userProfile.confirmPasswordRequired') },
          })}
        >
          <InputMessage>
            {errors.confirmPassword?.message}
          </InputMessage>
        </Input>
        <Buttons>
          <ShortButton
            id="cancel-change-password"
            variant="primary"
            onClick={handleOnCancel}
            disabled={operationInProgress}
          >
            {t('common.cancel')}
          </ShortButton>
          <ShortButton id="change-password" type="submit" disabled={operationInProgress || !confirm}>
            {t('common.save')}
          </ShortButton>
        </Buttons>
        {Boolean(serverError) && (
          <ServerError>
            <InputMessage>
              {serverError}
            </InputMessage>
          </ServerError>
        )}
      </Form>
    </Layout>
  );
};

export default ChangePasswordConfirm;
