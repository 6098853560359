import {
  useEffect, useState,
} from 'react';

import useTimeout from './useTimeout';

const useDelayedLoader = (
  timeout = 200,
): { isLoaderVisible: boolean, resetLoader: typeof resetLoader, cancelLoader: typeof cancelLoader } => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [startTimer, stopTimer] = useTimeout(() => {
    setIsLoaderVisible(true);
  }, timeout);

  const cancelLoader = () => {
    setIsLoaderVisible(false);
    stopTimer();
  };

  const resetLoader = () => {
    cancelLoader();
    startTimer();
  };

  useEffect(() => {
    /* dont show loader first 'timeout' ms */
    startTimer();
  }, []);

  return { isLoaderVisible, resetLoader, cancelLoader };
};

export default useDelayedLoader;
