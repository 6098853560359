import React, { ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import logger from 'helpers/logger';

interface ErrorBoundaryState {
  hasError?: boolean;
  error?: Error;
}

interface ErrorBoundaryProps {
  errorMessage?: string;
  onError?: () => void;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.handleError(error, errorInfo.componentStack);
  }

  // eslint-disable-next-line class-methods-use-this
  handleError(error: Error, componentStack: string): void {
    logger.error('Failed to render component', { error, componentStack });
  }

  render(): ReactNode {
    const { children, errorMessage, onError } = this.props;
    const fallbackElement = (
      <h3 style={{ padding: '25px 10px', textAlign: 'center' }}>
        {errorMessage ?? 'Cannot display the component'}
      </h3>
    );

    return (
      <Sentry.ErrorBoundary
        fallback={fallbackElement}
        onError={(error, componentStack) => {
          this.handleError(error, componentStack);
          onError?.();
        }}
        beforeCapture={(scope) => {
          const {
            peerId, roomId, participantId, participantName, email,
          } = window.lsd || {};
          scope.setUser({
            peerId,
            roomId,
            participantId,
            participantName,
            email,
          });
        }}
      >
        {children}
      </Sentry.ErrorBoundary>
    );
  }
}

export default ErrorBoundary;
