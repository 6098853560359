import styled from '@emotion/styled';

import { Color, Font } from 'ui-kit/theme';

export const MobileLayout = styled.div`
  text-align: initial;
  font-family: ${Font.BRAND};
  justify-content: flex-start;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
`;

export const MobileTitle = styled.div`
  margin-bottom: 16px;
  color: ${Color.DARK0};
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  font-family: 'PTRootUIWebBold';
`;
