import styled from '@emotion/styled';

export const SortOrderRoot = styled.div<{ order: string, disabled: boolean }>(({ theme, order, disabled }) => ({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: 'auto',
  color: theme.colors.accent04,
  ...(disabled && {
    color: theme.colors.secondary03,
  }),
  ...(order === 'desc' && {
    svg: {
      position: 'relative',
      top: 2,
    },
  }),
}));
