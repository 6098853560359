import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { IconButton } from 'ui-kit';

export const toastBody = {
  margin: 0,
  padding: 0,
};

export const toastClassName = css({ paddingRight: '12px !important' });

export const KeepRaisedButton = styled(IconButton)({
  whiteSpace: 'nowrap',
  height: 'initial',
  '&&': {
    minWidth: 'initial',
  },
});

const Layout = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 21,
});

export default Layout;
