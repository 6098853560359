import React, { VoidFunctionComponent } from 'react';

import PageDesktop from '../../Page/Desktop';
import { SignUpFormStep2 } from '../../Page/Desktop/index.styled';

const SignUpStep2PageDesktop: VoidFunctionComponent = () => (
  <PageDesktop>
    <SignUpFormStep2 />
  </PageDesktop>
);

export default SignUpStep2PageDesktop;
