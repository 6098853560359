import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  FunctionComponent,
} from 'react';
import { eventBus } from 'mobx-event-bus2';
import { observer } from 'mobx-react-lite';
import { Global, css } from '@emotion/react';
import { useParams } from 'react-router-dom';

import Chat from 'components/Chat/ChatWithReload';
import useStores from 'stores/root';
import { isCorrectChatNewWindowOpener } from 'helpers/common';
import useEventStateTracking from 'hooks/room/useEventStateTracking';
import { LANDING_PAGE_URL } from 'constants/common';
import { JoinPlaybackEventResponse } from 'services/MoodHoodApiClient/PlaybackEventsApi.types';
import { EventBusEventToChat } from 'modules/Autowebinar/Autowebinar';
import Autowebinar from 'modules/Autowebinar';

import { MessagesFromChatPopup, MessagesToChatPopup, PostMessage } from '../Room/types';

interface QueryParams {
  eventId?: string;
  spaceId?: string;
  playbackId?: string;
  eventAlias?: string;
}

const ChatEventPopup: FunctionComponent = observer(() => {
  const {
    eventId,
    spaceId,
    playbackId,
    eventAlias,
  } = useParams<QueryParams>();

  const {
    uiStore,
    spaceStore: { space },
    eventStore,
  } = useStores();

  const opener = useMemo(() => {
    if (!eventStore.event?.id || !isCorrectChatNewWindowOpener(eventStore.event.id)) {
      return null;
    }

    return (window.opener as Window);
  }, [eventStore.event?.id]);

  const [isReady, setIsReady] = useState(false);

  const isPreview = Boolean(playbackId);

  useEventStateTracking({
    isChatPopup: true,
    id: eventId || playbackId,
    alias: eventAlias,
    isPreview,
    spaceId,
  });

  const onUnload = useCallback(() => {
    const iid = setInterval(() => {
      if (!eventStore?.id) {
        return;
      }

      if (!isCorrectChatNewWindowOpener(eventStore.id)) {
        clearInterval(iid);
        window.opener?.removeEventListener('unload', onUnload);
        window.close();
      }
    }, 100);
  }, [eventStore.event?.id]);

  const handleMessageToChatPopupWindow = (e: MessageEvent<{ topic: string, payload: unknown }>) => {
    switch (e.data.topic) {
      case MessagesToChatPopup.chatSetEventJoinInfo: {
        eventStore.setJoinResult(e.data.payload as JoinPlaybackEventResponse);
        eventStore.setIsEventJoining(false);
        eventStore.setIsEventJoined(true);
        setIsReady(true);
        break;
      }
      case MessagesToChatPopup.chatSetEventSetTime:
        if (uiStore.isAutowebinarChatReady) {
          Autowebinar.getInstance().setIsChatTimeSetted(true);
          eventBus.post(EventBusEventToChat.chatSetTimeLine, e.data.payload);
        }

        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const id = eventStore?.id;

    if (!id) {
      return () => {};
    }

    if (!opener) {
      window.location.href = LANDING_PAGE_URL;
      return () => {};
    }

    if (eventStore.isEventInfoFetched) {
      opener.addEventListener('unload', onUnload);
      window.addEventListener('message', handleMessageToChatPopupWindow);
    }

    return () => {
      opener.removeEventListener('unload', onUnload);
      window.removeEventListener('message', handleMessageToChatPopupWindow);
    };
  }, [opener, eventStore.isEventInfoFetched]);

  useEffect(() => {
    opener?.postMessage({
      topic: MessagesFromChatPopup.chatPopupWindowIsReady,
      payload: eventStore.isEventInfoFetched && eventStore.isTimelineFetched,
    } as PostMessage);
  }, [eventStore.isEventInfoFetched, eventStore.isTimelineFetched]);

  if (!isReady) {
    return null;
  }

  return (
    <>
      <Global
        styles={css`
          [data-panel-name='chat'] {
            & > div {
              border-radius: 0;
            }
          }
        `}
      />
      <Chat
        /* we need to wait till space info is fetched too */
        isVisible={Boolean(space?.id)}
        style={{
          height: '100dvh',
          width: '100dvw',
        }}
      />
    </>
  );
});

export default ChatEventPopup;
