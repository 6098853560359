/* eslint-disable max-len */
import { AxiosInstance } from 'axios';
import { getAxiosErrorMessage } from 'helpers/errors';
import { apiCall } from 'decorators';
import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';
import i18next from 'i18next';

import {
  ApiResponse,
  StartRecordPayload,
  StopRecordPayload,
  GetCurrentRecordPayload,
  CheckRecordSecret,
  GetRecordsPayload,
  GetUrlForDownloadingPayload,
  PaginableRequest,
  ApiPaginableResponse,
  PaginableResponse,
  SizeRecordsPayload,
  UpdateRecordPayload,
  UpdateRecordResponse,
  RoomRecordCheckSecretResponse,
  RoomRecordPublicationMeta,
} from './types';
import { CloudRecordStatus, RecordPublicationStatus } from '../../modules/RoomRecorder/CloudRoomRecorder';

export interface RoomRecord {
  id: string;
  name: string;
  fileSize?: number;
  roomId: string;
  spaceId: string;
  callId?: string;
  state: CloudRecordStatus;
  createdAt: string;
  startedAt?: string;
  finishedAt?: string;
  playbackEventAlias?: string | null;
  publicationStatus: RecordPublicationStatus;
}

class RecordApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async start({ spaceId, roomId }: StartRecordPayload): Promise<void> {
    await this.api.post(`spaces/${spaceId}/rooms/${roomId}/records/start`);
  }

  async stop({ spaceId, roomId }: StopRecordPayload): Promise<void> {
    await this.api.post(`spaces/${spaceId}/rooms/${roomId}/records/stop`);
  }

  async getCurrentRecord({ spaceId, roomId }: GetCurrentRecordPayload): ApiResponse<RoomRecord> {
    try {
      const { data } = await this.api.get(`spaces/${spaceId}/rooms/${roomId}/records/current`);
      return { data };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async checkSecret(payload: CheckRecordSecret): Promise<RoomRecordCheckSecretResponse> {
    const { data } = await this.api.put<RoomRecordCheckSecretResponse>(
      `spaces/${payload.spaceId}/rooms/${payload.roomId}/records/check-secret`,
      {
        recordId: payload.recordId,
        secret: payload.secret,
      },
    );

    return data;
  }

  async getRecords({
    spaceId,
    roomId,
    dateFrom,
    dateTo,
    offset,
    limit,
    name,
    publicationStatus,
  }: PaginableRequest<GetRecordsPayload>): ApiPaginableResponse<RoomRecord> {
    try {
      const { data } = await this.api.get<PaginableResponse<RoomRecord>>(
        `spaces/${spaceId}/rooms/${roomId}/records/`,
        {
          params: {
            dateFrom,
            dateTo,
            offset,
            limit,
            name,
            publicationStatus,
          },
        },
      );
      return { data };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async getDownloadRecordUrl({ spaceId, roomId, recordId }: GetUrlForDownloadingPayload): Promise<string | undefined> {
    const { data: url } = await this.api.get<string>(`spaces/${spaceId}/rooms/${roomId}/records/download/${recordId}`);
    if (!url) {
      return undefined;
    }

    return url;
  }

  async downloadRecord({ spaceId, roomId, recordId }: GetUrlForDownloadingPayload): Promise<void> {
    const url = await this.getDownloadRecordUrl({ spaceId, roomId, recordId });
    if (!url) {
      return;
    }

    if (process.env.REACT_APP_FOR_ELECTRON) {
      window.electronApi?.downloadFileByUrl(url);
      window.electronApi?.onDownloadComplete(() => {
        toast.success(i18next.t('records.downloadCompleted'));
      });

      return;
    }

    saveAs(url);
  }

  async deleteRecord({ spaceId, roomId, recordId }: GetUrlForDownloadingPayload): Promise<void> {
    await this.api.delete(`spaces/${spaceId}/rooms/${roomId}/records/${recordId}`);
  }

  @apiCall()
  async getPublicationMeta({ spaceId, roomId, recordId }: GetUrlForDownloadingPayload): ApiResponse<RoomRecordPublicationMeta> {
    const { data } = await this.api.get<{
      totalViews: number;
      readinessStatusChangedAt?: string;
    }>(
      `spaces/${spaceId}/rooms/${roomId}/records/${recordId}/publication-meta`,
    );

    return {
      data: {
        readinessStatusChangedAt: data.readinessStatusChangedAt ? new Date(data.readinessStatusChangedAt) : undefined,
        totalViews: data.totalViews,
      },
    };
  }

  async getSizeInSpace({ spaceId }: SizeRecordsPayload): Promise<{ size: number }> {
    const { data } = await this.api.get<{ size: number }>(`spaces/${spaceId}/records/size`);
    return data;
  }

  async updateRecord({
    spaceId,
    roomId,
    recordId,
    name,
    publicationStatus,
  }: UpdateRecordPayload): Promise<UpdateRecordResponse> {
    if (!name && !publicationStatus) {
      throw new Error('Name or PublicationStatus must be exists');
    }

    const { data } = await this.api.put<UpdateRecordResponse>(
      `spaces/${spaceId}/rooms/${roomId}/records/${recordId}`,
      {
        name,
        publicationStatus,
      },
      {
        timeout: 22_000,
      },
    );

    return data;
  }
}

export default RecordApi;
