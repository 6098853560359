import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { DropDownButton, Tooltip } from 'ui-kit';
import { SettingsOutlineIcon } from 'ui-kit/assets/icons';

import SettingsMenu from 'components/ThemeAndLanguageMenu';

const SettingsButton: FunctionComponent = observer(() => {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={t('common.themeAndLanguage')}
    >
      <DropDownButton
        id="header-settings"
        icon={<SettingsOutlineIcon />}
        menu={<SettingsMenu />}
      />
    </Tooltip>
  );
});

export default SettingsButton;
