import { AxiosInstance } from 'axios';

import { apiCall } from 'decorators';

import { ApiResponse } from './types';
import { GetTimeZonesResponse, TimeZoneLocale } from './StaticApi.types';

class StaticApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  @apiCall()
  async getTimeZones(locale: TimeZoneLocale): ApiResponse<GetTimeZonesResponse> {
    const result = await this.api.get<GetTimeZonesResponse>(`/static/timezones/${locale}`);
    return result;
  }
}

export default StaticApi;
