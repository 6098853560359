import styled from '@emotion/styled';

import { Font } from 'ui-kit/theme';

const Layout = styled.div<{
  imageUrl?: string | null, bkgColor?: string
}>(({
  imageUrl, bkgColor, theme,
}) => ({
  display: 'flex',
  flexShrink: 0,
  justifyContent: 'center',
  alignItems: 'center',
  height: 32,
  color: theme.colors.contrast,
  fontFamily: Font.BRAND_BOLD,
  backgroundImage: imageUrl ? `url(${imageUrl})` : 'none',
  ...((!imageUrl && bkgColor) && {
    backgroundColor: bkgColor,
  }),
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  borderRadius: '50%',
  maxWidth: 120,
  maxHeight: 120,
}));

export default Layout;
