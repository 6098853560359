import React, { FunctionComponent } from 'react';
import useStores from 'stores/root';
import { observer } from 'mobx-react-lite';
import isMobile from 'is-mobile';

export interface ShowContentProps {
  allExcept?: boolean,
  moderator?: boolean,
  user?: boolean,
  guest?: boolean,
  conference?: boolean,
  webinar?: boolean,
  desktop?: true,
  mobile?: true,
}

const ShowContentFor: FunctionComponent<ShowContentProps> = observer(({
  allExcept,
  moderator = false,
  user = false,
  guest = false,
  conference = false,
  webinar = false,
  desktop,
  mobile,
  children,
}) => {
  const isDesktop = !isMobile();
  if ((desktop && !isDesktop) || (mobile && isDesktop)) {
    return null;
  }

  const {
    participantStore: { isModerator, isWebinarGuest },
    roomStore: { isWebinar },
    userStore: { id: userId },
  } = useStores();
  const isConference = !isWebinar;

  const participants: Record<string, boolean> = {
    moderator,
    user,
    guest,
  };

  const isUser = !isModerator && userId;

  const participantsPermissions: Record<string, boolean> = {
    moderator: isModerator,
    user: webinar ? isWebinarGuest : !!isUser,
    guest: webinar ? isWebinarGuest : (!isUser && !isModerator),
  };

  const showByParticipant = () => {
    const showForAll = !Object.values(participants).includes(true);
    if (showForAll) {
      return true;
    }

    return Object.keys(participants).reduce((acc, name) => {
      if (acc) {
        return acc;
      }

      return participants[name] && participantsPermissions[name];
    }, false);
  };

  const showByRoomType = () => {
    switch (true) {
      case conference && webinar:
        return true;
      case conference:
        return isConference;
      case webinar:
        return isWebinar;
      default:
        return true;
    }
  };

  const showContent = showByParticipant() && showByRoomType();
  const isInverted = allExcept ? !showContent : showContent;

  if (!isInverted) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
});

export default ShowContentFor;
