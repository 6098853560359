import React, { forwardRef, useCallback, useRef } from 'react';
import PhoneInput from 'react-phone-number-input/react-hook-form';
import PhoneInputRuLocale from 'react-phone-number-input/locale/ru.json';
import { getCountryCallingCode, isPossiblePhoneNumber } from 'react-phone-number-input';
import type { CountryCode } from 'libphonenumber-js';

import { DownMiniArrowIcon } from 'ui-kit/assets/icons';
import { InputProps } from 'ui-kit/components/Input/type';
import { Input as inputComponent } from 'ui-kit/components/Input/InputLayoutDesktop';

import { Input, InputPhoneStyles } from './index.styled';

interface InputPhoneProps {
  id: string;
  control: unknown;
  defaultCountry?: CountryCode;
  requiredFailedMessage: string;
  validationFailedMessage: string;
  currentLanguage?: string;
}

const InputPhone = forwardRef<HTMLInputElement, InputProps & InputPhoneProps>(({
  id,
  name,
  className,
  control,
  children,
  defaultCountry = 'RU',
  requiredFailedMessage,
  validationFailedMessage,
  currentLanguage,
  required,
  ...restProps
}, forwardedRef) => {
  const country = useRef<CountryCode>(defaultCountry);

  const validate = useCallback((phoneInput: string) => {
    if (!required) {
      return true;
    }

    const countryCallingCode = getCountryCallingCode(country.current);
    if (phoneInput === `+${countryCallingCode}`) {
      return validationFailedMessage;
    }

    if (!phoneInput) {
      return requiredFailedMessage;
    }

    return isPossiblePhoneNumber(phoneInput) || validationFailedMessage || undefined;
  }, [validationFailedMessage]);

  return (
    <>
      <InputPhoneStyles />
      <Input
        required={required}
        id={id}
        name={name}
        type="tel"
        className={className}
        inputElement={PhoneInput}
        ref={forwardedRef}
        inputElementProps={{
          international: true,
          style: {
            paddingRight: 0,
            padding: 0,
          },
          control,
          defaultCountry,
          inputComponent,
          numberInputProps: {
            style: {
              paddingLeft: 0,
              marginLeft: 4,
            },
          },
          countrySelectProps: {
            arrowComponent: DownMiniArrowIcon,
          },
          labels: currentLanguage?.toLocaleLowerCase().includes('ru') ? PhoneInputRuLocale : undefined,
          onCountryChange: (cc: CountryCode) => {
            country.current = cc;
          },
          rules: {
            validate,
          },
        }}
        {...restProps}
      >
        {children}
      </Input>
    </>
  );
});

export default InputPhone;
