import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';

import useStores from 'stores/root';
import { isWhiteLabelFromURL } from 'helpers/common';

const NotWhiteLabelIFrame: FunctionComponent = observer(({ children }) => {
  const {
    roomStore,
  } = useStores();

  if ((roomStore.isWhiteLabel || isWhiteLabelFromURL()) && roomStore.isIframe) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
});

export default NotWhiteLabelIFrame;
