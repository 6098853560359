import { PaymentPlan } from 'constants/payment';

interface FeaturesSet extends Record<string, boolean> {
  apiAccess: boolean;
  breakoutRooms: boolean;
  callsAnalytics: boolean;
  chat: boolean;
  cloudRecording: boolean;
  customSpaceLogo: boolean;
  hdCloudRecording: boolean;
  iframeIntegration: boolean;
  miroBoard: boolean;
  canUseForbiddenDevices: boolean; // not used since LD-5357
  bgBlur: boolean;
  noiseSuppression: boolean;
}

interface RestrictionsSetItem {
  enabled: boolean;
  value: number;
}

interface RestrictionsSet extends Record<string, RestrictionsSetItem> {
  cloudRecordStorageCapacityMb: RestrictionsSetItem;
  maxConcurrentCalls: RestrictionsSetItem;
  maxNumberOfDemoSlots: RestrictionsSetItem;
  maxParticipantsInPracticeRoom: RestrictionsSetItem;
  maxParticipantsInWebinarRoom: RestrictionsSetItem;
  maxSpaceAdminsAmount: RestrictionsSetItem;
  maxWebinarRoomSpeakers: RestrictionsSetItem;
}

export interface Plan {
  id: string;
  name: string;
  alias: PaymentPlan;
  price: number;
  periodInDays: number;
  isActive: boolean;
  isForSale: boolean;
  features: FeaturesSet;
  restrictions: RestrictionsSet;
  isPromo: boolean;
}

export interface PlanDescription {
  alias: PaymentPlan,
  name: string,
  annualAlias?: PaymentPlan;
  promoAlias?: PaymentPlan;
  promoAnnualAlias?: PaymentPlan;
  title: string,
  titleHint?: string,
  priceHintMonthly: string,
  priceHintAnnual: string,
  mainFeatures: string,
  extraFeaturesTitle: string;
  extraFeatures: string;
  planHint?: string;
  buyButtonText: string;
}

export enum PaymentSystem {
  Prodamus = 'prodamus',
  Yookassa = 'yookassa',
}
