import styled from '@emotion/styled';

import { ZIndex, Size } from 'ui-kit/theme';

export const BasicHeaderMobileRoot = styled.div`
  height: ${Size.BASIC_HEADER_MOBILE_HEIGHT}px;
  z-index: ${ZIndex.HEADER};
  position: fixed;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.bg};
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 16px;
  margin-bottom: 4px;
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
