import React from 'react';

import i18n from 'services/i18n';
import { toWindowFeatures } from 'helpers/common';
import { topCenterSnackbarError } from 'components/ToastTopCenter/TopCenterSnackbar';

import WarningMessage from './WarningMessage';

const timeLimitWarning = ({
  kickOutTimeSec,
  callEndTime,
  spaceId,
  isModerator,
  freePlanName,
}: {
  kickOutTimeSec: number,
  callEndTime: Date,
  spaceId: string,
  isModerator: boolean,
  freePlanName: string,
}) => {
  const handleClick = () => {
    const features = toWindowFeatures({
      width: 1080,
      height: 720,
      left: (window.screen.availWidth / 2) - (1080 / 2),
      top: (window.screen.availHeight / 2) - (720 / 2),
    });

    window.open(`/space/${spaceId}/plans?noheader`, undefined, features);
  };

  const buttonProps = {
    id: 'warn-snackbar-buy-plan',
    text: i18n.t('common.warnings.callTimeRestrictionPlansButton'),
    onClick: handleClick,
  };

  return topCenterSnackbarError(
    (
      <WarningMessage
        endTime={callEndTime}
        kickOutTimeSec={kickOutTimeSec}
        isModerator={isModerator}
        freePlanName={freePlanName}
      />
    ),
    {
      ...(isModerator && { buttonProps }),
      autoClose: false,
      closeOnClick: false,
      draggable: false,
      style: { cursor: 'default' },
    },
  );
};

export default timeLimitWarning;
