import styled from '@emotion/styled';
import { IconButton } from 'ui-kit';

export const ReferralCodeRoot = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'end',
  padding: 16,
  marginTop: 16,
  marginBottom: 32,
  borderRadius: 10,
  gap: 8,
  background: theme.colors.accent01,
}));

export const CodeApplied = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexShrink: 0,
  width: 48,
  height: 48,
  borderRadius: 24,
  background: theme.colors.successBase,
  color: theme.colors.contrast,
}));

export const ApplyButton = styled(IconButton)(({ theme }) => ({
  /* TODO: remove after merge IconButton refactoring */
  '&[disabled]': {
    background: theme.colors.secondary01,
  },
}));
