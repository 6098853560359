import isMobile from 'is-mobile';
import { reaction } from 'mobx';

import { setSessionStorageItem } from 'helpers/sessionStorage';
import logger from 'helpers/logger';

import MOSStats from './MOSStats';
import FPSStats from './FPSStats';
import {
  PERIOD_IN_SEC, MOS_SAMPLES_SIZE, STORAGE_KEY_CALL_STATS, FPS_SAMPLES_SIZE,
} from './const';
import { RoomStatsCollectorParams, CallStats } from './types';

class CallStatsCollector {
  private params: RoomStatsCollectorParams;

  private timerHandle: NodeJS.Timer | null = null;

  private stats: CallStats;

  private inboundMOS = new MOSStats(MOS_SAMPLES_SIZE);

  private outboundMOS = new MOSStats(MOS_SAMPLES_SIZE);

  private fpsStats = new FPSStats(PERIOD_IN_SEC, FPS_SAMPLES_SIZE);

  private reactionDisposers: (() => void)[] = [];

  private resetStats() {
    this.inboundMOS.reset();
    this.outboundMOS.reset();

    this.stats = {
      participantsNumber: 0,
      videoTracksNumber: 0,
      audioTracksNumber: 0,
      screenSharingNumber: 0,
      miroBoardNumber: 0,
      rejoinsNumber: 0,
      callDurationInSec: 0,
      isVisualEffectsOn: false,
      isNoiseSuppressionOn: false,
      avgInboundMOS: 0,
      avgOutboundMOS: 0,
      isMobile: isMobile(),
      domElementsNumber: 0,
      cssElementsNumber: 0,
      timeScreenShareInSec: 0,
      timeMiroBoardInSec: 0,
      fps: 0,
      fpsLowest: 0,
      memory: null,
    };

    return this.stats;
  }

  private updateSlotsStats() {
    const { roomStore } = this.params;
    this.stats.videoTracksNumber = roomStore.roomVideoTracks.length;
    this.stats.audioTracksNumber = roomStore.roomAudioTracks.length;
    this.stats.screenSharingNumber = roomStore.roomScreenVideoTracks.length;
    this.stats.miroBoardNumber = Number(!!roomStore.miroBoard.slot);
    if (roomStore.roomScreenVideoTracks.length) {
      this.stats.timeScreenShareInSec += PERIOD_IN_SEC;
    }

    if (roomStore.miroBoard.slot) {
      this.stats.timeMiroBoardInSec += PERIOD_IN_SEC;
    }
  }

  private updateCallStats() {
    const { roomStore } = this.params;
    this.stats.participantsNumber = roomStore.participantPeers.length;
    this.stats.callDurationInSec += PERIOD_IN_SEC;
  }

  private updateParticipantStats() {
    const { participantStore } = this.params;
    this.stats.avgInboundMOS = this.inboundMOS.average();
    this.stats.avgOutboundMOS = this.outboundMOS.average();
    this.stats.isVisualEffectsOn = participantStore.isVisualEffectsEnabled;
    this.stats.isNoiseSuppressionOn = participantStore.isNoiseSuppressionEnabled;
  }

  private updatePageStats() {
    this.stats.domElementsNumber = document.getElementsByTagName('*').length;
    this.stats.cssElementsNumber = document.getElementsByTagName('style').length;
    this.stats.fps = this.fpsStats.average();
    this.stats.fpsLowest = this.fpsStats.lowest();
    const { totalJSHeapSize, usedJSHeapSize, jsHeapSizeLimit } = window.performance.memory || {};
    this.stats.memory = {
      totalJSHeapSize, usedJSHeapSize, jsHeapSizeLimit,
    };
  }

  private collectStats() {
    this.updateSlotsStats();
    this.updateCallStats();
    this.updateParticipantStats();
    this.updatePageStats();
  }

  private storeStats() {
    setSessionStorageItem(STORAGE_KEY_CALL_STATS, this.stats);
  }

  private setupReactions() {
    const { roomStore, participantStore } = this.params;
    this.reactionDisposers = [
      reaction(
        () => roomStore.isRoomReJoined,
        () => {
          this.stats.rejoinsNumber += 1;
        },
      ),
      reaction(
        () => participantStore.inboundMOS,
        (inboundMOS) => {
          this.inboundMOS.push(inboundMOS.value);
        },
      ),
      reaction(
        () => participantStore.outboundMOS,
        (outboundMOS) => {
          this.outboundMOS.push(outboundMOS.value);
        },
      ),
    ];
  }

  private disposeReactions() {
    this.reactionDisposers.forEach((disposer) => disposer());
    this.reactionDisposers = [];
  }

  constructor(params: RoomStatsCollectorParams) {
    this.params = params;
    this.stats = this.resetStats();
  }

  isRunning() {
    return this.timerHandle !== null;
  }

  start() {
    this.resetStats();
    this.setupReactions();
    this.fpsStats.start();
    this.timerHandle = setInterval(() => {
      try {
        this.collectStats();
        this.storeStats();
      } catch (error) {
        logger.error('CallStatsCollector: failed to gather stats', { error });
      }
    }, PERIOD_IN_SEC * 1000);
  }

  stop() {
    if (this.timerHandle) {
      clearInterval(this.timerHandle);
      this.fpsStats.stop();
      this.timerHandle = null;
      this.disposeReactions();
    }
  }

  getStats() {
    return { ...this.stats };
  }

  dispose() {
    this.stop();
  }
}

export default CallStatsCollector;
