import { AxiosInstance } from 'axios';

import { ApiResponse } from 'services/MoodHoodApiClient/types';

import { apiCall } from 'decorators';
import { GetPublicMetaResponse } from './AccountsApi.types';

class AccountsApi {
  private readonly api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  @apiCall()
  getPublicMeta(spaceId?: string): ApiResponse<GetPublicMetaResponse> {
    return this.api.get('accounts/public-meta', {
      params: { spaceId },
    });
  }
}

export default AccountsApi;
