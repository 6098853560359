import styled from '@emotion/styled';

import { textOverflowStyle } from 'ui-kit/theme';
import { Avatar } from 'ui-kit';

export const UserAvatar = styled(Avatar)({
  width: 32,
  height: 32,
  fontSize: '16px',
  marginRight: 8,
});

export const User = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
});

export const UserName = styled.div({
  maxWidth: 200,
  marginRight: 12,
  overflowY: 'hidden',
  ...textOverflowStyle,
});

const UserButtonLayout = styled.div({
  display: 'flex',
  marginLeft: 8,
});

export default UserButtonLayout;
