import React from 'react';
import Portal from 'ui-kit/components/Portal';
import { Loader } from 'ui-kit/index';

import { LoaderContainer } from './Layout';

const FullPageLoader = () => (
  <Portal>
    <LoaderContainer>
      <Loader width="80" height="80" />
    </LoaderContainer>
  </Portal>
);

export default FullPageLoader;
