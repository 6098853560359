import React, {
  FunctionComponent, KeyboardEventHandler, useEffect, useState,
} from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Redirect } from 'react-router-dom';
import { reaction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import useStores from 'stores/root';
import { IconButton, InputMessage } from 'ui-kit';
import { Errors } from 'constants/errors';
import { getApiErrorResponse } from 'helpers/errors';
import { getUrlWithUTM } from 'helpers/utm';
import { getUrlParam } from 'helpers/url';
import { generateAndSetClientUniqueId } from 'helpers/common';
import qos from 'services/QualityOfService';
import { getFormInputClearButton } from 'ui-kit/components/Input/getFormInputClearButton';
import { EyeCloseIcon, EyeIcon } from 'ui-kit/assets/icons';

import {
  Link,
  Input,
  SpanWhite,
  ForgotPasswordBlock,
} from './Forms.styled';
import FormError from './FormError';

interface FormFields {
  email: string;
  password: string;
}

const SignInForm: FunctionComponent<{
  className?: string,
  separator?: React.ReactNode,
  footer?: React.ReactNode,
}> = observer(({
  separator,
  footer,
  className,
}) => {
  const { authStore } = useStores();
  const [redirectTo, setRedirectTo] = useState<string | null>(null);
  const [errorCode, setErrorCode] = useState<string>();
  const { t } = useTranslation();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const getReCaptchaToken = async ():Promise<string> => {
    if (!executeRecaptcha) {
      return '';
    }

    const token: string = await executeRecaptcha('login');

    return token;
  };

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors, isValid },
    setError,
    getValues,
    setValue,
    setFocus,
  } = useForm<FormFields>({
    mode: 'all',
  });

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    await authStore.signIn(data.email, data.password, getReCaptchaToken);
  };

  const [isCapsLockOn, setIsCapsLockOn] = useState(false);

  const passwordOnKeyupHandler: KeyboardEventHandler<HTMLInputElement> = (e) => {
    setIsCapsLockOn(e.getModifierState('CapsLock'));
  };

  useEffect(() => {
    clearErrors('password');
  }, [isCapsLockOn]);

  useEffect(() => {
    let to: (string | null) = getUrlParam('to');
    if (redirectTo === '/auth/logout' || redirectTo === '/auth/restore') {
      to = null;
    }

    setRedirectTo(to);
  }, []);

  useEffect(() => reaction(
    () => authStore.authError,
    (authError) => {
      if (!authError) {
        setErrorCode(undefined);
        return;
      }

      const errCode = getApiErrorResponse(authError);
      setErrorCode(errCode);
    },
  ), []);

  useEffect(() => {
    if (errorCode === Errors.INVALID_GRANT) {
      setError('email', { message: t('auth.wrongUsername') });
      setError('password', { message: t('auth.orPassword') });
    }
  }, [errorCode]);

  const [isPasswordHidden, setIsPasswordHidden] = useState(true);

  if (authStore.isUser) {
    qos.setClientId(generateAndSetClientUniqueId());

    const search = new URLSearchParams(window.location.search);
    search.delete('to');

    return (
      <Redirect to={getUrlWithUTM(`${redirectTo || '/'}?${search.toString()}`)} />
    );
  }

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Input
            id="email"
            fullWidth
            label={t('auth.email')}
            type="email"
            required
            autoComplete="email"
            placeholder={t('auth.enterYourEmail')}
            {...register('email', {
              required: { value: true, message: t('auth.emailIsRequired') },
              pattern: { value: /^\S+@\S+\.\S\S+$/i, message: t('auth.emailMustBeAValid') },
            })}
            hasError={Boolean(errors.email)}
            endInputElement={getFormInputClearButton<FormFields>({
              name: 'email', getValues, setValue, clearErrors, setFocus,
            })}
          >
            <InputMessage>
              {errors.email?.message}
            </InputMessage>
          </Input>
          <Input
            id="password"
            fullWidth
            label={t('auth.password')}
            type={isPasswordHidden ? 'password' : 'text'}
            required
            autoComplete="current-password"
            placeholder={t('auth.enterYourPassword')}
            onKeyUp={passwordOnKeyupHandler}
            {...register('password', {
              required: { value: true, message: t('auth.passwordIsRequired') },
              minLength: { value: 6, message: t('auth.passwordMinLength', { value: 6 }) },
            })}
            hasError={Boolean(errors.password)}
            endInputElement={getFormInputClearButton<FormFields>({
              name: 'password', getValues, setValue, clearErrors, setFocus,
            })}
            afterInputElement={(
              <IconButton
                id="toggle-password"
                size="large"
                onClick={() => {
                  setIsPasswordHidden((x) => !x);
                }}
              >
                {isPasswordHidden ? <EyeIcon /> : <EyeCloseIcon />}
              </IconButton>
            )}
          >
            <InputMessage>
              {isCapsLockOn && (<SpanWhite>{t('auth.capsLockIsOn')}</SpanWhite>)}
              {isCapsLockOn && errors.password?.message && <SpanWhite>{t('auth.and')}</SpanWhite>}
              <span>{errors.password?.message}</span>
            </InputMessage>
          </Input>
        </div>
        {separator}
        <div>
          <ForgotPasswordBlock size="main-text-medium" as="div">
            <Link to="recover">{t('auth.forgotPassword')}</Link>
          </ForgotPasswordBlock>
          {errorCode === Errors.SOMETHING_WENT_WRONG && (
            <FormError title={t('errors.somethingWentWrong')}>
              {t('errors.somethingWentWrongBody')}
            </FormError>
          )}
          <IconButton
            id="login"
            type="submit"
            fullWidth
            isLoading={authStore.isLoading}
            disabled={authStore.isLoading || !isValid}
            variant="primary"
          >
            {t('auth.signIn')}
          </IconButton>
        </div>
        {footer}
      </form>
    </div>
  );
});

export default SignInForm;
