import { Locale } from 'react-date-object';
import gregorian_en from 'react-date-object/locales/gregorian_en';

import { SupportedLanguages } from './types';

const locales: Record<SupportedLanguages, Locale> = {
  en: gregorian_en,
  ru: {
    name: 'russian-locale',
    months: [
      ['Январь', 'Янв'],
      ['Февраль', 'Фев'],
      ['Март', 'Мар'],
      ['Апрель', 'Апр'],
      ['Май', 'Май'],
      ['Июнь', 'Июн'],
      ['Июль', 'Июл'],
      ['Август', 'Авг'],
      ['Сентябрь', 'Сен'],
      ['Октябрь', 'Окт'],
      ['Ноябрь', 'Ноя'],
      ['Декабрь', 'Дек'],
    ],
    weekDays: [
      ['Суббота', 'Сб'],
      ['Воскресение', 'Вс'],
      ['Понедельник', 'Пн'],
      ['Вторник', 'Вт'],
      ['Среда', 'Ср'],
      ['Четверг', 'Чт'],
      ['Пятница', 'Пт'],
    ],
    digits: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
    meridiems: [
      ['AM', 'am'],
      ['PM', 'pm'],
    ],
  },
};
export default locales;
