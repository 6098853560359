import { getBrandDomain } from 'helpers/url';
import { RoomType } from 'types/common';
import { ReactionType } from 'services/MoodHoodApiClient/RoomApi.types';

import { ReactionsCounter } from 'components/Reactions/types';

export enum ConnectionQuality {
  BAD = 1,
  MEDIUM = 2,
  GOOD = 3,
}

export enum MosConnectionQuality {
  BAD = 2.6,
  POOR = 3.1,
  FAIR = 3.6,
  GOOD = 4,
  EXCELLENT = 4.3,
}

export const LESSON_TYPES = (t: (key: string) => string) => ({
  [RoomType.Lesson]: t('admin.classrooms.typeLesson'),
  [RoomType.Webinar]: t('admin.classrooms.typeWebinar'),
} as { [key in RoomType]: string });

export const ROOM_TYPE_HINT = (t: (key: string) => string) => ({
  [RoomType.Lesson]: t('room.conferenceHint'),
  [RoomType.Webinar]: t('room.webinarHint'),
} as { [key in RoomType]: string });

export const LANDING_PAGE_URL = `https://${getBrandDomain()}`;

export enum ToastContainers {
  Action = 'action',
  Feedback = 'feedback',
  Help = 'help',
  Common = 'common',
  TopCenter = 'topCenter',
  Chat = 'Chat',
}

export const AUDIO_PUBLISHER_LABELS = [
  'microphone', 'screen-audio',
];

export enum SlotsOnPage {
  videoWebinar = 3,
  videoConferenceMobile = 3,
  videoConferenceDesktop = 12,
}

export const MIN_USERNAME_LENGTH = 2;

export const MAX_USERNAME_LENGTH = 40;

export const MIN_USER_NAME = 2;

export const DEBUG_MODE_LS_KEY = 'ld_debug_mode';

export const VIDEO_CONFIG_LS_KEY = 'ld_video_config';

export const REACTIONS_INITIAL_VALUE: ReactionsCounter = {
  [ReactionType.Heart]: 0,
  [ReactionType.ThumbUp]: 0,
  [ReactionType.ThumbDown]: 0,
  [ReactionType.Fire]: 0,
};

export const NOISE_SUPPRESSION_LS_KEY = 'noise_suppression_required';

export const SignUpStep2OnlyCreatedAfterDate = new Date('2023-08-29');

export const HTTPS_AND_CURRENT_HOST = `https://${window.location.host}`;

export const continueButtonText = process.env.REACT_APP_FOR_ELECTRON
  ? 'common.continue'
  : 'common.continueInBrowser';

export const ESDK_VERSION = process.env.REACT_APP_ESDK_VERSION || '2.8.6';
