import loadScript from 'load-script';

export const asyncLoadScript = (url: string): Promise<HTMLScriptElement> => new Promise((resolve, reject) => {
  loadScript(
    url,
    (err: Error | null, script: HTMLScriptElement) => {
      if (err) {
        reject(err);
      } else {
        resolve(script);
      }
    },
  );
});

export const asyncLoadCSS = (url: string) => new Promise<void>((resolve, reject) => {
  const link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.onload = () => resolve();
  link.onerror = () => reject();
  link.href = url;

  const head = document.getElementsByTagName('head')[0];
  head.appendChild(link);
});
