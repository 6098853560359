type InviteURL = { pathname: string };

// Example: /invite/61f7ee3e37e7b5e583a6560e/62834f36196b1f462bf1b995
const getInvitePathRegExp = (): RegExp => /^\/invite\/([a-f\d]{24})\/([a-f\d]{24})\/*([a-zA-Z\d]+)*$/;

export const extractInviteParams = (url: InviteURL): { spaceId?: string, inviteId?: string, roomId?: string } => {
  const inviteRegExp = getInvitePathRegExp();
  const matches = inviteRegExp.exec(url.pathname);

  if (!matches) {
    return { spaceId: undefined, inviteId: undefined, roomId: undefined };
  }

  const [, spaceId, inviteId, roomId] = matches;

  return {
    spaceId,
    inviteId,
    roomId,
  };
};
