import React, {
  ComponentPropsWithRef, forwardRef, useRef, useEffect, useState,
} from 'react';

import {
  SliderRoot,
  SliderInput,
  MinMaxHint,
  LabelText,
} from './index.styled';

interface SliderProps extends ComponentPropsWithRef<'input'> {
  label?: string | JSX.Element,
  showMinMax?: boolean,
}

const Slider = forwardRef<HTMLInputElement, SliderProps>(({
  label,
  className,
  min,
  max,
  showMinMax,
  value,
  onChange,
  ...props
}, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [progress, setProgress] = useState(0);
  const handleRef = (el: HTMLInputElement | null) => {
    inputRef.current = el;
    if (typeof ref === 'function') {
      ref(el);
    } else if (ref) {
      // eslint-disable-next-line no-param-reassign
      ref.current = el;
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
    const progressOnChange = (Number(event.target.value) / Number(max)) * 100;
    setProgress(progressOnChange);
  };

  useEffect(() => {
    const initProgress = (Number(inputRef.current?.value) / Number(max)) * 100;
    setProgress(initProgress);
  }, []);

  useEffect(() => {
    setProgress(Number(value ?? 0));
  }, [value]);

  return (
    <SliderRoot>
      <LabelText>{label}</LabelText>
      <SliderInput
        type="range"
        className={className}
        min={min}
        max={max}
        onChange={handleChange}
        value={value}
        {...props}
        ref={handleRef}
        progress={progress}
      />
      {showMinMax && (
        <MinMaxHint>
          <span>{min}</span>
          <span>{max}</span>
        </MinMaxHint>
      )}
    </SliderRoot>
  );
});

export default Slider;
