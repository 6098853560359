import { InputElementDescriptor } from 'services/MoodHoodApiClient/types/inputElements';

import { JoinSettings } from './types/joinSettings';
import { PlaybackEventResponse } from './PlaybackEventsApi.types';

type Auditory = 'all' | 'guests' | 'users' | 'moderators';

export interface Playback {
  id: string;
  alias?: string;
  assetId: string | null;
  type: PlaybackEventType;
  name: string;
  spaceId: string;
  timelineId: string;
  chatApplicationId: string;
  readinessStatus: 'draft' | 'published',
  joinSettings: JoinSettings;
  counters: {
    recurring: number,
    nonRecurring: number,
    persistent: number,
    sessionsCompleted: number,
    sessionsRunning: number,
    participated: number
  },
}

export interface NewPlayback {
  name: string,
  fields: InputElementDescriptor[]
}

export interface PlaybackForPreview extends Playback {
  sessionStartDate: Date;
}

interface TimelineEvent {
  offset: number;
}

interface AnnouncementData {
  id: string;
  title: string;
  buttonText?: string;
  description?: string;
  type: 'promo_button' | 'prodamus_pay_form';
  auditory: Auditory;
  url?: string;
  imgUrl?: string;
  currency: 'RUB' | 'GBP' | 'EUR' | 'USD' | 'KZT';
  amount?: number;
}

export interface Announcement extends TimelineEvent {
  event: 'show' | 'hide';
  data: AnnouncementData;
}

export interface ChatMessageData {
  id: number;
  text: string;
  type: 'user' | 'system';
  userId?: number;
  createdAt: string;
}

interface ChatMessage extends TimelineEvent {
  event: 'send';
  data: ChatMessageData;
}

export interface ChatUser {
  id: number;
  externalId?: string;
  username: string;
  displayName: string;
  role: Auditory;
}

interface Chat {
  messages: ChatMessage[];
  users: ChatUser[];
}

export interface PlaybackTimeline {
  id: string;
  recordId: string;
  announcements: Announcement[];
  chat: Chat;
  duration: number;
}

export enum PlaybackEventType {
  Conference = 'conference',
  Webinar = 'webinar',
  Record = 'record',
}

export enum EventSessionFrequency {
  Permanent = 'permanent',
  OneTime = 'oneTime',
  Recurrent = 'recurrent',
}

type OmittedFields = 'type' | 'startDate' | 'endDate' | 'createdAt' | 'joinSettings';

export interface PlaybackEvent extends Omit<PlaybackEventResponse, OmittedFields> {
  type: PlaybackEventType;
  startDate: Date | null;
  endDate: Date | null;
  createdAt: Date;
  joinSettings: JoinSettings;
}

export interface PlaybackEventExtended extends PlaybackEvent {
  sessionStartDate: Date;
}

export interface PlaybackEventSession {
  id: string;
  startDate: Date;
  endDate: Date;
  type: PlaybackEventType;
  playbackEventId: string;
  mediaUrl: string;
  chatApplicationId: string;
  chatChannelId: string;
  frequency: EventSessionFrequency;
  recurrence: string | null;
  timezone: string;
  uniqueParticipantsCount: number;
}
