import styled from '@emotion/styled';

import { ZIndex } from 'ui-kit/theme';
import FigmaTypography from '../FigmaTypography';

export const Cover = styled.div({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: 'rgba(0 0 0/0.5)',
  zIndex: ZIndex.DIALOG,
});

// main dialog elements
export const DialogFromBottomRoot = styled.div(({ theme }) => ({
  position: 'absolute',
  zIndex: ZIndex.DIALOG,
  backgroundColor: theme.colors.bg,
  bottom: 0,
  left: 0,
  width: '100%',
  padding: '12px 16px 32px 16px',
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
}));

export const Header = styled.div<{ showDivider: boolean }>(({ theme, showDivider }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 0',
  ...(showDivider && {
    borderBottom: `solid 1px ${theme.colors.secondary02}`,
  }),
  '#close-dialog-icon-button:only-child': {
    marginLeft: 'auto',
  },
}));

export const SwipeIndicator = styled.div(({ theme }) => ({
  width: 50,
  height: 5,
  margin: '0 auto',
  borderRadius: 2,
  background: theme.colors.secondary02,
}));

export const Content = styled.div<{ isVisible: boolean }>(({ isVisible }) => ({
  ...(!isVisible && {
    display: 'none',
  }),
  marginTop: 8,
}));

export const Title = styled(FigmaTypography)({
  margin: '0 6px',
});
