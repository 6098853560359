export enum OutboundConnectionQuality {
  Good = 'good',
  Poor = 'poor',
  Bad = 'bad',
}

export enum JoinApproval {
  NotNeeded = 'notNeeded',
  Waiting = 'waiting',
  Approved = 'approved',
  Denied = 'denied',
}
