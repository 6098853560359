import {
  action,
  computed,
  observable,
  makeAutoObservable,
} from 'mobx';
import isMobile from 'is-mobile';

import { PeerInfo } from 'types/common';
import { RootStore } from 'stores/root';
import PeerStore from 'stores/peer';
import { SlotAssignment, TrackLabel } from 'services/MoodHoodApiClient/types';
import { SlotsOnPage } from 'constants/common';
import { sortPeerInfoByUID, sortPeerInfoByUIDAndPeerId, sortPeerStoreByUID } from 'helpers/common';
import Features from 'constants/features';
import { isRoomRecord } from 'helpers/url';

class RoomPaginationStore {
  rootStore: RootStore;

  @observable shift = 0;

  @observable pageNumber = 0;

  @observable actualPageSize = 1;

  @observable peersOnPage: PeerStore[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @computed peersInfo(): PeerInfo[] {
    const reducer = (acc: PeerInfo[], currentPeer: PeerStore) => {
      const noVideo = !currentPeer.hasVideo(TrackLabel.Camera) && !currentPeer.hasVideo(TrackLabel.ScreenVideo);

      if (noVideo) {
        return acc.concat({
          peer: currentPeer,
          trackLabel: TrackLabel.Camera,
          hasVideo: false,
          slotAssignment: {
            slotUuid: `${currentPeer.id}_${TrackLabel.Camera}`,
            trackLabel: TrackLabel.Camera,
          },
          isOutOfScreen: currentPeer.appData.isOutOfScreen,
        } as PeerInfo);
      }

      const hasScreensharing = currentPeer.hasVideo(TrackLabel.ScreenVideo)
        && !currentPeer.hasVideo(TrackLabel.Camera)
        && !currentPeer.appData.isScreensharingPeer;

      if (hasScreensharing) {
        acc.push({
          peer: currentPeer,
          trackLabel: TrackLabel.Camera,
          hasVideo: false,
          slotAssignment: {
            slotUuid: `${currentPeer.id}_${TrackLabel.Camera}`,
            trackLabel: TrackLabel.Camera,
          },
          isOutOfScreen: currentPeer.appData.isOutOfScreen,
        } as PeerInfo);
      }

      const slots = currentPeer.videoTracks.filter((x) => !x.isPaused).map(({ label }) => ({
        peer: currentPeer,
        trackLabel: label,
        hasVideo: currentPeer.hasVideo(label),
        slotAssignment: {
          slotUuid: `${currentPeer.id}_${label}`,
          trackLabel: label,
          transformParams: currentPeer.appData.transformParams,
        },
        isOutOfScreen: currentPeer.appData.isOutOfScreen,
      } as PeerInfo));

      return acc.concat(slots);
    };

    return this.rootStore.roomStore.participantPeers
      .reduce(reducer, []);
  }

  @computed get peersInfoWithoutFloatingSlots(): PeerInfo[] {
    const floatingSlotsUuids = this.rootStore.roomStore.floatSlots
      .filter(({ disable }) => !disable)
      .map(({ floatSlotUuid }) => floatSlotUuid);

    return this.peersInfo()
      .filter(({ slotAssignment: { slotUuid } }) => !floatingSlotsUuids.includes(slotUuid));
  }

  sortUsersPeers(usersPeers: PeerInfo[], demoPeers: PeerInfo[]): PeerInfo[] {
    const withDemoModerators: PeerInfo[] = [];
    const withDemoUsers: PeerInfo[] = [];
    const withCamModerators: PeerInfo[] = [];
    const withCamUsers: PeerInfo[] = [];
    const noCamModerators: PeerInfo[] = [];
    const noCamUsers: PeerInfo[] = [];

    const demoPeersIds = demoPeers.map(({ peer: { id } }) => id);

    usersPeers.forEach((x) => {
      if (demoPeersIds.includes(x.peer.id)) {
        if (x.peer.isModerator) {
          withDemoModerators.push(x);
        } else {
          withDemoUsers.push(x);
        }

        return;
      }

      if (x.peer.isModerator) {
        if (x.peer.hasActiveCameraTrack) {
          withCamModerators.push(x);
        } else {
          noCamModerators.push(x);
        }

        return;
      }

      if (x.peer.hasActiveCameraTrack) {
        withCamUsers.push(x);
        return;
      }

      noCamUsers.push(x);
    });

    withDemoModerators.sort(sortPeerInfoByUID);
    withDemoUsers.sort(sortPeerInfoByUID);
    withCamModerators.sort(sortPeerInfoByUID);
    withCamUsers.sort(sortPeerInfoByUID);
    noCamModerators.sort(sortPeerInfoByUID);
    noCamUsers.sort(sortPeerInfoByUID);

    return withDemoModerators
      .concat(withDemoUsers)
      .concat(withCamModerators)
      .concat(withCamUsers)
      .concat(noCamModerators)
      .concat(noCamUsers);
  }

  @computed get peersForGrid(): typeof desktopPeers {
    const trackLabels = [TrackLabel.ScreenVideo, TrackLabel.Board, TrackLabel.Broadcast];

    const demoPeers = this.peersInfoWithoutFloatingSlots
      .filter(({ trackLabel }) => trackLabels.includes(trackLabel))
      .sort(sortPeerInfoByUID);

    const usersPeers = this.rootStore.roomStore.isWebinar
      ? this.usersPeersForGrid.filter(({ isOutOfScreen }) => !isOutOfScreen)
        .sort(sortPeerInfoByUIDAndPeerId)
        .slice(0, SlotsOnPage.videoWebinar)
      : this.usersPeersForGrid;

    const hideWoVideo = this.rootStore.featureStore.isFeatureOn(Features.HidePeersWoVideo.key);

    const filterFn = hideWoVideo ? (x: PeerInfo) => x.hasVideo : () => true;

    const usersPeersSorted = this.sortUsersPeers(usersPeers.filter(filterFn), demoPeers);

    const desktopPeers = {
      demoPeers,
      usersPeers: usersPeersSorted,
      totalPeersNumber: new Set(this.peersInfo().map(({ peer: { id } }) => id)).size,
    };

    return desktopPeers;
  }

  @computed get peersSlicedForGrid(): typeof desktopPeers {
    const {
      demoPeers,
      usersPeers,
    } = this.peersForGrid;

    const desktopPeers = {
      demoPeers,
      usersPeers: usersPeers.slice(this.shift),
    };

    return desktopPeers;
  }

  @computed get usersPeersForGrid(): PeerInfo[] {
    return this.peersInfoWithoutFloatingSlots
      .filter(({ trackLabel, peer }) => trackLabel === TrackLabel.Camera && peer.isJoinApproved)
      .sort(sortPeerInfoByUID);
  }

  @computed get usersPeers(): PeerStore[] {
    return this.rootStore.roomStore.participantPeers
      .filter((peer) => peer.isJoinApproved && !peer.appData.isScreensharingPeer)
      .sort(sortPeerStoreByUID);
  }

  @computed get visiblePeers(): PeerInfo[] {
    return [
      ...this.peersSlicedForGrid.demoPeers,
      ...this.peersSlicedForGrid.usersPeers.filter((x) => this.peersOnPage.includes(x.peer)),
    ];
  }

  @computed get visibleUserPeers(): PeerInfo[] {
    return [
      ...this.peersSlicedForGrid.usersPeers.filter((x) => this.peersOnPage.includes(x.peer)),
    ];
  }

  @computed get hiddenPeers(): PeerInfo[] {
    return this.peersInfo().filter((peerInfo) => !this.visiblePeers.map((x) => x.peer).includes(peerInfo.peer));
  }

  @computed get maxPageCapacity(): number {
    const {
      demoPeers,
    } = this.peersForGrid;

    if (this.rootStore.roomStore.isWebinar) {
      return SlotsOnPage.videoWebinar;
    }

    if (isMobile()) {
      return SlotsOnPage.videoConferenceMobile - demoPeers.length;
    }

    return SlotsOnPage.videoConferenceDesktop - demoPeers.length;
  }

  @computed get pagesCount(): number {
    return Math.ceil(this.peersForGrid.usersPeers.length / this.actualPageSize);
  }

  @computed get isPaginable(): boolean {
    return this.actualPageSize < this.peersForGrid.usersPeers.length && !isRoomRecord();
  }

  @action setActualPageSize(value: number) {
    if (this.shift === 0 && value > 0) {
      this.actualPageSize = value;
    }
  }

  @action setPeersOnPage(value: PeerStore[]) {
    if (value.length === 0 && this.shift > 0 && this.pageNumber > 0) {
      this.gotoPrevPage();
      return;
    }

    this.peersOnPage = value;
    this.setActualPageSize(value.length);
  }

  @action gotoPeer(value: PeerStore) {
    const {
      usersPeers,
    } = this.peersForGrid;

    const peerInfo = usersPeers.find((x) => x.peer === value);

    if (peerInfo) {
      const peerIndex = usersPeers.indexOf(peerInfo);
      if (peerIndex > -1) {
        this.gotoPage(Math.trunc(peerIndex / this.actualPageSize));
      }
    }
  }

  @action gotoPage(page: number) {
    this.pageNumber = page;
    this.shift = page * this.actualPageSize;
  }

  @action gotoFirstPage() {
    this.gotoPage(0);
  }

  @action gotoLastPage() {
    this.gotoPage(this.pagesCount - 1);
  }

  @action gotoPrevPage() {
    const prevPage = this.pageNumber - 1;
    if (prevPage < 0) {
      this.gotoPage(this.pagesCount - 1);
    } else {
      this.gotoPage(prevPage);
    }
  }

  @action gotoNextPage() {
    const nextPage = this.pageNumber + 1;
    if (nextPage > this.pagesCount - 1) {
      this.gotoPage(0);
    } else {
      this.gotoPage(nextPage);
    }
  }

  @computed get slotsForPip(): SlotAssignment[] {
    const userPeersOnPage = this.peersSlicedForGrid.usersPeers || [];

    const notMySlots = userPeersOnPage
      .filter((peerInfo) => !peerInfo.peer.isMe);

    if (notMySlots.length > 0) {
      return notMySlots
        .filter((peerInfo) => peerInfo.hasVideo)
        .map((peerInfo) => peerInfo.slotAssignment);
    }

    const mySlot = userPeersOnPage
      .filter((peerInfo) => peerInfo.hasVideo && peerInfo.peer.isMe)
      .map((peerInfo) => peerInfo.slotAssignment);

    return mySlot;
  }
}

export default RoomPaginationStore;
