import React, { FunctionComponent, useRef } from 'react';
import UserMenu from '../../UserMenu';
import { UserButtonProps } from '../types';

import UserButtonLayout, { User, UserName, UserAvatar } from './Layout';

const UserButtonDesktop: FunctionComponent<UserButtonProps> = ({
  name = '', icon, handleMenuOpen, handleMenuClose, isMenuOpen, isCompact, ...restProps
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <UserButtonLayout {...restProps}>
      <User ref={ref} id="basic-user-menu" onClick={handleMenuOpen}>
        <UserAvatar userName={name} imageUrl={icon} fixedSize />
        {!isCompact && name && (
          <UserName>
            {name}
          </UserName>
        )}
      </User>
      <UserMenu
        isVisible={isMenuOpen}
        anchor={ref}
        onClose={handleMenuClose}
      />
    </UserButtonLayout>
  );
};

export default UserButtonDesktop;
