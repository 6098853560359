import styled from '@emotion/styled';

import { getObjectTextStyle } from 'ui-kit/theme';

import { LabelPosition } from './index';

export const CheckBoxInput = styled.input<{ disabled?: boolean }>(({ theme: { colors }, disabled }) => ({
  flex: '0 0 auto',
  appearance: 'none',
  display: 'block',
  width: 42,
  height: 24,
  backgroundColor: disabled ? colors.secondary02 : colors.secondary03,
  borderRadius: 14,
  transition: '0.1s linear',
  '&:hover:not([disabled])': {
    backgroundColor: colors.secondaryBase,
  },

  '&:checked': {
    backgroundColor: disabled ? colors.secondary02 : colors.accentBase,
    '&:hover:not([disabled])': {
      backgroundColor: colors.accent05,
    },
  },

  '&:focus': {
    outline: 'none',
  },

  '&:before': {
    marginTop: 4,
    marginLeft: 4,
    content: '""',
    display: 'block',
    width: 16,
    height: 16,
    backgroundColor: disabled ? colors.secondary03 : colors.bg,
    borderRadius: 16,
    transition: '0.1s linear',
  },
  '&:checked:before': {
    transform: 'translateX(18px)',
  },
}));

export const ToggleRoot = styled.label<{ labelPosition: LabelPosition, fullWidth?: boolean }>(({
  labelPosition,
  fullWidth,
  theme: { colors },
}) => ({
  ...getObjectTextStyle('main-text-medium'),
  color: colors.secondaryBase,
  position: 'relative',
  display: 'flex',
  justifyContent: fullWidth ? 'space-between' : 'flex-start',
  flexDirection: labelPosition === 'left' ? 'row-reverse' : 'row',
  alignItems: 'center',
  userSelect: 'none',
  '& > input': {
    margin: labelPosition === 'left' ? '0 0px 0 8px' : '0 8px 0 0px',
  },
}));
