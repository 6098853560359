import {
  observable,
  action,
  makeAutoObservable,
  computed,
} from 'mobx';

import { PlaybackEvent } from 'services/MoodHoodApiClient/Playbacks.types';

import {
  PlaybackEventToEdit,
} from './types';

class PlaybackEventsStore {
  @observable playbackEvents: (PlaybackEvent & { isDeleting?: boolean })[] = [];

  @observable totalCount = 0;

  @observable lastPage: PlaybackEvent[] = [];

  @observable playbackEventToEdit: PlaybackEventToEdit | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  @computed get isNewPlaybackEventToEdit(): boolean {
    return !(this.playbackEventToEdit as PlaybackEvent)?.id;
  }

  @action setPlaybackEventToEdit(playbackId: string) {
    const playbackEvent = this.findPlaybackEvent(playbackId);
    if (!playbackEvent) {
      return;
    }

    this.playbackEventToEdit = playbackEvent;
  }

  @action setBlankPlaybackEventToEdit() {
    const playbackEvent = this.createBlankPlaybackEvent();
    this.playbackEventToEdit = playbackEvent;
  }

  @action resetPlaybackEventToEdit() {
    this.playbackEventToEdit = null;
  }

  @action setPlaybackEvents(playbackEvents: PlaybackEvent[]) {
    this.playbackEvents = playbackEvents;
    this.playbackEventToEdit = null;
  }

  @action appendPlaybackEvents(playbackEvents: PlaybackEvent[]) {
    this.playbackEvents = this.playbackEvents.concat(playbackEvents);
  }

  @action setLastPage(playbackEvents: PlaybackEvent[]) {
    this.lastPage = playbackEvents;
  }

  @action findPlaybackEvent(playbackEventId: string): PlaybackEvent | null {
    return this.playbackEvents?.find((pe) => pe.id === playbackEventId) || null;
  }

  createBlankPlaybackEvent() {
    const playbackEvent = {
      recurrence: null,
      startDate: null,
      endDate: null,
      timezone: '',
    };

    return playbackEvent;
  }

  @computed get hasPlaybackEvents() {
    return this.playbackEvents.length > 0;
  }
}

export default PlaybackEventsStore;
