import { reaction } from 'mobx';
import { getClientUniqueId, getClientTabId } from 'helpers/common';
import { getUserAgentInfo, checkOSRequirements, isPageNavigatedByReload } from 'helpers/browser';
import { RootStore, rootStore } from 'stores/root';
import logger from 'helpers/logger';
import { getUrlParam } from 'helpers/url';
import { getSessionStorageItem } from 'helpers/sessionStorage';
import { STORAGE_KEY_CALL_STATS } from 'modules/CallStatsCollector/const';
import { CallStats } from 'modules/CallStatsCollector/types';

import {
  QoSEvent,
  QoSEvents,
  QoSEventType,
  QosEventCategory,
  TrackInfo,
  QoSEventTag,
  QoSUserAgentInfo,
  UserFeedback,
} from './types';
import { VLProjectSpacesToIgnore } from './constants';

const PEERS_TRACKS_CHECK_PERIOD = 5000;

class QualityOfService {
  private readonly rootStore: RootStore;

  private readonly uaInfo: QoSUserAgentInfo;

  private clientId: string;

  private readonly tabId: string;

  private readonly isUnSupportedOS;

  private readonly ymTrackerId = Number(process.env.REACT_APP_YANDEX_METRICA_TRACKER_ID);

  private tracksCheckTimerId: NodeJS.Timeout | null = null;

  private isPeersTracksCheckInProgress = false;

  constructor() {
    this.rootStore = rootStore;
    this.uaInfo = getUserAgentInfo();
    this.clientId = getClientUniqueId();
    this.tabId = getClientTabId();
    this.isUnSupportedOS = checkOSRequirements().isUnSupportedOS;
    this.setupReactions();
    this.setupListeners();
  }

  reportPeersTracksStatus() {
    if (this.isPeersTracksCheckInProgress) {
      return;
    }

    this.isPeersTracksCheckInProgress = true;

    this.tracksCheckTimerId = setTimeout(() => {
      const { allPeers, peers } = this.rootStore.roomStore;
      const peersWithDeadTracks = allPeers.filter((peer) => peer.allMediaTracks.some(
        ({ track }) => (track.readyState !== 'live' || track.muted),
      ));

      const peersCount = allPeers.length;
      const tracksCount = allPeers.reduce((acc, peer) => acc + peer.allMediaTracks.length, 0);

      if (!peersWithDeadTracks.length) {
        this.reportEvent({
          type: tracksCount ? QoSEventType.GotAllTracks : QoSEventType.GotNoneTracks,
          category: QosEventCategory.Media,
          meta: {
            attempt: 'first',
            peersCount,
            tracksCount,
          },
          ...this.getEventCommonPayload(),
        });

        this.tracksCheckTimerId = null;
        this.isPeersTracksCheckInProgress = false;

        return;
      }
      /* give time to dead tracks to become alive and check them again */
      this.tracksCheckTimerId = setTimeout(() => {
        const deadTracks = peersWithDeadTracks.reduce((acc, peer) => {
          const tracks = peer.allMediaTracks
            .filter(({ track }) => peers.get(peer.id) && (track.readyState !== 'live' || track.muted))
            .map(({ track: { id: trackId, label: trackLabel } }) => ({ peerId: peer.id, trackId, trackLabel }));
          acc.push(...tracks);

          return acc;
        }, [] as TrackInfo[]);

        this.reportEvent({
          type: deadTracks.length ? QoSEventType.GotSomeTracks : QoSEventType.GotAllTracks,
          category: QosEventCategory.Media,
          meta: {
            attempt: 'second',
            peersCount,
            tracksCount,
            ...(deadTracks.length && { deadTracks }),
          },
          ...this.getEventCommonPayload(),
        });

        this.tracksCheckTimerId = null;
        this.isPeersTracksCheckInProgress = false;
      }, PEERS_TRACKS_CHECK_PERIOD);
    }, PEERS_TRACKS_CHECK_PERIOD);
  }

  reportRoomNavigation(roomAlias: string):void {
    this.reportEvent({
      type: QoSEventType.NavigateRoom,
      category: QosEventCategory.Room,
      meta: {
        roomAlias,
        referer: document.referrer,
      },
      ...this.getEventCommonPayload(),
    });
  }

  reportRoomJoinAttempt() {
    this.reportEvent({
      type: QoSEventType.RoomJoinAttempt,
      category: QosEventCategory.Room,
      ...this.getEventCommonPayload(),
    });
  }

  reportRoomReJoinAttempt() {
    const { isConnectionLost } = this.rootStore.roomStore;
    const state = isConnectionLost ? 'connection_lost' : 'room_change';
    this.reportEvent({
      type: QoSEventType.RoomReJoinAttempt,
      category: QosEventCategory.Room,
      state,
      ...this.getEventCommonPayload(),
    });
  }

  reportRoomJoinSucceeded() {
    const payload = {
      category: QosEventCategory.Room as const,
      ...this.getEventCommonPayload(),
    };

    const events: QoSEvents[] = [{
      type: QoSEventType.RoomJoinSucceeded,
      ...payload,
    }];

    const isPageReloaded = isPageNavigatedByReload();

    if (isPageReloaded) {
      const prevCallStats = getSessionStorageItem<CallStats>(STORAGE_KEY_CALL_STATS);

      if (prevCallStats) {
        logger.info('Room reload prev call stats', { ...prevCallStats, clientId: this.clientId });
      }

      events.push({
        type: QoSEventType.RoomReload,
        meta: prevCallStats,
        ...payload,
      });
    }

    this.reportEvents(events);
  }

  reportRoomReJoinSucceeded() {
    this.reportEvent({
      type: QoSEventType.RoomReJoinSucceeded,
      category: QosEventCategory.Room,
      ...this.getEventCommonPayload(),
    });
  }

  reportDevicesReady() {
    const { availableAudioDevices, availableVideoDevices } = this.rootStore.participantStore.deviceStore;
    this.reportEvent({
      type: QoSEventType.DevicesReady,
      category: QosEventCategory.Device,
      meta: {
        devices: { availableAudioDevices, availableVideoDevices },
      },
      ...this.getEventCommonPayload(),
    });
  }

  reportDevicesAccessState() {
    const state = this.rootStore.participantStore.deviceStore.hasDevicePermissionError
      ? QoSEventType.DevicesAccessDenied
      : QoSEventType.DevicesAccessGranted;

    this.reportEvent({
      type: QoSEventType.DevicesAccessState,
      category: QosEventCategory.Device,
      state,
      ...this.getEventCommonPayload(),
    });
  }

  reportChatInitialized() {
    this.reportEvent({
      type: QoSEventType.ChatInitialized,
      category: QosEventCategory.Chat,
      ...this.getEventCommonPayload(),
    });
  }

  reportParticipantNameInputNavigation() {
    this.reportEvent({
      type: QoSEventType.ParticipantNameInput,
      category: QosEventCategory.Room,
      ...this.getEventCommonPayload(),
    });
  }

  reportDevicesNavigation() {
    const commonPayload = this.getEventCommonPayload();
    const { hasDevicePermissionError } = this.rootStore.participantStore.deviceStore;
    this.reportEvents([
      {
        type: QoSEventType.DevicesAccessState,
        category: QosEventCategory.Device,
        state: hasDevicePermissionError ? QoSEventType.DevicesAccessDenied : QoSEventType.DevicesAccessGranted,
        ...commonPayload,
      },
      {
        type: QoSEventType.NavigateDevices,
        category: QosEventCategory.Room,
        ...commonPayload,
      },
    ]);
  }

  reportDevicesAccessRequest() {
    this.reportEvent({
      type: QoSEventType.DevicesAccessRequest,
      category: QosEventCategory.Device,
      ...this.getEventCommonPayload(),
    });
  }

  reportDevicesAccessGranted() {
    this.reportEvent({
      type: QoSEventType.DevicesAccessGranted,
      category: QosEventCategory.Device,
      ...this.getEventCommonPayload(),
    });
  }

  reportDevicesAccessDenied() {
    this.reportEvent({
      type: QoSEventType.DevicesAccessDenied,
      category: QosEventCategory.Device,
      ...this.getEventCommonPayload(),
    });
  }

  reportUserFeedbackPositive() {
    this.reportEvent({
      type: QoSEventType.UserFeedbackPositive,
      category: QosEventCategory.Room,
      ...this.getEventCommonPayload(),
    });
  }

  reportUserFeedbackRequested() {
    this.reportEvent({
      type: QoSEventType.UserFeedbackRequested,
      category: QosEventCategory.Room,
      ...this.getEventCommonPayload(),
    });
  }

  reportUserFeedbackNegative(feedback: UserFeedback) {
    this.reportEvent({
      type: QoSEventType.UserFeedbackNegative,
      category: QosEventCategory.Room,
      meta: feedback,
      ...this.getEventCommonPayload(),
    });
  }

  reportRoomStateInconsistent(type: string, meta: { peerId?: string, producerId?: string }) {
    this.reportEvent({
      type: QoSEventType.RoomStateInconsistent,
      category: QosEventCategory.Room,
      tag: type,
      meta,
      ...this.getEventCommonPayload(),
    });
  }

  dispose() {
    if (this.tracksCheckTimerId) {
      clearTimeout(this.tracksCheckTimerId);
    }

    this.tracksCheckTimerId = null;
    this.isPeersTracksCheckInProgress = false;
  }

  setClientId(clientId: string): void {
    this.clientId = clientId;
  }

  private setupReactions() {
    const { roomStore, participantStore: { deviceStore } } = rootStore;

    reaction(
      () => deviceStore.isDevicesLoaded,
      (isDevicesLoaded) => {
        if (isDevicesLoaded) {
          const {
            availableAudioDevices,
            availableVideoDevices,
          } = deviceStore;

          if (!deviceStore.hasDeviceError
             && (availableAudioDevices.length || availableVideoDevices.length)
          ) {
            this.reportDevicesReady();
          }
        }
      },
    );

    reaction(
      () => ({
        isJoined: this.rootStore.roomStore.isRoomJoined,
        spaceId: this.rootStore.roomStore.spaceId,
        roomId: this.rootStore.roomStore.id,
      }),
      ({ isJoined, spaceId, roomId }) => {
        if (!isJoined || !spaceId || !roomId) {
          return;
        }

        if (roomStore.isRoomReJoining) {
          this.reportRoomReJoinSucceeded();
        } else {
          this.reportRoomJoinSucceeded();
        }

        this.reportPeersTracksStatus();
        this.reportDevicesAccessState();
      },
    );
  }

  private setupListeners() {
    const { participantStore: { client } } = rootStore;

    client.observer.on(
      'channel-state-inconsistent',
      (payload: { type: string, peerId?: string, producerId?: string }) => {
        const { type, ...meta } = payload;
        this.reportRoomStateInconsistent(type, meta);
        logger.warn('Channel state inconsistent', {
          case: 'ParticipantStore.listenChannelEvents',
          type,
          meta,
        });
      },
    );
  }

  private getEventTag(): string | undefined {
    if (this.isUnSupportedOS) {
      return QoSEventTag.UnsupportedOs;
    }

    const tag = getUrlParam('sessionTag');
    if (tag) {
      return tag;
    }

    const { roomStore: { spaceId } } = this.rootStore;
    if (spaceId && VLProjectSpacesToIgnore.includes(spaceId)) {
      return QoSEventTag.VLProjects;
    }

    return undefined;
  }

  private getEventCommonPayload(): Omit<QoSEvent, 'type' | 'category'> {
    const {
      roomStore, participantStore, userStore,
    } = this.rootStore;
    const { id: roomId, spaceId, type: roomType } = roomStore;
    const { id: participantId } = participantStore;
    const { id: userId } = userStore;
    const { activeCall } = roomStore.calls;
    const { clientId, tabId, uaInfo } = this;
    const tag = this.getEventTag();
    return {
      timestamp: new Date(),
      clientId,
      tabId,
      participantId,
      roomId,
      spaceId,
      tag,
      roomType,
      userId,
      callId: activeCall?.id,
      ua: uaInfo,
    };
  }

  private reportEvent(qosEvent: QoSEvents) {
    this.reportEvents([qosEvent]);
  }

  private reportEvents(qosEvents: QoSEvents[]): void {
    this.rootStore.moodHoodAnalyticsApiClient.analytics.sendQoSEvents(qosEvents);
    qosEvents.forEach((qosEvent) => {
      this.trackYMQosEvent(qosEvent.type);
      this.rootStore.newRelic.reportEvent({ ...qosEvent });
    });
  }

  private trackYMQosEvent(qosEventType: QoSEventType): void {
    try {
      if (window.ym) {
        window.ym(this.ymTrackerId, 'reachGoal', qosEventType);
      }
    } catch (error) {
      logger.error('Failed to track QoS event', { error, qosEventType });
    }
  }
}

export default new QualityOfService();
