import { useEffect, useState } from 'react';
import { MatchMedia } from 'ui-kit/theme';

const useMediaQuery = (mediaQuery: string = MatchMedia.Width640, callback?: (hasMatch: boolean) => void) => {
  const [hasMatch, setHasMatch] = useState(false);

  useEffect(() => {
    const handleMediaQueryEvent = (mqList: MediaQueryListEvent | MediaQueryList) => {
      setHasMatch(mqList.matches);
      callback?.(mqList.matches);
    };

    const mqList = window.matchMedia(mediaQuery);
    mqList.addEventListener('change', handleMediaQueryEvent);
    handleMediaQueryEvent(mqList);

    return () => {
      mqList.removeEventListener('change', handleMediaQueryEvent);
    };
  }, []);

  return hasMatch;
};

export default useMediaQuery;
