import styled from '@emotion/styled';
import { IconButton as ButtonBase } from 'ui-kit';

export const Layout = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  position: 'absolute',
  inset: 0,
});

export const Button = styled(ButtonBase)({
  width: 'auto',
  padding: '0 50px',
});
