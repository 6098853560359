import React, { VoidFunctionComponent } from 'react';
import isMobile from 'is-mobile';

import SignUpStep2PageDesktop from './SignUpStep2PageDesktop';
import SignUpStep2PageMobile from './SignUpStep2PageMobile';

const SignUpStep2Page: VoidFunctionComponent = () => {
  if (isMobile()) {
    return (
      <SignUpStep2PageMobile />
    );
  }

  return (
    <SignUpStep2PageDesktop />
  );
};

export default SignUpStep2Page;
