import {
  useState, useRef, useCallback,
} from 'react';

import useTimer from './useTimer';

interface ITimeCounter {
  minutes: number,
  seconds: number,
}

const timeSpanToCounter = (timeSpan: number) => {
  const timeSpanInSec = Math.round(timeSpan / 1000);
  const minutes = Math.floor(timeSpanInSec / 60);
  const seconds = timeSpanInSec - (minutes * 60);
  return { minutes, seconds };
};

const useStopWatch = (
  timeOutCallback:()=>void, countDownValueInMs = 0, periodInMs = 1000,
): [typeof startCount, typeof stopCount, ITimeCounter | null] => {
  const [counter, setCounter] = useState<ITimeCounter | null>(null);
  const startTime = useRef(0);

  const tick = useCallback(() => {
    const timeDiff = (Date.now() - startTime.current);
    const timeSpan = countDownValueInMs ? countDownValueInMs - timeDiff : timeDiff;
    if (timeSpan <= 0) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      stopCount();
      timeOutCallback();
      return;
    }

    setCounter(timeSpanToCounter(timeSpan));
  }, []);

  const [startTimer, stopTimer] = useTimer(tick, periodInMs);

  const startCount = () => {
    startTime.current = Date.now();
    setCounter(timeSpanToCounter(countDownValueInMs));
    startTimer();
  };

  const stopCount = () => {
    stopTimer();
    setCounter(null);
  };

  return [startCount, stopCount, counter];
};

export default useStopWatch;
