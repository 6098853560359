import React, {
  FunctionComponent, useEffect, useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import useStores from 'stores/root';
import { Redirect, useRouteMatch } from 'react-router-dom';

import { getUrlWithUTM } from 'helpers/utm';
import { getUrlParam } from 'helpers/url';
import { RoutePath } from 'types/common';

const GuestOnly:FunctionComponent = observer(({ children }) => {
  const { authStore } = useStores();
  const [redirectTo, setRedirectTo] = useState<string>();
  const match = useRouteMatch();

  const redirectIfNeeded = () => {
    if (authStore.isUser && match.path !== RoutePath.SignIn) {
      const to = getUrlParam('to') || '/';

      const urlSearchParams = new URLSearchParams(window.location.search);
      urlSearchParams.delete('to');

      const search = urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : '';

      setRedirectTo(to + search);
    }
  };

  useEffect(() => {
    redirectIfNeeded();
  }, [authStore.isTokenFetched]);

  useEffect(() => {
    redirectIfNeeded();
  }, []);

  if (redirectTo) {
    return (
      <Redirect push to={getUrlWithUTM(redirectTo)} />
    );
  }

  return (
    <>
      {children}
    </>
  );
});

export default GuestOnly;
