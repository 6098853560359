import React, { FunctionComponent, useRef } from 'react';
import UserMenu from '../../UserMenu';
import { UserButtonProps } from '../types';

import { UserButtonLayout, User, UserAvatar } from './Layout';

const UserButtonMobile: FunctionComponent<UserButtonProps> = ({
  name = '', icon, handleMenuOpen, handleMenuClose, isMenuOpen, ...restProps
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <UserButtonLayout {...restProps}>
      <User ref={ref} id="basic-user-menu" onClick={handleMenuOpen}>
        <UserAvatar userName={name} imageUrl={icon} fixedSize />
      </User>
      <UserMenu
        isVisible={isMenuOpen}
        anchor={ref}
        onClose={handleMenuClose}
      />
    </UserButtonLayout>
  );
};

export default UserButtonMobile;
