import { makeAutoObservable, reaction } from 'mobx';
import { toast, Id } from 'react-toastify';
import { addSeconds, differenceInSeconds } from 'date-fns';

import { toWindowFeatures } from 'helpers/common';
import { getBrandOptions } from 'modules/Branding';
import timeLimitWarning from 'components/Toasts/TimeLimitWarning/';

import { CallDataRestrictions, CallLimitParams } from './types';

class CallLimit {
  private params: CallLimitParams;

  private reactionDisposers: (() => void)[] = [];

  private timerId: NodeJS.Timeout | null = null;

  private intervalId: NodeJS.Timeout | null = null;

  snackbarId: Id | null = null;

  constructor(params: CallLimitParams) {
    this.params = params;
    this.initReactions();
    makeAutoObservable(this);
  }

  dispose() {
    this.stopTimer();
    this.reactionDisposers.forEach((disposer) => disposer());
  }

  initReactions() {
    const { calls } = this.params;
    this.reactionDisposers = [
      reaction(
        () => calls.callData?.restrictions,
        (restrictions) => {
          if (this.isEmptyRestrictions(restrictions)) {
            this.stopTimer();
          } else {
            this.startTimer();
          }
        },
      ),
    ];
  }

  isEmptyRestrictions(restrictions?: CallDataRestrictions): boolean {
    return !restrictions?.kickOutTimeSec || !restrictions?.timeToRoomKickOutNotificationSec;
  }

  private getRemainingTimeFromNowInSec(startTime: Date, delayInSec: number) {
    const endTime = addSeconds(new Date(startTime), delayInSec);
    const time = differenceInSeconds(endTime, new Date());
    return time > 0 ? time : 0;
  }

  startTimer() {
    const { spaceId } = this.params.room;

    if (!this.params.calls.callData || !spaceId) {
      return;
    }

    const {
      callStartedAt,
      restrictions: { kickOutTimeSec, timeToRoomKickOutNotificationSec },
    } = this.params.calls.callData;

    if (!kickOutTimeSec || !timeToRoomKickOutNotificationSec) {
      return;
    }

    const actualTimeToKickOutSec = this.getRemainingTimeFromNowInSec(callStartedAt, kickOutTimeSec);
    const callEndTime = addSeconds(new Date(callStartedAt), kickOutTimeSec);

    const { isModerator } = this.params.room.rootStore.participantStore;

    const { freePlanAlias, planDescriptions } = getBrandOptions();
    const freePlanName = planDescriptions[freePlanAlias].name;

    const showWarning = () => {
      if (process.env.REACT_APP_FOR_ELECTRON
        && this.params.room.rootStore.uiStore.isDesktopAppSharingActive
        && kickOutTimeSec) {
        const openPayment = (sid: string) => {
          const features = toWindowFeatures({
            width: 1080,
            height: 720,
            left: (window.screen.availWidth / 2) - (1080 / 2),
            top: (window.screen.availHeight / 2) - (720 / 2),
          });

          window.open(`/space/${sid}/plans?noheader`, undefined, features);
        };

        window.electronApi?.timeLimitWarningPaymentRequest(openPayment);

        window.electronApi?.timeLimitWarning({
          kickOutTimeSec,
          callEndTime,
          spaceId,
          isModerator,
          freePlanName,
        });
      } else if (kickOutTimeSec) {
        this.snackbarId = timeLimitWarning({
          spaceId,
          callEndTime,
          kickOutTimeSec,
          isModerator,
          freePlanName,
        });
      }
    };

    this.timerId = setTimeout(() => {
      showWarning();

      if (process.env.REACT_APP_FOR_ELECTRON) {
        this.intervalId = setInterval(() => {
          showWarning();
        }, 60_000);
      }
    }, (actualTimeToKickOutSec - timeToRoomKickOutNotificationSec) * 1000);
  }

  stopTimer() {
    if (this.timerId) {
      clearTimeout(this.timerId);
    }

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    if (this.snackbarId) {
      toast.dismiss(this.snackbarId);
    }
  }
}

export default CallLimit;
