import { getUTMParams } from 'helpers/browser';
import { getLocalStorageItem } from 'helpers/localStorage';

type PlainObject = Record<string, unknown>;

class YandexMetricaClient {
  private readonly ymTrackerId = Number(process.env.REACT_APP_YANDEX_METRICA_TRACKER_ID);

  isLoaded() {
    return window.ym !== undefined;
  }

  trackPageView(url: string) {
    if (window.ym) {
      window.ym(this.ymTrackerId, 'hit', url);
    }
  }

  reachGoal(goalName: string) {
    if (window.ym) {
      window.ym(this.ymTrackerId, 'reachGoal', goalName);
    }
  }

  setParams(params: PlainObject) {
    if (window.ym) {
      window.ym(this.ymTrackerId, 'params', params);
    }
  }

  sendEvent(eventDescriptor: string, value: unknown) {
    const utmParams = getUTMParams();
    const {
      roomId = '',
      spaceId = '',
      participantId = '',
      userId = '',
      roomName = '',
      spaceName = '',
      participantName = '',
      email = '',
    } = window.lsd;

    const eventDetails = { details: window.lsd, referrer: window.document.referrer, value };
    const payload = { ...eventDetails, ...utmParams } as Record<string, unknown>;
    const eventParams = this.makeEventParams(eventDescriptor, payload);

    const params: PlainObject = { global: eventParams };
    params.global = eventParams;

    if (spaceId) {
      params.spaces = { [spaceId]: { [spaceName]: eventParams } };
    }

    if (roomId) {
      params.rooms = { [roomId]: { [roomName]: eventParams } };
    }

    if (userId) {
      params.users = { [userId]: { [email]: eventParams } };
    }

    if (participantId) {
      params.participants = { [participantId]: { [participantName]: eventParams } };
    }

    if (eventDescriptor === 'user:register:success') {
      this.reachGoal('success_registration');
    }

    const isDebugEnabled = window.lsdProductionBuild && getLocalStorageItem('ld_gtm_debug');
    if (isDebugEnabled) {
      // eslint-disable-next-line no-console
      console.log('YMetrika', params);
    }

    this.setParams(params);
  }

  private makeEventParams(eventDescriptor: string, payload: Record<string, unknown>) {
    let curObject: Record<string, unknown> = {};
    const rootObject = curObject;
    const pathElements = eventDescriptor.split(':');

    // convert eventName to nested object, i.e 'a:b:c -> a.b.c'
    for (let i = 0; i < pathElements.length; i += 1) {
      const emptyObj: Record<string, unknown> = {};
      curObject[pathElements[i]] = emptyObj;
      curObject = emptyObj;
    }

    Object.assign(curObject, payload);

    return rootObject;
  }
}

export default YandexMetricaClient;
