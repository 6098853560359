/* Note: carrotquest builtin events starts with '$' */
export enum EventName {
  SignUp = '$registered',
  SignIn = '$authorized',
  SignOut = 'User Logout',
  PageView = '$page_view',
}

export interface TrackUserSignUpParams {
  isEmailSubscriptionEnabled: boolean;
  spaceUrl?: string;
}
