import { HTMLAttributes } from 'react';

import { LogLevel } from 'helpers/logger';
import { LogRecordMeta } from 'types/logger';

export interface ChatProps extends HTMLAttributes<HTMLElement> {
  isVisible: boolean;
}

export interface ChatPropsWithEvents extends ChatProps {
  onErrorHandle?: () => void;
  errorTimeout?: number;
}

export enum ChatEvent {
  MessageSent = 'MessageSent',
  ChatLoaded = 'ChatLoaded',
  ChatClose = 'ChatClose',
  ChatExpand = 'ChatExpand',
  ChatLog = 'ChatLog',
  ChatError = 'ChatError',
  ChatInitError = 'ChatInitError',
  OpenInPopup = 'OpenInPopup',
  ChatIsReady = 'ChatIsReady',
}

export enum ChatMobileVisualState {
  Expand = 'expand',
  Collapse = 'collapse',
  Hidden = 'hidden',
}

export interface ChatLogPayload {
  level: LogLevel,
  message: string,
  meta?: LogRecordMeta,
}

export interface ChatErrorPayload {
  message: string;
  severity: string;
  code: string;
}
