import React, {
  FunctionComponent, MouseEvent, useEffect, useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useTheme } from '@emotion/react';

import useStores from 'stores/root';
import { redirectTo } from 'helpers/url';

import { LogoPlaceholder, LogoLink, Image } from './Layout';

interface LogoProps {
  isClickable?: boolean;
  className?: string;
  id?: string;
  to?: string;
  image?: string;
  brandOnly?: boolean;
}

const Logo: FunctionComponent<LogoProps> = observer(({
  isClickable = false,
  className,
  to = '/',
  image: imageProps,
  brandOnly = false,
  ...props
}) => {
  const {
    spaceStore,
    paymentStore: { currentPlan },
    paymentStore,
  } = useStores();
  const theme = useTheme();
  const isCustomImageAllowed = currentPlan?.features.customSpaceLogo;

  const [logoImage, setLogoImage] = useState<string | undefined>();

  useEffect(() => {
    if (!spaceStore.space) {
      return;
    }

    if (!currentPlan) {
      paymentStore.setCurrentPlan(spaceStore.space.id);
      return;
    }

    const image = spaceStore.space?.logo || imageProps;
    const customImage = isCustomImageAllowed ? image : undefined;
    setLogoImage(customImage);
  }, [currentPlan, spaceStore.space, brandOnly, imageProps]);

  const handleOnClick = (evt:MouseEvent) => {
    if (!isClickable) {
      return;
    }

    evt.preventDefault();
    redirectTo(to);
  };

  if (!currentPlan && !brandOnly) {
    return (
      <LogoPlaceholder className={className} {...props} />
    );
  }

  const isWhiteLabel = !logoImage && isCustomImageAllowed;

  if (isWhiteLabel) {
    return (
      <LogoPlaceholder className={className} {...props} />
    );
  }

  return (
    <LogoLink
      className={className}
      {...props}
      isClickable={isClickable}
      onClick={handleOnClick}
    >
      {logoImage ? <Image src={logoImage} /> : <theme.LogoMax />}
    </LogoLink>
  );
});

export default Logo;
