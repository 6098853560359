import { AxiosInstance, AxiosResponse } from 'axios';

import { IAssetInfo, SignInfo } from 'types/common';
import { getAxiosErrorMessage } from 'helpers/errors';
import { apiCall } from 'decorators';

import { ApiResponse } from './types';
import {
  SignSpaceAssetParams,
  FetchSpaceAssetParams,
  SpaceAsset,
  CreateAssetParams,
} from './AssetsApi.types';

class AssetsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async sign(file: File, directory = 'image'): ApiResponse<SignInfo> {
    try {
      return await this.api.post<SignInfo>('assets/sign', {
        objectName: file.name,
        contentType: file.type,
        directory,
      });
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  @apiCall()
  async signSpaceAsset({
    spaceId, objectName, contentType, kind,
  }: SignSpaceAssetParams): ApiResponse<SignInfo> {
    return this.api.post(`/spaces/${spaceId}/assets/sign`, {
      objectName,
      contentType,
      kind,
    });
  }

  @apiCall()
  async fetchSpaceAsset({ spaceId, assetId }: FetchSpaceAssetParams) {
    return this.api.get<SpaceAsset>(`/spaces/${spaceId}/assets/${assetId}`);
  }

  async createAsset({
    name, fileName, filePath, url, type, kind, spaceId,
  }: CreateAssetParams): ApiResponse<SpaceAsset> {
    try {
      return await this.api.post(`spaces/${spaceId}/assets`, {
        name, fileName, filePath, url, type, kind,
      });
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async updateAsset({ name, id, spaceId }: { name: string, id: string, spaceId: string }): ApiResponse<AxiosResponse> {
    try {
      return await this.api.put(`spaces/${spaceId}/assets/${id}`, { name });
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async deleteAsset({ id, spaceId }: { id: string, spaceId: string }): ApiResponse<AxiosResponse> {
    try {
      return await this.api.delete(`spaces/${spaceId}/assets/${id}`);
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async getAssets({ spaceId, type }: { spaceId: string, type?: string[] }): ApiResponse<IAssetInfo[]> {
    try {
      const response = await this.api.get<{ items: IAssetInfo[] }>(
        `spaces/${spaceId}/assets`,
        {
          params: {
            limit: 100,
          },
        },
      );

      if (response.data?.items && Array.isArray(response.data.items) && response.data.items.length > 0) {
        if (type) {
          return { data: response.data.items.filter((x) => type.includes(x.type)) };
        }

        return { data: response.data.items };
      }

      return { data: [] };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }
}

export default AssetsApi;
