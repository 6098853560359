import { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';

import { getAxiosErrorMessage } from 'helpers/errors';
import { ApiResponse, CallData, PostAnnouncementResponse } from 'services/MoodHoodApiClient/types';
import { Call } from 'types/common';

import logger from 'helpers/logger';
import { Announcement } from 'types/stores/announcements';

const callRetriesConfig = {
  retries: 10,
  retryDelay: (retryCount: number) => retryCount * 500,
  retryCondition: () => true,
};

class CallsApi {
  private readonly api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
    axiosRetry(this.api);
  }

  async fetchActiveCall(spaceId: string, roomId: string): ApiResponse<Call> {
    try {
      const data = await this.api.get<Call>(`spaces/${spaceId}/rooms/${roomId}/active-call`,
        {
          'axios-retry': callRetriesConfig,
        },
      );

      return data;
    } catch (err) {
      const error = getAxiosErrorMessage(err);
      logger.error('Failed to fetch active call', { error });

      return { error };
    }
  }

  async fetchCalls(spaceId: string, roomId: string, date: Date, dateTo?: Date): ApiResponse<Call[]> {
    try {
      const { data: { items } } = await this.api.get<{ items: Call[] }>(
        `/spaces/${spaceId}/analytics/calls`, {
          params: {
            roomId,
            date,
            ...dateTo && { dateTo },
          },
        },
      );

      return { data: items };
    } catch (err) {
      const error = getAxiosErrorMessage(err);
      logger.error('Failed to fetch calls', { error });

      return { error };
    }
  }

  async fetchCallData(spaceId: string, roomId: string): ApiResponse<CallData> {
    try {
      const { data } = await this.api.get<CallData>(`spaces/${spaceId}/rooms/${roomId}/calls/data`,
        {
          'axios-retry': callRetriesConfig,
        },
      );

      return { data };
    } catch (err) {
      const error = getAxiosErrorMessage(err);
      logger.error('Failed to fetch call data', { error });

      return { error };
    }
  }

  async postAnnouncement(
    spaceId: string,
    roomId: string,
    announcement: Announcement,
  ) : ApiResponse<PostAnnouncementResponse> {
    try {
      const { data } = await this.api.post<PostAnnouncementResponse>(
        `spaces/${spaceId}/rooms/${roomId}/calls/announcements`, announcement,
      );

      return { data };
    } catch (err) {
      const error = getAxiosErrorMessage(err);
      logger.error('Failed to post announcement', { error });

      return { error };
    }
  }

  async deleteAnnouncement(spaceId: string, roomId: string, announcementId: string): ApiResponse<void> {
    try {
      await this.api.delete<CallData>(`spaces/${spaceId}/rooms/${roomId}/calls/announcements/${announcementId}`);

      return { };
    } catch (err) {
      const error = getAxiosErrorMessage(err);
      logger.error('Failed to delete announcement', { error });

      return { error };
    }
  }
}

export default CallsApi;
