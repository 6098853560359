import isMobile from 'is-mobile';
import React, {
  FunctionComponent, useCallback, useState,
} from 'react';

import UserButtonDesktop from './Desktop';
import UserButtonMobile from './Mobile';

interface UserButtonProps {
  name?: string;
  icon?: string | null;
  isCompact?: boolean;
}

const UserButton: FunctionComponent<UserButtonProps> = ({
  name = '', icon, isCompact, ...restProps
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuOpen = useCallback(() => setIsMenuOpen(true), []);
  const handleMenuClose = useCallback(() => setIsMenuOpen(false), []);

  if (isMobile()) {
    return (
      <UserButtonMobile
        name={name}
        icon={icon}
        handleMenuOpen={handleMenuOpen}
        handleMenuClose={handleMenuClose}
        isMenuOpen={isMenuOpen}
        {...restProps}
      />
    );
  }

  return (
    <UserButtonDesktop
      name={name}
      icon={icon}
      handleMenuOpen={handleMenuOpen}
      handleMenuClose={handleMenuClose}
      isMenuOpen={isMenuOpen}
      isCompact={isCompact}
      {...restProps}
    />
  );
};

export default UserButton;
