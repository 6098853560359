import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useStores from 'stores/root';
import RedirectPage from 'components/RedirectPage';
import { getUrlParam } from 'helpers/url';
import { Errors } from 'constants/errors';

const ChangeEmailConfirm: FunctionComponent = () => {
  const { t } = useTranslation();
  const confirmationToken = getUrlParam('confirm');
  const [isSuccessfulChanged, setIsSuccessfulChanged] = useState(false);
  const { userStore, uiStore } = useStores();
  const canStartCountdown = !isSuccessfulChanged || !!userStore.emailUpdatingError;

  useEffect(() => {
    if (!confirmationToken) {
      uiStore.showErrorPage(Errors.CONFIRMATION_TOKEN_INVALID);
      return;
    }

    (async () => {
      await userStore.finishUpdateUserEmail(confirmationToken);

      if (userStore.emailUpdatingError) {
        uiStore.showErrorPage(userStore.emailUpdatingError);
        return;
      }

      setIsSuccessfulChanged(true);
    })();
  }, [confirmationToken]);

  return (
    <RedirectPage waiting={canStartCountdown} title={t('userProfile.emailChanged')} />
  );
};

export default ChangeEmailConfirm;
