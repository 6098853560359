import {
  action, computed, makeAutoObservable, observable,
} from 'mobx';
import axios from 'axios';
import { eventBus } from 'mobx-event-bus2';

import { AppEvent, TSpaceInfo } from 'types/common';
import type PeerStore from 'stores/peer';
import type Metrics from 'services/Metrics';
import logger from 'helpers/logger';
import { Errors } from 'constants/errors';
import InviteApi from 'services/MoodHoodApiClient/InviteApi';
import Api from 'services/MoodHoodApiClient/Api';
import UserApi from 'services/MoodHoodApiClient/UserApi';

import { SpaceRole } from '../services/MoodHoodApiClient/types';

interface ActivateInviteForSignedUpUserParams {
  inviteId: string;
  inviteSpaceId: string;
  email: string;
}

class SpaceStore {
  @observable space: TSpaceInfo | null = null;

  @observable peers: Map<string, PeerStore> = new Map<string, PeerStore>();

  isInviteActivated = false;

  metrics: Metrics;

  inviteApi: InviteApi;

  userApi: UserApi;

  constructor(metrics: Metrics) {
    this.metrics = metrics;
    this.inviteApi = new InviteApi(Api);
    this.userApi = new UserApi(Api);
    makeAutoObservable(this);
  }

  @computed get isSpaceOwner(): boolean {
    return this.space?.roleInSpace === SpaceRole.Owner;
  }

  @action reset(): void {
    this.space = null;
    this.peers = new Map<string, PeerStore>();
  }

  @action clearPeers(): void {
    this.peers = new Map<string, PeerStore>();
  }

  hasPeer(peerId: string): boolean {
    return this.peers.has(peerId);
  }

  @action setPeer(peerStore: PeerStore): void {
    if (this.hasPeer(peerStore.id)) {
      return;
    }

    this.peers.set(peerStore.id, peerStore);
  }

  @action deletePeer(peerId: string): void {
    const peer = this.peers.get(peerId);
    if (peer) {
      peer?.reset();
      this.peers.delete(peerId);
    }
  }

  @action setSpaceInfo(spaceInfo: { space: TSpaceInfo }): void {
    const { space } = spaceInfo;
    this.space = space;
    window.lsd.spaceId = space?.id;
    window.lsd.spaceName = space?.name;
  }

  @action async activateSpaceInviteForJustSignedUpUser(params: ActivateInviteForSignedUpUserParams): Promise<{
    spaceId?: string;
    skipInitialSpaceCreation: boolean;
  }> {
    const { email, inviteId, inviteSpaceId } = params;
    let spaceId: string | undefined;
    let skipInitialSpaceCreation = false;

    if (!(inviteId && inviteSpaceId)) {
      return { spaceId: undefined, skipInitialSpaceCreation };
    }

    try {
      await this.performInviteActivation(inviteSpaceId, inviteId);
      logger.debug('Activated invite for space', { inviteSpaceId, inviteId, email });

      skipInitialSpaceCreation = await this.userApi.checkIsSpaceModerator(inviteSpaceId);
      spaceId = inviteSpaceId;
    } catch (error: unknown) {
      logger.error('Failed to handle invite activation', { error, email });
    }

    return { spaceId, skipInitialSpaceCreation };
  }

  @action async activateSpaceInvite(spaceId: string, inviteId: string): Promise<void> {
    try {
      await this.performInviteActivation(spaceId, inviteId);
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 404) {
          eventBus.post(AppEvent.Error, Errors.INVITE_EXPIRED_OR_NOT_EXIST);
          return;
        }

        if (err.response?.status === 409) {
          eventBus.post(AppEvent.Error, Errors.INVITE_CONFLICT);
          return;
        }
      }

      eventBus.post(AppEvent.Error, Errors.SOMETHING_WENT_WRONG);
    }
  }

  @action async performInviteActivation(spaceId: string, inviteId: string): Promise<void> {
    await this.inviteApi.activateInvite(spaceId, inviteId);
    this.metrics.marketing.trackInviteActivation(inviteId);
    this.setInviteActivated(true);
  }

  @action setInviteActivated(isActivated: boolean): void {
    this.isInviteActivated = isActivated;
  }

  @action resetInviteStatus(): void {
    this.setInviteActivated(false);
  }
}

export default SpaceStore;
