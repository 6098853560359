import React, {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageLayout from 'components/PageLayout';
import Plans from 'modules/Plans';
import useStores from 'stores/root';
import CongratsDialog from 'components/Payment/CongratsDialog';
import { getBrandOptions } from 'modules/Branding';

import PlansList from './components/PlansList';

const PlansPage: FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const { spaceStore, paymentStore } = useStores();
  const { spaceId } = useParams<{ spaceId: string }>();
  const { search } = useLocation();
  const plans = useRef<Plans>();
  const [noHeader, setNoHeader] = useState(false);

  useEffect(() => {
    if (spaceStore.space) {
      plans.current = new Plans({ space: spaceStore.space, payments: paymentStore });
      plans.current.load();
    }
  }, [spaceStore.space]);

  const { planDescriptions, freePlanAlias } = getBrandOptions();

  useEffect(() => {
    const searchParams = new URLSearchParams(search.toLowerCase());
    setNoHeader(searchParams.has('noheader'));
  }, [search]);

  return (
    <PageLayout spaceId={spaceId} noHeader={noHeader}>
      <PlansList
        plans={plans.current?.plansInfo || []}
        currentPlanName={plans.current?.currentPlan?.name || planDescriptions[freePlanAlias].name}
        periodDiscounts={{ year: t('payment.discountUpTo', { discount: -20 }) }}
      />
      {paymentStore.isPaymentCongratsDialogOpen && (
        <CongratsDialog />
      )}
    </PageLayout>
  );
});

export default PlansPage;
