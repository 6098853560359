import styled from '@emotion/styled';

import {
  ZIndex, Size, MediaBreakPoint,
} from 'ui-kit/theme';
import LogoBase from 'components/Logo';
import Navigation from '../Navigation';

export const Logo = styled(LogoBase)({
  marginRight: 'auto',
});

export const LeftSideWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const BasicHeaderDesktopRoot = styled.div(({ theme }) => ({
  position: 'fixed',
  zIndex: ZIndex.HEADER,
  backgroundColor: theme.colors.bg,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gridGap: '12px',
  gridArea: 'header',
  width: '100%',
  minHeight: Size.BASIC_HEADER_DESKTOP_HEIGHT,
  [MediaBreakPoint.WIDTH_640]: {
    minHeight: Size.BASIC_HEADER_MOBILE_HEIGHT,
  },
}));

export const DesktopBreadcrumbs = styled(Navigation)({
  marginLeft: 16,
  [MediaBreakPoint.WIDTH_640]: {
    display: 'none',
  },
});

export const CenterContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: 1272,
  margin: '0 auto',
  padding: '0 24px',
});

export const Buttons = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  alignItems: 'center',
});
