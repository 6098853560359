import { AxiosInstance, AxiosResponse } from 'axios';

import { getAxiosErrorMessage } from 'helpers/errors';
import { getPageUTMParams } from 'helpers/utm';
import { UTMParams } from 'helpers/types';
import { isObjectEmpty } from 'helpers/common';

import {
  ApiResponse,
  CreateUserContactParams,
  IUserInfo,
  UpdateChatUserProfileRequestProtected,
  GetOrCreateRoomChatParams,
  GetOrCreateEventChatParams,
  GetOrCreateEventPreviewChatParams,
} from './types';

interface IUserInfoUpdate extends Partial<Omit<IUserInfo, 'id'>> {
  password?: string;
  targetUse?: string;
  referralCode?: string;
}

interface RequestPasswordChangePayload {
  email: string;
  captchaToken: string;
  forgotPassword?: boolean;
}

type TObject = 'participant' | 'room' | 'space' | 'invite' | 'user';
type TAction = 'create' | 'update' | 'read' | 'delete' | 'list';

class UserApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  createUser({
    username,
    email,
    password,
    phone,
    captchaToken,
    utm,
    meta,
  }: {
    username: string,
    email: string,
    password: string,
    phone: string,
    captchaToken?: string,
    utm: Partial<UTMParams>,
    meta?: Record<string, unknown>
  }): Promise<AxiosResponse> {
    return this.api.post('users', {
      username,
      email,
      password,
      phone: phone?.trim() || undefined,
      captchaToken,
      ...((meta && !isObjectEmpty(meta)) && { meta }),
      ...utm,
    });
  }

  async getUserInfo(): Promise<IUserInfo> {
    const { data } = await this.api.get<IUserInfo>('users/me');
    return data;
  }

  async getOrCreateRoomChatToken(
    {
      chatApplicationId,
      spaceId,
      roomId,
      userId,
      clientUniqueId,
      username,
    }: GetOrCreateRoomChatParams,
  )
    : Promise<{ userToken?: string, error?: string }> {
    try {
      const { data } = await this.api.get<{ userToken: string }>(
        `users/me/chat-token/${chatApplicationId}`, {
          params: {
            spaceId,
            roomId,
            userId,
            username,
            clientUniqueId,
          },
        },
      );

      return data;
    } catch (err) {
      return { error: getAxiosErrorMessage(err) };
    }
  }

  async getOrCreateEventChatToken({
    id,
    username,
    clientUniqueId,
  } : GetOrCreateEventChatParams)
    : Promise<{ userToken?: string, error?: string }> {
    try {
      const { data } = await this.api.get<{ userToken: string }>(
        `events/${id}/chat-user-token`, {
          params: {
            username,
            clientUniqueId,
          },
        },
      );

      return data;
    } catch (err) {
      return { error: getAxiosErrorMessage(err) };
    }
  }

  async getOrCreateEventPreviewChatToken({
    id,
    spaceId,
    username,
    clientUniqueId,
  } : GetOrCreateEventPreviewChatParams)
    : Promise<{ userToken?: string, error?: string }> {
    try {
      const { data } = await this.api.get<{ userToken: string }>(
        `spaces/${spaceId}/playbacks/${id}/chat-user-token`, {
          params: {
            username,
            clientUniqueId,
          },
        },
      );

      return data;
    } catch (err) {
      return { error: getAxiosErrorMessage(err) };
    }
  }

  async updateUserProfile({
    email,
    password,
    username,
    image,
    isDebugger,
    targetUse,
    referralCode,
  }: IUserInfoUpdate): Promise<AxiosResponse> {
    const response = await this.api.put('users/me', {
      email,
      password,
      username,
      image,
      isDebugger,
      targetUse,
      referralCode,
    });

    return response;
  }

  async updateChatUserProfile(payload: UpdateChatUserProfileRequestProtected): ApiResponse<undefined> {
    const { chatToken, ...rest } = payload;
    try {
      await this.api.put(`users/me/update-chat-user/${chatToken}`, rest);
      return {};
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async checkPermission(spaceId:string, object: TObject, action:TAction, objectId?:string): Promise<boolean> {
    try {
      const { status } = await this.api.get('users/me/can', {
        params: {
          spaceId, object, objectId, action,
        },
      });

      return status === 200;
    } catch (err) {
      return false;
    }
  }

  async checkIsSpaceModerator(spaceId:string): Promise<boolean> {
    return this.checkPermission(spaceId, 'space', 'update');
  }

  async requestChangeEmail(newEmail: string, captchaToken: string): Promise<AxiosResponse> {
    const response = await this.api.post('users/me/request-change-email', {
      newEmail, captchaToken,
    });
    return response;
  }

  async changeEmail(confirmationToken: string): Promise<AxiosResponse> {
    const response = await this.api.post('users/me/change-email', { confirmationToken });
    return response;
  }

  async requestChangePassword(payload: RequestPasswordChangePayload): Promise<AxiosResponse> {
    const { email, captchaToken, forgotPassword } = payload;
    const response = await this.api.post('users/me/request-change-password', {
      email,
      captchaToken,
      forgotPassword,
    });
    return response;
  }

  async changePassword(password: string, confirmationToken: string): Promise<AxiosResponse> {
    const response = await this.api.post('users/me/change-password', { password, confirmationToken });
    return response;
  }

  async deleteUserAvatar(): Promise<{ error?: string }> {
    try {
      await this.api.delete('users/me/image');
      return {};
    } catch (err: unknown) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async createUserContact({ isEmailSubscriptionEnabled, spaceUrl }
  : CreateUserContactParams): Promise<{ error?: string }> {
    /* real users only - carrotquest gtm trigger wont load it for staging and stress tests */
    if (!window.carrotquest) {
      return {};
    }

    try {
      const utm = getPageUTMParams();
      const gaClientId = (window.ga?.getAll()?.[0]?.get('clientId'));
      await this.api.post('users/me/create-contact', {
        spaceUrl,
        isEmailSubscriptionEnabled,
        gaClientId,
        utm,
      });
      return {};
    } catch (error) {
      return {
        error: getAxiosErrorMessage(error),
      };
    }
  }
}

export default UserApi;
