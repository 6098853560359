import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import useStores from 'stores/root';
import { IconButton } from 'ui-kit';

const IntegrationButton: FunctionComponent = observer(() => {
  const { uiStore, authStore } = useStores();
  const { t } = useTranslation();

  const handleOpenIntegrationDialog = () => {
    uiStore.setIsIntegrationDialogOpen(true);
  };

  if (!authStore.isUser) {
    return null;
  }

  return (
    <IconButton
      id="integration"
      onClick={handleOpenIntegrationDialog}
      text={`${t('common.integration')}`}
    />
  );
});

export default IntegrationButton;
