import styled from '@emotion/styled';

import { IconButton } from 'ui-kit';
import { Font, getObjectTextStyle } from 'ui-kit/theme';

import { InputProps, SizeVariant, VisualState } from './types';

export const Label = styled.label(({ theme }) => ({
  display: 'block',
  fontFamily: Font.BRAND_BOLD,
  marginBottom: 4,
  color: theme.colors.accent04,
}));

export const InputBox = styled.input<InputProps>(({ theme }) => ({
  width: '100%',
  minWidth: 0,
  margin: '0 4px',
  color: theme.colors.primary,
  ...getObjectTextStyle('main-text-medium'),
  '&::placeholder': {
    color: theme.colors.secondary03,
    opacity: '1',
    ...getObjectTextStyle('main-text-medium'),
  },
  '&:disabled': {
    color: theme.colors.secondary03,
  },
}));

export const Hint = styled.div({
  marginTop: 4,
});

export const ClearButton = styled(IconButton)({
  flexShrink: 0,
  marginRight: 2,
});

export const InputContainer = styled.div<{ variant: SizeVariant, showClearButton?: boolean }>(({
  theme, variant, showClearButton,
}) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  gap: 4,
  ...(variant === 'medium' && { padding: '0 7px', height: 36 }),
  ...(variant === 'large' && { padding: '0 16px', height: 48 }),
  border: '1px solid',
  borderRadius: 10,
  borderColor: 'var(--border-color)',
  transition: 'all 0.3s, padding 0s',
  background: theme.colors.bg,
  '&:not(:has(input:focus)):not(:has(input:disabled)):hover': {
    borderColor: 'var(--border-color)',
    boxShadow: '0px 0px 0px 1px var(--border-color)',
  },
  '&:has(input:focus)': {
    borderColor: theme.colors.accent04,
    boxShadow: `0px 0px 0px 1px ${theme.colors.accent04}`,
  },
  '&:has(input:disabled)': {
    background: theme.colors.secondary01,
    borderColor: theme.colors.secondary01,
    color: theme.colors.secondary03,
    [ClearButton as never]: {
      display: 'none',
    },
  },
  '&:has(input:placeholder-shown)': {
    [ClearButton as never]: {
      display: 'none',
    },
  },
  '&:not(:has(input:placeholder-shown))': {
    ...(showClearButton && {
      paddingRight: 8,
    }),
  },
  '& > *:first-child:not(input)': {
    flexShrink: 0,
  },
  '& > *:last-child:not(input)': {
    flexShrink: 0,
  },
}));

export const InputLayout = styled.div<{ state?: VisualState, fullWidth?: boolean }>(({ theme, state, fullWidth }) => ({
  ...(fullWidth && { width: '100%' }),
  ...getObjectTextStyle('label-text-medium'),
  '--border-color': theme.colors.secondary03,
  ...(state === 'success' && { '--border-color': theme.colors.successBase }),
  ...(state === 'error' && { '--border-color': theme.colors.errorBase }),
  color: theme.colors.secondaryBase,
  '&:has(input:focus) > label': {
    color: theme.colors.accent04,
  },
  [Hint as never]: {
    ...(state && { color: 'var(--border-color)' }),
  },
}));

export const Controls = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: 8,
});
