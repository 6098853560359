import {
  action, computed, makeAutoObservable, observable, reaction,
} from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import { lock } from 'decorators';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from 'helpers/localStorage';
import { getClientUniqueId } from 'helpers/common';
import RoomApi from 'services/MoodHoodApiClient/RoomApi';
import api from 'services/MoodHoodApiClient/Api';
import {
  MiroBoardSlotData, SlotDataType, TrackLabel,
} from 'services/MoodHoodApiClient/types';
import { ChannelEvent } from 'types/socket';
import logger from 'helpers/logger';

import {
  MiroDemoData,
  MiroBoardLocalStorage,
  MiroBoardParams,
  Demonstration,
  DemonstrationType,
  DemonstrationUpdatedEvent,
  MiroSlot,
} from '../types';

const MIRO_LS_KEY = 'ld-miro-board';
class MiroBoard {
  @observable isStartMiroBusy = false;

  @observable isStopMiroBusy = false;

  @observable error: string | null = null;

  @observable slot: MiroSlot | null = null;

  private roomApi = new RoomApi(api);

  private params: MiroBoardParams;

  private reactionDisposers: (() => void)[] = [];

  localStorageValue: MiroBoardLocalStorage | null = null;

  constructor(params: MiroBoardParams) {
    this.params = params;
    makeAutoObservable(this);
    this.initReactions();
    eventBus.register(this);
    this.localStorageValue = getLocalStorageItem<MiroBoardLocalStorage>(MIRO_LS_KEY);
  }

  dispose() {
    this.reactionDisposers.forEach((disposer) => disposer());
    this.reset();
  }

  @action resetError() {
    this.error = null;
  }

  get isStartedByMe() {
    if (!this.localStorageValue) {
      return false;
    }

    const { started, isBreakoutRoom } = this.localStorageValue;

    if (isBreakoutRoom !== this.params.room.isBreakoutRoom) {
      return false;
    }

    return started.demonstrationId === this.demonstrationId;
  }

  @action reset() {
    this.slot = null;
    this.resetError();
  }

  @lock('isStartMiroBusy')
  @action async startMiro(meta: MiroBoardSlotData) {
    const { room } = this.params;
    const { spaceId, id: roomId } = room;

    if (!spaceId || !roomId) {
      logger.error('MiroBoard start: no room info', { case: 'MiroBoard.start' });
      throw new Error('MiroBoard start: no room info');
    }

    const clientUniqueId = getClientUniqueId();

    this.localStorageValue = null;
    removeLocalStorageItem(MIRO_LS_KEY);

    const { data, error } = await this.roomApi.startMiro({
      spaceId,
      roomId,
      clientUniqueId,
      meta,
    });

    if (error) {
      this.handleError('room.demo.boardStartError');
    }

    if (data) {
      this.localStorageValue = {
        started: {
          demonstrationId: data.startedDemonstrationId,
          meta,
        },
        isBreakoutRoom: this.params.room.isBreakoutRoom,
      };

      setLocalStorageItem<MiroBoardLocalStorage>(MIRO_LS_KEY, this.localStorageValue);
    }
  }

  @lock('isStopMiroBusy')
  @action async stopMiro() {
    const { room } = this.params;
    const { spaceId, id: roomId } = room;

    if (!spaceId || !roomId) {
      logger.error('MiroBoard stop: no room info', { case: 'MiroBoard.stop' });
      throw new Error('MiroBoard stop: no room info');
    }

    if (!this.canStopMiroBoard) {
      throw new Error('MiroBoard stop: can not stop MiroBoard');
    }

    if (!this.demonstrationId) {
      throw new Error('MiroBoard stop: no miro slot');
    }

    const { error } = await this.roomApi.stopMiro({
      spaceId,
      roomId,
      demonstrationId: this.demonstrationId,
      clientUniqueId: getClientUniqueId(),
    });

    if (error) {
      this.handleError('room.demo.broadcastStopError');
    }

    this.slot = null;

    removeLocalStorageItem(MIRO_LS_KEY);
  }

  get demonstrationId() {
    return this.slot?.slotAssignment.slotUuid;
  }

  @computed get canStopMiroBoard() {
    const { spaceId, id: roomId, myPeer } = this.params.room;

    if (!spaceId || !roomId) {
      throw new Error('Room info not available');
    }

    if (!this.slot) {
      return false;
    }

    if (!this.demonstrationId) {
      return false;
    }

    if (myPeer?.isModerator) {
      return true;
    }

    const data = this.localStorageValue;

    if (!data) {
      return false;
    }

    const { started } = data;

    return started.demonstrationId === this.demonstrationId;
  }

  @computed get canStartMiroBoard() {
    const { isDemoCountLimitExceed } = this.params.room;

    if (isDemoCountLimitExceed) {
      return false;
    }

    return !this.slot;
  }

  @observable initiated = false;

  @observable isBreakoutRoom: boolean | undefined = undefined;

  @action private initReactions() {
    const { room } = this.params;
    this.reactionDisposers = [
      reaction(
        () => ({
          isRoomJoined: room.isRoomJoined,
          isRoomReJoined: room.isRoomReJoined,
          callData: room.calls.callData,
          isPeersLoaded: room.isPeersAndParticipantsLoaded,
          isBreakoutRoom: room.isBreakoutRoom,
        }),
        ({
          isRoomJoined,
          isRoomReJoined,
          callData,
          isPeersLoaded,
          isBreakoutRoom,
        }) => {
          if (this.initiated && this.isBreakoutRoom !== undefined && this.isBreakoutRoom !== isBreakoutRoom) {
            this.initiated = false;
          }

          if ((isRoomJoined || isRoomReJoined) && callData && isPeersLoaded && !this.initiated) {
            this.initiated = true;
            this.isBreakoutRoom = isBreakoutRoom;
            this.reset();
            this.updateParticipantSlots(callData.demonstrations);
          }
        },
      ),
    ];
  }

  private updateParticipantSlots(demonstrations: Demonstration[]) {
    const miroDemo = demonstrations.find((demo) => demo.type === DemonstrationType.Miro);

    if (!miroDemo) {
      return;
    }

    this.addMiroBoardSlot(miroDemo as MiroDemoData);
  }

  @action addMiroBoardSlot(demo: MiroDemoData) {
    if (this.slot) {
      logger.error('MiroBoard already exists', { demo, case: 'MiroBoard.addMiroBoardSlot' });
      throw new Error('MiroBoard: already have board');
    }

    this.slot = {
      peer: null,
      trackLabel: TrackLabel.Board,
      hasVideo: false,
      slotAssignment: {
        trackLabel: TrackLabel.Board,
        slotUuid: demo.id,
        data: {
          type: SlotDataType.MiroBoard,
          id: demo.meta.id,
          accessLink: demo.meta.accessLink,
          name: demo.meta.name,
          viewLink: demo.meta.viewLink,
        },
      },
      isOutOfScreen: false,
    };
  }

  private removeMiroBoardSlot(demonstrationId: string) {
    if (this.demonstrationId !== demonstrationId) {
      return;
    }

    this.reset();
  }

  @subscribe(ChannelEvent.DemonstrationsUpdated)
  @action handleDemonstrationsUpdate(evt: DemonstrationUpdatedEvent) {
    if (this.isStartMiroBusy) {
      const tid: NodeJS.Timeout = setTimeout(() => {
        clearTimeout(tid);
        this.handleDemonstrationsUpdate(evt);
      }, 100);

      return;
    }

    logger.info('MiroBoard updated', { evt, case: 'MiroBoard.updated' });
    const {
      startedDemonstrationId,
      stoppedDemonstrationId,
      allDemonstrations,
    } = evt.payload;

    if (startedDemonstrationId) {
      const demonstration = allDemonstrations.find(({ id }) => id === startedDemonstrationId);

      if (!demonstration) {
        logger.error('Demonstration not found', { evt, case: 'MiroBoard.updated' });
        throw new Error('MiroBoard: Demonstration not found');
      }

      if (demonstration.type !== DemonstrationType.Miro) {
        return;
      }

      this.addMiroBoardSlot(demonstration);
    } else if (stoppedDemonstrationId) {
      this.removeMiroBoardSlot(stoppedDemonstrationId);
    }
  }

  private handleError(userError: string) {
    this.error = userError;
  }
}

export default MiroBoard;
