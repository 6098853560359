import UAParser from 'ua-parser-js';
import cookies from 'js-cookie';
import isMobile from 'is-mobile';
import addYears from 'date-fns/addYears';

import { UserAgentInfo } from 'services/MoodHoodAnalyticsApiClient/types';

import { getElectronAppVersion } from './common';

export const MIN_SUPPORTED_SAFARI_VERSION = '15.4';

const brandName = process.env.REACT_APP_BRAND as string;

export const getUserAgentInfo = (): Required<UserAgentInfo> => {
  const info = UAParser();

  const deviceType = process.env.REACT_APP_FOR_ELECTRON
    ? 'desktop-app'
    : (info.device.type || 'desktop');

  const browser = process.env.REACT_APP_FOR_ELECTRON
    ? (brandName || 'unknown')
    : (info.browser.name || 'unknown');

  const browserVersion = process.env.REACT_APP_FOR_ELECTRON
    ? (getElectronAppVersion() || 'unknown')
    : (info.browser.version || 'unknown');

  return {
    deviceType,
    deviceModel: 'unknown',

    browser,
    browserVersion,

    platform: info.os.name || 'unknown',
    platformVersion: info.os.version || 'unknown',

    userAgent: info.ua || 'unknown',
  };
};

export const getUTMParams = () => {
  const UTM_KEYS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_id', 'utm_content'];
  const urlParams = new URLSearchParams(window.location.search);
  return UTM_KEYS.reduce((acc, key) => {
    acc[key] = urlParams.get(key) || '';
    return acc;
  }, {} as Record<string, string>);
};

export const isInsideIFrame = (): boolean => typeof window === 'object' && (
  window !== window.parent
  || window.location?.search.includes('iframe=1')
  || window.location !== window.parent.location
);

export const getNewAudioContext = (contextOptions?: AudioContextOptions): AudioContext | null => {
  const Context = window.AudioContext // Default
    || window.webkitAudioContext // Safari and old versions of Chrome
    || false;

  if (Context) {
    return new Context(contextOptions);
  }

  return null;
};

interface OSRequirementsResult {
  isUnSupportedOS: boolean,
  requirements?: {
    os: string,
    minVersion: string,
  }
}

const isOlderVersion = (version: string, minVersion: string) => {
  const [major, minor, bugfix] = version.split('.').map(Number);
  const [minMajor, minMinor, minBugfix] = minVersion.split('.').map(Number);
  if (major === minMajor) {
    if (minor === minMinor) {
      return bugfix < minBugfix;
    }

    return minor < minMinor;
  }

  return major < minMajor;
};

export const checkOSRequirements = (): OSRequirementsResult => {
  const OS_MIN_VERSION = {
    Android: '9',
    'Android-x86': '9',
    iOS: '12.5.5',
  };

  try {
    const { os } = UAParser();
    if (os.version && os.name) {
      switch (os.name) {
        case 'Android':
        case 'Android-x86':
          return {
            isUnSupportedOS: isOlderVersion(os.version, OS_MIN_VERSION[os.name]),
            requirements: { os: os.name, minVersion: OS_MIN_VERSION[os.name] },
          };
        case 'iOS':
          return {
            isUnSupportedOS: isOlderVersion(os.version, OS_MIN_VERSION[os.name]),
            requirements: { os: os.name, minVersion: OS_MIN_VERSION[os.name] },
          };
        default:
          return { isUnSupportedOS: false };
      }
    }
  } catch {
    return { isUnSupportedOS: false };
  }

  return { isUnSupportedOS: false };
};

export const isSafari = (): boolean => /apple/i.test(navigator.vendor);

export const isOldSafari = (minVersion: string): boolean => {
  const userAgent = getUserAgentInfo();
  if (!isSafari() || !userAgent?.browserVersion) {
    return false;
  }

  return isOlderVersion(userAgent.browserVersion, minVersion);
};

export const isYandexBrowser = (): boolean => /YaBrowser/i.test(navigator.userAgent);

export const isChromeBasedBrowser = (): boolean => /Chrome/i.test(navigator.userAgent);

export const isHeadlessChrome = (): boolean => /HeadlessChrome/i.test(navigator.userAgent);

export const isChrome = getUserAgentInfo().browser === 'Chrome';

const isFirefox = (): boolean => /Firefox/i.test(navigator.userAgent);

const isOpera = (): boolean => /Opera|OPR/i.test(navigator.userAgent);

export const isIOS = getUserAgentInfo().platform === 'iOS';

export const getOS = (): 'Windows' | 'Mac' | 'Linux' | undefined => {
  if (navigator.userAgent.indexOf('Win') !== -1) {
    return 'Windows';
  }

  if (navigator.userAgent.indexOf('Mac') !== -1) {
    return 'Mac';
  }

  if (navigator.userAgent.indexOf('Linux') !== -1) {
    return 'Linux';
  }

  return undefined;
};

export const isPageNavigatedByReload = () => (
  (window.performance.navigation && window.performance.navigation.type === 1)
    || window.performance
      .getEntriesByType('navigation')
      .map((nav) => nav.entryType)
      .includes('reload')
);

export const isMobileSafari = (): boolean => isMobile() && isSafari();

export const enterPageFullScreen = async (element?: HTMLElement | null, options?: FullscreenOptions): Promise<void> => {
  if (isSafari()) {
    // eslint-disable-next-line
    // @ts-ignore
    await element?.webkitRequestFullscreen(options);
  } else {
    await element?.requestFullscreen(options);
  }
};

export const exitPageFullScreen = async (): Promise<void> => {
  if (isSafari()) {
    // eslint-disable-next-line
    // @ts-ignore
    await document.webkitExitFullscreen();
  } else {
    await document.exitFullscreen();
  }
};

export const isPermissionErrorText = (error: string | null) => {
  const words = ['permission', 'not allowed', 'context', 'notallowederror'];

  if (error) {
    const errorText = error.toLocaleLowerCase();

    return words.some((s) => errorText.includes(s));
  }

  return false;
};

export const getIsSupportedBrowser = async (): Promise<boolean> => {
  if (isChrome) {
    return true;
  }

  if (isSafari()) {
    return !isOldSafari(MIN_SUPPORTED_SAFARI_VERSION); // https://vlprojects.atlassian.net/browse/LD-5357
  }

  try {
    const audioContext = getNewAudioContext();
    const isNotSupported = isFirefox()
      || isOpera()
      || !RTCPeerConnection
      || (!isMobile() && !MediaStreamTrackGenerator)
      || !AudioWorkletNode
      || !navigator?.mediaDevices?.getUserMedia
      || (!isMobile() && !navigator?.mediaDevices?.getDisplayMedia)
      || !audioContext;

    if (isNotSupported) {
      return false;
    }

    await audioContext.audioWorklet.addModule(`${window.location.origin}/testBrowserSupportWorklet.js`);
    const testNode = new AudioWorkletNode(audioContext, 'tstsupp');
    testNode.disconnect();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return false;
  }

  return true;
};

export const areCookiesBlocked = (): boolean => {
  const name = 'checkCookiesDate';
  const setValue = Date.now().toString();

  cookies.set(name, setValue, {
    expires: addYears(new Date(), 100),
    sameSite: 'None',
    secure: true,
  });

  const getValue = cookies.get(name);

  return setValue !== getValue;
};

export const getVideoDuration = (url: string) => new Promise<number>(
  (resolve, reject) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onerror = () => {
      // eslint-disable-next-line prefer-promise-reject-errors
      reject({ url, error: video.error });
    };

    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      const { duration } = video;
      video.src = '';
      resolve(duration);
    };

    video.src = url;
  });

export const safeDecodeString = (str: string): string | null => {
  try {
    return decodeURIComponent(str);
  } catch (e) {
    return null;
  }
};

export const extractCookieValues = (
  CookieKeys: string[],
  cookieValueAsObject: Record<string, string>,
): Record<string, string> => CookieKeys.reduce((acc: Record<string, string>, key) => {
  const value = Array.isArray(cookieValueAsObject[key])
    ? cookieValueAsObject[key]?.[0]
    : cookieValueAsObject[key];

  if (value) {
    acc[key] = value;
  }

  return acc;
}, {});

export const getDomainWithoutSubdomains = (url: string): string => {
  try {
    const parsedUrl = new URL(url);
    const domains = parsedUrl.hostname.split('.').reverse();

    if (domains.length > 1) {
      return `${domains[1]}.${domains[0]}`;
    }

    return domains[0];
  } catch (e) {
    return '';
  }
};
