import { serializeError } from 'serialize-error';

let skipGettingStorage = false;

const getLocalStorage = (): Storage | null => {
  try {
    return skipGettingStorage ? null : window.localStorage;
  } catch (err: unknown) {
    // eslint-disable-next-line no-console
    console.error('Get local storage error', { error: serializeError(err) });

    if (err instanceof Error && err.name === 'SecurityError') {
      skipGettingStorage = true; // to reduce extra logs
    }

    return null;
  }
};

export function getLocalStorageItem<T>(itemName: string): T | null {
  const localStorage = getLocalStorage();

  if (!localStorage) {
    return null;
  }

  try {
    return JSON.parse(localStorage.getItem(itemName) as string) as T;
  } catch (err) {
    return null;
  }
}

export function getLocalStorageStringItem(itemName: string): string | null {
  const localStorage = getLocalStorage();

  if (!localStorage) {
    return null;
  }

  return localStorage.getItem(itemName);
}

export function setLocalStorageItem<T = unknown>(itemName: string, itemValue: T): void {
  const localStorage = getLocalStorage();

  if (!localStorage) {
    return;
  }

  try {
    localStorage.setItem(itemName, JSON.stringify(itemValue));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export function removeLocalStorageItem(itemName: string): void {
  const localStorage = getLocalStorage();

  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(itemName);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}
