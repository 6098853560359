import React, { FunctionComponent, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ThemeProvider, Global } from '@emotion/react';

import { ThemeType } from 'ui-kit/themes/types';
import { getThemeStyles, LightTheme, DarkTheme } from 'ui-kit/themes';
import useStores from 'stores/root';

interface ThemesProps {
  current: ThemeType
}

const Themes: FunctionComponent<ThemesProps> = observer(({ current, children }) => {
  const { uiStore } = useStores();
  const currentTheme = current !== ThemeType.auto ? current : uiStore.theme.type;
  const theme = currentTheme === ThemeType.auto ? uiStore.theme.getSystemTheme() : currentTheme;
  const styles = getThemeStyles(theme);

  useEffect(() => {
    if (current !== ThemeType.auto) {
      uiStore.theme.setTheme(current);
    }
  }, [current]);

  useEffect(() => {
    const init = async () => {
      await uiStore.theme.init();
    };

    init();
  }, []);

  return (
    <>
      <Global styles={styles} />
      <ThemeProvider theme={theme === ThemeType.dark ? DarkTheme : LightTheme}>
        {children}
      </ThemeProvider>
    </>
  );
});

export default Themes;
