/* eslint-disable max-len */
import { AxiosInstance } from 'axios';

import { getAxiosErrorMessage } from 'helpers/errors';
import { apiCall } from 'decorators';

import {
  ApiResponse,
  PaginableRequest,
  PaginableResponse,
} from './types';
import {
  PlaybackEventExtended,
  PlaybackEvent,
  PlaybackEventSession,
  PlaybackEventType,
  PlaybackTimeline,
} from './Playbacks.types';
import {
  CreatePlaybackEventPayload,
  GetPlaybackEventPayload,
  GetPlaybackEventsPayload,
  JoinPlaybackEventPayload,
  RemovePlaybackEventPayload,
  UpdatePlaybackEventPayload,

  GetEventSessionsResponse,
  JoinPlaybackEventResponse,
  PlaybackEventExtendedResponse,
  PlaybackEventResponse,

  GetEventSessions,
  JoinPlaybackEventByAliasPayload,
} from './PlaybackEventsApi.types';
import { JoinSettings } from './types/joinSettings';

class PlaybackEventsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  private preparePlaybackEvent(data: PlaybackEventResponse): PlaybackEvent {
    if (![
      PlaybackEventType.Conference.toString(),
      PlaybackEventType.Webinar.toString(),
      PlaybackEventType.Record.toString(),
    ].includes(data.type)) {
      throw new Error(`Not compatible PlaybackEvent type: playbackEventId=${data.id}, type=${data.type}`);
    }

    const result: PlaybackEvent = {
      ...data,
      type: data.type as PlaybackEventType,
      createdAt: new Date(data.createdAt),
      startDate: data.startDate ? new Date(data.startDate) : null,
      endDate: data.endDate ? new Date(data.endDate) : null,
      joinSettings: data.joinSettings as JoinSettings,
    };

    return result;
  }

  private preparePlaybackEventExtended(data: PlaybackEventExtendedResponse): PlaybackEventExtended {
    const result: PlaybackEventExtended = {
      ...this.preparePlaybackEvent(data),
      sessionStartDate: data.sessionStartDate ? new Date(data.sessionStartDate) : new Date(),
    };

    return result;
  }

  async createPlaybackEvent({
    name,
    spaceId,
    endDate,
    startDate,
    playbackId,
    recurrence,
    timezone,
  }: CreatePlaybackEventPayload): ApiResponse<PlaybackEvent> {
    try {
      const { data } = await this.api.post<PlaybackEventResponse>(`spaces/${spaceId}/playback-events`, {
        playbackId,
        name,
        recurrence,
        startDate,
        endDate,
        timezone,
      });

      return { data: this.preparePlaybackEvent(data) };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async fetchPlaybackEvents({
    limit,
    offset,
    spaceId,
    playbackId,
  }: PaginableRequest<GetPlaybackEventsPayload>): ApiResponse<PaginableResponse<PlaybackEvent>> {
    try {
      const { data } = await this.api.get<PaginableResponse<PlaybackEventResponse>>(`spaces/${spaceId}/playback-events`, {
        params: {
          playbackId, limit, offset,
        },
      });

      return {
        data: {
          ...data,
          items: data.items.map((x) => this.preparePlaybackEvent(x)),
        },
      };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async fetchPlaybackEvent({ id, spaceId }: GetPlaybackEventPayload): ApiResponse<PlaybackEventExtended> {
    try {
      const { data } = await this.api.get<PlaybackEventExtendedResponse>(`spaces/${spaceId}/playback-events/${id}`);

      return { data: this.preparePlaybackEventExtended(data) };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async fetchPlaybackEventById(id: string): ApiResponse<PlaybackEventExtended> {
    try {
      const { data } = await this.api.get<PlaybackEventExtendedResponse>(`events/${id}`);

      return { data: this.preparePlaybackEventExtended(data) };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async fetchPlaybackEventByAlias(alias: string): ApiResponse<PlaybackEventExtended> {
    try {
      const { data } = await this.api.get<PlaybackEventExtendedResponse>(`events/event-by-alias/${alias}`);

      return { data: this.preparePlaybackEventExtended(data) };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async removePlaybackEvent({
    id,
    spaceId,
  }: RemovePlaybackEventPayload): ApiResponse<boolean> {
    try {
      await this.api.delete<void>(`spaces/${spaceId}/playback-events/${id}`);

      return { data: true };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async fetchPlaybackEventTimeline({
    id,
    spaceId,
  }: GetPlaybackEventPayload): ApiResponse<PlaybackTimeline> {
    try {
      const { data } = await this.api.get<PlaybackTimeline>(`spaces/${spaceId}/playback-events/${id}/timeline`);

      return { data };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async updatePlaybackEvent({
    id,
    name,
    spaceId,
    endDate,
    startDate,
    recurrence,
    timezone,
  }: UpdatePlaybackEventPayload): ApiResponse<PlaybackEvent> {
    try {
      const { data } = await this.api.put<PlaybackEventResponse>(`spaces/${spaceId}/playback-events/${id}`, {
        name,
        recurrence,
        startDate,
        endDate,
        timezone,
      });

      return { data: this.preparePlaybackEvent(data) };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async joinPlaybackEvent({
    id,
    clientUniqueId,
  }: JoinPlaybackEventPayload): ApiResponse<JoinPlaybackEventResponse> {
    try {
      const { data } = await this.api.post<JoinPlaybackEventResponse>(`events/${id}/join`, {
        clientUniqueId,
      });

      return { data };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async joinPlaybackEventByAlias({
    alias,
    clientUniqueId,
  }: JoinPlaybackEventByAliasPayload): ApiResponse<JoinPlaybackEventResponse> {
    try {
      const { data } = await this.api.post<JoinPlaybackEventResponse>(`events/event-by-alias/${alias}/play-record`, {
        clientUniqueId,
      });

      return { data };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  @apiCall()
  async fetchPlaybackEventSessions(params: GetEventSessions): ApiResponse<PaginableResponse<PlaybackEventSession>> {
    const {
      spaceId,
      playbackId,
      offset,
      limit,
      order,
      sort,
      dateFrom,
      dateTo,
      group,
      frequencies,
    } = params;
    const { data } = await this.api.get<GetEventSessionsResponse>(
      `spaces/${spaceId}/playbacks/${playbackId}/event-sessions`, {
        params: {
          offset, limit, order, sort, dateFrom, dateTo, group, frequencies,
        },
      });

    return {
      data: {
        ...data,
        items: data.items.map((item) => ({
          ...item,
          startDate: new Date(item.startDate),
          endDate: new Date(item.endDate),
        })),
      },
    };
  }
}

export default PlaybackEventsApi;
