import axios, { AxiosInstance } from 'axios';
import qs from 'qs';
import { getClientTabId, receiveClientTrackingId } from 'helpers/common';
import { getMoodhoodAnalytyicsApiUrl } from 'helpers/url';
import AnalyticsApi from './AnalyticsApi';
import LogApi from './LogApi';
import { AnalyticsApiClient } from './types';
import { CLIENT_ID_HEADER_NAME, setTraceIdInterceptor, TAB_ID_HEADER_NAME } from '../../helpers/apiClient';

class MoodHoodAnalyticsApiClient implements AnalyticsApiClient {
  private readonly api: AxiosInstance;

  private readonly analyticsApi: AnalyticsApi;

  private readonly logApi: LogApi;

  constructor() {
    this.api = axios.create({
      baseURL: getMoodhoodAnalytyicsApiUrl(),
      timeout: 10000,
      withCredentials: true,
      paramsSerializer: (params) => qs.stringify(params),
      headers: {
        [CLIENT_ID_HEADER_NAME]: receiveClientTrackingId(),
        [TAB_ID_HEADER_NAME]: getClientTabId(),
      },
    });

    this.api.defaults.headers.common['Cache-Control'] = 'no-cache';
    this.api.defaults.headers.common.Pragma = 'no-cache';
    this.api.defaults.headers.common.Expires = '0';
    this.api.interceptors.request.use(setTraceIdInterceptor);

    this.analyticsApi = new AnalyticsApi(this.api);
    this.logApi = new LogApi(this.api);
  }

  get analytics(): AnalyticsApi {
    return this.analyticsApi;
  }

  get log(): LogApi {
    return this.logApi;
  }
}

const analyticsApiClient = new MoodHoodAnalyticsApiClient();

export default analyticsApiClient;
