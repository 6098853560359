import React, { FunctionComponent, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import useStores from 'stores/root';
import { Loader, useConfirmDialog } from 'ui-kit';
import { PersonalTokenResponseItem } from 'services/MoodHoodApiClient/types';

import {
  DesktopLayout, Inputs, SectionTitle, Tooltip,
} from '../index.styled';
import {
  InfoIcon,
  InputButton,
  NewTokenBody, NewTokenWarning,
  PersonalTokensList,
  RemoveTokenButton,
} from './Layout';

type PersonalTokenPrepared = {
  id: string;
  label: string | null;
  createdAt: Date;
};

const parseToken = (
  tkn: PersonalTokenResponseItem,
): PersonalTokenPrepared => ({
  ...tkn,
  createdAt: new Date(String(tkn.createdAt)),
});

const PersonalTokenSettings: FunctionComponent = observer(() => {
  const { rootStore: { moodHoodApiClient } } = useStores();
  const { getConfirmation } = useConfirmDialog();
  const { t } = useTranslation();
  const [tokens, setTokens] = useState<PersonalTokenPrepared[]>([]);
  const [operationInProgress, setOperationInProgress] = useState(false);
  const [initialDataLoadingState, setInitialDataLoadingState] = useState<'pending' | 'error' | 'done'>('pending');

  const fetchExistingToken = async () => {
    if (operationInProgress) {
      return;
    }

    setOperationInProgress(true);

    try {
      const { items } = await moodHoodApiClient.personalTokenApi.getAllTokens();
      setTokens(items.map((tkn) => parseToken(tkn)));

      if (initialDataLoadingState !== 'done') {
        setInitialDataLoadingState('done');
      }
    } catch (err: unknown) {
      if (initialDataLoadingState !== 'error') {
        setInitialDataLoadingState('error');
      }

      toast.error(String(err));
    } finally {
      setOperationInProgress(false);
    }
  };

  const createPersonalToken = async () => {
    if (operationInProgress) {
      return;
    }

    setOperationInProgress(true);

    try {
      const respData = await moodHoodApiClient.personalTokenApi.createToken();
      const { token, ...tokenMeta } = respData;
      setTokens((items) => [...items, parseToken(tokenMeta)]);

      getConfirmation({
        title: t('integration.personalToken.saveTokenDialogTitle'),
        acceptText: t('integration.personalToken.tokenSavedApprovalBtn'),
        body: (
          <>
            <NewTokenWarning>{t('integration.personalToken.saveTokenTip')}</NewTokenWarning>
            <NewTokenBody
              id="new-personal-token"
              readOnly
              value={token}
              onClick={(e) => e.currentTarget.select()}
            />
          </>
        ),
        withoutDecline: true,
        onAccept: () => {},
      });
    } catch (err: unknown) {
      toast.error(String(err));
    } finally {
      setOperationInProgress(false);
    }
  };

  const revokeToken = async (tokenId: string) => {
    if (operationInProgress) {
      return;
    }

    setOperationInProgress(true);

    try {
      await moodHoodApiClient.personalTokenApi.revokeToken(tokenId);
      setTokens((items) => items.filter((tkn) => tkn.id !== tokenId));
    } catch (err: unknown) {
      toast.error(String(err));
    } finally {
      setOperationInProgress(false);
    }
  };

  useEffect(() => {
    fetchExistingToken().finally(() => {});
  }, []);

  return (
    <DesktopLayout>
      <Inputs>
        <SectionTitle>
          <Tooltip title={t('integration.personalToken.whatIsIt')}>
            {t('integration.personalToken.title')}
            <InfoIcon />
          </Tooltip>
        </SectionTitle>

        {initialDataLoadingState === 'done' && (
          <>
            <PersonalTokensList>
              {tokens.length > 0 && tokens.map((tkn) => (
                <div key={tkn.id}>
                  {`${tkn.label || 'Personal Token'} issued at ${format(tkn.createdAt, 'yyyy-MM-dd HH:mm:ss')}`}
                  <RemoveTokenButton onClick={() => revokeToken(tkn.id)}>
                    {`(${t('common.delete')})`}
                  </RemoveTokenButton>
                </div>
              ))}

              {tokens.length === 0 && (
                <div>{t('integration.personalToken.noTokensText')}</div>
              )}
            </PersonalTokensList>

            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <InputButton
                id="get-personal-token"
                buttonSize="small"
                variant="primary"
                onClick={createPersonalToken}
                disabled={operationInProgress}
              >
                {t('common.create')}
              </InputButton>
            </div>
          </>
        )}

        {initialDataLoadingState === 'pending' && (
          <Loader />
        )}

        {initialDataLoadingState === 'error' && (
          <span>{t('integration.personalToken.failedToFetchTokensError')}</span>
        )}
      </Inputs>
    </DesktopLayout>
  );
});

export default PersonalTokenSettings;
