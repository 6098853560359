import React, {
  FunctionComponent, useEffect, useCallback, useRef,
} from 'react';
import { useTheme } from '@emotion/react';

import { FigmaColors as LightColors } from 'ui-kit/themes/light';
import { FigmaColors as DarkColors } from 'ui-kit/themes/dark';
import { ThemeType } from 'ui-kit/themes/types';

import { ColorPickerRoot, ColorPickerInput, IconWrapper } from './index.styled';

interface ColorPickerProps {
  icon?: JSX.Element,
  value?: string,
  onSelect?: (value: string, resetColor: () => void) => void,
}

const ColorPicker: FunctionComponent<ColorPickerProps> = ({
  icon, value, onSelect, ...restProps
}) => {
  const ref = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();

  const resetColor = useCallback(() => {
    if (ref.current) {
      /* color input wont accept css vars */
      ref.current.value = value || theme.name === ThemeType.light ? LightColors.accent01 : DarkColors.accent01;
    }
  }, []);

  const handleChange = useCallback((evt: Event) => {
    const { value: inputValue } = (evt.target as HTMLInputElement);
    onSelect?.(inputValue, resetColor);
  }, []);

  useEffect(() => {
    if (ref.current) {
      resetColor();
      /* react onChange handler for color input behave differently, see https://github.com/facebook/react/issues/6308 */
      ref.current.addEventListener('change', handleChange);
    }

    return () => ref.current?.removeEventListener('change', handleChange);
  }, []);

  return (
    <ColorPickerRoot>
      <ColorPickerInput ref={ref} type="color" {...restProps} />
      <IconWrapper>
        {icon}
      </IconWrapper>
    </ColorPickerRoot>
  );
};

export default ColorPicker;
