import React, { FunctionComponent } from 'react';

import { Tooltip } from 'ui-kit';
import { TooltipProps } from 'ui-kit/components/Tooltip';

import { TooltipTitle, HelpIcon } from './index.styled';

interface HelpIconWithTooltipProps extends TooltipProps {
  message: string,
}

const HelpIconWithTooltip: FunctionComponent<HelpIconWithTooltipProps> = ({ message, ...restProps }) => (
  <Tooltip
    title={(
      <TooltipTitle>{message}</TooltipTitle>
    )}
    {...restProps}
  >
    <HelpIcon onClickCapture={(evt) => { evt.preventDefault(); }} />
  </Tooltip>
);

export default HelpIconWithTooltip;
