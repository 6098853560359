import { makeAutoObservable, observable } from 'mobx';

import api from 'services/MoodHoodApiClient/Api';
import PlaybackEventsApi from 'services/MoodHoodApiClient/PlaybackEventsApi';

import type {
  JoinPlaybackEventPayload,
  CreatePlaybackEventPayload,
  UpdatePlaybackEventPayload,
  PlaybackId, JoinPlaybackEventByAliasPayload,
} from 'services/MoodHoodApiClient/PlaybackEventsApi.types';
import type {
  Id,
  SpaceId,
  DateableRequest,
  PaginableRequest,
} from 'services/MoodHoodApiClient/types';

import { lock } from 'decorators';

import PlaybackEventsStore from './playbackEventsStore';

class PlaybackEvents {
  readonly PlaybackEventsApi: PlaybackEventsApi;

  @observable isBusy = false;

  @observable error: string | null = null;

  store = new PlaybackEventsStore();

  constructor() {
    makeAutoObservable(this);
    this.PlaybackEventsApi = new PlaybackEventsApi(api);
  }

  dispose() {
    this.store = new PlaybackEventsStore();
    this.resetError();
  }

  @lock()
  async createPlaybackEvent({
    spaceId,
    playbackId,
    name,
    recurrence,
    startDate,
    endDate,
    timezone,
  }: CreatePlaybackEventPayload) {
    const { data, error } = await this.PlaybackEventsApi.createPlaybackEvent({
      spaceId,
      playbackId,
      name,
      recurrence,
      startDate,
      endDate,
      timezone,
    });

    if (error) {
      this.handleApiError('admin.playbackEdit.createError');
    }

    return { data, error };
  }

  @lock()
  async updatePlaybackEvent({
    spaceId,
    id,
    name,
    recurrence,
    startDate,
    endDate,
    timezone,
  }: UpdatePlaybackEventPayload) {
    const { data, error } = await this.PlaybackEventsApi.updatePlaybackEvent({
      spaceId,
      id,
      name,
      recurrence,
      startDate,
      endDate,
      timezone,
    });

    if (error) {
      this.handleApiError('admin.playbackEdit.updateError');
    }

    return { data, error };
  }

  @observable lastFetchParams?: PaginableRequest<DateableRequest<SpaceId & PlaybackId>>;

  @lock()
  async fetchPlaybackEvents(params: PaginableRequest<DateableRequest<SpaceId & PlaybackId>>) {
    this.lastFetchParams = {
      ...params,
    };

    const { data, error } = await this.PlaybackEventsApi.fetchPlaybackEvents(this.lastFetchParams);

    if (error || !data) {
      this.handleApiError(error || 'No data');
      return;
    }

    this.store.totalCount = data.total;

    if (!this.lastFetchParams.offset) {
      this.store.setPlaybackEvents(data.items);
    } else {
      this.store.appendPlaybackEvents(data.items);
    }

    this.store.setLastPage(data.items);

    this.lastFetchParams.offset = (this.lastFetchParams.offset || 0) + (data?.items.length || 0);
  }

  @lock()
  async fetchPlaybackEventsMore() {
    if (this.lastFetchParams && this.store.totalCount > this.store.playbackEvents.length) {
      await this.fetchPlaybackEvents(this.lastFetchParams);
    }
  }

  @lock()
  async fetchPlaybackEvent({ spaceId, id }: SpaceId & Id) {
    const { data, error } = await this.PlaybackEventsApi.fetchPlaybackEvent({ spaceId, id });

    return { data, error };
  }

  @lock()
  async fetchPlaybackEventById(id: string) {
    const { data, error } = await this.PlaybackEventsApi.fetchPlaybackEventById(id);

    return { data, error };
  }

  @lock()
  async fetchPlaybackEventByAlias(alias: string) {
    const { data, error } = await this.PlaybackEventsApi.fetchPlaybackEventByAlias(alias);

    return { data, error };
  }

  @lock()
  async removePlaybackEvent(id: string, spaceId: string) {
    this.store.setPlaybackEvents(this.store.playbackEvents.map((x) => {
      if (x.id === id) {
        // eslint-disable-next-line no-param-reassign
        x.isDeleting = true;
      }

      return x;
    }));

    const { data, error } = await this.PlaybackEventsApi.removePlaybackEvent({ spaceId, id });

    if (data) {
      this.store.setPlaybackEvents(this.store.playbackEvents.filter((x) => x.id !== id));
      return;
    }

    if (error) {
      this.store.setPlaybackEvents(this.store.playbackEvents.map((x) => {
        if (x.id === id) {
          // eslint-disable-next-line no-param-reassign
          x.isDeleting = false;
        }

        return x;
      }));

      this.handleApiError('admin.playbackEvents.remove.fail');
    }
  }

  @lock()
  async joinPlaybackEvent({ id, clientUniqueId }: JoinPlaybackEventPayload) {
    const { data, error } = await this.PlaybackEventsApi.joinPlaybackEvent({ id, clientUniqueId });

    return { data, error };
  }

  @lock()
  async joinPlaybackEventByAlias({ alias, clientUniqueId }: JoinPlaybackEventByAliasPayload) {
    const { data, error } = await this.PlaybackEventsApi.joinPlaybackEventByAlias({ alias, clientUniqueId });

    return { data, error };
  }

  @lock()
  async fetchPlaybackEventTimeline({ spaceId, id }: SpaceId & Id) {
    const { data, error } = await this.PlaybackEventsApi.fetchPlaybackEventTimeline({ id, spaceId });

    return { data, error };
  }

  resetError() {
    this.error = null;
  }

  private handleApiError(error: string) {
    if (!error) {
      return;
    }

    this.error = error;
  }
}

export default PlaybackEvents;
