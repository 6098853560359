import styled from '@emotion/styled';

import { FigmaTypography } from 'ui-kit';
import { textOverflowMultilineStyleObject } from 'ui-kit/theme';

export const Layout = styled.div({
  whiteSpace: 'nowrap',
  marginRight: 16,
});

export const Links = styled(FigmaTypography)(({ theme }) => ({
  color: theme.colors.secondaryBase,
  ...textOverflowMultilineStyleObject(1),
}));
