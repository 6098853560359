import React, {
  FunctionComponent, ChangeEvent, ComponentProps,
} from 'react';

import Layout, { InputBox, Label, RequiredMark } from './Layout';

interface IProps extends ComponentProps<'textarea'> {
  id: string;
  label?: string;
  value?:string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

const Input: FunctionComponent<IProps> = ({
  label, id, required, ...props
}) => (
  <Layout>
    {label && (
    <Label id={`${id}-label`} htmlFor={`${id}-textarea`}>
      {label}
      {required && <RequiredMark>*</RequiredMark>}
    </Label>
    )}
    <InputBox id={`${id}-textarea`} required={required} {...props} />
  </Layout>
);
export default Input;
