import styled from '@emotion/styled';

import PartyImage from './party.png';

export const CongratsContent = styled.div({
  display: 'grid',
  placeItems: 'center',
  height: 448,
});

export const CongratsImage = styled.div({
  width: 214,
  height: 214,
  background: `url(${PartyImage})`,
});
