import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import PageLayout from 'components/PageLayout';
import useStores from 'stores/root';

import PlaybackSessionsCard from './PlaybackSessionsCard';
import { UrlParams } from './types';

const PlaybackSessions: FunctionComponent = observer(() => {
  const { spaceId, playbackId } = useParams<UrlParams>();
  const { spaceStore: { space } } = useStores();

  return (
    <PageLayout spaceId={spaceId}>
      {space && (
        <PlaybackSessionsCard playbackId={playbackId} />
      )}
    </PageLayout>
  );
});

export default PlaybackSessions;
