import React, { FunctionComponent, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import copyToClipboard from 'copy-to-clipboard';

import { IconButton, Tooltip } from 'ui-kit';
import Input from 'ui-kit/new-components/Input';
import { InputProps } from 'ui-kit/new-components/Input/types';
import { CopyIcon } from 'ui-kit/assets/icons';

import { InputCopyTextRoot } from './index.styled';

interface InputCopyTextProps extends InputProps {
  onCopyText?: (value: string) => void,
  buttonHint?: string | JSX.Element,
}

const InputCopyText: FunctionComponent<InputCopyTextProps> = observer(({ onCopyText, buttonHint, ...props }) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (!inputRef.current) {
      return;
    }

    const { value, type } = inputRef.current;
    copyToClipboard(value);

    inputRef.current.value = t('common.copied');
    /* we must change input type to show message in password input */
    inputRef.current.type = 'text';

    setTimeout(() => {
      if (!inputRef.current) {
        return;
      }

      inputRef.current.type = type;
      inputRef.current.value = value;
    }, 800);

    onCopyText?.(value);
  };

  return (
    <InputCopyTextRoot>
      <Input ref={inputRef} {...props} />
      <Tooltip title={buttonHint} placement="top">
        <IconButton size="small" icon={<CopyIcon />} onClick={handleClick} />
      </Tooltip>
    </InputCopyTextRoot>
  );
});

export default InputCopyText;
