import { eventBus, subscribe } from 'mobx-event-bus2';

import {
  Announcement,
  ChatMessageData,
  ChatUser,
  PlaybackEventType,
  PlaybackTimeline,
} from 'services/MoodHoodApiClient/Playbacks.types';
import EventStore from 'stores/event';
import { ChannelEvent } from 'types/socket';
import { MessagesToChatPopup, PostMessage } from 'components/Room/types';

const INTERVAL = 50;

export enum EventBusEventToChat {
  chatMessages = 'chat.messages',
  chatUsers = 'chat.users',
  chatSetTimeLine = 'chat.setTimeLine',
}

enum EventBusEventFromChat {
  chatMessagesResponse = 'chat.messages.response',
  chatUsersResponse = 'chat.users.response',
  chatIsReady = 'chat.isReady',
}

class Autowebinar {
  private store?: EventStore;

  private announcements: Announcement[] = [];

  private currTime = Date.now();

  private endTime = Date.now();

  private eventType?: PlaybackEventType;

  private intervalId: NodeJS.Timeout | undefined = undefined;

  isAutowebinarChatReady = false;

  private isChatTimeSetted = false;

  private messagesToChat: ChatMessageData[] = [];

  private playerTime = Date.now();

  private prevTime = Date.now();

  private startTime = Date.now();

  private usersToChat: ChatUser[] = [];

  constructor() {
    eventBus.register(this);
  }

  dispose() {
    eventBus.unregister(this);
  }

  startEventLoop({
    timeline,
    startDate,
    endDate,
    eventType,
    store,
  }: {
    timeline: PlaybackTimeline,
    startDate: Date,
    endDate: Date,
    eventType: PlaybackEventType,
    store: EventStore,
  }) {
    this.eventType = eventType;
    this.startTime = startDate.getTime();
    this.endTime = endDate.getTime();
    this.prevTime = this.startTime;
    this.store = store;

    this.initTimelineData(timeline);

    const timerTickBind = this.timerTick.bind(this);

    this.intervalId = setInterval(timerTickBind, INTERVAL);
  }

  initTimelineData(timeline: PlaybackTimeline) {
    timeline.announcements.forEach((announcement) => {
      this.announcements.push(announcement);
    });

    timeline.chat.users.forEach((user) => {
      this.usersToChat.push(user);
    });

    timeline.chat.messages.forEach((message) => {
      this.messagesToChat.push({
        ...message.data,
        createdAt: new Date(message.offset + this.startTime).toISOString(),
      });
    });
  }

  getOffset(prevTime: number): number {
    const offset = prevTime - this.startTime;
    return offset;
  }

  timerTick() {
    this.currTime = Date.now();

    if (this.currTime > this.endTime) {
      this.stop();
      return;
    }

    if (this.eventType !== PlaybackEventType.Webinar) {
      this.currTime = this.playerTime;
    }

    const beginInterval = this.getOffset(this.prevTime);
    const endInterval = this.getOffset(this.currTime);

    this.prevTime = this.currTime;

    const newAnnouncements = this.announcements
      .filter(({ offset }) => offset >= beginInterval && offset <= endInterval);

    if (newAnnouncements.length > 0) {
      newAnnouncements.forEach((x) => {
        const activeAnnouncements = x.event === 'hide' ? [] : [x.data];
        eventBus.post(ChannelEvent.AnnouncementsUpdated, { activeAnnouncements });
      });
    }

    if (this.isAutowebinarChatReady && this.isChatTimeSetted) {
      if (this.usersToChat.length > 0) {
        eventBus.post(EventBusEventToChat.chatUsers, this.usersToChat);
      }

      if (this.messagesToChat.length > 0) {
        eventBus.post(EventBusEventToChat.chatMessages, this.messagesToChat);
      }
    }
  }

  stop() {
    clearInterval(this.intervalId);
    this.intervalId = undefined;
    this.store?.setEventFinished(true);
    eventBus.post(EventBusEventToChat.chatSetTimeLine, -1);
  }

  @subscribe(EventBusEventFromChat.chatIsReady)
  updateChatReadyState({ payload }: { payload: boolean }) {
    this.isAutowebinarChatReady = Boolean(payload);
    this.store?.rootStore.uiStore.setIsAutowebinarChatReady(this.isAutowebinarChatReady);
  }

  @subscribe(EventBusEventFromChat.chatMessagesResponse)
  chatMessagesResponse({ payload }: { payload: number[] }) {
    this.messagesToChat = this.messagesToChat.filter(({ id }) => !payload.includes(id));
  }

  @subscribe(EventBusEventFromChat.chatUsersResponse)
  chatUsersResponse({ payload }: { payload: number[] }) {
    this.usersToChat = this.usersToChat.filter(({ id }) => !payload.includes(id));
  }

  setIsChatTimeSetted(value: boolean) {
    this.isChatTimeSetted = value;
  }

  setPlayerTime(time: number) {
    this.playerTime = this.startTime + time;
    if (this.isAutowebinarChatReady) {
      eventBus.post(EventBusEventToChat.chatSetTimeLine, this.playerTime);
      this.setIsChatTimeSetted(true);

      if (this.store?.rootStore.uiStore.chatNewWindow) {
        this.store?.rootStore.uiStore.chatNewWindow.postMessage({
          topic: MessagesToChatPopup.chatSetEventSetTime,
          payload: this.playerTime,
        } as PostMessage);
      }
    }
  }
}

export default Autowebinar;
