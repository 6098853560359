import { makeAutoObservable } from 'mobx';
import { RootStore } from 'stores/root';

import logger from '../helpers/logger';
import {
  KickParticipantPayload, MoveParticipantPayload,
} from '../types/stores/moderatorControls';

import { Errors } from '../constants/errors';

class ModeratorControlsStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  async moveParticipant(payload: MoveParticipantPayload, errorCb: (e: Error, code: string) => void): Promise<void> {
    logger.info('moveParticipant', {
      participantId: payload.participantId,
      spaceId: payload.spaceId,
      slot: payload.slot,
      toRoomId: payload.roomId,
    });

    try {
      await this.rootStore.moodHoodApiClient.space.participants.update(payload);
    } catch (error: unknown) {
      const res = (error as { response: { data: Record<string, unknown> } }).response?.data;
      let code: string = Errors.SOMETHING_WENT_WRONG;

      if (res && res?.message && String(res.message).toLowerCase().includes('participant has no permissions')) {
        code = Errors.PARTICIPANT_PERMISSION_ERROR;
      } else {
        code = String(res?.code ?? code);
      }

      errorCb(
        error instanceof Error ? error : new Error(String(error)),
        code,
      );
      logger.error('moveParticipant error', {
        error,
        ...payload,
      });
    }
  }

  async kickParticipant(payload: KickParticipantPayload, errorCb: (message: string) => void): Promise<void> {
    try {
      await this.rootStore.moodHoodApiClient.space.participants.update(payload);
    } catch (err) {
      errorCb(err as string);
    }
  }
}

export default ModeratorControlsStore;
