import styled from '@emotion/styled';

import { IconButton as ButtonBase } from 'ui-kit';
import { TDialogType } from './types';

export const Message = styled.div(({ theme }) => ({
  marginTop: 8,
  marginBottom: 24,
  fontSize: 16,
  lineHeight: '20px',
  color: theme.colors.primary,
}));

export const PrimaryButton = styled(ButtonBase)<{ dialogVariant:TDialogType }>(({ dialogVariant, theme }) => ({
  ...(dialogVariant === 'warn' ? {
    color: theme.colors.errorBase,
    borderColor: theme.colors.errorBase,
  } : {}),
  width: 'calc(50vw - 16px)',
}));

export const Button = styled(ButtonBase)({
  marginLeft: 8,
  width: 'calc(50vw - 16px)',
});

export const Buttons = styled.div({
  display: 'flex',
  flexDirection: 'row',
  marginLeft: 'auto',
  width: '100%',
});

export const Layout = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '32px',
});
