import { AxiosError, AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';

import { getAxiosErrorMessage } from 'helpers/errors';
import {
  RoomInfo,
  CreateRoomParams,
  UpdateRoomPayload,
  GetRoomsParams,
} from 'types/common';
import logger from 'helpers/logger';
import { apiCall } from 'decorators';
import { RoomsCountersResponse } from 'modules/Rooms/types';

import {
  ApiResponse,
  FinishCallForEveryonePayload,
  PaginableRequest,
} from './types';
import {
  GetRoomResponse,
  GetRoomsResponse,
  JoinRoomParams,
  JoinRoomResponse,
  CreateBreakoutRoomsPayload,
  CreateBreakoutRoomsResponse,
  GetBreakoutRoomsPayload,
  GetBreakoutRoomsResponse,
  StartBreakoutRoomsPayload,
  StartBreakoutRoomsResponse,
  StopBreakoutRoomsPayload,
  CreateInvitePayload,
  CreateInviteResponse,
  EmitReactionPayload,
} from './RoomApi.types';

import { UpdateJoinFieldsPayload } from './types/joinSettings';
import { createLogOnRetryDataFunc, isNetworkRelatedError } from '../../helpers/apiClient';
import {
  Room,
  StartBroadcastPayload,
  StartBroadcastResponse,
  StopBroadcastPayload,
  StopBroadcastResponse,
  StartMiroPayload,
  StartMiroResponse,
  StopMiroPayload,
  StopMiroResponse,
  SwitchOffDevicesPayload,
  TurnOffDevicesPayload,
} from './types/room';

class RoomApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
    axiosRetry(api, {
      retries: 3,
      retryDelay: (retryCount) => retryCount * 1000,
      shouldResetTimeout: true,
      onRetry: createLogOnRetryDataFunc({ apiName: 'room', logger }),
      retryCondition: (error) => this.checkShouldRetryRequest(error),
    });
  }

  async getRoomByAlias(alias: string): Promise<RoomInfo> {
    const { data } = await this.api.get<RoomInfo>(`spaces/room-by-alias/${alias}`);
    return data;
  }

  async getRooms(spaceId: string, params?: PaginableRequest<GetRoomsParams>): ApiResponse<GetRoomsResponse> {
    try {
      const { data } = await this.api.get<GetRoomsResponse>(
        `spaces/${spaceId}/rooms`, { params },
      );

      return { data };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async getRoomById(spaceId: string, roomId: string): Promise<{ room: GetRoomResponse }> {
    const { data } = await this.api.get<GetRoomResponse>(`spaces/${spaceId}/rooms/${roomId}`);
    return { room: data };
  }

  async createRoom(room: CreateRoomParams): ApiResponse<Room> {
    try {
      const { data } = await this.api.post<Room>(`spaces/${room.spaceId}/rooms`, room);
      return { data };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async updateRoom({
    spaceId,
    id,
    name,
    isScreensharingAllowed,
    isChatAllowed,
    isPublic,
    type,
    waitingRoomAudience,
    isAutoRecordingAllowed,
  }: UpdateRoomPayload): Promise<{ error?: string }> {
    try {
      await this.api.put<RoomInfo>(`spaces/${spaceId}/rooms/${id}`, {
        name,
        isScreensharingAllowed,
        isChatAllowed,
        isPublic,
        type,
        waitingRoomAudience,
        isAutoRecordingAllowed,
      });
      return {};
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async deleteRoom(spaceId: string, roomId: string): Promise<{ error?: string }> {
    try {
      await this.api.delete(`spaces/${spaceId}/rooms/${roomId}`);
      return {};
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async join({
    spaceId,
    roomId,
    participantId,
  }: JoinRoomParams): ApiResponse<JoinRoomResponse> {
    try {
      const { data } = await this.api.post<JoinRoomResponse>(`spaces/${spaceId}/rooms/${roomId}/join`, {
        participantId,
      });

      return { data };
    } catch (error) {
      logger.warn('Join room request to API failed', {
        error,
        roomId,
        spaceId,
        participantId,
      });

      return {
        error: getAxiosErrorMessage(error),
      };
    }
  }

  async emitReaction(params: EmitReactionPayload): Promise<{ error?: string }> {
    const {
      spaceId, roomId, type, peerId,
    } = params;

    try {
      await this.api.post(`spaces/${spaceId}/rooms/${roomId}/reactions`, { type, peerId });
      return {};
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async finishCallForEveryone(payload: FinishCallForEveryonePayload): Promise<{ error?: string }> {
    try {
      const { spaceId, roomId } = payload;
      await this.api.post<{ sessionId: string }>(
        `/spaces/${spaceId}/rooms/${roomId}/finish-call`, { spaceId, roomId },
      );
      return {};
    } catch (err: unknown) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async createBreakoutRooms(
    payload: CreateBreakoutRoomsPayload,
  ) : ApiResponse<CreateBreakoutRoomsResponse> {
    try {
      const { spaceId, roomId, ...params } = payload;
      return await this.api.post<CreateBreakoutRoomsResponse>(
        `/spaces/${spaceId}/rooms/${roomId}/breakout-rooms`, params,
      );
    } catch (err: unknown) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async getBreakoutRooms(
    payload: GetBreakoutRoomsPayload,
  ) : ApiResponse<GetBreakoutRoomsResponse> {
    try {
      const { spaceId, roomId } = payload;
      return await this.api.get<GetBreakoutRoomsResponse>(
        `/spaces/${spaceId}/rooms/${roomId}/breakout-rooms`,
      );
    } catch (err: unknown) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async startBreakoutRooms(
    payload: StartBreakoutRoomsPayload,
  ) : ApiResponse<StartBreakoutRoomsResponse> {
    try {
      const { spaceId, roomId, groups } = payload;
      return await this.api.post<StartBreakoutRoomsResponse>(
        `/spaces/${spaceId}/rooms/${roomId}/breakout-rooms/session`, { groups },
      );
    } catch (err: unknown) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async stopBreakoutRooms(payload: StopBreakoutRoomsPayload): Promise<{ error?: string }> {
    try {
      const { spaceId, roomId } = payload;
      await this.api.delete<StartBreakoutRoomsResponse>(
        `/spaces/${spaceId}/rooms/${roomId}/breakout-rooms/session`,
      );

      return {};
    } catch (err: unknown) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  @apiCall()
  async updateJoinFields(payload: UpdateJoinFieldsPayload): ApiResponse<Room> {
    const { spaceId, roomId, fields } = payload;
    const res = await this.api.put<Room>(
      `/spaces/${spaceId}/rooms/${roomId}/join-settings/fields`, { fields },

    );

    return res;
  }

  @apiCall()
  async switchOffDevices(payload: SwitchOffDevicesPayload): ApiResponse<{ error?: string }> {
    const {
      spaceId, roomId, auditory, devicesTypes,
    } = payload;
    await this.api.post(`/spaces/${spaceId}/rooms/${roomId}/switch-off-devices`, {
      auditory,
      devicesTypes,
    });

    return {};
  }

  @apiCall()
  async turnOffDevices(payload: TurnOffDevicesPayload): Promise<void> {
    const {
      spaceId, roomId, labels, peerIds,
    } = payload;

    await this.api.post(`/spaces/${spaceId}/rooms/${roomId}/turn-off-devices`, {
      labels,
      peerIds,
    });
  }

  @apiCall()
  async getTotalRoomsCount(spaceId: string): ApiResponse<RoomsCountersResponse> {
    const result = await this.api.get<RoomsCountersResponse>(`/spaces/${spaceId}/rooms/count`);
    return result;
  }

  @apiCall()
  async startBroadcast(payload: StartBroadcastPayload): ApiResponse<StartBroadcastResponse> {
    const { spaceId, roomId, clientUniqueId } = payload;
    const result = await this.api.post<StartBroadcastResponse>(
      `/spaces/${spaceId}/rooms/${roomId}/demonstrations/broadcast/start`,
      {
        clientUniqueId,
      },
    );

    return result;
  }

  @apiCall()
  async stopBroadcast(payload: StopBroadcastPayload): ApiResponse<StopBroadcastResponse> {
    const {
      spaceId, roomId, clientUniqueId, demonstrationId,
    } = payload;
    const result = await this.api.delete<StopBroadcastResponse>(
      `/spaces/${spaceId}/rooms/${roomId}/demonstrations/broadcast/stop/${demonstrationId}`,
      {
        params: { clientUniqueId },
      },
    );

    return result;
  }

  @apiCall()
  async startMiro(payload: StartMiroPayload): ApiResponse<StartMiroResponse> {
    const {
      spaceId, roomId, clientUniqueId, meta,
    } = payload;
    const result = await this.api.post<StartMiroResponse>(
      `/spaces/${spaceId}/rooms/${roomId}/demonstrations/miro/start`,
      {
        clientUniqueId,
        meta,
      },
    );

    return result;
  }

  @apiCall()
  async stopMiro(payload: StopMiroPayload): ApiResponse<StopMiroResponse> {
    const {
      spaceId, roomId, clientUniqueId, demonstrationId,
    } = payload;
    const result = await this.api.delete<StopMiroResponse>(
      `/spaces/${spaceId}/rooms/${roomId}/demonstrations/miro/stop/${demonstrationId}`,
      {
        params: { clientUniqueId },
      },
    );

    return result;
  }

  private checkShouldRetryRequest(error: AxiosError): boolean {
    const { config: { url }, response } = error;

    if (response?.status && response.status < 500) {
      return false;
    }

    // retry only join endpoint
    if (!/^\/?spaces\/.{24}\/rooms\/.{24}\/join\/?/.test(url ?? '')) {
      return false;
    }

    return isNetworkRelatedError(error);
  }

  @apiCall()
  async createInvite(payload: CreateInvitePayload): ApiResponse<CreateInviteResponse> {
    const { spaceId, roomId, role } = payload;
    const result = await this.api.post<CreateInviteResponse>(
      `/spaces/${spaceId}/rooms/${roomId}/invites`,
      {
        role,
      },
    );

    return result;
  }
}

export default RoomApi;
