import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import useStores from 'stores/root';
import Popup from 'ui-kit/components/Popup';
import { PopupProps } from 'ui-kit/components/Popup/types';
import { Menu, MenuItem } from 'ui-kit/components/DropDownButton';
import { Color } from 'ui-kit/theme';
import { MenuDivider } from 'ui-kit/components/Menu/Layout';
import { StatsId } from 'types/stats';

interface UserMenuDesktopProps extends PopupProps {
  handleProfileClick: () => void;
  handleLogoutClick: () => void;
}

const UserMenuDesktop: FunctionComponent<UserMenuDesktopProps> = ({
  handleProfileClick,
  handleLogoutClick,
  ...restProps
}) => {
  const { authStore, spaceStore } = useStores();
  const { t } = useTranslation();
  const statsProfileEdit = spaceStore.space ? StatsId.RoomsProfileEdit : StatsId.SpacesProfileEdit;
  const statsProfileLogout = spaceStore.space ? StatsId.RoomsProfileLogout : StatsId.SpacesProfileLogout;

  return (
    <Popup {...restProps}>
      <Menu>
        <MenuItem
          id="profile-edit"
          data-stats={statsProfileEdit}
          onClick={handleProfileClick}
        >
          {t('common.profile')}
        </MenuItem>
        {authStore.isUser && (
          <>
            <MenuDivider />
            <MenuItem
              id="profile-logout"
              data-stats={statsProfileLogout}
              color={Color.RED0}
              onClick={handleLogoutClick}
            >
              {t('common.logout')}
            </MenuItem>
          </>
        )}
      </Menu>
    </Popup>
  );
};

export default UserMenuDesktop;
