import React from 'react';

import { LoaderIcon } from 'ui-kit/assets/icons';

import { IconButtonProps } from './types';
import {
  IconButtonRoot,
  Text,
} from './index.styled';
import Tooltip from '../Tooltip';

const IconButton = React.forwardRef<HTMLButtonElement & HTMLAnchorElement, IconButtonProps>(({
  id,
  text = undefined,
  loading = false,
  children,
  hideTextBreakPoint,
  iconColor,
  textColor,
  as,
  icon,
  iconAtEnd,
  variant = undefined,
  type = 'button',
  tooltip,
  tooltipDisabled,
  onTooltipOpen,
  onTooltipClosed,
  subVariant,
  textSize = subVariant === 'link' ? 'main-link' : 'main-text-medium',
  ...restProps
}, forwardedRef) => {
  const button = (
    <IconButtonRoot
      as={as}
      id={`${id}-icon-button`}
      ref={forwardedRef}
      iconColor={iconColor}
      text={text}
      hideTextBreakPoint={hideTextBreakPoint}
      type={as === undefined ? type : undefined}
      variant={variant}
      subVariant={subVariant}
      textColor={textColor}
      {...restProps}
    >
      {icon && (loading ? <LoaderIcon /> : icon)}
      {(text !== undefined) && (
      <Text
        color={textColor}
        size={textSize}
        isLinkWithoutIcon={subVariant === 'link' && (!icon || !iconAtEnd)}
        hideBreakPoint={hideTextBreakPoint}
      >
        {text}
      </Text>
      )}
      {children}
      {iconAtEnd && iconAtEnd}
    </IconButtonRoot>
  );

  if (tooltip) {
    return (
      <Tooltip
        title={tooltip}
        disabled={tooltipDisabled}
        onOpen={onTooltipOpen}
        onClose={onTooltipClosed}
      >
        {button}
      </Tooltip>
    );
  }

  return button;
});

export default IconButton;
