import React, { forwardRef } from 'react';

import {
  Label,
  Input,
  InputLayout,
  InputContainer,
  Required,
} from './InputLayoutDesktop';
import { InputProps } from './type';
import { Flex } from '../Flex';

export default forwardRef<HTMLInputElement, InputProps>(({
  disabled = false,
  variant = 'large',
  type = 'text',
  label,
  placeholder,
  id,
  onChange,
  className,
  children,
  fullWidth,
  beforeInputElement,
  startInputElement,
  inputElement,
  inputElementProps,
  endInputElement,
  afterInputElement,
  required,
  style,
  inputContainerProps,
  hasError = false,
  ...rest
}, forwardedRef) => {
  const inputId = `${id || ''}-input`;
  const labelId = `${id || ''}-label`;

  return (
    <InputLayout
      variant={variant}
      fullWidth={fullWidth}
      className={className}
      style={style}
      hasError={hasError}
    >
      {label && (
        <Label id={labelId} htmlFor={inputId}>
          {label}
          {required && <Required>*</Required>}
        </Label>
      )}
      <Flex flexDirection="row" gap={8}>
        {beforeInputElement}
        <InputContainer
          disabled={disabled}
          variant={variant}
          hasError={hasError}
          style={{ flexGrow: 1 }}
          {...inputContainerProps}
        >
          {startInputElement}
          <Input
            as={inputElement}
            id={inputId}
            type={type}
            variant={variant}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            ref={forwardedRef}
            required={required}
            {...rest}
            {...inputElementProps}
          />
          {endInputElement}
        </InputContainer>
        {afterInputElement}
      </Flex>
      {children}
    </InputLayout>
  );
});
