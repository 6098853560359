import type PeerStore from 'stores/peer';
import type Peer from '@livedigital/client/dist/engine/Peer';
import type { Role, PeerInfo as LiveDigitalPeerInfo } from '@livedigital/client/dist/types/common';

import type {
  TrackLabel, IUserInfo, SlotAssignment, SpaceRole,
} from 'services/MoodHoodApiClient/types';
import type { RoomSettings, Room } from 'services/MoodHoodApiClient/types/room';
import { ReactionType } from 'services/MoodHoodApiClient/RoomApi.types';
import { CallDataRestrictions } from 'modules/СallLimit/types';

import { PeerAppData } from './stores/peer';

export enum TemplateSlotType {
  Participant = 'participantSlot',
  Demo = 'demoSlot',
}

export type TSpaceInfo = {
  id: string;
  name: string;
  description: string;
  roleInSpace: SpaceRole;
  logo?: string;
  isPublic?: boolean;
  chatToken: string;
  planId?: string;
  planAlias?: string;
  isSubscriptionActive?: boolean;
  subscriptionEndsAt?: string;
};

export type TParticipantInfo = {
  id: string;
  name: string;
  email: string;
  spaceId: string;
  roomId: string | null;
  slot: number | null;
  isOnline: boolean;
  isPermanent: boolean;
  image: string;
};

export enum RoomType {
  Lesson = 'lesson',
  Webinar = 'webinar',
}

export interface ApiResponsePayload {
  error: string;
  code: string;
}

export interface CreateRoomParams extends RoomSettings {
  spaceId: string;
}

export interface GetRoomsParams {
  name?: string,
  type?: RoomType,
  sort?: 'createdAt' | 'lastCallAt'
  order?: SortOrder,
  isPublic?: boolean,
  isFavorite?: boolean,
}

export type RoomInfo = Room; // will be renamed in separate pr

export type UpdateRoomPayload = {
  id: string;
  spaceId: string;
  name?: string;
  background?: string;
  isScreensharingAllowed?: boolean;
  isChatAllowed?: boolean;
  isPublic?: boolean;
  type?: RoomType;
  waitingRoomAudience?: WaitingRoomAudience;
  isAutoRecordingAllowed?: boolean;
};

export interface TSlot {
  type: TemplateSlotType;
  slotUuid: string;
  width: number;
  height: number;
}

export type TJoinParams = {
  roomId: string,
  participantId: string,
  role: Role,
  name: string,
  image?: string | null,
};

export type TSlotParticipant = TSlot;

type TAssetType = 'video' | 'audio' | 'image';

export interface IAssetInfo {
  id: string,
  name: string;
  spaceId: string;
  url: string,
  type: TAssetType,
}

export enum MicrophoneAudioLevel {
  MIN = 0,
  TALK_WHILE_MUTED = 9,
  MAX = 10,
}

export enum SidePanelContent {
  USERS = 'users',
  CHAT = 'chat',
  ANNOUNCEMENTS = 'announcements',
  BREAKOUT_ROOMS = 'breakout_rooms',
  HIDDEN = 'hidden',
  EFFECTS = 'effects',
}

export interface PeerInfo {
  peer: PeerStore;
  trackLabel: TrackLabel;
  slotAssignment: SlotAssignment;
  hasVideo: boolean,
  isOutOfScreen?: boolean;
}

export interface IErrorPageData {
  error: string,
  hint?: string,
  customElement?: JSX.Element | null;
}

export interface SignInfo {
  ContentType: string;
  filename: string;
  filePath: string;
  originalFilename: string;
  publicUrl: string;
  signedUrl: string;
}

export type RoomEvent = {
  eventName: string,
  peerId?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RoomEventData = Record<string, any>;

export type MoodhoodPeer = Peer & {
  uid: string,
  appData: PeerAppData,
};

export type RoomEventNewReactionPayload = {
  peerId: string,
  reactionType: ReactionType,
  userId?: string,
};

export type RoomEventNewRestrictionsPayload = {
  callId: string;
  restrictions: CallDataRestrictions;
};

export interface Call {
  id: string,
  active: boolean,
  roomId: string,
  participantsCount: number,
  totalParticipantsCount: number,
  uniqueParticipantsCount: number,
  startedAt: string,
  finishedAt: string
}

export type VideoResolution = {
  width: number,
  height: number,
};

export interface UserInfo extends Omit<IUserInfo, 'id' | 'createdAt'> {
  userId: string;
  createdAt: Date;
}

export interface UpdateUserPasswordPayload {
  getCaptcha: () => Promise<string>;
  email?: string;
  hasForgottenPassword?: boolean;
}

export type ImmutableProps<T, K extends keyof T> = Readonly<Pick<T, K>> & Omit<T, K>;

export type PeerParticipantInfo = LiveDigitalPeerInfo & {
  name?: string,
  isMe: boolean,
  participantId: string,
  roomId: string,
  spaceId: string,
  callId?: string,
  isModerator?: boolean,
  isAudioStreamingAllowed?: boolean,
  isVideoStreamingAllowed?: boolean,
  userAgent: unknown,
};

export enum WindowEvent {
  FinishCall = 'ld_finish_call',
}

export interface DebugAudioTrackInfo {
  id: string,
  peerName: string,
  label: string,
  isAudioElementMuted: boolean,
  isAudioElementPaused: boolean,
  elVolume: number,
  enabled: boolean,
  muted: boolean,
  readyState: string,
  sinkId: string,
  badState: boolean,
  tsIsMuted?: boolean,
  tsIsPaused?: boolean,
  trackVolume: number,
}

export enum AppEvent {
  ChangeAppState = 'change_app_state',
  MovingToRoom = 'moving_to_room',
  Error = 'app_error',
}

export enum AppState {
  RoomNavigate = 'room_navigate',
  RoomJoined = 'room_joined',
  ChatInitialized = 'chat_initialized',
}

export enum AppHotkey {
  Camera = 'KeyC',
  Microphone = 'Space',
  PiP = 'KeyP',
  Record = 'KeyR',
  ExitFullScreen = 'Escape',
  Debug = 'KeyD',
  Feature = 'KeyF',
}

export enum WaitingRoomAudience {
  Nobody = 'nobody',
  Everybody = 'everybody',
  Guests = 'guests',
}

export enum ReactionEvent {
  emitReaction = 'emit-reaction',
  emitRaisedHand = 'emit-raised-hand',
}

export interface EmitReactionPayload {
  reactionName: ReactionType,
  newAnchorName?: string,
}

export interface EmitRaisedHandPayload {
  userImage?: string,
  userName?: string,
}

export enum ImageCropperState {
  spaceLogo = 'space-logo',
  userAvatar = 'user-avatar',
}

export enum ReJoinReason {
  Move = 'move',
  Reconnect = 'reconnet',
}

export enum Auditory {
  All = 'all',
  Guests = 'guests',
  Users = 'users',
  Moderators = 'moderators',
}

export enum DevicesTypes {
  Camera = 'camera',
  Microphone = 'microphone',
  SharedScreenMedia = 'sharedScreenMedia',
}

export enum CommonToastType {
  Info = 'Info',
  Warn = 'Warn',
  Success = 'Success',
  Error = 'Error',
}

export interface CommonToast {
  message: string,
  type: CommonToastType,
}

export interface ExecuteWithRndDelayPayload {
  maxDelayMs: number;
  callback: () => unknown;
}

export enum MeetingType {
  Conference = 'conference',
  Webinar = 'webinar',
  AutoWebinar = 'autoWebinar',
}

export type UrlParam =
  'to' |
  'code' |
  'debug' |
  'email' |
  'phone' |
  'confirm' |
  'showPrice' |
  'sessionTag' |
  'accessToken' |
  'refreshToken' |
  'externalUserId' |
  'disableSupport' |
  'participantName';

export type SortOrder = 'asc' | 'desc';

export enum RoutePath {
  Root = '/',
  SignUp = '/auth/signup',
  SignUpStep2 = '/auth/signup/step2',
  SignIn = '/auth/signin',
  Logout = '/auth/logout',

  ChangeEmailConfirm = '/change-email/apply',
  ChangePasswordConfirm = '/change-password/apply',
  Invite = '/invite/:spaceId/:inviteId/:roomId?',
  RecoverPassword = '/auth/recover',

  Room = '/room/:roomAlias',
  RoomStatistics = '/room/:roomAlias/statistics',
  RoomStatisticsPulse = '/room/:roomAlias/statistics/pulse/:timeFrom/:timeTo',
  RoomRecord = '/record/:roomAlias/:secret?',
  RoomRecords = '/room/:roomAlias/records',
  RoomRecordPLay = '/play/:eventAlias',

  SpaceOnly = '/space/',
  Space = '/space/:spaceId/:action?/:roomId?',
  Plans = '/space/:spaceId/plans',
  PromoPlans = '/promo',
  MobileProfile = '/space/:spaceId/mobile-profile',
  MobileProfilePlans = '/space/:spaceId/mobile-profile/plans',
  MobileProfileAndroid = '/mobile-profile',
  MobileProfilePlansAndroid = '/mobile-profile/plans',

  Playbacks = '/space/:spaceId/playbacks',
  PlaybackSessions = '/space/:spaceId/playback/:playbackId',
  PlaybackPreview = '/space/:spaceId/playback/:playbackId/preview',
  PlaybackEdit = '/space/:spaceId/playback/:playbackId/edit',

  Event = '/event/:eventId',
  SafariStorageAccess = '/allow',
  Thanks = '/thanks/:roomAlias',

  ChatPopup = '/chat/:roomAlias',
  ChatEventPopup = '/chat/:eventId/event',
  ChatPlaybackPreviewPopup = '/chat/space/:spaceId/playback/:playbackId/preview',

  ToolsScreenSharingTest = '/tools/screen-sharing-test',
  MiroWrapper = '/miro-wrapper',
  IconButtonStory = '/story/icon-button',
  Canary = '/canary/:action',

  FromApp = '/from-app',
}

export interface DatePeriod {
  dateFrom: Date,
  dateTo: Date,
}

export interface Sources {
  id: string;
  name: string;
  thumbnailUrl?: string;
}
