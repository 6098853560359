import styled from '@emotion/styled';

import { FigmaTypography } from 'ui-kit';
import { textOverflowStyle } from 'ui-kit/theme';

export const PlaybackInfo = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  margin: '0 8px',
  overflowX: 'hidden',
});

export const PlaybackSchedule = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: 4,
  color: theme.colors.secondary03,
}));

export const PlaybackTitle = styled(FigmaTypography)({
  minWidth: 0,
  flexShrink: 1,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  ...textOverflowStyle,
});

export const Playback = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
});

const Layout = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'inherit',
  borderRadius: 10,
  background: theme.colors.bg,
  padding: 24,
  gap: 16,
  width: '100%',
}));

export default Layout;
