import { AxiosInstance } from 'axios';
import { apiCall } from 'decorators';
import { getAxiosErrorMessage } from 'helpers/errors';
import { PaymentSystem, Plan } from 'types/payment';

import { ApiResponse } from './types';

interface ChangePlanResponse {
  paymentId: string,
  status: string,
  redirectUri?: string
  externalPaymentId?: string,
}

class PaymentsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async getPlans(): ApiResponse<Plan[]> {
    try {
      const { data } = await this.api.get<{ items: Plan[] }>('/plans');
      return { data: data.items };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async changePlan(
    spaceId: string, planId: string, paymentSystem: PaymentSystem,
  ): ApiResponse<ChangePlanResponse> {
    try {
      const { data } = await this.api.post<ChangePlanResponse>(
        '/payments', {
          spaceId,
          planId,
          idempotenceKey: String(Date.now()),
          paymentSystem,
        },
      );

      return { data };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  @apiCall()
  async getCurrentPlan(spaceId: string): ApiResponse<Plan> {
    const response = await this.api.get<Plan>('/plans/current', {
      params: { spaceId },
    });

    return response;
  }
}

export default PaymentsApi;
