import { AxiosInstance, AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { get } from 'lodash';
import logger from 'helpers/logger';
import { createLogOnRetryDataFunc, errorToJson } from 'helpers/apiClient';
import { apiCall } from 'decorators';

import { clamp, sleep } from '../../helpers/common';
import {
  TokenResponse,
  AuthorizeResponse,
  AccessToken,
  GrantTypes,
} from './AuthApi.types';
import { ApiResponse } from './types';

const isAuthUrl = (url: string) => /^\/?auth\/token/.test(url);

const className = 'AuthApi';

class AuthApi {
  private api: AxiosInstance;

  private readonly clientId?: string;

  private readonly clientSecret?: string;

  #shouldUseAuthHeader = false;

  #authToken?: Partial<TokenResponse>;

  #refreshingAccessTokenPromise?: Promise<TokenResponse>;

  #issuingClientAccessTokenPromise?: Promise<TokenResponse>;

  constructor(api: AxiosInstance) {
    this.api = api;
    this.api.interceptors.request.use(
      (config) => {
        const { headers, url, method } = config;

        if (!this.#shouldUseAuthHeader || !this.#authToken?.access_token) {
          return config;
        }

        // no need to attach bearer token to some auth-related requests (e.g. signIn)
        if (isAuthUrl(String(url)) && ['post', 'put'].includes(String(method))) {
          return config;
        }

        headers.Authorization = `Bearer ${this.#authToken.access_token}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    // Refresh access token by refresh token interceptor
    this.api.interceptors.response.use((response) => response, async (error) => {
      const {
        config: { skipTokenErrorHandling, url },
        config,
        response,
      } = error || {};

      if (response?.status !== 401 || skipTokenErrorHandling || isAuthUrl(String(url))) {
        return Promise.reject(error);
      }

      config.skipTokenErrorHandling = true;
      const refreshed = await this.refreshAccessTokenWrapper();

      if (!refreshed) {
        await this.issueClientAccessTokenWrapper();
      }

      return api(config as AxiosRequestConfig);
    });

    axiosRetry(api, {
      retries: 10,
      retryDelay: (retryCount) => clamp(retryCount * 1000, 1000, 5000),
      shouldResetTimeout: true,
      onRetry: createLogOnRetryDataFunc({ apiName: 'auth', logger }),
      retryCondition: (error) => {
        const { config: { url }, response } = error;

        if (!isAuthUrl(url ?? '')) {
          return false;
        }

        const status = response?.status;

        if (status && status < 500) {
          return false;
        }

        logger.warn('Request to auth API failed. Will retry if applicable', {
          // we cannot just pass in the error, because it may have user credentials
          error: errorToJson(error),
          status,
          className,
        });

        return true;
      },
    });

    this.clientId = process.env.REACT_APP_MOODHOOD_API_CLIENT_ID;
    this.clientSecret = process.env.REACT_APP_MOODHOOD_API_CLIENT_SECRET;
  }

  get accessTokenForAuthHeader(): string | undefined {
    return this.#authToken?.access_token;
  }

  setShouldUseAuthHeader(shouldUse: boolean): void {
    this.#shouldUseAuthHeader = shouldUse;
  }

  async getClientAccessToken(): Promise<TokenResponse> {
    logger.info('Handling guest access token creation by client credentials', { className });

    const response = await this.api.post<TokenResponse>('auth/token', {
      grant_type: GrantTypes.CLIENT_CREDENTIALS,
      client_id: this.clientId,
      client_secret: this.clientSecret,
    });

    return response.data;
  }

  @apiCall()
  authorize(): ApiResponse<AuthorizeResponse> {
    logger.info('Get auth code', { className });

    return this.api.post<AuthorizeResponse>('auth/authorize?response_type=code');
  }

  async getUserAccessToken(username: string, password: string, captchaToken: string): Promise<TokenResponse> {
    logger.info('Handling access token creation by user credentials', { username, className });

    const response = await this.api.post<TokenResponse>('auth/token', {
      username,
      password,
      captchaToken,
      grant_type: GrantTypes.PASSWORD,
      client_id: this.clientId,
      client_secret: this.clientSecret,
    });

    return response.data;
  }

  async getAccessTokenByRefresh(refreshToken?: string): Promise<TokenResponse> {
    logger.info('Handling refresh token exchange to a new access token', { className });

    const response = await this.api.post<TokenResponse>('auth/token', {
      grant_type: GrantTypes.REFRESH_TOKEN,
      client_id: this.clientId,
      client_secret: this.clientSecret,
      refresh_token: refreshToken ?? this.#authToken?.refresh_token,
    });

    return response.data;
  }

  async getAccessTokenByAuthCode(code: string): Promise<TokenResponse> {
    logger.info('Handling auth code exchange to a new access token', { className });

    const response = await this.api.post<TokenResponse>('auth/token', {
      grant_type: GrantTypes.AUTHORIZATION_CODE,
      client_id: this.clientId,
      client_secret: this.clientSecret,
      code,
    });

    return response.data;
  }

  async getAccessTokenInfo(accessToken?: string): Promise<AccessToken> {
    const response = await this.api.get<AccessToken>(
      'auth/token',
      {
        params: {
          accessToken,
        },
      },
    );

    const { data } = response;

    logger.info('Received parsed access token info', {
      aud: data?.aud,
      iat: data?.issuedAt,
      exp: data?.expiredAt,
      className,
    });

    return response.data;
  }

  async deleteAccessToken(): Promise<void> {
    logger.info('Handling logout intent', { className });

    await this.api.delete('auth/token');
    this.#authToken = undefined;
  }

  setAuthToken(token?: Partial<TokenResponse>) {
    if (this.#shouldUseAuthHeader) {
      this.#authToken = token;
    }
  }

  private async refreshAccessTokenWrapper(): Promise<boolean> {
    try {
      logger.info('Trying to get access token by refresh token', { className });

      this.#refreshingAccessTokenPromise = this.#refreshingAccessTokenPromise || this.getAccessTokenByRefresh();
      const newPair = await this.#refreshingAccessTokenPromise;
      this.setAuthToken(newPair);

      logger.info('Got access token by refresh token', { className });
      return true;
    } catch (err: unknown) {
      return await this.checkTokenIsAlreadyUpdatedInParallelTab(err);
    } finally {
      this.#refreshingAccessTokenPromise = undefined;
    }
  }

  private async issueClientAccessTokenWrapper(): Promise<void> {
    try {
      this.#issuingClientAccessTokenPromise = this.#issuingClientAccessTokenPromise || this.getClientAccessToken();
      const newPair = await this.#issuingClientAccessTokenPromise;
      this.setAuthToken(newPair);
    } catch (error: unknown) {
      logger.warn('Failed to issue client access token', { error, className });
      throw error;
    } finally {
      this.#issuingClientAccessTokenPromise = undefined;
    }
  }

  private async checkTokenIsAlreadyUpdatedInParallelTab(err: unknown): Promise<boolean> {
    const tokenAlreadyUsedError = get(err, 'response.status') === 403
      && get(err, 'response.data.error') === 'token_already_used';

    if (!tokenAlreadyUsedError) {
      return false;
    }

    logger.info('Refresh token already used error received. Checking if it was updated in parallel tab');
    await sleep(10);

    try {
      await this.getAccessTokenInfo();
      return true;
    } catch (error: unknown) {
      logger.warn('Could not check that access token was updated in parallel tab', { error, className });
      return false;
    }
  }
}

export default AuthApi;
