import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ConfirmDialogProvider } from 'ui-kit';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ThemeType } from 'ui-kit/themes/types';
import Themes from 'components/Themes';
import { TooltipsProvider } from 'ui-kit/components/Tooltip/Provider';

import App from 'components/App';
import * as serviceWorker from './serviceWorker';
import './services/i18n';
import ErrorBoundary from './components/ErrorPage/ErrorBoundary';

if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
  Sentry.init({
    dsn: 'https://9cb324f26e1c4c419391600062b06026@o560757.ingest.sentry.io/5696621',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    release: `lsd-client@${process.env.REACT_APP_VERSION as string}`,
  });
}

window.lsd = {
  version: process.env.REACT_APP_VERSION || '',
}; /* all global vars should be placed here */

// eslint-disable-next-line no-console
console.log({ version: window.lsd.version });

ReactDOM.render(
  <ErrorBoundary>
    <TooltipsProvider>
      <Themes current={ThemeType.auto}>
        <DndProvider backend={HTML5Backend}>
          <ConfirmDialogProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ConfirmDialogProvider>
        </DndProvider>
      </Themes>
    </TooltipsProvider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
