import { CallStats } from 'modules/CallStatsCollector/types';
import { RoomType } from 'types/common';
import { UserAgentInfo } from 'services/MoodHoodAnalyticsApiClient/types';

export enum QoSEventType {
  NavigateRoom = 'navigate_room',
  NavigateDevices = 'navigate_devices',
  RoomJoinAttempt = 'join_attempt',
  RoomJoinSucceeded = 'join_succeeded',
  RoomReJoinAttempt = 'rejoin_attempt',
  RoomReJoinSucceeded = 'rejoin_succeeded',
  RoomReload = 'room_reload',
  ParticipantNameInput = 'participant_name_input',
  DevicesReady = 'devices_ready',
  DevicesAccessRequest = 'devices_access_request',
  DevicesAccessGranted = 'devices_access_granted',
  DevicesAccessDenied = 'devices_access_denied',
  DevicesAccessState = 'devices_access_state',
  GotAllTracks = 'got_all_tracks',
  GotSomeTracks = 'got_some_tracks',
  GotNoneTracks = 'got_none_tracks',
  ChatInitialized = 'chat_initialized',
  UserFeedbackPositive = 'user_feedback_positive',
  UserFeedbackNegative = 'user_feedback_negative',
  UserFeedbackRequested = 'user_feedback_requested',
  RoomStateInconsistent = 'room_state_inconsistent',
}

export enum QosEventCategory {
  General = 'general',
  Room = 'room',
  Device = 'device',
  Media = 'media',
  Chat = 'chat',
}

export enum UserProblem {
  JoinProblems = 'joinProblems',
  TracksProblems = 'tracksProblems',
  UiProblems = 'uiProblems',
}

export interface TrackInfo {
  peerId: string,
  trackId: string
  trackLabel: string,
}

export interface UserFeedback {
  joinProblems: boolean,
  tracksProblems: boolean,
  uiProblems: boolean,
  details: string,
  hardwareInfo?: string,
  webRTCStats?: string,
}

export interface QoSEvent {
  type: QoSEventType,
  category: QosEventCategory,
  clientId: string,
  tabId: string,
  timestamp: Date,
  participantId?: string,
  roomId?: string,
  spaceId?: string,
  userId?: string,
  callId?: string,
  tag?: string,
  roomType?: RoomType,
  ua: QoSUserAgentInfo,
}

export type QoSUserAgentInfo = Required<Omit<UserAgentInfo, 'deviceModel'>>;

interface QoSRoomEvent extends QoSEvent {
  category: QosEventCategory.Room,
}

interface QoSDeviceEvent extends QoSEvent {
  category: QosEventCategory.Device,
  state?: string,
}

interface QoSMediaEvent extends QoSEvent {
  category: QosEventCategory.Media,
}

interface QoSChatEvent extends QoSEvent {
  category: QosEventCategory.Chat,
}

interface QoSDevicesReadyEvent extends QoSDeviceEvent {
  type: QoSEventType.DevicesReady,
  meta: {
    devices: {
      availableAudioDevices: MediaDeviceInfo[],
      availableVideoDevices: MediaDeviceInfo[],
    },
  },
}

interface QoSDevicesAccessStateEvent extends QoSDeviceEvent {
  type: QoSEventType.DevicesAccessState,
  state: QoSEventType.DevicesAccessDenied | QoSEventType.DevicesAccessGranted,
}

interface QoSDevicesAccessRequestEvent extends QoSDeviceEvent {
  type: QoSEventType.DevicesAccessRequest,
}

interface QoSDevicesAccessGrantedEvent extends QoSDeviceEvent {
  type: QoSEventType.DevicesAccessGranted,
}

interface QoSDevicesAccessDeniedEvent extends QoSDeviceEvent {
  type: QoSEventType.DevicesAccessDenied,
}

interface QoSNavigateRoomEvent extends QoSRoomEvent {
  type: QoSEventType.NavigateRoom,
  meta: {
    referer: string;
    roomAlias: string;
  },
}

interface QoSNavigateDevicesEvent extends QoSRoomEvent {
  type: QoSEventType.NavigateDevices,
}

interface QoSRoomJoinAttemptEvent extends QoSRoomEvent {
  type: QoSEventType.RoomJoinAttempt,
}

interface QoSRoomJoinSucceededEvent extends QoSRoomEvent {
  type: QoSEventType.RoomJoinSucceeded,
}

interface QoSRoomReJoinAttemptEvent extends QoSRoomEvent {
  type: QoSEventType.RoomReJoinAttempt,
  state: 'room_change' | 'connection_lost',
}

interface QoSRoomReJoinSucceededEvent extends QoSRoomEvent {
  type: QoSEventType.RoomReJoinSucceeded,
}

interface QoSRoomParticipantNameInputEvent extends QoSRoomEvent {
  type: QoSEventType.ParticipantNameInput,
}

interface TracksStatusPayload extends QoSMediaEvent {
  meta: {
    attempt: 'first' | 'second',
    peersCount: number,
    tracksCount: number,
    deadTracks?: TrackInfo[]
  }
}
interface QoSGotNoneTracks extends TracksStatusPayload {
  type: QoSEventType.GotNoneTracks,
}

interface QoSGotSomeTracks extends TracksStatusPayload {
  type: QoSEventType.GotSomeTracks,
}

interface QoSGotAllTracks extends TracksStatusPayload {
  type: QoSEventType.GotAllTracks,
}

interface QoSChatInitialized extends QoSChatEvent {
  type: QoSEventType.ChatInitialized,
}

interface QoSUserFeedbackPositive extends QoSRoomEvent {
  type: QoSEventType.UserFeedbackPositive,
}

interface QoSUserFeedbackRequested extends QoSRoomEvent {
  type: QoSEventType.UserFeedbackRequested,
}

interface QoSUserFeedbackNegative extends QoSRoomEvent {
  type: QoSEventType.UserFeedbackNegative,
  meta: UserFeedback,
}
interface QoSRoomReload extends QoSRoomEvent {
  type: QoSEventType.RoomReload,
  meta: CallStats | null,
}

interface QoSRoomStateInconsistent extends QoSRoomEvent {
  type: QoSEventType.RoomStateInconsistent,
  tag: string,
  meta: { peerId?: string, producerId?: string },
}

export type QoSEvents =
  QoSNavigateRoomEvent |
  QoSNavigateDevicesEvent |
  QoSRoomJoinAttemptEvent |
  QoSRoomJoinSucceededEvent |
  QoSRoomReJoinAttemptEvent |
  QoSRoomReJoinSucceededEvent |
  QoSRoomParticipantNameInputEvent |
  QoSDevicesReadyEvent |
  QoSDevicesAccessStateEvent |
  QoSDevicesAccessRequestEvent |
  QoSDevicesAccessGrantedEvent |
  QoSDevicesAccessDeniedEvent |
  QoSGotNoneTracks |
  QoSGotSomeTracks |
  QoSGotAllTracks |
  QoSChatInitialized |
  QoSUserFeedbackPositive |
  QoSUserFeedbackRequested |
  QoSUserFeedbackNegative |
  QoSRoomReload |
  QoSRoomStateInconsistent;

export enum QoSEventTag {
  UnsupportedOs = 'unsupported_os',
  VLProjects = 'vlprojects',
  Ignore = 'ignore',
}
