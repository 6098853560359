import {
  action,
  computed,
  observable,
  makeAutoObservable,
} from 'mobx';
import { AxiosError } from 'axios';

import {
  RoomType,
} from 'types/common';
import { RootStore } from 'stores/root';
import { Errors } from 'constants/errors';
import {
  PlaybackEventExtended,
  PlaybackEventType,
  PlaybackForPreview,
  PlaybackTimeline,
} from 'services/MoodHoodApiClient/Playbacks.types';
import { JoinSettings } from 'services/MoodHoodApiClient/types/joinSettings';
import { isInsideIFrame } from 'helpers/browser';
import PlaybackEvents from 'modules/PlaybackEvents/PlaybackEvents';
import {
  JoinPlaybackEventResponse,
  FetchEventInfoPayload,
} from 'services/MoodHoodApiClient/PlaybackEventsApi.types';
import Autowebinar from 'modules/Autowebinar';
import { LANDING_PAGE_URL } from 'constants/common';

class EventStore {
  // fields --------------------------------------------------------------------

  @observable eventAuthenticationFailReason?: string;

  @observable isEventInfoFetched = false;

  @observable isTimelineFetched = false;

  @observable isEventJoined = false;

  @observable isEventJoining = false;

  @observable isSpaceInfoFetched = false;

  @observable isWhiteLabel = false;

  @observable isSlotFullScreen = false;

  @observable joinResult?: JoinPlaybackEventResponse;

  @observable playbackForPreview?: PlaybackForPreview;

  @observable playbackEvent?: PlaybackEventExtended;

  @observable playbackEventsModule: PlaybackEvents = new PlaybackEvents();

  @observable rootStore: RootStore;

  @observable timeline?: PlaybackTimeline;

  @observable autowebinar = Autowebinar.getInstance();

  @observable eventFinished = false;

  @observable joinError: string | undefined;

  @observable isPreview = false;

  @observable isJoinByAlias = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.resetStore();
    makeAutoObservable(this);
  }

  // methods -------------------------------------------------------------------

  @action resetStore(): void {
    this.eventAuthenticationFailReason = undefined;
    this.isEventInfoFetched = false;
    this.isEventJoined = false;
    this.isEventJoining = false;
    this.isSpaceInfoFetched = false;
    this.isWhiteLabel = false;
    this.joinResult = undefined;
    this.playbackEvent = undefined;
    this.playbackForPreview = undefined;
    this.timeline = undefined;
    this.isPreview = false;
    this.isTimelineFetched = false;
    this.isJoinByAlias = false;
  }

  @action async fetchInfo({ id, alias }: FetchEventInfoPayload, spaceId?: string): Promise<void> {
    if (this.isPreview && id && spaceId) {
      await this.fetchPlaybackInfoForPreview(id, spaceId);
      return;
    }

    await this.fetchPlaybackEventInfo({ id, alias });
  }

  @action async fetchPlaybackEventInfo({ id, alias }: FetchEventInfoPayload): Promise<void> {
    let data;
    let error;

    try {
      if (id) {
        const { data: idData, error: idError } = await this.playbackEventsModule.fetchPlaybackEventById(id);
        data = idData;
        error = idError;
      } else if (alias) {
        const { data: aliasData, error: aliasError } = await this.playbackEventsModule.fetchPlaybackEventByAlias(alias);
        data = aliasData;
        error = aliasError;
      } else {
        throw new Error('Missing event id or alias');
      }

      if (data) {
        this.setPlaybackEvent(data);
      } else {
        throw new Error(error);
      }

      this.setIsEventInfoFetched(true);
    } catch (err) {
      this.setIsEventInfoFetched(false);

      if ((err as AxiosError).response?.status === 401) {
        this.setEventAuthenticationFailReason(Errors.AUTH_ERROR);
        return;
      }

      if ((err as AxiosError).response?.status === 404) {
        this.setEventAuthenticationFailReason(Errors.ROOM_NOT_FOUND_BY_ROOM_ID);
        return;
      }

      if ((err as AxiosError).response?.status === 403) {
        this.setEventAuthenticationFailReason(Errors.NO_PERMISSION_FOR_ROOM);
        return;
      }

      this.setEventAuthenticationFailReason(Errors.SOMETHING_WENT_WRONG);
    }
  }

  @action async fetchPlaybackInfoForPreview(id: string, spaceId: string): Promise<void> {
    try {
      const { data, error } = await this.rootStore.moodHoodApiClient.playbacks.fetchPlayback({ id, spaceId });

      if (data) {
        this.setPlaybackForPreview({
          ...data,
          sessionStartDate: new Date(),
        });
      } else {
        throw new Error(error);
      }

      this.setIsEventInfoFetched(true);
    } catch (err) {
      this.setIsEventInfoFetched(false);

      if ((err as AxiosError).response?.status === 401) {
        this.setEventAuthenticationFailReason(Errors.AUTH_ERROR);
        return;
      }

      if ((err as AxiosError).response?.status === 404) {
        this.setEventAuthenticationFailReason(Errors.ROOM_NOT_FOUND_BY_ROOM_ID);
        return;
      }

      if ((err as AxiosError).response?.status === 403) {
        this.setEventAuthenticationFailReason(Errors.NO_PERMISSION_FOR_ROOM);
        return;
      }

      this.setEventAuthenticationFailReason(Errors.SOMETHING_WENT_WRONG);
    }
  }

  @action async fetchSpaceInfo(): Promise<void> {
    this.isSpaceInfoFetched = false;

    if (!this.spaceId) {
      throw new Error('Missing space ID');
    }

    const { space, error } = await this.rootStore.moodHoodApiClient.space.getSpace(this.spaceId);
    if (!space) {
      throw new Error(error);
    }

    this.rootStore.spaceStore.setSpaceInfo({ space });

    this.isSpaceInfoFetched = true;
  }

  @action async fetchPlaybackEventTimeline(): Promise<void> {
    if (!this.playbackEvent) {
      return;
    }

    const { data, error } = await this.playbackEventsModule.fetchPlaybackEventTimeline({
      id: this.playbackEvent.id,
      spaceId: this.playbackEvent.spaceId,
    });

    if (data && !error) {
      this.setTimeline(data);
    } else {
      throw new Error(error);
    }
  }

  @action async fetchPlaybackTimeline(): Promise<void> {
    if (!this.playbackForPreview) {
      return;
    }

    const { data, error } = await this.rootStore.moodHoodApiClient.playbacks.fetchPlaybackTimeline({
      id: this.playbackForPreview.id,
      spaceId: this.playbackForPreview.spaceId,
    });

    if (data && !error) {
      this.setTimeline(data);
    } else {
      throw new Error(error);
    }
  }

  @action async joinParticipant({ clientUniqueId }: { clientUniqueId: string }): Promise<void> {
    this.setIsEventJoining(true);

    let data;
    let error;

    if (this.isPreview && this.spaceId) {
      const playbackJoinResult = await this.rootStore.moodHoodApiClient.playbacks.previewPlayback({
        id: this.id,
        spaceId: this.spaceId,
      });

      data = playbackJoinResult.data;
      error = playbackJoinResult.error;
    } else {
      const playbackEventJoinResult = this.isJoinByAlias
        ? await this.playbackEventsModule.joinPlaybackEventByAlias({
          alias: this.alias,
          clientUniqueId,
        })
        : await this.playbackEventsModule.joinPlaybackEvent({
          id: this.id,
          clientUniqueId,
        });

      data = playbackEventJoinResult.data;
      error = playbackEventJoinResult.error;
    }

    if (data && !error) {
      this.setJoinResult(data);
      this.setIsEventJoining(false);
      this.setIsEventJoined(true);
    } else {
      this.setIsEventJoining(false);
      this.setIsEventJoined(false);
      throw new Error(error);
    }
  }

  @action startEventLoop() {
    if (!this.timeline) {
      return;
    }

    if (!this.joinResult) {
      return;
    }

    if (!this.event) {
      return;
    }

    this.autowebinar.startEventLoop({
      timeline: this.timeline,
      startDate: new Date(this.joinResult.startDate),
      endDate: new Date(this.joinResult.endDate),
      eventType: this.event.type,
      store: this,
    });
  }

  @action stopEventLoop() {
    this.autowebinar.stop();
  }

  leave(): void {
    if (this.joinResult?.id && !this.isPreview) {
      this.rootStore.metrics.marketing.trackLeaveEvent(this.joinResult?.id);
    }

    window.location.href = LANDING_PAGE_URL;
  }

  @action resetJoinError(): void {
    this.joinError = undefined;
  }

  async getPlaybackEventChatToken({
    id,
    clientUniqueId,
    username,
  }: {
    id: string,
    clientUniqueId: string,
    username: string,
  }) {
    const { moodHoodApiClient: { user } } = this.rootStore;

    const { userToken } = await user.getOrCreateEventChatToken({
      id,
      clientUniqueId,
      username,
    });

    return userToken;
  }

  async getPlaybackEventPreviewChatToken({
    id,
    clientUniqueId,
    username,
    spaceId,
  }: {
    id: string,
    clientUniqueId: string,
    username: string,
    spaceId: string,
  }) {
    const { moodHoodApiClient: { user } } = this.rootStore;

    const { userToken } = await user.getOrCreateEventPreviewChatToken({
      id,
      spaceId,
      clientUniqueId,
      username,
    });

    return userToken;
  }

  async getChatUserToken() {
    const { name, clientUniqueId } = this.rootStore.participantStore;
    const username = this.rootStore.userStore.username || name || 'Guest';
    const id = this.event?.id;

    const entity = this.isPreview ? 'playback preview' : 'playback event';

    if (!id || !this.spaceId || !clientUniqueId) {
      const fields = JSON.stringify({
        id,
        clientUniqueId,
        spaceId: this.spaceId,
      }, (_, value: unknown) => (value === undefined ? null : value));

      throw new Error(`Failed to get ${entity} chat token: one of ${fields} is missing`);
    }

    const token = this.isPreview
      ? await this.getPlaybackEventPreviewChatToken({
        id,
        username,
        clientUniqueId,
        spaceId: this.spaceId,
      })
      : await this.getPlaybackEventChatToken({
        id,
        username,
        clientUniqueId,
      });

    if (!token) {
      throw new Error(`Failed to get ${entity} chat token`);
    }

    return token;
  }

  // setters -------------------------------------------------------------------

  @action setIsEventInfoFetched(value: boolean): void {
    this.isEventInfoFetched = value;
  }

  @action setIsEventJoined(value: boolean): void {
    this.isEventJoined = value;
  }

  @action setIsEventJoining(value: boolean): void {
    this.isEventJoining = value;
  }

  @action setIsWhiteLabel(value: boolean) {
    this.isWhiteLabel = value;
  }

  @action setJoinResult(value: JoinPlaybackEventResponse) {
    this.joinResult = value;
  }

  @action setPlaybackEvent(value: PlaybackEventExtended): void {
    this.playbackEvent = value;
  }

  @action setPlaybackForPreview(playback: PlaybackForPreview): void {
    this.playbackForPreview = playback;
  }

  @action setTimeline(value: PlaybackTimeline): void {
    this.timeline = value;
    this.isTimelineFetched = true;
  }

  @action setEventAuthenticationFailReason(reason: Errors): void {
    this.eventAuthenticationFailReason = reason;
    this.rootStore.uiStore.showErrorPage(reason);
  }

  @action setIsSlotFullScreen(value: boolean): void {
    this.isSlotFullScreen = value;
  }

  @action setJoinError(value: string | undefined) {
    this.joinError = value;
  }

  @action setIsPreview(value: boolean): void {
    this.isPreview = value;
  }

  @action setEventFinished(value: boolean) {
    this.eventFinished = value;
  }

  @action setIsJoinByAlias(value: boolean): void {
    this.isJoinByAlias = value;
  }

  // getters -------------------------------------------------------------------

  @computed get event() {
    return this.playbackEvent || this.playbackForPreview;
  }

  // TODO: legacy
  @computed get roomType() {
    return this.event?.type === PlaybackEventType.Webinar ? RoomType.Webinar : RoomType.Lesson;
  }

  // TODO: legacy
  @computed get isWebinar(): boolean {
    return this.roomType === RoomType.Webinar;
  }

  @computed get name(): string {
    return this.event?.name || '';
  }

  @computed get id(): string {
    return this.event?.id || '';
  }

  @computed get alias(): string {
    return this.event?.alias || '';
  }

  @computed get joinSettings(): JoinSettings {
    return this.event?.joinSettings || { fields: [] };
  }

  @computed get isIframe() {
    return isInsideIFrame();
  }

  @computed get spaceId() {
    return this.event?.spaceId;
  }

  @computed get mediaUrl() {
    return this.joinResult?.mediaUrl;
  }
}

export default EventStore;
