import styled from '@emotion/styled';

import { FigmaTypography } from 'ui-kit';
import { textOverflowStyle } from 'ui-kit/theme';

const Layout = styled(FigmaTypography)<{ color: string, backgroundColor: string }>(({
  color,
  backgroundColor,
}) => ({
  height: 20,
  color,
  flexShrink: 0,
  backgroundColor,
  borderRadius: 5,
  padding: '2px 8px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: 'fit-content',
  ...textOverflowStyle,
}));

Layout.defaultProps = {
  as: 'div',
  size: 'label-text-bold',
};

export default Layout;
