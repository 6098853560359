import { serializeError } from 'serialize-error';
import debug from 'debug';
import { MessageBatcher } from 'message-batcher';
import { getClientTabId, getClientUniqueId, receiveClientTrackingId } from './common';
import LogApi from '../services/MoodHoodAnalyticsApiClient/LogApi';
import analyticsApiClient from '../services/MoodHoodAnalyticsApiClient';
import { LogRecordMeta, Logger as LoggerInterface } from '../types/logger';
import { getLocalStorageStringItem } from './localStorage';
import { isInsideIFrame } from './browser';

export type LogLevel = 'error' | 'warn' | 'info' | 'debug';

const knownLogLevels = new Set<LogLevel>(['debug', 'info', 'warn', 'error']);

const isInIframe = isInsideIFrame();

export type LogToRemotePayload = { level: LogLevel, message: string, meta?: LogRecordMeta };

export const castToKnownLogLevel = (
  level: string,
  fallbackLevel: LogLevel = 'warn',
): LogLevel => {
  if (knownLogLevels.has(level as LogLevel)) {
    return level as LogLevel;
  }

  return fallbackLevel;
};

class Logger implements LoggerInterface {
  private readonly rootNamespaceName = 'SPA';

  private readonly _debug: debug.Debugger;

  private readonly _info: debug.Debugger;

  private readonly _warn: debug.Debugger;

  private readonly _error: debug.Debugger;

  private readonly namespace: string;

  private logToRemoteBatcher: MessageBatcher;

  private logApiClient: LogApi;

  constructor(namespace?: string) {
    this.namespace = namespace || this.rootNamespaceName;
    this.logToRemoteBatcher = new MessageBatcher({ MaxBatchSize: 50, MaxDelay: 1000, MinDelay: 100 });
    this.logToRemoteBatcher.on('batch', (logs: LogToRemotePayload[]) => {
      this.logToApi(logs);
    });

    if (!getLocalStorageStringItem('debug')) {
      debug.enable([
        `${this.rootNamespaceName}:W`,
        `${this.rootNamespaceName}:E`,
      ].join(','));
    }

    this._debug = debug(this.namespace).extend('D');
    this._info = debug(this.namespace).extend('I');
    this._warn = debug(this.namespace).extend('W');
    this._error = debug(this.namespace).extend('E');

    /* eslint-disable no-console */
    this._debug.log = console.log.bind(console);
    this._info.log = console.info.bind(console);
    this._warn.log = console.warn.bind(console);
    this._error.log = console.error.bind(console);
    /* eslint-enable no-console */

    this.logApiClient = analyticsApiClient.log;
  }

  debug(message: string, meta?: LogRecordMeta): void {
    this._debug(message, meta);
    this.logToRemote({ level: 'debug', message, meta: Logger.prepareRecordMeta(meta) });
  }

  info(message: string, meta?: LogRecordMeta): void {
    this._info(message, meta);
    this.logToRemote({ level: 'info', message, meta: Logger.prepareRecordMeta(meta) });
  }

  warn(message: string, meta?: LogRecordMeta): void {
    this._warn(message, meta);
    this.logToRemote({ level: 'warn', message, meta: Logger.prepareRecordMeta(meta) });
  }

  error(message: string, meta?: LogRecordMeta): void {
    this._error(message, meta);
    this.logToRemote({ level: 'error', message, meta: Logger.prepareRecordMeta(meta) });
  }

  log(logLevel: LogLevel, message: string, meta?: LogRecordMeta): void {
    switch (logLevel) {
      case 'debug':
        return this.debug(message, meta);
      case 'info':
        return this.info(message, meta);
      case 'warn':
        return this.warn(message, meta);
      case 'error':
        return this.error(message, meta);
      default:
        throw new Error('Unknown log level');
    }
  }

  extend(namespace: string): Logger {
    return new Logger(`${this.namespace}:${namespace}`);
  }

  private logToRemote(payload: LogToRemotePayload): void {
    this.logToRemoteBatcher.Queue(payload);
  }

  private async logToApi(logs: LogToRemotePayload[]): Promise<void> {
    const { error } = await this.logApiClient.batch({
      logs,
      clientMeta: Logger.getClientMeta(),
    });

    if (error) {
      this._debug('Log request was not successful', { error });
    }
  }

  private static getClientMeta() {
    const {
      peerId,
      participantId,
      participantName,
      roomId,
      roomAlias,
      spaceId,
      email,
      userId,
      callId,
    } = window.lsd || {};

    return {
      peerId,
      participantId,
      participantName,
      email,
      roomId,
      roomAlias,
      spaceId,
      userId,
      callId,
      tId: receiveClientTrackingId(),
      tabId: getClientTabId(),
      clUId: getClientUniqueId(),
      isCanary: Boolean(process.env.REACT_APP_IS_CANARY),
      ...(isInIframe ? { iFrame: isInIframe } : {}),
    };
  }

  private static prepareRecordMeta(metaData?: LogRecordMeta) {
    const meta = metaData || {};
    const { error } = meta;
    return {
      ...meta,
      clientTimestamp: metaData?.clientTimestamp || new Date(),
      from_spa: true,
      ...(error && typeof error === 'object' ? { error: serializeError(error) } : {}),
    };
  }
}

const logger = new Logger();

export default logger;
