import { lock } from 'decorators';
import {
  makeAutoObservable, action, observable, runInAction, computed,
} from 'mobx';

import { Plan } from 'types/payment';
import PaymentsApi from 'services/MoodHoodApiClient/PaymentsApi';
import Api from 'services/MoodHoodApiClient/Api';

import logger from '../helpers/logger';

class PaymentStore {
  private paymentsApi: PaymentsApi;

  @observable isPaymentCongratsDialogOpen = false;

  @observable isPaymentInProgress = false;

  @observable paymentPlans: Plan[] = [];

  @observable isPlansFetching = false;

  @observable currentPlan: Plan | null = null;

  @observable isShowPriceParamInURL = false;

  constructor() {
    this.paymentsApi = new PaymentsApi(Api);
    makeAutoObservable(this);
  }

  @computed get isPlansFetched() {
    return this.paymentPlans.length > 0;
  }

  @action openPaymentCongratsDialog(): void {
    this.isPaymentCongratsDialogOpen = true;
  }

  @action closePaymentCongratsDialog(): void {
    this.isPaymentCongratsDialogOpen = false;
  }

  @action setPlanPaymentInProgress(): void {
    this.isPaymentInProgress = true;
  }

  @action setPlanPaymentComplete(): void {
    this.isPaymentInProgress = false;
    this.openPaymentCongratsDialog();
  }

  @lock('isPlansFetching', false)
  @action async loadPlans(): Promise<void> {
    const { data: plans, error } = await this.paymentsApi.getPlans();
    if (plans) {
      this.setPaymentPlans(plans);
    } else {
      logger.error('Failed to load plans', { error });
    }
  }

  @action setPaymentPlans(value: Plan[]) {
    this.paymentPlans = value;
  }

  @lock('isPlansFetching', false)
  @action async getCurrentPlan(spaceId: string) {
    const { data: plan, error } = await this.paymentsApi.getCurrentPlan(spaceId);
    if (error) {
      logger.error('Failed to get current plan', { error });
    }

    return plan || null;
  }

  @action async setCurrentPlan(spaceId: string): Promise<void> {
    if (!spaceId) {
      return;
    }

    const currentPlan = await this.getCurrentPlan(spaceId);

    runInAction(() => {
      this.currentPlan = currentPlan;
    });
  }

  @action reset() {
    this.isPaymentCongratsDialogOpen = false;
    this.currentPlan = null;
    this.isPaymentInProgress = false;
    this.isPlansFetching = false;
    this.paymentPlans = [];
  }
}

export default PaymentStore;
