import styled from '@emotion/styled';
import { InfoCircleIcon } from 'ui-kit/assets/icons';
import { Color } from 'ui-kit/theme';

export const TooltipTitle = styled.div({
  maxWidth: 264,
});

export const InfoIcon = styled(InfoCircleIcon)({
  display: 'inline-block',
  verticalAlign: 'middle',
  height: 18,
  color: Color.GREY19,
});
