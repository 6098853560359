import React, { FunctionComponent, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useStores from 'stores/root';
import { toast } from 'react-toastify';

import {
  DesktopLayout,
  Inputs,
  Input,
  InputButton,
} from '../index.styled';

const Oauth2ClientSettings: FunctionComponent = observer(() => {
  const { oauth2ClientStore, spaceStore: { space } } = useStores();
  const { t } = useTranslation();

  const handleCreateClick = async () => {
    if (!space?.id || oauth2ClientStore.isExists) {
      return;
    }

    await oauth2ClientStore.create(space.id, (err: string) => toast.error(err));
  };

  const handleRefreshClick = async () => {
    if (!space?.id || !oauth2ClientStore.isExists) {
      return;
    }

    await oauth2ClientStore.delete(space.id, (err: string) => toast.error(err));
    await oauth2ClientStore.create(space.id, (err: string) => toast.error(err));
  };

  const loadClient = async () => {
    if (!space?.id) {
      return;
    }

    await oauth2ClientStore.get(space.id, (err: string) => toast.error(err));
  };

  useEffect(() => {
    loadClient();
  }, []);

  const Layout = DesktopLayout;

  return (
    <Layout>
      <Inputs>
        <Input
          id="client-id"
          fullWidth
          label="Client ID"
          value={oauth2ClientStore.clientId || ''}
          readOnly
          onClick={(e) => e.currentTarget?.select()}
          endInputElement={(
            (oauth2ClientStore.clientId ? undefined : (
              <InputButton
                id="save-username"
                buttonSize="small"
                variant="primary"
                onClick={handleCreateClick}
              >
                {t('common.get')}
              </InputButton>
            ))
          )}
        />
        <Input
          id="client-secret"
          fullWidth
          label="Client secret"
          value={oauth2ClientStore.clientSecret}
          readOnly
          onClick={(e) => e.currentTarget?.select()}
          placeholder={oauth2ClientStore.clientId ? '********************' : ''}
          endInputElement={(
            <InputButton
              id="refresh-secret"
              variant="primary"
              onClick={handleRefreshClick}
            >
              {t('common.refresh')}
            </InputButton>
          )}
        />
      </Inputs>
    </Layout>
  );
});

export default Oauth2ClientSettings;
