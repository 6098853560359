/* eslint-disable max-len */
export default {
  translation: {
    common: {
      downloadApp: 'Скачать приложение',
      loading: 'Загрузка...',
      audioOutput: 'Динамики',
      audioOutputTest: 'Проверка звука',
      pipOpen: 'Режим «Картинка в картинке»',
      pipClose: 'Выйти из режима «Картинка в картинке»',
      pip: 'Картинка в картинке',
      retry: 'Повторить',
      name: 'Имя',
      create: 'Создать',
      close: 'Закрыть',
      title: 'Заголовок',
      email: 'E-mail',
      room: 'Комната',
      slot: 'Слот',
      save: 'Сохранить',
      join: 'Присоединиться',
      startConference: 'Начать конференцию',
      startWebinar: 'Начать вебинар',
      logout: 'Выйти',
      value: 'Значение',
      selectInputPlaceHolder: 'Выберите...',
      participant: 'Участник',
      microphone: 'Микрофон',
      screenShare: 'Демонстрация',
      fullScreenEnter: 'Окно браузера во весь экран',
      fullScreenExit: 'Выйти из полноэкранного режима',
      slotFullScreenEnter: 'Видео во весь экран',
      slotFullScreenExit: 'Выйти из режима "Видео во весь экран"',
      camera: 'Камера',
      language: 'Язык',
      yes: 'Да',
      no: 'Нет',
      delete: 'Удалить',
      authenticating: 'Аутентификация',
      to: 'В',
      myCamera: 'Моя камера',
      comment: 'Комментарий',
      empty: 'Пусто',
      add: 'Добавить {{type}}',
      type: 'Тип',
      edit: 'Изменить',
      cancel: 'Отменить',
      cancel2: 'Отмена',
      ok: 'Ок',
      search: 'Найти',
      description: 'Описание',
      settings: 'Настройки',
      image: 'Изображение',
      photo: 'Фото',
      yourPhoto: 'Ваше фото',
      general: 'Общие',
      account: 'Учетная запись',
      users: 'Пользователи',
      usersOnline: 'Пользователей онлайн',
      view: 'Сменить вид',
      continue: 'Продолжить',
      continueInApp: 'Продолжить в приложении',
      continueInBrowser: 'Продолжить в браузере',
      continueWithoutDevices: 'Продолжить без устройств',
      enterName: 'Введите Имя',
      enterText: 'Введите Текст',
      offline: 'Вне сети',
      space: 'Группа',
      scenarios: 'Сценарии',
      auto: 'Автоматически',
      chat: 'Чат',
      owner: 'Владелец',
      moderator: 'Администратор',
      user: 'Пользователь',
      guest: 'Гость',
      private: 'Приватные',
      public: 'Публичные',
      notFound: 'Не найдено',
      profile: 'Профиль',
      devices: 'Устройства',
      selectPhoto: 'Выбрать фото',
      select: 'Выбрать',
      selectReplace: 'Выбрать и заменить',
      userPhotoChange: 'Изменить',
      userPhotoAdd: 'Добавить',
      userPhoto: 'фото',
      share: 'Поделиться',
      help: 'Справка',
      support: 'Поддержка',
      helpCenter: 'Центр помощи',
      options: 'Настройки',
      turnOn: 'Включить',
      turnOff: 'Выключить',
      leaveRoom: 'Покинуть встречу',
      leaveIntoRoomsList: 'Выйти в список комнат',
      leaveAndFinishForAll: 'Завершить для всех и выйти',
      redirectHint: 'Вы будете перенаправлены на главную страницу через {{ counter }} секунд',
      notAuthorized: 'Вы не авторизованы',
      spaceBtn: 'Пробел',
      download: 'Загрузить',
      download2: 'Скачать',
      statistics: 'Статистика',
      startDate: 'Начало',
      endDate: 'Конец',
      reset: 'Сбросить',
      information: 'Информация',
      bugReport: 'Сообщить о проблеме',
      participants: 'Участники',
      moderators: 'Администраторы',
      speakers: 'Спикеры',
      noSlotsParticipants: 'Вне слотов',
      until: 'До {{endOfSubscription}}',
      change: 'Изменить',
      password: 'Пароль',
      displayName: 'Имя',
      submit: 'Подтвердить',
      registration: 'Зарегистрироваться',
      knowledgeBase: 'База знаний',
      faq: 'FAQ',
      integration: 'Интеграция',
      refresh: 'Обновить',
      reload: 'Перезагрузить',
      refreshThePage: 'Обновить страницу',
      openHomePage: 'Перейти на главную страницу',
      get: 'Получить',
      installIOSAlert: {
        title: 'Попробуйте приложение',
        subtitle1: 'Ведите занятия и вебинары с мобильного:',
        subtitle2: 'демонстрация материалов',
        subtitle3: 'полноэкранный интерфейс',
        subtitle4: 'и модерация чата.',
        installButton: 'Установить',
        skipButton: 'Пропустить',
      },
      page: 'Страница',
      allow: 'Разрешить',
      forbid: 'Запретить',
      raiseHand: 'Поднять руку',
      putHandDown: 'Опустить руку',
      raisedHands: 'Поднятые руки',
      backToSpaces: 'Назад к группам',
      announcements: 'Рекламные объявления',
      breakoutRooms: 'Сессионные залы',
      reactions: 'Реакции',
      clear: 'Очистить',
      requiredToFill: 'Обязательно для заполнения',
      required: 'Обязательно',
      optional: 'Необязательно',
      warnings: {
        datePickerWarning: 'Дата должна быть в диапазоне от {{ startDate }} до {{ endDate }}',
        callTimeRestrictionWarning: {
          message1: 'Время встречи в тарифе {{ freePlanName }} ограничено {{ kickOutTimeMin }} минутами.',
          message2: 'До завершения сессии ',
          message3: ' минут',
          messageElectron: 'До завершения сессии {{ time }}',
        },
        callTimeRestrictionPlansButton: 'Выбрать тариф и продлить время',
      },
      inputPeriodLabel: 'Укажите дату или диапазон дат',
      current: 'Текущий',
      monthly: 'Месячный',
      annual: 'Годовой',
      month: 'месяц',
      brand: {
        livedigital: 'livedigital',
        moodhood: 'courseboost',
        teleboss: 'TeleBoss',
      },
      company: {
        livedigital: 'ООО «ЛайвДиджитал»',
        moodhood: 'Courseboost LLC',
        teleboss: 'ООО «Телебосс»',
      },
      pageTitle: {
        livedigital: 'livedigital',
        moodhood: 'courseboost',
        teleboss: 'TeleBoss',
        description: 'Онлайн-встречи и вебинары',
      },
      sort: 'Сортировка',
      spaceUsers: 'Пользователи группы',
      spaceSettings: 'Настройки группы',
      schedule: 'Расписание',
      preview: 'Предпросмотр',
      video: 'Видео',
      weekDaysShort: 'Пн Вт Ср Чт Пт Сб Вс',
      timeZone: 'Часовой пояс',
      repeating: 'Повторение',
      notAdded: 'Не добавлено',
      videoUpload: 'Загрузка видео',
      themeAndLanguage: 'Цветовая тема и язык',
      link: 'Ссылка',
      key: 'Ключ',
      copied: 'Скопировано',
      copyLink: 'Скопировать ссылку',
      on: 'Вкл',
      off: 'Выкл',
      sound: 'Звук',
      rooms: 'Комнаты',
    },
    join: {
      signIn: 'Войти c аккаунтом',
      joinedAs: 'Вы вошли как',
      changeAccount: 'Войти под другим аккаунтом',
      step1: {
        field: 'Имя пользователя',
        label: 'Введите ваше имя',
        agreements1: 'Нажимая "{{continue}}", вы подтверждаете, что прочитали и согласились с нашими ',
        agreements2: 'Условиями использования',
        agreements3: ' и ',
        agreements4: 'Политикой конфиденциальности',
        clientAgreement1: ', а также с ',
      },
      step4: {
        joinTitle: 'Вы собираетесь войти в комнату {{name}}',
        hint: 'Никто не услышит и не увидит вас, пока вы не присоединитесь к этому событию',
        title: 'Выберите ваши устройства',
        backToRooms: 'Назад в список событий',
      },
      deviceWizard: {
        begin: 'Начать',
        viewManual: 'Посмотреть инструкцию',
        requestAccess: 'Запросить доступ',
        accessGranted: 'Доступ открыт',
        TopBlockSafariError: 'У вашего браузера заблокирован доступ к устройствам',
        BottomBlockHint: {
          title: 'Разрешите доступ к вашим устройствам',
          hint: 'Чтобы другие могли видеть и слышать вас, ваш браузер запросит доступ к вашей камере и микрофону.',
        },
        BottomBlockChromeError: {
          title: 'У вашего браузера заблокирован доступ к устройствам',
          hint: [
            'Нажмите на значок камеры в крайнем правом углу строки URL-адреса.',
            'Выберите "Всегда разрешать", а затем "Готово".',
          ],
        },
        BottomBlockSafariError: [
          'Необходимо разрешить доступ к вашей камере и микрофону, чтобы присоединиться.',
          'Повторите попытку и выберите',
          ' "Разрешить" ',
          'при появлении запроса.',
        ],
      },
    },
    auth: {
      welcome: 'Добро пожаловать!',
      forgotPassword: 'Забыли пароль?',
      signIn: 'Войти',
      capsLockIsOn: 'CapsLock включен',
      and: ' и ',
      passwordIsRequired: 'Требуется пароль',
      passwordMinLength: 'Пароль не должен быть короче {{value}} символов.',
      enterYourPassword: 'Введите ваш пароль',
      createYourPassword: 'Создайте пароль от 6-ти символов',
      password: 'Пароль',
      emailIsRequired: 'Требуется электронная почта',
      phoneIsRequired: 'Требуется номер телефона',
      emailMustBeAValid: 'Адрес электронной почты должен быть действительным',
      phoneMustBeAValid: 'Номер телефона должен быть действительным',
      enterYourEmail: 'Введите ваш e-mail',
      enterYourPhone: 'Введите свой номер телефона',
      email: 'E-mail',
      phone: 'Номер телефона',
      phoneMinLength: 'Номер телефона не должен быть короче {{value}} символов.',
      phoneMaxLength: 'Номер телефона не может быть длиннее {{value}} символов',
      signUp: 'Регистрация',
      donTHaveAnAccount: 'Нет учетной записи? ',
      wrongUsername: 'Неверное имя пользователя',
      orPassword: 'или пароль',
      agreements1: 'Нажимая "{{registration}}", вы подтверждаете, что прочитали и согласились с нашими ',
      agreements2: 'Условиями использования',
      agreements3: ' и ',
      agreements4: 'Политикой конфиденциальности',
      usernameMaxLength: 'Имя пользователя не может быть длиннее {{value}} символов',
      usernameMinLength: 'Имя пользователя не должно быть короче {{value}} символов.',
      usernameIsRequired: 'Имя пользователя обязательно',
      usernameNEEmail: 'Имя не может совпадать с электронной почтой.',
      enterYourUsername: 'Введите свое имя пользователя',
      username: 'Имя пользователя',
      alreadyHaveAnAccount: 'У вас уже есть учетная запись? ',
      emailIsAlreadyTaken: 'Аккаунт с таким e-mail уже существует. Попробуйте ввести другой e-mail или войдите в существующий аккаунт.',
      passwordRecovery: 'Восстановления пароля',
      hintForPasswordRecovery: 'Введите email указанный при регистрации и мы вышлем вам ссылку для восстановления пароля',
      getLink: 'Получить ссылку',
      enterEmail: 'Введите почту',
      checkYourEmail: 'Проверьте вашу почту {{email}}',
      agree: 'Я хочу получать рассылки о продукте {{brand}} от {{company}}.',
      agreeIsRequired: 'Требуется согласие',
      purposeUsing: 'Цель использования {{brand}}',
      purposeUsingPlaceholder: 'Выберите цель использования',
      personal: 'Личное использование',
      company: 'Решение для компании',
    },
    moderator: {
      afterSignupMobileAlert: 'Полный функционал доступен в декстопной версии',
      moveParticipant: 'Переместить',
      reJoin: 'Переподключить',
      kick: 'Отключить',
      chooseRoom: 'Выберите комнату',
      chooseSlot: 'Выберите слот',
      notifications: {
        roomLoadingError: 'Ошибка загрузки комнат',
        moveFail: 'Не удалось переместить участника',
        moveFailOfPermissionsErr: 'Не удалось переместить участника. Пожалуйста, убедитесь, что участник имеет права на вход в указанную вами комнату',
        moveScreenSharingFail: 'Невозможно переместить скриншаринг',
        reJoinFail: 'Ошибка переподключения участника',
        kickFail: 'Ошибка удаления участника',
      },
      disableCamera: 'Заблокировать камеру участнику',
      disableMicrophone: 'Заблокировать микрофон участнику',
      enableCamera: 'Разблокировать камеру участнику',
      enableMicrophone: 'Разблокировать микрофон участнику',
      turnOffCamera: 'Выключить камеру участнику',
      turnOffMicrophone: 'Выключить микрофон участнику',
      usersDlgDescription: 'Управляйте доступом пользователей, назначая им роли',
      usersInSpace: 'Пользователи в {{ spaceName }}',
      dumpPeerInfo: 'Выгрузить отладочную информацию участника',
      dumpTrackInfo: 'Выгрузить отладочную информацию трэка',
      dumpRoomInfo: 'Выгрузить отладочную информацию',
      setOutOfScreen: 'Убрать с экрана',
      setOnScreen: 'Поместить на экран',
      peerOnScreenInWebinarReachedLimit: 'На экране можно показывать только 3 спикера одновременно',
      participantRequest: 'Запрос на вход в комнату',
      participantAwaits: '{{name}} запрашивает вход в комнату',
      disableScreenDemonstration: 'Отключить демонстрацию',
    },
    errors: {
      spaceWithIdNotFound: 'Группа с идентификатором "{{spaceId}}" не найдена',
      miroPickerError: 'Не удалось загрузить доску',
      pipStartError: 'Ошибка открытия "Картинки в картинке": {{ error }}',
      roomRecordError: 'Ошибка записи комнаты',
      somethingWentWrong: 'Что-то пошло не так',
      somethingWentWrongBody: 'Попробуйте перезагрузить страницу или попробовать позже',
      participantNotFound: 'Пользователь не существует. Возможно он удалён администратором.',
      invalidSpace: 'Вы зарегистрированы в другой группе',
      required: 'Обязательное поле',
      invalidEmail: 'Неверный адрес электронной почты',
      participantNotRegistered: 'Участник не зарегистрирован',
      participantAlreadyOnline: 'Участник уже онлайн на другом устройстве',
      noEntryRoomSpecified: 'Не указана комната для входа',
      roomNotFound: 'Комната не найдена',
      accessDenied: 'Доступ запрещен',
      noFreePlacesInRoom: 'Нет свободных мест в комнате',
      scenarioNotFound: 'Сценарий не найден',
      shortcutNotFound: 'Ярлык не найден',
      actionNotFound: 'Действие не найдено',
      yourDeviceIsDisconnected: 'Ваше устройство отключено от группы',
      deleteRecordError: 'Не удалось удалить запись',
      reportDownloadError: 'Не удалось загрузить статистику',
      reportNoDataError: 'Нет данных за указанный период',
      dndDestSlotIsBoard: 'Слот занят доской Miro, закройте её, чтобы освободить слот',
      userRoleChangeFailed: 'Не удалось изменить роль пользователя',
      alreadyTranslatingSlotToRoom: 'Слот уже транслируется в выбранную комнату',
      alreadyTranslatingToSlot: 'В выбранный слот уже запущена другая трансляция',
      noAvailableDevice: 'Нет доступных устройств',
      devicesEnumerateError: 'Не удалось получить список устройств',
      devicesPermissionError: 'Доступ к устройствам заблокирован',
      videoDevicesPermissionError: 'Не удалось получить доступ к камере. Пожалуйста, убедитесь, что доступ к устройству разрешен, что оно не используется в другом приложении и не блокируется на уровне операционной системы',
      audioDevicesPermissionError: 'Не удалось получить доступ к микрофону. Пожалуйста, убедитесь,  что доступ к устройству разрешен, что оно не используется в другом приложении и не блокируется на уровне операционной системы',
      screenPermissionError: 'Не удалось получить доступ к трансляции экрана. Пожалуйста, убедитесь,  что доступ к экрану разрешен и не блокируется на уровне операционной системы',
      devicesTrackError: 'Не удалось создать медиа трек',
      devicesGeneralError: 'Ошибка медиа устройства',
      tooShortName: 'Слишком короткое имя',
      joinProblems: 'Проблемы со входом',
      deviceNotSupportedError: 'Браузер или устройство не поддерживается. Попробуйте сменить браузер или устройство. Если ошибка все равно появляется, пожалуйста, обратитесь в службу поддержки',
      cookiesBlockedError: 'Ваш браузер блокирует cookie. Для правильной работы приложения необходимо разрешить использование файлов cookie в настройках вашего браузера',
      authenticationError: {
        text: 'Ошибка аутентификации',
        hint: 'Ваш браузер блокирует cookie. Для правильной работы приложения необходимо разрешить использование файлов cookie в настройках вашего браузера',
      },
      thisRoomDoesNotExist: {
        errorText: 'Эта комната не существует',
        hint: 'Проверьте корректность введенной ссылки',
      },
      noPermissionToEnterRoom: {
        errorText: 'У вас нет разрешения на вход в эту комнату',
        hint: 'Обратитесь к администратору комнаты',
      },
      noPermissionToEnterSpace: {
        errorText: 'У вас нет разрешения на вход в эту группу',
        hint: 'Обратитесь к администратору группы',
      },
      invalidRoomInvite: {
        errorText: 'Приглашение в комнату недействительно',
        hint: 'Обратитесь к администратору комнаты',
      },
      invalidSpaceInvite: {
        errorText: 'Приглашение в группу не действительно',
        hint: 'Обратитесь к администратору группы',
      },
      TechnicalWork: {
        errorText: 'Ведутся технические работы',
        hint: 'Пожалуйста, попробуйте позднее',
      },
      inviteExpiredOrNotExists: {
        errorText: 'Приглашение в группы недействительно',
        hint: 'Обратитесь к администратору группы',
      },
      noPermissionForSpace: {
        errorText: 'У вас нет разрешения на вход в этот space',
        hint: 'Обратитесь к администратору space',
      },
      confirmationTokenInvalid: {
        errorText: 'Токен подтверждения невалиден или был использован ранее',
        hint: 'Попробуйте запросить другую ссылку с необходимым токеном',
      },
      noPermissionForRoom: {
        errorText: 'У вас нет разрешения на вход в эту комнату',
        hint: 'Обратитесь к администратору комнаты',
      },
      inviteConflict: {
        errorText: 'Пользователь уже приглашен в эту группу',
      },
      problemWithDevices: 'Проблема с устройствами',
      mobileJoinToastHint: 'Перейдите в справку для дополнительной информации',
      blockedByAntivirus: 'Соединение заблокировано антивирусом',
      blockedByFirewall: 'Соединение заблокировано файерволом (сетевым экраном)',
      screensharingTogglePolicyError: 'Не удалось включить демонстрацию экрана в связи с политикой безопасности приложения',
      screensharingTogglePermissionError: 'Не удалось включить демонстрацию экрана. Пожалуйста, убедитесь, что вы выдали разрешение на демонстрацию экрана',
      screensharingToggleUnexpectedError: 'Не удалось включить демонстрацию экрана',
      turnOffDevicesError: 'Не удалось выключить устройства',
      switchOffDevicesError: 'Не удалось выключить устройства',
      disableDevicesError: 'Не удалось заблокировать устройство',
      enableDevicesError: 'Не удалось разблокировать устройство',
      failedToFetchTimeZones: 'Ошибка при загрузке часовых поясов',
      chatReload: {
        line1: 'Ой, чат не загрузился.',
        line2: 'Нажмите "{{ reload }}", чтобы попробовать снова.',
      },
    },
    room: {
      backToSpace: 'Вернуться к группе',
      talkWhileMuted: 'Включите микрофон, Вас никто не слышит :)',
      invite: 'Пригласить',
      inviteParticipant: 'Пригласить участника',
      spaceUsers: 'Пользователи группы',
      copied: 'Ссылка скопирована',
      selectSharingSlot: 'Выберите слот для демонстрации экрана',
      confirmExit: {
        title: 'Подтверждение выхода',
        text: 'Вы уверены, что хотите выйти из комнаты?',
        confirmButton: 'Выйти',
        dismissButton: 'Отмена',
      },
      confirmKickAllAndExit: {
        title: 'Подтверждение выхода',
        text: 'Вы уверены, что хотите отключить всех участников и выйти из комнаты?',
        confirmButton: 'Отключить всех и выйти',
        dismissButton: 'Отмена',
      },
      participantNameStub: 'Участник не указал имя',
      registrationHint: {
        title: 'Начните использовать {{brand}}',
        text: 'Зарегистрируйтесь и проводите свои собственные конференциии',
      },
      inviteHint: {
        title: 'Пригласите участников',
        text: 'Нажмите на кнопку и отправьте ссылку тем, кого хотите пригласить в эту конференцию',
        buttonText: 'Копировать ссылку',
      },
      microphone: {
        turnOn: 'Включить',
        turnOff: 'Выключить',
        turnOffMic: 'Выключить микрофон',
        turnOnMic: 'Включить микрофон',
        turnOnMicrophone: 'Включить свой микрофон',
        turnOffMicrophone: 'Выключить свой микрофон',
        microphoneIsOff: 'Микрофон выключен',
        microphoneIsOn: 'Микрофон включен',
        turnOffForAll: 'Выключить микрофон всем',
        changeInput: 'Сменить микрофон',
        changeOutput: 'Сменить динамик',
        settings: 'Настройки микрофона',
        enableNoiseSuppression: 'Включить программное шумоподавление',
        noiseSuppressionError: 'Ошибка модуля шумоподавления',
        microphoneOffWarning: 'Вас не слышат, ваш микрофон выключен',
        microphoneNotReactingWarning: 'Микрофон "{{ microphoneName }}" включен, но не реагирует за звук и голос.\nПопробуйте сменить на другой доступный микрофон или настройте устройство самостоятельно.',
        devicesSettings: 'Настройки устройств',
        changeMicrophone: 'Сменить микрофон',
        microphoneIsChanged: 'Смена микрофона на {{ microphoneName }}',
      },
      camera: {
        turnOn: 'Включить',
        turnOff: 'Выключить',
        turnOffCam: 'Выключить камеру',
        turnOnCam: 'Включить камеру',
        turnOnCamera: 'Включить свою камеру',
        turnOffCamera: 'Выключить свою камеру',
        cameraIsOff: 'Камера выключена',
        cameraIsOn: 'Камера включена',
        turnOffForAll: 'Выключить камеры всем',
        changeInput: 'Сменить камеру',
        settings: 'Настройки камеры',
        enableBackgroundBlur: 'Включить размытие заднего фона',
        backgroundBlurError: 'Ошибка модуля виртуальных фонов',
      },
      demo: {
        demoButtonHintTitle1: '{{ names }} {{ wordsEndingDemos }}',
        demoButtonHintTitle1AndMore: ' и еще {{ count }}',
        demoButtonHintContent1: 'Можно показывать только {{ limit }} экрана демонстрации',

        screenSharing: 'Демонстрация экрана',
        screenSharingStart: 'Начать демонстрацию экрана',
        screenSharingStop: 'Завершить демонстрацию экрана',
        screenSharingChange: 'Сменить экран',

        board: 'Доска Miro',
        boardInUse: 'Доска Miro уже используется',
        boardLimit: 'Можно использовать только один показ доски Miro',
        boardLimitNoBoard: 'Можно использовать только два слота для демонстрации',
        boardStart: 'Начать показ Miro',
        boardStop: 'Завершить показ Miro',
        boardChange: 'Сменить доску',
        boardStartError: 'Ошибка при запуске доски Miro',

        available: 'Свободно',
        notAvailable: 'Недоступно',
        taken: 'Занято',
        usedByMe: 'Вы используете',
        demoUsed: '{{ userName }} использует демонстрацию экрана',
        broadcast: 'Трансляция',
        broadcastInfo: 'Информация о трансляции (ссылка и ключ)',
        broadcastStart: 'Начать трансляцию',
        broadcastStop: 'Завершить трансляцию',
        broadcastLimit: 'Вы можете использовать только одну трансляцию',
        broadcastStartError: 'Ошибка при запуске трансляции',
        broadcastStopError: 'Ошибка при завершении трансляции',
        broadcastDlgTitle: 'Создание трансляции',
        broadcastDlgText: 'Скопируйте ссылку и ключ для вставки в видеокодер (OBS) и запустите трансляцию из программы.',
        broadcastDlgText2: 'Видеопоток начнет отображаться в этом окне.',
        broadcastInfoTitle: 'Информация о трансляции',
        broadcastNoOwner: 'Владелец трансляции не в комнате',
        broadcastCopyKey: 'Скопировать ключ',
        electron: {
          screens: 'Экраны',
          windows: 'Окна',
          withSound: 'Включить звук экрана демонстрации', // Enable demo screen sound
          start: 'Начать демонстрацию',
          cancel: 'Отмена',
        },
      },
      feedback: {
        satisfied: 'Довольны ли вы качеством нашего сервиса?',
        explain: 'Можете рассказать, с чем возникли трудности?',
        send: 'Отправить',
        problem1: 'Были проблемы с подключением',
        problem2: 'Не было видно или слышно собеседников',
        problem3: 'Неудобный интерфейс',
        thanks: 'Спасибо за помощь!',
      },
      stillHere: {
        title: 'Вы ещё здесь?',
        text1: 'Видеоконференция закончится через',
        text2: 'Чтобы продолжить общение, нажмите кнопку «Продолжить»',
      },
      onAir: {
        guest: {
          wait: 'Эфир скоро начнется',
          waitHint: 'Ожидание эфира',
        },
        moderator: {
          banner: {
            notReady: 'Эфир ещё не начался',
            notReadyHint: 'Вы можете начать эфир став спикером или остаться как администратор без входа в эфир. Стать спикером или перестать быть спикером можно во вкладке списка пользователей.',
            waiting: 'Ожидание эфира',
            waitingHint: 'Вы являетесь администратором эфира. Стать спикером можно в вкладке списка пользователей.',
            speakerButton: 'Начать эфир и стать спикером',
            moderatorButton: 'Остаться как администратор',
          },
          toast: {
            title: 'Хотите присоединиться в качестве спикера?',
            hint: 'Вы можете присоединиться в качестве спикера или остаться администратором без входа в эфир. Стать спикером или перестать быть спикером можно во вкладке списка пользователей.',
            speakerButton: 'Стать спикером',
            moderatorButton: 'Продолжить как администратор',
          },
        },
      },
      putHandDown: {
        toastMessage: 'Похоже вы уже высказались, ваша рука будет опущена',
        keepRaised: 'Не опускать',
      },
      conferenceHint: 'Онлайн-встреча с видео и аудио. Идеально для обсуждений и встреч в реальном времени.',
      webinarHint: 'Онлайн-мероприятие для большой аудитории. Участники следят за выступлением и общаются через чат.',
    },
    event: {
      pleaseWait: 'Ожидайте, вебинар скоро начнется',
      webinarIsOver: 'Вебинар закончен',
      webinarNotReady: 'Вебинар не начался либо уже закончен',
      thankYouForParticipating: 'Спасибо за участие',
    },
    warnings: {
      forbiddenBroadcastAudio: 'Администратор заблокировал ваш микрофон',
      forbiddenBroadcastVideo: 'Администратор заблокировал вашу камеру',
      allowedBroadcastAudio: 'Администратор разблокировал ваш микрофон',
      allowedBroadcastVideo: 'Администратор разблокировал вашу камеру',
      unstableConnection: 'Нестабильное соединение',
      unstableConnectionCommon: 'Нестабильное соединение с интернетом',
      unstableConnectionUserCameraOff: 'Ваша камера выключена из-за слабого соединения',
      unstableConnectionCamerasOff: 'Камеры выключены из-за слабого соединения',
      unsupportedOS: {
        msg: 'Наш сервис может работать нестабильно, если на вашем мобильном устройстве версия {{os}} ниже {{minVersion}}',
        hint: 'Рекомендуем зайти с компьютера для более комфортной работы',
        buttonText: 'Всё равно войти',
      },
      takeOffVideoForScreenSharing: 'У вас слабое исходящее соединение, отключите видео, чтобы улучшить скриншаринг',
      takeOffVideoForAudio: 'Слабое соединение, отключите камеру, чтобы вас лучше слышали',
      takeOffVideos: 'Видео пользователей отключены из-за слабого соединения',

      adminSwitchedOffVideo: 'Администратор выключил вашу камеру',
      adminSwitchedOffAudio: 'Администратор выключил ваш микрофон',
      adminSwitchedOffScreenSharing: 'Администратор выключил вашу демонстрацию экрана',

      adminTurnedOffCamera: 'Администратор выключил вашу камеру',
      adminTurnedOffMicrophone: 'Администратор выключил ваш микрофон',
      adminTurnedOffScreenSharing: 'Администратор выключил вашу демонстрацию экрана',
    },
    invites: {
      activate: 'Активировать',
      activated: 'Приглашение активировано',
      activateInvite: 'Активировать приглашение?',
      return: 'Возврат',
    },
    integration: {
      personalToken: {
        title: 'Персональные токены',
        saveTokenDialogTitle: 'Подтвердите сохранение токена',
        saveTokenTip: 'Сохраните токен в безопасное место. После закрытия этого окна увидеть токен повторно будет невозможно',
        whatIsIt: 'Персональный токен используется для авторизации в API от вашего имени. Токен не имеет срока жизни в отличие от обычного OAuth2 токена доступа. Рекомендуется регулярно обновлять токены в целях безопасности. Не передавайте его третьим лицам',
        noTokensText: 'У вас нет персональных токенов',
        tokenSavedApprovalBtn: 'Подтвердить сохранение токена',
        failedToFetchTokensError: 'Не удалось загрузить токены',
      },
    },
    chat: {
      activate: 'Активировать чат',
      activateFail: 'Не удалось активировать чат',
      displayChatFail: 'Не удалось отобразить чат',
      selectFileMenu: {
        title: 'Добавить вложение',
        photo: 'Сделать фото',
        media: 'Добавить медиа',
        file: 'Добавить файл',
        fileTooBig: 'Выбранный файл слишком большой',
      },
    },
    admin: {
      menu: {
        admin: 'Администратор',
        users: 'Пользователи',
        debug: 'Debug',
      },
      notifications: {
        connectionLost: 'Соединение потеряно. Ожидание соединения...',
        connectionEstablished: 'Соединение восстановлено.',
        connectionEstablishedRejoining: 'Соединение восстановлено. Повторный вход в комнату...',
        roomRejoined: 'Успешно подключено',
        linkCopied: 'Ссылка скопирована',
      },
      confirmation: {
        userMove: 'Вы действительно хотите переместить пользователя {{name}} в слот {{slotId}}?',
        kick: 'Кикнуть этого участника?',
      },
      spaces: {
        title: 'Группы',
        createButtonLabel: 'Новая группа',
        deleteButtonLabel: 'Удалить группу',
        deleteTitle: 'Удалить группу',
        deleteConfirm: 'Удалить группу',
        deleteMessage: 'Вы уверены, что хотите удалить группу?',
        description: 'Описание',
        logo: 'Логотип',
        saveSuccess: 'Группа успешно сохранена',
        saveFail: 'Ошибка сохранения группы',
        deleteFail: 'Ошибка удаления группы',
        mySpaces: 'Мои группы',
        createNewSpace: 'Создать новую группу',
        noImage: 'Нет изображения',
        private: 'Приватная',
        public: 'Публичная',
        type: 'Тип группы',
        noSpaces: 'Ни одна группа не создана',
        logoCropper: {
          title: 'Выбор логотипа',
          cropperTitle: 'Выберите область для логотипа',
          previewTitle: 'Превью логотипа',
        },
        buyPlanHint: 'Выберите группу, на которую вы хотите привязать тариф, после выбора группы вам откроется окно выбора и оплаты тарифа',
        titleHintTooltip: 'Объединяет ссылки на комнаты для удобства. Упорядочивайте встречи по темам или проектам.',
        defaultSpaceName: 'Моя первая группа',
      },
      rooms: {
        lessonDefaultName: 'Ваша первая комната',
        webinarDefaultName: 'Ваш первый вебинар',
        saveFail: 'Ошибка сохранения комнаты',
        saveSuccess: 'Встреча успешно сохранена',
        saveJoinSettingsFail: 'Ошибка сохранения полей входа в комнату',
        delete: {
          confirmTitle: 'Удалить комнату "{{roomName}}"?',
          confirmText: 'Вы уверены, что хотите удалить комнату?',
        },
        loadFail: 'Ошибка при загрузке списка комнат',
      },
      playbacks: {
        schedule: 'Расписание',
        withRepeating: '{{value}} повторяющихся',
        withoutRepeating: '{{value}} без повтора',
        constant: '{{value}} постоянное',
        notPublished: 'Не опубликовано',
        nowPlaying: 'Сейчас запущено',
        totalStarted: 'Пройдено запусков',
        totalMembers: 'Приняли участие',
        editPlayback: 'Редактировать',
        playbackEventsList: 'Перейти к списку запусков',
        playbacks: 'Автовебинары',
        playbacksDescription: 'Создание, редактирование и просмотр записанных трансляций',
        playbackCreate: 'Создать автовебинар',
        published: 'Опубликованные',
        unpublished: 'Не опубликованные',
        favorites: 'Избранные',
        createError: 'Не удалось создать playback',
        updateError: 'Не удалось обновить playback',
        downloadTimelineFailed: 'Ошибка при загрузке json с интерактивами',
        uploadTimelineFailed: 'Ошибка при сохранении json с интерактивами',
        deleteTimelineFailed: 'Ошибка при удалении json с интерактивами',
        timeLineDeleteTitle: 'Удалить чат и интерактивы?',
        timeLineDeleteWarn: 'Вы уверены, что хотите удалить чат и интерактивы?',
        videoeDeleteTitle: 'Удалить видео?',
        videoDeleteWarn: 'Вы уверены, что хотите удалить видео?',
        timeLineWrongFormat: 'Неверный формат файла',
        signVideoFailed: 'Ошибка при попытке подписать видео',
        deleteVideoFailed: 'Ошибка при удалении видео',
        updatePlaybackFailed: 'Ошибка при сохранении автовебинара',
        createAssetFailed: 'Ошибка при создании записи о видео',
        timeLineFileSizeError: 'Размер json файла должен быть не больше {{size}}',
        videoFileSizeError: 'Размер видео файла должен быть не больше {{size}}',
        fetchPlaybacksError: 'Не удалось получить playbacks',
        fetchPlaybackError: 'Не удалось получить playback',
        removePlaybackError: 'Не удалось удалить playback',
        uploadVideoFailed: 'Failed to upload video',
        remove: {
          confirmTitle: 'Удалить автовебинар "{{ name }}"?',
          confirmText: 'Вы уверены, что хотите удалить автовебинар?',
        },
        publish: {
          confirmTitle: 'Опубликовать "{{ name }}"?',
          confirmText: 'Вы уверены, что хотите опубликовать автовебинар?',
        },
        unpublish: {
          confirmTitle: 'Снять с публикации "{{ name }}"?',
          confirmText: 'Вы уверены, что хотите снять с публикации автовебинар?',
        },
      },
      playbackEvents: {
        remove: {
          confirmTitle: 'Удалить расписание?',
          confirmText: 'Вы уверены, что хотите удалить это расписание?',
          fail: 'Ошибка удаления расписания',
        },
      },
      playbackEdit: {
        schedule: 'Расписание',
        content: 'Контент',
        commonSettings: 'Общие настройки',
        inputNameLabel: 'Название автовебинара',
        inputNamePlaceholder: 'Введите название автовебинара',
        enteringFields: 'Поля входа',
        phone: 'Телефон',
        exit: 'Выйти',
        unPublish: 'Снять с публикации',
        remove: 'Удалить',
        publish: 'Опубликовать',
        addContent: 'Для публикации добавьте контент',
        autoSave: 'Автосохранение {{ autoSaveTime }}',
        saveFail: 'Ошибка сохранения playback',
        noScheduleMessage1: 'Пока автовебинар не запланирован',
        noScheduleMessage2: 'Добавьте расписание для проведения автовебинара',
        addSchedule: 'Добавить расписание',
        scheduleCreating: 'Создание расписания',
        scheduleEditing: 'Редактирование расписания',
        oneTime: 'Однократное',
        recurrent: 'Повторяющееся',
        persistent: 'Постоянное',
        startDayOneTimeLabel: 'Дата и время проведения',
        startDateRecurrentLabel: 'Дата начала повторения и время',
        endDateLabel: 'Дата завершения повторения',
        hasNoFinish: 'Без завершения',
        createError: 'Не удалось создать расписание',
        updateError: 'Не удалось обновить расписание',
        successCreate: 'Расписание создано',
        successUpdate: 'Расписание обновлено',
        daysOfWeek: 'Дни недели',
        everyDay: 'Каждый день',
      },
      classrooms: {
        shareRoom: 'Скопировать ссылку на комнату',
        lessonAnalytic: 'Статистика по участникам',
        editing: 'Редактирование комнаты',
        name: 'Название комнаты',
        lessonType: 'Тип комнаты',
        createClassroom: 'Создать комнату',
        enterName: 'Введите название комнаты',
        typeLesson: 'Конференция',
        typeWebinar: 'Вебинар',
        typeAutoWebinar: 'Автовебинар',
        createVirtualClassroom: 'Создать',
        joinClassroom: 'Войти',
        all: 'Все',
        deleteClassroom: 'Удалить комнату',
        saveClassroom: 'Сохранить комнату',
        joinHint: {
          title: 'Проведите первую встречу',
          text: 'Войдите в комнату и пригласите участников встречи.',
        },
        joinFields: 'Поля входа',
        email: 'E-mail',
        phone: 'Телефон',
        newLesson: 'Конференция',
        newWebinar: 'Вебинар',
        newPlaybackWebinar: 'Автовебинар',
        usersOnline: 'Принимают участие: {{onlineUsersCount}}',
        usersOnlineShort: 'Участвуют: {{onlineUsersCount}}',
        roomSettings: 'Настройки комнаты',
        roomRecords: 'Видеозаписи комнаты',
        allRooms: 'Все комнаты',
        onlineMeetings: 'Онлайн встречи',
        listDescription: 'Комнаты для онлайн конференций и вебинаров',
        conferences: 'Конференции',
        webinars: 'Вебинары',
        favorites: 'Избранное',
        sort: 'Сортировка',
        lastCreated: 'Последняя созданная',
        lastCall: 'Последняя со звонком',
        autoWebinars: 'Автовебинары',
        comingSoon: 'Скоро тут будут ваши автовебинары',
        createMeetingDialogTitle: 'Создание новой встречи',
        editMeetingDialogTitle: 'Редактирование встречи',
        playbackWebinarName: 'Название автовебинара',
        enterPlaybackWebinarName: 'Введите название автовебинара',
        autoRecordLabel: 'Автоматическая запись при входе администратора в комнату',
        autoRecordHelpTooltip: 'Запись встречи автоматически начнется при входе любого пользователя с правами администратора',
        inviteSpeaker: 'Добавить спикера',
        speakerLinkCopied: 'Ссылка на добавление спикера скопирована',
        failedToCreateSpeakerInvite: 'Не удалось создать ссылку на приглашение спикера',
      },
      assets: {
        title: 'Файлы',
        deleteConfirm: 'Удалить файл',
        video: 'Видео',
        audio: 'Аудио',
        image: 'Изображение',
        uploadFail: 'Ошибка загрузки файла',
        uploadSuccess: 'Файл успешно загружен',
        uploadStart: 'Загрузка файла началась',
        saveSuccess: 'Файл успешно изменён',
        deleteFail: 'Ошибка удаления файла',
      },
      invites: {
        invite: 'Пригласить',
        personal: 'Персональное приглашение',
        link: 'Пригласительная ссылка',
        inviteAs: 'Пригласить в группу как',
        copyLink: 'Копировать ссылку',
        list: 'Список пользователей',
        inviteModerator: 'Добавить администратора',
        inviteMember: 'Добавить участника',
        showUserList: 'Список пользователей',
      },
      videoEffects: {
        applyHint: 'Для применения нажмите кнопку «Применить эффекты»',
        applyEffects: 'Применить эффекты',
        applyReset: 'Применить сброс эффектов',
        resetButton: 'Сбросить все эффекты',
        visualEffects: 'Визуальные эффекты',
        blur: 'Размытие фона',
        blurLabel: 'Интенсивность размытия фона',
        background: 'Фон',
        beautification: 'Улучшение внешности',
        beautificationLabel: 'Интенсивность эффекта',
        colorCorrector: 'Цветокоррекция',
        colorCorrectorLabel: 'Интенсивность цветокоррекции',
        lowLightEffect: 'Осветление',
        lowLightEffectLabel: 'Интенсивность осветления',
        smartZoom: 'Умный зум',
        smartZoomLabel: 'Степень увеличения',
        layout: 'Расположение лица',
        colorFilter: 'Фильтр',
        colorFilterLabel: 'Интенсивность фильтра',
        pickColor: 'Выбор цвета фона',
        backgroundVideo: 'Видео',
        backgroundImage: 'Изображение',
        backgroundColor: 'Цвет',
        loadingVideoEffectsEngine: 'Загрузка обработчика видеоэффектов',
        faceLeft: 'Слева',
        faceCenter: 'Стандартно',
        faceRight: 'Справа',
        pickImage: 'Выбор изображения фона',
        pickVideo: 'Выбор видео фона',
        lowerThird: 'Подпись',
        lowerThirdTitle: 'Заголовок',
        lowerThirdSubtitle: 'Подзаголовок',
        lowerThirdType: 'Тип подписи',
        lowerThirdColor: 'Цвет акцента',
        imageLoadError: 'Не удалось загрузить изображение',
        imageTooBig: 'Не удалось загрузить изображение, размер изображения должен быть меньше {{size}} Мб',
        imageUnsupported: 'Неподдерживаемый тип изображения',
      },
    },
    records: {
      removeRecordHint: 'Удалить запись',
      renameRecordHint: 'Переименовать запись',
      renameError: 'Ошибка переименования записи',
      removeRecordTitle: 'Удалить запись "{{ name }}"?',
      removeRecordBody: 'Вы уверены, что хотите удалить эту запись?',
      renameRecord: 'Переименовать запись',
      public: 'Публичная',
      download: 'Скачать записи',
      searchIn: 'Поиск в {{ text }}',
      start: 'Запись',
      starting: 'Запись',
      stop: 'Остановить',
      title: 'Видеозаписи',
      recordSelectTitle: 'Выбор видеозаписи для автовебинара',
      userSpaceInSpace: 'Занятое место в группе: {{ user }} / {{ quota }}',
      video: 'Видео',
      admin: {
        delay100s: 'Запись начнётся в течение 100 секунд',
        started: 'Началась запись',
        stopped: 'Запись остановлена',
      },
      user: {
        started: 'Началась видеозапись встречи',
        alreadyStarted: 'Это событие записывается. Присоединяясь вы соглашаетесь с тем, что ваше участие будет записано',
        recordTooltip: 'Идет видеозапись встречи',
      },
      publishing: {
        noDate: 'Не была опубликована',
        unPublishDate: 'Снята с публикации {{ date }} в {{ time }}',
        publishDate: 'Опубликована {{ date }} в {{ time }}',
        viewCounter: '{{ count }} {{ word }}',
        unPublish: 'Снять с публикации',
        publish: 'Опубликовать',
        copyLink: 'Скопировать ссылку на запись',
      },
      onlyPublished: 'Показать только опубликованные',
      downloadCompleted: 'Скачивание файла {{ filename }} завершено',
      disabledRecordingButton: 'Запись недоступна',
      disabledRecordingHint: 'Функция записи недоступна в тарифе {{ freePlanName }}',
      openPlansLink: 'Получить функцию и выбрать тариф',
    },
    userProfile: {
      changeEmail: 'Изменить email',
      currentEmailLabel: 'Текущий email',
      newEmailLabel: 'Новый email',
      newEmailPlaceholder: 'Введите новый email',
      newEmailRequired: 'Требуется новый email',
      emailChanged: 'Email успешно изменен',
      requestChangeEmailSuccess: 'Ссылка для смены e-mail была выслана на вашу текущую почту',
      changeEmailHint: 'Для подтверждения смены электронной почты перейдите по ссылке, отправленной на почту {{email}}. Если не нашли письмо, проверьте папку "Спам", а также другие папки и фильтры',
      changePassword: 'Изменить пароль',
      currentPasswordLabel: 'Текущий пароль',
      currentPasswordPlaceholder: 'Введите текущий пароль',
      currentPasswordRequired: 'Требуется текущий пароль',
      newPasswordLabel: 'Новый пароль',
      newPasswordPlaceholder: 'Введите новый пароль',
      newPasswordRequired: 'Требуется новый пароль',
      confirmPasswordLabel: 'Подтверждение нового пароля',
      confirmPasswordPlaceholder: 'Введите новый пароль',
      confirmPasswordRequired: 'Требуется подтвердить новый пароль',
      passwordChanged: 'Пароль изменен',
      incorrectCurrentPassword: 'Неправильный текущий пароль',
      passwordMismatch: 'Пароли не совпадают',
      thumbnailSelection: 'Выбор аватара',
      selectArea: 'Выберите область для аватара',
      preview: 'Превью аватара',
      profilePhotoChanged: 'Фото профиля изменено',
      changePasswordHint: 'На {{email}} отправлено письмо с инструкциями по изменению пароля. Щелкните ссылку сброса пароля, чтобы установить новый пароль.',
      confirmAction: 'Подтвердите изменение пароля',
      profilePhotoRemoved: 'Фото профиля удалено',
      resetPassword: 'Подтвердить действие',
    },
    payment: {
      planTitle: 'Тарифный план',
      plansTitle: 'Тарифные планы',
      hint: 'Одна лицензия на школу',
      trial7Days: 'Trial',
      unlimited: 'Безлимит',
      starter: 'Starter',
      professional: 'Pro',
      business: 'Business',
      featureBase: 'Не более 1 ученика в комнате*',
      featureCommon: 'Неограниченное количество виртуальных комнат',
      featureStarter: 'До 25 учеников одновременно*',
      featureProfessional: 'До 100 учеников одновременно*',
      featureBusiness: 'До 200 учеников одновременно*',
      selectPlan: 'Купить',
      lifetime: 'Безлимитно',
      month1: '1 месяц',
      month3: '3 месяца',
      month12: '12 месяцев',
      paymentDone: 'Тариф оплачен :)',
      plan: 'План',
      upgradePlanTimer: 'Комната не оплачена - {{timeToUpgrade}} до окончания',
      upgradePlanTimerModerator: '{{timeToUpgrade}} до завершения сессии. Обновите тариф, чтобы снять ограничения.',
      changePlanFail: 'Ошибка при смене тарифа',
      fetchPlansFail: 'Ошибка при загрузке тарифных планов',
      changePlanSuccess: 'Тариф был изменен',
      plansFooterButton: '',
      currentPlan: 'Текущий тариф',
      upgradePlan: 'Изменить тариф',
      closeToEndInfo1: '{{ daysText }} ваша подписка на тариф «{{planName}}» заканчивается.',
      closeToEndDays: 'Через {{daysToEnd}} {{day}}',
      closeToEndToday: 'Сегодня',
      choosePlan: 'Изменить тариф',
      prolongPlan: 'Продлить тариф',
      planParticipants: 'Участники по тарифу',
      extraParticipants: 'Дополнительные участники',
      recommended: 'Рекомендуем',
      changePlan: 'Сменить тариф',
      featureOneTitle: 'Практическое занятие',
      featureTwoTitle: 'Вебинар',
      pricePPM: 'Индивидуальный расчет',
      ppmHint: 'Тарификация по минутам',
      freeFeatures: 'Всё, что включено в тариф <0>{{ freePlanName }}</0>',
      discountUpTo: 'Скидка до {{ discount }}%',
      promoUpTo: 'Акция до {{ discount }}%',
      promoUpToDate: 'Акция до {{ date }}',
      timeless: 'Бессрочно',
      freePlan: {
        priceHint: 'Бессрочно',
        mainFeatures: [
          ['Продолжительность встречи', ['30 минут']],
          ['Конференция', ['до 10 участников']],
          ['Вебинар', ['до 25 участников']],
        ],
        extraFeaturesTitle: 'Включено в тариф:',
        extraFeatures: [
          'Аналитика по сессиям',
          'Реакции и опросы, чат',
          'Miro',
          'Управление пользователями',
        ],
        buyButton: '',
      },
      personalPlan: {
        title: 'Personal',
        priceHintMonthly: 'При оплате помесячно',
        priceHintAnnual: 'При оплате за год',
        mainFeatures: [
          ['Включено минут', ['25000']],
          ['Доступ к API', ['Нет']],
          ['Запись', ['HD']],
          ['Хранение записей', ['300 минут (5 часов)']],
          ['Шаринг записей', ['Да']],
          ['Автовебинары (Акция)', ['Да']],
          ['Конференция', ['до 100 участников']],
          ['Вебинар', ['до 500 участников']],
        ],
        extraFeaturesTitle: 'Включено в тариф:',
        extraFeatures: [],
        buyButton: 'Купить',
      },
      teamPlan: {
        title: 'Team',
        priceHintMonthly: 'При оплате помесячно',
        priceHintAnnual: 'При оплате за год',
        mainFeatures: [
          ['Включено минут', ['50000']],
          ['Доступ к API', ['Да']],
          ['Запись', ['HD']],
          ['Хранение записей', ['900 минут (15 часов)']],
          ['Шаринг записей', ['Да']],
          ['Автовебинары (Акция)', ['Да']],
          ['Конференция', ['до 100 участников']],
          ['Вебинар', ['до 1000 участников']],
        ],
        extraFeaturesTitle: 'Включено в тариф:',
        extraFeatures: [],
        buyButton: 'Купить',
      },
      businessPlan: {
        title: 'Business',
        priceHintMonthly: 'При оплате помесячно',
        priceHintAnnual: 'При оплате за год',
        mainFeatures: [
          ['Включено минут', ['100000']],
          ['Доступ к API', ['Да']],
          ['Запись', ['Full HD']],
          ['Хранение записей', ['1800 минут (30 часов)']],
          ['Шаринг записей', ['Да']],
          ['Автовебинары (Акция)', ['Да']],
          ['Конференция', ['до 300 участников']],
          ['Вебинар', ['до 2500 участников']],
        ],
        extraFeaturesTitle: 'Включено в тариф:',
        extraFeatures: ['Замена логотипа'],
        buyButton: 'Купить',
      },
      enterprisePlan: {
        title: 'Enterprise',
        priceHintMonthly: '',
        priceHintAnnual: '',
        mainFeatures: [
          ['Включено минут', ['Индивидуальный расчет']],
          ['Доступ к API', ['Да']],
          ['Запись', ['Full HD']],
          ['Хранение записей', ['Индивидуальный расчет']],
          ['Шаринг записей', ['Да']],
          ['Автовебинары (Акция)', ['Да']],
          ['Конференция', ['до 1000 участников']],
          ['Вебинар', ['до 10000 участников']],
        ],
        extraFeaturesTitle: 'Включено в тариф:',
        extraFeatures: ['Замена логотипа'],
        buyButton: 'Оставить заявку',
      },
      proPlan: {
        title: 'Pro',
        titleHint: 'Для покупки нескольких лицензий обратитесь в Поддержку',
        priceHintMonthly: 'При оплате помесячно',
        priceHintAnnual: 'При оплате за год',
        mainFeatures: [
          ['Конференция', ['до 100 участников']],
          ['Вебинар', ['до 200 участников']],
          ['Длительность встречи', ['без ограничений']],
          ['Хранение записей', ['5Гб на лицензию']],
          ['Шаринг записей', ['Да']],
          ['Доступ к API', ['Нет']],
        ],
        extraFeaturesTitle: 'Включено в тариф:',
        buyButton: 'Купить',
      },
      businessPlanNew: {
        title: 'Business',
        priceHintMonthly: 'При оплате помесячно',
        priceHintAnnual: 'При оплате за год',
        mainFeatures: [
          ['Конференция', ['до 300 участников']],
          ['Вебинар', ['до 500 участников']],
          ['Длительность встречи', ['без ограничений']],
          ['Хранение записей', ['5Гб на лицензию']],
          ['Шаринг записей', ['Да']],
          ['Доступ к API', ['Да']],
        ],
        extraFeaturesTitle: 'Включено в тариф:',
        buyButton: 'Купить от 10-ти лицензий',
      },
      personalTelebossPlan: {
        title: 'Personal',
        priceHintMonthly: 'При оплате помесячно',
        priceHintAnnual: 'При оплате за год',
        mainFeatures: [
          ['Параллельные встречи', ['Без параллельных встреч']],
          ['Конференция', ['до 100 участников']],
          ['Вебинар', ['до 100 участников']],
          ['Доступ к API', ['Да']],
          ['Запись', ['HD']],
          ['Хранение записей', ['1500 минут (25 часов)']],
          ['Шаринг записей', ['Да']],
          ['Трансляция через OBS', ['Да']],
        ],
        extraFeaturesTitle: 'Включено в тариф:',
        extraFeatures: [],
        buyButton: 'Купить',
      },
      enterpriseTelebossPlan: {
        title: 'Enterprise',
        priceHintMonthly: '',
        priceHintAnnual: '',
        mainFeatures: [
          ['Параллельные встречи', ['Не ограничены']],
          ['Конференция', ['до 1000 участников']],
          ['Вебинар', ['до 10000 участников']],
          ['Доступ к API', ['Да']],
          ['Запись', ['Full HD']],
          ['Хранение записей', ['Индивидуальный расчет']],
          ['Шаринг записей', ['Да']],
          ['Трансляция через OBS', ['Да']],
        ],
        extraFeaturesTitle: 'Включено в тариф:',
        extraFeatures: ['Замена логотипа'],
        buyButton: 'Оставить заявку',
      },
    },
    unsupportedBrowser: {
      headTitle: 'Неподдерживаемый браузер',
      headTitleOutdated: 'Браузер устарел',
      title: 'Неподдерживаемый браузер',
      titleOutdated: 'Браузер устарел',
      hint: 'Браузер, который в данный момент используется, к сожалению, не поддерживается {{platformName}}.\nПожалуйста, используйте один из поддерживаемых браузеров.',
      hintOutdated: 'Текущая версия вашего браузера, к сожалению, не совместима с сервисом {{platformName}}.\nДля оптимальной работы, пожалуйста, обновите вашу систему или воспользуйтесь любым из поддерживаемых браузеров.',
      button: 'Скачать на странице браузера',
      openApp: 'Перейти в приложение',
    },
    wordsEnding: {
      day: ['день', 'дня', 'дней'],
      room: ['комнату', 'комнаты', 'комнат'],
      participant: ['участник', 'участника', 'участников'],
      second: ['секунду', 'секунды', 'секунд'],
      minute: ['минута', 'минуты', 'минут'],
      byte: ['Байт', 'Байта', 'Байт'],
      demos: ['демонстрирует', 'демонстрируют'],
      views: ['просмотр', 'просмотра', 'просмотров'],
    },
    pulse: {
      activityInClass: 'Активность в комнате',
      interactives: 'Участие в интерактивах',
      totalOnline: 'Количество онлайн',
      activeTab: 'Активная вкладка',
      interactivesHint: 'Участники, которые использовали реакции, опросы.',
      totalOnlineHint: 'Количество участников за сессию.',
      activeTabHint: 'Участники с открытой вкладкой.',
      tooltip: 'Активность в комнате',
      usersActivity: 'Активность',
    },
    statistics: {
      title: 'Статистика по участникам',
      pulse: 'Пульс',
      listTitle: 'Комната, чат и активность в опросах',
      cardTitle: 'Сессия {{date}} {{ startTime }} - {{ endTime }}',
      cardSubtitle: ' {{ participant }}',
      noSessionMessage: 'В этот период сессий не проводилось',
      notifications: {
        errorLoadingRoomInfo: 'Ошибка загрузки данных комнаты',
        errorLoadingSessionList: 'Ошибка загрузки списка сессий',
      },
    },
    safariRequestStorageAccess: {
      hint1: 'Для продолжения дайте доступ к cookie файлам',
      button1: 'Разрешить доступ',
      hint2: 'Подтвердите запись ваших cookie файлов',
      button2: 'Подтвердить',
      waiting: 'Ожидание...',
      thanks: 'Спасибо, мы сохранили ваши cookie файлы',
    },
    thanksPage: {
      text: 'Благодарим вас за использование {{brand}}',
      repeatCall: 'Повторить звонок',
      commonThanks: 'Видеозвонок завершен.\nСпасибо за участие!',
    },
    debug: {
      debugInfo: 'Отладочная информация',
      peerInfo: 'Информация о пользователе',
      trackInfo: 'Информация о трэке',
      audioInfo: 'Информация о аудио треках',
      requestKeyFrame: 'Запросить ключевой кадр',
      reportIssue: 'Сообщить о проблеме',
      issueDescription: 'Описание проблемы',
      moreInfo: 'Больше информации в консоли разработчика...',
      download: 'Скачать дамп',
      successRequestKeyFrame: 'Запрос нового ключевого кадра успешно отправлен',
      errorRequestKeyFrame: 'Ошибка запроса нового ключевого кадра',
      dblClickForDebugInfo: 'Двойной щелчок для отображения отладочной информации',
      successIssueReport: 'Отчёт о проблеме успешно отправлен',
      errorIssueReport: 'Ошибка при отправке отчёта',
      updateInfo: 'Обновить информацию',
      updateAudio: 'Переподключить аудио',
    },
    waitingRoom: {
      label: 'Зал ожидания',
      helpTooltip: 'Администратор может принять участников в конференцию выборочно, либо же всех сразу, когда будет готов начать встречу.',
      waitingForDecision: 'Ожидание решения администратора',
      requestRejected: 'Администратор отклонил запрос',
      backToRoomList: 'Покинуть зал ожидания',
      allowAll: 'Разрешить всем',
      denyAll: 'Запретить всем',
    },
    announcements: {
      started: 'Запущен',
      stopped: 'Не запущен',
      show: 'Показать баннер',
      hide: 'Скрыть баннер',
      text: 'Текст баннера',
      textButton: 'Текст на кнопке',
      link: 'Ссылка',
      enterText: 'Введите текст',
      create: 'Создать новую кнопку',
      loadError: 'Ошибка при загрузке баннеров',
      notFoundError: 'Баннер не найден',
      postError: 'Ошибка при показе баннера',
      deleteError: 'Ошибка при скрытии баннера',
      deleteBanner: 'Удалить баннер?',
      confirmAction: 'Подтвердите ваше действие',
      invalidUrl: 'Адрес ссылки неправильный',
      title: 'Заголовок',
      price: 'Цена',
      type: 'Тип баннера',
      default: 'Стандартный',
      loadPaymentFail: 'Не удалось загрузить виджет оплаты',
      paymentInitFail: 'Не удалось открыть форму оплаты',
      paymentWrongUrl: 'Неправильная ссылка на оплату, должна быть вида \'xxx.payform.ru\'',
      paymentError: 'Не удалось произвести платеж',
    },
    breakoutRooms: {
      dndHint: 'Перетяните участника в нужный зал',
      clearRooms: 'Очистить залы',
      shuffleRooms: 'Перемешать',
      roomsCount: 'Кол-во залов',
      breakoutRoom: 'Зал {{index}}',
      move: 'Переместить в зал {{index}}',
      unassigned: 'Неназначенные',
      administrators: 'Администраторы',
      startSession: 'Начать сессию',
      stopSession: 'Завершить сессию',
      prefix: 'Зал',
      wait: 'Подождите...',
      return: 'Вернуться в главную комнату',
      moved: 'Администратор переместил вас в {{roomName}}',
      returned: 'Сессия завершена, вы вернулись в {{roomName}}',
      deleteRoomError: 'Не удалось удалить зал, убедитесь, что в нем нет участников',
      startError: 'Ошибка при запуске сессии',
      stopError: 'Ошибка при остановке сессии',
      loadRoomsError: 'Ошибка при загрузке списка залов',
      sessionOver: 'Сессия завершена администратором',
      sessionStarted: 'Администратор начал сессию',
      enter: 'Войти в сессионный зал',
    },
    userList: {
      turnOffAllCameras: 'Выключить всем камеры',
      turnOffAllMicrophones: 'Выключить всем микрофоны',
      working: 'включены {{ withActiveDevices }}/{{ all }}',
    },
    events: {
      eventDate: '{{date}} в {{time}}',
      eventDateLaunch: 'Запуск: {{date}} в {{time}}',
      participantsPast: 'Всего участников',
      participantsNow: 'Принимает участие',
      participantsFeature: 'Не начато',
      continuous: 'Постоянное',
      runningNow: 'Сейчас запущено',
      notStarted: 'Не начатые',
      completed: 'Прошедшие запуски',
      edit: 'Редактировать',
      sortStartTime: 'Время запуска',
      uploadVideo: 'Загрузить видео',
      chooseVideo: 'Выбрать видео',
      noVideo: 'Пока видео не добавлено\nВыберите видео для проведения автовебинара',
      chatInteractions: 'Чат и интерактивы',
      noChatInteractions: 'Интерактивы и чат пока не добавлены\nДобавьте .json файл для их загрузки',
      uploadJson: 'Добавить .json',
      replaceJson: 'Заменить .json',
      uploadAndReplaceVideo: 'Загрузить и заменить видео',
      chooseAndReplaceVideo: 'Выбрать и заменить видео',
      announcements: 'Объявления',
      showTime: 'Время показа',
      ongoingBadge: 'Проходит',
      completedBadge: 'Завершено',
      recurrentBadge: 'Повторяющееся',
      recurrentOnceBadge: 'Однократное',
      notStartedBadge: 'Начнется по расписанию',
      failedToFetchSessions: 'Ошибка при загрузке сессиий',
      failedToFetchEvents: 'Ошибка при загрузке расписания',
      failedToDownloadReport: 'Ошибка при загрузке аналитического отчета',
      startTime: 'Время запуска',
    },
    language: {
      ru: 'Русский',
      en: 'English',
    },
    theme: {
      theme: 'Цветовая схема',
      light: 'Светлая тема',
      dark: 'Темная тема',
      auto: 'Системная тема',
    },
    afterRegisterPopup: {
      title1: 'Поздравляем!',
      title2: 'Регистрация успешно пройдена',
      checkList: [
        'Создание аккаунта',
        'Подтверждение e-mail',
        'Создана первая группа «{{ spaceName }}»',
        'Создана первая встреча «{{ roomName }}»',
      ],
      alert1: 'Для начала работы с сервисом проведите свою первую встречу.',
      alert2: 'Встречу можно провести или завершить сразу после её начала.',
      buttonText: 'Войти во встречу «{{ roomName }}»',
    },
    calls: {
      callLoadError: 'Ошибка при получении данных звонка',
      activeCallLoadError: 'Ошибка при получении активного звонка',
    },
    deviceSettings: {
      noMic: 'Не обнаружен',
      noCam: 'Не обнаружена',
      retryHint: 'Камера или микрофон уже используются в другом приложении',
      backBlur: 'Размытие фона',
      noiseSuppression: 'Программное шумоподавление',
      frontalCamera: 'Фронтальная камера',
      backCamera: 'Основная камера',
      offCamera: 'Камера выключена',
      micOn: 'Микрофон включен',
      micOff: 'Микрофон выключен',
      micNotAvailable: 'Микрофон: Недоступен',
      camNotAvailable: 'Камера: Недоступна',
      deviceSelectError: 'Ошибка подключения устройства',
      retryDetect: 'Повторить подключение устройств',
      switchCamera: 'Сменить камеру',
      testSound: 'Проверка звука',
    },
    electron: {
      thickness: 'Толщина',
      opacity: 'Непрозрачность',
      board: 'Доска',
      drawToolbar: 'Панель рисования',
      closeApp: 'Вы уверены, что хотите закрыть приложение?',
      checkingUpdates: 'Проверка наличия обновлений',
      downloadingUpdates: 'Загрузка обновлений',
      checkTip: 'Не закрывайте окно и дождитесь окончания проверки и установки обновлений чтобы избежать некорректной работы приложения',
      checkError: 'Oшибка проверки обновлений',
      errorTip: 'Произошла ошибка проверки обновлений, попробуйте:',
      errorTip0: 'Повторить проверку',
      errorTip1: 'Проверить качество интернет-соединения',
      errorTip2: 'Перезапустить приложение',
      errorTip3: 'Напишите в поддержку, если ни один из способов не помог',
      writeToSupport: 'Написать в поддержку',
      retry: 'Повторить попытку',
    },
    unknownSize: 'Неизвестный размер',
    sizeInBytes: ['Байт', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ', 'ЕБ', 'ЗБ'],
    referralCode: {
      enterCode: 'Введите промокод',
      codePlaceholder: 'Промокод',
      apply: 'Применить',
      validationError: 'Формат кода не подходит',
    },
  },
};
