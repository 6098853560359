import React, { HTMLAttributeAnchorTarget, HTMLAttributes } from 'react';

import { TextSize } from 'ui-kit/theme';

import { Text } from './index.styled';

export interface FigmaTypographyProps {
  size?: TextSize;
  as?: React.ElementType;
  center?: boolean;
  color?: string;
  href?: string;
  target?: HTMLAttributeAnchorTarget;
  htmlFor?: string;
}

const getHeadTag = (size: TextSize): React.ElementType | undefined => {
  if (size === 'h1') {
    return 'h1';
  }

  if (size === 'h2') {
    return 'h2';
  }

  if (size === 'h3') {
    return 'h3';
  }

  return undefined;
};

type Props = HTMLAttributes<HTMLElement> & FigmaTypographyProps;

const FigmaTypography = React.forwardRef<HTMLElement, Props>((props, ref) => {
  const {
    as,
    size = 'main-text-medium',
    children,
    className,
    center,
    color,
    ...restProps
  } = props;

  return (
    <Text
      ref={ref}
      size={size}
      className={className}
      as={as || getHeadTag(size)}
      center={center}
      color={color}
      {...restProps}
    >
      {children}
    </Text>
  );
});

export default FigmaTypography;
