import React, {
  FunctionComponent,
  useCallback,
  useState,
  useRef,
  cloneElement,
  ReactElement,
  useEffect,
} from 'react';

import {
  IconButton,
  Menu, MenuItem,
} from 'ui-kit';
import { ArrowDownSmallIcon, MoreVertIcon } from 'ui-kit/assets/icons';

import { IconButtonProps } from '../IconButton/types';
import { ArrowDown, IndicatorWrapper, ButtonWrapper } from './index.styled';

export interface DropDownButtonProps extends IconButtonProps {
  menu?: ReactElement;
  menuTooltip?: string;
  onClose?(): void;
  showIndicator?: boolean;
  id: string;
  disabled?: boolean;
  withBackdrop?: boolean;
  isVisibleCallback?: (menuState: boolean) => void;
  closeMenuOnClickOnly?: boolean;
  menuInitVisibility?: boolean;
  isSelected?: boolean;
  button?: React.NamedExoticComponent,
  autoOpenDelay?: number,
  autoCloseDelay?: number,
}

const DropDownButton: FunctionComponent<DropDownButtonProps> = React
  .forwardRef<HTMLButtonElement & HTMLAnchorElement, DropDownButtonProps>(({
  id,
  menu,
  menuTooltip,
  showIndicator,
  onClick,
  onClose,
  disabled,
  children,
  text,
  withBackdrop = true,
  isVisibleCallback,
  closeMenuOnClickOnly = false,
  menuInitVisibility = false,
  button,
  isSelected,
  variant,
  autoOpenDelay,
  autoCloseDelay,
  subVariant,
  tooltipDisabled,
  ...restProps
}, forwardedRef) => {
  const ref = useRef<HTMLButtonElement & HTMLAnchorElement | null>(null);
  const delayTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [menuState, setMenuState] = useState({ isVisible: menuInitVisibility, isHovered: false });
  const [tooltipDisabledByIdicator, setTooltipDisabledByIndicator] = useState(false);
  const tabVisibilityTimeStamp = useRef(0);
  const hasIndicatorButton = showIndicator && subVariant !== 'link';

  /* a workaround against situation when old events fired on tab switch via screen share system dialog */
  /* leads to inconsistent menu state - it opens on switching back to room tab */
  const isValidEvent = () => (Date.now() - tabVisibilityTimeStamp.current > 100);

  const initRef = (el: HTMLButtonElement & HTMLAnchorElement | null) => {
    ref.current = el;
    if (!forwardedRef) {
      return;
    }

    if (typeof forwardedRef === 'function') {
      forwardedRef(el);
    } else {
      // eslint-disable-next-line no-param-reassign
      forwardedRef.current = el;
    }
  };

  const handleClick = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    const childClicked = ref.current?.contains((e.target as HTMLElement));

    if (!childClicked) {
      return;
    }

    onClick?.(e);
    e.stopPropagation();
    if (hasIndicatorButton) {
      /* we should not open menu on click for indicator button, it opens on indicator button */
      return;
    }

    if (menu) {
      setMenuState(((prevState) => ({ ...prevState, isVisible: !prevState.isVisible, isHovered: true })));
    }

    if (delayTimerRef.current) {
      clearTimeout(delayTimerRef.current);
    }
  }, [onClick, closeMenuOnClickOnly, menu, hasIndicatorButton]);

  const handleClose = useCallback(() => {
    if (closeMenuOnClickOnly || !menuState.isVisible) {
      return;
    }

    onClose?.();
    setMenuState(((prevState) => ({ ...prevState, isVisible: false, isHovered: false })));
  }, [menuState]);

  const handleHoverButton = useCallback(() => {
    if (withBackdrop || closeMenuOnClickOnly || !isValidEvent()) {
      return;
    }

    setMenuState(((prevState) => ({ ...prevState, isVisible: true, isHovered: true })));
  }, []);

  const handleHoverMenu = useCallback(() => {
    if (withBackdrop || closeMenuOnClickOnly) {
      return;
    }

    setMenuState(((prevState) => ({ ...prevState, isHovered: true })));
  }, []);

  useEffect(() => {
    isVisibleCallback?.(menuState.isVisible);
  }, [menuState.isVisible]);

  const handleCloseMenu = useCallback(() => {
    if (!menuState.isVisible) {
      /* avoids unnecessary rerender */
      return;
    }

    setMenuState(((prevState) => ({ ...prevState, isVisible: false, isHovered: false })));
  }, [menuState]);

  const handleTooltipOpen = useCallback(() => { setTooltipDisabledByIndicator(true); }, []);
  const handleTooltipClose = useCallback(() => { setTooltipDisabledByIndicator(false); }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        tabVisibilityTimeStamp.current = Date.now();
      }
    };

    window.addEventListener('resize', handleCloseMenu);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    if (autoOpenDelay) {
      delayTimerRef.current = setTimeout(() => {
        setMenuState(((prevState) => ({ ...prevState, isVisible: true })));
        if (!autoCloseDelay) {
          return;
        }

        delayTimerRef.current = setTimeout(() => {
          setMenuState(((prevState) => ({ ...prevState, isVisible: false })));
        }, autoCloseDelay);
      }, autoOpenDelay);
    }

    return () => {
      window.removeEventListener('resize', handleCloseMenu);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (delayTimerRef.current) {
        clearTimeout(delayTimerRef.current);
      }
    };
  }, []);

  const Button = button || IconButton;

  const mainButton = (
    <Button
      id={id}
      onClick={handleClick}
      onMouseEnter={handleHoverButton}
      ref={initRef}
      text={text}
      disabled={disabled}
      isSelected={isSelected || menuState.isVisible}
      variant={variant}
      subVariant={subVariant}
      tooltipDisabled={menuState.isVisible || tooltipDisabled || tooltipDisabledByIdicator}
      icon={
        (showIndicator && subVariant === 'link') && (
          <ArrowDown>
            <ArrowDownSmallIcon />
          </ArrowDown>
        )
      }
      {...restProps}
    >
      {children}
    </Button>
  );

  const indicatorButton = hasIndicatorButton ? (
    <IndicatorWrapper id="indicator-wrapper">
      <DropDownButton
        id="indicator"
        subVariant="indicator"
        icon={<MoreVertIcon />}
        menu={menu}
        disabled={disabled}
        tooltip={menuTooltip}
        onTooltipOpen={handleTooltipOpen}
        onTooltipClosed={handleTooltipClose}
      />
    </IndicatorWrapper>
  ) : null;

  const buttons = indicatorButton ? (
    <ButtonWrapper>
      {mainButton}
      {indicatorButton}
    </ButtonWrapper>
  ) : mainButton;

  return (
    <>
      {buttons}
      {menu && !hasIndicatorButton && cloneElement(menu, {
        onClick: handleClose,
        isVisible: menuState.isVisible,
        onClose: handleClose,
        anchor: ref,
        onMouseEnter: handleHoverMenu,
        withBackdrop,
      })}
    </>
  );
});

export {
  Menu, MenuItem,
};

export default DropDownButton;
