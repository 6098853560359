import i18n from 'i18next';
import {
  action, computed, makeAutoObservable, observable, when,
} from 'mobx';
import { eventBus, subscribe } from 'mobx-event-bus2';

import { RootStore } from 'stores/root';
import {
  AppEvent,
  CommonToast,
  IErrorPageData,
  ImageCropperState,
  RoomEventNewReactionPayload,
  RoomType,
  SidePanelContent,
} from 'types/common';
import { ReactionsCounter } from 'components/Reactions/types';
import ERROR_PAGE_ERRORS, { Errors } from 'constants/errors';
import { ProfileStatesNames } from 'components/UserProfile/type';
import { ChatMobileVisualState } from 'components/Chat/types';
import { REACTIONS_INITIAL_VALUE } from 'constants/common';
import logger from 'helpers/logger';
import { enterPageFullScreen, exitPageFullScreen } from 'helpers/browser';
import { toWindowFeatures } from 'helpers/common';
import { MessagesFromChatPopup, MessagesToChatPopup, PostMessage } from 'components/Room/types';
import AppTheme from 'modules/AppTheme';

import PeerStore from './peer';

const CHAT_WINDOW_WIDTH = 365;

class UIStore {
  rootStore: RootStore;

  @observable hasPageError = false;

  errorPageData: IErrorPageData = {
    error: '',
    hint: '',
    customElement: null,
  };

  @observable croppingImage: string | ArrayBuffer | null = null;

  @observable croppedImage: HTMLCanvasElement | null = null;

  @observable isRoomLeft = false;

  @observable isLeavingRoom = false;

  @observable isConnectionIconShown = false;

  @observable isSpaceEditDialogShown = true;

  @observable imageCropperState: ImageCropperState | null = null;

  @observable isUserDevicesOpen = false;

  @observable isUserProfileOpen = false;

  @observable isUserProfileVisible = true;

  @observable dialogPeer?: PeerStore;

  @observable dialogPeerSlotId: number | null = null;

  @observable isProcessingAvatarFinished = false;

  @observable sidePanelContent: SidePanelContent = SidePanelContent.HIDDEN;

  @observable isLandscape = false;

  @observable isCompactWidth = false;

  @observable isCompactHeight = false;

  @observable userProfileDialogState = ProfileStatesNames.PROFILE_DATA;

  @observable hasIntentToLeaveRoom = false;

  @observable hasIntentToKickAllAndLeaveRoom = false;

  @observable latestReaction?: RoomEventNewReactionPayload;

  @observable reactions = new Map<string, RoomEventNewReactionPayload>();

  @observable reactionsCounter: ReactionsCounter = { ...REACTIONS_INITIAL_VALUE };

  @observable timeToUpdateRoomsList: Date | null = null;

  @observable roomsIdsWithCalls: string[] = [];

  @observable isTotalCallsCountFetched = false;

  @observable isTabActive = true;

  @observable isInviteDialogVisible = false;

  @observable isPulseDialogVisible = false;

  @observable pulseValue = 0;

  @observable pulseChartOpacityPercents = 100;

  @observable isPipOpen = false;

  @observable isParticipantsVideoDisabled = false;

  @observable isUserOwnVideoAutoDisabled = false;

  @observable isIntegrationDialogOpen = false;

  @observable isDebugInfoDialogOpen = false;

  @observable isAudioInfoDialogOpen = false;

  @observable isAntivirusBlocksConnectionWarningOpen = false;

  @observable isFireWallBlocksConnectionWarningOpen = false;

  @observable isChatExpanded = false;

  @observable isElectronScreensharingDialogOpen = false;

  @observable chatMobileVisualState = ChatMobileVisualState.Hidden;

  @observable onPipStart?: () => void;

  @observable onPipStop?: () => void;

  @observable isWaitingRoomToastOpen = false;

  @observable screensharingToggleErrorMsg: string | undefined;

  @observable commonToast: null | CommonToast | string = null;

  @observable chatNewWindow: Window | null = null;

  @observable isRoomFullScreenControlsVisible = false;

  @observable isAutoFullScreenOnJoinHappened = false;

  @observable timeoutForParticipantInRoomDialogInSec = 0;

  @observable isAutowebinarChatReady = false;

  @observable isDesktopAppSharingActive = false;

  @observable isVideoEffectsDialogOpen = false;

  @observable readonly theme;

  @observable hasIntentToOpenEffectsPanel = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.theme = new AppTheme(rootStore.participantStore.userSettings);
    makeAutoObservable(this);
    eventBus.register(this);
  }

  @action setIntentToOpenEffectsPanel(value: boolean) {
    this.hasIntentToOpenEffectsPanel = value;
  }

  @action setIsAutowebinarChatReady(value: boolean): void {
    this.isAutowebinarChatReady = value;
  }

  @action setIsAutoFullScreenOnJoinHappened(value: boolean) {
    this.isAutoFullScreenOnJoinHappened = value;
  }

  @action setCommonToast(message: CommonToast | string): void {
    this.commonToast = message;
  }

  @action disposeCommonToast(): void {
    this.commonToast = null;
  }

  @action setOnPipStart(value: () => void): void {
    this.onPipStart = value;
  }

  @action setOnPipStop(value: () => void): void {
    this.onPipStop = value;
  }

  @action setScreensharingToggleError(err?: Error): void {
    if (!err) {
      this.screensharingToggleErrorMsg = undefined;
      return;
    }

    const errMsg = String(err.message).toLowerCase();

    if (errMsg.includes('permission denied')) {
      this.screensharingToggleErrorMsg = undefined;
      return;
    }

    if (errMsg.includes('permission policy')) {
      this.screensharingToggleErrorMsg = i18n.t('errors.screensharingTogglePolicyError');
    } else if (errMsg.includes('notallowed') || errMsg.includes('not allowed')) {
      this.screensharingToggleErrorMsg = i18n.t('errors.screensharingTogglePermissionError');
    } else if (process.env.REACT_APP_FOR_ELECTRON) {
      this.screensharingToggleErrorMsg = i18n.t('errors.screenPermissionError');
    } else {
      this.screensharingToggleErrorMsg = i18n.t('errors.screensharingToggleUnexpectedError');
    }
  }

  @computed get isUsersPanelVisible(): boolean {
    return this.sidePanelContent === SidePanelContent.USERS;
  }

  @computed get isChatPanelVisible(): boolean {
    return this.sidePanelContent === SidePanelContent.CHAT;
  }

  @computed get isEffectsPanelVisible(): boolean {
    return this.sidePanelContent === SidePanelContent.EFFECTS;
  }

  @computed get isSidePanelVisible(): boolean {
    return this.sidePanelContent !== SidePanelContent.HIDDEN;
  }

  @action setSidePanelContent(content: SidePanelContent): void {
    this.sidePanelContent = content;
  }

  @action openUserDevices(): void {
    this.isUserDevicesOpen = true;
  }

  @action closeUserDevices(): void {
    this.isUserDevicesOpen = false;
  }

  @action openUserProfile(): void {
    this.isUserProfileOpen = true;
    this.setIsUserProfileVisible(true);
  }

  @action closeUserProfile(): void {
    if (this.imageCropperState) {
      return;
    }
    this.isUserProfileOpen = false;
    this.setIsUserProfileVisible(false);
    this.setUserProfileDialogState(ProfileStatesNames.PROFILE_DATA);
  }

  @action openImageCropper(img: string | ArrayBuffer | null): void {
    this.croppingImage = img;
    this.croppedImage = null;
  }

  @action openUserAvatarCropper(img: string | ArrayBuffer | null) {
    this.imageCropperState = ImageCropperState.userAvatar;
    this.setIsUserProfileVisible(false);
    this.openImageCropper(img);
  }

  @action openSpaceLogoCropper(img: string | ArrayBuffer | null) {
    this.imageCropperState = ImageCropperState.spaceLogo;
    this.setIsSpaceEditDialogShown(false);
    this.openImageCropper(img);
  }

  @action closeImageCropper(): void {
    if (this.imageCropperState === ImageCropperState.userAvatar) {
      this.setIsUserProfileVisible(true);
    }

    if (this.imageCropperState === ImageCropperState.spaceLogo) {
      this.setIsSpaceEditDialogShown(true);
    }

    this.imageCropperState = null;
    this.croppedImage = null;
  }

  @action setCroppedImage(canvas: HTMLCanvasElement): void {
    if (!canvas) {
      return;
    }
    this.croppedImage = canvas;
    this.imageCropperState = null;
  }

  @action async preprocessImage(type = 'image/jpg'): Promise<File | null> {
    await when(() => Boolean(this.croppedImage));
    if (this.croppedImage) {
      const canvas = this.croppedImage;
      const file: File | null = await new Promise((resolve) => {
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const fromBlobFile = new File([blob], 'image', { type });
              resolve(fromBlobFile);
            } else {
              resolve(null);
            }
          },
          type,
          1,
        );
      });
      this.croppedImage = null;
      return file;
    }
    return null;
  }

  isChatPanelOpen(): boolean {
    return this.sidePanelContent === SidePanelContent.CHAT;
  }

  @computed get isAnnouncementsOpen() {
    return this.sidePanelContent === SidePanelContent.ANNOUNCEMENTS;
  }

  @action toggleUsersPanel(): void {
    this.sidePanelContent = this.sidePanelContent === SidePanelContent.USERS
      ? SidePanelContent.HIDDEN : SidePanelContent.USERS;
  }

  @action toggleEffectsPanel(): void {
    this.sidePanelContent = this.sidePanelContent === SidePanelContent.EFFECTS
      ? SidePanelContent.HIDDEN : SidePanelContent.EFFECTS;
  }

  @action toggleChatPanel(): void {
    this.sidePanelContent = this.sidePanelContent === SidePanelContent.CHAT
      ? SidePanelContent.HIDDEN : SidePanelContent.CHAT;
  }

  @action toggleAnnouncementsPanel(): void {
    this.sidePanelContent = this.isAnnouncementsOpen
      ? SidePanelContent.HIDDEN : SidePanelContent.ANNOUNCEMENTS;
  }

  @action toggleBreakoutRooms(): void {
    this.sidePanelContent = this.sidePanelContent === SidePanelContent.BREAKOUT_ROOMS
      ? SidePanelContent.HIDDEN : SidePanelContent.BREAKOUT_ROOMS;
  }

  @action toggleIsChatExpanded(): void {
    this.isChatExpanded = !this.isChatExpanded;
  }

  @action hideSidePanel(): void {
    this.sidePanelContent = SidePanelContent.HIDDEN;
  }

  @action showErrorPage(errorType: Errors = Errors.SOMETHING_WENT_WRONG): void {
    this.errorPageData.error = ERROR_PAGE_ERRORS[errorType]?.error || i18n.t('errors.somethingWentWrong');
    this.errorPageData.hint = ERROR_PAGE_ERRORS[errorType]?.hint;
    this.hasPageError = true;
  }

  @action hideErrorPage(): void {
    this.hasPageError = false;
  }

  @computed get htmlTitle(): string {
    return this.rootStore.roomStore.name;
  }

  @action setIsLandscape(value: boolean): void {
    this.isLandscape = value;
  }

  @action setIsCompactWidth(value: boolean): void {
    this.isCompactWidth = value;
  }

  @action setIsCompactHeight(value: boolean): void {
    this.isCompactHeight = value;
  }

  @action setUserProfileDialogState(state: ProfileStatesNames): void {
    this.userProfileDialogState = state;
  }

  @action setIntentToLeaveRoom(state: boolean): void {
    this.hasIntentToLeaveRoom = state;
  }

  @action setIntentToKickAllAndLeaveRoom(state: boolean): void {
    this.hasIntentToKickAllAndLeaveRoom = state;
  }

  @action async leaveRoom(): Promise<void> {
    this.isLeavingRoom = true;
    await this.rootStore.roomStore.leave();
    this.isRoomLeft = true;
    this.setIntentToLeaveRoom(false);
    this.isLeavingRoom = false;
  }

  @action async kickAllAndLeaveRoom(): Promise<void> {
    this.isLeavingRoom = true;
    await this.rootStore.roomStore.finishCallForEveryone();
    this.setIntentToKickAllAndLeaveRoom(false);
    this.isLeavingRoom = false;
  }

  @action setLatestReaction(reaction: RoomEventNewReactionPayload): void {
    this.latestReaction = reaction;
    this.storeReaction(reaction);
  }

  @action storeReaction(reaction: RoomEventNewReactionPayload): void {
    if (!this.rootStore.participantStore.roomPermissions?.canResetReactionsCounters) {
      return;
    }

    const { reactions, reactionsCounter } = this;
    const { reactionType } = reaction;
    const prevSize = reactions.size;
    reactions.set(`${reaction.peerId}.${reaction.reactionType}`, reaction);
    if (reactions.size > prevSize) {
      this.reactionsCounter = { ...reactionsCounter, [reactionType]: reactionsCounter[reactionType] + 1 };
    }
  }

  @computed get roomReactions(): RoomEventNewReactionPayload[] {
    return Array.from(this.reactions.values());
  }

  @action clearReactions(): void {
    this.reactions.clear();
    this.reactionsCounter = { ...REACTIONS_INITIAL_VALUE };
  }

  @action setRoomsWithCalls(value: string[]): void {
    this.roomsIdsWithCalls = value;
  }

  @action setIsTotalCallsCountFetched(value: boolean): void {
    this.isTotalCallsCountFetched = value;
  }

  @action setIsTabActive(value: boolean): void {
    this.isTabActive = value;
  }

  @action setIsInviteDialogVisible(value: boolean): void {
    this.isInviteDialogVisible = value;
  }

  @action togglePulseDialog(): void {
    this.isPulseDialogVisible = !this.isPulseDialogVisible;
  }

  @action setPulseValue(value: number): void {
    this.pulseValue = value;
  }

  @action setPulseChartOpacity(opacity: number): void {
    this.pulseChartOpacityPercents = opacity;
  }

  @action togglePulseChartOpacity(): void {
    this.pulseChartOpacityPercents -= 20;
    if (this.pulseChartOpacityPercents < 20) {
      this.pulseChartOpacityPercents = 100;
    }
  }

  @action setIsPipOpen(value: boolean): void {
    this.rootStore.metrics.trackPipToggle(value);
    this.isPipOpen = value;

    if (this.isPipOpen && this.onPipStart) {
      this.onPipStart();
      return;
    }

    if (!this.isPipOpen && this.onPipStop) {
      this.onPipStop();
    }
  }

  @action togglePip(): void {
    this.setIsPipOpen(!this.isPipOpen);
  }

  @action setIsParticipantsVideoDisabled(state: boolean) {
    logger.warn('Video: ParticipantsVideoDisabled', { state });
    this.isParticipantsVideoDisabled = state;
  }

  @action setIsUserOwnVideoAutoDisabled(state: boolean) {
    logger.warn('Video: OwnVideoAutoDisabled', { state });
    this.isUserOwnVideoAutoDisabled = state;
  }

  @action setIsIntegrationDialogOpen(state: boolean) {
    this.isIntegrationDialogOpen = state;
  }

  @action setIsDebugInfoDialogOpen(state: boolean) {
    this.isDebugInfoDialogOpen = state;
  }

  @action setIsAudioInfoDialogOpen(state: boolean) {
    this.isAudioInfoDialogOpen = state;
  }

  @action setIsAntivirusBlocksConnectionWarningOpen(value: boolean) {
    this.isAntivirusBlocksConnectionWarningOpen = value;
  }

  @action setIsFirewallBlocksConnectionWarningOpen(value: boolean) {
    this.isFireWallBlocksConnectionWarningOpen = value;
  }

  @action setIsConnectionIconShown(value: boolean): void {
    this.isConnectionIconShown = value;
  }

  @action setIsUserProfileVisible(value: boolean): void {
    this.isUserProfileVisible = value;
  }

  @action setIsSpaceEditDialogShown(value: boolean): void {
    this.isSpaceEditDialogShown = value;
  }

  @action setChatMobileVisualState(state: ChatMobileVisualState) {
    this.chatMobileVisualState = state;
  }

  @action setIsRoomFullScreenControlsVisible(value: boolean) {
    this.isRoomFullScreenControlsVisible = value;
  }

  @action setIsElectronScreensharingDialogOpen(state: boolean) {
    this.isElectronScreensharingDialogOpen = state;
  }

  reloadThePage(): void {
    if (typeof window === 'undefined') {
      return;
    }

    window.location.reload();
  }

  @action setIsWaitingRoomToastOpen(value: boolean): void {
    this.isWaitingRoomToastOpen = value;
  }

  @observable isPageFullScreenDisabled = true;

  @observable isEnableFullScreenLock = false;

  @action pageFullScreenChangeHandler(): void {
    this.isPageFullScreenDisabled = !document.fullscreenElement;
  }

  @action addPageFullScreenChangeEventListener(): void {
    document.addEventListener('fullscreenchange', () => this.pageFullScreenChangeHandler());
  }

  @action removePageFullScreenChangeEventListener(): void {
    document.removeEventListener('fullscreenchange', () => this.pageFullScreenChangeHandler());
  }

  @action async togglePageFullScreen(): Promise<void> {
    if (this.isEnableFullScreenLock) {
      return;
    }

    this.isEnableFullScreenLock = true;

    try {
      if (this.isPageFullScreenDisabled) {
        await enterPageFullScreen(document.querySelector('html'));

        this.isPageFullScreenDisabled = false;

        return;
      }

      if (document.fullscreenElement === null) {
        this.isPageFullScreenDisabled = true;
        return;
      }

      await exitPageFullScreen();

      this.isPageFullScreenDisabled = true;
    } catch (error) {
      logger.error('Failed to toggle fullscreen mode', { error });

      this.isPageFullScreenDisabled = true;
    } finally {
      this.isEnableFullScreenLock = false;
    }
  }

  @computed get showUserDevices(): boolean {
    const {
      roomStore,
      participantStore,
    } = this.rootStore;

    return (roomStore.type === RoomType.Lesson)
      || participantStore.isWebinarHost;
  }

  @action openChatInPopupWindow() {
    const { roomAlias } = this.rootStore.roomStore;
    const eventId = this.rootStore.eventStore.event?.id;

    if (!roomAlias && !eventId) {
      return;
    }

    if (this.chatNewWindow) {
      this.chatNewWindow.focus();
      this.hideSidePanel();
      return;
    }

    let popupChatUrl;

    if (roomAlias) {
      popupChatUrl = `/chat/${roomAlias}`;
      const accessToken: string = this.rootStore.authStore.currentAccessToken || ''; // iFrame
      popupChatUrl = accessToken ? `${popupChatUrl}?accessToken=${accessToken}` : popupChatUrl;
    } else if (eventId) {
      const spaceId = this.rootStore.eventStore.event?.spaceId;
      if (this.rootStore.eventStore.isPreview && spaceId) {
        popupChatUrl = `/chat/space/${spaceId}/playback/${eventId}/preview`;
      } else {
        popupChatUrl = `/chat/${eventId}/event`;
      }
    }

    if (!popupChatUrl) {
      return;
    }

    const features = toWindowFeatures({
      width: CHAT_WINDOW_WIDTH,
      height: window.screen.availHeight,
      left: window.screen.availWidth - CHAT_WINDOW_WIDTH,
      top: 0,
    });

    this.chatNewWindow = window.open(popupChatUrl, undefined, features);

    this.hideSidePanel();

    const onChatPopupWindowPostMessage = ({ data }: MessageEvent<PostMessage>) => {
      if (data.topic === MessagesFromChatPopup.chatPopupWindowIsReady) {
        if (Boolean(data.payload) && this.rootStore.eventStore.event?.id && this.rootStore.eventStore.joinResult) {
          this.chatNewWindow?.postMessage({
            topic: MessagesToChatPopup.chatSetEventJoinInfo,
            payload: {
              id: this.rootStore.eventStore.joinResult.id,
              startDate: this.rootStore.eventStore.joinResult.startDate,
              endDate: this.rootStore.eventStore.joinResult.endDate,
              type: this.rootStore.eventStore.joinResult.type,
              playbackEventId: this.rootStore.eventStore.joinResult.playbackEventId,
              mediaUrl: this.rootStore.eventStore.joinResult.mediaUrl,
              chatApplicationId: this.rootStore.eventStore.joinResult.chatApplicationId,
              chatChannelId: this.rootStore.eventStore.joinResult.chatChannelId,
            },
          } as PostMessage);
        }
      }
    };

    window.addEventListener('message', onChatPopupWindowPostMessage);

    const onChatWindowClose = () => {
      const handleChatWindowClose = () => {
        this.chatNewWindow?.removeEventListener('pagehide', onChatWindowClose);
        this.chatNewWindow = null;

        if (this.sidePanelContent === SidePanelContent.HIDDEN) {
          this.sidePanelContent = SidePanelContent.CHAT;
        }

        window.removeEventListener('message', onChatPopupWindowPostMessage);
      };

      // timeout is added because a window does not have "closed: true" property
      // when the event is fired, it's set on the next cycles of the loop
      setTimeout(() => {
        // "pagehide" / "unload" events are triggered several times by some browsers, that's why
        // we need to check if the window is really closed or does not exist
        if (this.chatNewWindow && !this.chatNewWindow.closed) {
          return;
        }

        handleChatWindowClose();
      }, 150);
    };

    // "unload" event can be not triggered by some browsers, so we use "pagehide" event as recommended one by MDN
    this.chatNewWindow?.addEventListener('pagehide', onChatWindowClose);

    const onUnloadMainWindow = () => {
      this.closeChatInPopupWindow();
      window.removeEventListener('unload', onUnloadMainWindow);
    };

    window.addEventListener('unload', onUnloadMainWindow);
  }

  closeChatInPopupWindow() {
    this.chatNewWindow?.close();
    this.chatNewWindow = null;
  }

  @computed get isCompact() {
    return this.isCompactWidth || this.isCompactHeight;
  }

  @action setTimeoutForParticipantInRoomDialog(timeout: number) {
    this.timeoutForParticipantInRoomDialogInSec = timeout;
  }

  @computed get isParticipantInRoomDialogOpen() {
    return Boolean(this.timeoutForParticipantInRoomDialogInSec);
  }

  @action setIsDesktopAppSharingActive(value: boolean) {
    this.isDesktopAppSharingActive = value;
  }

  @subscribe(AppEvent.MovingToRoom)
  handleMoveToRoom() {
    this.closeChatInPopupWindow();
  }

  @action setIsVideoEffectsDialogOpen(value: boolean) {
    this.isVideoEffectsDialogOpen = value;
  }

  @subscribe(AppEvent.Error)
  handleError({ payload: error }: { payload: Errors }) {
    this.showErrorPage(error);
  }
}

export default UIStore;
