import { computed } from 'mobx';
import i18next from 'i18next';
import { toast } from 'react-toastify';

import { Plan } from 'types/payment';
import { getBrandOptions } from 'modules/Branding';

import logger from 'helpers/logger';
import { PlansParams, PlanInfo } from './types';

class Plans {
  private params: PlansParams;

  private getPlanInfo(plan: Plan): PlanInfo | null {
    const { planDescriptions, freePlanAlias } = getBrandOptions();
    const { space, payments, withPromoPlans } = this.params;
    const description = planDescriptions[plan.alias];

    if (!description) {
      return null;
    }

    const planInfo = {
      ...description,
      price: plan.price,
      planId: plan.id,
      subscriptionEndsAt: space?.subscriptionEndsAt,
      isSubscriptionActive: space?.isSubscriptionActive,
    };

    if (plan.alias === freePlanAlias || plan.price === -1) {
      return planInfo;
    }

    const annualPlan = payments.paymentPlans.find(
      (item) => (item.alias === description.annualAlias),
    );

    if (!annualPlan) {
      throw new Error(`Payment: Annual plan not found ${plan.alias}`);
    }

    const promoPlan = withPromoPlans ? payments.paymentPlans.find(
      (item) => (item.alias === description.promoAlias),
    ) : null;

    const promoAnnualPlan = withPromoPlans ? payments.paymentPlans.find(
      (item) => (item.alias === description.promoAnnualAlias),
    ) : null;

    return {
      ...planInfo,
      discounts: {
        annual: {
          planId: annualPlan.id,
          totalPrice: annualPlan.price,
        },
        ...(promoPlan && {
          promo: {
            planId: promoPlan.id,
            totalPrice: promoPlan.price,
          },
        }),
        ...(promoAnnualPlan && {
          promoAnnual: {
            planId: promoAnnualPlan.id,
            totalPrice: promoAnnualPlan.price,
          },
        }),
      },
    };
  }

  constructor(params: PlansParams) {
    this.params = params;
  }

  async load() {
    const { payments, space } = this.params;
    await payments.loadPlans();
    if (payments.paymentPlans.length === 0) {
      toast.error(i18next.t('payment.fetchPlansFail'));
      logger.error('Payment: failed to fetch plans');
      return;
    }

    await payments.setCurrentPlan(space.id);
    if (!payments.currentPlan) {
      toast.error(i18next.t('payment.fetchPlansFail'));
      logger.error('Payment: failed to fetch current plan');
    }
  }

  @computed get plansInfo() {
    const { payments, withPromoPlans } = this.params;

    const plans: PlanInfo[] = payments.paymentPlans
      .filter((plan) => plan.isActive && (withPromoPlans || (!withPromoPlans && !plan.isPromo)))
      .sort((a, b) => ((a.price < 0 ? Infinity : a.price) - (b.price < 0 ? Infinity : b.price)))
      .map((plan) => this.getPlanInfo(plan))
      .filter(Boolean) as PlanInfo[];

    return plans;
  }

  @computed get currentPlan() {
    return this.params.payments.currentPlan;
  }
}

export default Plans;
