import React, { FunctionComponent, HTMLProps } from 'react';

import { InputMessageRoot } from './InputMessage.styled';
import { FigmaTypographyProps } from '../FigmaTypography';

const InputMessage: FunctionComponent<Omit<HTMLProps<HTMLDivElement>, 'ref'> & FigmaTypographyProps & {
  isHint?: boolean,
}> = ({
  isHint = false,
  children,
  ...restProps
}) => (
  <InputMessageRoot size="label-text-medium" as="div" isHint={isHint} {...restProps}>
    {children}
  </InputMessageRoot>
);

export default InputMessage;
