import { rootStore } from 'stores/root';

import PlaybackEventSessions from './PlaybackEventSessions';

let instance: PlaybackEventSessions | null = null;

const dispose = () => {
  if (instance) {
    instance.dispose();
    instance = null;
  }
};

const getInstance = () => {
  if (instance) {
    return instance;
  }

  const {
    spaceStore: { space },
    moodHoodApiClient: { playbacks, playbackEvents, analytics },
  } = rootStore;

  if (!space) {
    throw new Error('Playbacks info not available');
  }

  instance = new PlaybackEventSessions({
    spaceId: space.id,
    playbacksApi: playbacks,
    playbackEventsApi: playbackEvents,
    analyticsApi: analytics,
  });

  return instance;
};

export default { getInstance, dispose };
