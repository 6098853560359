import { MicrophoneAudioLevel } from 'types/common';
import { getNewAudioContext } from 'helpers/browser';
import logger from 'helpers/logger';

const BUFFER_SIZE = 512;

class AudioMeter {
  private audioContext: AudioContext | null = null;

  private audioAnalyser: AnalyserNode | null = null;

  private audioSource: MediaStreamAudioSourceNode | null = null;

  private audioLevelWorklet: AudioWorkletNode | null = null;

  // TODO: ScriptProcessorNode is deprecated, must be replace by AudioWorkletNode
  private audioProcessor: ScriptProcessorNode | null = null;

  // eslint-disable-next-line no-bitwise
  private static freqData: Uint8Array = new Uint8Array(BUFFER_SIZE >> 1);

  constructor(stream: MediaStream, cb: (audioLevel: number) => void) {
    this.processorNodeInit(stream, cb);
  }

  dispose(): void {
    if (this.audioLevelWorklet) {
      this.audioLevelWorklet.disconnect();
      this.audioLevelWorklet = null;
    }

    if (this.audioSource) {
      this.audioSource.disconnect();
      this.audioSource = null;
    }

    if (this.audioAnalyser) {
      this.audioAnalyser.disconnect();
      this.audioAnalyser = null;
    }

    if (this.audioProcessor) {
      this.audioProcessor.onaudioprocess = null;
      this.audioProcessor.disconnect();
      this.audioProcessor = null;
    }

    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = null;
    }
  }

  async workletInit(stream: MediaStream, cb: (audioLevel: number) => void): Promise<void> {
    try {
      this.audioContext = getNewAudioContext();
      if (!this.audioContext) {
        logger.error('AudioMeter: Audio Context is not available');
        return;
      }

      this.audioSource = new MediaStreamAudioSourceNode(this.audioContext, { mediaStream: stream });
      await this.audioContext.audioWorklet.addModule('/audio-level-worklet.js');
      this.audioLevelWorklet = new AudioWorkletNode(this.audioContext, 'audio-level-worklet');
      this.audioSource.connect(this.audioLevelWorklet);
      this.audioLevelWorklet.onprocessorerror = (e) => {
        logger.error('AudioMeter: onprocessorerror', { error: e });
        // eslint-disable-next-line no-console
        console.trace();
      };

      this.audioLevelWorklet.port.onmessage = (event) => {
        if (event.data.audioLevel) {
          const audioLevel = Math.min(Math.round(event.data.audioLevel * 100), MicrophoneAudioLevel.MAX);
          cb(audioLevel);
        }
      };
    } catch (error) {
      logger.error('AudioMeter: Failed to init', { error });
    }
  }

  processorNodeInit(stream: MediaStream, cb: (audioLevel: number) => void): void {
    this.audioContext = getNewAudioContext();

    if (!this.audioContext) {
      return;
    }

    this.audioAnalyser = this.audioContext.createAnalyser();
    this.audioAnalyser.smoothingTimeConstant = 0.8;
    this.audioAnalyser.fftSize = BUFFER_SIZE;
    this.audioSource = this.audioContext.createMediaStreamSource(stream);
    this.audioSource.connect(this.audioAnalyser);
    this.audioProcessor = this.audioContext.createScriptProcessor(BUFFER_SIZE, 1, 1);

    if (this.audioProcessor && this.audioAnalyser) {
      this.audioAnalyser.connect(this.audioProcessor);
      this.audioProcessor.connect(this.audioContext.destination);
      this.audioProcessor.onaudioprocess = () => {
        if (this.audioAnalyser) {
          this.audioAnalyser.getByteFrequencyData(AudioMeter.freqData);
          const frequencySum = AudioMeter.freqData.reduce(
            (accumulator, frequencyLevel) => accumulator + frequencyLevel, 0,
          );
          const averageLevel = Math.round(((frequencySum / AudioMeter.freqData.length) / BUFFER_SIZE) * 100);
          const audioLevel = Math.min(averageLevel, MicrophoneAudioLevel.MAX);
          cb(audioLevel);
        }
      };
    }
  }
}

export default AudioMeter;
