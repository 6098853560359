import { useEffect } from 'react';

import useStores from 'stores/root';

const useEventStateTracking = ({
  isChatPopup,
  id,
  alias,
  isPreview,
  spaceId,
}: {
  isChatPopup: boolean,
  id?: string,
  alias?: string,
  isPreview?: boolean,
  spaceId?: string,
}) => {
  const {
    authStore,
    eventStore,
    paymentStore,
    participantStore,
  } = useStores();

  useEffect(() => {
    if (id) {
      eventStore.resetStore();
    }
  }, []);

  useEffect(() => {
    eventStore.setIsPreview(Boolean(isPreview));
  }, [isPreview]);

  useEffect(() => {
    eventStore.setIsJoinByAlias(Boolean(alias));
  }, [alias]);

  useEffect(() => {
    if (!id && !alias) {
      return;
    }

    if (authStore.isAuthenticated) {
      eventStore.fetchInfo({ id, alias }, spaceId);
    }
  }, [authStore.isAuthenticated, id, spaceId, alias]);

  useEffect(() => {
    if (!eventStore.isEventInfoFetched) {
      return;
    }

    eventStore.fetchSpaceInfo();
    if (eventStore.isPreview) {
      eventStore.fetchPlaybackTimeline();
    } else {
      eventStore.fetchPlaybackEventTimeline();
    }

    participantStore.setIsUserPermissionsFetched(true);
  }, [eventStore.isEventInfoFetched]);

  useEffect(() => {
    if (!eventStore.isEventJoined) {
      return;
    }

    eventStore.startEventLoop();
  }, [eventStore.isEventJoined]);

  useEffect(() => () => {
    eventStore.stopEventLoop();
  }, []);

  useEffect(() => {
    if (isChatPopup) {
      return;
    }

    if (eventStore.spaceId) {
      paymentStore.getCurrentPlan(eventStore.spaceId);
    }
  }, [isChatPopup, eventStore.spaceId]);

  useEffect(() => {
    if (isChatPopup) {
      return;
    }

    eventStore.setIsWhiteLabel(Boolean(paymentStore.currentPlan?.features.customSpaceLogo));
  }, [isChatPopup, paymentStore.currentPlan]);
};

export default useEventStateTracking;
