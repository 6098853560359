import React, {
  FunctionComponent, useCallback, useState, ReactElement, cloneElement,
} from 'react';
import { observer } from 'mobx-react-lite';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import useStores from 'stores/root';
import {
  TwoColumns, General,
} from 'ui-kit/components/Dialog/DialogLayoutDesktop';
import { drawImage } from 'ui-kit/components/ImageUploader/helpers';

import {
  Section, SecondColumn,
} from './Layout';
import CropperDialog from './CropperDialog';

export interface ImageCropperDialogProps {
  onClose?(): void;
  aspect?: number;
  isCircular?: boolean;
  previewComponent?: ReactElement;
  title: string;
  cropperTitle: string;
  previewTitle: string;
}

const ImageCropperDialog: FunctionComponent<ImageCropperDialogProps> = observer(({
  onClose,
  aspect,
  title,
  isCircular = true,
  previewComponent,
  cropperTitle,
  previewTitle,
}) => {
  const { uiStore } = useStores();

  const [image, setImage] = useState<HTMLImageElement>();
  const [completedCrop, setCompletedCrop] = useState<Crop>({});
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    width: 30,
    ...(!aspect && { height: 30 }),
    aspect,
  });

  const onLoad = useCallback((img: HTMLImageElement) => {
    setImage(img);
  }, []);

  const handleCloseImageCropper = () => {
    uiStore.closeImageCropper();
  };

  const handleSaveImage = useCallback(() => {
    const canvasElement = document.createElement('canvas');
    if (image) {
      drawImage(canvasElement, image, completedCrop);
      uiStore.setCroppedImage(canvasElement);
    }
  }, [image, completedCrop]);

  return (
    <CropperDialog
      title={title}
      onClose={onClose}
      onCancel={handleCloseImageCropper}
      onSave={handleSaveImage}
      saveButtonDisabled={!completedCrop.width || !completedCrop.height}
    >
      <TwoColumns>
        <General>
          <Section>{cropperTitle}</Section>
          <ReactCrop
            src={uiStore.croppingImage as string}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
            circularCrop={isCircular}
            style={{ width: 288 }}
          />
        </General>
        <SecondColumn>
          <Section>{previewTitle}</Section>
          {previewComponent && cloneElement(previewComponent, {
            crop,
            completedCrop,
            image,
          })}
        </SecondColumn>
      </TwoColumns>
    </CropperDialog>
  );
});

export default ImageCropperDialog;
