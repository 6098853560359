import React, {
  FunctionComponent,
  useEffect,
} from 'react';
import { observer } from 'mobx-react-lite';
import isMobile from 'is-mobile';

import useStores from 'stores/root';
import { isAdministrativeSpaceRole } from 'helpers/roles';
import { SpaceRole } from 'services/MoodHoodApiClient/types';
import { WithCSSTransition } from 'ui-kit';
import UserProfileDialog from 'components/UserProfile/Dialog';
import useRoomRecordsFromUrl from 'components/Space/hooks/useRoomRecordsFromUrl';

import BasicHeaderMobile from './Mobile';
import BasicHeaderDesktop from './Desktop';

interface BasicHeaderProps {
  outerSpaceId?: string;
}

const BasicHeader: FunctionComponent<BasicHeaderProps> = observer(({ outerSpaceId }) => {
  const {
    spaceStore,
    rootStore: { moodHoodApiClient },
    uiStore,
    participantStore,
  } = useStores();

  const spaceIdFromRoomRecords = useRoomRecordsFromUrl();
  const spaceId = outerSpaceId || spaceIdFromRoomRecords;

  useEffect(() => {
    if (!spaceId) {
      return;
    }

    const setSpace = async () => {
      const { space } = await moodHoodApiClient.space.getSpace(spaceId);
      participantStore.setIsModerator(isAdministrativeSpaceRole(space?.roleInSpace ?? SpaceRole.Guest));

      if (space) {
        spaceStore.setSpaceInfo({ space });
      }
    };

    setSpace();
  }, [spaceId]);

  const shouldOpenUserProfile = uiStore.isUserProfileOpen;

  return (
    <>
      {isMobile() ? (
        <BasicHeaderMobile />
      ) : (
        <BasicHeaderDesktop />
      )}
      <WithCSSTransition inValue={shouldOpenUserProfile}>
        <UserProfileDialog onClose={() => uiStore.closeUserProfile()} />
      </WithCSSTransition>
    </>
  );
});

export default BasicHeader;
