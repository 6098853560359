import { eventBus, subscribe } from 'mobx-event-bus2';
import type Peer from '@livedigital/client/dist/engine/Peer';

import { showPutHandDownAutomaticallyToast } from 'components/Toasts/PutHandDownAutomatically';

import { ActiveSpeakerChangedEvent } from './types';

const CHEKCK_SPEAKER_TIMEOUT_IN_MS = 2000;

class PutHandDownAutomatically {
  private dominantSpeakerTimer: NodeJS.Timer | null = null;

  private lastDominantSpeaker: Peer | null = null;

  private checkIsStillActiveSpeaker() {
    this.dominantSpeakerTimer = null;
    if (!this.lastDominantSpeaker?.appData.isHandRaised) {
      return;
    }

    showPutHandDownAutomaticallyToast();
  }

  private reset() {
    this.lastDominantSpeaker = null;
    if (this.dominantSpeakerTimer) {
      clearTimeout(this.dominantSpeakerTimer);
      this.dominantSpeakerTimer = null;
    }
  }

  constructor() {
    eventBus.register(this);
  }

  dispose() {
    this.lastDominantSpeaker = null;
    this.reset();
  }

  @subscribe<ActiveSpeakerChangedEvent>('active-speaker-changed')
  private handleDominantSpeakerChange(evt: ActiveSpeakerChangedEvent) {
    const { peer } = evt.payload || {};

    if (!peer?.isMe) {
      this.reset();
      return;
    }

    if (peer.isMe && peer.appData.isHandRaised) {
      this.lastDominantSpeaker = peer;
      this.dominantSpeakerTimer = setTimeout(() => this.checkIsStillActiveSpeaker(), CHEKCK_SPEAKER_TIMEOUT_IN_MS);
    }
  }
}

export default PutHandDownAutomatically;
