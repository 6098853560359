import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { LivedigitalTeleboss } from 'components/Optional';
import useStores from 'stores/root';
import IntegrationDialog from 'components/Dialogs/Desktop/IntegrationDialog';
import { CursorClickIcon } from 'ui-kit/assets/icons';
import { MediaBreakPoint } from 'ui-kit/theme';
import TeleBoss from 'components/Optional/TeleBoss';
import { getBrandOptions } from 'modules/Branding';

import {
  BasicHeaderDesktopRoot,
  Logo,
  LeftSideWrapper,
  DesktopBreadcrumbs,
  CenterContainer,
  Buttons,
} from './index.styled';
import KnowledgeBaseButton from './KnowledgeBaseButton';
import UserButton from '../UserButton';
import IntegrationButton from './IntegrationButton';
import SettingsButton from '../SettingsButton';
import PaymentPlansButton from './PaymentPlansButton';

const BasicHeaderDesktop: FunctionComponent = observer(() => {
  const {
    uiStore,
    userStore,
    paymentStore,
    spaceStore,
    authStore,
  } = useStores();

  const { t } = useTranslation();
  const { freePlanAlias } = getBrandOptions();

  const isBuyButtonVisible = spaceStore.isSpaceOwner
    && (paymentStore.currentPlan?.alias === freePlanAlias || !spaceStore.space?.isSubscriptionActive);

  return (
    <BasicHeaderDesktopRoot>
      <CenterContainer>
        <LeftSideWrapper>
          <Logo data-stats="ui_rooms_ld_logo" />
          <DesktopBreadcrumbs />
        </LeftSideWrapper>
        <Buttons>
          {authStore.isUser && (<IntegrationButton />)}
          <TeleBoss>
            {isBuyButtonVisible && (
              <PaymentPlansButton
                data-stats="ui_rooms_plan_header"
                variant="primary"
                fullWidth={false}
                text={t('payment.selectPlan')}
                hideAtBreakpoint={MediaBreakPoint.WIDTH_375}
              >
                <CursorClickIcon />
              </PaymentPlansButton>
            )}
          </TeleBoss>
          <LivedigitalTeleboss>
            <KnowledgeBaseButton />
          </LivedigitalTeleboss>
          <SettingsButton />
          <UserButton
            name={userStore.username}
            icon={userStore.image}
            isCompact={uiStore.isCompact}
          />
          {uiStore.isIntegrationDialogOpen && (
            <IntegrationDialog />
          )}
        </Buttons>
      </CenterContainer>
    </BasicHeaderDesktopRoot>
  );
});

export default BasicHeaderDesktop;
