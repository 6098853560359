import React, {
  ChangeEvent, ComponentProps, forwardRef,
} from 'react';

import { CheckboxRoot, CheckBoxInput, LoaderLayout } from './index.styled';
import Loader from '../Loader';

export type LabelPosition = 'right' | 'left';

interface CheckboxProps extends ComponentProps<'input'> {
  id: string;
  label?: string;
  inverted?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  loading?: boolean;
  labelPosition?: LabelPosition,
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({
  label,
  className,
  loading,
  labelPosition = 'right',
  style,
  ...props
}, ref) => (
  <CheckboxRoot className={className} labelPosition={labelPosition} style={style}>
    {
      loading
        ? (
          <LoaderLayout>
            <Loader />
          </LoaderLayout>
        )
        : <CheckBoxInput type="checkbox" {...props} ref={ref} />
    }
    {label}
  </CheckboxRoot>
));

export default Checkbox;
