import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import isMobile from 'is-mobile';
import { SubmitHandler, useForm } from 'react-hook-form';

import useStores from 'stores/root';
import { Errors } from 'constants/errors';
import i18n from 'services/i18n';

import { MobileLayout } from '../MobileLayout';
import {
  DesktopLayout,
} from '../DesktopLayout';
import { ProfileStatesNames } from '../type';
import ChangeEmailForm from './ChangeEmailForm';

export interface FormInput {
  currentEmail: string;
  newEmail: string
}

const ChangeEmail: FunctionComponent = observer(() => {
  const { userStore, uiStore } = useStores();
  const [serverError, setServerError] = useState<string | undefined>();
  const { t } = useTranslation();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const getReCaptchaToken = async ():Promise<string> => {
    if (!executeRecaptcha) {
      return '';
    }

    const token: string = await executeRecaptcha('requestChangeEmail');

    return token;
  };

  const useFormReturn = useForm<FormInput>({
    mode: 'onChange',
    defaultValues: { currentEmail: userStore.email },
  });

  const { clearErrors, setError } = useFormReturn;

  const onSubmit: SubmitHandler<FormInput> = async ({ newEmail }) => {
    await userStore.updateUserEmail(newEmail, getReCaptchaToken);
    if (!userStore.emailUpdatingError) {
      uiStore.setUserProfileDialogState(ProfileStatesNames.CHANGE_EMAIL_REQUEST_SUCCESS);
    }
  };

  useEffect(() => {
    if (!userStore.emailUpdatingError) {
      clearErrors('newEmail');
      setServerError(undefined);
      return;
    }

    switch (userStore.emailUpdatingError) {
      case Errors.ALREADY_REGISTERED:
        setError('newEmail', { message: t('auth.emailIsAlreadyTaken') });
        break;
      default:
        setServerError(i18n.t('errors.somethingWentWrong'));
    }
  }, [userStore.emailUpdatingError]);

  useEffect(() => () => userStore.clearEmailUpdatingError(), []);

  const Layout = isMobile() ? MobileLayout : DesktopLayout;

  return (
    <Layout>
      <ChangeEmailForm
        useFormReturn={useFormReturn}
        onSubmit={onSubmit}
        serverError={serverError}
      />
    </Layout>
  );
});

export default ChangeEmail;
