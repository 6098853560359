import React, { FunctionComponent } from 'react';

import { Popup as DefaultPopup } from 'ui-kit';
import { PopupProps as DefaultPopupProps } from 'ui-kit/components/Popup/types';
import DialogFromBottom from 'ui-kit/components/DialogFromBottom';

interface PopupProps extends DefaultPopupProps {
  isCompact: boolean;
  showSwipeIndicator?: boolean;
}

const Popup: FunctionComponent<PopupProps> = (props: PopupProps) => {
  const { isCompact, ...restProps } = props;

  return (
    isCompact ? <DialogFromBottom {...restProps} /> : <DefaultPopup {...restProps} />
  );
};

export default Popup;
