import {
  action, computed, makeAutoObservable, observable,
} from 'mobx';
import isMobile from 'is-mobile';

import {
  TSpaceInfo,
} from 'types/common';
import SpaceApi from 'services/MoodHoodApiClient/space';
import Api from 'services/MoodHoodApiClient/Api';
import { SpaceRole } from 'services/MoodHoodApiClient/types';

class Spaces {
  @observable spaces: TSpaceInfo[] | null = null;

  @observable defaultSpace: TSpaceInfo | null = null;

  @observable isSpacesFetched = false;

  @observable spaceToEdit: TSpaceInfo | null = null;

  private spaceApi: SpaceApi;

  constructor() {
    this.spaceApi = new SpaceApi(Api);
    makeAutoObservable(this);
  }

  getDefaultSpace(spaces: TSpaceInfo[]): TSpaceInfo | null {
    if (spaces?.length > 0 && isMobile()) {
      return spaces[0];
    }

    return null;
  }

  findFirstSpaceWithOwnerRole() {
    return this.spaces?.find((s) => s.roleInSpace === SpaceRole.Owner);
  }

  findSpace(spaceId: string) {
    return this.spaces?.find((space) => space.id === spaceId) || null;
  }

  findSpacesWithNameLike(nameFilter: string) {
    return this.spaces?.filter(({ name }) => name?.toLocaleLowerCase().includes(nameFilter)) || [];
  }

  @action async fetchSpaces(): Promise<void> {
    const { data } = await this.spaceApi.getSpaces();
    if (data?.items) {
      this.setDefaultSpace(this.getDefaultSpace(data.items));
      this.setSpaces(data.items);
    }

    this.setIsSpacesFetched(true);
  }

  @action setDefaultSpace(value: TSpaceInfo | null) {
    this.defaultSpace = value;
  }

  @action setSpaces(value: TSpaceInfo[]) {
    this.spaces = value;
  }

  @action setIsSpacesFetched(value: boolean) {
    this.isSpacesFetched = value;
  }

  @action setSpaceToEdit(space: TSpaceInfo | null): void {
    if (!space) {
      this.spaceToEdit = null;
      return;
    }

    const toEdit = { ...space } as TSpaceInfo;
    toEdit.description = toEdit.description ?? '';
    toEdit.name = toEdit.name ?? '';
    toEdit.chatToken = toEdit.chatToken ?? '';
    this.spaceToEdit = toEdit;
  }

  @computed get all() {
    return this.spaces;
  }
}

export default new Spaces();
