import React, { FunctionComponent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';

import useStores from 'stores/root';
import logger from 'helpers/logger';
import {
  Badge,
  FigmaTypography,
  FlexCenter,
  IconButton,
  InfoIconWithTooltip,
} from 'ui-kit';
import { PlanInfo } from 'modules/Plans/types';
import { PAYMENT_SYSTEM, PRODAMUS_URL } from 'constants/payment';
import { PromoPlanPricing, PromoPlans } from 'components/PromoPlansPage/const';
import Prodamus from 'services/Prodamus';
import { getBrandOptions } from 'modules/Branding';

import { CurrencyCode } from 'types/stores/announcements';
import {
  Badges,
  BulletContainer,
  Currency,
  ExtraFeature,
  ExtraFeaturesList,
  ExtraFeaturesTitle,
  ExtraFeatureText,
  ExtraFeatureTextsHolder,
  FeatureMainText,
  FeaturesContainer,
  FeatureSecondaryText,
  Footer,
  Header,
  Layout,
  MainFeature,
  MainFeaturesList,
  Price,
  PriceContainer,
  Priceless,
  Title,
} from './PlanCard.styled';
import { BillPeriod } from './types';

interface PlanCardProps extends PlanInfo {
  period: BillPeriod,
  openFreePlanDialog?(): void,
  inPopup?: boolean,
  hideTitle?: boolean,
  promoPlanPricing?: PromoPlanPricing,
}

const getPromoPlanPricing = (
  promoPlanPricing: PromoPlanPricing,
  alias: string,
  period: BillPeriod,
) => promoPlanPricing?.[alias as PromoPlans]?.[period] || null;

const getFinalPlan = (plan: Pick<PlanInfo, 'discounts' | 'planId'>, period: BillPeriod) => {
  if (period === 'month') {
    return plan.discounts?.promo?.planId || plan.planId;
  }

  return plan.discounts?.promoAnnual?.planId || plan.discounts?.annual.planId || plan.planId;
};

const PlanCard: FunctionComponent<PlanCardProps> = observer(({
  title, titleHint, alias, price,
  mainFeatures, priceHintMonthly, priceHintAnnual,
  extraFeatures, extraFeaturesTitle, buyButtonText, planId,
  openFreePlanDialog, inPopup, period, discounts, hideTitle, promoPlanPricing,
}) => {
  const {
    spaceStore: { space },
    rootStore: { moodHoodApiClient },
  } = useStores();
  const { t } = useTranslation();
  const [isPaymentPending, setIsPaymentPending] = useState(false);

  const planMainFeatureList = t(mainFeatures, { returnObjects: true });
  const planMainFeatureArray = Array.isArray(planMainFeatureList) ? planMainFeatureList : [];

  const planExtraFeatureList = t(extraFeatures, { returnObjects: true });
  const planExtraFeatureArray = Array.isArray(planExtraFeatureList) ? planExtraFeatureList : [];
  const finalPrice = period === 'month' ? price : (discounts?.annual.totalPrice || 0) / 12;
  const discount = (period === 'month' || !price) ? 0 : Math.round((1 - (finalPrice / price)) * 100);

  const { onlyThroughSupport, planDescriptions, freePlanAlias } = getBrandOptions();

  const handlePlanSelect = async () => {
    if (!space) {
      return;
    }

    setIsPaymentPending(true);

    if (onlyThroughSupport.includes(alias)) {
      setIsPaymentPending(false);
      return;
    }

    const actualPlanId = getFinalPlan({ planId, discounts }, period);

    if (!actualPlanId) {
      throw new Error(`Payment: Plan not found ${alias}`);
    }

    // for payment checking
    const urlParams = new URLSearchParams(window.location.search.toLowerCase());
    const paramsOrderSum = urlParams.get('ordersum');

    const {
      data: payment,
      error,
    } = await moodHoodApiClient.payments.changePlan(space.id, actualPlanId, PAYMENT_SYSTEM);

    if (payment?.redirectUri) {
      window.location.href = payment.redirectUri;
    } else if (payment?.externalPaymentId && discounts?.annual.totalPrice) {
      Prodamus.openProdamusWidget({
        url: PRODAMUS_URL,
        currency: CurrencyCode.RUB,
        orderSum: Number(paramsOrderSum) || (period === 'month' ? price : discounts?.annual.totalPrice),
        orderNum: payment.externalPaymentId,
      });
    } else {
      logger.error('Failed to change plan', {
        error,
        alias,
        planId: actualPlanId,
        discounts,
        spaceId: space.id,
      });

      toast.error(t('payment.changePlanFail'));
    }

    setIsPaymentPending(false);
  };

  const freePlanName = planDescriptions[freePlanAlias]?.name;

  const priceHintValue = t(period === 'month' ? priceHintMonthly : priceHintAnnual);
  const freePlanFeatures = (
    <Trans
      i18nKey="payment.freeFeatures"
      t={t}
      components={[(
        <IconButton id="free-plan-info" subVariant="link" onClick={openFreePlanDialog} />
      )]}
      values={{ freePlanName }}
    />
  );

  const extraFeaturesBlockLink = (
    <ExtraFeature key={`extra-feature-link-${alias}-0`}>
      <BulletContainer size="label-text-medium" as="div">—</BulletContainer>
      <FeatureMainText size="label-text-medium" as="div">
        {freePlanFeatures}
      </FeatureMainText>
    </ExtraFeature>
  );

  const extraFeaturesBlock = planExtraFeatureArray.map((feature, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <ExtraFeature key={`extra-feature-${alias}-${i}`}>
      <BulletContainer size="label-text-medium" as="div">—</BulletContainer>
      <ExtraFeatureText size="label-text-medium" as="div" text={feature} />
    </ExtraFeature>
  ));

  const translatedTitle = t(inPopup ? 'payment.plan' : title);

  const planPromoPeriodPrices = promoPlanPricing ? getPromoPlanPricing(promoPlanPricing, alias, period) : null;
  const planPrice = planPromoPeriodPrices ? planPromoPeriodPrices.price : finalPrice;
  const planDiscount = planPromoPeriodPrices ? planPromoPeriodPrices.discount : discount;

  return (
    <Layout
      inPopup={inPopup}
      data-qa={translatedTitle}
    >
      <Header>
        {!hideTitle && (
          <FlexCenter gap={8}>
            <Title inPopup={inPopup} size="h2">
              {translatedTitle}
            </Title>
            {titleHint && (
              <InfoIconWithTooltip message={t(titleHint)} />
            )}
          </FlexCenter>
        )}
        <PriceContainer>
          {price >= 0 ? (
            <>
              <Price inPopup={inPopup} size={inPopup ? 'h1' : 'h2'}>{planPrice.toLocaleString('ru-RU')}</Price>
              <Currency size="main-text-medium">{`₽${!inPopup ? ` / ${t('common.month')}` : ''}`}</Currency>
            </>
          ) : (
            <Priceless size="main-text-bold" text={t('payment.pricePPM')} />
          )}
        </PriceContainer>
        <Badges>
          { !inPopup && (planDiscount !== 0 && price >= 0) && (
            <Badge variant="red">{`-${planDiscount}%`}</Badge>
          )}
          { planPromoPeriodPrices?.price && (
            <Badge variant="red">{t('payment.promoUpToDate', { date: '31.12.23' })}</Badge>
          )}
          { !inPopup && (
            <Badge>{price >= 0 ? priceHintValue : t('payment.ppmHint')}</Badge>
          )}
          {inPopup && (
            <FigmaTypography size="main-text-medium">
              {`${t('payment.timeless')}`}
            </FigmaTypography>
          )}
        </Badges>
      </Header>
      <FeaturesContainer>
        <MainFeaturesList>
          {planMainFeatureArray.map(([featureTitle, features], i) => (
          // eslint-disable-next-line react/no-array-index-key
            <MainFeature key={`main-feature-${alias}-${i}`}>
              <BulletContainer size="label-text-medium" as="div" isAccented>●</BulletContainer>
              <FeatureMainText size="label-text-medium" as="div">
                {featureTitle}
              </FeatureMainText>
              {(features as [string]).map((featureSecondary, j) => (
                <FeatureSecondaryText
                  as="div"
                  size="label-text-bold"
                  text={featureSecondary}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`main-feature-${alias}-${i}-${j}`}
                />
              ))}
            </MainFeature>
          ))}
        </MainFeaturesList>
        <ExtraFeaturesList>
          <ExtraFeaturesTitle size="label-text-bold" as="div">
            {t(extraFeaturesTitle)}
          </ExtraFeaturesTitle>
          <ExtraFeatureTextsHolder>
            {!inPopup && extraFeaturesBlockLink}
            {extraFeaturesBlock}
          </ExtraFeatureTextsHolder>
        </ExtraFeaturesList>
      </FeaturesContainer>
      {!inPopup && (
        <Footer>
          <IconButton
            id={alias}
            variant="primary"
            fullWidth
            disabled={isPaymentPending}
            isLoading={isPaymentPending}
            onClick={handlePlanSelect}
          >
            {t(buyButtonText)}
          </IconButton>
        </Footer>
      )}
    </Layout>
  );
});

export default PlanCard;
