import React from 'react';
import {
  components,
  CSSObjectWithLabel,
  OptionProps,
} from 'react-select';

import { Color, AppTheme, ZIndex } from 'ui-kit/theme';
import { DownArrowIcon } from 'ui-kit/assets/icons';

import { OptionType, SelectInputCustomStyles, SizeVariant } from './types';
import { Flex } from '../Flex';

export const customStyles = ({
  theme,
  variant,
  inject,
  hasError,
}: {
  theme: AppTheme,
  inject?: Partial<SelectInputCustomStyles> | undefined,
  variant?: SizeVariant | undefined,
  hasError?: boolean,
}) => ({
  control: (_: CSSObjectWithLabel, { menuIsOpen }: { menuIsOpen: boolean }): CSSObjectWithLabel => ({
    background: menuIsOpen ? theme.colors.accent01 : theme.colors.bg,
    border: `1px solid ${menuIsOpen ? theme.colors.accent04 : theme.colors.secondary03}`,
    borderRadius: '10px',
    color: theme.colors.primary,
    alignItems: 'center',
    cursor: 'default',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minHeight: `${variant === 'small' ? 36 : 48}px`,
    outline: '0!important',
    position: 'relative',
    boxSizing: 'border-box',
    padding: 1,
    transition: 'background-color 0.3s, color 0.3s',
    '&:hover, &:focus-within': {
      borderWidth: 2,
      padding: 0,
      '& .select-down-arrow-down-icon': {
        color: theme.colors.secondaryBase,
      },
    },
    '&:hover': {
      borderColor: theme.colors.accent02,
      background: theme.colors.accent01,
    },
    '&:focus-within': {
      borderColor: theme.colors.accent04,
      background: theme.colors.accent01,
    },
    ...inject?.control,
    ...hasError ? {
      borderColor: theme.colors.errorBase,
      color: theme.colors.errorBase,
    } : undefined,
  }),
  placeholder: (style: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...style,
    color: theme.colors.secondary03,
  }),
  menu: (style: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...style,
    zIndex: 1,
    width: '100%',
    position: 'absolute',
    margin: 0,
    background: theme.colors.bg,
    padding: '4px 8px',
    borderRadius: '5px',
    boxShadow:
      '0 0 2px rgba(0, 0, 0, 0.08), 0 8px 24px rgba(0, 0, 0, 0.08)',
    border: `solid 2px ${theme.colors.secondary02}`,
    overflow: 'hidden',
    ...inject?.menu,
  }),
  option: (_: CSSObjectWithLabel, { isDisabled }:{ isDisabled: boolean }): CSSObjectWithLabel => ({
    display: 'block',
    padding: '12px 20px',
    width: '100%',
    userSelect: 'none',
    boxSizing: 'border-box',
    background: 'transparent',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    borderRadius: 5,
    ...(isDisabled && {
      color: Color.GREY4,
    }),
    ...(!isDisabled && {
      '&:hover': {
        background: theme.colors.accent01,
        color: theme.colors.accent05,
      },
    }),
    ...inject?.option,
  }),
  valueContainer: (style: CSSObjectWithLabel) => ({
    ...style,
    '& > span': {
      gridRowStart: 1,
      gridRowEnd: 2,
      gridColumnStart: 1,
      gridColumnEnd: 2,
    },
  }),
  indicatorsContainer: (style: CSSObjectWithLabel) => ({
    ...style,
    padding: 0,
    paddingLeft: 4,
    paddingRight: variant === 'small' ? 8 : 16,
    color: theme.colors.secondary03,
    ...inject?.indicatorsContainer,
  }),
  singleValue: (style: CSSObjectWithLabel): CSSObjectWithLabel => ({
    ...style,
    marginLeft: 6,
    marginTop: 1,
    color: theme.colors.primary,
  }),
  menuPortal: (base:CSSObjectWithLabel) => ({ ...base, zIndex: ZIndex.MAX }),
});

export const Option = (props: OptionProps<OptionType, false>) => {
  const { innerProps } = props;
  const { data } = props as { data: { value: string } };

  if (data.value) {
    (innerProps as { 'data-value': string })['data-value'] = data.value;
  }

  return (
    <components.Option {...props} />
  );
};

export const DropdownIndicator = () => (
  <Flex className="select-down-arrow-down-icon">
    <DownArrowIcon />
  </Flex>
);

export const IndicatorSeparator = () => null;
