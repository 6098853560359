import { Role } from '@livedigital/client/dist/types/common';

import {
  ImmutableProps, RoomType, WaitingRoomAudience,
} from 'types/common';
import { Announcement } from 'types/stores/announcements';
import { RecordPublicationStatus } from 'modules/RoomRecorder/CloudRoomRecorder';
import { Demonstration } from 'modules/Demo/types';
import { CallDataRestrictions } from 'modules/СallLimit/types';

import { JoinSettings } from './joinSettings';
import { RoomRecord } from '../RecordApi';

type SpaceIdParam = {
  spaceId: string;
};

export interface Id {
  id: string;
}

export interface Alias {
  alias: string;
}

export interface SpaceId {
  spaceId: string;
}

interface RoomId {
  roomId: string;
}

type RoomIdAndSpaceId = SpaceId & RoomId;

export interface IUserInfo {
  id: string;
  email: string;
  username: string;
  image: string | null;
  phone?: string;
  carrotQuestToken: string;
  createdAt: string;
  isDebugger?: boolean;
}

export interface SpaceUser extends Omit<IUserInfo, 'carrotQuestToken'> {
  role: SpaceRole;
}

export interface ICreateSpaceParams {
  isPublic?: boolean;
  name: string;
  description?: string;
  logo?: string;
}

export enum SpaceRole {
  Owner = 'role_space_owner',
  Moderator = 'role_space_moderator',
  User = 'role_space_user',
  Guest = 'role_space_guest',
}

export enum RoomRole {
  Owner = 'role_room_owner',
  Moderator = 'role_room_moderator',
  User = 'role_room_user',
  Guest = 'role_room_guest',
  Speaker = 'role_room_speaker',
}

export type CreateParticipantParams = {
  name?: string,
  image: string | null,
  roomId: string,
  clientUniqueId: string,
  role: Role,
} & SpaceIdParam;

export type UpdateParticipantParams = {
  participantId: string,
  name?: string,
  roomId?: string | null,
  image?: string | null,
  isAudioStreamingAllowed?: boolean,
  isVideoStreamingAllowed?: boolean,
} & SpaceIdParam;

export type TransformParams = {
  videoIncline?: number;
  videoFlipH?: boolean;
  videoFlipV?: boolean;
};

export enum TrackLabel {
  Camera = 'camera',
  CameraPreview = 'camera-preview',
  Microphone = 'microphone',
  ScreenVideo = 'screen-video',
  ScreenAudio = 'screen-audio',
  Board = 'board',
  Broadcast = 'broadcast',
  Unknown = 'unknown',
}

export type SlotAssignment = {
  slotUuid: string;
  trackLabel: TrackLabel;
  transformParams?: TransformParams;
  data?: SlotData;
};

export type ParticipantResponse = {
  id: string,
  name: string,
  spaceId: string,
  roomId: string,
  error?: string,
  clientUniqueId: string,
  isAudioStreamingAllowed: boolean,
  isVideoStreamingAllowed: boolean,
  externalUserId?: string,
};

export type RoomParams = {
  id: string,
  alias: string,
  name: string,
  type: RoomType,
  appId?: string,
  channelId?: string,
  background?: string,
  isScreensharingAllowed?: boolean,
  isChatAllowed?: boolean,
  spaceId?: string,
  isPublic?: boolean;
  waitingRoomAudience: WaitingRoomAudience;
};

export type BreakoutRooms = {
  session: {
    status: 'ready' | 'stopped' | 'started',
    startedAt: string | null,
  }
  status: 'enabled' | 'disabled',
};

export type SetRoomParams = {
  id: string,
  alias: string,
  name: string,
  background: string,
  isScreensharingAllowed: boolean,
  isChatAllowed: boolean
  isRecordAllowed: boolean,
  breakoutRooms: BreakoutRooms,
  joinSettings: JoinSettings,
  parentRoomId: string | null,
  isAutoRecordingAllowed: boolean,
  roleInRoom: RoomRole,
  type: RoomType,
};

export type ResponsePayload<T> = {
  data?: T,
  error?: string,
};

// TODO: Change response typings to the following:
// export type ResponseWithData<T> = { data: T };
// export type ResponseWithError = { error: string };
// export type ResponsePayload<T> = ResponseWithData<T> | ResponseWithError;

// export const isRequestFailed = <T>(response: ResponsePayload<T>)
//   : response is ResponseWithError => (response as ResponseWithError).error !== undefined;

export type ApiResponse<T> = Promise<ResponsePayload<T>>;
export type ApiPaginableResponse<T> = Promise<ResponsePayload<PaginableResponse<T>>>;

export interface FinishCallForEveryonePayload {
  spaceId: string,
  roomId: string,
}

export enum PeerRole {
  Host = 'host',
  Audience = 'audience',
}

export interface CreateUserContactParams {
  isEmailSubscriptionEnabled: boolean,
  spaceUrl: string,
}

enum ChatRole {
  User = 'user',
  Moderator = 'moderator',
  Guest = 'guest',
}

export interface UpdateChatUserProfile {
  username?: string;
  avatar?: string;
  lang?: string;
  role?: ChatRole;
}

interface UpdateChatUserProfileRequest extends UpdateChatUserProfile {
  chatToken: string;
  userId?: string;
}

export interface GetOrCreateRoomChatParams {
  chatApplicationId: string;
  spaceId: string;
  roomId: string;
  userId: string;
  clientUniqueId: string;
  username: string;
}

export type GetOrCreateEventChatParams = Id & {
  username: string;
  clientUniqueId: string;
};

export type GetOrCreateEventPreviewChatParams = SpaceId & GetOrCreateEventChatParams;

export enum SlotDataType {
  MiroBoard = 'MiroBoard',
  Broadcast = 'Broadcast',
}

export interface BroadcastSlotData {
  type: SlotDataType.Broadcast,
  id: string,
  embedAlias: string,
  playLink: string,
  streamId: string,
  startedUserId: string,
}

export interface MiroBoardSlotData {
  id: string,
  accessLink: string,
  type: SlotDataType.MiroBoard;
  viewLink: string,
  name: string,
}

type SlotData = MiroBoardSlotData | BroadcastSlotData;

export type UpdateChatUserProfileRequestProtected = ImmutableProps<UpdateChatUserProfileRequest,
'username' | 'avatar' | 'lang'>;

export type Oauth2ClientView = {
  clientId: string | undefined;
};

export type Oauth2ClientResponse = {
  clientId: string,
  clientSecret: string,
  isCaptchaRequired?: boolean,
  isConfidential?: boolean,
};

export type CreatePersonalTokenResponse = {
  id: string;
  token: string;
  label: string | null;
  createdAt: string;
};

export type PersonalTokenResponseItem = {
  id: string;
  label: string | null;
  createdAt: string;
};

export type PersonalTokensListResponse = {
  items: PersonalTokenResponseItem[];
};

export type WebHookView = {
  url: string;
  isActive: boolean,
};

export type WebHookResponse = WebHookView & {
  secret: string,
};

export type WebHookUpdatePayload = WebHookView & {
  spaceId: string,
};

export type WebHookCreatePayload = {
  spaceId: string,
  url: string
};

export interface IssueCreatePayload {
  defendantPeerId: string;
  roomId: string;
  callId: string;
  description?: string;
  issuerName: string;
  issuerParticipantId: string;
  dump: Record<string, unknown>;
}

export interface IssuePushDumpPayload {
  id: string;
  dump: Record<string, unknown>;
}

export type PaginableRequest<T> = T & {
  limit?: number;
  offset?: number;
  order?: 'asc' | 'desc';
  sort?: string;
};

export type PaginableResponse<T> = {
  items: T[];
  total: number;
};

export type SearchByName = {
  name?: string;
};

export type DateableRequest<T> = T & {
  dateFrom?: Date,
  dateTo?: Date,
};

export type StartRecordPayload = RoomIdAndSpaceId;

export type SizeRecordsPayload = {
  spaceId: string,
};

export type StopRecordPayload = RoomIdAndSpaceId;

export type GetCurrentRecordPayload = RoomIdAndSpaceId;

export type DateRange = {
  dateFrom: Date,
  dateTo: Date,
};

export interface SearchByPublicationStatus {
  publicationStatus?: RecordPublicationStatus;
}

export type GetRecordsPayload = RoomIdAndSpaceId & SearchByName & DateRange & SearchByPublicationStatus;

type RecordId = {
  recordId: string,
};

export type CheckRecordSecret = RoomIdAndSpaceId & RecordId & {
  secret: string,
};

export type GetUrlForDownloadingPayload = RoomIdAndSpaceId & RecordId;

export type UpdateRecordPayload = RoomIdAndSpaceId & RecordId & {
  name?: string,
  publicationStatus?: RecordPublicationStatus,
};

export type UpdateRecordResponse = {
  item: RoomRecord,
};

export interface PeerAppDataPayload {
  peerId: string,
  appData: Record<string, unknown>
}

export interface CallData {
  callId: string,
  callStartedAt: Date,
  announcements: Announcement[],
  demonstrations: Demonstration[],
  restrictions: CallDataRestrictions,
}

export interface PostAnnouncementResponse {
  id: string,
}

export interface ReportResponse {
  report: string;
  filename?: string;
}

export interface FloatSlotsInfo {
  disable: boolean;
  mainSlotUuid?: string;
  floatSlotUuid?: string;
  flip: boolean;
}

export type ParticipantSignalingTokenResponse = {
  signalingToken: string;
};

export type RoomRecordCheckSecretResponse = {
  signalingToken: string;
};

export interface RoomRecordPublicationMeta {
  totalViews: number;
  readinessStatusChangedAt?: Date;
}
