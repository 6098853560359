import { EventSessionFrequency } from 'services/MoodHoodApiClient/Playbacks.types';

export enum EventState {
  Past = 'past',
  Now = 'now',
  Future = 'future',
  Continuous = 'continuous',
}

export interface PlaybackEventSessionsListItemProps {
  playbackEventId: string,
  sessionId?: string,
  date?: string,
  time?: string,
  buttons?: React.ReactNode,
  timeZone?: string,
  state?: EventState,
  participantsCount?: number,
  recurrence?: string,
  frequency: EventSessionFrequency
  onDownloadAnalyticsReport(playbackEventId: string, sessionId: string): void
}
