/* eslint-disable max-len */
import React, { forwardRef } from 'react';
import { AsyncPaginate, UseAsyncPaginateParams } from 'react-select-async-paginate';
import { useTheme } from '@emotion/react';
import { GroupBase } from 'react-select';
import Select from 'react-select/dist/declarations/src/Select';

import {
  Option,
  customStyles,
  DropdownIndicator,
  IndicatorSeparator,
} from './common';
import { OptionType, SelectInputProps } from './types';

export type SelectInputAsyncPaginateProps = SelectInputProps & UseAsyncPaginateParams<OptionType, GroupBase<OptionType>, never>;

const SelectInputAsyncPaginate = forwardRef<Select<OptionType, false, GroupBase<OptionType>>, SelectInputAsyncPaginateProps>(({
  variant,
  inject,
  hasError,

  loadOptions,

  ...restProps
}, forwardedRef) => {
  const theme = useTheme();

  return (
    <AsyncPaginate
      selectRef={forwardedRef}
      styles={customStyles({
        theme,
        variant,
        inject,
        hasError,
      })}
      components={{
        Option,
        DropdownIndicator,
        IndicatorSeparator,
      }}
      loadOptions={loadOptions}
      {...restProps}
    />
  );
});

export default SelectInputAsyncPaginate;
