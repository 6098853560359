import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { Redirect, useLocation } from 'react-router-dom';

import { getUrlWithUTM } from 'helpers/utm';
import { FullPageLoader } from 'ui-kit';
import useStores from 'stores/root';

interface UserOnlyProps {
  shouldRedirectToAuth?: boolean
}

const UserOnly: FunctionComponent<UserOnlyProps> = observer(({ children, shouldRedirectToAuth = true }) => {
  const { authStore } = useStores();
  const location = useLocation();

  if (!authStore.isTokenFetched) {
    return (
      <FullPageLoader />
    );
  }

  if (!authStore.isUser) {
    if (!shouldRedirectToAuth) {
      return null;
    }

    const to = encodeURIComponent(location.pathname + location.search);

    return (
      <Redirect push to={getUrlWithUTM(`/auth/signin?to=${to}`)} />
    );
  }

  return (
    <>
      {children}
    </>
  );
});

export default UserOnly;
