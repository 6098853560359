import React, {
  forwardRef, useState, useRef, useEffect,
} from 'react';

import { MenuProps, ItemsSelectType } from './types';
import { makeItemsSelector } from './SingleSelect';
import { MenuLayout } from './Layout';

const Menu = forwardRef<HTMLDivElement, MenuProps>(({
  children, onSelect, select = 'none', ...restProps
}, ref) => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const [itemsSelector, setItemsSelector] = useState<ItemsSelectType>(null);
  const handleClick = (evt: React.MouseEvent) => {
    if (!itemsSelector) {
      return;
    }

    itemsSelector.select(evt.target as HTMLElement);
    onSelect?.(itemsSelector.getSelected());
  };

  const initRef = (el: HTMLDivElement | null) => {
    parentRef.current = el;
    if (!ref) {
      return;
    }

    if (typeof ref === 'function') {
      ref(el);
    } else {
      // eslint-disable-next-line no-param-reassign
      ref.current = el;
    }
  };

  useEffect(() => {
    if (!parentRef.current) {
      return;
    }

    const selector = makeItemsSelector(select, parentRef.current);
    setItemsSelector(selector);
  }, []);

  return (
    <MenuLayout {...restProps} onClick={handleClick} ref={initRef}>
      {children}
    </MenuLayout>
  );
});

export default Menu;
