import React, { FunctionComponent, useCallback } from 'react';

const MiroWrapper: FunctionComponent = () => {
  const accessLink = new URLSearchParams(window.location.search).get('accessLink');

  const handleMiroIframeLoad = useCallback((e: React.SyntheticEvent<HTMLIFrameElement>) => {
    const iframe = e.currentTarget.contentWindow;
    if (!iframe) {
      return;
    }

    const cookieScript = document.createElement('script');
    cookieScript.type = 'text/javascript';
    cookieScript.src = `${window.location.protocol}//${window.location.host}/electron-cookies.js`;
    iframe.document.head.appendChild(cookieScript);
  }, []);

  return (
    <iframe
      title="miro"
      onLoad={handleMiroIframeLoad}
      id="miro-iframe-wrapper"
      width="100%"
      height="100%"
      src={accessLink || ''}
      frameBorder="0"
      scrolling="no"
      allowFullScreen
    />
  );
};

export default MiroWrapper;
