import {
  action, observable, reaction, makeObservable, computed,
} from 'mobx';

import RecordApi from 'services/MoodHoodApiClient/RecordApi';
import CloudRoomRecorder from '../modules/RoomRecorder/CloudRoomRecorder';

export enum RecordState {
  Idle = 'idle',
  InProgress = 'in_progress',
  Stopped = 'stopped',
  Maintenance = 'maintenance',
  Error = 'error',
}

class RecordStore {
  @observable state: RecordState;

  @observable cloudRoomRecorder: CloudRoomRecorder;

  constructor(recordApiClient: RecordApi) {
    this.state = RecordState.Idle;
    this.cloudRoomRecorder = new CloudRoomRecorder(recordApiClient);
    this.watchRecorderStates();
    makeObservable(this);
  }

  @computed get isReady(): boolean {
    return this.cloudRoomRecorder.isReady;
  }

  @computed get isRecording(): boolean {
    return this.state === RecordState.InProgress;
  }

  @computed get startedAt(): number {
    if (this.cloudRoomRecorder.isRecording) {
      return this.cloudRoomRecorder.startedAt ?? Date.now();
    }

    return Date.now();
  }

  @action private setState(state: RecordState) {
    this.state = state;
  }

  private watchRecorderStates(): void {
    reaction(
      () => ({
        cloudRecorderState: this.cloudRoomRecorder.commonState,
      }),
      ({ cloudRecorderState }) => {
        if (!this.cloudRoomRecorder.isReady) {
          this.setState(cloudRecorderState);
        }

        if (this.cloudRoomRecorder.isReady) {
          this.setState(RecordState.Idle);
        }
      },
    );
  }
}

export default RecordStore;
