import React, { FunctionComponent } from 'react';
import ChartistGraph, { ChartitGraphProps } from 'react-chartist';
import './chartist.min.css';

const Chart: FunctionComponent<ChartitGraphProps> = ({
  data, options, type, ...rest
}) => (
  <ChartistGraph className="ct-octave" data={data} options={options} type="Line" {...rest} />
);

export default Chart;
