import { useEffect, RefObject } from 'react';

const useClickOutside = (
  ref: RefObject<HTMLElement> | null,
  callback: () => void,
  disabled = false,
): void => {
  const handleClickOutside = (event: MouseEvent) => {
    if (!ref) {
      return;
    }

    if (ref.current && !ref.current.contains(event.target as Node)) {
      const target = event.target as HTMLElement;
      if (target.closest?.('.popup')) { // ignore clicks in popup, since it lives in portal
        return;
      }

      callback?.();
    }
  };

  useEffect(() => {
    if (!disabled) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      if (!disabled) {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    };
  }, [disabled, callback]);
};

export default useClickOutside;
