import styled from '@emotion/styled';

export const logoDimensions = {
  widthDesktop: 143,
  widthMobile: 128,
  heightDesktop: 48,
  heightMobile: 36,
};

export const LogoLink = styled.div<{ isClickable: boolean }>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  display: flex;
  width: ${logoDimensions.widthDesktop}px;
  height: ${logoDimensions.heightDesktop}px;
  align-items: center;
`;

export const Image = styled.img`
  object-fit: contain;
  max-width: ${logoDimensions.widthDesktop}px;
  max-height: ${logoDimensions.heightDesktop}px;
  margin: auto auto auto auto;
`;

export const LogoPlaceholder = styled.div({
  width: logoDimensions.widthDesktop,
  height: logoDimensions.heightDesktop,
});
