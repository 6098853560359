import styled from '@emotion/styled';
import FigmaTypography from '../FigmaTypography';

export const SelectRoot = styled(FigmaTypography)`
  padding: 0;
  margin: 0;
  transition-duration: 0.3s;
  transition-property: color, background, border, border-color, opacity;
`;

export const Label = styled(FigmaTypography)`
  color: ${({ theme }) => theme.colors.secondaryBase};
  margin-bottom: 4px;
  display: block;
`;

export const ErrorText = styled(FigmaTypography)`
  color: ${({ theme }) => theme.colors.errorBase};
`;
