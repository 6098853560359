import React, { HTMLAttributes } from 'react';
import FigmaTypography, { FigmaTypographyProps } from '../FigmaTypography';

type Props = HTMLAttributes<HTMLElement> & FigmaTypographyProps;

const FigmaTypographyBR: React.VoidFunctionComponent<{ text: string } & Props> = (props) => {
  const {
    text,
    ...restProps
  } = props;

  return (
    <FigmaTypography
      dangerouslySetInnerHTML={{ __html: text.replaceAll('\n', '<br />') }}
      {...restProps}
    />
  );
};

export default FigmaTypographyBR;
