import { useEffect } from 'react';

import useStores from 'stores/root';

const useFeature = ({ title, key } : { title: string, key: string }): boolean => {
  const { featureStore } = useStores();

  useEffect(() => {
    featureStore.addFeature(title, key);
  }, []);

  return featureStore.isFeatureOn(key);
};

export default useFeature;
