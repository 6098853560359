import React, { FunctionComponent, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';
import { usePopperTooltip } from 'react-popper-tooltip';
import { Placement } from '@popperjs/core';
import isMobile from 'is-mobile';

import { Arrow, TooltipContainer } from './TooltipLayout';
import Portal from '../Portal';
import useTooltipsContext from './Provider';

export interface TooltipProps {
  title?: JSX.Element | string;
  component?: JSX.Element;
  isOpen?: boolean;
  placement?: Placement;
  flip?: boolean;
  onClose?(): void;
  onOpen?(): void;
  isInteractive?: boolean;
  backgroundColor?: string;
  className?: string;
  transitionClassNames?: CSSTransitionClassNames;
  showOnMobile?: boolean;
  disabled?: boolean;
  delayHide?: number,
}

const Tooltip: FunctionComponent<TooltipProps> = ({
  title,
  children,
  component,
  placement = 'bottom',
  flip = true,
  isOpen = undefined,
  onClose = undefined,
  onOpen = undefined,
  isInteractive = false,
  backgroundColor,
  transitionClassNames = {},
  showOnMobile = false,
  disabled = false,
  className,
  delayHide = 0,
  ...restProps
}) => {
  const finalCSSTransitionCLassNames = {
    enter: 'animate__animated animate__faster',
    enterActive: 'animate__faster',
    exit: 'animate__animated animate__faster',
    exitActive: 'animate__faster',
    ...transitionClassNames,
  };

  const { tooltipsSettings } = useTooltipsContext();

  const show = showOnMobile || !isMobile();

  const onVisibleChange = (isVisible: boolean) => {
    if (isVisible) {
      onOpen?.();
    } else {
      onClose?.();
    }
  };

  const modifiers: { name: string, enabled?: boolean, options: Record<string, unknown> }[] = [];

  modifiers.push({
    name: 'offset',
    enabled: true,
    options: {
      offset: [0, 10],
    },
  });

  if (!flip) {
    modifiers.push({
      name: 'flip',
      options: {
        fallbackPlacements: [],
      },
    });
  }

  const {
    setTooltipRef,
    setTriggerRef,
    getArrowProps,
    getTooltipProps,
    visible,
    forceUpdate,
    triggerRef,
  } = usePopperTooltip({
    placement,
    visible: isOpen,
    onVisibleChange,
    interactive: isInteractive,
    delayShow: 500,
    closeOnTriggerHidden: true,
    delayHide,
  }, {
    modifiers,
    strategy: 'fixed',
  });

  const triggerRefReact = JSON.stringify(triggerRef?.getBoundingClientRect());

  useEffect(() => {
    if (triggerRef) {
      forceUpdate?.();
    }
  }, [triggerRefReact]);

  if (!title) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <>
      {component ? React.cloneElement(component, { ref: setTriggerRef, className, ...restProps }) : (
        <div ref={setTriggerRef} className={className} {...restProps}>
          {children}
        </div>
      )}
      {(show && !disabled && tooltipsSettings.tooltipsEnabled) && (
        <Portal>
          <CSSTransition
            in={visible && !disabled}
            mountOnEnter
            unmountOnExit
            timeout={0}
            classNames={finalCSSTransitionCLassNames}
          >
            <TooltipContainer
              ref={setTooltipRef}
              backgroundColor={backgroundColor}
              className={className}
              {...getTooltipProps()}
            >
              {title}
              <Arrow {...getArrowProps()} />
            </TooltipContainer>
          </CSSTransition>
        </Portal>
      )}
    </>
  );
};

export default Tooltip;
