import styled from '@emotion/styled';
import { IconButton } from 'ui-kit';

export const RequestStorageAccessHolder = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  position: 'absolute',
  inset: 0,
});

export const RequestStorageAccessContent = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '100px',
});

export const Text = styled.div({
  fontSize: '22px',
  lineHeight: '27px',
  marginBottom: '12px',
  textAlign: 'center',
});

export const RequestStorageAccessButton = styled(IconButton)({
  width: 'auto',
  padding: '0 24px',
  fontSize: '16px',
});
