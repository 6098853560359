import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';

import { Loader } from 'ui-kit';

import { ListContainerRoot } from './Layout';

interface ListContainerProps {
  isLoading?: boolean;
}

const ListContainer: FunctionComponent<ListContainerProps> = observer(({ isLoading, children }) => {
  if (isLoading) {
    return <Loader centered size={48} />;
  }

  return (
    <ListContainerRoot>
      {children}
    </ListContainerRoot>
  );
});

export default ListContainer;
