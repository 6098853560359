import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import isMobile from 'is-mobile';

import { checkOSRequirements } from 'helpers/browser';
import { getLocalStorageItem, setLocalStorageItem } from 'helpers/localStorage';

import {
  Message, Hint, ContinueButton, DialogFromBottom,
} from './Layout';

const LOCAL_STORAGE_KEY = 'UnsupportedOSWarningShown';

const UnsupportedOS: FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const [isDialogShown, setIsDialogShown] = useState(Boolean(getLocalStorageItem(LOCAL_STORAGE_KEY)));

  const { isUnSupportedOS, requirements } = checkOSRequirements();

  const handleContinueClick = () => {
    setLocalStorageItem(LOCAL_STORAGE_KEY, '1');
    setIsDialogShown(true);
  };

  if (!isMobile()) {
    return null;
  }

  const isVisible = !isDialogShown && isUnSupportedOS;

  return (
    <DialogFromBottom isVisible={isVisible} canClose={false}>
      <Message>
        {t('warnings.unsupportedOS.msg', { os: requirements?.os, minVersion: requirements?.minVersion })}
      </Message>
      <Hint>{t('warnings.unsupportedOS.hint')}</Hint>
      <ContinueButton id="continue-anyway" onClick={handleContinueClick}>
        {t('warnings.unsupportedOS.buttonText')}
      </ContinueButton>
    </DialogFromBottom>
  );
});

export default UnsupportedOS;
