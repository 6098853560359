import styled from '@emotion/styled';

const Layout = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden',
  alignItems: 'center',
  gap: 4,
  height: 24,
  width: 'max-content',
  borderRadius: 10,
  padding: '4px 8px',
  paddingRight: '4px',
  backgroundColor: theme.colors.accent04,
  color: theme.colors.contrast,
}));

export default Layout;
