import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { CustomComponentProps } from 'react-multi-date-picker';

import { FigmaTypography, IconButton } from 'ui-kit';
import { CloseMiniIcon } from 'ui-kit/assets/icons';

import Layout from './DatePickerButton.styled';

interface DatePickerButtonProps extends CustomComponentProps {
  onClose(): void,
}

const DatePicker: FunctionComponent<DatePickerButtonProps> = observer(({ value, onClose, openCalendar }) => {
  const handleClick = () => {
    openCalendar?.();
  };

  return (
    <Layout onClick={handleClick}>
      <FigmaTypography size="label-text-medium">{value}</FigmaTypography>
      <IconButton
        id="date-picker-close"
        icon={<CloseMiniIcon />}
        subVariant="clear"
        size="tiny"
        onClick={onClose}
      />
    </Layout>
  );
});

export default DatePicker;
