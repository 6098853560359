import {
  action, computed, makeAutoObservable, observable, when,
} from 'mobx';

import DeviceStore from 'stores/device';

type Silence = 'init' | 'silence' | 'noise';

class SpeechRecognize {
  deviceStore: DeviceStore;

  watchSpeechDisposer: () => void = () => {};

  watchSilenceTimerTd: NodeJS.Timeout | undefined;

  @observable isSpeech = false;

  @observable isTotalSilence: Silence = 'init';

  @observable speechScores = 0;

  @observable silenceScores = 0;

  @observable isWatchingTotalSilence = false;

  // for tuning
  @observable minScoresForSpeechWatching = 15;

  @observable minScoresForSpeech = 50;

  @observable minAudioLevelForSpeech = 6;

  @observable maxAudioLevelForSilence = 2;

  @observable minSilenceScoresForSilence = 100;

  @observable checkingSpeechPeriodInSec = 5;

  @observable checkingSilencePeriodInSec = 5;

  @observable delayBeforeNextCheckingSpeechSec = 15;

  constructor(deviceStore: DeviceStore) {
    this.deviceStore = deviceStore;
    makeAutoObservable(this);
  }

  @computed get needWatchingSpeech() {
    return this.speechScores > this.minScoresForSpeechWatching;
  }

  @action setIsSpeech() {
    this.isSpeech = true;
    this.disposeIsSpeech();
  }

  @action resetIsSpeech() {
    this.isSpeech = false;
  }

  @action watchIsSpeech() {
    this.watchSpeechDisposer();
    this.watchSpeechDisposer = when(() => this.speechScores > this.minScoresForSpeech,
      () => this.setIsSpeech(),
      {
        timeout: this.checkingSpeechPeriodInSec * 1000,
        onError: () => this.watchSpeechDisposer(),
      });
  }

  disposeIsSpeech() {
    setTimeout(() => {
      this.resetIsSpeech();
      this.resetSpeechScores();
      this.resetSilenceScores();
    }, this.delayBeforeNextCheckingSpeechSec * 1000);
  }

  @action resetSpeechScores() {
    this.speechScores = 0;
  }

  @action resetSilenceScores() {
    this.silenceScores = 0;
  }

  @action setSpeechScores(level: number) {
    if (this.isSpeech) {
      return;
    }

    if (this.deviceStore.microphoneAudioLevel !== level && level >= this.minAudioLevelForSpeech) {
      this.speechScores += 1;
    }
  }

  @action setSilenceScores(level: number) {
    if (this.silenceScores > this.minSilenceScoresForSilence) {
      return;
    }

    if (level <= this.maxAudioLevelForSilence) {
      this.silenceScores += 1;
    }
  }

  @computed processAudioLevelScores(level: number) {
    this.setSpeechScores(level);
    this.setSilenceScores(level);

    if (level > 0 && this.silenceScores > 0) {
      this.resetSilenceScores();
    }

    if (this.silenceScores > this.minSilenceScoresForSilence) {
      this.resetSpeechScores();
    }

    if (this.isWatchingTotalSilence) {
      if (level > 0) {
        this.setIsTotalSilence('noise');
      }
    }
  }

  @action setIsWatchingTotalSilence(value: boolean) {
    this.isWatchingTotalSilence = value;
  }

  @action setIsTotalSilence(valule: Silence) {
    this.isTotalSilence = valule;
  }

  @action async watchIsTotalSilence() {
    this.setIsTotalSilence('init');
    this.setIsWatchingTotalSilence(true);
    await this.deviceStore.watchMicAudioLevel();
    this.watchSilenceTimerTd = setTimeout(() => {
      if (this.isTotalSilence === 'init') {
        this.setIsTotalSilence('silence');
      }
    }, this.checkingSilencePeriodInSec * 1000);
  }

  @action unWatchIsTotalSilence() {
    if (this.watchSilenceTimerTd) {
      clearTimeout(this.watchSilenceTimerTd);
    }

    this.setIsTotalSilence('init');
    this.setIsWatchingTotalSilence(false);
    this.deviceStore.unWatchMicAudioLevel();
  }

  // for tuning

  @action setLooksLikeSpeechTriggerLevel(level: number) {
    this.minScoresForSpeechWatching = level;
  }

  @action setIsSpeechScoresTriggerLevel(level: number) {
    this.minScoresForSpeech = level;
  }

  @action setSoundLevelForSpeech(level: number) {
    this.minAudioLevelForSpeech = level;
  }

  @action setIsSilenceScoresTriggerLevel(level: number) {
    this.minSilenceScoresForSilence = level;
  }

  @action setSoundLevelForSilence(level: number) {
    this.maxAudioLevelForSilence = level;
  }

  @action setCheckingSpeechPeriodInSec(period: number) {
    this.checkingSpeechPeriodInSec = period;
  }

  @action setDelayBeforeCheckingSpeechSec(delay: number) {
    this.delayBeforeNextCheckingSpeechSec = delay;
  }
}

export default SpeechRecognize;
