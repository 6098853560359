import React, { VoidFunctionComponent } from 'react';
import {
  toast,
  ToastContainerProps,
  ToastContent,
  ToastOptions,
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import isMobile from 'is-mobile';
import { observer } from 'mobx-react-lite';

import { ToastContainers } from 'constants/common';
import useStores from 'stores/root';

import {
  StyledToastContainerMobile,
  StyledToastContainerDesktop,
} from './Layout';

const containerId = ToastContainers.Help;

const ToastHelpContainer: VoidFunctionComponent<Omit<ToastContainerProps, 'theme'>> = observer((props) => {
  const { uiStore } = useStores();

  return (
    isMobile() ? (
      <StyledToastContainerMobile
        enableMultiContainer
        containerId={containerId}
        position="bottom-center"
        icon={false}
        hideProgressBar
        limit={1}
        {...props}
      />
    ) : (
      <StyledToastContainerDesktop
        enableMultiContainer
        containerId={containerId}
        position="bottom-center"
        icon={false}
        hidden={uiStore.isDesktopAppSharingActive}
        hideProgressBar
        limit={1}
        closeButton={false}
        {...props}
      />
    )
  );
});

// TODO: почему error?
const toastHelp = (content: ToastContent, options?:ToastOptions): React.ReactText => toast.error(
  content,
  {
    containerId: ToastContainers.Help,
    closeOnClick: true,
    closeButton: true,
    ...options,
  },
);

export {
  ToastHelpContainer,
  toastHelp,
};
