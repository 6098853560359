import styled from '@emotion/styled';

import { MediaBreakPoint } from 'ui-kit/theme';
import { DropDownButton as DropDownButtonBase, IconButton as IconButtonBase } from 'ui-kit';

export const ToolbarIconButton = styled(IconButtonBase)({});

ToolbarIconButton.defaultProps = {
  autoSmallBreakPoint: MediaBreakPoint.COMMON_PANELS_VIEW_IN_ROOM_BP,
};

export const ToolbarDropDownButton = styled(DropDownButtonBase)({});

ToolbarDropDownButton.defaultProps = {
  autoSmallBreakPoint: MediaBreakPoint.COMMON_PANELS_VIEW_IN_ROOM_BP,
};
