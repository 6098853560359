import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

import useStores from 'stores/root';
import { Room } from 'services/MoodHoodApiClient/types/room';
import { isRoomRecordsPage } from 'helpers/url';
import CloudRecords from 'modules/CloudRecords';

import logger from 'helpers/logger';
import { IUrlParams } from '../types';

const useRoomRecordsFromUrl = () => {
  const { roomAlias: roomAliasFromUrl } = useParams<IUrlParams>();
  const { rootStore: { moodHoodApiClient }, roomStore, spaceStore } = useStores();
  const [roomInfo, setRoomInfo] = useState<Room>();

  useEffect(() => {
    const getRoomByAlias = async (alias: string) => {
      try {
        const room = await moodHoodApiClient.room.getRoomByAlias(alias);
        setRoomInfo(room);
      } catch (error) {
        logger.error('Room Records: failed to fetch room info', { error, roomAlias: alias });
        roomStore.handleRoomFetchError(error as AxiosError);
      }
    };

    if (roomAliasFromUrl && isRoomRecordsPage()) {
      getRoomByAlias(roomAliasFromUrl);
    }
  }, []);

  useEffect(() => {
    if (spaceStore.space && roomInfo && roomAliasFromUrl) {
      CloudRecords.getInstance().setRoomToRecordDownload(roomInfo);
    }
  }, [spaceStore.space, roomAliasFromUrl, roomInfo]);

  return roomInfo?.spaceId;
};

export default useRoomRecordsFromUrl;
