import React, { VoidFunctionComponent, useMemo } from 'react';

import { ZIndex } from 'ui-kit/theme';
import { FigmaTypography } from 'ui-kit';

const getVersionString = (appVersionString?: string) => {
  if (!appVersionString) {
    return window.location.host;
  }

  const sliceAt = appVersionString.lastIndexOf('-');

  if (sliceAt === -1) {
    return [appVersionString, window.location.host].filter(Boolean).join('\n');
  }

  const version = appVersionString.slice(0, sliceAt);

  const commit = appVersionString.slice(sliceAt + 1, appVersionString.length);

  return [
    version,
    commit,
  ].filter(Boolean).join('\n');
};

const AppVersion: VoidFunctionComponent = () => {
  const version = useMemo(() => getVersionString(window.lsd.version), []);

  if (window?.lsdProductionBuild) {
    return null;
  }

  return (
    <FigmaTypography
      as="pre"
      color="red"
      id="app-version"
      style={{
        position: 'fixed',
        top: 8,
        left: '50%',
        zIndex: ZIndex.MAX + 1,
        textAlign: 'center',
        transform: 'translate(-50%, 0)',
        pointerEvents: 'none',
      }}
    >
      {version}
    </FigmaTypography>
  );
};

export default AppVersion;
