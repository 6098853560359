import React, {
  FunctionComponent, PropsWithChildren, HTMLAttributes,
} from 'react';
import { observer } from 'mobx-react-lite';
import useStores from 'stores/root';

import BasicHeader from 'components/BasicHeader';
import { Loader } from 'ui-kit';

import {
  PageLayoutRoot,
  Container,
} from './index.styled';

interface PageLayoutProps extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  spaceId?: string,
  className?: string,
  noHeader?: boolean;
}

const PageLayout: FunctionComponent<PageLayoutProps> = observer(({
  children,
  spaceId,
  className,
  noHeader,
}) => {
  const { spaceStore: { space } } = useStores();
  const showContent = !spaceId || space?.id;

  return (
    <PageLayoutRoot>
      {!noHeader && (
        <BasicHeader outerSpaceId={spaceId} />
      )}
      {showContent ? (
        <Container className={className} noHeader={noHeader}>
          {children}
        </Container>
      ) : (
        <Loader centered size={60} />
      )}
    </PageLayoutRoot>
  );
});

export default PageLayout;
