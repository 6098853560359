import { UrlParam } from 'types/common';

import { getUrlWithUTM } from './utm';

export const getMoodhoodApiUrl = (): string => (
  process.env.REACT_APP_MOODHOOD_API_BASEURL || ''
);

export const getMoodhoodAnalytyicsApiUrl = (): string => (
  process.env.REACT_APP_MOODHOOD_ANALYTICS_API_BASEURL || 'https://moodhood-analytics.livedigital.space/v1');

export const getLoadBalancerUrl = (): string | undefined => (
  process.env.REACT_APP_LIVEDIGITAL_LOADBALANCER_URL
);

export const getBrandDomain = (): string => process.env.REACT_APP_BRAND_DOMAIN as string;

export const isBrandLivedigital = (): boolean => getBrandDomain() === 'livedigital.space';

export const isBrandMoodhood = (): boolean => getBrandDomain() === 'moodhood.online';

export const isBrandTeleBoss = (): boolean => getBrandDomain() === 'teleboss.ru';

export const getBrandName = (): string => process.env.REACT_APP_BRAND as string;

export const isStaging = (): boolean => Boolean(process.env.REACT_APP_IS_STAGING);

export const makeRedirectRoomLink = (toRoomAlias: string, fromRoomAlias?: string) => {
  const fromRoom = fromRoomAlias ? `?fromRoom=${fromRoomAlias}` : '';
  return getUrlWithUTM(`/room/${toRoomAlias}${fromRoom}`);
};

export const redirectTo = (url: string, keepSearchParams = true, forceUTM = false) => {
  if (!url) {
    throw new Error(`Invalid url to redirect: ${url}`);
  }

  const isRelativeUrl = url[0] === '/';

  if (!isRelativeUrl && !forceUTM) {
    window.location.href = url;
    return;
  }

  const urlObj = new URL(url, window.location.href);

  const urlWithUTM = getUrlWithUTM(urlObj.href, keepSearchParams, forceUTM);
  if (!urlWithUTM) {
    throw new Error(`Failed to pass UTM params: ${url}, ${window.location.href}`);
  }

  window.location.href = urlWithUTM;
};

export const getUrlParam = (param: UrlParam) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const hasUrlParam = (param: UrlParam) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has(param);
};

export const isRoomRecord = () => window.location.href.toLocaleLowerCase().includes('/record/');
export const isRoomRecordsPage = () => window.location.href.toLocaleLowerCase().match(/room\/\w+\/records/);
export const isPlansPage = () => window.location.href.toLocaleLowerCase().match(/space\/\w+\/plans/);
export const isPromoPlansPage = () => window.location.href.toLocaleLowerCase().includes('/promo');
export const isMobileAppProfile = () => window.location.href.toLocaleLowerCase().match(/space\/\w+\/mobile-profile/);

export const getMobileProtocol = () => {
  if (isBrandLivedigital()) {
    if (isStaging()) {
      return 'livedigital-stage:';
    }

    return 'livedigital:';
  }

  if (isBrandTeleBoss()) {
    if (isStaging()) {
      return 'teleboss-stage:';
    }

    return 'teleboss:';
  }

  return undefined;
};

export const getMobileAppLink = () => {
  const url = new URL(window.location.href);

  const protocol = getMobileProtocol();

  if (protocol) {
    return url.href.replace(url.protocol, protocol);
  }

  return url.href;
};

export const isAuthPathName = () => window.location.pathname.startsWith('/auth');

export const isValidRedirectUrl = (to: string | null | undefined) => to && !to.startsWith('/auth');
