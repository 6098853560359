import logger from 'helpers/logger';
import { Sounds } from 'constants/audio';

import { MediaElementsPoolClass, PoolElement } from '.';

class AudioElementsPool extends MediaElementsPoolClass<HTMLAudioElement> {
  constructor(maxSize = Infinity, initialSize = 0) {
    super(maxSize, initialSize);
  }

  async whiteListAudio() {
    /* should be called in user action context to give permissions to play audio */
    const poolEl = this.getAvailableElement() ?? this.addElement();
    const el = poolEl.domElement;
    if (!el) {
      logger.warn('AudioElementsPool: no available element', { case: 'whiteListAudio' });
      return;
    }

    el.id = 'whitelist';
    const srcObj = el.srcObject;
    el.src = '';
    el.srcObject = null;
    el.src = Sounds.Silence;
    await el?.play();
    el.srcObject = srcObj;
  }

  protected resetElement(poolElement: PoolElement<HTMLAudioElement>) {
    const el = poolElement;
    if (!el.mediaStream) {
      return;
    }

    el.refCount = 0;
    if (el.domElement) {
      el.domElement.pause();
      el.domElement.muted = false;
      el.domElement.volume = 1;
      el.domElement.defaultMuted = false;
    }

    el.trackId = null;
    const tracks = el.mediaStream.getTracks();

    tracks.forEach((track) => {
      el.mediaStream?.removeTrack(track);
    });
    /* Delibiratly leaving dom element and MediaStream untouched to keep MediaPlayer alive to reuse it later */
  }

  protected addElement(): PoolElement<HTMLAudioElement> {
    if (this.pool.length >= this.maxSize) {
      logger.error('Audio pool error: max elements reached');
      throw new Error('Audio pool error: max elements reached');
    }

    const el = document.createElement('audio');
    el.className = 'peer-view-audio';
    el.setAttribute('autoplay', '1');
    el.autoplay = true;
    el.muted = false;
    el.volume = 1;
    el.defaultMuted = false;

    const mediaStream = new MediaStream();
    el.srcObject = mediaStream;

    const poolElement = {
      id: this.pool.length + 1,
      domElement: el,
      refCount: 0,
      trackId: null,
      mediaStream,
    };

    this.pool.push(poolElement);

    return poolElement;
  }
}

export default new AudioElementsPool();
