import { AxiosError, AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';

import { getAxiosErrorMessage } from 'helpers/errors';

import {
  CreateParticipantParams,
  UpdateParticipantParams,
  ParticipantResponse,
  PeerAppDataPayload,
  ParticipantSignalingTokenResponse,
} from '../types';
import logger from '../../../helpers/logger';
import {
  createLogOnRetryDataFunc,
  isNetworkRelatedError,
  isOneOfHttpMethods,
} from '../../../helpers/apiClient';

const RETRY_DELAY_IN_MS = 1000;

class ParticipantsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
    axiosRetry(api, {
      retries: 10,
      retryDelay: (retryCount) => retryCount * RETRY_DELAY_IN_MS,
      shouldResetTimeout: true,
      onRetry: createLogOnRetryDataFunc({ apiName: 'participants', logger }),
      retryCondition: (error) => this.checkShouldRetryRequest(error),
    });
  }

  checkShouldRetryRequest = (error: AxiosError): boolean => {
    const { config: { url, method }, response } = error;

    if ((response?.status && response.status < 500) || !/^\/?spaces\/.{24}\/participants\/?/.test(url ?? '')) {
      return false;
    }

    if (this.isUseSlotRequestError(error)) {
      return true;
    }

    if (isOneOfHttpMethods(method, 'get')) {
      return true;
    }

    return isNetworkRelatedError(error);
  };

  isUseSlotRequestError = (error: AxiosError): boolean => error.config.method === 'post'
    && !!error.config.url?.includes('/slot');

  async create(payload: CreateParticipantParams): Promise<ParticipantResponse> {
    const {
      spaceId,
      name = '',
      roomId,
      image,
      clientUniqueId,
      role,
    } = payload;

    const { data } = await this.api.post<ParticipantResponse>(`spaces/${spaceId}/participants`, {
      name,
      roomId,
      image,
      clientUniqueId,
      role,
    });

    return data;
  }

  async update(payload: UpdateParticipantParams): Promise<ParticipantResponse> {
    const {
      spaceId,
      participantId,
      name,
      roomId,
      image,
      isAudioStreamingAllowed,
      isVideoStreamingAllowed,
    } = payload;

    const { data } = await this.api.put<ParticipantResponse>(`spaces/${spaceId}/participants/${participantId}`, {
      name,
      roomId,
      image,
      isAudioStreamingAllowed,
      isVideoStreamingAllowed,
    });

    return data;
  }

  async updatePeerAppData(
    spaceId: string,
    participantId: string,
    payload: PeerAppDataPayload,
  ): Promise<{ error?: string }> {
    try {
      await this.api.put(
        `/spaces/${spaceId}/participants/${participantId}/app-data`, payload,
      );
      return {};
    } catch (err: unknown) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async createSignalingToken(spaceId: string, participantId: string): Promise<string> {
    const { data } = await this.api.post<ParticipantSignalingTokenResponse>(
      `/spaces/${spaceId}/participants/${participantId}/signaling-token`,
    );

    return data.signalingToken;
  }
}

export default ParticipantsApi;
