import React, { FunctionComponent } from 'react';
import { useTheme } from '@emotion/react';

import Layout from './Layout';

type BadgeVariants =
  'primary' |
  'secondary' |
  'accent' |
  'red' |
  'yellow' |
  'green';

interface BadgeProps {
  variant?: BadgeVariants;
}

const Badge: FunctionComponent<BadgeProps> = ({
  variant = 'primary',
  children,
  ...props
}) => {
  const {
    colors,
  } = useTheme();

  const Colors = {
    primary: { color: colors.accent05, backgroundColor: colors.accent01 },
    secondary: { color: colors.secondaryBase, backgroundColor: colors.secondary02 },
    accent: { color: colors.contrast, backgroundColor: colors.accentBase },
    red: { color: colors.contrast, backgroundColor: colors.error02 },
    yellow: { color: colors.contrast, backgroundColor: colors.warningBase },
    green: { color: colors.contrast, backgroundColor: colors.successBase },
  };

  return (
    <Layout
      color={Colors[variant].color}
      backgroundColor={Colors[variant].backgroundColor}
      as="div"
      size="label-text-bold"
      data-variant={variant}
      {...props}
    >
      {children}
    </Layout>
  );
};

export default Badge;
