import { useEffect } from 'react';
import { when } from 'mobx';
import { useLocation } from 'react-router-dom';

import useStores from 'stores/root';
import { SpaceRole } from 'services/MoodHoodApiClient/types';

const useCarrotQuest = (): void => {
  const {
    userStore: { userInfo },
    userStore,
    paymentStore: { currentPlan },
    participantStore,
    rootStore: { carrotQuest },
  } = useStores();

  const location = useLocation();

  useEffect(() => {
    const { pathname, search } = location;
    carrotQuest.trackPageView(`${pathname}${search}`);
  }, [location]);

  useEffect(() => {
    if (userInfo && currentPlan) {
      carrotQuest.updateUserInfo();
    }
  }, [userInfo, currentPlan]);

  useEffect(() => {
    if (participantStore.isModerator) {
      carrotQuest.updateUserSpaceRole(SpaceRole.Moderator);
    }
  }, [participantStore.isModerator]);

  useEffect(() => when(
    () => userStore.userInfo !== null,
    () => {
      carrotQuest.trackUserSignIn();
    },
  ), []);

  useEffect(() => () => { carrotQuest.dispose(); }, []);
};

export default useCarrotQuest;
