import React, {
  FunctionComponent, useEffect, useRef,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import PageLayout from 'components/PageLayout';
import Plans from 'modules/Plans';
import useStores from 'stores/root';
import CongratsDialog from 'components/Payment/CongratsDialog';
import PlansList from 'components/PlansPage/components/PlansList';
import { getBrandOptions } from 'modules/Branding';
import spaces from 'modules/Spaces';

import { promoPlanPricing } from './const';

const PromoPlansPage: FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const { paymentStore, spaceStore } = useStores();
  const plans = useRef<Plans>();

  useEffect(() => {
    const init = async () => {
      await spaces.fetchSpaces();
      const space = spaces.findFirstSpaceWithOwnerRole();
      if (space) {
        spaceStore.setSpaceInfo({ space });
      }
    };

    init();
  }, []);

  useEffect(() => {
    if (spaceStore.space) {
      plans.current = new Plans({ space: spaceStore.space, payments: paymentStore, withPromoPlans: true });
      plans.current.load();
    }
  }, [spaceStore.space?.id]);

  const { planDescriptions, freePlanAlias } = getBrandOptions();

  return (
    <PageLayout spaceId={spaceStore.space?.id}>
      <PlansList
        plans={plans.current?.plansInfo || []}
        currentPlanName={plans.current?.currentPlan?.name || planDescriptions[freePlanAlias].name}
        periodDiscounts={{
          month: t('payment.promoUpTo', { discount: -50 }),
          year: t('payment.promoUpTo', { discount: -60 }),
        }}
        promoPlanPricing={promoPlanPricing}
      />
      {paymentStore.isPaymentCongratsDialogOpen && (
        <CongratsDialog />
      )}
    </PageLayout>
  );
});

export default PromoPlansPage;
