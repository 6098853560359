import React, { FunctionComponent } from 'react';
import isMobile from 'is-mobile';
import { CSSTransition } from 'react-transition-group';

const CSSTransitionPropsRight = {
  mountOnEnter: true,
  unmountOnExit: true,
  timeout: 500,
  classNames: {
    enter: 'animate__animated animate__faster',
    exit: 'animate__animated animate__faster',
    enterActive: 'animate__fadeInRight',
    exitActive: 'animate__fadeOutLeft',
  },
};

interface WithCSSTransitionProps {
  inValue: boolean;
}

const WithCSSTransition: FunctionComponent<WithCSSTransitionProps> = ({
  inValue,
  children,
}) => {
  if (isMobile()) {
    return (
      <CSSTransition in={inValue} {...CSSTransitionPropsRight}>
        {children}
      </CSSTransition>
    );
  }

  if (inValue) {
    return (
      <>
        {children}
      </>
    );
  }

  return null;
};

export default WithCSSTransition;
