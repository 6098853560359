import styled from '@emotion/styled';

import { Color } from 'ui-kit/theme';

import { IInputProps, IVariant } from './type';
import FigmaTypography from '../FigmaTypography';

export const InputLayout = styled.div<IVariant & { fullWidth?: boolean, hasError: boolean }>`
  color: ${({ theme, hasError }) => (hasError ? theme.colors.errorBase : theme.colors.primary)};
  margin: 0;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }

  ${({ fullWidth, variant }) => {
    if (fullWidth) {
      return `
        width: 100%;
      `;
    }

    if (variant === 'large') {
      return `
        width: 344px;
      `;
    }

    if (variant === 'medium') {
      return `
        width: 240px;
      `;
    }

    if (variant === 'small') {
      return `
        margin-bottom: 0;
      `;
    }

    return '';
  }}
`;

export const Label = styled.label`
  margin-bottom: 4px;
  display: block;

  font-family: PT Root UI Bold;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.secondaryBase};
`;

export const Input = styled('input')<IInputProps>`
  &, &::placeholder {
    font-family: PT Root UI Medium;
    font-size: 16px;
    line-height: 20px;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary03};
  }

  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  outline: none;

  transition-duration: 0.3s;
  transition-property: background, color, opacity;

  font: inherit;
  line-height: inherit;
  border: none;
  box-sizing: border-box;
  width: 100%;

  &:disabled {
    color: ${({ theme }) => theme.colors.secondary03};
  }

  ${({ theme, variant, heightSize }) => {
    if (heightSize === 'medium') {
      return `
          height: 36px;
        `;
    }

    if (variant === 'large') {
      return `
        height: 48px;
      `;
    }

    if (variant === 'medium') {
      return `
        height: 48px;
      `;
    }

    if (variant === 'small') {
      return `
        border: none;
        padding: 0;
        height: 20px;
        color: ${theme.colors.primary};
      `;
    }

    return '';
  }}
`;

export const InputContainer = styled(FigmaTypography)<{ disabled: boolean, hasError: boolean } & IVariant>`
  background-color: ${({ theme }) => theme.colors.bg};

  --box-shadow-color: ${({ theme, hasError }) => (hasError ? theme.colors.errorBase : undefined)};

  transition-duration: 0.3s;
  transition-property: box-shadow, background, color, opacity;

  display: flex;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;

  box-shadow: var(--box-shadow-color, ${({ theme }) => theme.colors.secondary03}) 0px 0px 0px 1px;

  ${({ theme, disabled }) => (!disabled && `
    &:hover {
      box-shadow: var(--box-shadow-color, ${theme.colors.secondary03}) 0px 0px 0px 2px;
    }

    &:focus-within {
      box-shadow: var(--box-shadow-color, ${theme.colors.accent04}) 0px 0px 0px 2px;
    }
  `)};

  ${({ theme, disabled }) => {
    if (disabled) {
      return `
        color: ${theme.colors.secondary03};
        background-color: ${theme.colors.secondary01};
      `;
    }

    return '';
  }}

  ${({ theme, variant }) => {
    if (variant === 'small') {
      return `
        margin-bottom: 0;
        padding: 8px 8px 8px 10px;
        height: 36px;
        gap: 6px;
        color: ${theme.colors.secondary03};
      `;
    }

    return '';
  }}
`;

InputContainer.defaultProps = {
  size: 'main-text-medium',
  as: 'div',
};

export const Required = styled.span({
  color: Color.RED0,
});
