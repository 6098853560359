import styled from '@emotion/styled';

export const BreabcrumbsRoot = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 4,
});

export const Separator = styled.span(({ theme }) => ({
  color: theme.colors.secondary03,
}));
