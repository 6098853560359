import React from 'react';
import {
  toast, ToastContent, ToastOptions, TypeOptions,
} from 'react-toastify';
import { cx } from '@emotion/css';

import {
  FlexCenter,
  IconButton,
} from 'ui-kit';

import { SnackbarOptions } from './types';
import { withWidthClassName, Content } from './Layout';

export const getSnackbarByType = (
  snackbarType: TypeOptions,
  commonParams: ToastOptions,
) => (
  content: ToastContent,
  options: SnackbarOptions = {},
): React.ReactText => {
  const {
    style,
    withWidth,
    bodyClassName,
    buttonProps,
    ...restOptions
  } = options;
  const width = withWidth?.isCompact ? '100%' : withWidth?.width;

  let toastContent = content;

  if (buttonProps) {
    toastContent = (
      <Content>
        <FlexCenter>
          {content}
        </FlexCenter>
        <IconButton {...buttonProps} />
      </Content>
    );
  }

  return toast(
    toastContent,
    {
      type: snackbarType,
      bodyClassName: cx({ [withWidthClassName]: Boolean(width) }),
      style: {
        ...width && ({ width }),
        ...style && style,
      },
      ...commonParams,
      ...restOptions,
    },
  );
};
