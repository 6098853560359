import React, {
  useState, useEffect, FunctionComponent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { FlexCenter, IconButton, FigmaTypography } from 'ui-kit';
import { RefreshIcon } from 'ui-kit/assets/icons';
import logger from 'helpers/logger';

import Chat from '.';
import { ChatProps } from './types';
import { Root, Wrapper } from './ChatWithReload.styled';

const ChatWithReload: FunctionComponent<ChatProps> = observer(({
  ...restProps
}) => {
  const { t } = useTranslation();

  const [hasError, setHasError] = useState(false);
  const [showReload, setShowReload] = useState(false);

  const [reloadAttemptsCount, setReloadAttemptsCount] = useState(0);
  const nextKey = () => setReloadAttemptsCount((prev) => prev + 1);

  const reload = (isAuto: boolean) => {
    setShowReload(false);
    setHasError(false);
    nextKey();

    logger.warn('chat reload', {
      isAuto, reloadAttemptsCount,
    });
  };

  useEffect(() => {
    if (hasError) {
      if (reloadAttemptsCount > 2) {
        setShowReload(true);
      } else {
        reload(true);
      }
    }
  }, [hasError]);

  return (
    <>
      {showReload ? (
        <Root {...restProps}>
          <Wrapper>
            <FlexCenter flexDirection="column" style={{ justifyContent: 'center', gap: '24px' }}>
              <FigmaTypography style={{ textAlign: 'center' }}>
                {t('errors.chatReload.line1')}
              </FigmaTypography>
              <FigmaTypography style={{ textAlign: 'center' }}>
                {t('errors.chatReload.line2', { reload: t('common.reload') })}
              </FigmaTypography>
              <IconButton id="reload" onClick={() => reload(false)} text={t('common.reload')}>
                <RefreshIcon />
              </IconButton>
            </FlexCenter>
          </Wrapper>
        </Root>
      ) : (
        <Chat
          {...restProps}
          errorTimeout={30_000}
          key={reloadAttemptsCount}
          onErrorHandle={() => {
            setHasError(true);
          }}
        />
      )}
    </>
  );
});

export default ChatWithReload;
