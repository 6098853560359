import styled from '@emotion/styled';

import { Color, Font, textOverflowStyle } from '../../theme';
import { IconButtonRoot } from '../IconButton/index.styled';
import { FlexCenter } from '../Flex';

const Triangle = styled.div({
  width: 0,
  height: 0,
  borderLeft: '8px solid transparent',
  borderRight: '8px solid transparent',
  borderTop: '8px solid #ffffff',
  position: 'absolute',
  bottom: -7,
  right: 10,
});

export const IconContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: 4,
  color: theme.colors.accent04,
}));

export const MenuItemLayout = styled.li<{
  disabled?: boolean, color?: string, isSelected?: boolean, variant?: 'red'
}>(({
  disabled, color, variant, theme, isSelected,
}) => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: '24px',
  minHeight: 36,
  borderRadius: 5,
  userSelect: 'none',
  fontFamily: Font.BRAND_MEDIUM,
  fontSize: '16px',
  whiteSpace: 'nowrap',
  ...(disabled ? {
    color: Color.GREY19,
    pointerEvents: 'none',
    '&&& svg': {
      color: 'inherit',
    },
  } : {
    ...color && { color },
    ...(variant === 'red') && { color: theme.colors.errorBase },
    '&[data-selected="1"]': {
      color: theme.colors.accent01,
      backgroundColor: theme.colors.accent05,
    },
    '&:not([data-selected="1"]):hover': {
      backgroundColor: theme.colors.accent01,
      ...(variant === 'red') && { backgroundColor: theme.colors.errorBase },
      [Triangle as never]: {
        borderTop: `8px solid ${Color.GREY2}`,
      },
    },
  }),
  '&:hover:not([disabled])': {
    '& > button': {
      color: 'inherit',
    },
  },
  [IconButtonRoot as never]: {
    padding: 0,
    '& > svg': {
      color: color || (isSelected ? theme.colors.accent01 : theme.colors.accent04),
    },
  },
  [IconContainer as never]: {
    '& > svg': {
      flexShrink: 0,
      color: color || (isSelected ? theme.colors.accent01 : theme.colors.accent04),
      width: 24,
      height: 24,
    },
  },
  '&&&:has(button)': {
    padding: '0 6px',
  },
  label: {
    width: '100%',
    input: {
      marginLeft: 'auto',
    },
  },
  '&>span': {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    ...textOverflowStyle,
  },
}));

export const MenuHeader = styled(MenuItemLayout)(({ theme }) => ({
  color: theme.colors.secondaryBase,
  minHeight: 'initial',
  fontWeight: 700,
  fontFamily: Font.BRAND_BOLD,
  pointerEvents: 'none',
  lineHeight: '20px',
  background: theme.colors.secondary01,
  padding: '4px 8px',
}));

export const MenuLayout = styled.div<{ isCompact?: boolean, subMenu?: boolean }>(({ theme, isCompact, subMenu }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'max-content',
  padding: 8,
  borderRadius: 10,
  border: `solid 2px ${theme.colors.secondary02}`,
  backgroundColor: theme.colors.bg,
  textAlign: 'left',
  overflow: 'hidden',
  gap: 4,
  color: theme.colors.accent05,
  [MenuItemLayout as never]: {
    height: 'initial',
    padding: 6,
  },
  ...(isCompact && {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: 4,
    border: 'none',
    borderRadius: 0,
    padding: 0,
  }),
  ...(subMenu && {
    padding: 0,
    border: 'none',
    width: '100%',
    borderRadius: 0,
  }),
}));

export const MenuDivider = styled.hr(({ theme }) => ({
  width: 'auto',
  flexGrow: 1,
  border: `1px solid ${theme.colors.secondary02}`,
  borderBottom: 'none',
  margin: 0,
  '&:last-child': {
    display: 'none',
  },
}));

export const ChildContainer = styled(FlexCenter)`
  justify-content: space-between;
  flex-grow: 1;
  gap: 10px;

  & > svg {
    color: ${({ theme }) => theme.colors.accent03};
  }
`;
