import styled from '@emotion/styled';

import { Font, Color, MediaBreakPoint } from 'ui-kit/theme';
import {
  DropDownButton as DropDownBase,
  MenuItem as MenuItemBase,
  IconButton,
} from 'ui-kit';
import ImageUploader from 'components/AvatarUploader';

export const BadgeInfo = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

export const UserName = styled.div({
  fontFamily: Font.BRAND_BOLD,
  fontSize: 22,
  lineHeight: '26px',
  wordBreak: 'break-all',
});

export const CurrentPaymentPlan = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: 'min-content',
  marginTop: 6,
  padding: 8,
  color: Color.ORANGE2,
  border: '1px solid',
  borderRadius: 10,
  borderColor: Color.GREY1,
  fontFamily: Font.BRAND_MEDIUM,
  fontSize: 14,
  lineHeight: '18px',
});

export const DropDownButton = styled(DropDownBase)({
  flexShrink: 0,
  minWidth: 53,
});

export const MenuItem = styled(MenuItemBase)({
  height: 36,
  paddingLeft: 8,
});

export const IconContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginRight: 8,
});

export const DesktopLayout = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 424,

  [MediaBreakPoint.WIDTH_640]: {
    width: '100%',
  },
});

export const UserImage = styled(ImageUploader)({
  width: 81,
  height: 81,
  borderRadius: '50%',
});

const InfoBlock = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.secondary01,
  borderRadius: 10,
}));

export const Inputs = styled(InfoBlock)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginTop: 24,
  padding: 24,
});

export const PlanFlex = styled(InfoBlock)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 16,
  padding: '20px 24px',
});

export const PlanInfo = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

export const PlanTitle = styled.span({
  fontFamily: Font.BRAND_BOLD,
  fontSize: 16,
  lineHeight: '20px',
});

export const PlanName = styled(PlanTitle)({
  color: Color.ORANGE2,
});

export const PlanExpiredDate = styled.span({
  marginTop: 4,
  fontFamily: Font.BRAND_MEDIUM,
  fontSize: 13,
  lineHeight: '16px',
  color: Color.GREY21,
});

export const InputButton = styled(IconButton)({
  height: 36,
  width: 'auto',
  flexShrink: 0,
  marginRight: 4,
  borderRadius: 8,
});

export const HintContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 20px',
});

export const HintTitle = styled.div({
  fontFamily: Font.BRAND_BOLD,
  fontSize: 40,
  lineHeight: '50px',
  marginBottom: 8,
});

export const HintText = styled.div({
  textAlign: 'center',
});
