import React, { FunctionComponent, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import isMobile from 'is-mobile';
import { useTranslation } from 'react-i18next';

import { Dialog } from 'ui-kit';
import useStores from 'stores/root';
import { MobileDialogContent } from 'ui-kit/components/Dialog/DialogLayoutMobile';

import { ProfileStatesNames } from '../type';
import ProfileStates from '../ProfileStates';

interface UserProfileDialogProps {
  onClose(): void;
}

const UserProfileDialog: FunctionComponent<UserProfileDialogProps> = observer(({
  onClose,
}) => {
  const { t } = useTranslation();

  const { uiStore } = useStores();

  const ismobile = useMemo(() => isMobile(), []);

  const handleOnCloseDialog = () => {
    onClose();
  };

  const hasBackButton = ismobile || [
    ProfileStatesNames.CHANGE_EMAIL,
    ProfileStatesNames.CHANGE_PASSWORD,
  ]
    .includes(uiStore.userProfileDialogState);

  const handleBackToInitial = () => {
    if (ismobile) {
      if (uiStore.userProfileDialogState === ProfileStatesNames.PROFILE_DATA) {
        onClose();
        return;
      }
    }

    uiStore.setUserProfileDialogState(ProfileStatesNames.PROFILE_DATA);
  };

  const title = useMemo(() => {
    switch (uiStore.userProfileDialogState) {
      case ProfileStatesNames.PROFILE_DATA:
        return t('common.profile');
      case ProfileStatesNames.CHANGE_EMAIL:
        return t('userProfile.changeEmail');
      case ProfileStatesNames.CHANGE_PASSWORD:
        return t('userProfile.changePassword');
      default:
        return null;
    }
  }, [uiStore.userProfileDialogState]);

  const Content = ismobile ? MobileDialogContent : React.Fragment;

  return (
    <Dialog
      onClose={handleOnCloseDialog}
      title={title}
      hasBackButton={hasBackButton}
      backButtonHandler={handleBackToInitial}
      backButtonId="user-profile-back"
      noBackCover={!uiStore.isUserProfileVisible}
    >
      <div style={{ display: uiStore.isUserProfileVisible ? undefined : 'none' }}>
        <Content>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}>
            <ProfileStates />
          </GoogleReCaptchaProvider>
        </Content>
      </div>
    </Dialog>
  );
});

export default UserProfileDialog;
