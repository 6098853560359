import logger from 'helpers/logger';
import { useCallback } from 'react';
import { useHistory, useRouteMatch, generatePath } from 'react-router-dom';

import useStores from 'stores/root';
import { RoutePath } from 'types/common';

const usePlansPage = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const { spaceStore } = useStores();

  const redirectToPlansPage = useCallback((inNewWindow = false) => {
    if (!spaceStore.space) {
      const message = 'Payment: space info not available';
      logger.error(message);
      throw new Error(message);
    }

    if (match.path === RoutePath.MobileProfile) {
      history.push(generatePath(RoutePath.MobileProfilePlans, { spaceId: spaceStore.space.id }));

      return;
    }

    if (match.path === RoutePath.MobileProfileAndroid) {
      history.push(`${RoutePath.MobileProfilePlansAndroid}?spaceId=${spaceStore.space.id}`);

      return;
    }

    if (inNewWindow) {
      window.open(generatePath(RoutePath.Plans, { spaceId: spaceStore.space.id }));

      return;
    }

    history.push(generatePath(RoutePath.Plans, { spaceId: spaceStore.space.id }));
  }, [spaceStore.space]);

  return { redirectToPlansPage };
};

export default usePlansPage;
