import React, {
  FunctionComponent, useState, useEffect,
} from 'react';
import { observer } from 'mobx-react-lite';
import { DraggableData } from 'react-rnd';

import useStores from 'stores/root';
import {
  Checkbox, Typography,
} from 'ui-kit';
import { getLocalStorageItem, setLocalStorageItem } from 'helpers/localStorage';

import { useFlags } from 'launchdarkly-react-client-sdk';
import { FeatureFlags } from 'types/featureFlags';
import {
  Draggable,
  Content,
  Hint,
} from './Layout';
import MicNotificationTuningPanel from './MicNotificationTuningPanel';

type DraggableEvent = React.MouseEvent<HTMLElement | SVGElement>
| React.TouchEvent<HTMLElement | SVGElement>
| MouseEvent
| TouchEvent;

const FEATURES_PANEL_POSITION = 'FeaturesPanelPosition';

const FeaturesPanel: FunctionComponent = observer(() => {
  const {
    featureStore,
  } = useStores();
  const { offMicSpeechNotification } = useFlags<FeatureFlags>();

  const handleChangeCheckboxSelected = (featureKey: string) => {
    featureStore.toggleFeatureStatus(featureKey);
  };

  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  useEffect(() => {
    const { x, y } = getLocalStorageItem<{ x: number, y: number }>(FEATURES_PANEL_POSITION) || { x: 0, y: 0 };
    setLeft(x);
    setTop(y);

    const urlParams = new URLSearchParams(window.location.search.toLowerCase());
    const ftValue = urlParams.get('ft');
    if (ftValue) {
      featureStore.setIsFeaturesToggleActivated(ftValue !== '0');
    }
  }, []);

  const dragHandler = (event: DraggableEvent, data: DraggableData) => {
    setLeft(data.x);
    setTop(data.y);

    setLocalStorageItem(FEATURES_PANEL_POSITION, { x: data.x, y: data.y });
  };

  if (!featureStore.isFeaturesToggleActivated || featureStore.isFeatureToggleHidden) {
    return null;
  }

  return (
    <Draggable
      enableResizing={false}
      onDragStop={dragHandler}
      position={{ x: left, y: top }}
    >
      <Content>
        <Typography.H3>Feature toggle panel</Typography.H3>
        <Hint>Press F to hide/open panel</Hint>
        {featureStore.featureList.map((featureName) => (
          <Checkbox
            id={`toggle-feature-${featureName}`}
            key={featureName}
            type="checkbox"
            label={featureStore.getFeatures[featureName].title}
            checked={featureStore.getFeatures[featureName].isOn}
            onChange={() => handleChangeCheckboxSelected(featureName)}
          />
        ))}
        {offMicSpeechNotification && (
          <MicNotificationTuningPanel />
        )}
      </Content>
    </Draggable>
  );
});

export default FeaturesPanel;
