import { PaymentPlan } from 'constants/payment';

interface PlanPricing {
  month: {
    price: number,
    discount: number,
  }
  year: {
    price: number,
    discount: number,
  }
}

export type PromoPlans = PaymentPlan.team_nov1323_1m;

export const promoPlanPricing: Record<PromoPlans, PlanPricing> = {
  [PaymentPlan.team_nov1323_1m]: {
    month: {
      price: 2495,
      discount: 50,
    },
    year: {
      price: 1995,
      discount: 60,
    },
  },
};

export type PromoPlanPricing = typeof promoPlanPricing;
