import {
  action, computed, makeAutoObservable, observable,
} from 'mobx';

import RecordApi, { RoomRecord } from 'services/MoodHoodApiClient/RecordApi';
import {
  CheckRecordSecret,
  GetCurrentRecordPayload,
  GetUrlForDownloadingPayload,
  StartRecordPayload,
  StopRecordPayload,
} from 'services/MoodHoodApiClient/types';
import logger from 'helpers/logger';
import { RecordState } from '../../stores/record';

export enum CloudRecordStatus {
  Idle = 'idle', // frontend only status

  New = 'new', // just created a fresh record object
  Pending = 'pending', // got a task to start recording, waiting for recorder to start
  Recording = 'recording', // recorder has started recording process
  Stopping = 'stopping', // got a task to stop recording, waiting for recorder to stop
  Stopped = 'stopped', // got a task to stop recording, waiting for recorder to finish
  Uploading = 'uploading', // recorder has started to upload the recording to storage
  Finished = 'finished', // recorder has finished its jobs, the recording is ready to be downloaded
  Failed = 'failed', // some error has occurred, the record is not available
}

export enum RecordPublicationStatus {
  Published = 'published',
  Unpublished = 'unpublished',
}

class CloudRoomRecorder {
  @observable recordApiClient: RecordApi;

  @observable currentRecordId?: string;

  @observable status = CloudRecordStatus.Idle;

  @observable isBusy = false;

  @observable startedAt?: number;

  @observable signalingToken?: string;

  constructor(recordApiClient: RecordApi) {
    this.recordApiClient = recordApiClient;
    makeAutoObservable(this);
  }

  @computed get isReady(): boolean {
    return this.commonState === RecordState.Idle;
  }

  @computed get isActive(): boolean {
    return !this.isReady;
  }

  @computed get isRecording(): boolean {
    return this.status === CloudRecordStatus.Recording;
  }

  @computed get commonState(): RecordState {
    switch (this.status) {
      case CloudRecordStatus.Pending:
        return RecordState.Maintenance;
      case CloudRecordStatus.New:
        return RecordState.Maintenance;
      case CloudRecordStatus.Recording:
        return RecordState.InProgress;
      case CloudRecordStatus.Stopped:
        return RecordState.Stopped;
      default:
        return RecordState.Idle;
    }
  }

  @action async startRecord(payload: StartRecordPayload): Promise<void> {
    if (this.isBusy) {
      return;
    }

    this.isBusy = true;
    try {
      await this.recordApiClient.start(payload);
      this.setStatus(CloudRecordStatus.Pending);
    } catch (error) {
      this.setStatus(CloudRecordStatus.Failed);
      logger.error('Failed to start room record', { error });
    } finally {
      this.isBusy = false;
    }
  }

  @action async stopRecord(payload: StopRecordPayload): Promise<void> {
    if (this.isBusy) {
      return;
    }

    this.isBusy = true;
    try {
      await this.recordApiClient.stop(payload);
      this.setStatus(CloudRecordStatus.Stopped);
    } catch (error) {
      this.setStatus(CloudRecordStatus.Failed);
      logger.error('Failed to stop room record', { error });
    } finally {
      this.isBusy = false;
    }
  }

  @action async loadCurrentRecord(payload: GetCurrentRecordPayload): Promise<RoomRecord | undefined> {
    const { data: record } = await this.recordApiClient.getCurrentRecord(payload);
    if (!record) {
      this.setInitial();
      return undefined;
    }

    if (record.startedAt) {
      this.setStartedAt(new Date(record.startedAt).getTime());
    }

    this.setCurrentRecordId(record.id);
    this.setStatus(record.state);

    return record;
  }

  @action async checkSecret(payload: CheckRecordSecret): Promise<boolean> {
    try {
      const { signalingToken } = await this.recordApiClient.checkSecret(payload);
      this.setSignalingToken(signalingToken);
      return true;
    } catch (error) {
      if (error?.status !== 403) {
        logger.error('Failed to check record secret', { error, recordId: this.currentRecordId });
      }

      return false;
    }
  }

  async downloadRecord(payload: GetUrlForDownloadingPayload): Promise<void> {
    return this.recordApiClient.downloadRecord(payload);
  }

  @action setStatus(value: CloudRecordStatus): void {
    this.status = value;
  }

  @action setCurrentRecordId(value: string): void {
    this.currentRecordId = value;
  }

  @action private setIsBusy(value: boolean): void {
    this.isBusy = value;
  }

  @action setStartedAt(value: number) {
    this.startedAt = value;
  }

  @action setInitial() {
    this.startedAt = undefined;
    this.currentRecordId = undefined;
    this.status = CloudRecordStatus.Idle;
    this.isBusy = false;
  }

  @action setSignalingToken(value: string) {
    this.signalingToken = value;
  }
}

export default CloudRoomRecorder;
