import Autowebinar from './Autowebinar';

let instance: Autowebinar | null = null;

const dispose = () => {
  if (instance) {
    instance.dispose();
    instance = null;
  }
};

const getInstance = () => {
  if (instance) {
    return instance;
  }

  instance = new Autowebinar();

  return instance;
};

export default { getInstance, dispose };
