import styled from '@emotion/styled';
import { Rnd } from 'react-rnd';

import { ZIndex } from 'ui-kit/theme';
import { Menu } from 'ui-kit';

export const Draggable = styled(Rnd)({
  zIndex: ZIndex.POPUP,
});

export const Hint = styled.div({
  marginTop: 8,
  marginBottom: 16,
  fontSize: 12,
});

export const Content = styled(Menu)`
  padding: 24px;
  background: ${({ theme }) => theme.colors.bg};
  opacity: 0.5;
  transition: opacity 0.3s;
  transition-delay: 1s;

  &:hover {
    opacity: 1;
    transition-delay: 0s;
  }
`;
