import React, { FunctionComponent, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { ButtonSelectOption } from 'ui-kit/components/ButtonSelect/types';

import { TabProps, TabsHeaderProps } from '../types';
import { TabsHeaderWithSelectRoot } from './index.styled';

const tabToOption = (tab: React.ReactElement<TabProps>, index: number) => {
  const text = tab.props.header.toString();
  return {
    id: `tab-button-${text}`,
    text,
    value: String(index),
  };
};

const TabsHeaderWithSelect: FunctionComponent<TabsHeaderProps> = observer(({
  tabs,
  onChange,
  buttonSelectProps = {},
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (option: ButtonSelectOption) => {
    const tabIndex = Number(option.value);
    setCurrentTab(tabIndex);
    onChange?.(tabIndex);
  };

  const options = tabs.map((tab, index) => ({
    ...tabToOption(tab, index),
    selected: currentTab === index,
  }));

  return (
    <TabsHeaderWithSelectRoot
      options={options}
      onChange={handleChange}
      variant="space-equal"
      {...buttonSelectProps}
    />
  );
});

export default TabsHeaderWithSelect;
