import { AxiosInstance } from 'axios';

import { getAxiosErrorMessage } from 'helpers/errors';
import { TSpaceInfo } from 'types/common';
import ParticipantsApi from './ParticipantsApi';
import Oauth2ClientApi from './Oauth2ClientApi';
import WebHookApi from './WebHookApi';

import {
  ICreateSpaceParams, SpaceUser, SpaceRole, ApiResponse,
} from '../types';

interface IGetUsersResponce {
  items: SpaceUser[];
}

interface ISpaceResponse {
  space?: TSpaceInfo;
  error?: string;
}

interface SpaceStats {
  totalInSpace: number;
  totalByRooms: Record<string, number>;
}

class SpaceApi {
  private readonly api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  get participants(): ParticipantsApi {
    return new ParticipantsApi(this.api);
  }

  get oauth2client(): Oauth2ClientApi {
    return new Oauth2ClientApi(this.api);
  }

  get webhook(): WebHookApi {
    return new WebHookApi(this.api);
  }

  async getUsers(spaceId: string): Promise<SpaceUser[]> {
    const { data } = await this.api.get<IGetUsersResponce>(`/spaces/${spaceId}/users`);
    return data.items;
  }

  async getSpaces(): ApiResponse<{ items: TSpaceInfo[] }> {
    try {
      const { data } = await this.api.get('/spaces');
      return { data };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async getSpace(spaceId: string): Promise<ISpaceResponse> {
    try {
      const { data: space } = await this.api.get(`spaces/${spaceId}`);
      return { space };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async createSpace(params: ICreateSpaceParams): Promise<ISpaceResponse> {
    try {
      const { data: space } = await this.api.post('spaces', params);
      return { space };
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async updateSpace({
    name, description, logo, spaceId, isPublic, chatToken,
  }: {
    name?: string,
    description?: string,
    logo?: string,
    isPublic?: boolean,
    chatToken?: string,
    spaceId: string,
  }): Promise<ISpaceResponse> {
    try {
      const { data } = await this.api.put(`spaces/${spaceId}`, {
        name,
        description,
        logo,
        isPublic,
        chatToken,
      });

      return data as ISpaceResponse;
    } catch (err: unknown) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async deleteSpace(spaceId: string): Promise<ISpaceResponse> {
    try {
      const { data } = await this.api.delete(`spaces/${spaceId}`);
      return data as ISpaceResponse;
    } catch (err: unknown) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async getStats(spaceId: string): ApiResponse<SpaceStats> {
    try {
      return await this.api.get<SpaceStats>(`spaces/${spaceId}/statistics`);
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async changeUserRole(spaceId: string, userId: string, role: SpaceRole): ApiResponse<undefined> {
    try {
      await this.api.put(`spaces/${spaceId}/users/role`, { userId, role });
      return {};
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }

  async revokeUserRole(spaceId: string, userId: string): ApiResponse<undefined> {
    try {
      await this.api.delete(`spaces/${spaceId}/users/role`, { data: { userId } });
      return {};
    } catch (err) {
      return {
        error: getAxiosErrorMessage(err),
      };
    }
  }
}

export default SpaceApi;
