import { AxiosInstance } from 'axios';

import { ApiResponse, ReportResponse } from 'services/MoodHoodApiClient/types';
import { getAxiosErrorMessage } from 'helpers/errors';
import { apiCall } from 'decorators';
import { GetPlaybackAnalyticsParams } from './AnalyticsApi.types';

class AnalyticsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async getReport(spaceId: string, roomId: string, callId: string): ApiResponse<ReportResponse> {
    try {
      const { data: report, headers } = await this.api.get(
        `/spaces/${spaceId}/analytics/report`, {
          params: {
            spaceId, roomId, callId,
          },
          timeout: 20_000,
          responseType: 'blob',
        },
      );

      const filename = this.extractReportFilename((headers || {}) as Record<string, unknown>);
      return { data: { report, filename } };
    } catch (err) {
      return { error: getAxiosErrorMessage(err) };
    }
  }

  private extractReportFilename(headers: Record<string, unknown>): string | undefined {
    const header = String(headers['content-disposition']);
    const nonUtf8FileName = header.split('"')[1];

    if (nonUtf8FileName) {
      return nonUtf8FileName;
    }

    const utf8EncodedFileName = header.split("''")[1];
    return utf8EncodedFileName ? decodeURIComponent(utf8EncodedFileName) : undefined;
  }

  @apiCall()
  async getPlaybackAnalytics({
    spaceId, playbackEventId, playbackEventSessionId,
  }: GetPlaybackAnalyticsParams): ApiResponse<ReportResponse> {
    const { data: report, headers } = await this.api.get<string>(`/spaces/${spaceId}/analytics/playback-report`, {
      params: { playbackEventId, playbackEventSessionId },
      responseType: 'blob',
    });

    const filename = this.extractReportFilename((headers || {}) as Record<string, unknown>);
    return { data: { report, filename } };
  }
}

export default AnalyticsApi;
