import React, {
  FunctionComponent, ReactElement, useState, ComponentProps,
} from 'react';

import { Portal } from 'ui-kit';

import { ButtonSelectProps } from 'ui-kit/components/ButtonSelect/types';
import Layout from './index.styled';
import TabsHeader from './TabsHeader';
import { TabProps } from './types';
import TabsHeaderWithSelect from './TabsHeaderWithSelect';

interface TabsProps extends ComponentProps<'div'> {
  contentElementId?: string; // if you want to draw tab content apart from tabs panel
  value?:string;
  onTabChange?: (tabIndex: number, value?: string) => void;
  children: ReactElement<TabProps>[] | ReactElement<TabProps>;
  customHeaderLayout?: FunctionComponent;
  controls?: JSX.Element;
  disabled?: boolean;
  variant?: 'tabs' | 'tabs-select';
  buttonSelectProps?: ButtonSelectProps;
}

const Tabs: FunctionComponent<TabsProps> = ({
  contentElementId,
  children,
  onTabChange,
  customHeaderLayout,
  controls,
  variant,
  buttonSelectProps,
  ...restProps
}) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (tabIndex: number, tabValue?:string) => {
    setCurrentTab(tabIndex);
    onTabChange?.(tabIndex, tabValue);
  };

  const tabs = React.Children.toArray(children) as ReactElement<TabProps>[];

  return (
    <Layout {...restProps}>
      {variant === 'tabs-select' ? (
        <TabsHeaderWithSelect
          tabs={tabs}
          onChange={handleChange}
          controls={controls}
          buttonSelectProps={buttonSelectProps}
        />
      ) : (
        <TabsHeader tabs={tabs} onChange={handleChange} controls={controls} />
      )}
      {contentElementId ? (
        <Portal hostElementId={contentElementId}>
          {tabs[currentTab]?.props.children}
        </Portal>
      ) : tabs[currentTab]?.props.children}
    </Layout>
  );
};

export default Tabs;
