import React, {
  FunctionComponent, useState, useCallback, useEffect, useRef,
} from 'react';
import { observer } from 'mobx-react-lite';

import {
  Header, Indicator, TabsHeaderRoot, HeaderWrapper, TabTitle,
} from './TabsHeader.styled';
import { TabsHeaderProps } from './types';

const TITLE_PADDING = 12;

const TabsHeader: FunctionComponent<TabsHeaderProps> = observer(({
  tabs, controls, onChange, children,
}) => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  const currentTabRef = useRef<HTMLElement>();
  const [currentTab, setCurrentTab] = useState(0);
  const [indicatorPosition, setIndicatorPosition] = useState<{ left: number, width: number }>();

  const updateIndicator = (tab: HTMLElement) => {
    if (tab && tab.parentNode) {
      const tabIndex = [...tab.parentNode.children].indexOf(tab);
      const styles = {
        left: tabIndex === 0 ? 0 : tab.offsetLeft + TITLE_PADDING,
        width: tabIndex === 0 ? tab.clientWidth - TITLE_PADDING : tab.clientWidth - TITLE_PADDING * 2,
      };

      setIndicatorPosition(styles);
    }
  };

  const handleTabClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    const el = event.currentTarget;
    const tabIndex = Number(el.dataset.tabIndex);
    if (tabIndex === currentTab) {
      return;
    }

    const { value } = el.dataset;
    setCurrentTab(tabIndex);
    onChange?.(tabIndex, value);
  }, [children, onChange, currentTab]);

  useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      entries.forEach(() => {
        if (!currentTabRef.current) {
          return;
        }

        updateIndicator(currentTabRef.current);
      });
    });

    if (headerRef.current) {
      ro.observe(headerRef.current);
    }

    return () => {
      currentTabRef.current = undefined;
      ro.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!headerRef.current) {
      return;
    }

    const tab = headerRef.current.children[currentTab] as HTMLElement;
    currentTabRef.current = tab;
    updateIndicator(tab);
  }, [currentTab]);

  return (
    <TabsHeaderRoot>
      <HeaderWrapper>
        <Header ref={headerRef}>
          {tabs.map(({ props: { id: tabId, header, ...restTabProps } }, index) => (
            <TabTitle
              onClick={handleTabClick}
              data-tab-index={index}
              key={tabId}
              current={index === currentTab}
              {...restTabProps}
            >
              {header}
            </TabTitle>
          ))}
        </Header>
        {controls}
      </HeaderWrapper>
      <Indicator left={indicatorPosition?.left} width={indicatorPosition?.width} />
    </TabsHeaderRoot>
  );
});

export default TabsHeader;
