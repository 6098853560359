import logger from 'helpers/logger';

class ScreenWakeLock {
  private wakeLock: WakeLockSentinel | null = null;

  private handleVisibilityStateChange = () => {
    if (!this.isFeatureSupported()) {
      return;
    }

    if (document.visibilityState === 'visible') {
      this.acquire();
    }
  };

  private clearWakeLock: () => void;

  constructor() {
    this.clearWakeLock = () => { this.wakeLock = null; };

    if (!this.isFeatureSupported()) {
      return;
    }

    document.addEventListener('visibilitychange', this.handleVisibilityStateChange);
  }

  async acquire() {
    if (!this.isFeatureSupported() || this.wakeLock) {
      return;
    }

    try {
      this.wakeLock = await navigator.wakeLock.request('screen');
      this.wakeLock?.addEventListener('release', this.clearWakeLock);
    } catch (error) {
      logger.error('ScreenWakeLock: failed to acquire lock', { error });
    }
  }

  async release() {
    if (!this.isFeatureSupported() || !this.wakeLock) {
      return;
    }

    try {
      await this.wakeLock.release();
      this.wakeLock.removeEventListener('release', this.clearWakeLock);
      this.wakeLock = null;
    } catch (error) {
      logger.error('ScreenWakeLock: failed to release lock', { error });
    }
  }

  isFeatureSupported() {
    return Boolean(navigator.wakeLock);
  }

  async dispose(): Promise<void> {
    if (!this.isFeatureSupported() || !this.wakeLock) {
      return;
    }

    document.removeEventListener('visibilitychange', this.handleVisibilityStateChange);
    await this.release();
  }
}

export default ScreenWakeLock;
