import { MetricDurations, MetricReactions } from 'services/MoodHoodAnalyticsApiClient/types';

export type TToggleMetrics = MetricDurations & MetricReactions;

export type TMetricName = keyof TToggleMetrics;

export enum EventCategory {
  Microphone = 'microphone',
  Camera = 'camera',
  ActiveTab = 'activeTab',
}

export interface IMetricEvent {
  event: 'metric',
  metric: string,
  value: number
}

export interface IToggleEventStats {
  name: EventCategory,
  onTimeInMs: number,
  offTimeInMs: number,
  lastEventTime: number,
  isOn: boolean,
}

export interface IToggleEvents {
  microphone: IToggleEventStats,
  camera: IToggleEventStats,
  activeTab: IToggleEventStats,
}
