import styled from '@emotion/styled';

import { FigmaTypography } from 'ui-kit';
import LogoBase from 'components/Logo';

import SignUpFormBase from '../../Forms/SignUpForm';
import SignUpFormStep2Base from '../../Forms/SignUpFormStep2';
import SignInFormBase from '../../Forms/SignInForm';

export const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: ${412 + 16 * 2}px;
  margin: 0 auto;
  padding: 32px 16px;
  min-height: 100%;
`;

export const Text = styled(FigmaTypography)`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
`;

Text.defaultProps = {
  size: 'main-text-bold',
};

export const Logo = styled(LogoBase)`
  &&, && > img {
    width: 108px;
    max-width: 108px;
    height: 36px;
    max-height: 36px;
  }
`;

export const Separator = styled.div`
  width: 100%;
  margin: 16px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.secondary02};
`;

const FormStyle = styled.div`
  width: 100%;

  &, & > form {
    display: flex;
    flex-grow: 1;
  }

  & > form {
    flex-direction: column;
  }
`;

export const SignInForm = styled(FormStyle)().withComponent(SignInFormBase);

export const SignUpForm = styled(FormStyle)().withComponent(SignUpFormBase);

export const SignUpFormStep2 = styled(FormStyle)().withComponent(SignUpFormStep2Base);

export const FlexGrow = styled.div`
  flex-grow: 1;
`;
