import React, { useState } from 'react';

import { HomeIcon } from 'ui-kit/assets/icons';
import { ThemeType } from 'ui-kit/themes/types';
import useStores from 'stores/root';
import {
  MenuItem, Popup, Menu, DropDownButton,
} from 'ui-kit';

import {
  IconButtonStoryRoot,
  ButtonsRow,
  ButtonsColumn,
  ButtonsGroup,
  Controls,
} from './index.styled';
import IconButton from '../../ui-kit/components/IconButton';
import Toggle from '../../ui-kit/components/Toggle';
import {
  IconButtonProps,
  IconButtonSubVariant,
  IconButtonVariant,
} from '../../ui-kit/components/IconButton/types';

type ButtonProps = Partial<IconButtonProps>;
type ButtonsProps = ButtonProps[];

const buttons: ButtonsProps = [
  {
    text: 'Label',
    icon: <HomeIcon />,
  },
  {
    icon: <HomeIcon />,
  },
  {
    text: 'Label',
    icon: <HomeIcon />,
    iconAtEnd: <HomeIcon />,
  },
];

const FakeMenu = (
  <Popup>
    <Menu>
      <MenuItem id="first">Menu Item text here</MenuItem>
      <MenuItem id="second">Menu Item</MenuItem>
    </Menu>
  </Popup>
);

type RenderFn = (buttonsProps: ButtonsProps, addProps?: ButtonProps) => void;

const renderButtons = (buttonsProps: ButtonsProps, addProps?: ButtonProps) => (
  <ButtonsRow>
    {/* eslint-disable-next-line react/no-array-index-key */}
    {buttonsProps.map((props, index) => (<IconButton key={index} {...props} {...addProps} />))}
  </ButtonsRow>
);

const renderMoreButtons = (buttonsProps: ButtonsProps, addProps?: ButtonProps) => (
  <ButtonsRow>
    {buttonsProps.map((props, index) => (
    /* eslint-disable-next-line react/no-array-index-key */
      <DropDownButton id="test" key={index} {...props} {...addProps} menu={FakeMenu} showIndicator />
    ))}
  </ButtonsRow>
);

const renderButtonVariant = (
  variant?: IconButtonVariant,
  subVariant?: IconButtonSubVariant,
  props?: Partial<IconButtonProps>,
  renderFn: RenderFn = renderButtons,
) => (
  <ButtonsColumn>
    {renderFn(buttons, { variant, subVariant, ...props })}
    {renderFn(buttons, {
      variant, subVariant, disabled: true, ...props,
    })}
    {renderFn(buttons, {
      variant, subVariant, isSelected: true, ...props,
    })}
    {renderFn(buttons, {
      variant, subVariant, isSelected: true, disabled: true, ...props,
    })}
  </ButtonsColumn>
);

const IconButtonStory = () => {
  const { uiStore } = useStores();
  const [buttonSize, setButtonSize] = useState<'small'>();

  const handleToggleSize = () => {
    setButtonSize(buttonSize === 'small' ? undefined : 'small');
  };

  const handleToggleTheme = () => {
    const newTheme = uiStore.theme.type === ThemeType.dark ? ThemeType.light : ThemeType.dark;
    uiStore.theme.setTheme(newTheme);
  };

  const props = { size: buttonSize };

  return (
    <IconButtonStoryRoot>
      <Controls>
        <Toggle label="Change theme" onChange={handleToggleTheme} />
        <Toggle label="Small size" onChange={handleToggleSize} />
      </Controls>
      <ButtonsGroup>
        {renderButtonVariant(undefined, undefined, props)}
        {renderButtonVariant('primary', undefined, props)}
        {renderButtonVariant('red', undefined, props)}
      </ButtonsGroup>
      <ButtonsGroup>
        {renderButtonVariant('primary-red', undefined, props)}
        {renderButtonVariant('secondary', 'clear', props)}
        {renderButtonVariant('secondary', 'link', props)}
      </ButtonsGroup>
      <ButtonsGroup>
        {renderButtonVariant('primary-red', undefined, props)}
        {renderButtonVariant('secondary', 'clear', props)}
        {renderButtonVariant('secondary', 'link', props)}
      </ButtonsGroup>
      <ButtonsGroup>
        {renderButtonVariant(undefined, undefined, props, renderMoreButtons)}
        {renderButtonVariant('red', undefined, props, renderMoreButtons)}
      </ButtonsGroup>
    </IconButtonStoryRoot>
  );
};

export default IconButtonStory;
