import React, { FunctionComponent, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import useStores from 'stores/root';
import { toast } from 'react-toastify';

import {
  DesktopLayout,
  Inputs,
  Input,
  InputButton,
} from '../index.styled';

const WebHookSettings: FunctionComponent = observer(() => {
  const { webHookStore, spaceStore: { space } } = useStores();
  const { t } = useTranslation();

  const handleSaveClick = async () => {
    if (!space?.id) {
      return;
    }

    if (webHookStore.isExists) {
      await webHookStore.update(space.id, (err: string) => toast.error(err));
      return;
    }

    await webHookStore.create(space.id, (err: string) => toast.error(err));
  };

  const handleRefreshClick = async () => {
    if (!space?.id || !webHookStore.isExists) {
      return;
    }

    await webHookStore.refreshSecret(space.id, (err: string) => toast.error(err));
  };

  const handleWebHookUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    webHookStore.setWebHookUrl(value);
  };

  const loadWebHook = async () => {
    if (!space?.id) {
      return;
    }

    await webHookStore.get(space.id, (err: string) => toast.error(err));
  };

  useEffect(() => {
    loadWebHook();
  }, []);

  const Layout = DesktopLayout;

  return (
    <Layout>
      <Inputs>
        <Input
          id="webhook-url"
          fullWidth
          label="WebHook URL"
          value={webHookStore.url || ''}
          onChange={handleWebHookUrlChange}
          endInputElement={(
            <InputButton
              id="save-username"
              buttonSize="small"
              variant="primary"
              onClick={handleSaveClick}
            >
              {t('common.save')}
            </InputButton>
          )}
        />
        <Input
          id="webhook-secret"
          fullWidth
          label="WebHook secret"
          value={webHookStore.secret}
          readOnly
          placeholder={webHookStore.url ? '********************' : ''}
          endInputElement={(
            <InputButton
              id="save-username"
              buttonSize="small"
              variant="primary"
              onClick={handleRefreshClick}
            >
              {t('common.refresh')}
            </InputButton>
          )}
        />
      </Inputs>
    </Layout>
  );
});

export default WebHookSettings;
