import styled from '@emotion/styled';

import Badge from 'ui-kit/components/Badge/Layout';

import { ButtonSelectVariant } from './types';
import { IconButtonSubVariant } from '../IconButton/types';

export const OptionText = styled.div({
  display: 'flex',
  flexDirection: 'row',
  [Badge as never]: {
    marginLeft: 8,
  },
});

export const ButtonSelectRoot = styled.div<{
  variant?: ButtonSelectVariant,
  buttonVariant?: IconButtonSubVariant,
}>(({ theme, variant, buttonVariant }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: 10,
  border: 'solid 1px',
  borderColor: theme.colors.secondary03,
  height: 'max-content',
  '&& > *': {
    ...(variant === 'space-equal' ? { flex: '1 1 0' } : { flexGrow: 1 }),
  },
  ...(buttonVariant !== 'clear' && {
    gap: 8,
    border: 'none',
  }),
}));
