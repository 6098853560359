import { RoomRole } from 'services/MoodHoodApiClient/types';
import { RoomRolePermissions } from './types';

const moderatorPermissions: RoomRolePermissions = {
  canUseScreenShare: true,
  canUseRoomRecording: true,
  canFinishCallForAll: true,
  canDeleteMessageInChat: true,
  canBanUsersInChat: true,
  canPutUsersOutOfScreen: true,
  canUseAnnouncements: true,
  canUsePolls: true,
  canDisableParticapansMic: true,
  canUseSpacesList: true,
  canUseSpaceRoomsList: true,
  canResetReactionsCounters: true,
  canRaiseHand: true,
  canUseRoomActivity: true,
  canBanUsersInRoom: true,
};

const speakerPermissions: RoomRolePermissions = {
  canUseScreenShare: true,
  canPutUsersOutOfScreen: true,
  canUseAnnouncements: true,
  canUsePolls: true,
  canResetReactionsCounters: true,
  canRaiseHand: true,
  canUseRoomActivity: true,
  canUseRoomRecording: false,
  canFinishCallForAll: false,
  canDeleteMessageInChat: false,
  canBanUsersInChat: false,
  canDisableParticapansMic: false,
  canUseSpacesList: false,
  canUseSpaceRoomsList: false,
  canBanUsersInRoom: false,
};

const userPermissions: RoomRolePermissions = {
  canUseScreenShare: false,
  canUseRoomRecording: false,
  canFinishCallForAll: false,
  canDeleteMessageInChat: false,
  canBanUsersInChat: false,
  canPutUsersOutOfScreen: false,
  canUseAnnouncements: false,
  canUsePolls: false,
  canDisableParticapansMic: false,
  canUseSpacesList: false,
  canUseSpaceRoomsList: false,
  canResetReactionsCounters: false,
  canRaiseHand: false,
  canUseRoomActivity: false,
  canBanUsersInRoom: false,
};

const guestPermissions: RoomRolePermissions = {
  canUseScreenShare: false,
  canUseRoomRecording: false,
  canFinishCallForAll: false,
  canDeleteMessageInChat: false,
  canBanUsersInChat: false,
  canPutUsersOutOfScreen: false,
  canUseAnnouncements: false,
  canUsePolls: false,
  canDisableParticapansMic: false,
  canUseSpacesList: false,
  canUseSpaceRoomsList: false,
  canResetReactionsCounters: false,
  canRaiseHand: false,
  canUseRoomActivity: false,
  canBanUsersInRoom: false,
};

const rolePermissions = Object.freeze({
  [RoomRole.Owner]: Object.freeze(moderatorPermissions),
  [RoomRole.Moderator]: Object.freeze(moderatorPermissions),
  [RoomRole.Speaker]: Object.freeze(speakerPermissions),
  [RoomRole.User]: Object.freeze(userPermissions),
  [RoomRole.Guest]: Object.freeze(guestPermissions),
});

export default rolePermissions;
