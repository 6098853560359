import styled from '@emotion/styled';

import { FigmaTypography } from 'ui-kit';
import LogoBase from 'components/Logo';

import SignUpFormBase from '../../Forms/SignUpForm';
import SignUpFormStep2Base from '../../Forms/SignUpFormStep2';
import SignInFormBase from '../../Forms/SignInForm';

export const Wrapper = styled.div`
  background-image: ${({ theme }) => (theme.PageBack ? `url('${theme.PageBack}')` : undefined)};
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  display: flex;
  margin: auto;
`;

export const Logo = styled(LogoBase)`
  &&, && > img {
    width: 143px;
    max-width: 143px;
    height: 48px;
    max-height: 48px;
  }
`;

export const FormHolder = styled.div`
  margin: auto;

  display: flex;
  width: 600px;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.secondary02};
  background: ${({ theme }) => theme.colors.bg};
`;

export const Text = styled(FigmaTypography)`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
`;

Text.defaultProps = {
  size: 'h2',
};

export const SignInForm = styled(SignInFormBase)`
  width: 100%;
`;

export const SignUpForm = styled(SignUpFormBase)`
  width: 100%;
`;

export const SignUpFormStep2 = styled(SignUpFormStep2Base)`
  width: 100%;
`;
