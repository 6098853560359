import { lock } from 'decorators';
import { makeAutoObservable, observable } from 'mobx';
import { saveAs } from 'file-saver';

import { Playback } from 'services/MoodHoodApiClient/Playbacks.types';
import PlaybackEventSessionsListItemsProvider from './PlaybackEventSessionsListItemsProvider';
import { PlaybackEventSessionsParams } from './types';

class PlaybackSessions {
  @observable playback: Playback | null = null;

  @observable sessionsProvider: PlaybackEventSessionsListItemsProvider | null = null;

  @observable isBusy = false;

  @observable error: string | null = null;

  private params: PlaybackEventSessionsParams;

  constructor(params: PlaybackEventSessionsParams) {
    this.params = params;
    makeAutoObservable(this);
  }

  load(playbackId: string) {
    const { spaceId, playbackEventsApi } = this.params;
    this.fetchPlayback(playbackId);
    this.sessionsProvider = new PlaybackEventSessionsListItemsProvider({ spaceId, playbackId, playbackEventsApi });
    this.sessionsProvider.setSource('sessions-running');
    this.sessionsProvider.loadMore();
  }

  resetError() {
    this.error = null;
  }

  dispose() {
    this.sessionsProvider?.dispose();
  }

  @lock()
  async downloadAnalyticsReport(playbackEventId: string, playbackEventSessionId: string) {
    const { spaceId, analyticsApi } = this.params;
    const { data, error } = await analyticsApi.getPlaybackAnalytics({
      spaceId, playbackEventId, playbackEventSessionId,
    });

    if (error) {
      this.handleApiError('events.failedToDownloadReport');
    }

    if (data) {
      const blob = new Blob([data.report], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      saveAs(blob, data.filename || 'report.xlsx');
    }
  }

  @lock()
  private async fetchPlayback(playbackId: string) {
    const { spaceId, playbacksApi } = this.params;
    const { data, error } = await playbacksApi.fetchPlayback({ spaceId, id: playbackId });
    if (data) {
      this.playback = data;
    }

    if (error) {
      this.handleApiError('events.failedToFetchSessions');
    }
  }

  private handleApiError(error: string) {
    this.error = error;
  }
}

export default PlaybackSessions;
