import styled from '@emotion/styled';
import { ZIndex } from 'ui-kit/theme';

import { PopupLayout } from './types';

export const Cover = styled.div({
  position: 'fixed',
  zIndex: ZIndex.POPUP_COVER,
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
});

const Layout = styled.div<PopupLayout>(({ duration }) => ({
  '--animation-duration': `${duration}ms`,
  position: 'absolute',
  zIndex: ZIndex.POPUP,
  margin: '0 auto',
  textAlign: 'left',
}));

export default Layout;
