export const LESSON_ENCODINGS = {
  screenSharingEncodings: {
    vp8: [
      {
        scaleResolutionDownBy: 1, // 1920x1080 0.8mbps 24fps by default
        maxBitrate: 800_000,
        maxFramerate: 24,
      },
    ],
    vp9: [
      {
        scaleResolutionDownBy: 1,
        maxBitrate: 900_000,
        maxFramerate: 24,
        scalabilityMode: 'L1T1',
        dtx: true,
      },
    ],
  },
  webCamEncodings: {
    vp8: [
      {
        scaleResolutionDownBy: 4, // 320x180 0.15mbps 8fps by default
        maxBitrate: 150_000,
        maxFramerate: 8,
      },
      {
        scaleResolutionDownBy: 2, // 640x360 0.2mbps 12fps by default
        maxBitrate: 200_000,
        maxFramerate: 12,
      },
      {
        scaleResolutionDownBy: 1, // 1280x720 0.35mbps 14fps by default
        maxBitrate: 350_000,
        maxFramerate: 14,
      },
    ],
    vp9: [
      {
        maxBitrate: 150_000,
        maxFramerate: 14,
        scalabilityMode: 'L1T1',
        scaleResolutionDownBy: 4,
      },
      {
        maxBitrate: 350_000,
        maxFramerate: 14,
        scalabilityMode: 'L2T1',
        scaleResolutionDownBy: 2,
      },
      {
        maxBitrate: 900_000,
        maxFramerate: 14,
        scalabilityMode: 'L3T1',
        scaleResolutionDownBy: 1,
      },
    ],
  },
};

export const WEBINAR_ENCODINGS = {
  webCamEncodings: {
    vp8: [
      {
        scaleResolutionDownBy: 4, // 320x180 0.15mbps 8fps by default
        maxBitrate: 150_000,
        maxFramerate: 8,
      },
      {
        scaleResolutionDownBy: 2, // 640x360 0.2mbps 12fps by default
        maxBitrate: 200_000,
        maxFramerate: 12,
      },
      {
        scaleResolutionDownBy: 1, // 1280x720 0.35mbps 14fps by default
        maxBitrate: 350_000,
        maxFramerate: 14,
      },
    ],
    vp9: [
      {
        maxBitrate: 150_000,
        maxFramerate: 14,
        scalabilityMode: 'L1T1',
        scaleResolutionDownBy: 4,
      },
      {
        maxBitrate: 350_000,
        maxFramerate: 14,
        scalabilityMode: 'L2T1',
        scaleResolutionDownBy: 2,
      },
      {
        maxBitrate: 900_000,
        maxFramerate: 14,
        scalabilityMode: 'L3T1',
        scaleResolutionDownBy: 1,
      },
    ],
  },
};

export const YA_BROWSER_LESSON_ENCODINGS = {
  webCamEncodings: {
    vp8: [
      {
        scaleResolutionDownBy: 2, // 640x360 0.2mbps 12fps by default
        maxBitrate: 200_000,
        maxFramerate: 12,
      },
      {
        scaleResolutionDownBy: 1, // 1280x720 0.35mbps 14fps by default
        maxBitrate: 350_000,
        maxFramerate: 14,
      },
    ],
    vp9: [
      {
        maxBitrate: 150_000,
        maxFramerate: 14,
        scalabilityMode: 'L1T1',
        scaleResolutionDownBy: 4,
      },
      {
        maxBitrate: 350_000,
        maxFramerate: 14,
        scalabilityMode: 'L2T1',
        scaleResolutionDownBy: 2,
      },
      {
        maxBitrate: 900_000,
        maxFramerate: 14,
        scalabilityMode: 'L3T1',
        scaleResolutionDownBy: 1,
      },
    ],
  },
};

export const YA_BROWSER_WEBINAR_ENCODINGS = {
  webCamEncodings: {
    vp8: [
      {
        scaleResolutionDownBy: 2, // 640x360 0.2mbps 12fps by default
        maxBitrate: 200_000,
        maxFramerate: 12,
      },
      {
        scaleResolutionDownBy: 1, // 1280x720 0.35mbps 14fps by default
        maxBitrate: 350_000,
        maxFramerate: 14,
      },
    ],
    vp9: [
      {
        maxBitrate: 150_000,
        maxFramerate: 14,
        scalabilityMode: 'L1T1',
        scaleResolutionDownBy: 4,
      },
      {
        maxBitrate: 350_000,
        maxFramerate: 14,
        scalabilityMode: 'L2T1',
        scaleResolutionDownBy: 2,
      },
      {
        maxBitrate: 900_000,
        maxFramerate: 14,
        scalabilityMode: 'L3T1',
        scaleResolutionDownBy: 1,
      },
    ],
  },
};

export const SAFARI_LESSON_ENCODINGS = {
  webCamEncodings: {
    vp8: [
      {
        scaleResolutionDownBy: 6, // 212x120 0.15mbps 8fps by default
        maxBitrate: 150_000,
        maxFramerate: 8,
      },
      {
        scaleResolutionDownBy: 2, // 640x360 0.2mbps 12fps by default
        maxBitrate: 200_000,
        maxFramerate: 12,
      },
      {
        scaleResolutionDownBy: 1, // 1280x720 0.35mbps 14fps by default
        maxBitrate: 350_000,
        maxFramerate: 14,
      },
    ],
    vp9: [
      {
        maxBitrate: 150_000,
        maxFramerate: 14,
        scalabilityMode: 'L1T1',
        scaleResolutionDownBy: 4,
      },
      {
        maxBitrate: 350_000,
        maxFramerate: 14,
        scalabilityMode: 'L2T1',
        scaleResolutionDownBy: 2,
      },
      {
        maxBitrate: 900_000,
        maxFramerate: 14,
        scalabilityMode: 'L3T1',
        scaleResolutionDownBy: 1,
      },
    ],
  },
};

export const SAFARI_WEBINAR_ENCODINGS = {
  webCamEncodings: {
    vp8: [
      {
        scaleResolutionDownBy: 6, // 212x120 0.15mbps 8fps by default
        maxBitrate: 150_000,
        maxFramerate: 8,
      },
      {
        scaleResolutionDownBy: 2, // 640x360 0.2mbps 12fps by default
        maxBitrate: 200_000,
        maxFramerate: 12,
      },
      {
        scaleResolutionDownBy: 1, // 1280x720 0.35mbps 14fps by default
        maxBitrate: 350_000,
        maxFramerate: 14,
      },
    ],
    vp9: [
      {
        maxBitrate: 150_000,
        maxFramerate: 14,
        scalabilityMode: 'L1T1',
        scaleResolutionDownBy: 4,
      },
      {
        maxBitrate: 350_000,
        maxFramerate: 14,
        scalabilityMode: 'L2T1',
        scaleResolutionDownBy: 2,
      },
      {
        maxBitrate: 900_000,
        maxFramerate: 14,
        scalabilityMode: 'L3T1',
        scaleResolutionDownBy: 1,
      },
    ],
  },
};

export const FULL_HD_ENCODINGS = {
  screenSharingEncodings: {
    vp8: [
      {
        scaleResolutionDownBy: 4,
        maxBitrate: 350_000,
        maxFramerate: 25,
      },
      {
        maxBitrate: 2_500_000,
        maxFramerate: 25,
      },
    ],
    vp9: [
      {
        scaleResolutionDownBy: 1,
        maxBitrate: 2_500_000,
        maxFramerate: 25,
        scalabilityMode: 'L1T1',
      },
    ],
  },
  webCamEncodings: {
    vp8: [
      {
        scaleResolutionDownBy: 3, // 640x360 0.3mbps 8fps by default
        maxBitrate: 300_000,
        maxFramerate: 8,
      },
      {
        scaleResolutionDownBy: 2, // 960x540 0.45mbps 14fps by default
        maxBitrate: 450_000,
        maxFramerate: 14,
      },
      {
        scaleResolutionDownBy: 1, // 1920x1080 0.9mbps 25fps by default
        maxBitrate: 900_000,
        maxFramerate: 25,
      },
    ],
    vp9: [
      {
        maxBitrate: 150_000,
        maxFramerate: 25,
        scalabilityMode: 'L1T1',
        scaleResolutionDownBy: 4,
      },
      {
        maxBitrate: 350_000,
        maxFramerate: 25,
        scalabilityMode: 'L2T1',
        scaleResolutionDownBy: 2,
      },
      {
        maxBitrate: 900_000,
        maxFramerate: 25,
        scalabilityMode: 'L3T1',
        scaleResolutionDownBy: 1,
      },
    ],
  },
};
