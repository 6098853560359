import {
  computed, makeAutoObservable, observable, toJS,
} from 'mobx';
import { Effects } from '@livedigital/client/dist/engine/media/streamEffects/video/esdk/TrackProcessor';

import { ThemeType } from 'ui-kit/themes/types';
import { getLocalStorageItem, setLocalStorageItem } from 'helpers/localStorage';
import { getSessionStorageItem, setSessionStorageItem } from 'helpers/sessionStorage';
import { get, set } from 'idb-keyval';
import logger from 'helpers/logger';

import { LocalStorageUserSettings, SessionStorageUserSettings } from './types';

class UserSettings {
  private APP_SETTINGS_KEY = 'ld-user-settings';

  @observable private settings: LocalStorageUserSettings;

  @observable private sessionSettings: SessionStorageUserSettings;

  constructor() {
    this.settings = getLocalStorageItem<LocalStorageUserSettings>(this.APP_SETTINGS_KEY) || {};
    this.sessionSettings = getSessionStorageItem<SessionStorageUserSettings>(this.APP_SETTINGS_KEY) || {};
    makeAutoObservable(this);
  }

  @computed get theme() {
    return this.settings?.theme || null;
  }

  @computed get effects() {
    return this.settings?.effects || null;
  }

  @computed get storedEffects() {
    return this.settings?.storedEffects || null;
  }

  @computed get backgroundBlurColors() {
    return this.sessionSettings.backgroundBlurColors;
  }

  @computed get backgroundBlurImages() {
    return this.settings.backgroundBlurImages;
  }

  @computed get backgroundBlurVideos() {
    return this.settings.backgroundBlurVideos;
  }

  async load() {
    try {
      this.sessionSettings = getSessionStorageItem<SessionStorageUserSettings>(this.APP_SETTINGS_KEY) || {};
      const settings = await get<LocalStorageUserSettings>(this.APP_SETTINGS_KEY);
      this.settings = settings ?? (getLocalStorageItem<LocalStorageUserSettings>(this.APP_SETTINGS_KEY) || {});
    } catch (error) {
      logger.error('Failed to load user settings', { error, case: 'UserSettings::load' });
    }
  }

  setTheme(themeType: ThemeType) {
    this.settings.theme = themeType;
    this.setLocalStorageValue(this.APP_SETTINGS_KEY, this.settings);
  }

  setBackgroundBlurColors(colors: string[]) {
    this.sessionSettings.backgroundBlurColors = colors;
    setSessionStorageItem(this.APP_SETTINGS_KEY, this.sessionSettings);
  }

  setBackgroundBlurImages(images: string[]) {
    this.settings.backgroundBlurImages = images;
    this.setLocalStorageValue(this.APP_SETTINGS_KEY, this.settings);
  }

  setBackgroundBlurVideos(videos: string[]) {
    this.settings.backgroundBlurVideos = videos;
    this.setLocalStorageValue(this.APP_SETTINGS_KEY, this.settings);
  }

  setEffects(effects?: Effects) {
    this.settings.effects = effects;
    this.setLocalStorageValue(this.APP_SETTINGS_KEY, this.settings);
  }

  setStoredEffects(effects?: Effects) {
    this.settings.storedEffects = effects;
    this.setLocalStorageValue(this.APP_SETTINGS_KEY, this.settings);
  }

  private get canAccessIndexedDB() {
    return Boolean(window.indexedDB);
  }

  private setLocalStorageValue(key: string, val: LocalStorageUserSettings) {
    try {
      return this.canAccessIndexedDB
        ? set(this.APP_SETTINGS_KEY, toJS(val))
        : setLocalStorageItem(this.APP_SETTINGS_KEY, val);
    } catch (error) {
      logger.error('Failed to store user settings', { error, case: 'setLocalStorageValue' });
    }

    return undefined;
  }
}

export default UserSettings;
