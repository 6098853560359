import React, {
  FunctionComponent, useCallback, useState, useRef, ChangeEvent, useEffect, forwardRef,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip, IconButton, HelpIconWithTooltip } from 'ui-kit';
import useEnterEscKeyHandler from 'hooks/useEnterEscKeyHandler';

import LayoutDesktop, {
  Cards as CardsDesktop,
  Header as HeaderDesktop,
  Title as TitleDesktop,
  HelpIconContainer,
} from './LayoutDesktop';
import LayoutMobile, {
  Cards as CardsMobile,
  Header as HeaderMobile,
  Title as TitleMobile,
} from './LayoutMobile';
import { GridProps } from './types';
import { ReactComponent as PlusIcon } from './assets/plus.svg';
import { ReactComponent as SearchIcon } from './assets/search.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';

const Grid: FunctionComponent<GridProps> = forwardRef<HTMLDivElement, GridProps>(({
  children,
  title,
  showButtons,
  searchTooltip,
  createTooltip,
  onFilter,
  onCreate,
  dataStats,
  isMobile = false,
  showHeader = true,
}, cardsRef) => {
  const {
    Layout,
    Cards,
    Header,
    Title,
  } = isMobile ? {
    Layout: LayoutMobile,
    Cards: CardsMobile,
    Header: HeaderMobile,
    Title: TitleMobile,
  } : {
    Layout: LayoutDesktop,
    Cards: CardsDesktop,
    Header: HeaderDesktop,
    Title: TitleDesktop,
  };

  const titleRef = useRef<HTMLInputElement>(null);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [gridTitle, setGridTitle] = useState(title);
  const { t } = useTranslation();

  useEnterEscKeyHandler(titleRef, (key) => {
    if (key === 'Escape') {
      setIsFilterActive(false);
    }
  }, !isFilterActive);

  useEffect(() => {
    setIsFilterActive(false);
  }, [title]);

  useEffect(() => {
    if (!isFilterActive) {
      setGridTitle(title);
      onFilter?.('');
    } else {
      setGridTitle('');
      titleRef.current?.focus();
    }
  }, [isFilterActive, title]);

  const handleTitleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onFilter?.(value);
    setGridTitle(value);
  }, [onFilter]);

  const handleSearchClick = useCallback(() => {
    setIsFilterActive(true);
  }, []);

  const handleCloseClick = useCallback(() => {
    setIsFilterActive(false);
  }, []);

  const handleCreateClick = useCallback(() => {
    onCreate?.();
  }, [onCreate]);

  const dataStatsPrefix = dataStats ? `${dataStats}_` : '';

  return (
    <Layout>
      {showHeader && (
        <Header>
          <Title
            spellCheck="false"
            autoComplete="off"
            ref={titleRef}
            value={gridTitle}
            readOnly={!isFilterActive}
            placeholder={t('common.search')}
            onChange={handleTitleChange}
            id="grid-search"
          />
          {
            isFilterActive ? showButtons && (
              <Tooltip title={t('common.close') ?? ''}>
                <IconButton
                  id="grid-close"
                  data-stats={`${dataStatsPrefix}find_close`}
                  onClick={handleCloseClick}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <>
                {!isMobile && (
                  <HelpIconContainer>
                    <HelpIconWithTooltip message={t('admin.spaces.titleHintTooltip')} />
                  </HelpIconContainer>
                )}
                {showButtons && (
                  <>
                    <Tooltip title={createTooltip ?? ''}>
                      <IconButton
                        id="grid-add"
                        data-stats={`${dataStatsPrefix}create`}
                        onClick={handleCreateClick}
                        variant="primary"
                      >
                        <PlusIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={searchTooltip ?? ''}>
                      <IconButton
                        id="grid-search"
                        data-stats={`${dataStatsPrefix}find`}
                        onClick={handleSearchClick}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
              </>
            )
          }
        </Header>
      )}
      <Cards ref={cardsRef}>
        { children }
      </Cards>
    </Layout>
  );
});

export default Grid;
