import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import {
  Menu, MenuHeader, MenuItem, MenuDivider,
} from 'ui-kit';
import Popup from 'ui-kit/new-components/Popup';
import useStores from 'stores/root';
import { SelectedItem } from 'ui-kit/components/Menu/types';
import { ThemeType } from 'ui-kit/themes/types';
import { PopupProps } from 'ui-kit/components/Popup/types';
import locales from 'services/i18n/locales';

const SettingsMenu: FunctionComponent<PopupProps> = observer((props) => {
  const { t, i18n } = useTranslation();
  const {
    userStore,
    userStore: { currentLanguage },
    uiStore: { theme, isCompactWidth },
  } = useStores();

  const handleSelectLanguage = (values: SelectedItem[]) => {
    if (!values.length) {
      return;
    }

    const { value } = values[0];
    i18n.changeLanguage(value);
    userStore.setCurrentLanguage();
  };

  const handleThemeChange = (values: SelectedItem[]) => {
    if (!values.length) {
      return;
    }

    const value = values[0].value as ThemeType;
    theme.changeTheme(value);
  };

  return (
    <Popup isCompact={isCompactWidth} title={t('common.settings')} {...props}>
      <Menu isCompact={isCompactWidth} style={{ width: isCompactWidth ? '100%' : 290 }}>
        {Object.keys(locales).length > 1 && (
          <>
            <MenuHeader>{t('common.language')}</MenuHeader>
            {Object.keys(locales).map((lang) => (
              <Menu key={`lang-${lang}`} subMenu select="single" onSelect={handleSelectLanguage}>
                <MenuItem
                  id={`lang-${lang}`}
                  data-value={lang}
                  checkMark
                  isSelected={currentLanguage === lang}
                >
                  {t(`language.${lang}`)}
                </MenuItem>
              </Menu>
            ))}
            <MenuDivider />
          </>
        )}
        <MenuHeader>{t('theme.theme')}</MenuHeader>
        <Menu subMenu select="single" onSelect={handleThemeChange}>
          <MenuItem
            id="theme-light"
            data-value={ThemeType.light}
            checkMark
            isSelected={theme.type === ThemeType.light}
          >
            {t('theme.light')}
          </MenuItem>
          <MenuItem
            id="theme-dark"
            data-value={ThemeType.dark}
            checkMark
            isSelected={theme.type === ThemeType.dark}
          >
            {t('theme.dark')}
          </MenuItem>
          <MenuItem
            id="theme-auto"
            data-value={ThemeType.auto}
            checkMark
            isSelected={theme.type === ThemeType.auto}
          >
            {t('theme.auto')}
          </MenuItem>
        </Menu>
      </Menu>
    </Popup>
  );
});

export default SettingsMenu;
