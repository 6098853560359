import styled from '@emotion/styled';

export const BigPreview = styled.canvas<{ width: number }>(({ width }) => ({
  width,
  height: width,
  borderRadius: '50%',
}));

export const SmallPreview = styled(BigPreview)({
  marginTop: 24,
});
