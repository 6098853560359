import React, {
  VoidFunctionComponent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  FigmaTypography,
  FigmaTypographyBR,
} from 'ui-kit';
import { NotWhiteLabelIFrame } from 'components/Optional';

import { getUrlWithUTM } from 'helpers/utm';
import { isInsideIFrame } from 'helpers/browser';
import { isWhiteLabelFromURL } from 'helpers/common';

import { getBrandOptions } from 'modules/Branding';
import { Layout } from './Layout';

interface ParamTypes {
  roomAlias: string,
}

const ThanksPage: VoidFunctionComponent = () => {
  const { t } = useTranslation();
  const params = useParams<ParamTypes>();
  const { roomAlias } = params;

  return (
    <Layout withBackground={!isWhiteLabelFromURL()}>
      {isInsideIFrame() ? (
        <FigmaTypographyBR text={t('thanksPage.commonThanks')} />
      ) : (
        <FigmaTypography>
          <a href={getUrlWithUTM(`/room/${roomAlias}`)}>{t('thanksPage.repeatCall')}</a>
        </FigmaTypography>
      )}
      <NotWhiteLabelIFrame>
        <FigmaTypography>
          {t('thanksPage.text', { brand: t(getBrandOptions().name) })}
        </FigmaTypography>
      </NotWhiteLabelIFrame>
    </Layout>
  );
};

export default ThanksPage;
