import React, { FunctionComponent } from 'react';
import { secondsToMinutes } from 'date-fns';

import i18n from 'services/i18n';
import useRestTime from 'components/Toasts/TimeLimitWarning/useRestTime';

import { TimeBox } from './WarningMessage.styled';

interface WarningMessageProps {
  endTime: Date;
  kickOutTimeSec: number;
  isModerator: boolean;
  freePlanName: string;
}

const WarningMessage: FunctionComponent<WarningMessageProps> = ({
  endTime, kickOutTimeSec, isModerator, freePlanName,
}) => {
  const { minutes, seconds } = useRestTime(endTime);
  const kickOutTimeMin = secondsToMinutes(kickOutTimeSec);
  const message2 = i18n.t('common.warnings.callTimeRestrictionWarning.message2');

  return (
    <div>
      {isModerator && i18n.t('common.warnings.callTimeRestrictionWarning.message1', { freePlanName, kickOutTimeMin })}
      <div>
        {!!message2 && (
          <span style={{ marginRight: 3 }}>
            {message2}
          </span>
        )}
        <TimeBox>
          <div style={{ marginLeft: 'auto', width: 'min-content' }}>{`${minutes}:${seconds}`}</div>
        </TimeBox>
        {i18n.t('common.warnings.callTimeRestrictionWarning.message3')}
      </div>
    </div>
  );
};

export default WarningMessage;
