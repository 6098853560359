import styled from '@emotion/styled';

export const LoaderContainer = styled.div({
  position: 'absolute',
  left: '50%',
  top: '50%',
  width: 80,
  height: 80,
  transform: 'translate(-50%, -50%)',
});
