import styled from '@emotion/styled';

import { Font, ZIndex } from 'ui-kit/theme';

export const Layout = styled.div({
  height: '100dvh',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ErrorText = styled.div({
  maxWidth: 800,
  fontFamily: Font.BRAND_BOLD,
  fontSize: 32,
  lineHeight: '60px',
  zIndex: ZIndex.ROOM_FOREGROUND,
  textAlign: 'center',
});

export const HintText = styled.div({
  maxWidth: 600,
  fontFamily: Font.BRAND,
  fontSize: 22,
  fontWeight: 400,
  lineHeight: 'initial',
  zIndex: ZIndex.ROOM_FOREGROUND,
  textAlign: 'center',
});

export const CustomElement = styled.div({
  marginTop: 16,
  zIndex: ZIndex.ROOM_FOREGROUND,
});
