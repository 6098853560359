import RingBuffer from './RingBuffer';

class FPSStats {
  private fps: RingBuffer<number>;

  private periodInSec: number;

  private fpsLowest = 0;

  private startTime = 0;

  private framesCount = 0;

  private timerHandle: NodeJS.Timer | null = null;

  private frameTick = () => {
    /* we are calculating number of frames in 1 second */
    const time = performance.now();
    this.framesCount += 1;

    if (time - this.startTime > 1000) {
      const fps = (this.framesCount / ((time - this.startTime) / 1000));
      if (fps < this.fpsLowest) {
        this.fpsLowest = fps;
      }

      this.fps.push(fps);
      return;
    }

    window.requestAnimationFrame(this.frameTick);
  };

  constructor(period: number, samplesSize: number) {
    this.fps = new RingBuffer<number>(samplesSize);
    this.periodInSec = period;
  }

  start() {
    this.timerHandle = setInterval(() => {
      this.startTime = performance.now();
      this.framesCount = 0;
      window.requestAnimationFrame(this.frameTick);
    }, this.periodInSec * 1000);
  }

  stop() {
    if (this.timerHandle) {
      clearInterval(this.timerHandle);
      this.fps.reset();
    }
  }

  average() {
    const avg = this.fps.values.reduce((acc, val) => (acc + val), 0) / this.fps.size();
    return Math.round(avg * 100) / 100;
  }

  lowest() {
    return this.fpsLowest;
  }
}

export default FPSStats;
