import React from 'react';
import { observer } from 'mobx-react-lite';

import useStores from 'stores/root';
import i18n from 'services/i18n';

import ImageCropperDialog, { ImageCropperDialogProps } from 'components/AvatarUploader/ImageCropperDialog';
import { ImageCropperState } from 'types/common';
import UserAvatarPreview from './UserAvatarPreview';
import SpaceLogoPreview from './SpaceLogoPreview';

const cropperOptions: Record<ImageCropperState, ImageCropperDialogProps> = {
  [ImageCropperState.userAvatar]: {
    isCircular: true,
    aspect: 1 / 1,
    previewComponent: <UserAvatarPreview />,
    title: i18n.t('userProfile.thumbnailSelection'),
    cropperTitle: i18n.t('userProfile.selectArea'),
    previewTitle: i18n.t('userProfile.preview'),
  },
  [ImageCropperState.spaceLogo]: {
    isCircular: false,
    previewComponent: <SpaceLogoPreview />,
    title: i18n.t('admin.spaces.logoCropper.title'),
    cropperTitle: i18n.t('admin.spaces.logoCropper.cropperTitle'),
    previewTitle: i18n.t('admin.spaces.logoCropper.previewTitle'),
  },
};

const ImageCropper = observer(() => {
  const { uiStore } = useStores();

  if (!uiStore.imageCropperState) {
    return null;
  }

  return (
    <ImageCropperDialog
      {...cropperOptions[uiStore.imageCropperState]}
      onClose={() => uiStore.closeImageCropper()}
    />
  );
});

export default ImageCropper;
