import {
  action, computed, makeAutoObservable, observable,
} from 'mobx';

import { getExceptionMessage } from 'helpers/errors';

import Oauth2ClientApi from '../services/MoodHoodApiClient/space/Oauth2ClientApi';
import { Errors } from '../constants/errors';

class Oauth2ClientStore {
  @observable private readonly oauth2ClientApi: Oauth2ClientApi;

  @observable private oauth2ClientId?: string;

  @observable private oauth2ClientSecret?: string;

  @observable private isOauth2ClientExists?: boolean;

  constructor(oauth2ClientApi: Oauth2ClientApi) {
    this.oauth2ClientApi = oauth2ClientApi;
    makeAutoObservable(this);
  }

  @computed get clientId(): string | undefined {
    return this.oauth2ClientId;
  }

  @computed get clientSecret(): string | undefined {
    return this.oauth2ClientSecret;
  }

  @computed get isExists(): boolean {
    return !!this.isOauth2ClientExists;
  }

  @action setOauth2ClientId(oauth2ClientId?: string): void {
    this.oauth2ClientId = oauth2ClientId;
  }

  @action setOauth2Secret(oauth2ClientSecret?: string): void {
    this.oauth2ClientSecret = oauth2ClientSecret;
  }

  @action setIsOauth2ClientExists(isOauth2ClientExists: boolean): void {
    this.isOauth2ClientExists = isOauth2ClientExists;
  }

  @action async get(spaceId: string, errorCb: (message: string) => void): Promise<void> {
    try {
      const { clientId } = await this.oauth2ClientApi.get(spaceId);
      this.setOauth2ClientId(clientId);
      this.setIsOauth2ClientExists(true);
    } catch (error) {
      const message = getExceptionMessage(error);
      if (message === Errors.NOT_FOUND) {
        return;
      }

      errorCb(message);
    }
  }

  @action async create(spaceId: string, errorCb: (message: string) => void): Promise<void> {
    try {
      const { clientId, clientSecret } = await this.oauth2ClientApi.create(spaceId);
      this.setOauth2ClientId(clientId);
      this.setOauth2Secret(clientSecret);
      this.setIsOauth2ClientExists(true);
    } catch (error) {
      const message = getExceptionMessage(error);
      errorCb(message);
    }
  }

  @action async delete(spaceId: string, errorCb: (message: string) => void): Promise<void> {
    try {
      await this.oauth2ClientApi.delete(spaceId);
      this.setOauth2ClientId(undefined);
      this.setOauth2Secret(undefined);
      this.setIsOauth2ClientExists(false);
    } catch (error) {
      const message = getExceptionMessage(error);
      errorCb(message);
    }
  }
}

export default Oauth2ClientStore;
