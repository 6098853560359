import { IParseOptions, parse } from 'qs';

interface SearchParamsToObjectOptions extends IParseOptions {
  convertStrings?: boolean;
  ignoreQueryPrefix?: boolean;
}

interface HttpUrlOptions {
  protocolRequired: boolean,
}

export const searchParamsToObject = (
  str?: string,
  options: SearchParamsToObjectOptions = {},
) => {
  const { convertStrings, ...rest } = options;
  if (!str) {
    return {};
  }
  return parse(str, { strictNullHandling: true, ...rest });
};

export const isValidHttpUrl = (url: string, options: HttpUrlOptions = { protocolRequired: true }): boolean => {
  try {
    const hasHttpProtocol = url.match(/^(?:http[s]?:\/\/)/);
    if (!hasHttpProtocol && !options.protocolRequired) {
      return isValidHttpUrl(`https://${url}`);
    }

    const newUrl = new URL(url);
    return newUrl.hostname.includes('.') && (newUrl.protocol === 'http:' || newUrl.protocol === 'https:');
  } catch (err) {
    return false;
  }
};

export const getProdamusDomain = (url?: string) => {
  if (!url) {
    return null;
  }

  const reSubdomain = /^(?:http[s]?:\/\/)?([a-zA-Z0-9_-]+\.payform\.ru)\/?/i;
  const match = url.match(reSubdomain);
  if (!match || match?.length !== 2) {
    return null;
  }

  return match[1];
};
