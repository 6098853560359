import React, {
  FunctionComponent, ComponentProps, ReactElement, useState, useEffect,
} from 'react';
import { DropDownButton } from 'ui-kit';
import { DropDownButtonProps } from 'ui-kit/components/DropDownButton';
import { StatsId } from 'types/stats';
import { CheckedIcon, RightMiniArrowIcon } from 'ui-kit/assets/icons';

import {
  MenuItemLayout,
  IconContainer,
  ChildContainer,
} from './Layout';

export interface MenuItemProps extends ComponentProps<'li'> {
  id: string;
  icon?: JSX.Element;
  disabled?: boolean;
  color?: string;
  menu?: ReactElement;
  isSelected?: boolean;
  checkMark?: boolean;
  dropDownButtonProps?: Omit<DropDownButtonProps, 'id'>;
  variant?: 'red';
  'data-stats'?: StatsId;
}

const MenuItem: FunctionComponent<MenuItemProps> = ({
  icon,
  children,
  menu,
  id,
  color,
  dropDownButtonProps = {},
  checkMark,
  isSelected: isSelectedInitial = false,
  ...props
}) => {
  const [isSelected, setIsSelected] = useState(isSelectedInitial);

  useEffect(() => {
    setIsSelected(isSelectedInitial);
  }, [isSelectedInitial]);

  return (menu ? (
    <MenuItemLayout
      isSelected={isSelected}
      data-selected={Number(isSelected)}
      color={color}
      {...props}
    >
      <DropDownButton
        id={id}
        subVariant="menuItem"
        size="small"
        menu={menu}
        iconColor={color}
        isVisibleCallback={setIsSelected}
        {...dropDownButtonProps}
      >
        {icon && (
          <IconContainer>
            {icon}
          </IconContainer>
        )}
        <ChildContainer>
          {children}
          <RightMiniArrowIcon />
        </ChildContainer>
      </DropDownButton>
    </MenuItemLayout>
  ) : (
    <MenuItemLayout
      id={id}
      isSelected={isSelected}
      data-selected={Number(isSelected)}
      color={color}
      {...props}
    >
      {(icon || (isSelected && checkMark)) && (
        <IconContainer>
          {icon ?? <CheckedIcon />}
        </IconContainer>
      )}
      <span>{children}</span>
    </MenuItemLayout>
  ));
};

export default MenuItem;
