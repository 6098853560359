import { TrackLabel } from '@livedigital/client/dist/types/common';
import PeerStore from './peer';
import logger from '../helpers/logger';

interface PublisherStoreConstructor {
  producerId: string,
  kind: 'video' | 'audio',
  label: TrackLabel,
  peerStore: PeerStore;
  paused: boolean;
}

class PublisherStore {
  public readonly producerId: string;

  public readonly kind: 'video' | 'audio';

  public readonly label: TrackLabel;

  public isSubscribed = false;

  private readonly peerStore: PeerStore;

  private isSubscriptionInProgress = false;

  private paused: boolean;

  constructor({
    producerId,
    kind,
    label,
    peerStore,
    paused,
  }: PublisherStoreConstructor) {
    this.peerStore = peerStore;
    this.kind = kind;
    this.label = label;
    this.producerId = producerId;
    this.paused = paused;
  }

  get isPaused() {
    return this.paused;
  }

  setIsPaused(value: boolean) {
    this.paused = value;
  }

  async subscribe(): Promise<void> {
    if (this.peerStore.isMe || this.isSubscribed || this.isSubscriptionInProgress) {
      return;
    }

    try {
      this.isSubscriptionInProgress = true;
      await this.peerStore.engine.subscribe({
        producerId: this.producerId,
        muted: this.kind === 'video' || (this.peerStore.isAudioStreamingAllowed === false && this.kind === 'audio'),
      });
      this.isSubscribed = true;
    } catch (error) {
      logger.error('Error subscribe media', {
        error,
        case: 'subscribe',
        peerId: this.peerStore.id,
        producerId: this.producerId,
        trackLabel: this.label,
      });
    } finally {
      this.isSubscriptionInProgress = false;
    }
  }
}

export default PublisherStore;
