import styled from '@emotion/styled';

import { Font } from 'ui-kit/theme';

export const Cards = styled.div({
  flexGrow: 1,
  display: 'grid',
  height: 0,
  overflowY: 'auto',
  overflowX: 'hidden',
  gap: 30,
  gridTemplateColumns: 'repeat(auto-fill, 370px)',
  gridAutoRows: 'min-content',
  marginRight: -32,
  paddingLeft: 8,
  paddingTop: 14,
  paddingBottom: 14,
});

export const Header = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 14,
  gap: 12,
});

export const Title = styled.input(({ readOnly, value }) => ({
  flexGrow: 1,
  padding: 0,
  margin: 0,
  marginLeft: 12,
  border: 'none',
  background: 'none',
  fontFamily: Font.BRAND,
  fontSize: 48,
  fontWeight: 500,
  color: 'inherit',
  verticalAlign: 'middle',
  cursor: readOnly ? 'default' : 'text',
  ...readOnly && { maxWidth: String(value).length * 26 },
  '&:focus': {
    outline: 'none',
    border: 'none',
  },
  '&::placeholder': {
    paddingLeft: 12,
    fontSize: '20px',
    lineHeight: '48px',
    color: '#6C747E',
    transform: 'translate(0, -10px)',
  },
}));

export const HelpIconContainer = styled.div({
  marginRight: 'auto',
});

const Layout = styled.div(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  width: '100%',
  color: theme.colors.primary,
  fontFamily: Font.BRAND,
  paddingBottom: 28,
}));

export default Layout;
