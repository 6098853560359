import styled from '@emotion/styled';

import {
  FigmaTypography,
} from 'ui-kit';

export const Alert = styled.div`
  width: 100%;
  padding: 10px 16px 10px 10px;
  background: ${({ theme }) => theme.colors.error01};
  border-radius: 8px;
  border: 1px ${({ theme }) => theme.colors.errorBase} solid;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
  margin-bottom: 24px;
`;

export const ContentContainer = styled.div`
  flex: 1 1 0;
  height: 63px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
`;

export const IconContainer = styled.div`
  padding-top: 6px;
  padding-bottom: 6px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  color: ${({ theme }) => theme.colors.errorBase};
`;

export const TextAndButtons = styled.div`
  flex: 1 1 0;
  padding-top: 6px;
  padding-bottom: 6px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: inline-flex;
`;

export const AlertMessage = styled.div`
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
  display: flex;
`;

export const TitleContainer = styled.div`
  align-self: stretch;
  height: 25px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
  display: flex;
`;

export const Title = styled(FigmaTypography)`
  color: ${({ theme }) => theme.colors.primary};
`;

export const TextContainer = styled.div`
  align-self: stretch;
  padding-top: 2px;
  padding-bottom: 2px;
  justify-content: flex-start;
  align-items: center;
  display: inline-flex;
`;

export const Text = styled(FigmaTypography)`
  flex: 1 1 0;
  color: ${({ theme }) => theme.colors.primary};
`;
