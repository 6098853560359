import React, {
  FunctionComponent, useRef, useCallback, useEffect, HTMLAttributes,
} from 'react';
import isMobile from 'is-mobile';
import { css, cx } from '@emotion/css';
import { useTheme } from '@emotion/react';

import { ArrowLeftIcon, CloseRegularIcon } from 'ui-kit/assets/icons';
import useClickOutside from 'hooks/useClickOutside';
import usePrevious from 'hooks/usePrevious';

import * as DialogLayoutDesktop from './DialogLayoutDesktop';
import * as DialogLayoutMobile from './DialogLayoutMobile';
import { DialogProps, DialogCloseReason } from './types';
import Portal from '../Portal';
import { TitleHolder } from './Layout';
import FigmaTypography from '../FigmaTypography';
import IconButton from '../IconButton';

const Dialog: FunctionComponent<DialogProps & Omit<HTMLAttributes<HTMLDivElement>, 'title'>> = ({
  children,
  fixedPosition = false,
  canClose = true,
  onClose,
  className,
  isHidden = false,
  hasBackButton = isMobile(),
  backButtonId = 'dialog-back-button',
  backButtonHandler,
  isUseClickOutsideDisabled,
  title,
  noBackCover = false,
  isCompact,
  style,
  contentClassName,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => {
    if (canClose) {
      onClose?.(DialogCloseReason.ClickOutside);
    }
  }, isUseClickOutsideDisabled || isHidden);

  const prevIsVisible = usePrevious(!isHidden);
  const isVisible = !isHidden;

  const handleOverlayClick = useCallback((event:React.MouseEvent) => {
    if (!canClose) {
      event.stopPropagation();
    }
  }, [canClose]);

  const handleClose = useCallback(() => {
    onClose?.(DialogCloseReason.CloseButton);
  }, [onClose]);

  useEffect(() => {
    if (fixedPosition && ref.current) {
      const content = ref.current;
      const { top } = content.getBoundingClientRect();
      Object.assign(content.style, { position: 'absolute', top: `${top}px` });
    }
  }, []);

  const theme = useTheme();

  if (isHidden) {
    return null;
  }

  const stringTitle = isMobile() ? (
    <FigmaTypography size="h2" center as="div">
      {title}
    </FigmaTypography>
  ) : (
    <FigmaTypography size="h2" color={theme.colors.primary}>
      {title}
    </FigmaTypography>
  );
  const dialogTitle = typeof title === 'string' ? stringTitle : title;

  if (isMobile()) {
    return (
      <Portal>
        <DialogLayoutMobile.Layout
          ref={ref}
          className={cx(className, 'animate__animated animate__faster', {
            animate__fadeInUp:
            prevIsVisible !== isVisible && isVisible,
            animate__fadeOutDown:
            prevIsVisible !== isVisible && !isVisible,
            [css`display: none`]:
            prevIsVisible === isVisible && !isVisible,
          })}
        >
          {(hasBackButton && (backButtonHandler || onClose)) && (
            <DialogLayoutMobile.BackButton id={backButtonId} onClick={backButtonHandler || handleClose}>
              <ArrowLeftIcon />
            </DialogLayoutMobile.BackButton>
          )}
          {title && dialogTitle}
          {children}
        </DialogLayoutMobile.Layout>
      </Portal>
    );
  }

  return (
    <Portal>
      <DialogLayoutDesktop.DialogRoot
        isHidden={isHidden}
        canClose={canClose}
        onMouseDownCapture={handleOverlayClick}
        className={className}
        noBackCover={noBackCover}
      >
        <DialogLayoutDesktop.DialogContent
          canClose={canClose}
          ref={ref}
          isCompact={isCompact}
          style={style}
          className={contentClassName}
        >
          {title && (
            <TitleHolder>
              {hasBackButton && backButtonId && (
              <IconButton id={backButtonId} size="small" variant="shadow" onClick={backButtonHandler || handleClose}>
                <ArrowLeftIcon />
              </IconButton>
              )}
              {dialogTitle}
              {canClose && (
                <DialogLayoutDesktop.CloseButton onClick={handleClose} id="dialog-close">
                  <CloseRegularIcon />
                </DialogLayoutDesktop.CloseButton>
              )}
            </TitleHolder>
          )}
          {children}
        </DialogLayoutDesktop.DialogContent>
      </DialogLayoutDesktop.DialogRoot>
    </Portal>
  );
};

export default Dialog;
