import styled from '@emotion/styled';
import { ChatProps } from './types';

const Layout = styled.div<ChatProps>(({ isVisible }) => `
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  margin-top: 0;
  display: ${isVisible ? 'flex' : 'none'};
  overflow: hidden;
  position: relative;
`);

export default Layout;
