import styled from '@emotion/styled';

import { MediaBreakPoint } from 'ui-kit/theme';
import {
  Input as DefaultInput,
  IconButton,
  Tooltip as TooltipBase,
} from 'ui-kit';

export const DesktopLayout = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: 424,

  [MediaBreakPoint.WIDTH_640]: {
    width: '100%',
  },
});

const InfoBlock = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.secondary01,
  borderRadius: 10,
}));

export const Inputs = styled(InfoBlock)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginTop: 24,
  padding: 24,
});

export const Input = styled(DefaultInput)({
  position: 'relative',
  rowGap: 24,
  '& > input': {
    '&:disabled': {
      backgroundColor: 'inherit',
    },
  },
});

export const InputButton = styled(IconButton)({
  marginRight: 4,
  width: 120,
  height: 40,
});

export const SectionTitle = styled('div')({
  marginBottom: 8,
  fontSize: 16,
});

export const Tooltip = styled(TooltipBase)`
  max-width: 300px;
`;
