import React, { FunctionComponent, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import ReactS3Uploader, { S3Response } from 'react-s3-uploader';
import { toast } from 'react-toastify';

import { Loader } from 'ui-kit';

import {
  ChangePhotoWrapper,
  RemovePhotoButton,
  SelectImage,
  TrashIcon,
} from './index.styled';

interface ImageUploaderProps {
  id: string;
  hasImage: boolean;
  onSaveCroppedImage?: () => void;
  onRemoveImage: () => void;
  onError: (message: string) => void;
  onPublicUrl: (url: string) => void;
  kindOfImages?: string; // sort of images (avatar, photo etc.), needs for creating directory on cloud. Default: images
  onFileLoaded: (img: string | ArrayBuffer | null) => void;
  preprocessImage: (file: File) => Promise<File | null>;
  signFile:(file: File) => Promise<SignInfo>;
  onPreprocessComplete:(origfile: File, preprocessedFile: File | null) => void;
}

export interface SignInfo {
  filename: string;
  publicUrl: string;
  signedUrl: string;
}

const ImageUploader: FunctionComponent<ImageUploaderProps> = observer(({
  id,
  hasImage,
  onRemoveImage,
  onFileLoaded,
  onPreprocessComplete,
  onError,
  onPublicUrl,
  preprocessImage,
  signFile,
  children,
}) => {
  const [isFileUploading, setIsFileUploading] = useState(false);
  const { t } = useTranslation();
  const uploaderRef = useRef<HTMLInputElement | null>(null);

  const resetFile = () => {
    if (uploaderRef.current) {
      uploaderRef.current.value = '';
    }
  };

  const handlePreprocess = async (file: File, next: (file: File) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => onFileLoaded(reader.result), { once: true });
    reader.readAsDataURL(file);

    const nextFile = preprocessImage ? await preprocessImage(file) : file;
    onPreprocessComplete?.(file, nextFile);

    if (nextFile) {
      next(nextFile);
    } else {
      resetFile();
    }
  };

  const handleRemoveImage = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onRemoveImage();
  };

  const getSignedUrl = async (file: File, callback: (params: S3Response) => void) => {
    setIsFileUploading(true);
    const signInfo = await signFile(file);
    if (signInfo) {
      const { signedUrl, publicUrl, filename } = signInfo;
      const callbackData: S3Response = {
        signedUrl,
        publicUrl,
        filename,
        fileKey: '',
      };
      callback(callbackData);
    } else {
      toast.error(t('admin.assets.uploadFail'));
    }
  };

  const handleFinish = ({ publicUrl }: S3Response): void => {
    // please don't put onGetPublicUrl function here because if previous upload wasn't finished
    // next upload will trigger this function (it go to closure or something)
    setIsFileUploading(false);
    onPublicUrl(publicUrl);
    resetFile();
  };

  const handleError = (message: string) => {
    setIsFileUploading(false);
    onError(message);
  };

  return (
    <SelectImage
      id={id}
      isUploading={isFileUploading}
      htmlFor="file-input"
    >
      {isFileUploading ? (
        <Loader />
      ) : (
        <>
          {(hasImage) && (
            <RemovePhotoButton id="remove-avatar-btn" onClick={handleRemoveImage}>
              <TrashIcon />
            </RemovePhotoButton>
          )}
          <ChangePhotoWrapper data-stats="ui_profile_change_photo">
            {t(hasImage ? 'common.userPhotoChange' : 'common.userPhotoAdd')}
            <br />
            {t('common.userPhoto')}
          </ChangePhotoWrapper>
          <ReactS3Uploader
            inputRef={(cmp) => { uploaderRef.current = cmp; }}
            id="file-input"
            accept="image/*"
            style={{ display: 'none' }}
            preprocess={handlePreprocess}
            getSignedUrl={getSignedUrl}
            onError={handleError}
            onFinish={handleFinish}
            autoUpload
          />
          {children}
        </>
      )}
    </SelectImage>
  );
});

export default ImageUploader;
