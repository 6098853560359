import { useEffect } from 'react';
import useStores from 'stores/root';
import { useTranslation } from 'react-i18next';

const useLanguage = (): void => {
  const { userStore } = useStores();
  const { i18n } = useTranslation();

  const handleLanguageChange = () => {
    userStore.setCurrentLanguage();
  };

  useEffect(() => {
    handleLanguageChange();
    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);
};

export default useLanguage;
