import styled from '@emotion/styled';

import { FigmaTypography } from 'ui-kit';
import { VariantType, textOverflowStyle, getVariantColor } from 'ui-kit/theme';

export const EventCardRoot = styled.div<{ variant?: VariantType }>(({ variant = 'secondary', theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: 16,
  gap: 8,
  width: '100%',
  minWidth: 0,
  height: 'min-content',
  padding: 16,
  border: 'solid 2px',
  borderColor: getVariantColor(theme, variant),
  borderRadius: 10,
  '&:hover': {
    boxShadow: `inset ${getVariantColor(theme, variant)} 0 0 0 1px`,
  },
}));

export const Buttons = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
});

export const Badges = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
});

export const EventInfo = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  minWidth: 0,
  gap: 8,
});

export const EventHeader = styled.div({
  display: 'flex',
  flexDirection: 'row',
  minWidth: 0,
  gap: 8,
});

export const EventDescription = styled(FigmaTypography)(({ theme }) => ({
  color: theme.colors.secondaryBase,
}));

export const EventParticipants = styled(FigmaTypography)({
  color: 'initial',
  marginLeft: 4,
});

export const TimeZone = styled(FigmaTypography)({
  ...textOverflowStyle,
});

export const DateTime = styled(FigmaTypography)({
  whiteSpace: 'nowrap',
});

export const StoryRoot = styled.div({
  height: '100%',
  width: '100%',
  padding: 10,
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
});
