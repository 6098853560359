import { isEqual } from 'lodash';
import {
  action,
  computed,
  makeAutoObservable,
  observable,
} from 'mobx';
import isMobile from 'is-mobile';

import { AvailableMediaDevices } from '@livedigital/client/dist/types/common';
import { Track } from '@livedigital/client/dist/types/media';
import {
  isPermissionErrorText,
} from 'helpers/browser';
import SpeechRecognize from 'modules/SpeechRecognize';

import { getLocalStorageItem, setLocalStorageItem } from '../helpers/localStorage';
import ParticipantStore from './participant';
import logger from '../helpers/logger';

const LOCAL_STORAGE_KEY_CAMERA = 'camera';
const LOCAL_STORAGE_KEY_AUDIO_OUTPUT = 'audiooutput';
const LOCAL_STORAGE_KEY_MICROPHONE = 'microphone';

class DeviceStore {
  participantStore: ParticipantStore;

  speechRecognize: SpeechRecognize;

  @observable availableVideoDevicesLocal: MediaDeviceInfo[] = [];

  @observable availableAudioDevicesLocal: MediaDeviceInfo[] = [];

  @observable availableAudioOutputDevicesLocal: MediaDeviceInfo[] = [];

  @observable currentAudioDeviceId?: string;

  @observable currentVideoDeviceId?: string;

  @observable currentAudioOutputDeviceId?: string;

  @observable isDevicesLoaded = false;

  @observable deviceIsBusy?: boolean = false;

  @observable cameraError: string | null = null;

  @observable microphoneError: string | null = null;

  @observable detectDevicesError: string | null = null;

  @observable microphoneAudioLevel = 0;

  @observable newDevicesSelected = false;

  @observable shouldEnableCameraAfterJoin = false;

  @observable shouldEnableMicrophoneAfterJoin = false;

  /* separate stream to track mic volume, since current audio input might be disabled
     required by 'talk while muted' feature */
  micAudioTrack?: Track;

  constructor(participantStore: ParticipantStore) {
    this.participantStore = participantStore;
    this.speechRecognize = new SpeechRecognize(this);
    makeAutoObservable(this);

    navigator.mediaDevices.addEventListener('devicechange', () => {
      this.detectAudioOutputDevices();
    });
  }

  async republishMicrophoneTrack({ audio }: AvailableMediaDevices): Promise<void> {
    const oldLabel = this.availableAudioDevices.find(({ deviceId }) => deviceId === 'default')?.label;
    const newLabel = audio.find(({ deviceId }) => deviceId === 'default')?.label;

    if (oldLabel && newLabel && oldLabel === newLabel) {
      return;
    }

    const isMicWatched = Boolean(this.micAudioTrack);

    if (isMicWatched) {
      await this.unWatchMicAudioLevel();
    }

    await this.setCurrentAudioDevice('default');

    if (isMicWatched) {
      await this.watchMicAudioLevel();
    }
  }

  @action setMicrophoneAudioLevel(level: number): void {
    this.speechRecognize.processAudioLevelScores(level);
    if (this.microphoneAudioLevel === level) {
      return;
    }

    this.microphoneAudioLevel = level;
  }

  @action.bound async setCurrentVideoDevice(deviceId: string): Promise<void> {
    if (this.currentVideoDeviceId === deviceId && !this.cameraError) {
      return;
    }

    this.resetCameraError();
    const previousVideoDevice = getLocalStorageItem<string>(LOCAL_STORAGE_KEY_CAMERA);
    if (previousVideoDevice && previousVideoDevice !== deviceId) {
      this.newDevicesSelected = true;
    }

    this.currentVideoDeviceId = deviceId;
    setLocalStorageItem(LOCAL_STORAGE_KEY_CAMERA, deviceId);
    logger.info('Video device selected', {
      deviceId,
      deviceName: this.availableVideoDevices.find((device) => device.deviceId === deviceId)?.label,
    });

    if (this.participantStore.cameraPreviewTrack) {
      await this.participantStore.deleteCameraPreviewTrack();
    }

    const { cameraTrack, peer } = this.participantStore;

    if (cameraTrack?.isPaused && deviceId !== previousVideoDevice) {
      await this.participantStore.unPublishCameraTrack();
    }

    if (peer && cameraTrack?.isPublished && !cameraTrack.isPaused) {
      await this.participantStore.republishCameraTrack();
    }
  }

  async createNewCameraPreviewTrack(): Promise<void> {
    if (this.participantStore.cameraPreviewTrack) {
      await this.participantStore.deleteCameraPreviewTrack();
    }

    await this.participantStore.createCameraPreviewTrack();
  }

  async createCameraPreview() {
    const isJoined = this.participantStore.peer;
    await this.createNewCameraPreviewTrack();
    if (!isJoined && this.participantStore.peer) {
      /* user entered room without waiting camera preview track request fulfilled,
         we should release this track, since its not needed anymore to avoid camera led go on */
      await this.participantStore.deleteCameraPreviewTrack();
    }
  }

  @action.bound async setCurrentAudioDevice(deviceId: string): Promise<void> {
    const { label: deviceName } = this.availableAudioDevices.find((device) => device.deviceId === deviceId) || {};

    const previousAudioDevice = getLocalStorageItem<string>(LOCAL_STORAGE_KEY_MICROPHONE);
    if (previousAudioDevice && previousAudioDevice !== deviceId) {
      this.newDevicesSelected = true;
    }

    this.currentAudioDeviceId = deviceId;
    setLocalStorageItem(LOCAL_STORAGE_KEY_MICROPHONE, deviceId);
    logger.info('Audio device selected', {
      deviceId,
      deviceName,
    });

    const { microphoneTrack, peer } = this.participantStore;
    if (microphoneTrack?.isPaused && deviceId !== previousAudioDevice) {
      await this.participantStore.unPublishMicrophoneTrack();
    }

    if (peer && microphoneTrack?.isPublished && !microphoneTrack?.isPaused) {
      await this.participantStore.unPublishMicrophoneTrack();
      await this.participantStore.publishNewMicrophoneTrack();
    }

    if (!this.participantStore.audioDisabled || !peer) {
      return;
    }

    /* we need to create microphone track on device change even when its disabled */
    /* to set output device for mobile(tablets?) correctly */
    this.participantStore.microphoneTrack?.stopMediaStreamTrack();
    this.participantStore.setMicrophoneTrack(undefined);
    await this.participantStore.createMicrophoneAudioTrack();
  }

  @action.bound async setCurrentAudioOutputDevice(deviceId: string): Promise<void> {
    if (this.currentAudioOutputDeviceId === deviceId || isMobile()) {
      return;
    }

    const previousAudioOutputDevice = getLocalStorageItem<string>(LOCAL_STORAGE_KEY_AUDIO_OUTPUT);
    if (previousAudioOutputDevice && previousAudioOutputDevice !== deviceId) {
      this.newDevicesSelected = true;
    }

    this.currentAudioOutputDeviceId = deviceId;
    setLocalStorageItem(LOCAL_STORAGE_KEY_AUDIO_OUTPUT, deviceId);
    logger.info('Audio output device selected', {
      deviceId,
      deviceName: this.availableAudioOutputDevices.find((device) => device.deviceId === deviceId)?.label,
    });

    if ('setSinkId' in AudioContext.prototype) {
      // eslint-disable-next-line
      // @ts-ignore
      await this.participantStore.rootStore.roomStore.audioContext?.setSinkId(deviceId || '');
    }
  }

  @action.bound setAvailableVideoDevices(availableVideoDevices: MediaDeviceInfo[]): void {
    /* not sorting till default devices task to avoid selection of wrong camera on mobile */
    this.availableVideoDevicesLocal = availableVideoDevices;
  }

  @action.bound setAvailableAudioDevices(availableAudioDevices: MediaDeviceInfo[]): void {
    this.availableAudioDevicesLocal = [
      ...availableAudioDevices.filter((x) => x.deviceId === 'default'),
      ...availableAudioDevices.filter((x) => x.deviceId !== 'default'),
    ];
  }

  @action.bound setAvailableAudioOutputDevices(availableAudioOutputDevices: MediaDeviceInfo[]): void {
    // for safari
    if (availableAudioOutputDevices.length === 0) {
      this.availableAudioOutputDevicesLocal = [{
        deviceId: '',
        groupId: '',
        kind: 'audiooutput',
        label: 'Default',
      } as MediaDeviceInfo];
    } else {
      this.availableAudioOutputDevicesLocal = [
        ...availableAudioOutputDevices.filter((x) => x.deviceId === 'default'),
        ...availableAudioOutputDevices.filter((x) => x.deviceId !== 'default'),
      ];
    }
  }

  @action.bound async useLastUsedAudioDevice(): Promise<void> {
    if (!this.hasAvailableAudioDevice) {
      return;
    }

    const microphoneId = getLocalStorageItem<string>(LOCAL_STORAGE_KEY_MICROPHONE);
    if (microphoneId) {
      const savedMicrophoneIdExists = this.availableAudioDevices
        .find(({ deviceId }) => deviceId === microphoneId);
      if (savedMicrophoneIdExists) {
        await this.setCurrentAudioDevice(microphoneId);

        return;
      }
    }

    if (this.availableAudioDevices[0]?.deviceId) {
      await this.setCurrentAudioDevice(this.availableAudioDevices[0].deviceId);
    }
  }

  @action.bound async useLastUsedAudioOutputDevice(): Promise<void> {
    if (!this.hasAvailableAudioOutputDevice) {
      return;
    }

    const audioOutputId = getLocalStorageItem<string>(LOCAL_STORAGE_KEY_AUDIO_OUTPUT);
    if (audioOutputId) {
      const savedAudioOutputIdExists = this.availableAudioOutputDevices
        .find(({ deviceId }) => deviceId === audioOutputId);

      if (savedAudioOutputIdExists) {
        await this.setCurrentAudioOutputDevice(audioOutputId);

        return;
      }
    }

    if (this.availableAudioOutputDevices[0]?.deviceId) {
      await this.setCurrentAudioOutputDevice(this.availableAudioOutputDevices[0].deviceId);
    }
  }

  @action.bound async useLastUsedVideoDevice(): Promise<void> {
    if (!this.hasAvailableVideoDevice) {
      return;
    }

    const cameraId = getLocalStorageItem<string>(LOCAL_STORAGE_KEY_CAMERA);
    if (cameraId) {
      const savedCameraIdExists = this.availableVideoDevices
        .find(({ deviceId }) => deviceId === cameraId);
      if (savedCameraIdExists) {
        await this.setCurrentVideoDevice(cameraId);

        return;
      }
    }

    if (this.availableVideoDevices[0]?.deviceId) {
      await this.setCurrentVideoDevice(this.availableVideoDevices[0].deviceId);
    }
  }

  getDefaultAudioDeviceById() {
    return this.availableAudioDevicesLocal.find((device) => device.deviceId === 'default');
  }

  getDefaultVideoDeviceById() {
    return this.availableVideoDevicesLocal.find((device) => device.deviceId === 'default');
  }

  getAudioDeviceById(deviceId: string) {
    return this.availableAudioDevicesLocal.find((device) => device.deviceId === deviceId);
  }

  getVideoDeviceById(deviceId: string) {
    return this.availableVideoDevicesLocal.find((device) => device.deviceId === deviceId);
  }

  @action getDefaultAudioDevice() {
    const defaultDevice = this.getDefaultAudioDeviceById();
    if (!defaultDevice) {
      return null;
    }

    const realDevice = this.availableAudioDevices.find(
      (device) => defaultDevice.label.indexOf(device.label) !== -1,
    );

    if (!realDevice) {
      return null;
    }

    return realDevice.deviceId;
  }

  @action autoSelectAudioDevice() {
    this.resetMicrophoneError();
    const defaultDevice = this.getDefaultAudioDevice();

    if (defaultDevice) {
      this.setCurrentAudioDevice(defaultDevice);
      return;
    }

    if (!this.availableAudioDevices.length) {
      this.currentAudioDeviceId = undefined;
      return;
    }

    if (this.availableAudioDevices.length >= 1) {
    // if we have > 1 audio devices and none of them marked as default device(Safari),
    // it is possible to get default device via asking getUserMedia({audio: true}) and track.getSettings(),
    // but it leads to unpredictable page behavior due various Safari bugs(<16.4) with bt headsets
    // for now, just pick the first device
      this.setCurrentAudioDevice(this.availableAudioDevices[0].deviceId);
      return;
    }

    this.currentAudioDeviceId = undefined;
  }

  @action setIsDevicesLoaded(value: boolean): void {
    this.isDevicesLoaded = value;
  }

  @action checkDevicesAccess() {

  }

  @action.bound async hasDevicesAccess(): Promise<boolean> {
    if (process.env.REACT_APP_FOR_ELECTRON) {
      if (!await window.electronApi?.hasCameraAccess()) {
        return false;
      }

      const hasMicAccess = await window.electronApi?.hasMicrophoneAccess();
      return Boolean(hasMicAccess);
    }

    const devices = await navigator.mediaDevices.enumerateDevices();
    if (devices.length === 0) {
      return true;
    }

    /* this checks on browser level, wont detect if allowed in browser, but denied by OS */
    const hasAccess = devices.every((device) => device.deviceId && device.label);

    return hasAccess;
  }

  @action.bound async detectAudioOutputDevices(): Promise<void> {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioOutput = devices.filter((x) => x.kind === 'audiooutput');
    this.setAvailableAudioOutputDevices(audioOutput);
    await this.useLastUsedAudioOutputDevice();
  }

  @action.bound async detectDevices(force?: boolean): Promise<void> {
    if ((this.isDevicesLoaded && !(this.hasDetectDevicesError || this.hasDeviceError)) && !force) {
      return;
    }

    this.resetCameraError();
    this.resetMicrophoneError();
    if (!force) {
      this.setIsDevicesLoaded(false);
      this.currentAudioDeviceId = undefined;
      this.currentVideoDeviceId = undefined;
    }
    try {
      // electron detects devices even if access is denied
      const { audio, video } = await this.participantStore.client.detectDevices(force);
      /* TBD: check if new devices are equals to current and skip the rest */
      await this.detectAudioOutputDevices();

      this.setAvailableVideoDevices(video);
      this.setAvailableAudioDevices(audio);

      await Promise.allSettled([
        this.useLastUsedVideoDevice(),
        this.useLastUsedAudioDevice(),
        this.useLastUsedAudioOutputDevice(),
      ]);

      this.resetDetectDevicesError();
    } catch (error) {
      logger.error('Failed to detect devices', {
        error,
        peerId: this.participantStore.client.id,
        case: 'detectDevices',
      });

      if (error.message === 'NotAllowedError') {
        this.setDetectDevicesError('NotAllowedError');
      } else if (error.message === 'NotFoundError') {
        this.setDetectDevicesError('NoDevices');
      } else if (error.message === 'NoDevices') {
        this.setDetectDevicesError('NoDevices');
      } else if (error.message === 'DeviceIsBusy') {
        this.deviceIsBusy = true;
      } else {
        this.setDetectDevicesError((error as Error).message);
      }
    }

    this.setIsDevicesLoaded(true);
  }

  @action async switchToNextVideoDevice() {
    if (this.availableVideoDevices.length < 2) {
      return;
    }

    const currentDeviceIndex = this.availableVideoDevices
      .findIndex((x) => x.deviceId === this.currentVideoDeviceId);

    const nextId = (currentDeviceIndex + 1) % this.availableVideoDevices.length;

    const nextVideoDevice = this.availableVideoDevices[nextId];
    await this.setCurrentVideoDevice(nextVideoDevice.deviceId);
  }

  @action async switchToNextAudioDevice(): Promise<boolean> {
    if (this.availableAudioDevices.length < 2) {
      return false;
    }

    const currentDeviceIndex = this.availableAudioDevices
      .findIndex((x) => x.deviceId === this.currentAudioDeviceId);

    const nextId = (currentDeviceIndex + 1) % this.availableAudioDevices.length;

    const nextAudioDevice = this.availableAudioDevices[nextId];

    await this.setCurrentAudioDevice(nextAudioDevice.deviceId);

    return true;
  }

  @action async handleDeviceListUpdated(devices: AvailableMediaDevices): Promise<void> {
    if (this.currentAudioDeviceId === 'default') {
      await this.republishMicrophoneTrack(devices);
    }

    /* safari has issues with headset disconnect and auto selection of another another device after that */
    /* audio mixer starts to produce audio noise(robovoice, etc) */

    if (!isEqual(this.availableVideoDevicesLocal, devices.video)) {
      let video = [...devices.video];
      if (process.env.REACT_APP_FOR_ELECTRON) {
        if (!await window.electronApi?.hasCameraAccess()) {
          video = [];
        }
      }

      this.setAvailableVideoDevices(video);
    }

    if (!isEqual(this.availableAudioDevicesLocal, devices.audio)) {
      let audio = [...devices.audio];
      if (process.env.REACT_APP_FOR_ELECTRON) {
        if (!await window.electronApi?.hasMicrophoneAccess()) {
          audio = [];
        }
      }

      this.setAvailableAudioDevices(audio);
      if (!this.currentAudioDeviceId || !this.getAudioDeviceById(this.currentAudioDeviceId)) {
        this.autoSelectAudioDevice();
      }
    }
  }

  @action async unWatchMicAudioLevel(): Promise<void> {
    this.resetMicrophoneError();

    if (!this.micAudioTrack) {
      return;
    }

    try {
      const { micAudioTrack } = this;
      this.setMicAudioTrack(undefined);
      this.participantStore.rootStore.audioLevelWatcherStore.stopWatchAudioLevel(micAudioTrack?.mediaStreamTrack);
      await this.participantStore.client.deleteTrack(micAudioTrack);
    } catch (error: unknown) {
      logger.error('Failed to stop watching mic sound level', { error });
    }
  }

  @action async watchMicAudioLevel(noiseSuppression = false): Promise<void> {
    try {
      await this.unWatchMicAudioLevel();

      const track = await this.participantStore.client.createMicrophoneAudioTrack({
        audio: { deviceId: this.currentAudioDeviceId },
        noiseSuppression,
      });

      this.setMicAudioTrack(track);
      this.participantStore.rootStore.audioLevelWatcherStore.watchAudioLevel(
        track.mediaStreamTrack,
        this.setMicrophoneAudioLevel.bind(this),
      );

      track.mediaStreamTrack.addEventListener('ended', () => {
        if (this.micAudioTrack && !this.participantStore.isEnableAudioLock
          && !this.participantStore.rootStore.roomStore.isRoomReJoining
          && !this.participantStore.rootStore.uiStore.isLeavingRoom) {
          this.setMicrophoneError('Unexpected track end due device failure or permission denial');
        }
      });
    } catch (error) {
      logger.error('Failed to watch mic sound level', { error });
      this.setMicrophoneError((error instanceof Error) ? error.message : String(error));
    }
  }

  @action setMicAudioTrack(track?: Track): void {
    this.micAudioTrack = track;
  }

  @action setCameraError(error: string): void {
    this.cameraError = error;
  }

  @action resetCameraError(): void {
    this.cameraError = null;
  }

  @action setMicrophoneError(error: string): void {
    this.microphoneError = error;
  }

  @action resetMicrophoneError(): void {
    if (this.microphoneError === null) {
      return;
    }

    this.microphoneError = null;
  }

  @action setDetectDevicesError(error: string | null): void {
    this.detectDevicesError = error;
  }

  @action resetDetectDevicesError(): void {
    this.detectDevicesError = null;
  }

  @action setShouldEnableCameraAfterJoin(value: boolean): void {
    this.shouldEnableCameraAfterJoin = value;
  }

  @action setShouldEnableMicrophoneAfterJoin(value: boolean): void {
    this.shouldEnableMicrophoneAfterJoin = value;
  }

  @computed get hasAvailableAudioDevice(): boolean {
    return this.availableAudioDevices.length > 0;
  }

  @computed get hasAvailableAudioOutputDevice(): boolean {
    return this.availableAudioOutputDevices.length > 0;
  }

  @computed get hasAvailableVideoDevice(): boolean {
    return this.availableVideoDevices.length > 0;
  }

  @computed get availableVideoDevices(): MediaDeviceInfo[] {
    return this.availableVideoDevicesLocal;
  }

  @computed get availableAudioDevices(): MediaDeviceInfo[] {
    return this.availableAudioDevicesLocal;
  }

  @computed get availableAudioOutputDevices(): MediaDeviceInfo[] {
    return this.availableAudioOutputDevicesLocal;
  }

  @computed get hasDeviceError(): boolean {
    return Boolean(this.cameraError || this.microphoneError);
  }

  @computed get hasDetectDevicesError(): boolean {
    return Boolean(this.detectDevicesError);
  }

  @computed get hasAudioPermissionError(): boolean {
    return isPermissionErrorText(this.microphoneError);
  }

  @computed get hasDevicePermissionError(): boolean {
    return isPermissionErrorText(this.cameraError)
            || isPermissionErrorText(this.microphoneError)
            || isPermissionErrorText(this.detectDevicesError);
  }

  @computed get hasDeviceTrackError(): boolean {
    return this.isDeviceErrorTextContainsText(['track']);
  }

  @computed get hasNoDevicesError(): boolean {
    return this.detectDevicesError === 'NoDevices';
  }

  @computed get canAccessAudioDevice(): boolean {
    return this.hasAvailableAudioDevice && !this.microphoneError;
  }

  @computed get canAccessVideoDevice(): boolean {
    return this.hasAvailableVideoDevice && !this.cameraError;
  }

  private isDeviceErrorTextContainsText(words: string[]): boolean {
    const hasError = (error: string | null) => {
      if (error) {
        const errorText = error.toLocaleLowerCase();

        return words.some((s) => errorText.includes(s));
      }

      return false;
    };

    return hasError(this.cameraError) || hasError(this.microphoneError) || hasError(this.detectDevicesError);
  }
}

export default DeviceStore;
