import styled from '@emotion/styled';

import { Color, Font, overlayStyle } from 'ui-kit/theme';
import { TrashIcon as TrashIconDefault } from 'ui-kit/assets/icons';

export const SelectImage = styled.label<{ isUploading: boolean }>(({ isUploading }) => ({
  position: 'absolute',
  zIndex: 1,
  left: 0,
  top: 0,
  ...overlayStyle,
  visibility: isUploading ? 'visible' : 'hidden',
  cursor: 'pointer',
  backgroundColor: 'rgba(0,0,0,0.7)',
  borderRadius: 'inherit',
}));

export const TrashIcon = styled(TrashIconDefault)({
  color: Color.RED0,
});

export const RemovePhotoButton = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  zIndex: 2,
  cursor: 'pointer',
  top: -8,
  right: -8,
  width: 32,
  height: 32,
  borderRadius: '50%',
  backgroundColor: Color.GREY20,
  '&:hover': {
    backgroundColor: Color.GREY17,
  },
});

export const ChangePhotoWrapper = styled.div(({ theme }) => ({
  textAlign: 'center',
  color: theme.colors.contrast,
  fontSize: 13,
  fontFamily: Font.BRAND_BOLD,
}));
