import { useRef, useEffect } from 'react';

type TimerType = 'interval' | 'timeout';

const useTimer = (
  callback:() => unknown, period: number, type: TimerType = 'interval',
): [typeof startTimer, typeof stopTimer, typeof getTimerId] => {
  const timerHandle = useRef<NodeJS.Timer | null>(null);

  const setTimer = type === 'interval' ? setInterval : setTimeout;
  const clearTimer = type === 'interval' ? clearInterval : clearTimeout;

  const stopTimer = (): void => {
    if (timerHandle.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      clearTimer(timerHandle.current);
      timerHandle.current = null;
    }
  };

  const startTimer = (): void => {
    if (timerHandle.current) {
      stopTimer();
    }

    timerHandle.current = setTimer(callback, period);
  };

  const getTimerId = () => timerHandle.current;

  useEffect(() => () => stopTimer(), []);

  return [startTimer, stopTimer, getTimerId];
};

export default useTimer;
