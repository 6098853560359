import { AxiosInstance } from 'axios';

import { getAxiosErrorMessage } from 'helpers/errors';

import { CreatePersonalTokenResponse, PersonalTokensListResponse } from '../types';

class PersonalTokenApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async getAllTokens(): Promise<PersonalTokensListResponse> {
    try {
      const { data } = await this.api.get<PersonalTokensListResponse>('/auth/personal-tokens');
      return data;
    } catch (error) {
      throw new Error(getAxiosErrorMessage(error));
    }
  }

  async createToken(): Promise<CreatePersonalTokenResponse> {
    try {
      const { data } = await this.api.post<CreatePersonalTokenResponse>('/auth/personal-tokens');
      return data;
    } catch (error) {
      throw new Error(getAxiosErrorMessage(error));
    }
  }

  async revokeToken(tokenId: string): Promise<void> {
    try {
      await this.api.delete(`/auth/personal-tokens/${tokenId}`);
    } catch (error) {
      throw new Error(getAxiosErrorMessage(error));
    }
  }
}

export default PersonalTokenApi;
