import styled from '@emotion/styled';

import { IconButton as DefaultButton } from 'ui-kit';

export const ServerError = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 16,
});

export const ConfirmButton = styled(DefaultButton)({
  marginTop: 8,
  width: '100%',
});
