export enum GrantTypes {
  CLIENT_CREDENTIALS = 'client_credentials',
  REFRESH_TOKEN = 'refresh_token',
  PASSWORD = 'password',
  AUTHORIZATION_CODE = 'authorization_code',
}

export interface AccessToken {
  aud?: string;
  expiredAt?: number;
  issuedAt?: number;
  issuer?: string;
  jti?: string;
  ownerId?: string;
  type?: string;
  coId?: string;
  ownUid?: string;
  sub?: string;
}

export interface TokenResponse {
  access_token: string;
  refresh_token: string;
}

export interface AuthorizeResponse {
  code: string,
}
