import { CSSObject } from '@emotion/serialize';

import { LightTheme } from './themes';

export enum Size {
  SCROLLBAR_WIDTH = 3,
  SCROLLBAR_HEIGHT = 3,

  BASIC_HEADER_MOBILE_HEIGHT = 88,
  BASIC_HEADER_DESKTOP_HEIGHT = 80,

  NAVBAR_HEIGHT = 64,
  NAVBAR_HEIGHT_960 = 52,

  CONTROLPANEL_HEIGHT = 68,
  CONTROLPANEL_HEIGHT_960 = 52,
  CONTROLPANEL_HEIGHT_SLOT_FULLSCREEN = 52,

  MOBILE_NAVBAR_HEIGHT = 36,
  MOBILE_NAVBAR_PADDING = 8,

  MOBILE_CONTROLPANEL_HEIGHT = 36,
  MOBILE_CONTROLPANEL_PADDING = 8,

  MOBILE_PAGINATION_HEIGHT = 8,
  MOBILE_PAGINATION_PADDING = 8,

  SIDE_PANEL_WIDTH = 365,
  SIDE_PANEL_MARGINS = 12,
  SIDE_PANEL_MARGINS_TOP = 8,
  SIDE_PANEL_MARGINS_BOTTOM = 8,
  NARROW_SCREEN_BREAK_POINT = 1280,
  GRID_PADDING = 8,
  GRID_MOBILE_DEMO_GAP = 24,

  DESKTOP_PAGINATION_HEIGHT = 36,
}

export enum Color {
  RED0 = '#FC2F2F',
  RED1 = '#fd8282',
  RED2 = '#feacac',
  RED3 = '#D9042B',
  RED4 = 'rgba(217, 4, 43, 0.1)',

  RED5 = '#FDE9E9',
  RED6 = '#FE9FA9',

  DARK0 = '#333333',
  DARK1 = '#060A2D',

  DARK3 = '#1D1D2C',

  BLUE0 = '#4C76B5',
  BLUE1 = '#7EA4DC',
  BLUE2 = '#0C8EED',
  BLUE4 = '#1399FA',
  BlUE5 = '#E8F5FF',
  BLUE6 = '#163498',
  BLUE7 = '#3D5ECC',
  BLUE8 = 'rgba(19, 153, 250, 0.1)',
  BLUE9 = 'rgba(22, 52, 152, 0.1)',

  GREY1 = '#DEE3E7',
  GREY2 = '#E8E8E9',
  GREY3 = '#A1B0BC',
  GREY4 = '#B8B6C2',
  GREY5 = '#676767',
  GREY6 = '#BDBDBD',
  GREY7 = 'rgba(51, 51, 51, 0.8)',
  GREY8 = '#EEEEEE',
  GREY9 = '#AAAAAA',
  GREY10 = '#DADADA',
  GREY11 = '#636363',
  GREY12 = 'rgba(51, 51, 51, 0.6)',
  GREY13 = '#F6F6F9',
  GREY14 = 'rgba(51, 51, 51, 0.2)',
  GREY15 = 'rgba(255, 255, 255, 0.2)',
  GREY16 = '#4A494E',
  GREY17 = '#E2E2F0',
  GREY18 = '#DADAE5',
  GREY19 = '#A1A4BC',
  GREY20 = '#F6F6FC',
  GREY21 = '#A7A7A7',
  GREY22 = '#7A7D98',
  GREY23 = '#F3F2F9',
  GREY24 = '#EBEBEB',

  GREY25 = '#D4D5DE',
  GREY26 = '#5C5D6C',
  GREY27 = '#F1F1F4',

  GREEN1 = '#54F147',
  GREEN2 = '#C9F9CE',
  GREEN3 = '#23BE33',
  GREEN4 = 'rgba(35, 190, 51, 0.2)',

  YELLOW = '#FFAF37',
  YELLOW1 = '#EFCB0B',

  ORANGE = '#EFA20B',
  ORANGE1 = '#FFFBF4',
  ORANGE2 = '#FC9E22',
  ORANGE3 = 'rgba(239, 162, 11, 0.1)',

  PURPLE = '#7918AA',
}

export enum Shadow {
  Default = '0px 4px 15px rgba(0, 0, 0, 0.17)',
}

export enum Font {
  BRAND = 'PTRootUIWebRegular',
  BRAND_BOLD = 'PTRootUIWebBold',
  BRAND_LIGHT = 'PTRootUIWebLight',
  BRAND_MEDIUM = 'PTRootUIWebMedium',
}

export enum ZIndex {
  ROOM_FOREGROUND = 5,
  SLOT_VIDEO = 1,
  SLOT_SOUND = 2,
  SLOT_OVERLAY = 4,
  SLOT_SHARE_ICON = 5,
  SLOT_BUTTON = 7,
  PEER_FULLSCREEN = 20,
  HEADER = 10,
  PEER_TRUE_FULLSCREEN = 20,
  OVERLAY = 40,
  DIALOG = 50,
  TOOLTIP = 60,
  POPUP_COVER = 99,
  POPUP = 100,
  REACTION = 101,
  MAX = 9999,
  BACKGROUND = 0,
}

export enum MediaBreakPoint {
  WIDTH_375 = '@media (max-width: 375px)',
  WIDTH_568 = '@media (max-width: 568px)',
  WIDTH_640 = '@media (max-width: 640px)',
  WIDTH_960 = '@media (max-width: 960px)',
  WIDTH_960_OR_HEIGHT_760 = '@media (max-width: 960px), (max-height: 760px)',
  WIDTH_640_OR_HEIGHT_760 = '@media (max-width: 640px), (max-height: 760px)',

  WIDTH_1024 = '@media (max-width: 1024px)',
  WIDTH_1365 = '@media (max-width: 1365px)',

  WIDTH_1280 = '@media (max-width: 1280px)',
  WIDTH_1440 = '@media (max-width: 1440px)',
  WIDTH_1920 = '@media (max-width: 1920px)',

  HEIGHT_1024 = '@media (max-height: 1024px)',
  HEIGHT_960 = '@media (max-height: 960px)',
  HEIGHT_900 = '@media (max-height: 900px)',
  HEIGHT_760 = '@media (max-height: 760px)',
  HEIGHT_700 = '@media (max-height: 700px)',
  HEIGHT_480 = '@media (max-height: 480px)',
  HEIGHT_400 = '@media (max-height: 400px)',
  HEIGHT_700_AND_WIDTH_960 = '@media (max-height: 700px) and (max-width: 960px)',

  COMMON_PANELS_VIEW_IN_ROOM_BP = '@media (max-height: 900px)',
}

export type VariantType = 'success' | 'error' | 'warning' | 'accent' | 'secondary';
export const getVariantColor = (theme: AppTheme, variant: VariantType) => {
  const variantsMap = {
    success: theme.colors.successBase,
    error: theme.colors.error02,
    warning: theme.colors.warningBase,
    accent: theme.colors.accentBase,
    secondary: theme.colors.secondary02,
  };

  return variantsMap[variant];
};

export const MOBILE_MEDIA_BP = MediaBreakPoint.WIDTH_640;
export const MOBILE_MEDIA_QUERY = '(max-width: 640px)';
export enum MatchMedia {
  Width640 = '(max-width: 640px)',
  Width960 = '(max-width: 960px)',
  Width1920 = '(max-width: 1920px)',

  Height480 = '(max-height: 480px)',
  Height900 = '(max-height: 900px)',
}

const DefaultTheme = LightTheme;

export type AppTheme = typeof DefaultTheme;

export const iconStyle = (icon?: string): CSSObject => Object.freeze({
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  ...(icon && { backgroundImage: `url(${icon})` }),
});

export const textOverflowStyle: CSSObject = Object.freeze({
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
});

export const textOverflowMultilineStyle = (lineNumber: number): string => `
  display: -webkit-box;
  -webkit-line-clamp: ${lineNumber};
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
`;

export const textOverflowMultilineStyleObject = (lineNumber: number): CSSObject => ({
  display: '-webkit-box',
  WebkitLineClamp: `${lineNumber}`,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  wordBreak: 'break-all',
});

export const overlayStyle: CSSObject = Object.freeze({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  width: '100%',
  height: '100%',
});

export type TextSize = 'inherit'
| 'h1'
| 'h2'
| 'h3'
| 'main-text-medium'
| 'main-text-bold'
| 'main-link'
| 'label-text-medium'
| 'label-text-bold';

/*
  Change font-name copied from Figma based on font-weight
  font-weight: 500; => font-family: PT Root UI Medium;
  font-weight: 700; => font-family: PT Root UI Bold;

  and remove font-weight and letter-spacing copied from Figma font style
*/

/* don't forget make changes in both functions */

export const getStringTextStyle = (size: TextSize): string => {
  switch (size) {
    case 'h1': {
      return `
          font-family: PT Root UI Bold;
          font-size: 32px;
          line-height: 44px;
        `;
    }
    case 'h2': {
      return `
          font-family: PT Root UI Bold;
          font-size: 24px;
          line-height: 32px;
        `;
    }
    case 'h3': {
      return `
          font-family: PT Root UI Bold;
          font-size: 20px;
          line-height: 24px;
        `;
    }
    case 'main-text-medium': {
      return `
          font-family: PT Root UI Medium;
          font-size: 16px;
          line-height: 20px;
        `;
    }
    case 'main-text-bold': {
      return `
        font-family: PT Root UI Bold;
        font-size: 16px;
        line-height: 20px;
      `;
    }
    case 'main-link': {
      return `
        font-family: PT Root UI Bold;
        font-size: 16px;
        line-height: 20px;
      `;
    }
    case 'label-text-medium': {
      return `
          font-family: PT Root UI Medium;
          font-size: 14px;
          line-height: 16px;
        `;
    }
    case 'label-text-bold': {
      return `
          font-family: PT Root UI Bold;
          font-size: 14px;
          line-height: 16px;
        `;
    }
    case 'inherit':
    default: {
      return `
          font-weight: inherit;
          font-size: inherit;
          line-height: inherit;
        `;
    }
  }
};

export const getObjectTextStyle = (size: TextSize): CSSObject => {
  switch (size) {
    case 'h1': {
      return {
        fontFamily: 'PT Root UI Bold',
        fontSize: 32,
        lineHeight: '44px',
      };
    }
    case 'h2': {
      return {
        fontFamily: 'PT Root UI Bold',
        fontSize: 24,
        lineHeight: '32px',
      };
    }
    case 'h3': {
      return {
        fontFamily: 'PT Root UI Bold',
        fontSize: 20,
        lineHeight: '24px',
      };
    }
    case 'main-text-medium': {
      return {
        fontFamily: 'PT Root UI Medium',
        fontSize: 16,
        lineHeight: '20px',
      };
    }
    case 'main-text-bold': {
      return {
        fontFamily: 'PT Root UI Bold',
        fontSize: 16,
        lineHeight: '20px',
      };
    }
    case 'main-link': {
      return {
        fontFamily: 'PT Root UI Bold',
        fontSize: 16,
        lineHeight: '20px',
      };
    }
    case 'label-text-medium': {
      return {
        fontFamily: 'PT Root UI Medium',
        fontSize: 14,
        lineHeight: '16px',
      };
    }
    case 'label-text-bold': {
      return {
        fontFamily: 'PT Root UI Bold',
        fontSize: 14,
        lineHeight: '16px',
      };
    }
    case 'inherit':
    default: {
      return {
        fontWeight: 'inherit',
        fontSize: 'inherit',
        lineHeight: 'inherit',
      };
    }
  }
};

export const landscapeMediaQuery = '(min-aspect-ratio: 13/9)';
