import React, { useState, useEffect, FunctionComponent } from 'react';

const MESSAGE_DELAY_IN_MS = 300;

interface DelayedFallbackMessageProps {
  message?: string,
  timeoutInMs?: number,
}

const DelayedFallbackMessage: FunctionComponent<DelayedFallbackMessageProps> = (
  { message = 'Loading...', timeoutInMs = MESSAGE_DELAY_IN_MS },
) => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const timerId = setTimeout(() => setIsVisible(true), timeoutInMs);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <>
      {isVisible && <div>{message}</div>}
    </>
  );
};

export default DelayedFallbackMessage;
