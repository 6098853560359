import { useEffect } from 'react';
import { autorun, reaction } from 'mobx';
import { useParams } from 'react-router-dom';

import useStores from 'stores/root';

interface QueryParams {
  roomAlias: string,
}

const useChatRoomStateTracking = () => {
  const { roomAlias } = useParams<QueryParams>();

  const {
    authStore,
    roomStore,
  } = useStores();

  useEffect(() => autorun(() => {
    if (authStore.isAuthenticated && roomAlias) {
      roomStore.getRoomByAlias(roomAlias);
    }
  }), []);

  useEffect(() => reaction(
    () => roomStore.isRoomInfoLoaded,
    (isRoomInfoLoaded) => {
      if (!isRoomInfoLoaded || !roomStore.id || !roomStore.spaceId) {
        return;
      }

      roomStore.fetchSpaceInfo();
    },
  ), []);

  useEffect(() => {
    if (roomAlias) {
      roomStore.resetStore();
    }
  }, []);
};

export default useChatRoomStateTracking;
