import axios from 'axios';
import qs from 'qs';
import { v4 as uuid } from 'uuid';

import { getClientTabId, receiveClientTrackingId } from 'helpers/common';
import { getMoodhoodApiUrl } from 'helpers/url';
import { CLIENT_ID_HEADER_NAME, TAB_ID_HEADER_NAME, TRACE_ID_HEADER_NAME } from 'helpers/apiClient';

const createApi = () => axios.create({
  baseURL: getMoodhoodApiUrl(),
  timeout: 10000,
  withCredentials: true,
  paramsSerializer: (params) => qs.stringify(params),
  headers: {
    [CLIENT_ID_HEADER_NAME]: receiveClientTrackingId(),
    [TAB_ID_HEADER_NAME]: getClientTabId(),
    [TRACE_ID_HEADER_NAME]: uuid(),
  },
});

export default createApi();
