import { AnalyticsEventType } from 'services/MoodHoodAnalyticsApiClient/types';
import type CallsApi from 'services/MoodHoodApiClient/CallsApi';
import type { Announcement, CurrencyCode } from 'types/stores/announcements';

export interface AnnouncementsConstructorParams {
  isEvent: boolean;
  callsApi: CallsApi;
  room: RoomParam;
}

export interface RoomParam {
  roomId: string;
  spaceId: string;
  userId?: string;
}

export interface AnnouncementsUpdatedEvent {
  payload: {
    activeAnnouncements: Announcement[];
  }
}

export enum ProdamusEventType {
  Open = 'open',
  Close = 'close',
  Waiting = 'waiting',
  Success = 'success',
  Error = 'error',
}

export interface ProdamusEvent {
  type: ProdamusEventType;
  announcementId: string;
  amount?: number;
  currency?: CurrencyCode;
}

export interface BannerEvent {
  event: AnalyticsEventType;
  announcementId: string;
}

export const ProdamusEventTypeValues = Object.values(ProdamusEventType);
