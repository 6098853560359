import styled from '@emotion/styled';
import { Link as LinkBase } from 'react-router-dom';

import {
  IconButton,
  FigmaTypography,
  Input as InputBase,
  InputPhone as InputPhoneBase,
} from 'ui-kit';

export const Input = styled(InputBase)`
  margin-bottom: 10px;
`;

export const InputPhone = styled(InputPhoneBase)`
  margin-bottom: 10px;
`;

export const SpanWhite = styled.span`
  color: ${({ theme }) => theme.colors.secondaryBase};
`;

export const Link = styled(LinkBase)({});

export const ForgotPasswordBlock = styled(FigmaTypography)`
  margin: 14px 0 16px 0;
  text-align: center;
`;

ForgotPasswordBlock.defaultProps = {
  size: 'main-text-medium',
  as: 'div',
};

export const Agreements = styled(FigmaTypography)`
  margin-bottom: 24px;
  text-wrap: ${({ center }) => (center ? 'balance' : undefined)};
`;

Agreements.defaultProps = {
  size: 'main-text-medium',
  as: 'div',
};

export const NewsletterAgreements = styled.div`
  margin-bottom: 24px;
`;

export const CloseAlertButton = styled(IconButton)`
  width: auto;
  min-height: auto;
  height: auto;
  padding: 8px 16px;
`;

export const Hint = styled.div`
  text-align: center;
  margin-top: 16px;
`;
