import React, { FunctionComponent } from 'react';
import { useTheme } from '@emotion/react';
import {
  toast,
  ToastContainerProps,
  ToastContent,
  ToastOptions,
} from 'react-toastify';
import { observer } from 'mobx-react-lite';

import { ToastContainers } from 'constants/common';

import { ToastContainer } from './Layout';

const ToastActionContainer: FunctionComponent<ToastContainerProps> = observer(() => {
  const theme = useTheme();

  return (
    <ToastContainer
      enableMultiContainer
      containerId={ToastContainers.Action}
      position="top-left"
      icon={false}
      hideProgressBar
      limit={1}
      closeButton={false}
      appTheme={theme}
    />
  );
});

const commonParams: ToastOptions = {
  containerId: ToastContainers.Action,
  closeOnClick: false,
  className: 'toast-info',
  autoClose: false,
};

const toastInfoAction = (content: ToastContent, options?: ToastOptions): React.ReactText => toast.info(
  content,
  {
    ...commonParams,
    ...options,
  },
);

const toastWarnAction = (content: ToastContent, options?: ToastOptions): React.ReactText => toast.warn(
  content,
  {
    ...commonParams,
    ...options,
  },
);

const toastErrorAction = (content: ToastContent, options?: ToastOptions): React.ReactText => toast.error(
  content,
  {
    ...commonParams,
    ...options,
  },
);

export {
  ToastActionContainer,
  toastInfoAction,
  toastWarnAction,
  toastErrorAction,
};
