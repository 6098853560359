import {
  action,
  reaction,
  computed,
  makeAutoObservable,
} from 'mobx';
import axios, { AxiosError } from 'axios';
import jwt_decode from 'jwt-decode';
import { eventBus } from 'mobx-event-bus2';

import { decodeAccessToken, isDateExpired, sleep } from 'helpers/common';
import logger from 'helpers/logger';
import AuthApi from 'services/MoodHoodApiClient/AuthApi';
import { AccessToken, AuthorizeResponse, TokenResponse } from 'services/MoodHoodApiClient/AuthApi.types';
import { ApiResponse } from 'services/MoodHoodApiClient/types';
import { Errors } from 'constants/errors';
import { errorToJson } from 'helpers/apiClient';
import { areCookiesBlocked, isInsideIFrame } from 'helpers/browser';
import { setSessionStorageItem, getSessionStorageItem, removeSessionStorageItem } from 'helpers/sessionStorage';
import Api from 'services/MoodHoodApiClient/Api';
import { AppEvent } from 'types/common';

import { getUrlParam } from '../helpers/url';

const className = 'AuthStore';

class AuthStore {
  private authApi: AuthApi;

  accessTokenDecoded: AccessToken = {};

  isAuthenticationFail = false;

  fetchingAccessTokenInfoError?: AxiosError = undefined;

  refreshingAccessTokenError?: AxiosError = undefined;

  clientAccessTokenError?: AxiosError = undefined;

  refreshAccessTokenInProgress = false;

  authError?: AxiosError = undefined;

  isLoading = false;

  isTokenFetched = false;

  shouldTriggerAccessTokenRefreshment = false;

  shouldTriggerAuth = false;

  constructor() {
    this.authApi = new AuthApi(Api);
    makeAutoObservable(this);

    const accessTokenFromUrl = getUrlParam('accessToken') || undefined;
    const refreshTokenFromUrl = getUrlParam('refreshToken') || undefined;
    this.authApi.setShouldUseAuthHeader(
      isInsideIFrame() || !!(accessTokenFromUrl || refreshTokenFromUrl),
    );

    // handle failed access token info fetching
    reaction(
      () => this.fetchingAccessTokenInfoError,
      (fetchingAccessTokenInfoError) => {
        if (!fetchingAccessTokenInfoError) {
          return;
        }

        // we need to try to refresh access token if we failed to get accessToken info (in case it's stale)
        // Otherwise, we will come to fetchClientAccessToken method on failure
        const token = isInsideIFrame()
          ? getSessionStorageItem<string>('refreshToken') || undefined
          : undefined;

        logger.info('Decoded access token  info fetch error. Trying to refresh access token', {
          className,
          hasRefreshTokenInStorage: !!token,
          cookiesBlocked: areCookiesBlocked(),
        });

        this.refreshAccessToken(token);
      },
    );

    // handle failed access token refreshing
    reaction(
      () => this.refreshingAccessTokenError,
      (refreshingTokenError) => {
        if (!refreshingTokenError) {
          return;
        }

        logger.info('Refreshing access token error received', {
          className,
          error: refreshingTokenError,
          cookiesBlocked: areCookiesBlocked(),
        });

        (async () => {
          await sleep(60);
          const existingPair = await this.checkHasAccessTokenInfo();

          if (!existingPair) {
            logger.warn('No existing token pair after checking access token info');
            await this.fetchClientAccessToken();
          } else {
            logger.info('Token was refreshed in a parallel tab');

            // as we do not have raw taken data we need to reset it in api client and rely only on cookies
            this.setApiAuthToken(undefined);
            this.setAccessTokenDecodedFromToken(existingPair); // to indicate that we have authed user/client data
          }
        })();
      },
    );

    // handle successful login and logout
    reaction(
      () => this.isAuthenticated,
      (isAuthenticated) => {
        if (isAuthenticated) {
          logger.info('Is authenticated. Resetting previous errors', { className });

          // clear errors after successful authentication
          this.resetFetchingAccessTokenInfoError();
          this.refreshingAccessTokenError = undefined;
        } else {
          logger.info('Not authenticated. Handling auth', { className });

          // run authentication after logout or something that cleared accessTokenDecoded
          this.authenticate();
        }
      },
    );

    reaction(
      () => this.shouldTriggerAccessTokenRefreshment,
      (shouldTriggerAccessTokenRefreshment) => {
        if (shouldTriggerAccessTokenRefreshment) {
          this.refreshAccessToken();
        }
      },
    );

    reaction(
      () => this.shouldTriggerAuth,
      (shouldTriggerAuth) => {
        if (shouldTriggerAuth) {
          this.authenticate();
        }
      },
    );

    // should go after all existing reactions
    if (accessTokenFromUrl || refreshTokenFromUrl) {
      this.setApiAuthToken({
        access_token: accessTokenFromUrl,
        refresh_token: refreshTokenFromUrl,
      });
    }

    if (refreshTokenFromUrl) {
      this.shouldTriggerAccessTokenRefreshment = true;
    } else {
      this.shouldTriggerAuth = true;
    }
  }

  get currentAccessToken(): string | undefined {
    return this.authApi.accessTokenForAuthHeader;
  }

  @action async fetchClientAccessToken(): Promise<void> {
    try {
      logger.info('Fetching client access token', { className });

      const tokenResponse = await this.authApi.getClientAccessToken();
      this.setApiAuthToken(tokenResponse);
    } catch (err: unknown) {
      logger.error('Failed to fetch client access token', {
        error: errorToJson(err),
      });

      this.setApiAuthToken(undefined);
      this.isAuthenticationFail = true;
      this.clientAccessTokenError = err as AxiosError;
      eventBus.post(AppEvent.Error, Errors.AUTH_ERROR);
    }
  }

  @action async fetchAccessTokenInfo(accessToken?: string): Promise<void> {
    try {
      logger.info('Getting current access token info', { className });

      const accessTokenDecoded = await this.authApi.getAccessTokenInfo(accessToken);
      this.setAccessTokenDecodedFromToken(accessTokenDecoded);
      this.resetFetchingAccessTokenInfoError();
    } catch (err: unknown) {
      logger.warn('Failed to get access token info', { error: err });
      this.setFetchingAccessTokenInfoError(err as AxiosError);
    }
  }

  @action async exchangeAuthCode(code: string): Promise<void> {
    try {
      logger.info('Getting current access token info', { className });

      const token = await this.authApi.getAccessTokenByAuthCode(code);
      this.setApiAuthToken(token);
    } catch (err: unknown) {
      logger.warn('Failed to get access token info', { error: err });
      this.setFetchingAccessTokenInfoError(err as AxiosError);
    }
  }

  @action async checkHasAccessTokenInfo(): Promise<AccessToken | undefined> {
    try {
      return await this.authApi.getAccessTokenInfo();
    } catch (error: unknown) {
      logger.info('Got error while checking if has access token info', { className, error });
      return undefined;
    }
  }

  @computed get isAuthenticated(): boolean {
    return !!this.accessTokenDecoded.aud;
  }

  @computed get isUser(): boolean {
    return this.accessTokenDecoded.aud === 'user';
  }

  @action async authenticate(accessToken?: string): Promise<void> {
    logger.info('Handling authentication', { className });

    await this.fetchAccessTokenInfo(accessToken);
  }

  @action async refreshAccessToken(refreshToken?: string): Promise<void> {
    if (this.refreshAccessTokenInProgress) {
      return;
    }

    if (refreshToken) {
      const refreshTokenDecoded: AccessToken = jwt_decode(refreshToken);

      const shouldNotUpdateByRefreshToken = this.accessTokenDecoded
        && refreshTokenDecoded.sub === this.accessTokenDecoded.ownerId
        && !isDateExpired(this.accessTokenDecoded.expiredAt);

      if (shouldNotUpdateByRefreshToken) {
        logger.info('Skipping refreshing access token by refresh token. Should not update', {
          className,
          sub: refreshTokenDecoded.sub,
        });
        return;
      }
    }

    this.setRefreshAccessTokenInProgress(true);

    try {
      logger.info('Handling access token refreshment', { className });

      const tokenResponse = await this.authApi.getAccessTokenByRefresh(refreshToken);

      logger.info('Refresh token exchanged to a new token pair');

      this.setApiAuthToken(tokenResponse);
    } catch (err: unknown) {
      if (!(axios.isAxiosError(err) && err.response?.status === 400)) {
        logger.error('Failed to refresh access token', {
          error: errorToJson(err),
        });
      } else {
        logger.warn('Could not refresh access token', { error: err });
      }

      this.refreshingAccessTokenError = err as AxiosError;
    } finally {
      this.setRefreshAccessTokenInProgress(false);
    }
  }

  @action async signIn(username: string, password: string, getCaptcha: () => Promise<string>): Promise<void> {
    logger.info('Handling user sign in with credentials', { username });

    this.authError = undefined;
    this.isLoading = true;

    try {
      const captcha = await getCaptcha();
      const tokenResponse = await this.authApi.getUserAccessToken(username, password, captcha);
      this.setApiAuthToken(tokenResponse);
    } catch (error: unknown) {
      logger.error('Failed to sign in user', {
        username,
        error: errorToJson(error),
      });

      this.authError = error as AxiosError;
    }

    this.isLoading = false;
  }

  @action async authorize(): ApiResponse<AuthorizeResponse> {
    return this.authApi.authorize();
  }

  @action setAccessTokenDecodedFromToken(token: AccessToken): void {
    this.accessTokenDecoded = token;
    this.isTokenFetched = true;
  }

  @action setAccessTokenDecodedFromResponse(tokenResponse?: Partial<TokenResponse>): void {
    const token = tokenResponse?.access_token ? decodeAccessToken(tokenResponse.access_token) : {};

    logger.info('Setting access token decoded from response', {
      data: String(tokenResponse?.access_token).split('.')[1],
      currentAud: this.accessTokenDecoded?.aud,
      currentSub: this.accessTokenDecoded?.sub,
      parsedAud: token?.aud,
      parsedSub: token?.sub,
      clientTimeGmt: (new Date()).toISOString(),
    });

    this.setAccessTokenDecodedFromToken(token);
  }

  @action setRefreshAccessTokenInProgress(isInProgress: boolean): void {
    this.refreshAccessTokenInProgress = isInProgress;
  }

  @action setFetchingAccessTokenInfoError(error: AxiosError): void {
    this.fetchingAccessTokenInfoError = error;
  }

  @action resetFetchingAccessTokenInfoError(): void {
    this.fetchingAccessTokenInfoError = undefined;
  }

  @action.bound async logout(): Promise<void> {
    logger.info('Handling logout', { className });

    await this.authApi.deleteAccessToken();
    this.isTokenFetched = false;
    setSessionStorageItem('refreshToken', '');
  }

  private setApiAuthToken(token?: Partial<TokenResponse>): void {
    this.setAccessTokenDecodedFromResponse(token);

    // Probably in some cases browsers allow to store data in storages while cookies are blocked
    if (isInsideIFrame() && areCookiesBlocked()) {
      logger.info('Fallback edit refresh token in session', { hasToken: !!token?.refresh_token });

      if (token?.refresh_token) {
        setSessionStorageItem('refreshToken', token?.refresh_token);
      } else {
        removeSessionStorageItem('refreshToken');
      }
    }

    logger.info('Setting api auth token pair to api client', {
      hasAccessToken: !!token?.access_token,
      hasRefreshToken: !!token?.refresh_token,
    });

    this.authApi.setAuthToken(token);
  }
}

export default AuthStore;
