import { AxiosInstance } from 'axios';

class IntegrationsApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async getMiroToken(): Promise<string> {
    const { data } = await this.api.get<{ miroToken: string }>('/integrations/miro-token');
    return data.miroToken;
  }
}

export default IntegrationsApi;
