import React, { VoidFunctionComponent } from 'react';
import { CustomComponentProps } from 'react-multi-date-picker';

import { IconButton } from 'ui-kit';
import { CalendarIcon } from 'ui-kit/assets/icons';

const CalendarButton: VoidFunctionComponent<CustomComponentProps> = ({
  openCalendar,
}) => (
  <IconButton id="sessions-date" onClick={openCalendar}>
    <CalendarIcon />
  </IconButton>
);

export default CalendarButton;
