import React, { FunctionComponent, useState } from 'react';

import { Badge, IconButton } from 'ui-kit';

import { ButtonSelectRoot, OptionText } from './index.styled';
import { ButtonSelectOption, ButtonSelectProps } from './types';

const ButtonSelect: FunctionComponent<ButtonSelectProps> = ({
  onChange,
  options,
  children,
  disabled,
  variant,
  size,
  buttonVariant,
  isMultiSelect,
  ...restProps
}) => {
  const propSelectedOptionsIds = options?.filter((option) => option.selected)
    .map((option) => option.id);
  const initValue = new Set(propSelectedOptionsIds);
  const [selectedOption, setSelectedOption] = useState(initValue);

  const handleChange = (option: ButtonSelectOption) => {
    setSelectedOption((ps) => {
      const prevState = new Set(ps);
      if (isMultiSelect) {
        if (prevState.has(option?.id)) {
          prevState.delete(option?.id);
          return prevState;
        }

        prevState.add(option?.id);
        return prevState;
      }

      prevState.clear();
      prevState.add(option?.id);
      return prevState;
    });
    onChange?.(option);
  };

  const buttonStyle = buttonVariant ?? 'clear';

  return (
    <ButtonSelectRoot {...restProps} variant={variant} buttonVariant={buttonStyle}>
      {options?.map((option) => (
        <IconButton
          key={option.id}
          id={option.id}
          disabled={disabled}
          size={size}
          text={(
            <OptionText>
              {option.text}
              {option.badgeText && (
                <Badge variant="red">
                  {option.badgeText}
                </Badge>
              )}
            </OptionText>
          )}
          isSelected={selectedOption.has(option.id)}
          subVariant={!selectedOption.has(option.id) ? buttonStyle : undefined}
          onClick={() => { handleChange(option); }}
          icon={option.icon}
        />
      ))}
      {children}
    </ButtonSelectRoot>
  );
};

export default ButtonSelect;
