import React, { ChangeEvent, FunctionComponent, useState } from 'react';

import { Flex, Input, IconButton } from 'ui-kit';
import useStores from 'stores/root';
import { observer } from 'mobx-react-lite';

const MicNotificationTuningPanel: FunctionComponent = observer(() => {
  const {
    participantStore: {
      deviceStore: { speechRecognize },
    },
  } = useStores();
  const [display, setDisplay] = useState(false);

  const handleChangeLooksLikeSpeechTriggerLevel = (event: ChangeEvent<HTMLInputElement>) => {
    speechRecognize.setLooksLikeSpeechTriggerLevel(Number(event.target.value));
  };

  const handleChangeIsSpeechScoresTriggerLevel = (event: ChangeEvent<HTMLInputElement>) => {
    speechRecognize.setIsSpeechScoresTriggerLevel(Number(event.target.value));
  };

  const handleChangeSoundLevelForSpeech = (event: ChangeEvent<HTMLInputElement>) => {
    speechRecognize.setSoundLevelForSpeech(Number(event.target.value));
  };

  const handleChangeSoundLevelForSilence = (event: ChangeEvent<HTMLInputElement>) => {
    speechRecognize.setSoundLevelForSilence(Number(event.target.value));
  };

  const handleChangeIsSilenceScoresTriggerLevel = (event: ChangeEvent<HTMLInputElement>) => {
    speechRecognize.setIsSilenceScoresTriggerLevel(Number(event.target.value));
  };

  const handleCheckingSpeechPeriodInSec = (event: ChangeEvent<HTMLInputElement>) => {
    speechRecognize.setCheckingSpeechPeriodInSec(Number(event.target.value));
  };

  const handleSetDelayBeforeCheckingSpeechSec = (event: ChangeEvent<HTMLInputElement>) => {
    speechRecognize.setDelayBeforeCheckingSpeechSec(Number(event.target.value));
  };

  const handleVisible = () => {
    setDisplay((prevState) => !prevState);
  };

  return (
    <>
      <IconButton
        style={{ marginBottom: 20, marginTop: 20 }}
        id="speechTuning"
        text="Настройки распознавания речи"
        onClick={handleVisible}
      />
      <div style={{ marginTop: 20, display: display ? 'block' : 'none' }}>
        <div style={{ marginBottom: 10 }}>Настройка триггера речи:</div>
        <div style={{ marginBottom: 20, width: 300 }}>
          -Счетчик речи считает каждое изменение уровня звука за 1 поинт
          <br />
          -Счетчик тишины считает каждую милисикунду уровня звука меньше порога за 1 поинт (длительность тишины)
        </div>
        <Flex gap={16}>
          <Input
            id="checkingSpeechPeriodInSec"
            label="Время в течении которого будем слушать речь это или нет в сек."
            type="number"
            onChange={handleCheckingSpeechPeriodInSec}
            afterInputElement={<div>{speechRecognize.checkingSpeechPeriodInSec}</div>}
          />
          <Input
            id="soundLevelForSpeech"
            label="Уровень звука при котором считаем что это звук речи (1 - 10)"
            type="number"
            onChange={handleChangeSoundLevelForSpeech}
            afterInputElement={<div>{speechRecognize.minAudioLevelForSpeech}</div>}
          />
        </Flex>
        <Flex gap={16}>
          <Input
            id="soundLevelForSpeech"
            label="Уровень звука при котором считаем что это тишина (1 - 10)"
            type="number"
            onChange={handleChangeSoundLevelForSilence}
            afterInputElement={<div>{speechRecognize.maxAudioLevelForSilence}</div>}
          />
          <Input
            id="looksLikeSpeechTriggerLevel"
            label="Порог счетчика для старта проверки на речь"
            type="number"
            onChange={handleChangeLooksLikeSpeechTriggerLevel}
            afterInputElement={<div>{speechRecognize.minScoresForSpeechWatching}</div>}
          />
        </Flex>
        <Flex gap={16}>
          <Input
            id="isSpeechScoresTriggerLevel"
            label="Порог счетчика при котором это речь"
            type="number"
            onChange={handleChangeIsSpeechScoresTriggerLevel}
            afterInputElement={<div>{speechRecognize.minScoresForSpeech}</div>}
          />
          <Input
            id="looksLikeSpeechTriggerLevel"
            label="Порог счетчика тишины для сброса счетчика речи на 0, меньше него считается паузой между словами"
            type="number"
            onChange={handleChangeIsSilenceScoresTriggerLevel}
            afterInputElement={<div>{speechRecognize.minSilenceScoresForSilence}</div>}
          />
        </Flex>
        <Input
          id="delayBeforeNextCheckingSpeechSec"
          label="Пауза перед следующей проверкой на речь в сек"
          type="number"
          onChange={handleSetDelayBeforeCheckingSpeechSec}
          afterInputElement={<div>{speechRecognize.delayBeforeNextCheckingSpeechSec}</div>}
        />
        <div style={{ marginBottom: 10 }}>{`Очки речи: ${speechRecognize.speechScores}`}</div>
        <div style={{ marginBottom: 10 }}>{`Очки тишины: ${speechRecognize.silenceScores}`}</div>
      </div>
    </>
  );
});

export default MicNotificationTuningPanel;
