import { SwipeableHandlers, useSwipeable } from 'react-swipeable';

interface UseSwipableParams {
  disabled: boolean,
  onClose: () => void,
}

const useSwipable = ({ disabled, onClose }: UseSwipableParams): SwipeableHandlers => {
  const swipeableHandlers = useSwipeable(!disabled ? {
    onSwipedDown: onClose,
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
  } : {});

  return swipeableHandlers;
};

export default useSwipable;
