import React from 'react';

export enum DialogCloseReason {
  CloseButton = 'close button',
  ClickOutside = 'click outside',
}

export interface DialogProps {
  fixedPosition?: boolean;
  canClose?: boolean;
  onClose?(reason: DialogCloseReason): void;
  className?: string;
  isHidden?: boolean;
  title?: React.ReactNode;
  hasBackButton?: boolean;
  backButtonId?: string;
  backButtonHandler?: () => void;
  isUseClickOutsideDisabled?:boolean;
  noBackCover?: boolean;
  isCompact?: boolean;
  contentClassName?: string;
}
