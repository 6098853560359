import React, { FunctionComponent } from 'react';

import { InformationIcon } from 'ui-kit/assets/icons';

import {
  Text,
  Alert,
  Title,
  AlertMessage,
  IconContainer,
  TextContainer,
  TextAndButtons,
  TitleContainer,
  ContentContainer,
} from './FormError.styled';

const FormError: FunctionComponent<{ title: string }> = ({ title, children }) => (
  <Alert>
    <ContentContainer>
      <IconContainer>
        <InformationIcon />
      </IconContainer>
      <TextAndButtons>
        <AlertMessage>
          {title && (
            <TitleContainer>
              <Title size="h3">
                {title}
              </Title>
            </TitleContainer>
          )}
          <TextContainer>
            <Text size="main-text-medium">
              {children}
            </Text>
          </TextContainer>
        </AlertMessage>
      </TextAndButtons>
    </ContentContainer>
  </Alert>
);

export default FormError;
