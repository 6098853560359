import { RoomRole } from 'services/MoodHoodApiClient/types';

import { RoomRolePermissions } from './types';
import webinarPermissions from './webinarPermissions';

const moderatorPermissions: RoomRolePermissions = {
  ...webinarPermissions[RoomRole.Moderator],
  canPutUsersOutOfScreen: false,
};

const speakerPermissions: RoomRolePermissions = {
  ...webinarPermissions[RoomRole.Speaker],
};

const userPermissions: RoomRolePermissions = {
  ...webinarPermissions[RoomRole.User],
  canUseScreenShare: true,
  canRaiseHand: true,
  canPutUsersOutOfScreen: false,
};

const guestPermissions: RoomRolePermissions = {
  ...userPermissions,
};

const rolePermissions = Object.freeze({
  [RoomRole.Owner]: Object.freeze(moderatorPermissions),
  [RoomRole.Moderator]: Object.freeze(moderatorPermissions),
  [RoomRole.Speaker]: Object.freeze(speakerPermissions),
  [RoomRole.User]: Object.freeze(userPermissions),
  [RoomRole.Guest]: Object.freeze(guestPermissions),
});

export default rolePermissions;
