import styled from '@emotion/styled';
import { ZIndex } from 'ui-kit/theme';

export const DialogContent = styled.div<{ canClose: boolean, isCompact?: boolean }>(({ theme, isCompact }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  padding: 32,
  borderColor: theme.colors.secondaryBase,
  borderRadius: 10,
  backgroundColor: theme.colors.bg,
  ...(isCompact && {
    width: '100dvw',
    height: '100dvh',
    borderRadius: 0,
    overflowY: 'auto',
  }),
}));

export const CloseButton = styled.div(({ theme }) => ({
  cursor: 'pointer',
  zIndex: ZIndex.POPUP,
  '& > svg': {
    width: 24,
    height: 24,
    display: 'block',
  },
  padding: 6,
  color: theme.colors.accent04,
}));

export const DialogRoot = styled.div<{
  canClose: boolean,
  isHidden?: boolean,
  noBackCover?: boolean,
}>(({
  canClose,
  isHidden = false,
  noBackCover = false,
}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  zIndex: canClose ? ZIndex.DIALOG : ZIndex.POPUP,
  overflow: 'hidden',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: noBackCover ? undefined : 'rgba(0,0,0,0.6)',
  fontSize: '14px',
  visibility: isHidden ? 'hidden' : 'visible',
}));

export const Section = styled.div({
  margin: '16px 0',
});

export const General = styled.div({
  minWidth: 344,
});

export const TwoColumns = styled.div({
  display: 'flex',
  flexDirection: 'row',
});

export const SecondColumn = styled.div`
  margin: 19px 8px 0 44px;
  width: 308px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;
