import React, {
  FunctionComponent,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import useStores from 'stores/root';
import IconButton from 'ui-kit/components/IconButton';
import { HomeIcon, UploadIcon } from 'ui-kit/assets/icons';
import { Href } from 'ui-kit';
import { getUrlWithUTM } from 'helpers/utm';

import {
  Layout,
  HintText,
  ErrorText,
  CustomElement,
} from './Layout';
import RequestStorageAccess from './RequestStorageAccess';

const ErrorPage: FunctionComponent = ({ children }) => {
  const {
    roomStore: { isIframe },
    uiStore: { errorPageData, hasPageError },
    uiStore,
  } = useStores();
  const { t } = useTranslation();
  const { error, hint, customElement } = errorPageData;
  return (
    hasPageError ? (
      <RequestStorageAccess>
        <Layout>
          <ErrorText>{error}</ErrorText>
          <HintText>{hint}</HintText>
          <CustomElement>{customElement}</CustomElement>

          <IconButton
            id="refresh-err-page-btn"
            onClick={() => uiStore.reloadThePage()}
            title={t('common.refreshThePage')}
            style={{ marginTop: 10 }}
          >
            <UploadIcon aria-label={t('common.refreshThePage')} />
            &nbsp;
            {t('common.refreshThePage')}
          </IconButton>

          {!isIframe && (
            <Href
              id="go-home-err-page-btn"
              title={t('common.openHomePage')}
              style={{ marginTop: 32, display: 'inline-flex', alignItems: 'center' }}
              href={getUrlWithUTM('/')}
            >
              <HomeIcon aria-label={t('common.openHomePage')} />
              &nbsp;
              {t('common.openHomePage')}
            </Href>
          )}
        </Layout>
      </RequestStorageAccess>
    ) : (
      <>
        {children}
      </>
    )
  );
};

export default observer(ErrorPage);
