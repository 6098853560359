import styled from '@emotion/styled';
import { Color } from 'ui-kit/theme';
import { IconButton, TextArea } from 'ui-kit';
import { InfoCircleIcon } from 'ui-kit/assets/icons';

export const InputButton = styled(IconButton)({
  marginRight: 4,
  width: 120,
  height: 40,
});

export const PersonalTokensList = styled('div')({
  marginBottom: 16,
});

export const RemoveTokenButton = styled('span')(({ theme }) => ({
  marginLeft: 4,
  cursor: 'pointer',
  color: theme.colors.errorBase,
}));

export const InfoIcon = styled(InfoCircleIcon)({
  height: 12,
  width: 12,
  marginLeft: 4,
});

export const NewTokenWarning = styled('div')({
  width: 600,
  fontSize: 14,
  color: Color.RED0,
  marginBottom: 8,
});

export const NewTokenBody = styled(TextArea)({
  width: 600,
  minHeight: 130,
  fontSize: 12,
  border: `1px solid ${Color.GREY4}`,
  '&:hover': {
    borderColor: Color.GREY16,
  },
  cursor: 'pointer',
  marginBottom: 8,
});
