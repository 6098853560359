import {
  observable, makeAutoObservable, action, computed,
} from 'mobx';

import { RoomInfo, DatePeriod } from 'types/common';
import RoomApi from 'services/MoodHoodApiClient/RoomApi';
import RecordApi from 'services/MoodHoodApiClient/RecordApi';
import Metrics from 'services/Metrics';
import { lock, apiCall } from 'decorators';
import {
  SearchByName,
  PaginableRequest,
  PaginableResponse,
  UpdateRecordResponse,
  SearchByPublicationStatus,
} from 'services/MoodHoodApiClient/types';
import { Room } from 'services/MoodHoodApiClient/types/room';
import { RecordPublicationStatus } from 'modules/RoomRecorder/CloudRoomRecorder';

import {
  RecordItem,
  CloudRecordsConstructorParams,
  GetDownloadRecordUrlParams,
} from './types';

class CloudRecords {
  readonly roomApi: RoomApi;

  readonly recordApi: RecordApi;

  readonly metrics: Metrics;

  @observable spaceId?: string;

  @observable records?: PaginableResponse<RecordItem>;

  @observable downloads: string[] = [];

  @observable isLoadingRooms = false;

  @observable isLoadingRecords = false;

  @observable room: RoomInfo | null = null;

  @observable error: string | null = null;

  @observable isAllRecordsFetched = false;

  @observable recordsSizeInSpace = 0;

  @observable isRecordsSizeInSpaceFetched = false;

  @observable isRecordsSizeInSpaceFetching = false;

  @observable roomToRecordDownload: Room | null = null;

  constructor({
    roomApi,
    recordApi,
    metrics,
  }: CloudRecordsConstructorParams) {
    this.roomApi = roomApi;
    this.recordApi = recordApi;
    this.metrics = metrics;
    this.resetSpace();
    makeAutoObservable(this);
  }

  @lock('isLoadingRooms')
  async fetchRoomByAlias(roomAlias: string): Promise<void> {
    try {
      const room = await this.roomApi.getRoomByAlias(roomAlias);
      if (room) {
        this.setRoom(room);
      } else {
        this.handleError('statistics.notifications.errorLoadingRoomInfo');
      }
    } catch (error) {
      this.handleError('statistics.notifications.errorLoadingRoomInfo');
    }
  }

  @lock('isLoadingRooms')
  async fetchRoomById(spaceId: string, roomId: string): Promise<void> {
    try {
      const { room } = await this.roomApi.getRoomById(spaceId, roomId);
      if (room) {
        this.setRoom(room);
      } else {
        this.handleError('statistics.notifications.errorLoadingRoomInfo');
      }
    } catch (error) {
      this.handleError('statistics.notifications.errorLoadingRoomInfo');
    }
  }

  @lock('isLoadingRecords')
  async fetchRecords({
    dateFrom,
    dateTo,
    limit = 25,
    offset,
    name,
    publicationStatus,
  }: PaginableRequest<DatePeriod & SearchByName & SearchByPublicationStatus>): Promise<void> {
    if (!this.room) {
      return;
    }

    const {
      spaceId,
      id: roomId,
    } = this.room;

    const { data } = await this.recordApi.getRecords({
      spaceId,
      roomId,
      dateFrom,
      dateTo,
      offset,
      limit,
      name,
      publicationStatus,
    });

    if (data) {
      const result = data.items.map<RecordItem>((item) => ({
        id: item.id,
        name: item.name,
        fileSize: item.fileSize,
        roomId: item.roomId,
        spaceId: item.spaceId,
        callId: item.callId,
        state: item.state,
        createdAt: new Date(item.createdAt),
        startedAt: item.startedAt ? new Date(item.startedAt) : undefined,
        finishedAt: item.finishedAt ? new Date(item.finishedAt) : undefined,
        playbackEventAlias: item.playbackEventAlias,
        publicationStatus: item.publicationStatus,
      }));

      this.setRecords({
        total: data.total,
        items: result,
      });
    }
  }

  @lock('isLoadingRecords')
  async fetchMore({
    dateFrom,
    dateTo,
    limit = 25,
    offset,
    name,
  }: PaginableRequest<DatePeriod & SearchByName>): Promise<void> {
    if (!this.room) {
      return;
    }

    const { spaceId, id: roomId } = this.room;

    const { data } = await this.recordApi.getRecords({
      spaceId,
      roomId,
      dateFrom,
      dateTo,
      offset,
      limit,
      name,
    });

    if (data) {
      const result = data.items.map<RecordItem>((item) => ({
        id: item.id,
        name: item.name,
        fileSize: item.fileSize,
        roomId: item.roomId,
        spaceId: item.spaceId,
        callId: item.callId,
        state: item.state,
        createdAt: new Date(item.createdAt),
        startedAt: item.startedAt ? new Date(item.startedAt) : undefined,
        finishedAt: item.finishedAt ? new Date(item.finishedAt) : undefined,
        playbackEventAlias: item.playbackEventAlias,
        publicationStatus: item.publicationStatus,
      }));

      this.setRecords({
        total: data.total,
        items: [...this.records?.items || [], ...result],
      });
    }
  }

  @apiCall()
  async downloadRecord(record: RecordItem): Promise<void> {
    const {
      spaceId, roomId, id: recordId, name,
    } = record;

    if (this.isRecordDownloading(recordId)) {
      return;
    }

    this.addDownload(recordId);
    this.metrics.marketing.trackRoomRecordDownload(name);

    try {
      await this.recordApi.downloadRecord({ spaceId, roomId, recordId });
    } catch (e) {
      this.handleError('errors.reportDownloadError');
    }

    this.removeDownload(recordId);
  }

  @apiCall()
  async deleteRecord(record: RecordItem): Promise<void> {
    const {
      spaceId, roomId, id: recordId,
    } = record;

    try {
      await this.recordApi.deleteRecord({ spaceId, roomId, recordId });
    } catch (e) {
      this.handleError('errors.deleteRecordError');
      throw e;
    }
  }

  @apiCall<string | undefined>()
  async getDownloadRecordUrl({ spaceId, roomId, id }: GetDownloadRecordUrlParams): Promise<string | undefined> {
    try {
      const url = await this.recordApi.getDownloadRecordUrl({ spaceId, roomId, recordId: id });
      return url;
    } catch (e) {
      return undefined;
    }
  }

  @apiCall<UpdateRecordResponse>()
  async updateRecordName(record: RecordItem, name: string): Promise<UpdateRecordResponse | undefined> {
    const {
      spaceId, roomId, id: recordId,
    } = record;

    try {
      const response = await this.recordApi.updateRecord({
        spaceId,
        roomId,
        recordId,
        name,
      });

      return response;
    } catch (e) {
      return undefined;
    }
  }

  @apiCall<UpdateRecordResponse>()
  async PublishRecord(record: RecordItem): Promise<UpdateRecordResponse | undefined> {
    const {
      spaceId, roomId, id: recordId,
    } = record;

    try {
      const response = await this.recordApi.updateRecord({
        spaceId,
        roomId,
        recordId,
        publicationStatus: RecordPublicationStatus.Published,
      });

      return response;
    } catch (e) {
      return undefined;
    }
  }

  @apiCall<UpdateRecordResponse>()
  async UnPublishRecord(record: RecordItem): Promise<UpdateRecordResponse | undefined> {
    const {
      spaceId, roomId, id: recordId,
    } = record;

    try {
      const response = await this.recordApi.updateRecord({
        spaceId,
        roomId,
        recordId,
        publicationStatus: RecordPublicationStatus.Unpublished,
      });

      return response;
    } catch (e) {
      return undefined;
    }
  }

  @apiCall()
  async getSizeInSpace(): Promise<void> {
    if (!this.spaceId) {
      return;
    }

    this.setIsRecordsSizeInSpaceFetching(true);

    try {
      const { size } = await this.recordApi.getSizeInSpace({ spaceId: this.spaceId });
      this.setRecordsSizeInSpace(size);
      this.setIsRecordsSizeInSpaceFetched(true);
    } catch (e) {
      this.setRecordsSizeInSpace(0);
      this.setIsRecordsSizeInSpaceFetched(false);
    }

    this.setIsRecordsSizeInSpaceFetching(false);
  }

  @action setRoom(value: RoomInfo): void {
    if (value.spaceId !== this.spaceId) {
      this.setSpaceId(value.spaceId);
    } else {
      this.resetRoom();
    }

    this.room = value;
  }

  @action setRecords(records: PaginableResponse<RecordItem>): void {
    this.records = records;

    if (this.records.items.length === this.records.total) {
      this.setIsAllRecordsFetched(true);
    } else {
      this.setIsAllRecordsFetched(false);
    }
  }

  @action setRoomToRecordDownload(room: Room) {
    this.roomToRecordDownload = room;
  }

  @action addDownload(recordId: string): void {
    this.downloads = [...this.downloads, recordId];
  }

  @action removeDownload(recordId: string): void {
    this.downloads = this.downloads.filter((id) => id !== recordId);
  }

  @computed isRecordDownloading(recordId: string): boolean {
    return this.downloads.includes(recordId);
  }

  @action setSpaceId(value: string): void {
    this.resetSpace();
    this.spaceId = value;
  }

  @action resetRoomToRecordDownload() {
    this.roomToRecordDownload = null;
  }

  @action resetSpace(): void {
    this.spaceId = undefined;
    this.setRecordsSizeInSpace(0);
    this.setIsRecordsSizeInSpaceFetched(false);
    this.setIsRecordsSizeInSpaceFetching(false);

    this.resetRoom();
  }

  @action resetRoom(): void {
    this.records = undefined;
    this.downloads = [];
    this.room = null;
    this.error = null;
    this.setIsAllRecordsFetched(false);
  }

  private handleError(userError: string) {
    this.error = userError;
  }

  private setIsAllRecordsFetched(value: boolean): void {
    this.isAllRecordsFetched = value;
  }

  private setRecordsSizeInSpace(value: number): void {
    this.recordsSizeInSpace = value;
  }

  private setIsRecordsSizeInSpaceFetched(value: boolean): void {
    this.isRecordsSizeInSpaceFetched = value;
  }

  private setIsRecordsSizeInSpaceFetching(value: boolean): void {
    this.isRecordsSizeInSpaceFetching = value;
  }

  resetError() {
    this.error = null;
  }
}

export default CloudRecords;
