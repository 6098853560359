import {
  makeAutoObservable, observable, computed, action,
} from 'mobx';

import { lock } from 'decorators';
import StaticApi from 'services/MoodHoodApiClient/StaticApi';
import { TimeZone, TimeZoneLocale } from 'services/MoodHoodApiClient/StaticApi.types';
import logger from 'helpers/logger';
import api from 'services/MoodHoodApiClient/Api';

class TimeZones {
  // @observable timeZonesList: TimeZone[] | null = null;

  @observable timeZonesMap: Map<string, TimeZone> | null = null;

  @observable currentLocale: TimeZoneLocale | null = null;

  @observable error: string | null = null;

  @observable isBusy = false;

  private _timeZonesListsCollection: Record<TimeZoneLocale, TimeZone[] | null> = { en: null, ru: null };

  constructor(private staticApi: StaticApi) {
    makeAutoObservable(this);
  }

  @lock()
  async fetchTimeZones(locale: TimeZoneLocale) {
    const { data, error } = await this.staticApi.getTimeZones(locale);

    if (error) {
      logger.error('Timezones: failed to fetch');
      this.error = 'errors.failedToFetchTimeZones';
      return;
    }

    if (!data) {
      return;
    }

    this.currentLocale = locale;
    this.setTimeZonesListInCollection(locale, data.items);
    this.updateTimeZonesMap(data.items);
  }

  findTimeZoneById(id: string) {
    if (!this.timeZonesMap) {
      throw new Error('TimeZones: not fetched yet');
    }

    return this.timeZonesMap.get(id);
  }

  @computed hasActualTimezonesList(locale: TimeZoneLocale) {
    return Boolean(this.timeZonesList && this.currentLocale === locale);
  }

  @computed get timeZonesList() {
    return this.timeZonesMap ? [...this.timeZonesMap.values()] : null;
  }

  @action setTimeZonesListInCollection(locale: TimeZoneLocale, list: TimeZone[]) {
    this._timeZonesListsCollection[locale] = list;
  }

  @action async setActualTimeZonesList(locale: TimeZoneLocale) {
    const timeZones = this._timeZonesListsCollection[locale];
    if (timeZones) {
      this.updateTimeZonesMap(timeZones);
      return;
    }

    await this.fetchTimeZones(locale);
  }

  private updateTimeZonesMap(timeZones: TimeZone[]) {
    this.timeZonesMap = timeZones.reduce((acc, timeZone) => {
      acc.set(timeZone.id, {
        id: timeZone.id,
        title: timeZone.title,
        offset: timeZone.offset,
        titleWithOffset: `${timeZone.title} (GMT${timeZone.offset > -1 ? '+' : ''}${timeZone.offset / 60})`,
      });
      return acc;
    }, new Map<string, TimeZone>());
  }
}

export default new TimeZones(new StaticApi(api));
