import { AxiosInstance } from 'axios';
import axiosRetry from 'axios-retry';
import { getAxiosErrorMessage } from 'helpers/errors';

import { ApiResponse } from '../MoodHoodApiClient/types';
import { BatchLogPayload } from './types';

const RETRY_COUNT = 2;
const RETRY_DELAY_IN_MS = 1500;

export default class LogApi {
  private readonly api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
    axiosRetry(this.api, {
      retries: RETRY_COUNT,
      shouldResetTimeout: true,
      retryDelay: (retryCount) => retryCount * RETRY_DELAY_IN_MS,
      retryCondition: (error) => /^\/?log\//.test(error.config.url ?? '') || false,
    });
  }

  async batch(payload: BatchLogPayload): ApiResponse<void> {
    try {
      await this.api.post('/log/batch', payload);
      return { };
    } catch (err) {
      return { error: getAxiosErrorMessage(err) };
    }
  }
}
