import { ReactComponent as IconLight } from './icon-md-light.svg';
import { ReactComponent as IconDark } from './icon-md-dark.svg';
import { ReactComponent as LogoLight } from './logo-md-light.svg';
import { ReactComponent as LogoDark } from './logo-md-dark.svg';
import { ReactComponent as LogoMaxLight } from './logo-md-max-light.svg';
import { ReactComponent as LogoMaxDark } from './logo-md-max-dark.svg';

export const MoodhoodImages = {
  IconLight,
  IconDark,
  LogoLight,
  LogoDark,
  LogoMaxLight,
  LogoMaxDark,
};
