import styled from '@emotion/styled';
import { IconButton } from 'ui-kit';
import { MediaBreakPoint } from 'ui-kit/theme';

const Layout = styled(IconButton)({
  marginTop: 16,
  [MediaBreakPoint.WIDTH_568]: {
    marginTop: 4,
  },
});

export default Layout;
