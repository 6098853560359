import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';

import {
  Color, Font, Size, MediaBreakPoint,
} from 'ui-kit/theme';
import SuccessIcon from 'ui-kit/assets/icons/snackbar/success.svg';
import ErrorIcon from 'ui-kit/assets/icons/snackbar/error.svg';
import WarningIcon from 'ui-kit/assets/icons/snackbar/warning.svg';

export const StyledToastContainer = styled(ToastContainer)<{ isPanelsShown: boolean }>`
  width: auto;
  left: 0;
  bottom: ${({ isPanelsShown }) => (isPanelsShown ? Size.CONTROLPANEL_HEIGHT + 8 : 8)}px;
  padding: 0 8px;
  transition: bottom 700ms;
  
  ${MediaBreakPoint.WIDTH_640} {
    width: 100%;
    left: 0;
  }

  ${MediaBreakPoint.HEIGHT_900} {
    bottom: ${({ isPanelsShown }) => (isPanelsShown ? Size.CONTROLPANEL_HEIGHT_960 + 8 : 8)}px;
  }
  
  .Toastify__toast {
    align-items: center;
    min-height: 56px;
    border-radius: 15px;
    background: ${Color.DARK1};
    &:last-child {
      margin-bottom: 0;
    }
  }

  .Toastify__toast-body {
    color: #FFFFFF;
    font-family: ${Font.BRAND_BOLD};
    font-size: 16px;
    line-height: 20px;
  }

  .Toastify__toast--default,
  .Toastify__toast--info {
    padding-left: 16px;
  }

  .Toastify__toast--success,
  .Toastify__toast--warning,
  .Toastify__toast--error {
    padding-left: 32px;
    background-repeat: no-repeat;
    background-position: 10px center;
  }

  .Toastify__toast--success {
    background-image: url('${SuccessIcon}');
  }

  .Toastify__toast--error {
    background-image: url('${ErrorIcon}');
  }

  .Toastify__toast--warning {
    background-image: url('${WarningIcon}');
  }

  .Toastify__close-button {
    align-self: start;
    margin-top: 12px;
  }

  .Toastify__close-button > svg {
    color: #FFFFFF;
  }
`;
