import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import isMobile from 'is-mobile';

import { Dialog } from 'ui-kit';

import * as LayoutMobile from './LayoutMobile';
import * as LayoutDesktop from './LayoutDesktop';
import { TDialogType } from './types';

interface IDialogConfig {
  title: string;
  acceptText?: string;
  declineText?: string;
  body: string | ReactElement;
  type?: TDialogType,
  withoutDecline?: boolean;
  onAccept(): void | Promise<void>;
  onDecline?(): void | Promise<void>;
  onClose?(): void | Promise<void>;
}

interface IDialogContext {
  openDialog(config:IDialogConfig): void;
}

const ConfirmDialogContext = React.createContext<Partial<IDialogContext>>({});

const ConfirmDialogProvider: FunctionComponent = ({ children }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogConfig, setDialogConfig] = React.useState<IDialogConfig | null>(null);
  const { t } = useTranslation();

  const openDialog = useCallback((config:IDialogConfig) => {
    setDialogOpen(true);
    setDialogConfig(config);
  }, []);

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig(null);
  };

  const onConfirm = useCallback(() => {
    resetDialog();
    dialogConfig?.onAccept();
  }, [dialogConfig]);

  const onDismiss = useCallback(() => {
    resetDialog();
    dialogConfig?.onDecline?.();
  }, [dialogConfig]);

  const handleOnClose = () => {
    dialogConfig?.onClose?.();
    setDialogOpen(false);
  };

  const renderDialog = () => {
    if (!dialogConfig || !dialogOpen) {
      return null;
    }

    const Layout = isMobile() ? LayoutMobile : LayoutDesktop;

    const {
      title, body, declineText, acceptText, type,
      withoutDecline,
    } = dialogConfig;

    return (
      <Dialog canClose={false} onClose={handleOnClose} title={title}>
        <Layout.Layout>
          <Layout.Message>{body}</Layout.Message>
          <Layout.Buttons>
            <Layout.PrimaryButton
              id="apply-dialog-button"
              buttonSize="medium"
              dialogVariant={type || 'confirm'}
              onClick={onConfirm}
              variant="primary"
            >
              {acceptText || t('common.ok')}
            </Layout.PrimaryButton>

            {!withoutDecline && (
              <Layout.Button
                id="abort-dialog-button"
                buttonSize="medium"
                onClick={onDismiss}
              >
                {declineText || t('common.cancel')}
              </Layout.Button>
            )}
          </Layout.Buttons>
        </Layout.Layout>
      </Dialog>
    );
  };

  const providerValue = useMemo(() => ({ openDialog }), [openDialog]);

  return (
    <ConfirmDialogContext.Provider value={providerValue}>
      {renderDialog()}
      {children}
    </ConfirmDialogContext.Provider>
  );
};

const useConfirmDialog = (): { getConfirmation: IDialogContext['openDialog'] } => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { openDialog: getConfirmation } = React.useContext(ConfirmDialogContext) as IDialogContext;

  return { getConfirmation };
};

export { ConfirmDialogProvider, useConfirmDialog };
