import cookies from 'js-cookie';

import { extractCookieValues, getDomainWithoutSubdomains, safeDecodeString } from './browser';
import { META_KEYS } from './types';

const LANDING_COOKIES_META_NAME = 'ld-landing';

const getPageLandingMetaParamsFromCookies = (): Record<string, string> => {
  try {
    const cookiesValue = cookies.get(LANDING_COOKIES_META_NAME);

    const cookiesValueAsObject = JSON.parse(cookiesValue || '{}') as Record<string, string>;
    if (Object.keys(cookiesValueAsObject).length === 0) {
      return {};
    }

    return extractCookieValues(META_KEYS, cookiesValueAsObject);
  } catch {
    return {};
  }
};

export const getPageMetaParams = (stripPrefix = true): Record<string, string> => {
  const urlParams = new URLSearchParams(window.location.search);
  const paramKeys = [...urlParams.keys()];
  const reMetaKey = /^meta_.+/;

  const urlMetaParams = paramKeys.reduce((acc: Record<string, string>, key) => {
    if (!reMetaKey.test(key)) {
      return acc;
    }

    const value = safeDecodeString(urlParams.get(key) || '');
    if (value) {
      const keyName = stripPrefix ? key.replace(/^meta_/, '') : key;
      acc[keyName] = value;
    }

    return acc;
  }, {});

  const cookiesMetaParams = getPageLandingMetaParamsFromCookies();

  const metaParams = {
    ...urlMetaParams,
    ...cookiesMetaParams,
  };

  if (!metaParams.referrer && document.referrer.length) {
    metaParams.referrer = document.referrer;
  }

  return metaParams;
};

export const setCookiesExternalReferrer = (): void => {
  try {
    if (!document.referrer.length) {
      return;
    }

    const documentReferrer = document.referrer;
    const currentPageUrl = window.location.origin;
    const isSameDomains = getDomainWithoutSubdomains(documentReferrer) === getDomainWithoutSubdomains(currentPageUrl);

    if (isSameDomains) {
      return;
    }

    cookies.set(LANDING_COOKIES_META_NAME, { referrer: [documentReferrer] }, { expires: 1 });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
