import { AxiosInstance } from 'axios';

import { getAxiosErrorMessage } from 'helpers/errors';

import { Oauth2ClientResponse, Oauth2ClientView } from '../types';

class Oauth2ClientApi {
  private api: AxiosInstance;

  constructor(api: AxiosInstance) {
    this.api = api;
  }

  async get(spaceId: string): Promise<Oauth2ClientView> {
    try {
      const { data } = await this.api.get<Oauth2ClientView>(`spaces/${spaceId}/oauth2-client`);
      return data;
    } catch (error) {
      throw new Error(getAxiosErrorMessage(error));
    }
  }

  async create(spaceId: string): Promise<Oauth2ClientResponse> {
    try {
      const { data } = await this.api.post<Oauth2ClientResponse>(`spaces/${spaceId}/oauth2-client`);
      return data;
    } catch (error) {
      throw new Error(getAxiosErrorMessage(error));
    }
  }

  async delete(spaceId: string): Promise<void> {
    try {
      await this.api.delete(`spaces/${spaceId}/oauth2-client`);
    } catch (error) {
      throw new Error(getAxiosErrorMessage(error));
    }
  }
}

export default Oauth2ClientApi;
