import React, {
  FunctionComponent, useEffect, useState,
} from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import {
  IconButton,
  FullPageLoader,
  InputMessage,
  Select,
} from 'ui-kit';
import useStores from 'stores/root';
import { Errors } from 'constants/errors';
import { StatsId } from 'types/stats';
import { getUrlParam, isValidRedirectUrl } from 'helpers/url';
import { OptionType } from 'ui-kit/components/SelectInput/types';
import { MAX_USERNAME_LENGTH, MIN_USERNAME_LENGTH } from 'constants/common';
import { getFormInputClearButton } from 'ui-kit/components/Input/getFormInputClearButton';
import { isValidUserName } from 'helpers/common';
import { getBrandOptions } from 'modules/Branding';
import TeleBoss from 'components/Optional/TeleBoss';

import { Input } from './Forms.styled';
import FormError from './FormError';
import ReferralCode from '../ReferralCode';

interface FormFields {
  username: string;
  targetUse: OptionType & { message?: string };
}

const SignUpFormStep2: FunctionComponent<{
  className?: string,
  separator?: React.ReactNode,
}> = observer(({
  separator,
  className,
}) => {
  const {
    authStore,
    userStore,
    userStore: { isUserInfoFetched, userInfo },
  } = useStores();
  const [errorCode, setErrorCode] = useState<string>();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const { t } = useTranslation();
  const [redirectTo, setRedirectTo] = useState<string>();

  const {
    control,
    register,
    handleSubmit,
    formState,
    getValues,
    setValue,
    clearErrors,
    setFocus,
  } = useForm<FormFields>({
    mode: 'all',
    defaultValues: {
      username: userInfo?.username,
      targetUse: undefined,
    },
  });

  const { errors, isValid } = formState;

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    setIsSubmitDisabled(true);

    const { error } = await userStore.updateUser({
      username: data.username,
      targetUse: data.targetUse.value,
    });

    setErrorCode(error);

    if (!error) {
      const to = getUrlParam('to') || '';

      if (!isValidRedirectUrl(to)) {
        setRedirectTo('/');
        return;
      }

      setRedirectTo(to);
    }

    setIsSubmitDisabled(false);
  };

  useEffect(() => {
    if (isValidUserName(userInfo?.username, userInfo?.email)) {
      const to = getUrlParam('to') || '';
      if (!isValidRedirectUrl(to)) {
        setRedirectTo('/');
        return;
      }

      setRedirectTo(to);
    }
  }, [userStore.userInfo, userStore.isUserInfoFetched]);

  if (redirectTo) {
    return (
      <Redirect to={redirectTo} />
    );
  }

  if (!isUserInfoFetched || isValidUserName(userInfo?.username, userInfo?.email)) {
    return (
      <FullPageLoader />
    );
  }

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Input
            id="username"
            fullWidth
            label={t('auth.username')}
            type="name"
            required
            autoComplete="username"
            placeholder={t('auth.enterYourUsername')}
            {...register('username', {
              required: {
                value: true,
                message: t('auth.usernameIsRequired'),
              },
              minLength: {
                value: MIN_USERNAME_LENGTH,
                message: t('auth.usernameMinLength', { value: MIN_USERNAME_LENGTH }),
              },
              maxLength: {
                value: MAX_USERNAME_LENGTH,
                message: t('auth.usernameMaxLength', { value: MAX_USERNAME_LENGTH }),
              },
              validate: (name) => {
                const message = t('auth.usernameNEEmail').toString();

                return isValidUserName(name, userInfo?.email) ? true : message;
              },
            })}
            hasError={Boolean(errors.username)}
            endInputElement={getFormInputClearButton<FormFields>({
              name: 'username', getValues, setValue, clearErrors, setFocus,
            })}
          >
            <InputMessage>
              {errors.username?.message}
            </InputMessage>
          </Input>
          <Controller
            name="targetUse"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                id="target-use"
                hasError={Boolean(errors.targetUse)}
                errorText={errors.targetUse?.message?.message}
                label={t('auth.purposeUsing', { brand: t(getBrandOptions().name) })}
                placeholder={t('auth.purposeUsingPlaceholder')}
                options={[
                  { label: t('auth.personal').toString(), value: 'personal' },
                  { label: t('auth.company').toString(), value: 'company' },
                ]}
              />
            )}
            rules={{
              required: { value: true, message: t('common.required') },
            }}
          />
        </div>
        <TeleBoss>
          <ReferralCode />
        </TeleBoss>
        {separator}
        <div>
          {errorCode === Errors.SOMETHING_WENT_WRONG && (
            <FormError title={t('errors.somethingWentWrong')}>
              {t('errors.somethingWentWrongBody')}
            </FormError>
          )}
          <IconButton
            id="register"
            type="submit"
            fullWidth
            isLoading={authStore.isLoading}
            data-stats={StatsId.SignupUser}
            disabled={isSubmitDisabled || !isValid}
            variant="primary"
          >
            {t('common.submit')}
          </IconButton>
        </div>
      </form>
    </div>
  );
});

export default SignUpFormStep2;
