import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Label, SelectRoot } from './select.styled';
import SelectInputAsyncPaginate, { SelectInputAsyncPaginateProps } from '../SelectInput/SelectInputAsyncPaginate';
import { SelectWrapperProps } from './types';

const SelectAsyncPaginate = forwardRef<HTMLDivElement, SelectWrapperProps & SelectInputAsyncPaginateProps>(({
  id,
  children,
  title = undefined,
  label = '',
  className = '',
  placeholder = undefined,
  inject = undefined,
  style,
  hasError = false,
  variant,
  loadOptions,
  ...restProps
}, forwardedRef) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dataSet = { 'data-stats': (restProps as any)['data-stats'] };

  return (
    <SelectRoot
      as="div"
      size="main-text-medium"
      ref={forwardedRef}
      {...dataSet}
      title={title}
      className={className}
      style={style}
    >
      {label && (
        <Label
          as="label"
          size="label-text-bold"
          id={`${id}-label`}
          htmlFor={`${id}-select`}
        >
          {label}
        </Label>
      )}
      <SelectInputAsyncPaginate
        variant={variant}
        inject={inject}
        id={`${id}-select`}
        hasError={hasError}
        isSearchable={false}
        placeholder={placeholder || t('common.selectInputPlaceHolder')}
        {...restProps}
        loadOptions={loadOptions}
      />
      {children}
    </SelectRoot>
  );
});

export default SelectAsyncPaginate;
