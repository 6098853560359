import { makeAutoObservable, action, observable } from 'mobx';

import { Call } from 'types/common';
import logger from 'helpers/logger';
import CallsApi from 'services/MoodHoodApiClient/CallsApi';
import Api from 'services/MoodHoodApiClient/Api';

class CallStore {
  @observable calls: Call[] | null = null;

  private callsApi: CallsApi;

  constructor() {
    this.callsApi = new CallsApi(Api);
    makeAutoObservable(this);
  }

  @action async loadCalls(spaceId: string, roomId: string, date: Date, dateTo?: Date): Promise<void> {
    if (spaceId && roomId) {
      this.calls = null;
      const { data: calls } = await this.callsApi.fetchCalls(spaceId, roomId, date, dateTo);
      if (calls) {
        this.calls = calls;
      }
    } else {
      logger.error('Failed to load calls: roomId or spaceId not provided');
    }
  }
}

export default CallStore;
