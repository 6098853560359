import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import useStores from 'stores/root';
import useStopWatch from 'hooks/useStopWatch';

import { PUT_HAND_AUTOMATICALLY_ID, HIDE_TOAST_TIMEOUT_IN_SEC } from './const';
import Layout, { KeepRaisedButton } from './Layout';

const PutHandDownAutomatically = observer(() => {
  const { t } = useTranslation();
  const { participantStore } = useStores();

  const [start, stop] = useStopWatch(() => {
    toast.dismiss(PUT_HAND_AUTOMATICALLY_ID);
    if (participantStore.peer?.appData.isHandRaised) {
      participantStore.participantAppData.toggleRaiseHand();
    }
  }, HIDE_TOAST_TIMEOUT_IN_SEC * 1000);

  const handleKeepHandRaised = () => {
    toast.dismiss(PUT_HAND_AUTOMATICALLY_ID);
  };

  useEffect(() => {
    start();
    return () => stop();
  }, []);

  return (
    <Layout>
      <div>{t('room.putHandDown.toastMessage')}</div>
      <KeepRaisedButton
        id="keep-hand-raised"
        onClick={handleKeepHandRaised}
      >
        {t('room.putHandDown.keepRaised')}
      </KeepRaisedButton>
    </Layout>
  );
});

export default PutHandDownAutomatically;
