import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { FlexCenterSpaceBetween } from 'ui-kit';

import {
  Content,
  Logo,
  Separator,
  Text,
  Wrapper,
} from './index.styled';

const PageMobile: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Content>
        <FlexCenterSpaceBetween>
          <Text>
            {t('auth.welcome')}
          </Text>
          <Logo isClickable={false} brandOnly />
        </FlexCenterSpaceBetween>
        <Separator />
        {children}
      </Content>
    </Wrapper>
  );
};

export default PageMobile;
