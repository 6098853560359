import { checkOSRequirements } from 'helpers/browser';
import { getUrlParam } from 'helpers/url';

import { QoSEventTag } from './types';
import { VLProjectSpacesToIgnore } from './constants';

export function getEventTag(spaceId?: string): string | undefined {
  const { isUnSupportedOS } = checkOSRequirements();
  if (isUnSupportedOS) {
    return QoSEventTag.UnsupportedOs;
  }

  const tag = getUrlParam('sessionTag');
  if (tag) {
    return tag;
  }

  if (spaceId && VLProjectSpacesToIgnore.includes(spaceId)) {
    return QoSEventTag.VLProjects;
  }

  return undefined;
}
