import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react-lite';
import copyToClipboard from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { IconButton } from 'ui-kit';
import { EnterIcon, CopyLinkIcon, StatisticsIcon } from 'ui-kit/assets/icons';
import { HTTPS_AND_CURRENT_HOST } from 'constants/common';

import { EventState } from './types';

interface DefaultButtonsProps {
  playbackEventId: string,
  state?: EventState,
  isContinous: boolean,
  onDownloadAnalyticsReport(): void,
}

const DefaultButtons: FunctionComponent<DefaultButtonsProps> = observer(({
  state, playbackEventId, isContinous, onDownloadAnalyticsReport,
}) => {
  const { t } = useTranslation();
  const link = `${HTTPS_AND_CURRENT_HOST}/event/${playbackEventId}`;

  const handleClickLink = () => {
    if (!playbackEventId) {
      return;
    }

    copyToClipboard(link);
    toast.success(t('room.copied'));
  };

  const handleAnalyticsClick = () => {
    onDownloadAnalyticsReport();
  };

  switch (state) {
    case 'future':
      return (
        <IconButton
          id="session-link"
          onClick={handleClickLink}
        >
          <CopyLinkIcon />
        </IconButton>
      );
    case 'continuous':
    case 'now':
      return (
        <>
          <IconButton
            id="link"
            onClick={handleClickLink}
          >
            <CopyLinkIcon />
          </IconButton>
          <IconButton
            id="open-session"
            variant="primary"
            as="a"
            target="_blank"
            href={link}
          >
            <EnterIcon />
          </IconButton>
        </>
      );
    case 'past':
      return isContinous ? null : (
        <IconButton
          id="session-analytics"
          onClick={handleAnalyticsClick}
        >
          <StatisticsIcon />
        </IconButton>
      );
    default:
      return null;
  }
});

export default DefaultButtons;
