import { MAX_USERNAME_LENGTH } from 'constants/common';
import { UrlParam } from 'types/common';

import {
  UserParams,
} from './types';

class UrlQueryParams {
  get params(): Partial<Record<UrlParam, string>> {
    const urlParams = new URLSearchParams(window.location.search);
    let params = {};
    urlParams.forEach((value, name) => {
      params = { ...params, [name]: value };
    });

    return params;
  }

  get userParams(): UserParams {
    const {
      participantName,
      email,
      phone,
      externalUserId,
    } = this.params;

    return {
      participantName: participantName && participantName.slice(0, MAX_USERNAME_LENGTH),
      email,
      phone,
      externalUserId,
    };
  }

  get hasEmailQueryParam() {
    return Boolean(this.params.email);
  }

  get hasPhoneQueryParam() {
    return Boolean(this.params.phone);
  }
}

export default new UrlQueryParams();
