import i18n from 'i18next';
import { IErrorPageData } from '../types/common';

export enum Errors {
  SOMETHING_WENT_WRONG = 'something_went_wrong',
  NOT_FOUND = 'not_found',
  DEVICE_IS_DISCONNECTED = 'device_is_disconnected',
  AUTH_ERROR = 'auth_error',
  AUTHENTICATION_REQUIRED = 'authentication_required',
  ACCESS_DENIED = 'access_denied',
  ALREADY_REGISTERED = 'already_registered',
  ROOM_NOT_FOUND_BY_ROOM_ID = 'room not found by roomId',
  INVALID_GRANT = 'invalid_grant',
  INCORRECT_CURRENT_PASSWORD = 'incorrect_current_password',
  INVITE_EXPIRED_OR_NOT_EXIST = 'invite_expired_or_not_exist',
  INVITE_CONFLICT = 'invite_conflict',
  NO_PERMISSION_FOR_SPACE = 'no_permission_for_space',
  NO_PERMISSION_FOR_ROOM = 'no_permission_for_room',
  PARTICIPANT_PERMISSION_ERROR = 'participant_permission_error',
  CONFIRMATION_TOKEN_INVALID = 'confirmation_token_invalid',
}

const ERROR_PAGE_ERRORS = {
  [Errors.SOMETHING_WENT_WRONG]: {
    error: i18n.t('errors.somethingWentWrong'),
  },
  [Errors.ROOM_NOT_FOUND_BY_ROOM_ID]: {
    error: i18n.t('errors.thisRoomDoesNotExist.errorText'),
    hint: i18n.t('errors.thisRoomDoesNotExist.hint'),
  },
  [Errors.DEVICE_IS_DISCONNECTED]: {
    error: i18n.t('errors.yourDeviceIsDisconnected'),
  },
  [Errors.AUTH_ERROR]: {
    error: i18n.t('errors.authenticationError.text'),
    hint: i18n.t('errors.authenticationError.hint'),
  },
  [Errors.ACCESS_DENIED]: {
    error: i18n.t('errors.accessDenied'),
  },
  [Errors.INVITE_EXPIRED_OR_NOT_EXIST]: {
    error: i18n.t('errors.inviteExpiredOrNotExists.errorText'),
    hint: i18n.t('errors.inviteExpiredOrNotExists.hint'),
  },
  [Errors.INVITE_CONFLICT]: {
    error: i18n.t('errors.inviteConflict.errorText'),
  },
  [Errors.NO_PERMISSION_FOR_SPACE]: {
    error: i18n.t('errors.noPermissionForSpace.errorText'),
    hint: i18n.t('errors.noPermissionForSpace.hint'),
  },
  [Errors.NO_PERMISSION_FOR_ROOM]: {
    error: i18n.t('errors.noPermissionForRoom.errorText'),
    hint: i18n.t('errors.noPermissionForRoom.hint'),
  },
  [Errors.CONFIRMATION_TOKEN_INVALID]: {
    error: i18n.t('errors.confirmationTokenInvalid.errorText'),
    hint: i18n.t('errors.confirmationTokenInvalid.hint'),
  },
} as { [key: string]: IErrorPageData };

export default ERROR_PAGE_ERRORS;
