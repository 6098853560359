import styled from '@emotion/styled';

import AvatarBase from 'ui-kit/components/Avatar';
import { Color } from 'ui-kit/theme';

export const Avatar = styled(AvatarBase)({
  position: 'relative',
  width: 114,
  height: 114,
  fontSize: '40px',
  border: `1px solid ${Color.GREY4}`,
  borderRadius: 5,
  '&:hover': {
    borderColor: Color.GREY16,
  },
  '&:hover > label': {
    visibility: 'visible',
  },
  '& > input': {
    display: 'none',
  },
});
