import React from 'react';

import { IconButton } from 'ui-kit';
import { CloseMiniIcon } from 'ui-kit/assets/icons';

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const getFormInputClearButton = <T,>({
  name,
  getValues,
  setValue,
  clearErrors,
  setFocus,
}: {
  name: keyof T,
  getValues: (name: keyof T) => string,
  setValue: (name: keyof T, value: string) => void,
  clearErrors: (name: keyof T) => void,
  setFocus: (name: keyof T) => void,
}): JSX.Element | undefined => {
  if (!getValues(name)) {
    return undefined;
  }

  return (
    <IconButton
      type="button"
      id={`${name as string}-clear`}
      style={{ margin: '0 6px 0 4px' }}
      onClick={() => {
        setValue(name, '');
        clearErrors(name);
        setFocus(name);
      }}
      size="small"
    >
      <CloseMiniIcon />
    </IconButton>
  );
};
