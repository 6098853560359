import styled from '@emotion/styled';

import { MessagesIcon } from 'ui-kit/assets/icons';
import { Color, ZIndex } from 'ui-kit/theme';

const Layout = styled.button<{ isMobile: boolean }>(({ isMobile }) => ({
  position: 'absolute',
  zIndex: ZIndex.MAX,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  right: 27,
  bottom: 27,
  width: 48,
  height: 48,
  backgroundColor: Color.BLUE6,
  border: 'none',
  borderRadius: 24,
  ...(isMobile && {
    right: 7,
    bottom: 7,
  }),
  '&:not([disabled]):hover': {
    filter: 'brightness(1.3)',
    transition: 'filter 200ms linear',
  },
  '&:not([disabled]):active': {
    transform: 'scale(0.95, 0.95)',
  },
}));

export const SupportIcon = styled(MessagesIcon)({
  color: 'white',
  currentColor: Color.BLUE6,
});

export default Layout;
