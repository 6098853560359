import { observer } from 'mobx-react-lite';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'ui-kit';
import { cronDaysOfWeekToString } from 'helpers/common';

import { EventSessionFrequency } from 'services/MoodHoodApiClient/Playbacks.types';
import {
  EventCardRoot, EventInfo, EventHeader,
  EventDescription, EventParticipants, Buttons, Badges, TimeZone,
  DateTime,
} from './index.styled';
import { EventState, PlaybackEventSessionsListItemProps } from './types';
import DefaultButtons from './DefaultButtons';

const stateToText: Record<EventState, string> = {
  past: 'events.participantsPast',
  now: 'events.participantsNow',
  future: 'events.participantsFeature',
  continuous: 'events.participantsFeature',
};

const PlaybackEventSessionsListItem: FunctionComponent<PlaybackEventSessionsListItemProps> = observer(({
  playbackEventId,
  sessionId,
  buttons,
  date = '',
  time = '',
  recurrence = '',
  timeZone = '',
  state,
  participantsCount,
  onDownloadAnalyticsReport,
  frequency,
}) => {
  const { t } = useTranslation();

  const getStateBadge = () => {
    switch (state) {
      case 'past':
        return <Badge variant="red">{t('events.completedBadge')}</Badge>;
      case 'now':
        return <Badge variant="green">{t('events.ongoingBadge')}</Badge>;
      case 'future':
        return <Badge variant="primary">{t('events.notStartedBadge')}</Badge>;
      default:
        return null;
    }
  };

  const getDateBadge = () => {
    if (!date) {
      return null;
    }

    if (recurrence) {
      const days = cronDaysOfWeekToString(recurrence, t('common.weekDaysShort'));

      return (
        <>
          <Badge variant="accent">{t('events.recurrentBadge')}</Badge>
          { days && <Badge variant="accent">{days}</Badge> }
          <Badge variant="accent">{time}</Badge>
        </>
      );
    }

    return (
      <>
        <Badge variant="yellow">{t('events.recurrentOnceBadge')}</Badge>
        <Badge variant="yellow">{t('events.eventDate', { date, time })}</Badge>
      </>
    );
  };

  const stateBadge = getStateBadge();
  const dateBadge = getDateBadge();
  const stateVariant = state && (['now'].includes(state) ? 'success' : undefined);
  const cardVariant = stateVariant;
  const isContinous = frequency === EventSessionFrequency.Permanent;

  return (
    <EventCardRoot variant={cardVariant}>
      <EventInfo>
        <EventHeader>
          <DateTime size="main-text-bold">
            {date ? t('events.eventDate', { date, time }) : t('events.continuous')}
          </DateTime>
          {((stateBadge || dateBadge) && !isContinous) && (
            <Badges>
              {stateBadge }
              {dateBadge}
            </Badges>
          )}
          <TimeZone size="main-text-medium">{timeZone}</TimeZone>
        </EventHeader>
        {(state && !isContinous) && (
          <EventDescription>
            {t(stateToText[state])}
            {(state !== 'future') && (
              <EventParticipants size="main-text-bold">{participantsCount}</EventParticipants>
            )}
          </EventDescription>
        )}
      </EventInfo>
      <Buttons>
        {buttons || (
          <DefaultButtons
            onDownloadAnalyticsReport={() => sessionId && onDownloadAnalyticsReport(playbackEventId, sessionId)}
            state={state}
            isContinous={isContinous}
            playbackEventId={playbackEventId}
          />
        )}
      </Buttons>
    </EventCardRoot>
  );
});

export default PlaybackEventSessionsListItem;
