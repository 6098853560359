import { PaymentSystem } from 'types/payment';

export enum PaymentPlan {
  free = 'free',

  personal_nov1323_1m = 'personal_nov1323_1m',
  personal_nov1323_12m = 'personal_nov1323_12m',

  business_nov1323_1m = 'business_nov1323_1m',
  business_nov1323_12m = 'business_nov1323_12m',

  team_nov1323_1m = 'team_nov1323_1m',
  team_nov1323_12m = 'team_nov1323_12m',

  enterprise_feb17 = 'enterprise_feb17',

  team_newyear23_1m = 'team_newyear23_1m',
  team_newyear23_12m = 'team_newyear23_12m',

  teleboss_free = 'teleboss_free',

  teleboss_enterprise = 'teleboss_enterprise',

  teleboss_personal_jan01_1m = 'teleboss_personal_jan01_1m',
  teleboss_personal_jan01_12m = 'teleboss_personal_jan01_12m',

  pro_jan2924_1m = 'pro_jan2924_1m',
  pro_jan2924_12m = 'pro_jan2924_12m',

  business_jan2924_1m = 'business_jan2924_1m',
  business_jan2924_12m = 'business_jan2924_12m',
}

export const PRODAMUS_URL = process.env.REACT_APP_PRODAMUS_PAYFORM_LINK;

export const PAYMENT_SYSTEM = PRODAMUS_URL
  ? PaymentSystem.Prodamus
  : PaymentSystem.Yookassa;
