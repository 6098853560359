import { withLDProvider } from 'launchdarkly-react-client-sdk';

import App from './App';

const AppWithLD = withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_KEY as string,
  /* to bootstrap flags with default values, use flags: {'some-flag': true} */
  options: {
    logger: process.env.REACT_APP_LAUNCH_DARKLY_NO_LOGS?.toLowerCase() === 'true' ? {
      debug: () => {},
      info: () => {},
      warn: () => {},
      error: () => {},
    } : undefined,
  },
})(App);

export default AppWithLD;
