import React, { FunctionComponent, forwardRef } from 'react';

import { CloseMiniIcon } from 'ui-kit/assets/icons';

import {
  Label,
  InputBox,
  Hint,
  InputLayout,
  InputContainer,
  ClearButton,
  Controls,
} from './index.styled';
import { InputProps } from './types';

const Input: FunctionComponent<InputProps> = forwardRef<HTMLInputElement, InputProps>(({
  variant = 'medium',
  hint,
  state,
  label,
  id,
  value,
  showClearButton,
  onClear,
  onChange,
  className,
  children,
  fullWidth,
  startInputElement,
  inputElement,
  inputElementProps,
  endInputElement,
  required,
  style,
  inputContainerProps,
  asideControls,
  ...rest
}, forwardedRef) => {
  const inputId = `${id || ''}-input`;
  const labelId = `${id || ''}-label`;

  const handleClearClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    if (value === undefined) {
      /* not controllable component */
      const target = evt.target as HTMLElement;
      const input = target?.closest('button')?.parentElement?.querySelector(`#${inputId}`) as HTMLInputElement;
      input.value = '';
      input.focus();
    }

    onClear?.();
  };

  return (
    <InputLayout className={className} state={state} fullWidth={fullWidth}>
      {label && (
        <Label id={labelId} htmlFor={inputId}>
          {label}
        </Label>
      )}
      <Controls>
        <InputContainer
          variant={variant}
          {...inputContainerProps}
          showClearButton={showClearButton}
        >
          {startInputElement}
          <InputBox
            id={inputId}
            value={value}
            variant={variant}
            onChange={onChange}
            ref={forwardedRef}
            {...rest}
            {...inputElementProps}
          />
          {
            showClearButton && (
              <ClearButton
                id={`${id}-clear`}
                size={variant === 'large' ? 'small' : 'tiny'}
                onClick={handleClearClick}
              >
                <CloseMiniIcon />
              </ClearButton>
            )
          }
          {endInputElement}
        </InputContainer>
        {asideControls}
      </Controls>

      {hint && <Hint>{hint}</Hint>}
    </InputLayout>
  );
});

export default Input;
