import React, {
  VoidFunctionComponent, useEffect, useMemo, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getLocalStorageItem, removeLocalStorageItem } from 'helpers/localStorage';
import { FeatureFlags } from 'types/featureFlags';
import useStores from 'stores/root';
import { GetRoomResponse } from 'services/MoodHoodApiClient/RoomApi.types';
import { RoomType } from 'types/common';

import AfterRegisterDialog from './AfterRegisterDialog';

const excludePaths = ['/room/', '/auth/'];

export const REGISTERED_LS_KEY = 'registered';

const AfterRegisterDialogWrapper: VoidFunctionComponent = observer(() => {
  const {
    authStore,
    roomStore,
    spaceStore,
    userStore,
    rootStore: { moodHoodApiClient },
  } = useStores();

  const location = useLocation();

  const { afterRegisterPopup } = useFlags<FeatureFlags>();

  const isCurrentUserRegisteredLSFlag = getLocalStorageItem(REGISTERED_LS_KEY) === userStore.email;

  const isExcludePath = useMemo(() => excludePaths.some((x) => location.pathname.startsWith(x)), [location]);

  useEffect(() => {
    if (roomStore.isRoomJoined && isCurrentUserRegisteredLSFlag) {
      removeLocalStorageItem(REGISTERED_LS_KEY);
    }
  }, [isCurrentUserRegisteredLSFlag, roomStore.isRoomJoined]);

  const [room, setRoom] = useState<GetRoomResponse>();

  useEffect(() => {
    if (!afterRegisterPopup
      || isExcludePath
      || !isCurrentUserRegisteredLSFlag
      || !authStore.isUser
      || !spaceStore.space?.id) {
      return;
    }

    const fetchRoom = async (spaceId: string) => {
      const { data } = await moodHoodApiClient.room.getRooms(spaceId, { limit: 1, type: RoomType.Lesson });
      if (data && data.items.length > 0) {
        setRoom(data.items[0]);
      }
    };

    fetchRoom(spaceStore.space?.id);
  }, [spaceStore.space?.id]);

  if (!afterRegisterPopup
    || isExcludePath
    || !isCurrentUserRegisteredLSFlag
    || !authStore.isUser
    || !spaceStore?.space?.id
    || !room) {
    return null;
  }

  return (
    <AfterRegisterDialog room={room} />
  );
});

export default AfterRegisterDialogWrapper;
