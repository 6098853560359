import React, {
  useRef,
  Suspense,
  useState,
  useEffect,
  useCallback,
  FunctionComponent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { eventBus } from 'mobx-event-bus2';
import { useTheme } from '@emotion/react';

import { Loader } from 'ui-kit';
import DelayedFallbackMessage from 'components/LazyComponents/DelayedFallbackMessage';
import useStores from 'stores/root';
import useMetrics from 'hooks/useMetrics';
import logger from 'helpers/logger';
import qos from 'services/QualityOfService';
import { AppState } from 'types/common';
import loadWithRetry from 'components/LazyComponents/loadWithRetry';
import chatEventBus from 'services/ChatEventBus';
import chatSnackbar from 'ui-kit/components/Snackbar/ChatSnackbar';

import Layout from './Layout';
import ErrorBoundary from '../ErrorPage/ErrorBoundary';
import {
  ChatErrorPayload,
  ChatEvent,
  ChatLogPayload,
  ChatPropsWithEvents,
} from './types';

const Chat: FunctionComponent<ChatPropsWithEvents> = observer(({
  isVisible,
  onErrorHandle,
  errorTimeout,
  ...restProps
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { ChatSnackbarContainer, ...snackbars } = chatSnackbar;

  const {
    uiStore,
    userStore,
    roomStore,
    eventStore,
    spaceStore: { space },
  } = useStores();

  const [isChatReady, setIsChatReady] = useState(false);

  const [VLChat, setVLChat] = useState<ReturnType<typeof loadWithRetry> | undefined>(undefined);

  const metrics = useMetrics();
  const [chatUserToken, setChatUserToken] = useState('');

  const chatRef = useRef<HTMLDivElement | null>(null);

  const onChatReady = () => {
    setIsChatReady(true);
    qos.reportChatInitialized();
    eventBus.post(AppState.ChatInitialized, chatRef.current);
  };

  const tid = useRef<NodeJS.Timeout>();

  const handleChatError = () => {
    clearTimeout(tid.current);
    tid.current = undefined;
    onErrorHandle?.();
  };

  const handleChatEvent = useCallback(({ event, data }) => {
    switch (event) {
      case ChatEvent.MessageSent: {
        const id = roomStore.roomAlias || eventStore.event?.id;
        if (id) {
          metrics.trackSentChatMessage(id);
        }

        return undefined;
      }
      case ChatEvent.ChatIsReady:
        return onChatReady();
      case ChatEvent.ChatClose:
        return uiStore.hideSidePanel();
      case ChatEvent.OpenInPopup:
        return uiStore.openChatInPopupWindow();
      case ChatEvent.ChatExpand:
        return uiStore.toggleIsChatExpanded();
      case ChatEvent.ChatLog:
      {
        const { level, message, meta } = data as ChatLogPayload;
        return logger.log(level, message, meta);
      }
      case ChatEvent.ChatError: {
        handleChatError();
        const { message, severity, code } = data as ChatErrorPayload;
        return logger.error(message, {
          severity, code,
        });
      }
      default:
        return null;
    }
  }, [roomStore.roomAlias, eventStore.event?.id]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    setVLChat(loadWithRetry(() => import('@vlprojects-chat/chat'/* webpackChunkName: "chat" */)));
  }, []);

  useEffect(() => {
    clearTimeout(tid.current);

    if (errorTimeout && errorTimeout > 0) {
      tid.current = setTimeout(() => {
        setIsChatReady(false);
        handleChatError();
      }, errorTimeout);
    }

    return () => {
      clearTimeout(tid.current);
      tid.current = undefined;
    };
  }, [errorTimeout]);

  useEffect(() => {
    if (isChatReady) {
      clearTimeout(tid.current);
      tid.current = undefined;
    }
  }, [isChatReady]);

  useEffect(() => {
    const getChatUserToken = async () => {
      if (eventStore.event?.id) {
        try {
          const token = await eventStore.getChatUserToken();
          setChatUserToken(token);
        } catch (error) {
          logger.error(`Failed to get chat token in ${eventStore.isPreview ? 'playback' : 'event'}`, {
            error,
          });
        }

        return;
      }

      if (roomStore.isChatAllowed) {
        try {
          const token = await roomStore.getChatUserToken();
          setChatUserToken(token);
        } catch (error) {
          logger.error('Failed to get chat token in room', {
            error,
          });
        }
      }
    };

    if (isVisible && !chatUserToken) {
      getChatUserToken();
    }
  }, [
    isVisible,
    userStore.id,
    chatUserToken,
    roomStore.isChatAllowed,
    space?.chatToken,
    space?.id,
  ]);

  const channelId = roomStore.id || eventStore.joinResult?.chatChannelId;

  return (
    <Layout data-panel-name="chat" isVisible={isVisible} ref={chatRef} {...restProps}>
      {chatUserToken ? (
        <ErrorBoundary errorMessage={t('chat.displayChatFail')} onError={handleChatError}>
          <Suspense fallback={<DelayedFallbackMessage />}>
            {VLChat && (
              <VLChat
                appId={space?.chatToken}
                userToken={chatUserToken}
                apiUrl={process.env.REACT_APP_CHAT_API_BASEURL || undefined}
                channelId={channelId}
                onEvent={handleChatEvent}
                showCloseButton={!window.opener}
                showOpenInPopupButton={!window.opener}
                showPolls={!window.opener}
                chatVisualState={uiStore.chatMobileVisualState}
                lang={userStore.currentLanguage}
                hostEventBus={chatEventBus}
                outerSnackbar={{
                  ...snackbars,
                  chatSnackbarContainer: <ChatSnackbarContainer theme={theme} />,
                }}
              />
            )}
          </Suspense>
        </ErrorBoundary>
      ) : (
        <Loader />
      )}
    </Layout>
  );
});

export default Chat;
