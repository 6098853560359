import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';

import useStores from 'stores/root';
import ImageUploader from 'ui-kit/components/ImageUploader';

import {
  Avatar,
} from './Layout';

interface AvatarUploaderProps {
  id: string,
  image: string | null;
  isUploading: boolean;
  onCropperOpen: () => void;
  onSaveCroppedImage?: () => void;
  onGetPublicUrl: (url: string) => void;
  onRemoveImage: () => void;
  kindOfImages?: string; // sort of images (avatar, photo etc.), needs for creating directory on cloud. Default: images
  openImageCropper: (img: string | ArrayBuffer | null) => void;
  className?: string;
  name: string;
  isUploadingAllowed: boolean,
  showDefaultImage?: boolean,
  defaultImage?: string,
}

const AvatarUploader: FunctionComponent<AvatarUploaderProps> = observer(({
  image,
  onGetPublicUrl,
  onRemoveImage,
  onCropperOpen,
  onSaveCroppedImage,
  kindOfImages,
  openImageCropper,
  className,
  name,
  isUploadingAllowed,
  id,
  showDefaultImage,
  defaultImage,
}) => {
  const {
    uiStore,
    rootStore: { moodHoodApiClient },
  } = useStores();
  const [imageUrl, setImageUrl] = useState<string>();

  const { t } = useTranslation();

  const onFileLoaded = (img: string | ArrayBuffer | null) => {
    if (onCropperOpen) {
      onCropperOpen();
    }

    openImageCropper(img);
  };

  const signFile = async (file: File) => {
    const { data } = await moodHoodApiClient.assets.sign(file, kindOfImages);
    if (!data) {
      toast.error(t('admin.assets.uploadFail'));
      throw new Error('Upload failed');
    }

    return data;
  };

  const preprocessImage = async (file: File) => {
    const nextFile = await uiStore.preprocessImage(file.type);
    return nextFile;
  };

  const onPreprocessComplete = () => {
    onSaveCroppedImage?.();
  };

  useEffect(() => {
    if (imageUrl) {
      onGetPublicUrl(imageUrl);
    }
  }, [imageUrl]);

  const onError = () => {
    toast.error(t('admin.assets.uploadFail'));
  };

  return (
    <Avatar
      className={className}
      userName={name}
      imageUrl={image}
      showDefaultImage={showDefaultImage}
      defaultImage={defaultImage}
      fixedSize
    >
      {isUploadingAllowed && (
        <ImageUploader
          id={id}
          hasImage={Boolean(image)}
          signFile={signFile}
          preprocessImage={preprocessImage}
          onFileLoaded={onFileLoaded}
          onPublicUrl={setImageUrl}
          onError={onError}
          onRemoveImage={onRemoveImage}
          onPreprocessComplete={onPreprocessComplete}
        />
      )}
    </Avatar>
  );
});

export default AvatarUploader;
