import { AudioEncoderConfig } from '@livedigital/client/dist/types/common';

import { RoomType } from 'types/common';
import {
  FULL_HD_ENCODINGS,
  SAFARI_LESSON_ENCODINGS,
  SAFARI_WEBINAR_ENCODINGS,
  LESSON_ENCODINGS,
  WEBINAR_ENCODINGS,
  YA_BROWSER_LESSON_ENCODINGS,
  YA_BROWSER_WEBINAR_ENCODINGS,
} from 'constants/cameraEncodings';

import { VideoConfig } from './types';
import { isMobileSafari, isYandexBrowser, isSafari } from './browser';
import { getSessionStorageItem } from './sessionStorage';

const preferredScreenShareCodec = 'vp8' as const;
const preferredWebcamCodec = 'vp8' as const;

/*
// Example snippet:
sessionStorage.setItem('localMediaConfig', JSON.stringify({
  cameraConfig: {
    encoderConfig: {
      preferredCodec: 'vp9',
      encodings: [
        {
          maxBitrate: 150_000,
          maxFramerate: 14,
          scalabilityMode: 'L1T1',
          scaleResolutionDownBy: 4,
        },
        {
          maxBitrate: 350_000,
          maxFramerate: 14,
          scalabilityMode: 'L2T1',
          scaleResolutionDownBy: 2,
        },
        {
          maxBitrate: 900_000,
          maxFramerate: 14,
          scalabilityMode: 'L3T1',
          scaleResolutionDownBy: 1,
        },
      ],
    },
    options: {
      width: 1280,
      height: 720,
    },
  },
}));
*/
const tryToGetMediaConfigDataFromStorage = (): VideoConfig | undefined => {
  // priority is given to local config to simplify testing, then goes config form feature flags (LD)
  const config = getSessionStorageItem<VideoConfig>('localMediaConfig')
    ?? getSessionStorageItem<VideoConfig>('mediaConfig');

  if (!config || typeof config !== 'object' || Array.isArray(config)) {
    return undefined;
  }

  return config;
};

export const getAudioEncodings = (): AudioEncoderConfig => ({
  encodings: isMobileSafari() ? [] : [{ maxBitrate: 40_000, dtx: true }],
  preferredCodec: 'opus',
  enableFec: true,
  enableDtx: true,
});

const getLessonEncodings = () => {
  if (isYandexBrowser()) {
    return YA_BROWSER_LESSON_ENCODINGS;
  }

  if (isSafari()) {
    return SAFARI_LESSON_ENCODINGS;
  }

  return LESSON_ENCODINGS;
};

const getWebinarEncodings = () => {
  if (isYandexBrowser()) {
    return YA_BROWSER_WEBINAR_ENCODINGS;
  }

  if (isSafari()) {
    return SAFARI_WEBINAR_ENCODINGS;
  }

  return WEBINAR_ENCODINGS;
};

const getDefaultVideoConfig = (roomType: RoomType): VideoConfig => ({
  screenSharingConfig: {
    encoderConfig: {
      encodings: LESSON_ENCODINGS.screenSharingEncodings[preferredScreenShareCodec],
      preferredCodec: preferredScreenShareCodec,
    },
    options: {
      width: 1920,
      height: 1080,
    },
  },
  cameraConfig: {
    encoderConfig: {
      encodings: roomType === RoomType.Lesson
        ? getLessonEncodings().webCamEncodings[preferredWebcamCodec]
        : getWebinarEncodings().webCamEncodings[preferredWebcamCodec],
      preferredCodec: preferredWebcamCodec,
    },
    options: {
      width: 1280,
      height: 720,
    },
  },
});

const getFulHDVideoConfig = (): VideoConfig => ({
  screenSharingConfig: {
    encoderConfig: {
      encodings: FULL_HD_ENCODINGS.screenSharingEncodings[preferredScreenShareCodec],
      preferredCodec: preferredScreenShareCodec,
    },
    options: {
      width: 1920,
      height: 1080,
    },
  },
  cameraConfig: {
    encoderConfig: {
      encodings: FULL_HD_ENCODINGS.webCamEncodings[preferredWebcamCodec],
      preferredCodec: preferredWebcamCodec,
    },
    options: {
      width: 1920,
      height: 1080,
    },
  },
});

const applyLocalConfig = (config: VideoConfig, localConfig: Partial<VideoConfig>): VideoConfig => {
  const { screenSharingConfig: screenConfig, cameraConfig } = config;
  screenConfig.options = localConfig?.screenSharingConfig?.options ?? screenConfig.options;
  screenConfig.encoderConfig = localConfig?.screenSharingConfig?.encoderConfig ?? screenConfig.encoderConfig;
  cameraConfig.options = localConfig?.cameraConfig?.options ?? cameraConfig.options;
  cameraConfig.encoderConfig = localConfig?.cameraConfig?.encoderConfig ?? cameraConfig.encoderConfig;
  return config;
};

export const getVideoConfig = (roomType = RoomType.Lesson) => {
  const urlParams = new URLSearchParams(window.location.search.toLowerCase());
  const isFullHd = urlParams.get('fhd');
  const localConfig = tryToGetMediaConfigDataFromStorage();
  const config = isFullHd ? getFulHDVideoConfig() : getDefaultVideoConfig(roomType);

  if (!localConfig) {
    return config;
  }

  applyLocalConfig(config, localConfig);
  return config;
};
