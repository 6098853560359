import { useEffect } from 'react';
import { useLDClient, useLDClientError } from 'launchdarkly-react-client-sdk';

import { getUserAgentInfo } from 'helpers/browser';
import useStores from 'stores/root';
import logger from 'helpers/logger';
import { LaunchDarklyDeviceContext, LaunchDarklyDeviceSpaceContext } from 'types/featureFlags';

const makeDeviceContext = () => {
  const {
    browser,
    browserVersion,
    deviceType,
    platform,
    platformVersion,
  } = getUserAgentInfo();
  const deviceKey = `${deviceType}_${platform}_${platformVersion}_${browser}_${browserVersion}`.toLowerCase();

  return {
    kind: 'device' as const,
    key: deviceKey,
    deviceType,
    browser,
    browserVersion,
    platform,
    platformVersion,
  };
};

const useLaunchDarkly = () => {
  const { spaceStore } = useStores();
  const ldClient = useLDClient();
  const ldError = useLDClientError();

  useEffect(() => {
    if (!ldClient) {
      return;
    }

    const { space } = spaceStore;
    const deviceContext = makeDeviceContext();
    const spaceContext = space ? {
      kind: 'space' as const,
      key: space.id,
      name: space.name,
      spaceId: space.id,
    } : {};

    const context: LaunchDarklyDeviceContext | LaunchDarklyDeviceSpaceContext = {
      kind: 'multi' as const,
      device: deviceContext,
      ...(space && { space: spaceContext }),
    };

    ldClient.identify(context);
  }, [ldClient, spaceStore.space]);

  useEffect(() => {
    if (!ldError) {
      return;
    }

    logger.error('LaunchDarkly error', { error: ldError });
  }, [ldError]);
};

export default useLaunchDarkly;
