import React, { FunctionComponent } from 'react';
import ReactLoaderSpinner, { LoaderProps } from 'react-loader-spinner';
import { useTheme } from '@emotion/react';
import useDelayedLoader from 'hooks/useDelayedLoader';

interface ILoaderProps extends Omit<LoaderProps, 'type'> {
  enabled?: boolean;
  type?: LoaderProps['type'];
  centered?: boolean;
  size?: number;
}

const Loader:FunctionComponent<ILoaderProps> = ({
  enabled = true, type = 'TailSpin', centered = false, size = 24, ...rest
}) => {
  const theme = useTheme();
  const { isLoaderVisible } = useDelayedLoader();

  if (!enabled || !isLoaderVisible) {
    return null;
  }

  const style = (centered && { margin: 'auto' }) || undefined;

  return (
    <div style={style}>
      <ReactLoaderSpinner
        type={type}
        color={theme.colors.secondary03}
        height={size}
        width={size}
        {...rest}
      />
    </div>
  );
};

Loader.defaultProps = {
  enabled: true,
};

export default Loader;
