export type HotkeyCallback = (e: KeyboardEvent) => void;

export enum KeyModifier {
  None = 0,
  Ctrl = 1,
  Alt = 2,
  Shift = 4,
  Meta = 8,
}

export interface HotKey {
  key: string,
  keyPress?: 'keyup' | 'keydown',
  modifiers?: KeyModifier,
}
