import RingBuffer from './RingBuffer';

class MOSStats {
  private mos: RingBuffer<number>;

  constructor(samplesSize: number) {
    this.mos = new RingBuffer<number>(samplesSize);
  }

  push(value: number) {
    this.mos.push(value);
  }

  reset() {
    this.mos.reset();
  }

  average() {
    const avg = this.mos.values.reduce((acc, val) => (acc + val), 0) / this.mos.size();
    return Math.round(avg * 100) / 100;
  }
}

export default MOSStats;
