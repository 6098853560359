import React, { useEffect, useState, VoidFunctionComponent } from 'react';
import isMobile from 'is-mobile';
import { Redirect, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import useStores from 'stores/root';
import { Loader } from 'ui-kit';
import { getUrlWithUTM } from 'helpers/utm';

import {
  LayoutDesktop,
  LayoutMobile,
} from './InvitePageLayout';

interface InvitePageParams {
  spaceId:string;
  inviteId: string;
  roomId?: string;
}

const InvitePage: VoidFunctionComponent = observer(
  () => {
    const {
      spaceId,
      inviteId,
      roomId,
    } = useParams<InvitePageParams>();
    const { spaceStore } = useStores();
    const [doRedirect, setDoRedirect] = useState(false);

    useEffect(() => {
      spaceStore.resetInviteStatus();
      spaceStore.activateSpaceInvite(spaceId, inviteId);
    }, []);

    useEffect(() => {
      if (spaceStore.isInviteActivated) {
        setDoRedirect(true);
      }
    }, [spaceStore.isInviteActivated]);

    if (doRedirect) {
      if (roomId) {
        return (
          <Redirect to={getUrlWithUTM(`/room/${roomId}`)} />
        );
      }

      return (
        <Redirect to={getUrlWithUTM(`/space/${spaceId}`)} />
      );
    }

    const Layout = isMobile() ? LayoutMobile : LayoutDesktop;

    return (
      <Layout>
        <Loader enabled />
      </Layout>
    );
  },
);

export default InvitePage;
