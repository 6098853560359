import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { Menu } from 'ui-kit';
import Popup from 'ui-kit/components/Popup';
import {
  RussianFlagIcon,
  UsaFlagIcon,
} from 'ui-kit/assets/icons';
import useStores from 'stores/root';
import locales from 'services/i18n/locales';

import { StatsId } from 'types/stats';
import { DropDownButton, IconContainer, MenuItem } from '../DesktopLayout';

const ChangeLanguageDropDownButton: FunctionComponent = observer(() => {
  const { i18n, t } = useTranslation();
  const {
    userStore,
  } = useStores();

  const getLanguageFullContent = (lang: string) => {
    switch (lang) {
      case 'ru': return { fullName: t('language.ru'), icon: <RussianFlagIcon />, text: 'RU' };
      default: return { fullName: t('language.en'), icon: <UsaFlagIcon />, text: 'EN' };
    }
  };

  const langs = Object.keys(locales).map((lang) => ({ content: getLanguageFullContent(lang), value: lang }));

  const updateLang = (value: string) => () => {
    i18n.changeLanguage(value);
    userStore.setCurrentLanguage();
  };

  return (
    <DropDownButton
      id="language-menu"
      text={getLanguageFullContent(userStore.currentLanguage).text}
      menu={(
        <Popup
          alignment="right"
        >
          <Menu>
            {langs.map((lang) => (
              <MenuItem
                id="change-language"
                data-stats={StatsId.ProfileChangeLanguage}
                data-stats-value={lang.value}
                key={lang.value}
                onClick={updateLang(lang.value)}
              >
                <IconContainer>
                  {lang.content.icon}
                </IconContainer>
                {lang.content.fullName}
              </MenuItem>
            ))}
          </Menu>
        </Popup>
      )}
    />
  );
});

export default ChangeLanguageDropDownButton;
