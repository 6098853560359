import {
  action, computed, makeAutoObservable, reaction,
} from 'mobx';
import { eventBus } from 'mobx-event-bus2';

import type RoomStore from 'stores/room';
import type Peer from '@livedigital/client/dist/engine/Peer';
import { Role } from '@livedigital/client/dist/types/common';
import { ReactionEvent } from 'types/common';
import { getIsHandRaised } from 'helpers/common';
import { JoinApproval } from 'types/stores/peerAppData';

import { ConnectionQuality } from '../constants/common';
import {
  RoomRole, SlotAssignment, TrackLabel,
} from '../services/MoodHoodApiClient/types';
import MediaTrackStore from './mediaTrack';
import PublisherStore from './publisher';
import type { PeerParticipantInfo } from '../types/common';
import PeerAppDataStore from './peerAppData';

interface PeerStoreConstructor {
  peer: Peer
  roomStore: RoomStore;
  name?: string;
  image?: string;
  isAudioStreamingAllowed?: boolean;
  isVideoStreamingAllowed?: boolean;
}

type PeerAppData = {
  userId: string;
  roomId: string;
  spaceId: string;
};

class PeerStore {
  engine: Peer;

  id: string;

  roomStore: RoomStore;

  _name?: string;

  userId?: string;

  _image?: string;

  participantId: string;

  loginDate = new Date();

  roomId: string;

  spaceId: string;

  mediaTracks = new Map<string, MediaTrackStore>();

  publishers = new Map<string, PublisherStore>();

  connectionQuality: ConnectionQuality = ConnectionQuality.GOOD;

  isModerator?: boolean;

  role: Role;

  appData: PeerAppDataStore;

  reactionDisposers: Set<() => void> = new Set<() => void>();

  constructor({
    peer,
    roomStore,
    name,
    image,
  }: PeerStoreConstructor) {
    this.engine = peer;
    this.id = peer.id;
    this.role = peer.role;
    this.roomStore = roomStore;
    this.participantId = peer.uid as string;
    this.listenPeerEvents();
    const { userId, spaceId } = peer.appData as PeerAppData;
    this._name = name;
    this._image = image;
    this.userId = userId;
    this.roomId = roomStore.id as string;
    this.spaceId = spaceId;
    this.isModerator = Boolean(peer.appData.isModerator);
    this.appData = new PeerAppDataStore({
      peerStore: this,
      client: this.roomStore.rootStore.participantStore.client,
    });

    this.loadPublishers();

    makeAutoObservable(this);

    this.reactionDisposers.add(reaction(
      () => this.isAudioStreamingAllowed,
      (isAudioAllowed) => {
        if (isAudioAllowed) {
          this.audioTracks.filter((x) => x.label !== TrackLabel.ScreenAudio).forEach((track) => {
            track.unmute();
          });
        } else {
          this.audioTracks.filter((x) => x.label !== TrackLabel.ScreenAudio).forEach((track) => {
            track.mute();
          });
        }
      },
    ));

    this.reactionDisposers.add(reaction(
      () => this.isVideoStreamingAllowed,
      (isVideoAllowed) => {
        if (isVideoAllowed) {
          this.videoTracks.filter((x) => x.label !== TrackLabel.ScreenVideo).forEach((track) => {
            track.unmute();
          });
        } else {
          this.videoTracks.filter((x) => x.label !== TrackLabel.ScreenVideo).forEach((track) => {
            track.mute();
          });
        }
      },
    ));

    this.reactionDisposers.add(reaction(
      () => this.appData.isHandRaised,
      (isHandRaised) => {
        if (isHandRaised) {
          eventBus.post(ReactionEvent.emitRaisedHand, { userImage: this.image, userName: this.name });
        }
      },
    ));
  }

  get slots(): SlotAssignment[] {
    return this.videoTracks.map((track) => ({
      trackLabel: track.label,
      slotUuid: `${this.id}_${track.label}`,
    } as SlotAssignment));
  }

  private loadPublishers(): void {
    this.engine.publishedMedia.forEach((payload) => {
      if (this.publishers.has(payload.label)) {
        return;
      }

      const publisher = new PublisherStore({ ...payload, peerStore: this });
      this.publishers.set(publisher.label, publisher);
    });
  }

  private listenPeerEvents(): void {
    this.engine.observer.on('media-published', (payload) => {
      const publisher = new PublisherStore({ ...payload, peerStore: this });
      this.publishers.set(publisher.label, publisher);
      publisher.subscribe();
    });

    this.engine.observer.on('publisher-paused', (payload) => {
      const publisher = this.publishers.get(payload.label);
      if (publisher && publisher.producerId === payload.producerId) {
        publisher.setIsPaused(payload.paused);
      } else {
        this.publishers.delete(payload.label);
        const pub = new PublisherStore({ ...payload, peerStore: this });
        this.publishers.set(pub.label, pub);
        pub.subscribe();
      }
    });

    this.engine.observer.on('publisher-resumed', (payload) => {
      const publisher = this.publishers.get(payload.label);
      if (publisher && publisher.producerId === payload.producerId) {
        publisher.setIsPaused(payload.paused);
      } else {
        this.publishers.delete(payload.label);
        const pub = new PublisherStore({ ...payload, peerStore: this });
        this.publishers.set(pub.label, pub);
        pub.subscribe();
      }
    });

    this.engine.observer.on('media-unpublished', (payload) => {
      this.publishers.delete(payload.label);
    });

    this.engine.observer.on('track-start', (track) => {
      const mediaTrack = new MediaTrackStore({ peerStore: this, peerTrack: track });
      if (mediaTrack.label === 'screen-video') {
        mediaTrack.setMaxPriority();
      }

      this.setMediaTrack(mediaTrack);
    });

    this.engine.observer.on('track-end', (track) => {
      this.removeMediaTrack(track.label);
    });

    this.engine.observer.on('track-paused', (track) => {
      this.mediaTracks.get(track.label)?.setIsPaused(true);
    });

    this.engine.observer.on('track-resumed', (track) => {
      this.mediaTracks.get(track.label)?.setIsPaused(false);
    });

    this.engine.observer.on('connection-quality-changed', (payload) => {
      this.setConnectionQuality(payload?.connectionQuality);
    });
  }

  private removePeerEventsListeners() {
    this.engine.observer.removeAllListeners('media-published');
    this.engine.observer.removeAllListeners('media-unpublished');
    this.engine.observer.removeAllListeners('app-data-updated');
    this.engine.observer.removeAllListeners('connection-quality-changed');
    this.engine.observer.removeAllListeners('publisher-paused');
    this.engine.observer.removeAllListeners('publisher-resumed');
    this.engine.observer.removeAllListeners('track-start');
    this.engine.observer.removeAllListeners('track-end');
    this.engine.observer.removeAllListeners('track-resumed');
    this.engine.observer.removeAllListeners('track-paused');
    this.engine.observer.removeAllListeners('track-failed');
  }

  reset() {
    this.removePeerEventsListeners();
    this.reactionDisposers.forEach((x) => x.call(x));
    this.publishers.clear();
    this.mediaTracks.clear();
  }

  @action setMediaTrack(mediaTrack: MediaTrackStore): void {
    this.mediaTracks.set(mediaTrack.label, mediaTrack);
  }

  @action removeMediaTrack(label: TrackLabel): void {
    this.mediaTracks.delete(label);
  }

  @computed get isVideoStreamingAllowed(): boolean | undefined {
    return this.appData.isVideoStreamingAllowed;
  }

  @action async setVideoStreamingPermission(value?: boolean): Promise<void> {
    if (this.isMe) {
      await this.appData.setAttribute('isVideoStreamingAllowed', value);
    }
  }

  @computed get isAudioStreamingAllowed(): boolean | undefined {
    return this.appData.isAudioStreamingAllowed;
  }

  @action async setAudioStreamingPermission(value?: boolean): Promise<void> {
    if (this.isMe) {
      await this.appData.setAttribute('isAudioStreamingAllowed', value);
    }
  }

  @computed get allMediaTracks(): MediaTrackStore[] {
    return Array.from(this.mediaTracks.values());
  }

  @computed get allPublishers(): PublisherStore[] {
    return Array.from(this.publishers.values());
  }

  @computed get audioTracks(): MediaTrackStore[] {
    return this.allMediaTracks.filter((mediaTrack) => mediaTrack.track.kind === 'audio');
  }

  @computed get videoTracks(): MediaTrackStore[] {
    return this.allMediaTracks.filter((mediaTrack) => mediaTrack.track.kind === 'video');
  }

  @computed get hasActiveVideo(): boolean {
    return !!this.videoTracks.length && this.videoTracks.some((track) => !track.isPaused);
  }

  @computed get hasActiveCameraTrack(): boolean {
    const track = this.mediaTracks.get(TrackLabel.Camera);
    if (!track) {
      return false;
    }

    return !track.isPaused;
  }

  @computed get hasActiveCameraPublishers(): boolean {
    return this.allPublishers.some((x) => x.label === TrackLabel.Camera && !x.isPaused);
  }

  @computed get hasActiveAudio(): boolean {
    return !!this.audioTracks.length && this.audioTracks.some((track) => !track.isPaused);
  }

  @computed get hasActiveMicTrack(): boolean {
    const track = this.mediaTracks.get(TrackLabel.Microphone);
    if (!track) {
      return false;
    }

    return !track.isPaused;
  }

  @action getAudioLevel(audioTrack: MediaStreamTrack): number {
    return this.roomStore.rootStore.audioLevelWatcherStore.getAudioLevel(audioTrack);
  }

  @computed get isMe(): boolean {
    return this.engine.isMe;
  }

  @computed get image(): string | undefined {
    return this.appData.image || this._image;
  }

  @action async setImage(image?: string): Promise<void> {
    this._image = image;

    if (this.isMe) {
      await this.appData.setAttribute('image', image);
    }
  }

  @computed get name(): string | undefined {
    return this.appData.name || this._name;
  }

  @action async setName(name?: string): Promise<void> {
    this._name = name;

    if (this.isMe) {
      await this.appData.setAttribute('name', name);
    }
  }

  @action setConnectionQuality(quality: ConnectionQuality): void {
    this.connectionQuality = quality;
  }

  @computed get isVideoEnabled(): boolean {
    return this.hasActiveVideo;
  }

  @computed get isAudioEnabled(): boolean {
    return this.hasActiveAudio;
  }

  @action watchAudioLevel(audioTrack: MediaStreamTrack): void {
    this.roomStore.rootStore.audioLevelWatcherStore.watchAudioLevel(audioTrack);
  }

  @action stopWatchAudioLevel(audioTrack: MediaStreamTrack) {
    this.roomStore.rootStore.audioLevelWatcherStore.stopWatchAudioLevel(audioTrack);
  }

  @action copyParticipantInfo(peer: PeerStore): void {
    if (peer.name) {
      this.setName(peer.name);
    }

    this.setImage(peer.image);
    this.setAudioStreamingPermission(Boolean(peer.isAudioStreamingAllowed));
    this.setVideoStreamingPermission(Boolean(peer.isVideoStreamingAllowed));
  }

  async getInfo(): Promise<PeerParticipantInfo> {
    const peerInfo = await this.engine.getInfo();
    return {
      isMe: this.isMe,
      participantId: this.participantId,
      roomId: this.roomId,
      spaceId: this.spaceId,
      callId: this.roomStore.calls.activeCall?.id,
      isModerator: this.isModerator,
      isAudioStreamingAllowed: this.isAudioStreamingAllowed,
      isVideoStreamingAllowed: this.isVideoStreamingAllowed,
      userAgent: this.engine.appData.userAgent,
      ...peerInfo,
    };
  }

  @computed hasVideo(trackLabel: TrackLabel): boolean {
    const videoTrack = this.videoTracks.find((track) => track.label === trackLabel);
    return !!videoTrack && !videoTrack?.isPaused;
  }

  @computed get isHandRaised(): boolean {
    return getIsHandRaised(this.appData);
  }

  @computed get isJoinApproved(): boolean {
    const { joinApproval } = this.appData;

    return joinApproval === JoinApproval.Approved || joinApproval === JoinApproval.NotNeeded;
  }

  @computed get isSpeaker() {
    return this.appData.roleInRoom === RoomRole.Speaker;
  }
}

export default PeerStore;
