import styled from '@emotion/styled';

export const LayoutDesktop = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100dvh',
  width: '100%',
  flexDirection: 'column',
});

export const LayoutMobile = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100dvh',
  flexDirection: 'column',
});
