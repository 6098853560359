// https://github.com/VLprojects/livedigital-sdk/blob/master/src/types/socket.ts#L2-L15
export enum SocketIOEvents {
  Connect = 'connect',
  Connected = 'connected',
  ConnectError = 'connect_error',

  Reconnect = 'reconnect',
  Reconnecting = 'reconnecting',
  Reconnected = 'reconnected',
  ReconnectAttempt = 'reconnect_attempt',

  Disconnect = 'disconnect',
  Disconnected = 'disconnected',
  Error = 'error',
}

// Frontend only events from frontend to frontend passthrough livedigital-sdk and backend
export enum ChannelEvent {
  NewReaction = 'newReaction',
  UpdateParticipant = 'updateParticipant',
  RoomRecord = 'roomRecord',
  UpdateRoomParticipantsCount = 'updateRoomParticipantsCount',
  NeedToSendDumps = 'needToSendDumps',
  AnnouncementsUpdated = 'announcementsUpdated',
  BreakoutRoomsSessionEvent = 'breakoutRoomsSessionEvent',
  SwitchOffParticipantsMedia = 'switchOffParticipantsMedia',
  DemonstrationsUpdated = 'demonstrationsUpdated',
  RestrictionsUpdated = 'restrictionsUpdated',
}
