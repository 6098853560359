/* eslint-disable max-len */
import React, { FunctionComponent, Suspense } from 'react';
import DelayedFallbackMessage from './DelayedFallbackMessage';

import loadWithRetry from './loadWithRetry';

const Fallback: FunctionComponent = ({ children }) => (
  <Suspense fallback={<DelayedFallbackMessage />}>
    {children}
  </Suspense>
);

const LazySpace = loadWithRetry(() => import('components/Space'/* webpackChunkName: "rooms" */));
const LazyEvent = loadWithRetry(() => import('components/Event'/* webpackChunkName: "event" */));
const LazyPlaybackEditor = loadWithRetry(() => import('components/Space/PlaybackEditor'/* webpackChunkName: "playback" */));
const LazySpaces = loadWithRetry(() => import('components/Spaces'/* webpackChunkName: "spaces" */));
const LazyRoomStatistics = loadWithRetry(() => import('components/RoomStatistics'/* webpackChunkName: "statistics" */));
const LazySignUpPage = loadWithRetry(() => import('components/Auth/Pages/SignUpPage'/* webpackChunkName: "signup" */));
const LazySignInPage = loadWithRetry(() => import('components/Auth/Pages/SignInPage'/* webpackChunkName: "signin" */));
const LazyRoom = loadWithRetry(() => import('components/Room'/* webpackChunkName: "room" */));
const LazyRoomRecord = loadWithRetry(() => import('components/RoomRecord'/* webpackChunkName: "room" */));
const LazyPasswordRecovery = loadWithRetry(() => import('components/PasswordRecovery'/* webpackChunkName: "passrecovery" */));
const LazyRoomStatisticsPulse = loadWithRetry(() => import('components/RoomStatisticsPulse'/* webpackChunkName: "pulsestat" */));
const LazyDebugInfo = loadWithRetry(() => import('components/Dialogs/Desktop/DebugInfo'/* webpackChunkName: "debug-info" */));
const LazyElectronScreensharing = loadWithRetry(() => import('components/Electron/Dialogs/Screensharing'/* webpackChunkName: "electron-screensharing" */));
const LazyScreenSharingTest = loadWithRetry(() => import('components/Tools/ScreenSharingTest'/* webpackChunkName: "screen-sharing-test" */));
const LazyUserProfileMobileApp = loadWithRetry(() => import('components/UserProfile/MobileApp'/* webpackChunkName: "mobile-app" */));
const LazyPlansPageMobileApp = loadWithRetry(() => import('components/PlansPage/MobileApp'/* webpackChunkName: "mobile-app" */));

export const Space: FunctionComponent = () => <Fallback><LazySpace /></Fallback>;
export const Event: FunctionComponent<{ isPreview?: boolean }> = ({ isPreview }) => <Fallback><LazyEvent isPreview={isPreview} /></Fallback>;
export const PlaybackEditor: FunctionComponent = () => <Fallback><LazyPlaybackEditor /></Fallback>;
export const Spaces: FunctionComponent = () => <Fallback><LazySpaces /></Fallback>;
export const RoomStatistics: FunctionComponent = () => <Fallback><LazyRoomStatistics /></Fallback>;
export const SignUpPage: FunctionComponent = () => <Fallback><LazySignUpPage /></Fallback>;
export const SignInPage: FunctionComponent = () => <Fallback><LazySignInPage /></Fallback>;
export const PasswordRecovery: FunctionComponent = () => <Fallback><LazyPasswordRecovery /></Fallback>;
export const Room: FunctionComponent = () => <Fallback><LazyRoom /></Fallback>;
export const RoomStatisticsPulse: FunctionComponent = () => <Fallback><LazyRoomStatisticsPulse /></Fallback>;
export const RoomRecord: FunctionComponent = () => <Fallback><LazyRoomRecord /></Fallback>;
export const DebugInfo: FunctionComponent = () => <Fallback><LazyDebugInfo /></Fallback>;
export const ElectronScreensharing: FunctionComponent = () => <Fallback><LazyElectronScreensharing /></Fallback>;
export const ScreenSharingTest: FunctionComponent = () => <Fallback><LazyScreenSharingTest /></Fallback>;
export const UserProfileMobileApp: FunctionComponent = () => <Fallback><LazyUserProfileMobileApp /></Fallback>;
export const PlansPageMobileApp: FunctionComponent = () => <Fallback><LazyPlansPageMobileApp /></Fallback>;
