export interface PoolElement<T extends HTMLMediaElement> {
  id: number,
  mediaStream: MediaStream | null,
  trackId: string | null,
  refCount: number,
  domElement: T | null,
}

export class MediaElementsPoolClass<T extends (HTMLVideoElement | HTMLAudioElement)> {
  protected maxSize: number;

  protected pool: PoolElement<T>[] = [];

  constructor(maxSize = Infinity, initialSize = 0) {
    this.maxSize = maxSize;

    if (initialSize) {
      for (let i = 0; i < initialSize; i += 1) {
        this.addElement();
      }
    }
  }

  acquireMedia(track: MediaStreamTrack) {
    const poolEl = this.findElement(track?.id || '');
    if (poolEl?.domElement) {
      return poolEl.domElement;
    }

    const availableEl = this.getAvailableElement();

    if (availableEl?.domElement) {
      this.useElement(availableEl, track);
      // availableEl.domElement.play();
      return availableEl.domElement;
    }

    const newElement = this.addElement();
    this.useElement(newElement, track);
    return newElement.domElement;
  }

  releaseMedia(trackId: string) {
    const el = this.findElement(trackId);
    if (!el) {
      return;
    }

    el.refCount -= 1;

    if (el.refCount === 0) {
      this.resetElement(el);
    }
  }

  dispose() {
    this.pool.forEach((poolElement) => {
      const el = poolElement;
      this.resetElement(el);
      el.mediaStream = null;
      if (el.domElement) {
        el.domElement.srcObject = null;
        el.domElement = null;
      }
    });

    this.pool = [];
  }

  dump() {
    return { elements: this.pool };
  }

  private useElement(pe: PoolElement<T>, track: MediaStreamTrack) {
    const el = pe;

    if (el.trackId !== track.id && el.refCount !== 0) {
      throw new Error('Media element already in use');
    }

    if (el.domElement) {
      el.domElement.id = track.id;
    }

    el.trackId = track.id;
    el.refCount += 1;

    const existedTrack = el.mediaStream?.getTrackById(track.id);

    if (!existedTrack) {
      el.mediaStream?.addTrack(track);
    }
  }

  private findElement(trackId: string) {
    return this.pool.find((el) => el.trackId === trackId);
  }

  protected getAvailableElement(): PoolElement<T> | null {
    return this.pool.find((poolEl: PoolElement<T>) => poolEl.refCount === 0) || null;
  }

  // dummy method must be overloaded with child class
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected resetElement(poolElement: PoolElement<T>) {
  }

  // dummy method must be overloaded with child class
  protected addElement(): PoolElement<T> {
    return {} as PoolElement<T>;
  }
}

export default new MediaElementsPoolClass();
