import { makeTheme } from './helpers';
import { ThemeType } from './types';

export const FigmaColors = {
  /* Main */
  bg: '#FFFFFF',
  primary: '#060A2D',
  contrast: '#FFFFFF',
  bgContrast: '#060A2DCC',
  primaryTransparency: '#1d1d2cb3',
  /* Secondary */
  secondaryBase: '#5C5D6C',
  secondary01: '#F1F1F4',
  secondary02: '#D4D5DE',
  secondary03: '#B7B9C9',
  /* Accent */
  accentBase: '#163498',
  accent01: '#E8EBF5',
  accent02: '#D0D6EA',
  accent03: '#8A99CB',
  accent04: '#5C71B7',
  accent05: '#1A2657',
  /* Tertiary */
  tertiaryBase: '#1399FA',
  tertiary01: '#E7F5FE',
  /* Error */
  errorBase: '#E11D08',
  error01: '#FCE5E2',
  error02: '#F07D70',
  error03: '#921003',
  error04: '#5F0A01',
  /* Success */
  successBase: '#06AA2A',
  success01: '#CDEED4',
  success02: '#9BDDAA',
  success03: '#026F1A',
  /* Warning */
  warningBase: '#FF9900',
  warning01: '#FFF4E5',
  warning02: '#FFD495',
  warning03: '#765128',
  /* Purple */
  purpleBase: '#C0437F',
  purprle01: '#F2D9E5',
  purprle02: '#DFA1BF',
  purprle03: '#8C315D',
  /* Reactions */
  'reaction-lilac': '#3431D0',
  'reaction-lilac-light-01': '#ABA9EB',
  'reaction-lilac-light-02': '#EAEAFA',
  'reaction-orange': '#FF9900',
  'reaction-orange-light-01': '#FFD495',
  'reaction-orange-light-02': '#FFF4E5',
  'reaction-pink': '#FF005C',
  'reaction-pink-light-02': '#FFE5EE',
  'reaction-red': '#F32A2A',
  'reaction-red-light-01': '#FE9FA9',
  'reaction-red-light-02': '#FDE9E9',
  /* Avatar */
  avatarBurgundy: '#6B0C22',
  avatarRed: '#D9042B',
  avatarDarkPink: '#C0437F',
  avatarPink: '#D792D8',
  avatarGold: '#E2B978',
  avatarNavyGreen: '#588C8C',
  avatarNavyBlue: '#66588C',
  avatarPurple: '#5E0C6B',
};

export const LightTheme = makeTheme(ThemeType.light, FigmaColors);
