import { TrackLabel } from '@livedigital/client/dist/types/common';
import PeerTrack from '@livedigital/client/dist/engine/media/tracks/PeerTrack';
import {
  action, computed, makeAutoObservable, observable,
} from 'mobx';
import PeerStore from './peer';
import { VideoResolution } from '../types/common';

interface MediaTrackStoreConstructor {
  peerStore: PeerStore;
  peerTrack: PeerTrack;
}

class MediaTrackStore {
  readonly peerStore: PeerStore;

  readonly peerTrack: PeerTrack;

  private paused: boolean;

  @observable private muted: boolean;

  @observable volume = 0;

  constructor({
    peerTrack,
    peerStore,
  }: MediaTrackStoreConstructor) {
    this.peerStore = peerStore;
    this.peerTrack = peerTrack;
    this.paused = peerTrack.isPaused;
    this.muted = peerTrack.isMuted;
    makeAutoObservable(this, { peerStore: false, peerTrack: false });
    this.peerTrack.observer.addListener('volume-changed', ({ value } : { value: number }) => {
      this.setVolume(value);
    });
  }

  @computed get isPaused(): boolean {
    return this.paused;
  }

  @action setIsPaused(value: boolean): void {
    this.paused = value;
  }

  @action setVolume(value: number) : void {
    this.volume = value;
  }

  get label(): TrackLabel {
    return this.peerTrack.label;
  }

  get track(): MediaStreamTrack {
    return this.peerTrack.mediaStreamTrack;
  }

  get isVideo(): boolean {
    return this.track.kind === 'video';
  }

  get isAudio(): boolean {
    return this.track.kind === 'audio';
  }

  get availableResolutions(): VideoResolution[] {
    const availableVideoLayers = this.peerTrack.getVideoAvailableLayers();
    const isResolutionsAvailable = availableVideoLayers?.every((layer) => layer.width && layer.height);
    if (!availableVideoLayers || this.isAudio || !isResolutionsAvailable) {
      return [];
    }

    return availableVideoLayers.map((layer) => {
      if (layer.width && layer.height) {
        return {
          width: layer.width,
          height: layer.height,
        };
      }

      return null;
    }).filter((resolution): resolution is VideoResolution => !!resolution);
  }

  @computed get isMuted(): boolean {
    return this.muted;
  }

  @action setIsMuted(value: boolean): void {
    this.muted = value;
  }

  @action async pause(): Promise<void> {
    await this.peerTrack.pause();
  }

  @action async resume(): Promise<void> {
    const peer = this.peerStore;
    if ((!peer.isAudioStreamingAllowed && this.isAudio) || (!peer.isVideoStreamingAllowed && this.isVideo)) {
      return;
    }

    await this.peerTrack.resume();
  }

  @action async mute(): Promise<void> {
    await this.peerTrack.mute();
    this.setIsMuted(true);
  }

  @action async unmute(): Promise<void> {
    const peer = this.peerStore;
    const isConsumeMediaRestricted = (peer.isAudioStreamingAllowed === false && this.isAudio)
      || (peer.isVideoStreamingAllowed === false && this.isVideo);

    if (isConsumeMediaRestricted) {
      return;
    }

    await this.peerTrack.unmute();
    this.setIsMuted(false);
  }

  async setMaxPriority(): Promise<void> {
    await this.peerTrack.setPriority(255);
  }

  async adjustVideoToParams(width: number, height: number): Promise<void> {
    if (this.isAudio) {
      return;
    }

    if (!this.availableResolutions.length) {
      return;
    }

    const targetResolution = width * height;
    // looking for closest resolution to the given one
    const matchData = this.availableResolutions.reduce((acc, res, idx) => {
      const currentResolution = res.width * res.height;
      const currentDiff = Math.abs(currentResolution - targetResolution);

      if (currentDiff < acc.minDiff) {
        acc.minDiff = currentDiff;
        acc.layerIndex = idx;
      }

      return acc;
    }, { minDiff: Number.MAX_SAFE_INTEGER, layerIndex: 0 });
    // for debug purposes
    const layerIndex = ((window as unknown as Record<string, number>).debugLayerIndex) ?? matchData.layerIndex;

    await this.peerTrack.requestVideoPreferredLayers({
      spatialLayer: layerIndex >= 0 ? layerIndex : 0,
      temporalLayer: 0, // always request min temporal layer
    });
  }
}

export default MediaTrackStore;
