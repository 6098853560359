import { LightTheme } from './light';
import { DarkTheme } from './dark';
import { ThemeType } from './types';

export const getThemeStyles = (theme: ThemeType) => {
  switch (theme) {
    case ThemeType.light:
      return LightTheme.styles;
    case ThemeType.dark:
      return DarkTheme.styles;
    default:
      return null;
  }
};

export { LightTheme, DarkTheme };
