import React, {
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Loader } from 'ui-kit';
import { isSafari } from 'helpers/browser';

import {
  Text,
  RequestStorageAccessHolder,
  RequestStorageAccessContent,
  RequestStorageAccessButton,
} from './Layout';

const RequestStorageAccess: FunctionComponent = ({
  children,
}) => {
  const { t } = useTranslation();

  const [hasStorageAccess, setHasStorageAccess] = useState(false);
  const [openedAllowPage, setOpenedAllowPage] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [popupClosed, setPopupClosed] = useState(false);
  const [ref, setRef] = useState<Window | null>(null);

  useEffect(() => {
    if (document.hasStorageAccess) {
      document.hasStorageAccess().then((value) => {
        setHasStorageAccess(value);
      }).catch(() => {
        setHasStorageAccess(false);
      });
    }
  }, []);

  const grant = () => {
    window.document.requestStorageAccess().then(() => {
    }).catch(() => {
    }).finally(() => {
      window.location.reload();
    });
  };

  const openPopup = () => {
    const width = 400;
    const height = 300;

    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const windowRef = window.open(
      `//${window.location.host}/allow`,
      '',
      `popup=true, width=${width}, height=${height}, left=${left}, top=${top}`,
    );

    setRef(windowRef);
    setOpenedAllowPage(true);
    setTimerStarted(true);
  };

  const [intervalId, setIid] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (timerStarted) {
      const iid = setInterval(() => {
        setPopupClosed(ref?.closed || false);
      }, 1);
      setIid(iid);
      return;
    }

    if (intervalId) {
      clearInterval(intervalId);
    }
  }, [timerStarted]);

  useEffect(() => () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    if (popupClosed) {
      setTimerStarted(false);
    }
  }, [popupClosed]);

  if (isSafari() && !hasStorageAccess) {
    return (
      <RequestStorageAccessHolder>
        <RequestStorageAccessContent>
          {!openedAllowPage && (
            <>
              <Text>
                {t('safariRequestStorageAccess.hint1')}
              </Text>
              <RequestStorageAccessButton id="open-popup" onClick={openPopup} variant="primary">
                {t('safariRequestStorageAccess.button1')}
              </RequestStorageAccessButton>
            </>
          )}
          {openedAllowPage && !popupClosed && (
            <>
              <Text>
                {t('safariRequestStorageAccess.waiting')}
              </Text>
              <Loader enabled />
            </>
          )}
          {popupClosed && (
            <>
              <Text>
                {t('safariRequestStorageAccess.hint2')}
              </Text>
              <RequestStorageAccessButton id="grant-button" onClick={grant} variant="primary">
                {t('safariRequestStorageAccess.button2')}
              </RequestStorageAccessButton>
            </>
          )}
        </RequestStorageAccessContent>
      </RequestStorageAccessHolder>
    );
  }

  return (
    <>
      {children}
    </>
  );
};

export default observer(RequestStorageAccess);
