import {
  RoomType,
  WaitingRoomAudience,
} from 'types/common';

import {
  RoomRole,
  ParticipantResponse,
  SlotAssignment,
  PaginableResponse,
  BreakoutRooms,
} from './types';

import { JoinSettings } from './types/joinSettings';

export type GetRoomResponse = {
  id: string,
  alias: string,
  appId: string,
  spaceId: string,
  channelId: string,
  name: string,
  type: RoomType,
  background: string,
  isScreensharingAllowed: boolean,
  isRecordAllowed: boolean,
  isChatAllowed: boolean,
  isPublic: boolean,
  parentRoomId: string | null,
  breakoutRooms: BreakoutRooms,
  waitingRoomAudience: WaitingRoomAudience,
  joinSettings: JoinSettings,
  isAutoRecordingAllowed: boolean,
  roleInRoom: RoomRole,
};

export enum ReactionType {
  Heart = 'heart',
  ThumbUp = 'thumb-up',
  ThumbDown = 'thumb-down',
  Fire = 'fire',
}

export type GetRoomsResponse = PaginableResponse<GetRoomResponse> & {
  webinars: number,
  conferences: number,
  favorites: number,
};
export type JoinRoomParams = {
  spaceId: string,
  roomId: string,
  participantId: string,
};

export type JoinRoomResponse = {
  slot: number,
  allSlots: SlotAssignment[],
};

export interface CreateBreakoutRoomsPayload {
  roomId: string,
  spaceId: string,
  namePrefix: string,
  amount: number,
  enumerationStartIdx: number,
}

interface BreakoutRoomResponse extends GetRoomResponse {
  parentRoomId: string,
}

export interface CreateBreakoutRoomsResponse {
  items: BreakoutRoomResponse[],
}

interface ParticipantGroupBodyItem {
  roomId: string,
  participantIds: string[],
}
export interface StartBreakoutRoomsPayload {
  roomId: string,
  spaceId: string,
  groups: ParticipantGroupBodyItem[],
}

export interface StartBreakoutRoomsResponse {
  items: Pick<BreakoutRoomResponse, 'id' | 'alias'>,
  participantIds: string[],
}

export interface StopBreakoutRoomsPayload {
  roomId: string,
  spaceId: string,
}

export interface GetBreakoutRoomsPayload {
  roomId: string,
  spaceId: string,
}

export interface BreakoutRoomWithParticipants extends GetRoomResponse {
  participants: ParticipantResponse[],
}
export interface GetBreakoutRoomsResponse {
  session: {
    status: 'started' | 'ready',
    startedAt: string,
  },
  items: BreakoutRoomWithParticipants[],
}
export interface CreateInvitePayload {
  spaceId: string;
  roomId: string;
  role: RoomRole;
}

export interface CreateInviteResponse {
  id: string;
}

export interface EmitReactionPayload {
  spaceId: string,
  roomId: string,
  type: ReactionType,
  peerId: string,
}
