import React, {
  VoidFunctionComponent, useEffect,
} from 'react';
import { observer } from 'mobx-react-lite';

import useStores from 'stores/root';
import { getUrlParam, redirectTo } from 'helpers/url';
import { RoutePath } from 'types/common';
import { FullPageLoader } from 'ui-kit';

const Logout: VoidFunctionComponent = observer(() => {
  const {
    authStore,
  } = useStores();

  const logout = async () => {
    const to = getUrlParam('to');

    if (authStore.isUser) {
      await authStore.logout();
    }

    if (!to || to.includes('/auth/')) {
      redirectTo(RoutePath.SignIn);
      return;
    }

    redirectTo(`${RoutePath.SignIn}?to=${to}`);
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <FullPageLoader />
  );
});

export default Logout;
