import styled from '@emotion/styled';

import { Font, ZIndex } from 'ui-kit/theme';

const Layout = styled.div({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: ZIndex.ROOM_FOREGROUND,
});

export const DesktopTitle = styled.div({
  fontFamily: Font.BRAND_BOLD,
  fontSize: 48,
  lineHeight: '60px',
  textAlign: 'center',
});

export const MobileTitle = styled.div({
  fontFamily: Font.BRAND_BOLD,
  fontSize: 24,
  lineHeight: '30px',
  textAlign: 'center',
});

export const DesktopHint = styled.div({
  fontFamily: Font.BRAND,
  fontSize: 32,
  fontWeight: 400,
  lineHeight: '40px',
  textAlign: 'center',
});

export const MobileHint = styled.div({
  fontFamily: Font.BRAND,
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '20px',
  textAlign: 'center',
});

export default Layout;
