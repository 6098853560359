import QueryString from 'qs';
import cookies from 'js-cookie';

import { RoomType } from 'types/common';

import { UTM_KEYS, GetUTMSPayload } from './types';
import { extractCookieValues, safeDecodeString } from './browser';
import { getPageMetaParams } from './pageMeta';

const LANDING_COOKIES_UTMS_NAME = 'ld-utms';

const getPageUTMParamsFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return UTM_KEYS.reduce((acc: Record<string, string>, key) => {
    const value = safeDecodeString(urlParams.get(key) || '');
    if (value) {
      acc[key] = value;
    }

    return acc;
  }, {});
};

const getPageUTMParamsFromCookies = () => {
  try {
    const cookiesValue = cookies.get(LANDING_COOKIES_UTMS_NAME);

    const cookiesValueAsObject = JSON.parse(cookiesValue || '{}') as Record<string, string>;

    if (Object.keys(cookiesValueAsObject).length === 0) {
      return {};
    }

    return extractCookieValues(UTM_KEYS, cookiesValueAsObject);
  } catch {
    return {};
  }
};

const createDefaultUTMObject = ({
  roomId,
  roomType,
  content,
  addSuffix = true,
}: GetUTMSPayload): Record<string, string | undefined> => {
  const userSource = roomType === RoomType.Lesson ? 'conference' : 'webinar';

  const utms = {
    utm_source: 'edu',
    utm_medium: 'participant',
    utm_content: addSuffix ? `${content}${userSource}` : content,
    user_source: userSource,
    room_id: roomId,
  };

  return utms;
};

export const getPageUTMParams = () => {
  const fromURL = getPageUTMParamsFromURL();
  if (Object.keys(fromURL).length > 0) {
    return fromURL;
  }

  const fromCookies = getPageUTMParamsFromCookies();
  if (Object.keys(fromCookies).length > 0) {
    return fromCookies;
  }

  return {};
};

export const getUTMString = ({
  roomId,
  roomType,
  content,
  addSuffix = true,
}: GetUTMSPayload): string => {
  const utms = createDefaultUTMObject({
    roomType,
    roomId,
    content,
    addSuffix,
  });

  return QueryString.stringify(utms);
};

export const getUrlWithUTM = (url: string, keepSearchParams = true, forceUTM = false) => {
  try {
    if (!url) {
      throw new Error(`Invalid url to redirect: ${url}`);
    }

    const isRelativeUrl = url[0] === '/';
    const urlObj = new URL(url, isRelativeUrl ? window.location.href : undefined);

    if (urlObj.hostname !== window.location.hostname && !forceUTM) {
      return url;
    }

    if (!urlObj.pathname.endsWith('/signup') && !urlObj.pathname.endsWith('/signin') && !forceUTM) {
      return url;
    }

    if (!keepSearchParams) {
      urlObj.search = '';
    }

    const { searchParams } = urlObj;
    const utm = getPageUTMParams();

    Object.entries(utm).forEach(([key, value]) => {
      searchParams.set(key, value);
    });

    const meta = getPageMetaParams(false);
    if (meta) {
      Object.entries(meta).forEach(([key, value]) => {
        searchParams.set(key, value);
      });
    }

    return isRelativeUrl ? `${urlObj.pathname}${urlObj.search}` : urlObj.toString();
  } catch (error) {
    return '';
  }
};
